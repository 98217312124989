import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ThreeUtils } from 'src/app/utils/three-utils';
import * as THREE from 'three';
import {
  ReplaySubject,
  filter,
  map,
  of,
  startWith,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { Box3, Vector3 } from 'three';
import { New_ThreeViewComponent } from '../../three-view/three-view.component';
import { toRequestState } from '../../../../../data-request/operators';
import { NewAssetStoreService } from '../../../../../services/3dview/asset-store.service';
import { settings } from '../../../../../models_new/config/application-settings';
import { RenderingService } from '../../../../../services/3dview/rendering.service';
import { IHwSwType } from '../../../../../models_new/types/robot-config/hw-sw-type';

@Component({
  selector: 'app-model-preview',
  // Doesn't need a custom template.
  templateUrl: '../../three-view/three-view.component.html',
  styleUrls: [
    '../../three-view/three-view.component.scss',
    './model-preview.component.scss',
  ],
})
export class ModelPreviewComponent
  extends New_ThreeViewComponent
  implements OnInit, OnChanges, AfterViewInit, OnDestroy
{
  @Input()
  hardware: IHwSwType;

  model: THREE.Object3D;

  changesOnInit$ = new ReplaySubject<SimpleChanges>(1);

  constructor(
    ngZone: NgZone,
    cdRef: ChangeDetectorRef,
    private assetStore: NewAssetStoreService,
    public elementRef: ElementRef,
    renderingService: RenderingService
  ) {
    super(ngZone, renderingService, cdRef);
  }

  ngOnInit(): void {
    super.ngOnInit();

    this.loading$ = this.changesOnInit$.pipe(
      takeUntil(this.destroy$),
      filter(
        (changes: SimpleChanges) =>
          changes.hardware?.currentValue &&
          (changes.hardware?.firstChange ||
            changes.hardware?.currentValue.id !==
              changes.hardware?.previousValue?.id)
      ),
      switchMap((_: SimpleChanges) => {
        return this.hardware.metadata.urdf_path
          ? this.assetStore.load(
              this.hardware.name.toLowerCase(),
              settings.pallyDescriptionsURL + this.hardware.metadata.urdf_path,
              'urdf',
              true
            ).data$
          : of(undefined);
      }),
      tap((model) => {
        if (this.model) {
          this.scene.remove(this.model);
        }

        if (!model) {
          this.render();
          return;
        }

        this.model = model;
        this.model.rotateX(
          this.hardware.hw_sw_type.name === 'gripper'
            ? Math.PI / 2
            : -Math.PI / 2
        );
        this.scene.add(this.model);

        const bounding = new Box3();
        bounding.setFromObject(this.model);
        const size = bounding.getSize(new Vector3());
        const max = Math.max(size.x, size.y, size.z);

        this.controls.object.position.set(max, max, max);
        this.controls.target.set(0, 0, 0);
        this.controls.update();
      }),
      map(() => false),
      startWith(true),
      toRequestState()
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    this.changesOnInit$.next(changes);
  }

  ngAfterViewInit(): void {
    this.addLighting();
  }

  ngOnDestroy(): void {
    if (this.scene === undefined) {
      return;
    }
    this.scene.remove(this.model);
    ThreeUtils.disposeObject(this.model);
    super.ngOnDestroy();
  }

  addLighting(): void {
    const ambientIntensity = 0.25;
    const directionalIntensity = 1 - ambientIntensity;

    const ambient = new THREE.AmbientLight('#ffffff', ambientIntensity - 0.1);
    this.scene.add(ambient);

    const directional = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional.position.set(10, -2, 0);
    directional.lookAt(new THREE.Vector3());
    this.scene.add(directional);
    const directional2 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional2.position.set(-10, -2, 0);
    directional2.lookAt(new THREE.Vector3());
    this.scene.add(directional2);
    const directional3 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional3.position.set(0, -2, 10);
    directional3.lookAt(new THREE.Vector3());
    this.scene.add(directional3);
    const directional4 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional4.position.set(0, -2, -10);
    directional4.lookAt(new THREE.Vector3());
    this.scene.add(directional4);
    const directional5 = new THREE.DirectionalLight(
      '#ffffff',
      directionalIntensity
    );
    directional5.position.set(0, 10, 0);
    directional5.lookAt(new THREE.Vector3());
    this.scene.add(directional5);
  }
}
