import { LogoViewHandler } from '../../classes/3dview/handlers/logo-view-handler';
import { NEWPalletViewHandler } from '../../classes/3dview/handlers/new-pallet-view-handler';
import { PalletViewHandler } from '../../classes/3dview/handlers/pallet-view-handler';
import { RobotConfigHandler } from '../../classes/3dview/handlers/robot-config-handler';
import { ThreeHandler } from '../../classes/3dview/three-handler';
import { ThreeHandlerMode } from '../../classes/3dview/three-handler-mode';
import { IThreeHandlerModeConfig } from '../../types/3dview/three-handler-mode-config';
import { ProductionLineHandler } from '../../classes/3dview/handlers/production-line-view-handler';

export const threeHandlerModeConfig = new Map<
  ThreeHandlerMode,
  IThreeHandlerModeConfig
>([
  [ThreeHandlerMode.Default, { type: ThreeHandler }],
  [ThreeHandlerMode.Project, { type: PalletViewHandler }],

  [ThreeHandlerMode.PalletView, { type: NEWPalletViewHandler }],
  [ThreeHandlerMode.RobotConfig, { type: RobotConfigHandler }],
  [ThreeHandlerMode.LogoView, { type: LogoViewHandler }],
  [ThreeHandlerMode.ProductionLineView, { type: ProductionLineHandler }],
]);
