import { Injectable } from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { StateService } from 'src/app/auth/state.service';
import { IApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';

export interface IOrganizationContextResolverData {
  breadcrumb: string;
  organization_id: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrganizationContextResolver {
  constructor(private stateService: StateService) {}

  resolve(
    _route: ActivatedRouteSnapshot,
    _state: RouterStateSnapshot
  ): Observable<string> {
    return this.stateService.getCustomerOrSalesOrganizationPreserveState().pipe(
      take(1),
      map((org: IApiOrganization) => {
        return org.id;
      })
    );
  }
}
