<div style="width: 95%; height: 95%; margin: auto; padding: 2em">
  <app-import-file
    [multiple]="input.data.multiple"
    [accept]="input.data.accept"
    [sizeLimit]="input.data.sizeLimit"
    [label]="input.data.label"
    [fileCategory]="input.data.fileCategory"
    [loading]="loading"
    (assets)="setAssets($event)"
  ></app-import-file>
  <div class="actions">
    <button mat-stroked-button color="primary" (click)="cancel()">
      <mat-icon style="margin-right: 0.5em">arrow_back</mat-icon>CANCEL
    </button>
    <button
      mat-flat-button
      color="primary"
      (click)="submitAssets()"
      [disabled]="!assets || assets.length === 0 || loading"
    >
      <mat-icon style="margin-right: 0.5em">cloud_upload</mat-icon>UPLOAD
    </button>
  </div>
</div>
