import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

export interface IScriptElementAttribute {
  name: string;
  value?: string;
}

@Injectable({
  providedIn: 'root',
})
export class SupportWidgetService {
  supportButtonElementId: string;
  supportButtonStylingElementId: string;
  scriptUrl: string;
  dbUrl: string;
  stylingParams: any;

  constructor(private domSanitizer: DomSanitizer) {
    this.scriptUrl = 'https://jsd-widget.atlassian.com/assets/embed.js';
    this.dbUrl = 'https://jsd-widget.atlassian.com';
    this.supportButtonElementId = 'jsd-widget';
    this.supportButtonStylingElementId = 'support-button-styling';
    this.stylingParams = {};
  }

  public loadScriptDynamically(): Promise<any> {
    const serviceWidgetAttributes: IScriptElementAttribute[] = [
      { name: 'data-jsd-embedded' },
      { name: 'src', value: this.scriptUrl },
      { name: 'data-base-url', value: this.dbUrl },
      { name: 'data-key', value: environment.atlassianServiceWidgetKey },
    ];

    const script = document.createElement('script');

    serviceWidgetAttributes?.forEach((a) => {
      this.domSanitizer.bypassSecurityTrustResourceUrl(a?.value);
      script.setAttribute(a.name, a?.value || '');
    });

    document.getElementsByTagName('body')[0].appendChild(script);

    return new Promise((resolve, _reject) => (script.onload = resolve));
  }

  setStyleTag(innerHtmlString: string) {
    const widgetStyleTag = document.getElementById(
      this.supportButtonStylingElementId
    );
    if (widgetStyleTag) {
      widgetStyleTag.innerHTML = innerHtmlString;
    } else {
      const styleElement = document.createElement('style');
      styleElement.id = this.supportButtonStylingElementId;
      styleElement.innerHTML = innerHtmlString;
      document.getElementsByTagName('head')[0].appendChild(styleElement);
    }
  }

  generateStyleInnerHtml() {
    let cssString = '';
    Object.keys(this.stylingParams).forEach((prop) => {
      cssString += prop + ': ' + this.stylingParams[prop] + '; ';
    });

    return (
      "iframe[id='" + this.supportButtonElementId + "'] { " + cssString + ' }'
    );
  }

  elevateSupportButton(pxFromBottom?: number) {
    const pxToElevate = pxFromBottom ?? '60';
    this.stylingParams['margin-bottom'] = pxToElevate + 'px !important';
    this.setStyleTag(this.generateStyleInnerHtml());
  }

  resetSupportButtonStyling() {
    this.stylingParams = [];
    this.setStyleTag(this.generateStyleInnerHtml());
  }

  hideSupportButton() {
    this.stylingParams['visibility'] = 'hidden';
    this.setStyleTag(this.generateStyleInnerHtml());
  }

  unhideSupportButton() {
    this.stylingParams['visibility'] = 'visible';
    this.setStyleTag(this.generateStyleInnerHtml());
  }
}
