import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Observable,
  Subject,
  combineLatest,
  of,
  shareReplay,
  skipWhile,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { ApiPattern } from 'src/app/models_new/classes/api-models/ApiPattern';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { OpenSimService } from '../open-sim.service';
import { FileUtils } from '../../../utils/file-utils';
import { IGuiButtonInput } from '../../../models_new/types/button-gui-input';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { NotificationService } from 'src/app/services/notification.service';
import { UnitSystemType } from 'src/app/utils/unit-utils';
import { IOpenSimPage } from 'src/app/models_new/config/fast-track-pages';
import { ApiProduct } from 'src/app/models_new/classes/api-models/ApiProduct';

@Component({
  selector: 'app-fast-track-pattern',
  templateUrl: './fast-track-pattern.component.html',
  styleUrls: ['./fast-track-pattern.component.scss'],
})
export class FastTrackPatternComponent implements OnInit, OnDestroy {
  palletizingPattern$: Observable<DataRequestState<ApiPattern>>;
  patternEmitted: ApiPattern;

  actionButtons: IGuiButtonInput[] = [];
  unit: UnitSystemType = 'metric';
  currentPageContent$: Observable<IOpenSimPage>;
  /** Temporary fix: Used to bypass the pattern-maker-card from overritting the product's data */
  _originalProduct: ApiProduct;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private localStorage: LocalStorageService,
    private openSimService: OpenSimService,
    public appLayout: AppLayoutService,
    private notifier: NotificationService
  ) {}

  ngOnInit(): void {
    this.unit = this.localStorage.getData(
      LocalStorageKey.PREFERRED_UNIT_SYSTEM
    );
    this.palletizingPattern$ = this.openSimService
      .setStepById('pattern-details')
      .pipe(
        skipWhile((step) => !step),
        switchMap((step) =>
          combineLatest([
            of(step.index),
            this.openSimService.prevIsValid(step.index).pipe(take(1)),
          ])
        ),
        tap(([currentStep, isValid]) => {
          if (!isValid) {
            this.openSimService.findAndNavigateToPreviousPage(currentStep);
          }
        }),
        switchMap((_) => {
          const project = this.localStorage.getData(
            LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN
          );
          return of(project || null);
        }),
        tap((opp: ApiPattern) => {
          if (opp?.product?.name) this._originalProduct = opp.product;
          opp.data.labelOrientation = opp.product.data.label_orientation;
          this.patternUpdate(opp);
        }),

        toRequestState(),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    this.currentPageContent$ = this.openSimService
      .getPageContentByStepId('pattern-details')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  patternUpdate(palletizingPattern: ApiPattern) {
    this.updateValid(palletizingPattern);
    const savedPP = this.localStorage.getData(
      LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN
    );

    palletizingPattern.data.name = savedPP.product.name || 'Missing name';
    palletizingPattern.data.productDimensions = palletizingPattern.product.data;
    palletizingPattern.data.labelOrientation =
      savedPP.product.data.label_orientation;
    palletizingPattern.data.guiSettings.units = this.unit;
    palletizingPattern.data.labelOrientation = savedPP.data.labelOrientation;

    /** Temporary fix: Used to bypass the pattern-maker-card from overritting the product's data */
    palletizingPattern.product = this._originalProduct;

    this.localStorage.setData(
      LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN,
      palletizingPattern
    );
  }

  downloadJson(pattern) {
    // Validate
    const palletNotEmpty1 = FileUtils.validField(pattern.data, 'layers', '>0');
    const palletNotEmpty2 = FileUtils.validField(
      pattern.data,
      'layerTypes',
      '>0'
    );
    const descNotEmpty = FileUtils.validField(
      pattern.data,
      'description',
      'defined'
    );

    // Fix
    if (!palletNotEmpty1) {
      this.notifier.showMessage('The pallet in this pattern is empty...');
      pattern.data.layers = [];
    }
    if (!palletNotEmpty2) {
      this.notifier.showMessage('The pallet in this pattern is empty...');
      pattern.data.layerTypes = [];
    }
    if (!descNotEmpty) {
      pattern.data.description = '';
      this.notifier.showMessage('The description in this pattern is empty...');
    }
    /**
     * @desc When exporting JSON from MRC, it is advised to remove or replace whitespace in the file name to
     * ensure compatibility with older versions of Pally URCap that cannot handle patterns with whitespace in the file name.
     * {@link https://rocketfarm.atlassian.net/browse/PALLY-3921?focusedCommentId=40602 Learn more} about this issue.
     */
    pattern.data.name = pattern.name.replace(/ /g, '_');
    FileUtils.downloadJson(pattern.data, 'pattern');
  }

  updateValid(palletizingPattern: ApiPattern) {
    const notLeftPalletEmpty = FileUtils.validField(
      palletizingPattern.data.layerTypes.map((m) => m.pattern),
      '0',
      '>0'
    );
    const notRightPalletEmpty = FileUtils.validField(
      palletizingPattern.data.layerTypes.map((m) => m.pattern),
      '1',
      '>0'
    );

    if (!notLeftPalletEmpty || !notRightPalletEmpty) {
      this.notifier.showMessage('Your pallet needs to contain some boxes!');
    }

    if (
      palletizingPattern.data.dimensions &&
      palletizingPattern.data.guiSettings.stackingMethod &&
      palletizingPattern.data.layers.length &&
      notLeftPalletEmpty &&
      notRightPalletEmpty
    ) {
      this.openSimService.setStepValidity(true);
    } else {
      this.openSimService.setStepValidity(false);
    }
  }
}
