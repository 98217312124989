import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  filter,
  map,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import * as THREE from 'three';
import { NewThreePallet } from '../../../../../models_new/classes/3dview/new-three-pallet';
import { TargetedCameraHelper } from '../../../../../models_new/classes/3dview/targeted-camera-helper';
import { Pallet } from '../../../../../models_new/classes/pallet';
import { Project } from '../../../../../models_new/classes/project';
import { settings } from '../../../../../models_new/config/application-settings';
import { LabelOrientation } from '../../../../../models_new/enums/label-orientation';
import { PalletPosition } from '../../../../../models_new/enums/pallet-position';
import { milliToMeter } from '../../../../../utils/div';
import { ThreePalletComponent } from '../../content/three-pallet/three-pallet.component';
import { New_ThreeViewComponent } from '../../three-view/three-view.component';

@Component({
  selector: 'app-pallet-report-view',
  templateUrl: './pallet-report-view.component.html',
  styleUrls: ['./pallet-report-view.component.scss'],
})
export class PalletReportViewComponent implements OnInit, OnChanges, OnDestroy {
  @Input() project: Project;
  @Input() sticker: THREE.Texture;
  @Input() palletPosition: PalletPosition = settings.primaryPallet;
  @Input() showOutLines = false;
  @Input() firstLayerOnly = false;

  _pallet: Pallet;
  _palletPosition: THREE.Vector3;
  _labelOrientations: LabelOrientation[];
  lightPos1 = new THREE.Vector3(4, 3.5, 5);
  lightPos2 = new THREE.Vector3(4, 3.5, -5);
  lightPos3 = new THREE.Vector3(-4, 3.5, 5);
  lightPos4 = new THREE.Vector3(-4, 3.5, -5);
  @Input() cameraPos = settings.view3d.defaultCameraPosition.clone();

  helper: TargetedCameraHelper;

  @ViewChild('view', { static: true }) view: New_ThreeViewComponent;
  @ViewChild('threePallet') threePallet: ThreePalletComponent;

  @Output() screenshot$ = new EventEmitter<string | undefined>();

  ngOnChanges$ = new ReplaySubject<SimpleChanges>(1);
  destroy$ = new Subject<boolean>();

  ngOnInit(): void {
    const changes$ = this.ngOnChanges$.pipe(
      takeUntil(this.destroy$),
      filter(Boolean),
      map((changes: SimpleChanges) => {
        if (
          (changes.project?.currentValue ||
            changes.palletPosition?.currentValue) &&
          Boolean(this.project) &&
          this.palletPosition !== undefined &&
          this.palletPosition !== null
        ) {
          this.updatePallet();
        }
      })
    );

    changes$
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this.view.loading$),
        filter((v) => v.value),
        switchMap(() => this.threePallet.handleBuild$)
      )
      .subscribe((palletModel: NewThreePallet) => {
        if (this.view) {
          palletModel.shouldUpdateBoundingBox = true;
          palletModel.updateBoundingBox();

          this.view.camera.lookAt(this._palletPosition);
          this.helper = new TargetedCameraHelper(
            this.view.camera,
            this.view.controls
          );
          this.helper.setBoundingBox(palletModel.boundingBox);

          // NOTE: Max zoom (is inverse for orthographic cameras).
          this.helper.minOffset = 200;

          // NOTE: Min zoom (is inverse for orthographic cameras).
          this.helper.maxOffset = 380;

          // Just interested in the final position.
          this.helper.skipAnimation = true;

          this.helper.update(1);
          this.view.getBase64Screenshot();
        }
      });
  }

  processScreenshot(event: string): void {
    this.screenshot$.emit(event);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnChanges$.next(changes);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  updatePallet(): void {
    const isLabelEnabled =
      typeof this.project.data.box.label.orientation !== 'undefined' &&
      this.project.data.box.label.orientation !== null;
    if (isLabelEnabled) {
      let oppositeLabelOrientation =
        (this.project.data.box.label.orientation + 180) % 360;
      if (oppositeLabelOrientation === 270) {
        oppositeLabelOrientation = LabelOrientation.LEFT;
      }

      this._labelOrientations = [
        this.project.data.box.label.orientation,
        oppositeLabelOrientation,
      ];
    }
    this._pallet = this.project.getPalletByPosition(this.palletPosition);
    this._palletPosition = new THREE.Vector3(
      0,
      -milliToMeter(this._pallet.dimensions.totalHeight) / 2,
      0
    );
  }
}
