<app-page>
  <app-breadcrumb-nav></app-breadcrumb-nav>
  <app-header>
    <h1 app-title>Pally program settings</h1>

    <app-header-actions>
      <button
        *appIfCommandAvailable="'create_pally_path_strategy'"
        mat-flat-button
        pally-rounded-button-m
        color="primary"
        (click)="navigateToPallyPathStrategy('new')"
      >
        <mat-icon>add</mat-icon>New
      </button>
    </app-header-actions>
  </app-header>

  <mat-divider></mat-divider>

  <ng-container *ngIf="strategies$ | async as strategies">
    <!-- Loading state -->
    <app-loading *ngIf="strategies.isLoading"></app-loading>
    <!-- Error state -->
    <app-error *ngIf="strategies.error as error" [error]="error"></app-error>

    <app-content-switcher
      *ngIf="strategies.didSucceed"
      [selected]="blockSelected"
      [sortingColumns]="sortingColumns"
      objectType="pally_path_strategy"
      (order)="orderBy = $event"
      (delete)="onDelete(blockSelected)"
      (selectedFiltering)="tableFilter = objUtil.cloneObject($event)"
    >
      <ng-template #table>
        <app-inventory-table
          [filter]="tableFilter"
          [sortBy]="orderBy"
          [data]="strategies.value"
          [displayedColumns]="displayedColumns"
          [rowActions]="rowActions"
          (rowAction)="actionClicked($event)"
          (rowSelected)="rowClicked($event)"
          (blockSelectedChange)="blockSelected = $event"
        >
        </app-inventory-table>
      </ng-template>
    </app-content-switcher>
  </ng-container>
</app-page>
