import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-captcha-dialog',
  templateUrl: './captcha-dialog.component.html',
  styleUrls: ['./captcha-dialog.component.scss'],
})
export class CaptchaDialogComponent implements OnInit {
  public siteKey = environment.reCaptchaSiteKey;
  public reCaptchaCheck!: FormGroup;
  public isLoggable?: boolean;

  constructor(
    public dialogRef: MatDialogRef<CaptchaDialogComponent>,
    public formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public input: any
  ) {}

  ngOnInit(): void {
    this.isLoggable = this.input.data?.isLoggable ?? true;
    this.reCaptchaCheck = this.formBuilder.group({
      recaptcha: ['', Validators.required],
    });
  }

  public onSuccess(): void {
    setTimeout(() => {
      this.dialogRef.close(true);
    }, 1000);
  }

  public doClose(): void {
    this.dialogRef.close();
  }
}
