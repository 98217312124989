import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  combineLatest,
  filter,
  map,
  Observable,
  of,
  shareReplay,
  skipWhile,
  startWith,
  Subject,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { IApiOpenSimulation } from 'src/app/models_new/classes/api-models/ApiOpenSimulation';
import { ApiPattern } from 'src/app/models_new/classes/api-models/ApiPattern';
import { Field } from 'src/app/models_new/classes/field';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import {
  ICountryNState,
  PublicApiService,
} from 'src/app/services/api/public-api.service';
import {
  LocalStorageService,
  StorageMethod,
} from 'src/app/services/local-storage.service';
import { OpenSimService } from '../open-sim.service';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { IOpenSimPage } from 'src/app/models_new/config/fast-track-pages';

@Component({
  selector: 'app-fast-track-region',
  templateUrl: './fast-track-region.component.html',
  styleUrls: ['./fast-track-region.component.scss'],
})
export class FastTrackRegionComponent implements OnInit, OnDestroy {
  dataReady$: Observable<DataRequestState<boolean>>;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  fields: Field[];
  countryCode: string;
  countryControl = new UntypedFormControl('', [Validators.required]);
  filteredCountryOptions$: Observable<string[]>;
  countries: ICountryNState[] = [];
  showStateField = false;
  stateControl = new UntypedFormControl('', [Validators.required]);
  filteredStateOptions$: Observable<string[]>;
  states: ICountryNState[] = [];
  currentPageContent$: Observable<IOpenSimPage>;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private localStorage: LocalStorageService,
    private publicApi: PublicApiService,
    private openSimService: OpenSimService,
    public appLayout: AppLayoutService
  ) {
    this.countryControl.addValidators((control: AbstractControl) => {
      return !this.validateInList(this.countries, control.value)
        ? { select: 'Please select an option' }
        : null;
    });
    this.stateControl.addValidators((control: AbstractControl) => {
      return !this.validateInList(this.states, control.value)
        ? { select: 'Please select an option' }
        : null;
    });
  }

  validateInList(list: ICountryNState[], value: string): boolean {
    // If given name exsists in the options, assume a coutry/state was selected.
    return this.getStringOptions(list)
      .map((o) => o.toLowerCase())
      .includes(value.toLowerCase());
  }

  ngOnInit(): void {
    // Set up initial country field.
    this.dataReady$ = this.openSimService.setStepById('region-details').pipe(
      switchMap((currentStep) =>
        combineLatest([
          of(currentStep.index),
          this.openSimService.prevIsValid(currentStep.index),
        ])
      ),
      tap(([step, isValid]) => {
        if (!isValid) this.openSimService.findAndNavigateToPreviousPage(step);
      }),
      map((_) => {
        const storedPattern = this.localStorage.getData(
          LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN
        );
        return storedPattern ? new ApiPattern(storedPattern) : null;
      }),
      // Add the field to group.
      tap(() => {
        this.formGroup.addControl('country', this.countryControl);
      }),

      // Get country options.
      switchMap(() => this.publicApi.getCountries(true)),
      tap((res) => (this.countries = res)),

      // We're ready.
      map(() => true),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: false })
    );

    // Handles adding of state field if states are available.
    this.dataReady$
      .pipe(
        takeUntil(this.destroy$),

        // Skip while not ready.
        skipWhile((r) => !r.value),

        // Get country from country field.
        switchMap((_) => this.countryControl.valueChanges),
        map((name: string) => {
          // Reset and hide field.
          this.showStateField = false;
          this.removeStateField();

          // Find the country and update field if valid.
          const country = this.findByName(this.countries, name);
          if (country) {
            this.countryControl.setValue(country.name, { emitEvent: false });
          }
          return country;
        }),

        // Skip until user select country.
        filter((v) => typeof v === 'object'),

        // Get states and setup country options.
        switchMap((country: ICountryNState) => {
          return this.publicApi.getStates(country.value);
        }),
        map((res) => {
          this.states = res;

          this.showStateField = this.states.length > 0;

          if (this.showStateField) {
            this.formGroup.addControl('state', this.stateControl);
            this.stateControl.setValue('');
          }
        })
      )
      .subscribe();

    // Country field options filtering.
    this.filteredCountryOptions$ = this.countryControl.valueChanges.pipe(
      // Starts by showing all options.
      startWith(''),
      switchMap((value) => {
        return combineLatest([
          of(value),
          of(this.getStringOptions(this.countries)),
        ]);
      }),
      map(([value, options]: [string, string[]]) => {
        return options.filter((option) =>
          option.toLowerCase().startsWith(value.toLowerCase())
        );
      })
    );

    // State field options filtering.
    this.filteredStateOptions$ = this.stateControl.valueChanges.pipe(
      // Starts by showing all options.
      startWith(''),
      switchMap((value) => {
        return combineLatest([
          of(value),
          of(this.getStringOptions(this.states)),
        ]);
      }),
      map(([value, options]: [string, string[]]) => {
        return options.filter((option) =>
          option.toLowerCase().startsWith(value.toLowerCase())
        );
      })
    );

    this.dataReady$
      .pipe(
        takeUntil(this.destroy$),
        skipWhile((r) => !r.value),
        switchMap((_) => this.formGroup.valueChanges),
        map((v) => {
          // Input is { country: string, state: string }, needs to be mapped to objects.
          v['country'] = this.findByName(this.countries, v.country);
          if (v.state) {
            const state = this.findByName(this.states, v.state);
            // Is this a proper state?, then select it.
            if (state) {
              this.stateControl.setValue(state.name, { emitEvent: false }); //, { emitEvent: false });
            }
            v['state'] = state;
          }
          return v;
        }),
        tap((value: any) => {
          if (value.country) {
            const openSim: IApiOpenSimulation =
              this.localStorage.getData(LocalStorageKey.OPEN_SIM) || {};

            openSim.continent = value.country.continent?.name;
            openSim.country = value.country.name;
            openSim.region = value.state?.name;

            // Grab country code and pass it to 3dview.
            this.countryCode = value.country?.value;

            this.localStorage.setData(
              LocalStorageKey.OPEN_SIM,
              openSim,
              StorageMethod.LOCAL
            );
          }
          this.formGroup.valid
            ? this.openSimService.setStepValidity(true)
            : this.openSimService.setStepValidity(false);
        })
      )
      .subscribe();

    this.currentPageContent$ = this.openSimService
      .getPageContentByStepId('region-details')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  getStringOptions(list: ICountryNState[]): string[] {
    if (!list) {
      return [];
    }
    return list.map((i) => i.name);
  }

  removeStateField() {
    if (Boolean(this.formGroup.get('state'))) {
      this.formGroup.removeControl('state');
    }
  }

  findByName(list: ICountryNState[], name: string): ICountryNState {
    return list.find((c) => c.name.toLowerCase() === name.toLowerCase());
  }
}
