<div class="new-calibration-wrapper">
  <h3>New configuration</h3>

  <app-field *ngFor="let field of fields" [field]="field"></app-field>

  <button mat-raised-button (click)="dialogRef.close()">Cancel</button>
  <button
    style="float: right"
    color="primary"
    mat-raised-button
    (click)="close()"
    [disabled]="!fieldsValid()"
  >
    New configuration
  </button>
</div>
