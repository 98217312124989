import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { SupportWidgetService } from 'src/app/services/support-widget.service';

@Component({
  selector: 'app-wizard-bottom-navbar',
  templateUrl: './wizard-bottom-navbar.component.html',
  styleUrls: ['./wizard-bottom-navbar.component.scss'],
})
export class WizardBottomNavbarComponent implements OnInit, OnDestroy {
  @Input() title: string = 'Missing title input';
  @Input() hintText: string = '';
  @Input() primaryButton: 'next' | 'confirm' | 'save' | 'export' | 'hidden' =
    'next';
  @Input() primaryButtonDisabled = false;
  @Input() secondaryButton: 'skip' | 'back' | 'cancel' | 'hidden' = 'hidden';
  @Input() secondaryButtonDisabled = false;
  @Input() tertiaryButton?: string;
  @Input() tertiaryButtonDisabled = false;
  @Output() nextClick = new EventEmitter<boolean>();
  @Output() backClick = new EventEmitter<boolean>();
  @Output() skipClick = new EventEmitter<boolean>();
  @Output() tertiaryButtonClick = new EventEmitter<boolean>();

  constructor(private supportWidgetService: SupportWidgetService) {}

  ngOnInit(): void {
    this.supportWidgetService.elevateSupportButton();
  }
  ngOnDestroy(): void {
    this.supportWidgetService.resetSupportButtonStyling();
  }
}
