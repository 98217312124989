import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-info-error-badge',
  templateUrl: './info-error-badge.component.html',
  styleUrls: ['./info-error-badge.component.scss'],
})
export class InfoErrorBadgeComponent {
  @Input() type: 'info' | 'error';
  @Input() data: any;
  @Output() actionClicked: EventEmitter<{ actionId: string }> =
    new EventEmitter();
}
