// This file contains functions and constants. No state should be presisted here

import {
  OffsetBracketType,
  simConfigSceneFields,
} from '../models_new/config/sim-config/sim-config-fields';
import { ISimApiFormConfig } from '../models_new/types/sim-api-form-config';
import { Field } from '../models_new/classes/field';
import {
  getDefaultScene,
  ISceneApiFileType,
} from '../models_new/types/simulation-api-file-type';
import { getConveyorOrientationFromAngle } from '../models_new/config/form_fields';
import { SimConfigFieldIds } from '../models_new/enums/simconfig-field-ids';
import * as THREE from 'three';
import { MathUtils } from 'three';
import { Type } from '../utils/type';
import { meterToMilli, milliToMeter } from '../utils/div';
import { filter as _filter, find, set, get, findIndex } from 'lodash-es';
import { toIQuarternion, toQuarternion } from '../utils/three-utils';
import { Validators } from '@angular/forms';
import {
  defaultCustomGripper,
  defaultScene,
} from '../models_new/config/default/default-scene';
import { NotificationService } from './notification.service';
import { FieldType } from '../models_new/types/field-type';
import { LocalStorageService } from './local-storage.service';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';
import { ICustomGripper } from '../models_new/types/custom-gripper';
import {
  HwSwTypeMap,
  IHwSwType,
} from '../models_new/types/robot-config/hw-sw-type';
import { ObjectUtils } from '../utils/object';

export const fieldNeedsConversion = [
  SimConfigFieldIds.BasebracketHeight,
  SimConfigFieldIds.OffsetBracketWidth,
  SimConfigFieldIds.OffsetBracketLength,
  SimConfigFieldIds.OffsetBracketHeight,
  SimConfigFieldIds.OffsetBracketPositionX,
  SimConfigFieldIds.OffsetBracketPositionY,
  SimConfigFieldIds.OffsetBracketPositionZ,
  SimConfigFieldIds.OffsetBracketOffsetX,
  SimConfigFieldIds.OffsetBracketOffsetY,
  SimConfigFieldIds.OffsetBracketOffsetZ,
  SimConfigFieldIds.GripperCustomWidth,
  SimConfigFieldIds.GripperCustomLength,
  SimConfigFieldIds.GripperCustomHeight,
  SimConfigFieldIds.GripperCustomPositionX,
  SimConfigFieldIds.GripperCustomPositionY,
  SimConfigFieldIds.GripperCustomPositionZ,
  SimConfigFieldIds.PalletRightPositionX,
  SimConfigFieldIds.PalletRightPositionY,
  SimConfigFieldIds.PalletRightPositionZ,
  SimConfigFieldIds.PalletLeftPositionX,
  SimConfigFieldIds.PalletLeftPositionY,
  SimConfigFieldIds.PalletLeftPositionZ,
  SimConfigFieldIds.ConveyorPrimaryWidth,
  SimConfigFieldIds.ConveyorPrimaryLength,
  SimConfigFieldIds.ConveyorPrimaryHeight,
  SimConfigFieldIds.ConveyorPrimaryPositionX,
  SimConfigFieldIds.ConveyorPrimaryPositionY,
  SimConfigFieldIds.ConveyorPrimaryPositionZ,
  SimConfigFieldIds.ConveyorPrimaryGuideWidth,
  SimConfigFieldIds.ConveyorSecondaryWidth,
  SimConfigFieldIds.ConveyorSecondaryLength,
  SimConfigFieldIds.ConveyorSecondaryHeight,
  SimConfigFieldIds.ConveyorSecondaryPositionX,
  SimConfigFieldIds.ConveyorSecondaryPositionY,
  SimConfigFieldIds.ConveyorSecondaryPositionZ,
  SimConfigFieldIds.ConveyorSecondaryGuideWidth,
  SimConfigFieldIds.LiftkitMaxStroke,
];

export const fieldToSoftwareMap = Object.fromEntries([
  [SimConfigFieldIds.FrameType, 'frame'],
  [SimConfigFieldIds.LiftkitType, 'liftkit'],
  [SimConfigFieldIds.RobotType, 'robot'],
  [SimConfigFieldIds.GripperType, 'gripper'],
  [SimConfigFieldIds.ConveyorPrimaryType, 'conveyor'],
  [SimConfigFieldIds.ConveyorSecondaryType, 'conveyor'],
  [SimConfigFieldIds.ThemeType, 'scene'],
]);
export const exportAsBooleanFields = [SimConfigFieldIds.ShowDimensions];

/**
 * Delayed field updates on import.
 * All fields in this list will be updated last (in-order)
 * to ensure other fields updated by them are not overwritten later.
 * NOTE: This list also dictates the order of the delayed imports.
 */
export const delayImportIds = [
  SimConfigFieldIds.ConveyorPrimaryType,
  SimConfigFieldIds.ConveyorSecondaryType,
  SimConfigFieldIds.FrameType,
  SimConfigFieldIds.LiftkitType,
  SimConfigFieldIds.RobotType,
  SimConfigFieldIds.GripperType,
  // Should be updated last to ensure all other conveyor fields are updated first.
  SimConfigFieldIds.DualProductMode,
];
// Excluded fields for importing and exporting
export const excludedImportIds = [
  SimConfigFieldIds.Name,
  SimConfigFieldIds.AddOffsetBracket, // Handeled manually
  SimConfigFieldIds.OffsetBracketType, // Handeled manually
  SimConfigFieldIds.OffsetBracketAdvanced,
  SimConfigFieldIds.ConveyorPrimaryDirection,
  SimConfigFieldIds.ConveyorSecondaryDirection,
  SimConfigFieldIds.ConveyorPrimaryBoxFreeHeight,
  SimConfigFieldIds.ConveyorSecondaryBoxFreeHeight,
];
const excludedExportIds = [
  SimConfigFieldIds.AddOffsetBracket,
  SimConfigFieldIds.OffsetBracketAdvanced,
  SimConfigFieldIds.ConveyorPrimaryName,
  SimConfigFieldIds.ConveyorSecondaryName,
  SimConfigFieldIds.PalletRightName,
  SimConfigFieldIds.PalletLeftName,
  SimConfigFieldIds.ConveyorPrimaryDirection,
  SimConfigFieldIds.ConveyorSecondaryDirection,
  SimConfigFieldIds.ConveyorPrimaryBoxFreeHeight,
  SimConfigFieldIds.ConveyorSecondaryBoxFreeHeight,
  SimConfigFieldIds.OffsetBracketType,
];

export function createSimConfigSceneFields(
  notification: NotificationService,
  localstoreService: LocalStorageService,
  hwSwMap: HwSwTypeMap,
  apiScene?: ISceneApiFileType,
  sceneName?: string
) {
  if (apiScene?.robot.gripper.type === 'CUSTOM') {
    localstoreService.setData(LocalStorageKey.CUSTOM_GRIPPER, {
      custom_collision_box: {
        length: meterToMilli(
          apiScene.robot.gripper.custom_collision_box.length
        ),
        width: meterToMilli(apiScene.robot.gripper.custom_collision_box.width),
        height: meterToMilli(
          apiScene.robot.gripper.custom_collision_box.height
        ),
        position: new THREE.Vector3(),
      },
      custom_offset_pose: {
        position: new THREE.Vector3(
          meterToMilli(apiScene.robot.gripper.custom_offset_pose.position.x),
          meterToMilli(apiScene.robot.gripper.custom_offset_pose.position.y),
          0
        ),
        rotation: { r: 0, p: 0, y: 0 },
      },
    } as ICustomGripper);
  }

  apiScene = apiScene ? apiScene : getDefaultScene();
  const fields = simConfigSceneFields.map((config) => makeField(config));
  return updateFields(
    notification,
    localstoreService,
    fields,
    apiScene,
    hwSwMap,
    sceneName
  );
}

export function isDuelProductModeEnabled(scene: ISceneApiFileType) {
  return (
    // Duel product mode is enabled on the scene, or...
    scene.dualProduct ||
    // ...there is more than one conveyor.
    scene.conveyors.length > 1
  );
}

export function updateFields(
  notification: NotificationService,
  localstoreService: LocalStorageService,
  fields: Field[],
  scene: ISceneApiFileType,
  hwSwIdMap: HwSwTypeMap,
  sceneName?: string
) {
  const dualProductModeField = findField(
    fields,
    SimConfigFieldIds.DualProductMode
  )?.formControl;
  const dualProductModeEnabled =
    // Enabled if field is set, or...
    dualProductModeField?.value ||
    // Duel product mode is enabled on the scene, or...
    // ...there are more than one conveyor and the field is not set.
    isDuelProductModeEnabled(scene);

  // Merging incoming scene with default scene to ensure all fields are present.
  const scn = ObjectUtils.mergeObjects(getDefaultScene(), scene);

  let delayedFields: Field[] = [];
  fields?.forEach((field) => {
    if (field.id === SimConfigFieldIds.DualProductMode) {
      field.formControl.setValue(dualProductModeEnabled);
    } else if (
      field.id === SimConfigFieldIds.ConveyorPrimaryDirection ||
      (field.id === SimConfigFieldIds.ConveyorSecondaryDirection &&
        dualProductModeEnabled)
    ) {
      const index =
        field.id === SimConfigFieldIds.ConveyorPrimaryDirection ? 0 : 1;

      setConveyorOrientation(
        field,
        toQuarternion(scn.conveyors[index].box_corner_pose.orientation)
      );
    } else if (field.id === SimConfigFieldIds.Name && sceneName) {
      field.formControl.setValue(sceneName);
    } else if (field.id === SimConfigFieldIds.AddOffsetBracket) {
      field.formControl.setValue(
        scn.robot.offset_bracket.collision_object.length > 0
      );
    } else if (field.id === SimConfigFieldIds.OffsetBracketType) {
      const bracketLength =
        scn.robot.offset_bracket.collision_object.length * 100; // meter => cm
      let type: OffsetBracketType = 'None';
      if (bracketLength > 10) {
        type = '15 cm';
      } else if (bracketLength > 5) {
        type = '10 cm';
      } else if (bracketLength > 0) {
        type = '5 cm';
      }
      field.formControl.setValue(type);
    } else if ((excludedImportIds as string[]).includes(field.id)) {
      return;
    } else if ((delayImportIds as string[]).includes(field.id)) {
      // Some fields recursively updates other fields with recursion depth 1
      // For this reason we need to process these after all other fields
      delayedFields.push(field);
      return;
    }

    updateField(scn, field, hwSwIdMap, notification);
  });

  // Sort delayed fields to get them in order of definitions in delayImportIds
  delayedFields = delayedFields.sort((a, b) => {
    return (
      delayImportIds.indexOf(a.id as SimConfigFieldIds) -
      delayImportIds.indexOf(b.id as SimConfigFieldIds)
    );
  });

  delayedFields.forEach((field) => {
    if (
      field.id === SimConfigFieldIds.GripperType &&
      scn.robot.gripper.type === 'CUSTOM'
    ) {
      const customGripper = localstoreService.getData(
        LocalStorageKey.CUSTOM_GRIPPER
      );
      fields
        .find((f: Field) => f.id === SimConfigFieldIds.GripperCustomLength)
        .formControl.setValue(
          customGripper
            ? customGripper.custom_collision_box.length
            : defaultCustomGripper.custom_collision_box.length
        );
      fields
        .find((f: Field) => f.id === SimConfigFieldIds.GripperCustomWidth)
        .formControl.setValue(
          customGripper
            ? customGripper.custom_collision_box.width
            : defaultCustomGripper.custom_collision_box.width
        );
      fields
        .find((f: Field) => f.id === SimConfigFieldIds.GripperCustomHeight)
        .formControl.setValue(
          customGripper
            ? customGripper.custom_collision_box.height
            : defaultCustomGripper.custom_collision_box.height
        );
      fields
        .find((f: Field) => f.id === SimConfigFieldIds.GripperCustomPositionX)
        .formControl.setValue(
          customGripper
            ? customGripper.custom_offset_pose.position.x
            : defaultCustomGripper.custom_offset_pose.position.x
        );
      fields
        .find((f: Field) => f.id === SimConfigFieldIds.GripperCustomPositionY)
        .formControl.setValue(
          customGripper
            ? -customGripper.custom_offset_pose.position.y
            : defaultCustomGripper.custom_offset_pose.position.y
        );
    }

    updateField(scn, field, hwSwIdMap, notification);
  });
  return fields;
}

export function getHw(
  hwSwTypeName: string,
  hwName: string,
  hwSwMap: HwSwTypeMap,
  notification?: NotificationService
) {
  let hw = find(
    hwSwMap,
    (v) =>
      // hw_sw_type name is only unique for hw_sw_tablenot unique, but (hw_sw_type.name + )
      v.hw_sw_type.name === hwSwTypeName && v.name === hwName
  );

  // Couln't find the selected hardware, give warning and select default
  if (!hw) {
    let newHwName: string;
    if (hwSwTypeName === 'frame') {
      newHwName = defaultScene.robot.frame.type;
    } else if (hwSwTypeName === 'liftkit') {
      newHwName = defaultScene.robot.lift_kit.type;
    } else if (hwSwTypeName === 'gripper') {
      newHwName = defaultScene.robot.gripper.type;
    } else if (hwSwTypeName === 'conveyor') {
      newHwName = defaultScene.conveyors[0].type;
    }

    hw = find(
      hwSwMap,
      (v) =>
        // hw_sw_type name is only unique for hw_sw_tablenot unique, but (hw_sw_type.name + )
        v.hw_sw_type.name === hwSwTypeName && v.name === newHwName
    );

    const msg = `Could not find part "${hwName}" ${hwSwTypeName} in the given list of parts!\nDefaulting to part "${newHwName}" ${hwSwTypeName} instead!`;
    console.warn(msg);
    if (notification) {
      notification.showMessage(msg);
    }
  }
  return hw;
}

//export const getHwSw = (id:SimConfigFieldIds, )
export const getHwOptions = (hwType: string, hwSwIdMap: HwSwTypeMap) =>
  _filter(hwSwIdMap, (hw) => hw.hw_sw_type.name == hwType);

export function updateField(
  scene: ISceneApiFileType,
  field: Field,
  hwSwIdMap: HwSwTypeMap,
  notification?: NotificationService
) {
  let value = property(scene, field.id);

  // Map boolean value to string selection field
  if (
    field.id === SimConfigFieldIds.ConveyorPrimaryGuideSide ||
    field.id === SimConfigFieldIds.ConveyorSecondaryGuideSide
  ) {
    field.formControl.setValue(value ? 'Left' : 'Right');
    return field;
  }
  if (field.id in fieldToSoftwareMap) {
    // setting fieldValue to object from name in scene
    field.formControl.setValue(
      getHw(fieldToSoftwareMap[field.id], value, hwSwIdMap, notification)
    );
    // Adding object options for select fileds
    field.options = getHwOptions(fieldToSoftwareMap[field.id], hwSwIdMap);
    return field;
  }

  if (
    fieldNeedsConversion.includes(field.id as SimConfigFieldIds) &&
    !isNaN(value)
  ) {
    value = meterToMilli(+value);
  }

  if (field.id === SimConfigFieldIds.OffsetBracketOffsetY) {
    // Y-axis for offset-bracket is flipped between simulator and three.js.
    // Should always be positive until export
    value = Math.abs(value);
  }

  if (
    field.id === SimConfigFieldIds.PalletLeftPositionY ||
    field.id === SimConfigFieldIds.PalletRightPositionY
  ) {
    // Is in relation to default value -600, but the field should show 0.
    value += 600;
  }

  if (Type.isDefined_NotNull(value)) {
    field.formControl.setValue(value);
  }
  return field;
}

export function findField(fields: Field[], fieldId: string): Field {
  return fields.find((f: Field) => f.id === fieldId);
}

export function makeField(config: ISimApiFormConfig): Field {
  const validators = [];
  if (config.required) {
    validators.push(Validators.required);
  }
  if (config.validators.length > 0) {
    validators.push(...config.validators);
  }

  const field = new Field(
    config.type,
    config.required,
    config.defaultValue,
    validators,
    null,
    config.options,
    null,
    {
      label: config.label,
      name: config.id,
      suffix: config.suffix ? config.suffix : null,
    },
    config.id
  );
  if (config.disabled) {
    field.disable();
  }

  if (config.showProperty) field.text.showProperty = config.showProperty;

  if (config.type === FieldType.NUMBER && config.step) {
    field.step = config.step;
  } else {
    field.step = 1;
  }

  field.reactive.enabled_by_field = config.enabledByFields;
  field.reactive.updates_fields_onChange = config.updatesFieldsOnChange;
  field.reactive.post_updates_fields_onChange =
    config.postUpdatesFieldsOnChange;
  field.reactive.updatesFieldFn = config.updatesFieldFn;
  return field;
}

export function setConveyorOrientation(
  directionField: Field,
  quarternion: THREE.Quaternion
) {
  if (
    directionField.id !== SimConfigFieldIds.ConveyorPrimaryDirection &&
    directionField.id !== SimConfigFieldIds.ConveyorSecondaryDirection
  ) {
    throw `setConveyorOrientation can not be called with form with Form.id == ${directionField.id}, it must be ${SimConfigFieldIds.ConveyorPrimaryDirection} or ${SimConfigFieldIds.ConveyorSecondaryDirection}`;
  }
  const direction = getConveyorOrientationFromAngle(
    MathUtils.radToDeg(
      new THREE.Euler().setFromQuaternion(quarternion, 'XYZ').z
    )
  );

  directionField.formControl.setValue(direction, { emitEvent: true });
}

export function toScene(
  fields: Field[],
  id?: string,
  _dualProductMode?: boolean
) {
  const scene = getDefaultScene();
  if (id && id !== 'new') scene.id = id;

  const offsetEnabled = findField(fields, SimConfigFieldIds.AddOffsetBracket)
    ?.formControl?.value;
  fields
    .filter(
      (field) =>
        !(excludedExportIds as string[]).includes(field.id) ||
        (!offsetEnabled && field.id.includes('robot.offset_bracket'))
    )
    .forEach((field) => {
      // Skip name field as it's not a part of hardware structure and handled elsewhere
      if (field.id === SimConfigFieldIds.Name) {
        return;
      }

      // Map string selection field to boolean value
      if (
        field.id === SimConfigFieldIds.ConveyorPrimaryGuideSide ||
        field.id === SimConfigFieldIds.ConveyorSecondaryGuideSide
      ) {
        property(scene, field.id, field.getValue() === 'Left');
        return;
      }

      let value = field.getValue();
      if (field.id in fieldToSoftwareMap) {
        // Convert value from IHwSwType object to name string
        value = (value as IHwSwType).name;
      } else if (field.id in exportAsBooleanFields) {
        value = Boolean(value);
      }
      if (
        (fieldNeedsConversion as string[]).includes(field.id) &&
        !isNaN(Number(value))
      ) {
        value = milliToMeter(value as number);
      }

      // Map string selection field to boolean value
      if (field.id === SimConfigFieldIds.OffsetBracketOffsetY) {
        property(scene, field.id, -value);
        return;
      }

      if (
        field.id === SimConfigFieldIds.PalletRightPositionY ||
        field.id === SimConfigFieldIds.PalletLeftPositionY
      ) {
        // Value is in relation to -600.
        value = -0.6 + +value;
      }

      property(scene, field.id, value);

      if (
        field.id === SimConfigFieldIds.FrameType ||
        field.id === SimConfigFieldIds.LiftkitType ||
        field.id === SimConfigFieldIds.RobotType ||
        field.id === SimConfigFieldIds.GripperType ||
        field.id === SimConfigFieldIds.ConveyorPrimaryType ||
        field.id === SimConfigFieldIds.ConveyorSecondaryType
      ) {
        updateHwProperties(scene, field);
      }
    });

  // THREE.Quaternions produce "_x" properties when converted to JSON.
  // So we need to convert them manually
  for (const conveyor of scene.conveyors) {
    conveyor.box_corner_pose.orientation = toIQuarternion(
      conveyor.box_corner_pose.orientation as THREE.Quaternion
    );
  }
  for (const pallet of scene.place_targets) {
    pallet.corner_base_pose.orientation = toIQuarternion(
      pallet.corner_base_pose.orientation as THREE.Quaternion
    );
  }

  // Remove second conveyor if dual product mode is disabled.
  if (!scene.dualProduct && scene.conveyors.length > 1) {
    scene.conveyors.pop();
  }
  return scene;
}

function updateHwProperties(scene: ISceneApiFileType, field: Field): void {
  let fieldPaths: Record<string, string> = {};
  if (field.id === SimConfigFieldIds.FrameType) {
    fieldPaths['id'] = SimConfigFieldIds.FrameId;
    fieldPaths['type'] = SimConfigFieldIds.FrameName;
    fieldPaths['name'] = SimConfigFieldIds.FrameName;
    fieldPaths['label'] = SimConfigFieldIds.FrameTypeLabel;
  } else if (field.id === SimConfigFieldIds.LiftkitType) {
    fieldPaths['id'] = SimConfigFieldIds.LiftkitId;
    fieldPaths['type'] = SimConfigFieldIds.LiftkitName;
    fieldPaths['name'] = SimConfigFieldIds.LiftkitName;
    fieldPaths['label'] = SimConfigFieldIds.LiftkitTypeLabel;
  } else if (field.id === SimConfigFieldIds.RobotType) {
    fieldPaths['id'] = SimConfigFieldIds.RobotId;
    fieldPaths['type'] = SimConfigFieldIds.RobotName;
    fieldPaths['name'] = SimConfigFieldIds.RobotName;
    fieldPaths['label'] = SimConfigFieldIds.RobotTypeLabel;
  } else if (field.id === SimConfigFieldIds.GripperType) {
    fieldPaths['id'] = SimConfigFieldIds.GripperId;
    fieldPaths['type'] = SimConfigFieldIds.GripperName;
    fieldPaths['name'] = SimConfigFieldIds.GripperName;
    fieldPaths['label'] = SimConfigFieldIds.GripperTypeLabel;
  } else if (field.id === SimConfigFieldIds.ConveyorPrimaryType) {
    fieldPaths['id'] = SimConfigFieldIds.ConveyorPrimaryId;
    fieldPaths['type'] = SimConfigFieldIds.ConveyorPrimaryTypeName;
    fieldPaths['name'] = SimConfigFieldIds.ConveyorPrimaryTypeName;
    fieldPaths['label'] = SimConfigFieldIds.ConveyorPrimaryTypeLabel;
  } else if (field.id === SimConfigFieldIds.ConveyorSecondaryType) {
    fieldPaths['id'] = SimConfigFieldIds.ConveyorSecondaryId;
    fieldPaths['type'] = SimConfigFieldIds.ConveyorSecondaryTypeName;
    fieldPaths['name'] = SimConfigFieldIds.ConveyorSecondaryTypeName;
    fieldPaths['label'] = SimConfigFieldIds.ConveyorSecondaryTypeLabel;
  }

  if (field) {
    const fieldValues = field.formControl.value;
    Object.keys(fieldPaths).forEach((fieldId) => {
      property(
        scene,
        fieldPaths[fieldId],
        fieldValues?.[fieldId] ? fieldValues[fieldId] : ''
      );
    });
  }
}

// Set or get value in object by string describing path. Supports conditional 'find' for array
// Example usage:
// var obj = {team:[{user:'john', age:2},{user:'travis'}]}
// setPath(obj, 'team[user:travis]', {objectToReplace:'hello'})
//   obj => {team:[{user:'john'},{objectToReplace:'hello'}]}
// setPath(obj, 'team[1]', {objectToReplace:'hello2'})
//   obj => {"team":[{"user":"jenny"},{"objectToReplace":"hello2"}]}
// setPath(obj, 'team[0].user', 'jenny')
//   obj => {team:[{user:'jenny'},{objectToReplace:'hello2'}]}
// setPath(obj, 'team[age:2].user', 'bob')
//   obj => {"team":[{"user":"bob","age":2},{"objectToReplace":"hello2"}]}
// setPath(obj, 'team', [{user:'lonely'}])
//   obj => {"team":[{"user":"lonely"}]}

function property(obj: object, path: string, value?: any) {
  if (!path) throw `property() called with invalid path: ${path}`;
  const reResult = path.match(/(.*?)?\.?\[([^\]]*?):(.*?)\]\.?(.*)/);
  if (!reResult) {
    //Is lodash able to resolve path?
    if (typeof value !== 'undefined') {
      // Should value be set?
      set(obj, path, value); // set value on path match
      return value; // Return value set
    }
    return get(obj, path); // return value at path match
  }
  // We need regular expression result to find property
  const valueAsInt = parseInt(reResult[3]);
  const selector = (Object as any).fromEntries([
    // Construct object slector from path. Eg. '[myProperty:valueToMatch]' => {myProperty:valueToMatch}
    [reResult[2], !isNaN(valueAsInt) ? valueAsInt : reResult[3]],
  ]);
  const array = get(obj, reResult[1]); // Get array at path
  const arrayIndex = findIndex(array, selector); // Get index of found match
  if (!reResult[4]) {
    // Is this end of path?
    if (typeof value !== 'undefined') {
      // End of path. Set and return value
      set(array, `[${arrayIndex}]`, value); // Set value on path match
      return value; // Return value set
    }
    return array[arrayIndex]; // End of path. Return value
  }
  return property(array[arrayIndex], reResult[4], value); // End of path not found => recursive call
}
