import * as THREE from 'three';
import { MathUtils } from 'three';
import {
  URDFCollider,
  URDFJoint,
  URDFLink,
  URDFRobot,
  URDFVisual,
} from '@rocketfarm/urdf-loader/src/URDFClasses';
import { ThreeUtils } from './three-utils';
import { Type } from './type';
import {
  JointNames,
  LinkNames,
} from '../services/project-robot-descriptor.service';
import { PartType } from '../models_new/enums/sim-config-part-type';
export type URDFTypes =
  | URDFRobot
  | URDFJoint
  | URDFLink
  | URDFVisual
  | URDFCollider;

type ModifiableURDFJoint = URDFJoint & {
  origPosition?: THREE.Vector3;
  origQuaternion?: THREE.Quaternion;
};

export class URDFUtils {
  static isURDFRobot(obj: any): obj is URDFRobot {
    return obj && Type.isDefined(obj.isURDFRobot) && obj.isURDFRobot;
  }

  static isURDFJoint(obj: any): obj is URDFJoint {
    return obj && Type.isDefined(obj.isURDFJoint) && obj.isURDFJoint;
  }

  static isURDFLink(obj: any): obj is URDFLink {
    return obj && Type.isDefined(obj.isURDFLink) && obj.isURDFLink;
  }

  static isURDFVisual(obj: any): obj is URDFVisual {
    return obj && Type.isDefined(obj.isURDFVisual) && obj.isURDFVisual;
  }

  static isURDFCollider(obj: any): obj is URDFCollider {
    return obj && Type.isDefined(obj.isURDFCollider) && obj.isURDFCollider;
  }

  static isURDFType(obj: any): obj is URDFTypes {
    return (
      URDFUtils.isURDFRobot(obj) ||
      URDFUtils.isURDFJoint(obj) ||
      URDFUtils.isURDFLink(obj) ||
      URDFUtils.isURDFVisual(obj) ||
      URDFUtils.isURDFCollider(obj)
    );
  }

  static makeEmptyURDFRobot(): URDFRobot {
    const robot: any = new THREE.Object3D();

    // Link properties
    robot.isURDFLink = true;
    robot.urdfNode = null;

    // Robot properties
    robot.isURDFRobot = true;

    robot.urdfRobotNode = null;
    robot.robotName = '';

    robot.links = {};
    robot.joints = {};
    robot.colliders = {};
    robot.visual = {};
    robot.frames = {};

    robot.setJointValue = (
      _jointName: string,
      _value0: number,
      _value1?: number,
      _value2?: number
    ) => {
      /* Add implementation here if needed */
    };
    robot.setJointValues = (_values: { [key: string]: number | number[] }) => {
      /* add something here */
    };

    robot.getFrame = (_name: string) => new THREE.Object3D();

    return robot as URDFRobot;
  }

  static makeEmptyURDFJoint(): URDFJoint {
    const joint: any = new THREE.Object3D();

    joint.isURDFJoint = true;

    joint.urdfNode = null;
    joint.axis = new THREE.Vector3();
    joint.jointType = 'fixed';
    joint.angle = 0;
    joint.jointValue = [0, 0, 0];
    joint.limit = { lower: -180, upper: 180 };
    joint.ignoreLimits = false;
    joint.mimicJoints = [];

    joint.setJointValue = (
      _value0: number,
      _value1?: number,
      _value2?: number
    ) => {
      /* Add implementation here if needed */
    };

    return joint as URDFJoint;
  }

  static makeEmptyURDFLink(): URDFLink {
    const link: any = new THREE.Object3D();

    link.isURDFLink = true;
    link.urdfNode = null;

    return link;
  }

  /**
   * Sets the joint angle. NOTE! Only revolution joint are supported for now.
   * Need to do this as a custom function since objects are now based on interfaces rather than classes.
   * Source: https://github.com/gkjohnson/urdf-loaders/blob/9110fa8499b5eb1afa347c24abe04afaefc86f3e/javascript/src/URDFClasses.js#L148
   *
   * @param joint - URDFJoint - Joint to update.
   * @param value - number - Angle in degrees/radians or distance if prismatic joint type.
   * @param degrees - boolean - Specifies if "angle" is in degrees. Ignored if prismatic.
   */
  static setJointAngle(
    joint: ModifiableURDFJoint,
    value: number,
    degrees: boolean = false
  ): void {
    if (!joint.origPosition || !joint.origQuaternion) {
      joint.origPosition = joint.position.clone();
      joint.origQuaternion = joint.quaternion.clone();
    }

    if (joint.jointType === 'revolute' || joint.jointType === 'continuous') {
      if (degrees) {
        value = MathUtils.degToRad(value);
      }

      if (!joint.ignoreLimits && joint.jointType === 'revolute') {
        value = Math.min(joint.limit.upper.valueOf(), value);
        value = Math.max(joint.limit.lower.valueOf(), value);
      }

      joint.quaternion
        .setFromAxisAngle(joint.axis, value)
        .premultiply(joint.origQuaternion);
      if (joint.jointValue[0] !== value) {
        joint.jointValue[0] = value;
        joint.matrixWorldNeedsUpdate = true;
      }
    } else if (joint.jointType === 'prismatic') {
      if (!joint.ignoreLimits) {
        value = Math.min(joint.limit.upper.valueOf(), value);
        value = Math.max(joint.limit.lower.valueOf(), value);
      }

      joint.position.copy(joint.origPosition);
      joint.position.addScaledVector(joint.axis, value);

      if (joint.jointValue[0] !== value) {
        joint.jointValue[0] = value;
        joint.matrixWorldNeedsUpdate = true;
      }
    }
  }

  static clone(src: any, updateWorldMatrix: boolean = true): any {
    let obj;
    if (URDFUtils.isURDFRobot(src)) {
      obj = URDFUtils.cloneRobot(src);
    } else if (URDFUtils.isURDFJoint(src)) {
      obj = URDFUtils.cloneJoint(src);
    } else if (URDFUtils.isURDFLink(src)) {
      obj = URDFUtils.cloneLink(src);
    } else if (URDFUtils.isURDFVisual(src)) {
      obj = URDFUtils.cloneVisual(src);
    } else if (URDFUtils.isURDFCollider(src)) {
      obj = URDFUtils.cloneCollider(src);
    } else if (
      src &&
      Type.isDefined(src.type) &&
      (src.type === 'Object3D' || src.type === 'Group' || src.type === 'Mesh')
    ) {
      obj = ThreeUtils.clone(src, true);
    }

    if (updateWorldMatrix) {
      obj.updateMatrixWorld();
    }

    return obj;
  }

  private static handleChildren(src: any, parent: any): void {
    for (let i = 0; i < src.children.length; i++) {
      const child = URDFUtils.clone(src.children[i]);
      if (typeof child != 'undefined' && child != null) {
        parent.add(child);
      }
    }
  }

  private static handleThreeProperties(src: any, obj: any): void {
    if (typeof obj.uuid == 'undefined') {
      obj.uuid = MathUtils.generateUUID();
    }
    obj.name = src.name;
    obj.up = src.up ?? THREE.Object3D.DefaultUp;
    /*
    NOTE: Temporarily disabled due to rollback of THREE.js.

    obj.up = Type.isDefined(src.up) ? src.up : THREE.Object3D.DEFAULT_UP;
    */
    if (obj.position && src.position) {
      obj.position.copy(src.position);
    }
    if ((obj.quaternion, src.quaternion)) {
      obj.quaternion.copy(src.quaternion);
    }
    if ((obj.scale, src.scale)) {
      obj.scale.copy(src.scale);
    }
    obj.matrix = new THREE.Matrix4().copy(src.matrix);
    obj.matrixWorld = new THREE.Matrix4().copy(src.matrixWorld);
    obj.children = [];

    if (src.material) {
      obj.material = src.material.clone();
    }
    if (src.geometry) {
      obj.geometry = src.geometry.clone();
    }

    obj.matrixAutoUpdate = src.matrixAutoUpdate;
    obj.matrixWorldNeedsUpdate = src.matrixWorldNeedsUpdate;
    if (obj.layers && src.layers) {
      obj.layers = new THREE.Layers();
      obj.layers.mask = src.layers.mask;
    }
    obj.visible = src.visible;
    obj.castShadow = src.castShadow;
    obj.receiveShadow = src.receiveShadow;
    obj.frustumCulled = src.frustumCulled;
    obj.renderOrder = src.renderOrder;

    obj.userData = JSON.parse(JSON.stringify(src.userData));
  }

  private static findAll<T>(
    obj: THREE.Object3D,
    predicate: (child: any) => boolean
  ): T[] {
    const list = new Array<T>();
    obj.traverse((child: unknown) => {
      if (predicate(child)) {
        list.push(child as T);
      }
    });
    return list;
  }

  private static cloneRobot(src: URDFRobot): URDFRobot {
    const obj: URDFRobot = URDFUtils.makeEmptyURDFRobot();

    this.handleThreeProperties(src, obj);

    obj.isURDFRobot = src.isURDFRobot;

    obj.urdfRobotNode = src.urdfRobotNode;
    obj.robotName = src.robotName;

    obj.links = {};
    obj.joints = {};
    obj.colliders = {};
    obj.visual = {};
    obj.frames = {};

    URDFUtils.handleChildren(src, obj);

    URDFUtils.updateJointsLinksFramesColliders(obj);

    Object.setPrototypeOf(obj, Object.getPrototypeOf(src));

    return obj;
  }

  public static updateJointsLinksFramesColliders(
    src: URDFRobot,
    target: URDFRobot | undefined = undefined
  ): void {
    const receiver = target ?? src;
    const linkList = URDFUtils.findAll<URDFLink>(src, URDFUtils.isURDFLink);
    for (const link of linkList) {
      if (link.name !== '') {
        receiver.links[link.name] = link;
        receiver.frames[link.name] = link;
      }
    }

    const jointList = URDFUtils.findAll<URDFJoint>(src, URDFUtils.isURDFJoint);
    for (const joint of jointList) {
      if (joint.name !== '') {
        receiver.joints[joint.name] = joint;
        receiver.frames[joint.name] = joint;
      }
    }

    const colliderList = URDFUtils.findAll<URDFCollider>(
      src,
      URDFUtils.isURDFCollider
    );
    for (const collider of colliderList) {
      if (collider.name !== '') {
        receiver.colliders[collider.name] = collider;
      }
    }

    const visualList = URDFUtils.findAll<URDFVisual>(
      src,
      URDFUtils.isURDFVisual
    );
    for (const visual of visualList) {
      if (visual.name !== '') {
        receiver.visual[visual.name] = visual;
      }
    }
  }

  private static cloneJoint(src: URDFJoint): URDFJoint {
    const obj: any = new THREE.Object3D();

    this.handleThreeProperties(src, obj);

    obj.isURDFJoint = src.isURDFJoint;

    obj.urdfNode = src.urdfNode;
    obj.axis = src.axis;
    obj.jointType = src.jointType;
    obj.limit = src.limit;
    obj.ignoreLimits = src.ignoreLimits;

    obj.jointValue = [...src.jointValue];

    URDFUtils.handleChildren(src, obj);

    Object.setPrototypeOf(obj, Object.getPrototypeOf(src));

    return obj;
  }

  private static cloneLink(src: URDFLink): URDFLink {
    const obj: any = new THREE.Object3D();

    this.handleThreeProperties(src, obj);

    obj.isURDFLink = src.isURDFLink;

    obj.urdfNode = src.urdfNode;

    URDFUtils.handleChildren(src, obj);

    Object.setPrototypeOf(obj, Object.getPrototypeOf(src));

    return obj;
  }

  private static cloneVisual(src: URDFVisual): URDFVisual {
    const obj: any = new THREE.Object3D();

    this.handleThreeProperties(src, obj);

    obj.isURDFVisual = src.isURDFVisual;

    obj.urdfNode = src.urdfNode;

    URDFUtils.handleChildren(src, obj);

    Object.setPrototypeOf(obj, Object.getPrototypeOf(src));

    return obj;
  }

  private static cloneCollider(src: URDFCollider): URDFCollider {
    const obj: any = new THREE.Object3D();

    this.handleThreeProperties(src, obj);

    obj.isURDFCollider = src.isURDFCollider;

    obj.urdfNode = src.urdfNode;

    URDFUtils.handleChildren(src, obj);

    Object.setPrototypeOf(obj, Object.getPrototypeOf(src));

    return obj;
  }

  static findVisualFromJoint(joint: URDFJoint): URDFVisual | undefined {
    let visual;
    if (joint) {
      for (const jointChild of joint.children) {
        if (URDFUtils.isURDFLink(jointChild)) {
          for (const linkChild of jointChild.children) {
            if (URDFUtils.isURDFVisual(linkChild)) {
              visual = linkChild;
            }
          }
        }
      }
    }
    return visual;
  }

  static findLinkBeforeMountJoint(root: any): URDFLink | undefined {
    return this.findMountJoint(root)?.parent as URDFLink;
  }

  static findMountJoint(root: any): URDFJoint | undefined {
    let mountJoint;
    if (root) {
      root.traverse((child: any) => {
        if (child.name === 'mount_joint') {
          mountJoint = child;
        }
      });
    }
    return mountJoint;
  }

  static findLinkFromJoint(root: URDFJoint): URDFLink | undefined {
    for (const child of root.children) {
      if (URDFUtils.isURDFLink(child)) {
        return child;
      }
    }
    return undefined;
  }

  static findPartVisual(root: THREE.Object3D): any {
    let visual;
    root.traverse((child) => {
      if (this.isURDFVisual(child)) {
        visual = child;
      }
    });
    return visual;
  }

  static getJoint(robot: URDFRobot, id: JointNames): URDFJoint {
    const lookup = URDFUtils.jointLookup.find((info) => info.tag === id);
    return robot.joints[lookup.name];
  }
  static getLink(robot: URDFRobot, id: LinkNames): URDFLink {
    const lookup = URDFUtils.linkLookup.find((info) => info.tag === id);
    return robot.links[lookup.name];
  }

  /**
   * @property name = Name of joint inside the URDF.
   * @property tag = Simplified nickname.
   * @property type = PartType this joint is related to.
   */
  static readonly jointLookup = [
    {
      name: 'world_frame_joint',
      tag: JointNames.WorldFrame,
      type: PartType.FRAME,
    },
    {
      name: 'frame_liftkit_joint',
      tag: JointNames.FrameLiftkit,
      type: PartType.LIFTKIT,
    },
    {
      name: 'liftkit_basebracket_joint',
      tag: JointNames.LiftkitBasebracket,
      type: PartType.BASE_BRACKET,
    },
    {
      name: 'bracket_base_link_joint',
      tag: JointNames.BasebracketRobotbase,
      type: PartType.ROBOT,
    },
    {
      name: 'tool0_offset_bracket_joint',
      tag: JointNames.ToolmountOffsetBracket,
      type: PartType.OFFSET_BRACKET,
    },
    {
      name: 'gripper_joint',
      tag: JointNames.OffsetBracketGripper,
      type: PartType.GRIPPER,
    },
    { name: 'p1_joint', tag: JointNames.PalletRight, type: PartType.PALLET },
    {
      name: 'p1_lip_joint',
      tag: JointNames.PalletRightLip,
      type: PartType.PALLET_LIP,
    },
    { name: 'p2_joint', tag: JointNames.PalletLeft, type: PartType.PALLET },
    {
      name: 'p2_lip_joint',
      tag: JointNames.PalletLeftLip,
      type: PartType.PALLET_LIP,
    },
    {
      name: 'world_joint_conveyor',
      tag: JointNames.WorldPrimaryConveyor,
      type: PartType.CONVEYOR,
    },
    {
      name: 'conveyor_boxFreeHeight_joint',
      tag: JointNames.ConveyorBoxFreeHeight,
      type: PartType.NONE,
    },
    {
      name: 'conveyor_guide_x_offset_joint',
      tag: JointNames.ConveyorGuideOffsetX,
      type: PartType.NONE,
    },
    {
      name: 'conveyor_guide_joint',
      tag: JointNames.ConveyorGuide,
      type: PartType.CONVEYOR_GUIDE,
    },
    {
      name: 'conveyor_box_joint',
      tag: JointNames.ConveyorBox,
      type: PartType.NONE,
    },
    {
      name: 'world_joint_secondary_conveyor',
      tag: JointNames.WorldSecondaryConveyor,
      type: PartType.CONVEYOR,
    },
    {
      name: 'secondary_conveyor_boxFreeHeight_joint',
      tag: JointNames.SecondaryConveyorBoxFreeHeight,
      type: PartType.NONE,
    },
    {
      name: 'secondary_conveyor_guide_x_offset_joint',
      tag: JointNames.SecondaryConveyorGuideOffsetX,
      type: PartType.NONE,
    },
    {
      name: 'secondary_conveyor_guide_joint',
      tag: JointNames.SecondaryConveyorGuide,
      type: PartType.CONVEYOR_GUIDE,
    },
    {
      name: 'secondary_conveyor_box_joint',
      tag: JointNames.SecondaryConveyorBox,
      type: PartType.NONE,
    },
    { name: 'wrist_3_link', tag: JointNames.Tool0, type: PartType.NONE },
    {
      name: 'world_pally_joint',
      tag: JointNames.SceneJoint,
      type: PartType.SCENE,
    },
  ];

  /**
   * @property name = Name of joint inside the URDF.
   * @property tag = Simplified nickname.
   */
  static readonly linkLookup = [
    { name: 'simconfig_view_robot', tag: LinkNames.World },
    { name: 'base_bracket', tag: LinkNames.Basebracket },
    { name: 'wrist_3_link', tag: LinkNames.RobotWrist3 },
    { name: 'tool0', tag: LinkNames.RobotTool0 },
    { name: 'offset_bracket', tag: LinkNames.OffsetBracket },
    { name: 'gripper', tag: LinkNames.Gripper },
    { name: 'conveyor_mock', tag: LinkNames.ConveyorMock },
    { name: 'conveyor_boxFreeHeight', tag: LinkNames.ConveyorBoxFreeHeight },
    { name: 'guide_offsetx', tag: LinkNames.ConveyorGuideOffsetX },
    { name: 'guide', tag: LinkNames.ConveyorGuide },
    { name: 'conveyor_box', tag: LinkNames.ConveyorBox },
    { name: 'secondary_conveyor_mock', tag: LinkNames.SecondaryConveyorMock },
    {
      name: 'secondary_conveyor_boxFreeHeight',
      tag: LinkNames.SecondaryConveyorBoxFreeHeight,
    },
    {
      name: 'secondary_guide_offsetx',
      tag: LinkNames.SecondaryConveyorGuideOffsetX,
    },
    { name: 'secondary_guide', tag: LinkNames.SecondaryConveyorGuide },
    { name: 'secondary_conveyor_box', tag: LinkNames.ConveyorBox },
    { name: 'p1', tag: LinkNames.PalletRight },
    { name: 'p1_lip', tag: LinkNames.PalletRightLip },
    { name: 'p2', tag: LinkNames.PalletLeft },
    { name: 'p2_lip', tag: LinkNames.PalletLeftlip },
    { name: 'pally_link', tag: LinkNames.Scene },
  ];
}
