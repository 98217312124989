<ng-container *ngIf="subnavView.view$ | async as view">
  <ng-container
    *appVar="subnavView.customBoxIndexLayer$ | async as overrideIndex"
  >
    <div [ngSwitch]="view">
      <div *ngSwitchCase="pagesPATH.PATTERNEDIT">
        <mat-list role="list" style="height: 60vh">
          <div class="col-md-12 py-3 d-flex flex-column h-100">
            <mat-list-item role="listitem">
              <button
                class="col-md-12"
                matTooltip="Add new box"
                mat-raised-button
                color="primary"
                (click)="subnavView.addNewBox()"
              >
                <mat-icon>add</mat-icon>
                New box
              </button>
            </mat-list-item>

            <mat-list-item role="listitem">
              <button
                class="col-md-4"
                matTooltip="Mirror pattern vertically"
                mat-icon-button
                color="primary"
                (click)="subnavView.mirrorPatternVert()"
              >
                <mat-icon>swap_vert</mat-icon>
              </button>
              <button
                class="col-md-4"
                matTooltip="Mirror pattern horizontally"
                mat-icon-button
                color="primary"
                (click)="subnavView.mirrorPatternHoriz()"
              >
                <mat-icon>swap_horiz</mat-icon>
              </button>
              <button
                class="col-md-4"
                matTooltip="Rotate pattern"
                mat-icon-button
                color="primary"
                (click)="subnavView.rotatePattern()"
              >
                <mat-icon>rotate_90_degrees_ccw</mat-icon>
              </button>
            </mat-list-item>

            <mat-list-item role="listitem" class="mb-5">
              <button
                class="col-md-6"
                matTooltip="Align pattern vertically"
                mat-icon-button
                color="primary"
                (click)="subnavView.alignPatternVert()"
              >
                <mat-icon>vertical_align_center</mat-icon>
              </button>
              <button
                class="col-md-6"
                matTooltip="Align pattern horizontally"
                mat-icon-button
                color="primary"
                (click)="subnavView.alignPatternHoriz()"
              >
                <mat-icon style="transform: rotate(90deg)"
                  >vertical_align_center</mat-icon
                >
              </button>
            </mat-list-item>

            <mat-list-item role="listitem"> </mat-list-item>

            <mat-list-item role="listitem" class="mt-auto">
              <button
                (click)="subnavView.undoChange$.next(true)"
                class="col-md-6"
                mat-icon-button
                color="primary"
                matTooltip="Undo last change"
              >
                <mat-icon>undo</mat-icon>
              </button>

              <button
                (click)="subnavView.redoChange$.next(true)"
                class="col-md-6"
                mat-icon-button
                color="primary"
                matTooltip="Redo last change"
              >
                <mat-icon>redo</mat-icon>
              </button>
            </mat-list-item>

            <mat-list-item role="listitem">
              <button
                class="col-md-12"
                mat-stroked-button
                color="primary"
                [matMenuTriggerFor]="settings"
              >
                <mat-icon>settings</mat-icon>
                Settings
              </button>

              <mat-menu #settings="matMenu" yPosition="above">
                <button mat-menu-item (click)="$event.stopPropagation()">
                  <mat-slide-toggle
                    #invPlacement
                    [checked]="subnavView.inverseApproach$ | async"
                    (change)="changeApproach(invPlacement.checked)"
                    >Inverted Approach
                  </mat-slide-toggle>
                </button>
                <button mat-menu-item (click)="$event.stopPropagation()">
                  <mat-slide-toggle
                    #boxPositions
                    [checked]="subnavView.patternBoxPositions$ | async"
                    (change)="
                      subnavView.patternBoxPositions$.next(boxPositions.checked)
                    "
                    >Show Selected Box Positions
                  </mat-slide-toggle>
                </button>
                <button
                  mat-menu-item
                  (click)="$event.stopPropagation()"
                  *appVar="
                    subnavView.customBoxIndexLayer$ | async as overrideIndex
                  "
                >
                  <mat-slide-toggle
                    #boxPositions
                    [checked]="overrideIndex"
                    (change)="toggleCustomIndexLayer(overrideIndex)"
                    >Customize box index
                  </mat-slide-toggle>
                </button>
              </mat-menu>
            </mat-list-item>
          </div>
        </mat-list>
      </div>

      <div *ngSwitchCase="pagesPATH.BOXEDIT">
        <mat-list role="list" style="height: 60vh">
          <div class="col-md-12 py-3 d-flex flex-column h-100">
            <mat-list-item role="listitem" class="mb-5">
              <button
                class="col-md-12"
                mat-stroked-button
                color="primary"
                matTooltip="Release selected boxes"
                (click)="unselectBoxes()"
              >
                <mat-icon> deselect</mat-icon>
                Deselect
              </button>
            </mat-list-item>
            <mat-list-item
              role="listitem"
              class="number-field"
              *ngIf="isFieldReady$ | async"
            >
              <div
                [matTooltip]="
                  overrideIndex ? null : 'Enable custom box index setting'
                "
              >
                <app-field
                  [field]="indexField"
                  [disabled]="!overrideIndex"
                  [class.disabled]="!overrideIndex"
                >
                </app-field>
              </div>
            </mat-list-item>
            <mat-list-item role="listitem">
              <button
                class="col-md-12"
                matTooltip="Rotate box"
                mat-stroked-button
                color="primary"
                (click)="subnavView.patternRotateBoxes$.next(true)"
              >
                <mat-icon>rotate_90_degrees_ccw</mat-icon>
                Rotate
              </button>
            </mat-list-item>

            <mat-list-item role="listitem">
              <button
                class="col-md-12"
                matTooltip="Mirror box"
                mat-stroked-button
                color="primary"
                (click)="subnavView.patternMirrorBoxes$.next(true)"
              >
                <mat-icon style="transform: rotate(90deg)"
                  >vertical_align_center</mat-icon
                >
                Mirror
              </button>
            </mat-list-item>

            <mat-list-item role="listitem">
              <button
                class="col-md-12"
                mat-stroked-button
                color="primary"
                matTooltip="Advanced options"
                [matMenuTriggerFor]="advanced"
              >
                <mat-icon>tune</mat-icon>

                Advanced
              </button>

              <mat-menu #advanced="matMenu" [overlapTrigger]="true">
                <mat-list role="list" class="p-4">
                  <mat-list-item role="listitem">
                    <button
                      class="col-md-12"
                      matTooltip="Change box direction"
                      mat-stroked-button
                      color="primary"
                      (click)="
                        subnavView.patternChangeBoxOrientation$.next(true);
                        $event.stopPropagation()
                      "
                    >
                      Lock direction
                      <mat-icon
                        style="transform: scale(-1, 1); margin-left: 5px"
                        >lock</mat-icon
                      >
                    </button>
                  </mat-list-item>

                  <mat-list-item role="listitem">
                    <button
                      class="col-md-12"
                      matTooltip="Stop multiple pick"
                      mat-stroked-button
                      color="primary"
                      (click)="
                        $event.stopPropagation();
                        subnavView.patternChangeStopMultiplePick$.next(true)
                      "
                    >
                      Stop multiple pick
                      <mat-icon
                        style="transform: scale(-1, 1); margin-left: 5px"
                        >pan_tool</mat-icon
                      >
                    </button>
                  </mat-list-item>

                  <mat-list-item
                    role="listitem"
                    *ngIf="
                      subnavView.patternEnforcedGripperOrientationOptions$
                        | async as boxGripperOrientations
                    "
                  >
                    <button
                      (click)="$event.stopPropagation()"
                      class="col-md-12"
                      mat-stroked-button
                      color="primary"
                      matTooltip="Set enfroced gripper orientation"
                      [matMenuTriggerFor]="gripperOrientation"
                    >
                      Gripper orientation
                      <mat-icon
                        style="transform: scale(-1, 1); margin-left: 5px"
                        >screen_rotation</mat-icon
                      >
                    </button>

                    <mat-menu
                      #gripperOrientation="matMenu"
                      [overlapTrigger]="true"
                    >
                      <mat-checkbox
                        *ngFor="let orientation of boxGripperOrientations"
                        (click)="
                          $event.stopPropagation();
                          orientation.selected = !orientation.selected;
                          changeGripperOrientation(
                            boxGripperOrientations,
                            orientation
                          )
                        "
                        [checked]="orientation.selected"
                        class="mat-menu-item"
                      >
                        {{ orientation.name }}
                      </mat-checkbox>
                    </mat-menu>
                  </mat-list-item>
                </mat-list>
              </mat-menu>
            </mat-list-item>

            <mat-list-item role="listitem">
              <button
                class="col-md-12"
                matTooltip="Remove box"
                mat-stroked-button
                color="warn"
                (click)="subnavView.patternDeleteBoxes$.next(true)"
              >
                <mat-icon>delete</mat-icon>
                Remove
              </button>
            </mat-list-item>

            <mat-list-item role="listitem" class="mt-auto">
              <button
                (click)="subnavView.undoChange$.next(true)"
                class="col-md-6"
                mat-icon-button
                color="primary"
                matTooltip="Undo last change"
              >
                <mat-icon>undo</mat-icon>
              </button>

              <button
                (click)="subnavView.redoChange$.next(true)"
                class="col-md-6"
                mat-icon-button
                color="primary"
                matTooltip="Redo last change"
              >
                <mat-icon>redo</mat-icon>
              </button>
            </mat-list-item>

            <mat-list-item role="listitem">
              <button
                class="col-md-12"
                mat-stroked-button
                color="primary"
                [matMenuTriggerFor]="settings"
              >
                <mat-icon>settings</mat-icon>
                Settings
              </button>
              <mat-menu #settings="matMenu" yPosition="above">
                <button mat-menu-item (click)="$event.stopPropagation()">
                  <mat-slide-toggle
                    #invPlacement
                    [checked]="subnavView.inverseApproach$ | async"
                    (change)="changeApproach(invPlacement.checked)"
                    >Inverted Approach
                  </mat-slide-toggle>
                </button>
                <button mat-menu-item (click)="$event.stopPropagation()">
                  <mat-slide-toggle
                    #boxPositions
                    [checked]="subnavView.patternBoxPositions$ | async"
                    (change)="
                      subnavView.patternBoxPositions$.next(boxPositions.checked)
                    "
                    >Show Selected Box Positions
                  </mat-slide-toggle>
                </button>
                <button mat-menu-item (click)="$event.stopPropagation()">
                  <mat-slide-toggle
                    #boxPositions
                    [checked]="overrideIndex"
                    (change)="toggleCustomIndexLayer(overrideIndex)"
                    >Customize box index
                  </mat-slide-toggle>
                </button>
              </mat-menu>
            </mat-list-item>
          </div>
        </mat-list>
      </div>

      <div *ngSwitchCase="pagesPATH.EXPORT_IMPORT">
        <p>Export import</p>
      </div>

      <div *ngSwitchDefault></div>
    </div>
  </ng-container>
</ng-container>
