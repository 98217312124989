import { Injectable, OnDestroy } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, Observable, Subject } from 'rxjs';
import { ObjectUtils } from '../../utils/object';
import { ClientApiService } from './client-api.service';
import {
  ApiPallyPathStrategy,
  IApiPallyPathStrategy,
} from 'src/app/models_new/classes/api-models/ApiPallyPathStrategy';
import { IPallyPathStrategy } from 'src/app/models_new/types/pally-path-strategy';

@Injectable({
  providedIn: 'root',
})
export class PallyPathStrategyApiService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private clientApi: ClientApiService) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * @method fetchPathStrategyById
   * @desc Fetches a Pally Path Strategy aka Pally Program Setting by ID.
   * @param {string} id - The ID of the Pally Path Strategy to fetch.
   * @returns An Observable that emits the fetched Pally Path Strategy.
   */
  public fetchPathStrategyById(id: string): Observable<ApiPallyPathStrategy> {
    const q = gql`
      query FetchPallyPathStrategy {
        pally_path_strategy_by_pk(id: "${id}") {
          id
          name
          description
          organization {
            id
            name
          }
          pally_path_strategy_waypoints_generations {
            id
            name
          }
          data
          created_at
          updated_at
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .query<{ pally_path_strategy_by_pk: IApiPallyPathStrategy }>({
        query: q,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else {
            return new ApiPallyPathStrategy(
              ObjectUtils.cloneObject(data.data?.pally_path_strategy_by_pk)
            );
          }
        })
      );
  }

  /**
   * @method fetchPallyPathStrategies
   * @desc Fetches PallyPath strategies from the API.
   * @param org_id - The organization ID (optional). If provided, only strategies from that org will be fetched.
   * @param type - Level of detail to fetch. 'lite' fetches only the basic info, 'full' fetches all info.
   * @returns An Observable that emits an array of ApiPallyPathStrategy objects.
   */
  public fetchPallyPathStrategies(
    org_id?: string,
    type: 'lite' | 'full' | 'inventory' = 'lite'
  ): Observable<ApiPallyPathStrategy[]> {
    const ql = gql`
      subscription GetPallyPathStrategies {
        pally_path_strategy${
          org_id ? `(where: {organization_id: {_eq: "${org_id}"}})` : ''
        } {
          id
          name
          description
          created_at
          updated_at
          box_free_height: data(path: "path_planning.box_free")
          box_free_auto: data(path: "path_planning.box_free_auto")
          approach: data(path:"path_planning.approach")
          approach_auto: data(path:"path_planning.approach_auto")
          gripper_rotation: data(path:"gripper.grip_rot")
          max_grip: data(path:"gripper.max_grip") 
          organization {
            id
            name
          }
        }
      }
    `;
    const q = gql`
      subscription GetPallyPathStrategies {
        pally_path_strategy${
          org_id ? `(where: {organization_id: {_eq: "${org_id}"}})` : ''
        } {
          id
          name
          description
          data
          created_at
          updated_at
          organization {
            id
            name
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: type === 'lite' || type === 'inventory' ? ql : q,
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else {
            const list = [];
            for (const s of data.data.pally_path_strategy) {
              list.push(new ApiPallyPathStrategy(ObjectUtils.cloneObject(s)));
            }
            return list;
          }
        })
      );
  }

  public insertPallyPathStrategy(
    org_id: string,
    name: string,
    data: any
  ): Observable<IApiPallyPathStrategy> {
    const q = gql`
      mutation InsertPallyPathStrategy(
        $organization_id: uuid!
        $name: String!
        $data: jsonb!
      ) {
        insert_pally_path_strategy_one(
          object: {
            organization_id: $organization_id
            name: $name
            data: $data
          }
        ) {
          id
          name
        }
      }
    `;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: q,
        variables: {
          organization_id: org_id,
          name: name,
          data: data,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else return data.data.insert_pally_path_strategy_one;
        })
      );
  }

  public updatePallyPathStrategy(
    id: string,
    name: string,
    data: IPallyPathStrategy
  ): Observable<IApiPallyPathStrategy> {
    const q = gql`
      mutation UpdatePallyPathStrategy(
        $id: uuid!
        $name: String!
        $data: jsonb!
      ) {
        update_pally_path_strategy_by_pk(
          pk_columns: { id: $id }
          _set: { name: $name, data: $data }
        ) {
          id
          name
        }
      }
    `;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: q,
        variables: {
          id: id,
          name: name,
          data: data,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else {
            return data.data.update_pally_path_strategy_by_pk;
          }
        })
      );
  }

  public deletePallyPathStrategy(id: string): Observable<string> {
    const q = gql`
      mutation DeletePallyPathStrategy($id: uuid!) {
        delete_pally_path_strategy_by_pk(id: $id) {
          id
        }
      }
    `;
    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: q,
        variables: {
          id: id,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else {
            return data.data.delete_pally_path_strategy_by_pk;
          }
        })
      );
  }
}
