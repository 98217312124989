import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingComponent {
  /** @param {string} message - The message shown under the loading animation */
  @Input() message?: string;
  /** @param {number} number - Sets the size of the loading animation icon */
  @Input() size: number = 100;
  /** @param {boolean} WhiteColor - Sets the color of the loading animation icon to white */
  @Input() whiteColor?: boolean;

  defaultMessage = '';
}
