import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ITableAction } from '../table/table.component';

@Component({
  selector: 'app-sub-menu',
  templateUrl: './sub-menu.component.html',
  styleUrls: ['./sub-menu.component.scss'],
})
export class SubMenuComponent<ActionType> {
  @Input() items: ITableAction<ActionType>[];
  @Output() actionEmit: EventEmitter<ActionType> = new EventEmitter();
  @ViewChild('childMenu', { static: true }) public childMenu: any;

  clickedAction(val) {
    this.actionEmit.emit(val.actionId);
  }
}
