import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule, DatePipe } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BreadcrumbNavComponent } from '../components/gui/breadcrumb-nav/breadcrumb-nav.component';
import { ButtonComponent } from '../components/gui/button/button.component';
import { CardListComponent } from '../components/gui/card-list/card-list.component';
import { CheckmarkComponent } from '../components/gui/checkmark/checkmark.component';
import { FieldComponent } from '../components/gui/field/field.component';
import { FiltersComponent } from '../components/gui/filters/filters.component';
import { InfoCardComponent } from '../components/gui/info-card/info-card.component';
import { ListOverviewCardComponent } from '../components/gui/list-overview-card/list-overview-card.component';
import { RadioCheckComponent } from '../components/gui/radio-check/radio-check.component';
import { SectionHeaderLineComponent } from '../components/gui/section-header-line/section-header-line.component';
import { StepperRadioComponent } from '../components/gui/stepper-radio/stepper-radio.component';
import { TableComponent } from '../components/gui/table/table.component';
import { TableExpandableComponent } from '../components/gui/table-expandable/table-expandable.component';
import { SubMenuComponent } from '../components/gui/sub-menu/sub-menu.component';
import { AdvancedNavbarComponent } from '../components/patterns/pattern/pallet/advanced-navbar/advanced-navbar.component';
import { MaterialModule } from './material.module';
import { ThreeModule } from './three.module';
import { HardwareConfigurationCardComponent } from '../components/gui/hardware-configuration-card/hardware-configuration-card.component';
import { DonutChartComponent } from '../components/gui/donut-chart/donut-chart.component';
import { LicenseComponent } from '../components/dialogs/license/license.component';
import { RenewLicenseComponent } from '../components/dialogs/renew-license/renew-license.component';
import { ProgressSidebarComponent } from '../components/gui/progress-sidebar/progress-sidebar.component';
import { LightButtonToggleComponent } from '../components/gui/light-button-toggle/light-button-toggle.component';
import { SplitCardComponent } from '../components/gui/split-card/split-card.component';
import { CardRobotConfigListComponent } from '../components/gui/card-robot-config-list/card-robot-config-list.component';
import { CardRobotConfigComponent } from '../components/gui/card-robot-config/card-robot-config.component';
import { CardPatternConfigComponent } from '../components/gui/card-pattern-config/card-pattern-config.component';
import { CardHardwareDisplayComponent } from '../components/gui/card-hardware-display/card-hardware-display.component';
import { CardSoftwareDisplayComponent } from '../components/gui/card-software-display/card-software-display.component';
import { PageComponent } from '../components/gui/page/page.component';
import { EmptyComponent } from '../components/gui/empty/empty.component';
import { LoadingComponent } from '../components/gui/loading/loading.component';
import { TrustPipe } from '../pipes/trust.pipe';
import {
  DataRequestComponent,
  OnDataLoadedDirective,
} from '../components/gui/data-request/data-request.component';
import { OnDataDirective } from '../components/gui/data-request/on-data.directive';
import {
  HeaderActionsComponent,
  HeaderComponent,
  HeaderUnderlineActionsComponent,
} from '../components/gui/header/header.component';
import { BurgerMenuComponent } from '../components/gui/burger-menu/burger-menu.component';
import { HardwareCardComponent } from '../components/gui/hardware-card/hardware-card.component';
import { RequestStatePipe } from 'src/app/pipes/request-state.pipe';
import { IfCommandAvailableDirective } from '../directives/if-command-available.directive';
import { NotificationMembersComponent } from '../components/gui/notification-members/notification-members.component';
import { CommentsComponent } from '../components/gui/comments/comments.component';
import { CommentComponent } from '../components/gui/comments/comment/comment.component';
import { CommentReplyComponent } from '../components/gui/comments/comment-reply/comment-reply.component';
import { CompanyLogoComponent } from '../components/gui/company-logo/company-logo.component';
import { SlideshowComponent } from '../components/gui/slideshow/slideshow.component';
import { SplitCardTableComponent } from '../components/gui/split-card-table/split-card-table.component';
import { ExitButtonComponent } from '../components/gui/exit-button/exit-button.component';
import { PublicShareCardComponent } from '../components/gui/public-share-card/public-share-card.component';
import { GenericInventoryPickerComponent } from '../components/gui/generic-inventory-picker/generic-inventory-picker.component';
import { CardPickerListComponent } from '../components/gui/card-picker-list/card-picker-list.component';
import { CardPatternViewComponent } from '../components/gui/card-pattern-view/card-pattern-view.component';
import { AwaitingComponent } from '../components/gui/awaiting/awaiting.component';
import {
  AppCardSubtitleComponent,
  CardTargetComponent,
} from '../components/gui/card-target/card-target.component';
import { InfoPopupComponent } from '../components/dialogs/info-popup/info-popup.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SplitPipe } from '../pipes/split.pipe';
import { CustomerEditCardComponent } from '../components/customers/customer-edit-card/customer-edit-card.component';
import { NewProductCardComponent } from '../components/products/product/new-product-card/new-product-card.component';
import { NewPatternMakerCardComponent } from '../components/patterns/pattern/pattern-maker-card/pattern-maker-card.component';
import { CustomPalletDialogComponent } from '../components/dialogs/custom-pallet-dialog/custom-pallet-dialog.component';
import { PatternInfoComponent } from '../components/patterns/pattern/pattern-info/pattern-info.component';
import { ConfigPresetPickerComponent } from '../components/gui/config-preset-picker/config-preset-picker.component';
import { UnitSystemPipe } from '../pipes/unit-system.pipe';
import { UnitSystemLabelPipe } from '../pipes/unit-system-label.pipe';
import { CardListWrapperComponent } from '../components/gui/card-list-wrapper/card-list-wrapper.component';
import {
  FilterMenuComponent,
  SortMenuComponent,
} from '../components/gui/sorting/sorting.component';
import { CardInvitationComponent } from '../components/gui/card-invitation/card-invitation.component';
import { ContentSwitcherComponent } from '../components/gui/content-switcher/content-switcher.component';
import { TestViewComponent } from '../components/gui/test-view/test-view.component';
import { RichtextDocumentToHtmlPipe } from '../pipes/richtext-to-html.pipe';
import { PdfPreviewComponent } from '../components/dialogs/pdf-preview/pdf-preview.component';
import { GoogleAnalyticsDirective } from '../directives/analytics.directive';
import { ImportFileComponent } from '../components/gui/import-file/import-file.component';
import { ImportFileDirective } from '../components/gui/import-file/import-file.directive';
import { ImportFileDialogComponent } from '../components/dialogs/import-file-dialog/import-file-dialog.component';
import { LandingToolbarComponent } from '../components/gui/landing-toolbar/landing-toolbar.component';
import { CaptchaDialogComponent } from '../components/dialogs/captcha-dialog/captcha-dialog.component';
import { IllegalOrganizationSubscriptionComponent } from '../components/dialogs/illegal-organization-subscription/illegal-organization-subscription.component';
import { StatusMessagesComponent } from '../components/status-messages/status-messages.component';
import { PromptDeletionComponent } from '../components/prompt-deletion/prompt-deletion.component';
import { CustomGripperComponent } from '../components/dialogs/custom-gripper/custom-gripper.component';
import { IrLicenseComponent } from '../components/dialogs/ir-license/ir-license.component';
import { EditPageComponent } from '../components/gui/edit-page/edit-page.component';
import { VNCComponent } from '../components/vnc/vnc.component';
import { RouterLink } from '@angular/router';
import { PalletReportComponent } from '../components/patterns/pattern/pattern-report/pallet-report.component';
import { InfoErrorBadgeComponent } from '../components/gui/info-error-badge/info-error-badge.component';
import { SaveEditedDataDialogComponent } from '../components/dialogs/save-edited-data-dialog/save-edited-data-dialog.component';
import { SelectableCardComponent } from '../components/gui/selectable-card/selectable-card.component';
import { WizardBottomNavbarComponent } from '../components/gui/wizard-bottom-navbar/wizard-bottom-navbar.component';
import { SoftwareDetailsComponent } from '../components/inventory/software/software-details/software-details.component';
import { PromptUploadComponent } from '../components/prompt-upload/prompt-upload.component';
import { CardSolutionEditComponent } from '../components/gui/card-solution-edit/card-solution-edit.component';
import { DialogBottomNavbarComponent } from '../components/gui/dialog-bottom-navbar/dialog-bottom-navbar.component';
import { RemoveLodashPipe } from '../pipes/remove-lodash.pipe';
import { ToIndexArrayPipe } from '../pipes/toIndexArray.pipe';
import { MainSidenavComponent } from '../components/gui/main-sidenav/main-sidenav.component';
import { SimulationsTableComponent } from '../components/gui/tables/simulations-table/simulations-table.component';
import { WaypointsTableComponent } from '../components/gui/tables/waypoints-table/waypoints-table.component';
import { InventoryTableComponent } from '../components/gui/inventory-table/inventory-table.component';
import { HardwareCardListComponent } from '../components/inventory/hardware-library/hardware-card-list/hardware-card-list.component';
import { MiscAssetsModule } from './assets/misc-assets.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    HttpClientModule,
    DragDropModule,
    ReactiveFormsModule,
    ThreeModule,
    NgxCaptchaModule,
    RouterLink,
    MiscAssetsModule,
  ],
  declarations: [
    SoftwareDetailsComponent,
    ButtonComponent,
    CheckmarkComponent,
    LicenseComponent,
    RenewLicenseComponent,
    FieldComponent,
    StepperRadioComponent,
    AdvancedNavbarComponent,
    LightButtonToggleComponent,
    SectionHeaderLineComponent,
    InfoCardComponent,
    ListOverviewCardComponent,
    CardListComponent,
    BreadcrumbNavComponent,
    BurgerMenuComponent,
    ConfigPresetPickerComponent,
    TableComponent,
    InventoryTableComponent,
    TableExpandableComponent,
    SubMenuComponent,
    FiltersComponent,
    RadioCheckComponent,
    HardwareConfigurationCardComponent,
    DonutChartComponent,
    ProgressSidebarComponent,
    SplitCardComponent,
    CardRobotConfigListComponent,
    CardRobotConfigComponent,
    CardPatternConfigComponent,
    PageComponent,
    MainSidenavComponent,
    HeaderComponent,
    HeaderActionsComponent,
    HeaderUnderlineActionsComponent,
    CardHardwareDisplayComponent,
    CardSoftwareDisplayComponent,
    SplitCardTableComponent,
    EmptyComponent,
    LoadingComponent,
    HardwareCardComponent,
    TrustPipe,
    DataRequestComponent,
    OnDataDirective,
    OnDataLoadedDirective,
    RequestStatePipe,
    RemoveLodashPipe,
    SplitPipe,
    IfCommandAvailableDirective,
    NotificationMembersComponent,
    CommentsComponent,
    CommentComponent,
    CommentReplyComponent,
    CompanyLogoComponent,
    SlideshowComponent,
    ExitButtonComponent,
    PublicShareCardComponent,
    GenericInventoryPickerComponent,
    CardPickerListComponent,
    CardPatternViewComponent,
    CardSolutionEditComponent,
    AwaitingComponent,
    CardTargetComponent,
    AppCardSubtitleComponent,
    InfoPopupComponent,
    CaptchaDialogComponent,
    CardInvitationComponent,
    ContentSwitcherComponent,
    CardListWrapperComponent,
    CustomerEditCardComponent,
    NewProductCardComponent,
    NewPatternMakerCardComponent,
    CustomPalletDialogComponent,
    PatternInfoComponent,
    ContentSwitcherComponent,
    HeaderUnderlineActionsComponent,
    ConfigPresetPickerComponent,
    CardInvitationComponent,
    FilterMenuComponent,
    SortMenuComponent,
    TestViewComponent,
    RichtextDocumentToHtmlPipe,
    PdfPreviewComponent,
    GoogleAnalyticsDirective,
    ImportFileComponent,
    ImportFileDirective,
    ImportFileDialogComponent,
    LandingToolbarComponent,
    IrLicenseComponent,
    IllegalOrganizationSubscriptionComponent,
    StatusMessagesComponent,
    PromptDeletionComponent,
    PromptUploadComponent,
    CustomGripperComponent,
    UnitSystemPipe,
    UnitSystemLabelPipe,
    EditPageComponent,
    HardwareCardListComponent,
    VNCComponent,
    InfoErrorBadgeComponent,
    PalletReportComponent,
    SaveEditedDataDialogComponent,
    SelectableCardComponent,
    WizardBottomNavbarComponent,
    DialogBottomNavbarComponent,
    ToIndexArrayPipe,
    SimulationsTableComponent,
    WaypointsTableComponent,
  ],
  exports: [
    SoftwareDetailsComponent,
    ButtonComponent,
    CheckmarkComponent,
    LicenseComponent,
    RenewLicenseComponent,
    FieldComponent,
    StepperRadioComponent,
    AdvancedNavbarComponent,
    LightButtonToggleComponent,
    SectionHeaderLineComponent,
    InfoCardComponent,
    ListOverviewCardComponent,
    CardListComponent,
    BreadcrumbNavComponent,
    TableComponent,
    InventoryTableComponent,
    TableExpandableComponent,
    FiltersComponent,
    SubMenuComponent,
    RadioCheckComponent,
    HardwareConfigurationCardComponent,
    DonutChartComponent,
    ProgressSidebarComponent,
    SplitCardComponent,
    CardRobotConfigListComponent,
    CardRobotConfigComponent,
    CardPatternConfigComponent,
    PageComponent,
    MainSidenavComponent,
    HeaderComponent,
    HeaderActionsComponent,
    HeaderUnderlineActionsComponent,
    CardHardwareDisplayComponent,
    CardSoftwareDisplayComponent,
    SplitCardTableComponent,
    EmptyComponent,
    LoadingComponent,
    BurgerMenuComponent,
    ConfigPresetPickerComponent,
    HardwareCardComponent,
    TrustPipe,
    DataRequestComponent,
    OnDataDirective,
    OnDataLoadedDirective,
    RequestStatePipe,
    RemoveLodashPipe,
    SplitPipe,
    IfCommandAvailableDirective,
    NotificationMembersComponent,
    CommentsComponent,
    CommentComponent,
    CommentReplyComponent,
    CompanyLogoComponent,
    SlideshowComponent,
    ExitButtonComponent,
    PublicShareCardComponent,
    GenericInventoryPickerComponent,
    CardPickerListComponent,
    CardPatternViewComponent,
    CardSolutionEditComponent,
    AwaitingComponent,
    CardTargetComponent,
    AppCardSubtitleComponent,
    InfoPopupComponent,
    CaptchaDialogComponent,
    CardInvitationComponent,
    ContentSwitcherComponent,
    CardListWrapperComponent,
    CustomerEditCardComponent,
    NewProductCardComponent,
    NewPatternMakerCardComponent,
    CustomPalletDialogComponent,
    PatternInfoComponent,
    ContentSwitcherComponent,
    HeaderUnderlineActionsComponent,
    ConfigPresetPickerComponent,
    CardInvitationComponent,
    FilterMenuComponent,
    TestViewComponent,
    RichtextDocumentToHtmlPipe,
    PdfPreviewComponent,
    GoogleAnalyticsDirective,
    ImportFileComponent,
    ImportFileDirective,
    LandingToolbarComponent,
    IrLicenseComponent,
    IllegalOrganizationSubscriptionComponent,
    StatusMessagesComponent,
    PromptDeletionComponent,
    PromptUploadComponent,
    UnitSystemPipe,
    UnitSystemLabelPipe,
    EditPageComponent,
    HardwareCardListComponent,
    VNCComponent,
    PalletReportComponent,
    SelectableCardComponent,
    WizardBottomNavbarComponent,
    DialogBottomNavbarComponent,
    ToIndexArrayPipe,
    SimulationsTableComponent,
    WaypointsTableComponent,
  ],
  providers: [
    TrustPipe,
    IfCommandAvailableDirective,
    UnitSystemPipe,
    UnitSystemLabelPipe,
    EditPageComponent,
    HardwareCardListComponent,
    VNCComponent,
    PalletReportComponent,
    DatePipe,
  ],
})
export class SharedModule {}
