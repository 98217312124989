import cloneDeep from 'lodash-es/cloneDeep';
import sortBy from 'lodash-es/sortBy';
import orderBy from 'lodash-es/orderBy';
import isEqual from 'lodash-es/isEqual';
import differenceWith from 'lodash-es/differenceWith';
import every from 'lodash-es/every';
import get from 'lodash/get';
import merge from 'lodash/merge';

export class ObjectUtils {
  static getNested<T>(data: T, path: string) {
    return get(data, path);
  }

  /**
   * @param object // to clone
   * @returns cloned object
   */
  static cloneObject<T>(object: T): T {
    return cloneDeep(object);
  }

  static sortyBy<T>(array: T[]): T[] {
    return sortBy(array);
  }

  static orderBy<T>(
    array: T[],
    key: string[],
    direction: Array<'asc' | 'desc'>
  ): T[] {
    return orderBy(array, key, direction);
  }

  static isEqual(a: any, b: any): boolean {
    return isEqual(a, b);
  }

  static hasAlikeValues(array, values) {
    return differenceWith(array, values, isEqual);
  }

  static allIsTrue(array, value) {
    return every(array, value);
  }

  static mergeObjects<T>(object: T, source: T): T {
    return merge(object, source);
  }
}
