import { IPallyPathStrategy } from '../../types/pally-path-strategy';

export const defaultPallyPathStrategy: IPallyPathStrategy = {
  path_planning: {
    above_pickup: 0.1,
    box_free: 0.3,
    box_free_auto: true,
    smart_exit: {
      auto: true,
      p1: {
        min_x: 0.1,
        max_x: 0.7,
        min_y: 0.0,
        max_y: 0.4,
      },
      p2: {
        min_x: 0.1,
        max_x: 0.7,
        min_y: 0.0,
        max_y: 0.4,
      },
    },
    pallet_edge: 0.0,
    approach: 0.03,
    approach_auto: true,
    high_approach_boost: 50,
    collision_radius_min: 0.15,
    collision_radius_max: 0.35,
    quick_return: true,
  },
  gripper: {
    min_grip_quality: 0.3,
    grip_rot: 4,
    max_grip: 2,
    foam_height: 0.02,
    length_coverage: 0.2,
    grip_rot_cost: [0, 30, 10, 10],
    align_to_edges: true,
  },
  lifting_column: {
    max_stroke: 0.9,
    min_stroke: 0,
    lift_wait_time: 1.0,
    lift_safe_up: 0.05,
    lift_safe_down: 0.05,
    lift_alt_boost: 1,
    lift_alt_boost_auto: true,
    lift_optimistic: true,
  },
};
