import { Env } from '../../typings';

declare const require: any;
declare const $ENV: Env;

// TSC cannot find this type from the auth0 package, so is redefined here.
type CacheLocation = 'localstorage' | 'memory';

export const environment = {
  name: 'production',
  version: require('../../package.json').version,
  title: 'MyRobot.cloud | Palletizing Robot Management System | Rocketfarm',
  development: false, // Watermark GUI
  production: true, // Angular build prod
  auth0: {
    domain: 'login.myrobot.cloud',
    clientId: 'vZrLXxEhzyyP3kfK6S7y5RxOTrAvsLdY',
    useRefreshTokens: true,
    response_type: 'token id_token',
    scope: 'openid profile email',
    cacheLocation: 'localstorage' as CacheLocation,
    authorizationParams: {
      audience: 'https://palletizer-v2.rocketfarm.no',
      redirect_uri: `${window.location.origin}/user/callback`,
    },
  },
  boo: '42b56fb1-2e4e-4459-b09e-6a451d491eb3',
  googleAnalyticsGtag: $ENV.ANALYTICS_TAG,
  grapqlURL: $ENV.GRAPHQL_URL,
  grapqlWebSocketURL: $ENV.GRAPHQL_WS_URL,
  pipedriveKey: $ENV.PIPEDRIVE_KEY,
  backendPipedriveUpdatePersonURL: $ENV.BACKEND_PIPEDRIVE_UPDATE_PERSON_URL,
  pdfReportURL: $ENV.PDF_REPORT_URL,
  pdfReportParams: $ENV.PDF_REPORT_PARAMS,
  pdfReportTemplate: $ENV.PDF_REPORT_TEMPLATE_ID,
  learnMoreFallbackUrl: $ENV.LEARN_MORE_FALLBACK_URL,
  reCaptchaSiteKey: $ENV.RECAPTCHA_SITE_KEY,
  quoteReqestActionKey: $ENV.QUOTE_REQUEST_ACTION_KEY,
  backofficeOrgs: $ENV.BACKOFFICE_ORGS,
  atlassianServiceWidgetKey: $ENV.ATLASSIAN_SERVICE_WIDGET_KEY,
  contentfulLandingPageId: $ENV.CONTENTFUL_LANDING_PAGE_ID,
  datadogSiteKey: $ENV.DATADOG_SITE_KEY,
  datadogSiteUrl: $ENV.DATADOG_SITE_URL,
  datadogService: $ENV.DATADOG_SERVICE,
};
