import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { PresetPickerData } from '../../../models_new/types/preset-picker-data';
import { PickerType } from '../../../models_new/enums/picker-type';
import { PickerComponent } from '../../dialogs/picker/picker.component';
import { MatDialog } from '@angular/material/dialog';
import { DialogSize } from '../../../models_new/enums/dialogSize';
import { DialogService } from '../../../services/dialog.service';
import { HardwareDialogComponent } from '../../dialogs/hardware-dialog/hardware-dialog.component';
import { SimConfigService } from '../../../services/sim-config.service';
import { ThreeHandlerMode } from '../../../models_new/classes/3dview/three-handler-mode';
import { take } from 'rxjs';
import { ApiStrategy } from 'src/app/models_new/classes/api-models/ApiStrategy';
import { ApiScene } from 'src/app/models_new/classes/api-models/ApiScene';
import { NotificationService } from 'src/app/services/notification.service';
import { Router } from '@angular/router';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { EditSoftwareDialogComponent } from '../../dialogs/edit-software-dialog/edit-software-dialog.component';

export type PresetType =
  | 'hardware'
  | 'software'
  | 'smart exit'
  | 'safety'
  | 'product';

@Component({
  selector: 'app-config-preset-picker',
  templateUrl: './config-preset-picker.component.html',
  styleUrls: ['./config-preset-picker.component.scss'],
})
export class ConfigPresetPickerComponent implements OnInit, OnChanges {
  @Input() presetType: PresetType;
  @Input() presets: PresetPickerData[] = [];
  @Input() disableAdd: boolean = false;
  @Input() disableInventoryPick: boolean = false;
  @Input() disableCards: boolean = false;
  @Input() isDialog: boolean = false;

  threeMode: ThreeHandlerMode = ThreeHandlerMode.RobotConfig;

  @Output() pickerSelect: EventEmitter<string> = new EventEmitter();
  @Output() optmizationSelect: EventEmitter<{
    newVal: string;
    defaultVal: string;
  }> = new EventEmitter();

  selectedId: string;

  constructor(
    private dialog: MatDialog,
    private dialogService: DialogService,
    private simConfigService: SimConfigService,
    private notify: NotificationService,
    private router: Router
  ) {}

  ngOnInit(): void {
    if (this.presets) {
      for (let preset of this.presets) {
        if (this.presetType === 'hardware') {
          this.load3dView(preset.id);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    // Scroll to position when the preset are loaded
    if (changes.presets && changes.presets.currentValue) {
      if (!this.selectedId) {
        this.selectedId = this.presets.find((f) => f.selected)?.id;
      }

      setTimeout(
        () => {
          this.scrollToCard(this.selectedId);
        },
        this.presetType === 'hardware' ? 100 : 1000
      );
    }
  }

  scrollToCard(id: string) {
    const selectedPreset = document.getElementById(
      'preset-' + id + '-' + this.presetType
    );

    if (selectedPreset) {
      selectedPreset.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'start',
      });
    }
  }

  private emitSelection() {
    this.presets.forEach((preset) => (preset.selected = false));
    this.pickerSelect.emit(this.selectedId);
  }

  pickerClick(id: string) {
    if (this.selectedId === id) {
      this.selectedId = null;
    } else {
      this.selectedId = id;
    }
    this.emitSelection();
  }

  createNew() {
    let dialog;
    if (this.presetType === 'software') {
      if (this.isDialog) {
        dialog = this.dialogService.showCustomDialog(
          EditSoftwareDialogComponent,
          DialogSize.LARGE,
          null,
          {
            sw_id: 'new',
          },
          true
        );
      } else {
        this.router.navigate(
          [pagesPATH.INVENTORY, pagesPATH.SOFTWARE_LIST, 'new'],
          {
            queryParams: {
              origin:
                pagesPATH.INVENTORY +
                '/' +
                pagesPATH.ROBOT_CONFIGURATIONS +
                '/new?software=true',
            },
          }
        );
      }
    } else if (this.presetType === 'product') {
      this.router.navigate([pagesPATH.PRODUCTS, 'new'], {
        queryParams: {
          origin: pagesPATH.PATTERNS + '/new',
        },
      });
    } else {
      dialog = this.dialogService.showCustomDialog(
        HardwareDialogComponent,
        DialogSize.LARGE,
        null,
        {
          hw_id: 'new',
        },
        true
      );
    }
    dialog
      ?.afterClosed()
      .pipe(take(1))
      .subscribe((config) => {
        if (config) {
          this.addConfig(config);
        }
      });
  }

  showInventoryPicker() {
    let type;
    if (this.presetType === 'hardware') {
      type = PickerType.HARDWARE_CONFIGURATION;
    }
    if (this.presetType === 'software') {
      type = PickerType.SOFTWARE_CONFIGURATION;
    }
    if (this.presetType === 'product') {
      type = PickerType.PRODUCT;
    }

    const picker = this.dialog.open(PickerComponent, {
      data: {
        type: type,
        selectionAmount: 'SELECT_SINGLE',
      },
      width: 'calc(100vw - 2em)',
      height: 'calc(100vh - 2em)',
    });
    picker
      .afterClosed()
      .pipe(take(1))
      .subscribe((configs) => {
        if (configs) {
          this.addConfig(configs[0]);
        }
      });
  }

  private addConfig(config: ApiScene | ApiStrategy) {
    if (this.presets.map((m) => m.id).includes(config.id)) {
      this.notify.showMessage('Already added...');
      return;
    } else {
      let presetImg = '../../../../assets/illustrative/simulation.png';

      if (config.image?.url) {
        // if existing preset with already stored image url
        presetImg = config.image?.url;
      } else if (config?.updatedImage) {
        // if new preset, image is recieved as base64 in object
        // (asset is uploaded after page render)
        presetImg = config?.updatedImage;
      }

      let pallyVersion;
      if (
        config instanceof ApiStrategy ||
        (this.presetType === 'software' && this.isDialog)
      ) {
        pallyVersion = config.data['software']
          ? config.data['software']?.pally_version
          : undefined;
      }

      this.presets.unshift({
        id: config.id,
        label: config.name,
        image: presetImg,
        pallyVersion: pallyVersion ? pallyVersion : null,
      });
    }
    this.selectedId = config.id;
    this.emitSelection();
    if (this.presetType === 'hardware') {
      this.load3dView(config.id);
    }
  }

  private load3dView(id: string) {
    const threeID = 'config-preset-picker-' + id;
    this.simConfigService
      .configureScene(id, undefined, undefined, false, threeID)
      .subscribe();
  }
}
