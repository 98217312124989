import { Component, ElementRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import RFB from '@novnc/novnc/core/rfb.js';
import { switchMap, take } from 'rxjs';
import {
  IUrSimContainerAsset,
  IUrSimContainerAssets,
  IUrSimContainerStarted,
  InstalledRobotsApiService,
} from 'src/app/services/api/robot-installations-api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { FileUtils } from 'src/app/utils/file-utils';

@Component({
  selector: 'app-vnc',
  templateUrl: './vnc.component.html',
  styleUrls: ['./vnc.component.scss'],
})
export class VNCComponent {
  constructor(
    private robotsApi: InstalledRobotsApiService,
    private notify: NotificationService
  ) {}

  state: 'new' | 'existing';
  title = 'vnc-client';
  assets: IUrSimContainerAssets = {
    installations: [],
    others: [],
    programs: [],
  };

  public rfb: RFB;
  @ViewChild('file') fileClick: ElementRef;

  formGroupNewClient = new FormGroup({
    polyscope: new FormControl('6.0.0', [Validators.required]),
    robot: new FormControl('UR10', [Validators.required]),
  });

  formGroupClient = new FormGroup({
    host: new FormControl('', [Validators.required]),
    port: new FormControl('', [Validators.required]),
    password: new FormControl(''),
    path: new FormControl('vnc.html'),
  });

  getNewClientControlValue(controlName: 'polyscope' | 'robot'): string {
    return this.formGroupNewClient.get(controlName).value;
  }

  getClientControlValue(
    controlName: 'host' | 'port' | 'password' | 'path'
  ): string | number {
    return this.formGroupClient.get(controlName).value;
  }

  startUrSimContainer() {
    this.robotsApi
      .startUrSimContainer(
        this.getNewClientControlValue('polyscope'),
        this.getNewClientControlValue('robot')
      )
      .pipe(take(1))
      .subscribe({
        next: (res: IUrSimContainerStarted) => {
          this.formGroupClient
            .get('host')
            .setValue(res.ip, { emitEvent: false });
          this.formGroupClient
            .get('port')
            .setValue(res.port, { emitEvent: false });
          this.startClient();
        },
        error: (err) => {
          this.notify.showError(err);
        },
      });
  }

  startClient() {
    // Remove existing connection of any;
    console.debug('Starting !!!');
    // Read parameters specified in the URL query string
    // By default, use the host and port of server that served this file
    const host = this.getClientControlValue('host');
    const port = this.getClientControlValue('port');
    const password = this.getClientControlValue('password'); // password of your vnc server
    const path = this.getClientControlValue('path');
    // Build the websocket URL used to connect
    let url = 'ws';

    if (window.location.protocol === 'https:') {
      url = 'wss';
    } else {
      url = 'ws';
    }

    url += '://' + host;
    if (port) {
      url += ':' + port;
    }
    url += '/' + path;

    console.debug('URL: ', url);

    // Creating a new RFB object will start a new connection
    /**
     * https://novnc.com/noVNC/docs/API.html
     */
    this.rfb = new RFB(document.getElementById('screen'), url, {
      credentials: { password: password },
    });

    this.getAssets();
  }

  getAssets() {
    this.robotsApi
      .getUrSimAssets()
      .pipe(take(1))
      .subscribe({
        next: (assets: IUrSimContainerAssets) => {
          this.assets = assets;
        },
        error: (err) => {
          this.notify.showError(err.message);
        },
      });
  }

  uploadFileEvent(event: any) {
    let fileType = event.target.files[0].name;
    fileType = fileType.substring(
      fileType.lastIndexOf('.') + 1,
      fileType.length
    );

    FileUtils.uploadFile(event)
      .pipe(
        take(1),
        switchMap((file: string) => {
          const withoutMeta = file.substring(
            file.lastIndexOf('base64,') + 7,
            file.length
          );
          return this.robotsApi.insertUrSimAsset(
            withoutMeta,
            fileType,
            `.${fileType}`,
            'test',
            '9309a9e7-e041-45d2-b3bf-bf1e8b9a114f'
          );
        })
      )
      .subscribe({
        next: (res) => {
          console.debug('Asset added:', res);
          this.getAssets();
        },
        error: (err) => this.notify.showError(err),
      });
  }

  uploadFile() {
    this.fileClick.nativeElement.value = '';
    this.fileClick.nativeElement.click();
  }

  downloadFile(asset: IUrSimContainerAsset) {
    FileUtils.downloadFile(asset.url, asset.name);
  }

  disconnect() {
    this.rfb ? this.rfb.disconnect() : null;
    this.rfb = null;
  }

  screenshot() {
    FileUtils.downloadFile(this.rfb.toDataURL(), 'vnc-screenshot');
  }

  // sendClipboardData() {
  //   this.rfb.clipboardPasteFrom('text');
  // }

  rebootMachine() {
    this.rfb.machineReboot();
  }
}
