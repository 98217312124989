import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-highlighted-message-card',
  templateUrl: './highligted-message-card.component.html',
  styleUrls: ['./highligted-message-card.component.scss'],
})
export class HighligtedMessageCardComponent implements OnInit {
  @Input() type: 'info' | 'warn' | 'error' = 'error';
  @Input() title: string;
  @Input() message: string;
  @Input() icon?: string;
  @Input() solidBackground?: boolean;

  ngOnInit(): void {
    // If not set, add an appropriate icon for the message type.
    if (!this.icon) {
      switch (this.type) {
        case 'info':
          this.icon = 'info';
          break;
        case 'warn':
          this.icon = 'warning';
          break;
        case 'error':
          this.icon = 'error';
          break;
      }
    }
  }
}
