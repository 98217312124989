import { Validators } from '@angular/forms';
import { ConveyorDirection } from '../../enums/sim-config-conveyor-dir';
import { SimConfigFieldIds } from '../../enums/simconfig-field-ids';
import { FieldType } from '../../types/field-type';
import { ISimApiFormConfig } from '../../types/sim-api-form-config';
import { conveyorDirections } from '../form_fields';
import { FieldUpdateFn } from './sim-config-update-functions';
import { IHwSwType } from '../../types/robot-config/hw-sw-type';

export type OffsetBracketType = 'None' | '5 cm' | '10 cm' | '15 cm';

export const simConfigSceneFields: ISimApiFormConfig[] = [
  /* ------------------------------- Info fields ------------------------------ */
  {
    label: 'Hardware configuration name',
    id: SimConfigFieldIds.Name,
    validators: [],
    type: FieldType.TEXT,
    required: true,
    defaultValue: '',
  },
  {
    label: 'Show dimensions',
    id: SimConfigFieldIds.ShowDimensions,
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: true,
    defaultValue: false,
    suffix: '',
    hint: '',
    disabled: false,
  },

  /* ------------------------------ Frame fields ------------------------------ */
  {
    label: 'Frame type',
    id: SimConfigFieldIds.FrameType,
    validators: [],
    type: FieldType.SELECT_SINGLE_OBJECT,
    optionsType: 'frame',
    required: true,
    defaultValue: '',
    suffix: '',
    showProperty: 'label',
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.PalletRightPositionX,
        fnOutput: 'right_posX',
      },
      {
        id: SimConfigFieldIds.PalletLeftPositionX,
        fnOutput: 'left_posX',
      },
      {
        id: SimConfigFieldIds.PalletSideOffset,
        fnOutput: 'right_posX',
      },
      {
        id: SimConfigFieldIds.PalletRightPositionY,
        fnOutput: 'posY',
      },
      {
        id: SimConfigFieldIds.PalletLeftPositionY,
        fnOutput: 'posY',
      },
      {
        id: SimConfigFieldIds.PalletEndOffset,
        fnOutput: 'posY',
      },
      {
        id: SimConfigFieldIds.PalletLeftPositionZ,
        fnOutput: 'posZ',
      },
      {
        id: SimConfigFieldIds.PalletRightPositionZ,
        fnOutput: 'posZ',
      },
      {
        id: SimConfigFieldIds.PalletVerticalOffset,
        fnOutput: 'posZ',
      },
    ],
    updatesFieldFn: FieldUpdateFn.frame,
  },

  /* ----------------------------- Liftkit fields ----------------------------- */
  {
    label: 'Lifting column type',
    id: SimConfigFieldIds.LiftkitType,
    validators: [],
    type: FieldType.SELECT_SINGLE_OBJECT,
    optionsType: 'liftkit',
    required: true,
    defaultValue: '',
    suffix: '',
    showProperty: 'label',
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.LiftkitMaxStroke,
        fnOutput: 'run_func', // Just to get the updatesFieldFn to run
      },
    ],
    updatesFieldFn: (value: IHwSwType) => value.metadata?.stroke,
  },
  {
    label: 'Lifting column max stroke',
    id: SimConfigFieldIds.LiftkitMaxStroke,
    validators: [Validators.min(0), Validators.max(3000)],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 900,
    suffix: 'mm',
  },

  /* --------------------------- Basebracket fields --------------------------- */
  {
    label: 'Robot mounting bracket',
    id: SimConfigFieldIds.BasebracketHeight,
    validators: [Validators.min(0)],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    suffix: 'mm',
    errorMsgs: new Map<string, string>([['min', 'Minimum height is 0!']]),
  },
  {
    label: 'Robot mounting rotation',
    id: SimConfigFieldIds.BasebracketRotation,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    suffix: '',
  },

  /* ------------------------------ Robot fields ------------------------------ */
  {
    label: 'Robot type',
    id: SimConfigFieldIds.RobotType,
    validators: [],
    type: FieldType.SELECT_SINGLE_OBJECT,
    optionsType: 'robot',
    required: true,
    defaultValue: '',
    suffix: '',
    showProperty: 'label',
  },

  /* -------------------------- Offsetbracket fields -------------------------- */
  {
    label: 'Offsetbracket type',
    id: SimConfigFieldIds.OffsetBracketType,
    validators: [],
    type: FieldType.SELECT_SINGLE,
    options: ['None', '5 cm', '10 cm', '15 cm'] as OffsetBracketType[],
    required: false,
    defaultValue: 'None',
    suffix: '',
    showProperty: 'label',
    updatesFieldFn: FieldUpdateFn.bracket_type,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.OffsetBracketLength,
        fnOutput: 'length',
      },
      {
        id: SimConfigFieldIds.OffsetBracketWidth,
        fnOutput: 'width',
      },
      {
        id: SimConfigFieldIds.OffsetBracketHeight,
        fnOutput: 'height',
      },
      {
        id: SimConfigFieldIds.OffsetBracketOffsetX,
        fnOutput: 'offX',
      },
      {
        id: SimConfigFieldIds.OffsetBracketOffsetY,
        fnOutput: 'offY',
      },
      {
        id: SimConfigFieldIds.OffsetBracketOffsetZ,
        fnOutput: 'offZ',
      },
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        fnOutput: 'add',
      },
    ],
  },
  {
    label: 'Add offsetbracket',
    id: SimConfigFieldIds.AddOffsetBracket,
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    suffix: '',
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.OffsetBracketWidth,
        skipIf: [
          {
            id: SimConfigFieldIds.AddOffsetBracket,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value,
          },
        ],
      },
      {
        id: SimConfigFieldIds.OffsetBracketLength,
        skipIf: [
          {
            id: SimConfigFieldIds.AddOffsetBracket,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value,
          },
        ],
      },
      {
        id: SimConfigFieldIds.OffsetBracketHeight,
        skipIf: [
          {
            id: SimConfigFieldIds.AddOffsetBracket,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value,
          },
        ],
      },
      {
        id: SimConfigFieldIds.OffsetBracketPositionX,
        skipIf: [
          {
            id: SimConfigFieldIds.AddOffsetBracket,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value,
          },
        ],
      },
      {
        id: SimConfigFieldIds.OffsetBracketPositionY,
        skipIf: [
          {
            id: SimConfigFieldIds.AddOffsetBracket,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value,
          },
        ],
      },
      {
        id: SimConfigFieldIds.OffsetBracketPositionZ,
        skipIf: [
          {
            id: SimConfigFieldIds.AddOffsetBracket,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value,
          },
        ],
      },
      {
        id: SimConfigFieldIds.OffsetBracketOffsetX,
        skipIf: [
          {
            id: SimConfigFieldIds.AddOffsetBracket,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value,
          },
        ],
      },
      {
        id: SimConfigFieldIds.OffsetBracketOffsetY,
        skipIf: [
          {
            id: SimConfigFieldIds.AddOffsetBracket,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value,
          },
        ],
      },
      {
        id: SimConfigFieldIds.OffsetBracketOffsetZ,
        skipIf: [
          {
            id: SimConfigFieldIds.AddOffsetBracket,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value,
          },
        ],
      },
    ],
  },
  {
    label: 'Offsetbracket width',
    id: SimConfigFieldIds.OffsetBracketWidth,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
      {
        id: SimConfigFieldIds.OffsetBracketAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Offsetbracket length',
    id: SimConfigFieldIds.OffsetBracketLength,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
      {
        id: SimConfigFieldIds.OffsetBracketAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Offsetbracket height',
    id: SimConfigFieldIds.OffsetBracketHeight,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
      {
        id: SimConfigFieldIds.OffsetBracketAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Offsetbracket advanced',
    id: SimConfigFieldIds.OffsetBracketAdvanced,
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
    ],
  },
  {
    label: 'Offsetbracket position x',
    id: SimConfigFieldIds.OffsetBracketPositionX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Position on x axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
      {
        id: SimConfigFieldIds.OffsetBracketAdvanced,
        value: 'I will hide this field forever!',
      },
    ],
  },
  {
    label: 'Offsetbracket position y',
    id: SimConfigFieldIds.OffsetBracketPositionY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Position on y axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
      {
        id: SimConfigFieldIds.OffsetBracketAdvanced,
        value: 'I will hide this field forever!',
      },
    ],
  },
  {
    label: 'Offsetbracket position z',
    id: SimConfigFieldIds.OffsetBracketPositionZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Position on z axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
      {
        id: SimConfigFieldIds.OffsetBracketAdvanced,
        value: 'I will hide this field forever!',
      },
    ],
  },
  {
    label: 'Offsetbracket offset x',
    id: SimConfigFieldIds.OffsetBracketOffsetX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Offset on x axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
      {
        id: SimConfigFieldIds.OffsetBracketAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Offsetbracket offset y',
    id: SimConfigFieldIds.OffsetBracketOffsetY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Offset on y axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
      {
        id: SimConfigFieldIds.OffsetBracketAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Offsetbracket offset z',
    id: SimConfigFieldIds.OffsetBracketOffsetZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Offset on z axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.AddOffsetBracket,
        value: true,
      },
      {
        id: SimConfigFieldIds.OffsetBracketAdvanced,
        value: true,
      },
    ],
  },

  /* ----------------------------- Gripper fields ----------------------------- */
  {
    label: 'Gripper type',
    id: SimConfigFieldIds.GripperType,
    validators: [],
    type: FieldType.SELECT_SINGLE_OBJECT,
    optionsType: 'gripper',
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    showProperty: 'label',
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.GripperCustomWidth,
        skipIf: [
          {
            id: SimConfigFieldIds.GripperType,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value.name != 'CUSTOM',
          },
        ],
      },
      {
        id: SimConfigFieldIds.GripperCustomLength,
        skipIf: [
          {
            id: SimConfigFieldIds.GripperType,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value.name != 'CUSTOM',
          },
        ],
      },
      {
        id: SimConfigFieldIds.GripperCustomHeight,
        skipIf: [
          {
            id: SimConfigFieldIds.GripperType,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value.name != 'CUSTOM',
          },
        ],
      },
      {
        id: SimConfigFieldIds.GripperCustomPositionX,
        skipIf: [
          {
            id: SimConfigFieldIds.GripperType,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value.name != 'CUSTOM',
          },
        ],
      },
      {
        id: SimConfigFieldIds.GripperCustomPositionY,
        skipIf: [
          {
            id: SimConfigFieldIds.GripperType,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value.name != 'CUSTOM',
          },
        ],
      },
      {
        id: SimConfigFieldIds.GripperCustomPositionZ,
        skipIf: [
          {
            id: SimConfigFieldIds.GripperType,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value.name != 'CUSTOM',
          },
        ],
      },
      {
        id: SimConfigFieldIds.GripperCustomRotationR,
        skipIf: [
          {
            id: SimConfigFieldIds.GripperType,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value.name != 'CUSTOM',
          },
        ],
      },
      {
        id: SimConfigFieldIds.GripperCustomRotationP,
        skipIf: [
          {
            id: SimConfigFieldIds.GripperType,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value.name != 'CUSTOM',
          },
        ],
      },
      {
        id: SimConfigFieldIds.GripperCustomRotationY,
        skipIf: [
          {
            id: SimConfigFieldIds.GripperType,
            // eslint-disable-next-line eqeqeq
            conditionFn: (value: any) => value.name != 'CUSTOM',
          },
        ],
      },
    ],
  },
  {
    label: 'Gripper width',
    id: SimConfigFieldIds.GripperCustomWidth,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Width of offset bracket',
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperType,
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Gripper length',
    id: SimConfigFieldIds.GripperCustomLength,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Length of offset bracket',
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperType,
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Gripper height',
    id: SimConfigFieldIds.GripperCustomHeight,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Height of offset bracket',
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperType,
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Advanced gripper',
    id: SimConfigFieldIds.GripperAdvanced,
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperType,
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Gripper position x',
    id: SimConfigFieldIds.GripperCustomPositionX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Position on x axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Gripper position y',
    id: SimConfigFieldIds.GripperCustomPositionY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Position on y axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Gripper position z',
    id: SimConfigFieldIds.GripperCustomPositionZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: 'Position on z axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Gripper rotation r',
    id: SimConfigFieldIds.GripperCustomRotationR,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: 'Rotation on r axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Gripper rotation p',
    id: SimConfigFieldIds.GripperCustomRotationP,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: 'Rotation on p axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Gripper rotation y',
    id: SimConfigFieldIds.GripperCustomRotationY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: 'Rotation on z axis',
    enabledByFields: [
      {
        id: SimConfigFieldIds.GripperAdvanced,
        value: true,
      },
    ],
  },

  /* -------------------------- Lite Pallet distance -------------------------- */
  {
    label: 'Robot - Pallet side offset',
    id: SimConfigFieldIds.PalletSideOffset,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 330,
    suffix: 'mm',
    hint: '',
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.PalletRightPositionX,
        fnOutput: 'right',
      },
      {
        id: SimConfigFieldIds.PalletLeftPositionX,
        fnOutput: 'left',
      },
    ],
    updatesFieldFn: (value: number, fieldId: 'right' | 'left') =>
      value * (fieldId === 'left' ? -1 : 1),
  },
  {
    label: 'Robot - Pallet end offset',
    id: SimConfigFieldIds.PalletEndOffset,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    suffix: 'mm',
    hint: '',
    disabled: false,
    /*
      For some reason fnOutput and updatesFieldFn have to be defined although
      they shouldn't have to be. The code runs like normal when they're not
      defined, but the fields for some reason still doesn't update. 

      TODO: Look into this at a later stage.
    */
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.PalletRightPositionY,
        fnOutput: 'left',
      },
      {
        id: SimConfigFieldIds.PalletLeftPositionY,
        fnOutput: 'left',
      },
    ],
    updatesFieldFn: (v: any) => v,
  },
  {
    label: 'Robot - Pallet vertical offset',
    id: SimConfigFieldIds.PalletVerticalOffset,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    suffix: 'mm',
    hint: '',
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.PalletRightPositionZ,
        fnOutput: 'right',
      },
      {
        id: SimConfigFieldIds.PalletLeftPositionZ,
        fnOutput: 'left',
      },
    ],
    updatesFieldFn: (v: any) => v,
  },

  /* ------------------------------ Right pallet fields ----------------------------- */
  {
    label: 'Pallet name',
    id: SimConfigFieldIds.PalletRightName,
    validators: [],
    type: FieldType.TEXT,
    required: true,
    defaultValue: 'p1',
    suffix: '',
    hint: '',
    disabled: true,
  },
  {
    label: 'Pallet advanced',
    id: SimConfigFieldIds.PalletRightAdvanced,
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    suffix: '',
    hint: '',
    disabled: false,
  },
  {
    label: 'Right pallet - robot distance',
    id: SimConfigFieldIds.PalletRightPositionX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
  },
  {
    label: 'Pallet position y',
    id: SimConfigFieldIds.PalletRightPositionY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    suffix: 'mm',
    hint: '',
    disabled: false,
  },
  {
    label: 'Pallet position z',
    id: SimConfigFieldIds.PalletRightPositionZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet orientation x',
    id: SimConfigFieldIds.PalletRightRotationX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet orientation y',
    id: SimConfigFieldIds.PalletRightRotationY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet orientation z',
    id: SimConfigFieldIds.PalletRightRotationZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet orientation w',
    id: SimConfigFieldIds.PalletRightRotationW,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet lip height',
    id: SimConfigFieldIds.PalletRightLipHeight,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },

  /* ------------------------------ Left fields ----------------------------- */
  {
    label: 'Pallet name',
    id: SimConfigFieldIds.PalletLeftName,
    validators: [],
    type: FieldType.TEXT,
    required: true,
    defaultValue: 'p2',
    suffix: '',
    hint: '',
    disabled: true,
  },
  {
    label: 'Pallet advanced',
    id: SimConfigFieldIds.PalletLeftAdvanced,
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    suffix: '',
    hint: '',
    disabled: false,
  },
  {
    label: 'Left pallet - robot distance',
    id: SimConfigFieldIds.PalletLeftPositionX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
  },
  {
    label: 'Pallet position y',
    id: SimConfigFieldIds.PalletLeftPositionY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: 0,
    suffix: 'mm',
    hint: '',
    disabled: false,
  },
  {
    label: 'Pallet position z',
    id: SimConfigFieldIds.PalletLeftPositionZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet orientation x',
    id: SimConfigFieldIds.PalletLeftRotationX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet orientation y',
    id: SimConfigFieldIds.PalletLeftRotationY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet orientation z',
    id: SimConfigFieldIds.PalletLeftRotationZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet orientation w',
    id: SimConfigFieldIds.PalletLeftRotationW,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Pallet lip height',
    id: SimConfigFieldIds.PalletLeftLipHeight,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.PalletRightAdvanced,
        value: true,
      },
    ],
  },

  /* ----------------------------- Right conveyor fields ---------------------------- */
  {
    label: 'Conveyor type',
    id: SimConfigFieldIds.ConveyorPrimaryType,
    validators: [],
    type: FieldType.SELECT_SINGLE_OBJECT,
    optionsType: 'conveyor',
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    showProperty: 'label',
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryWidth,
        fnOutput: 'dimX',
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryLength,
        fnOutput: 'dimY',
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryHeight,
        fnOutput: 'dimZ',
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryPositionY,
        ignoreUpdateFunction: true,
        fnOutput: 'posY',
      },
      // {
      //   name: FieldIds.ConveyorGuideSide,
      // },
      // {
      //   name: FieldIds.ConveyorGuideWidth,
      // },
      // {
      //   name: FieldIds.ConveyorDirection,
      // },
    ],
    updatesFieldFn: FieldUpdateFn.changeConveyorType,
  },
  {
    label: 'Conveyor name',
    id: SimConfigFieldIds.ConveyorPrimaryName,
    validators: [],
    type: FieldType.TEXT,
    required: true,
    defaultValue: 'conveyor_mock',
    suffix: '',
    hint: '',
    disabled: true,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
        value: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryType,
        value: 'CUSTOM',
      },
      {
        // Will never show, but will activate conveyor_direction
        id: SimConfigFieldIds.ConveyorPrimaryDirection,
        value: !null,
      },
    ],
  },
  {
    label: 'Conveyor advanced',
    id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    suffix: '',
    hint: '',
    disabled: false,
  },
  {
    label: 'Conveyor direction',
    id: SimConfigFieldIds.ConveyorPrimaryDirection,
    validators: [],
    type: FieldType.SELECT_SINGLE_OBJECT,
    options: Array.from(conveyorDirections.values()),
    required: true,
    defaultValue: conveyorDirections.get(ConveyorDirection.FRONT),
    suffix: '',
    hint: '',
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryRotationX,
        fnOutput: 'x',
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryRotationY,
        fnOutput: 'y',
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryRotationZ,
        fnOutput: 'z',
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryRotationW,
        fnOutput: 'w',
      },
    ],
    updatesFieldFn: FieldUpdateFn.changeConveyorDirection,
  },
  {
    label: 'Conveyor width',
    id: SimConfigFieldIds.ConveyorPrimaryWidth,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryType,
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Conveyor length',
    id: SimConfigFieldIds.ConveyorPrimaryLength,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryType,
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Conveyor height',
    id: SimConfigFieldIds.ConveyorPrimaryHeight,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryPositionZ,
        fnOutput: 'Run updatesFieldFn! Run!',
      },
    ],
    updatesFieldFn: (value: any) => value,
  },
  {
    label: 'Conveyor Side Offset',
    id: SimConfigFieldIds.ConveyorPrimaryPositionX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    step: 50,
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Robot - Conveyor Distance',
    id: SimConfigFieldIds.ConveyorPrimaryPositionY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    step: 50,
    suffix: 'mm',
    hint: '',
    disabled: false,
  },
  {
    label: 'Conveyor position z',
    id: SimConfigFieldIds.ConveyorPrimaryPositionZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor rotation x',
    id: SimConfigFieldIds.ConveyorPrimaryRotationX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor rotation y',
    id: SimConfigFieldIds.ConveyorPrimaryRotationY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor rotation z',
    id: SimConfigFieldIds.ConveyorPrimaryRotationZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor rotation w',
    id: SimConfigFieldIds.ConveyorPrimaryRotationW,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor guide position',
    id: SimConfigFieldIds.ConveyorPrimaryGuideSide,
    validators: [],
    type: FieldType.SELECT_SINGLE,
    required: true,
    defaultValue: 'Left',
    options: ['Left', 'Right'],
    suffix: '',
    hint: '',
    disabled: false,
  },
  {
    label: 'Conveyor guide width',
    id: SimConfigFieldIds.ConveyorPrimaryGuideWidth,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
  },
  {
    label: 'Boxes per minute',
    id: SimConfigFieldIds.ConveyorPrimaryPubRate,
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 40,
    suffix: 'box/min',
    hint: '(Pub rate)',
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Speed',
    id: SimConfigFieldIds.ConveyorPrimaryVelocity,
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 0.5,
    suffix: 'm/s',
    hint: '(Velocity)',
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Add at end',
    id: SimConfigFieldIds.ConveyorPrimaryAddAtEnd,
    validators: [],
    type: FieldType.CHECKBOX,
    required: false,
    defaultValue: false,
    suffix: '',
    hint: 'The width of the fixed conveyor (if the conveyor doesn´t have guide this should be the width of the conveyor divided by 2)',
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryType,
        value: 'CUSTOM',
      },
    ],
  },

  /* ----------------------------- Left conveyor fields ---------------------------- */
  {
    label: '',
    id: SimConfigFieldIds.DualProductMode,
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    suffix: '',
    hint: '',
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.ConveyorPrimaryType,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryLength,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryWidth,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryHeight,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryPositionX,
        fnOutput: 'offsetX - primary',
        getFields: [
          // NOTE: The order here is important!
          SimConfigFieldIds.ConveyorPrimaryType,
          SimConfigFieldIds.ConveyorPrimaryPositionX,
        ],
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryPositionY,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryPositionZ,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryRotationX,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryRotationY,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryRotationZ,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryRotationW,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryGuideSide,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryGuideWidth,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryPubRate,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryVelocity,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorPrimaryAddAtEnd,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryType,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryLength,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryWidth,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryHeight,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryPositionX,
        fnOutput: 'offsetX - secondary',
        getFields: [
          // NOTE: The order here is important!
          SimConfigFieldIds.ConveyorSecondaryType,
          SimConfigFieldIds.ConveyorSecondaryPositionX,
        ],
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryPositionY,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryPositionZ,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryRotationX,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryRotationY,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryRotationZ,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryRotationW,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryGuideSide,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryGuideWidth,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryPubRate,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryVelocity,
        ignoreUpdateFunction: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryAddAtEnd,
        ignoreUpdateFunction: true,
      },
    ],
    updatesFieldFn: FieldUpdateFn.changeDualProductMode,
  },
  {
    label: 'Conveyor type',
    id: SimConfigFieldIds.ConveyorSecondaryType,
    validators: [],
    type: FieldType.SELECT_SINGLE_OBJECT,
    optionsType: 'conveyor',
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    showProperty: 'label',
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryWidth,
        fnOutput: 'dimX',
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryLength,
        fnOutput: 'dimY',
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryHeight,
        fnOutput: 'dimZ',
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryPositionY,
        ignoreUpdateFunction: true,
        fnOutput: 'posY',
      },
      // {
      //   name: FieldIds.ConveyorGuideSide,
      // },
      // {
      //   name: FieldIds.ConveyorGuideWidth,
      // },
      // {
      //   name: FieldIds.ConveyorDirection,
      // },
    ],
    updatesFieldFn: FieldUpdateFn.changeConveyorType,
  },
  {
    label: 'Conveyor name',
    id: SimConfigFieldIds.ConveyorSecondaryName,
    validators: [],
    type: FieldType.TEXT,
    required: true,
    defaultValue: 'conveyor_mock',
    suffix: '',
    hint: '',
    disabled: true,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryAdvanced,
        value: true,
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryType,
        value: 'CUSTOM',
      },
      {
        // Will never show, but will activate conveyor_direction
        id: SimConfigFieldIds.ConveyorSecondaryDirection,
        value: !null,
      },
    ],
  },
  {
    label: 'Conveyor advanced',
    id: SimConfigFieldIds.ConveyorSecondaryAdvanced,
    validators: [],
    type: FieldType.SLIDETOGGLE,
    required: false,
    defaultValue: false,
    suffix: '',
    hint: '',
    disabled: false,
  },
  {
    label: 'Conveyor direction',
    id: SimConfigFieldIds.ConveyorSecondaryDirection,
    validators: [],
    type: FieldType.SELECT_SINGLE_OBJECT,
    options: Array.from(conveyorDirections.values()),
    required: true,
    defaultValue: conveyorDirections.get(ConveyorDirection.FRONT),
    suffix: '',
    hint: '',
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryRotationX,
        fnOutput: 'x',
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryRotationY,
        fnOutput: 'y',
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryRotationZ,
        fnOutput: 'z',
      },
      {
        id: SimConfigFieldIds.ConveyorSecondaryRotationW,
        fnOutput: 'w',
      },
    ],
    updatesFieldFn: FieldUpdateFn.changeConveyorDirection,
  },
  {
    label: 'Conveyor width',
    id: SimConfigFieldIds.ConveyorSecondaryWidth,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryType,
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Conveyor length',
    id: SimConfigFieldIds.ConveyorSecondaryLength,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryType,
        value: 'CUSTOM',
      },
    ],
  },
  {
    label: 'Conveyor height',
    id: SimConfigFieldIds.ConveyorSecondaryHeight,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    postUpdatesFieldsOnChange: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryPositionZ,
        fnOutput: 'Run updatesFieldFn! Run!',
      },
    ],
    updatesFieldFn: (value: any) => value,
  },
  {
    label: 'Conveyor Side Offset',
    id: SimConfigFieldIds.ConveyorSecondaryPositionX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    step: 50,
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Robot - Conveyor Distance',
    id: SimConfigFieldIds.ConveyorSecondaryPositionY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    step: 50,
    suffix: 'mm',
    hint: '',
    disabled: false,
  },
  {
    label: 'Conveyor position z',
    id: SimConfigFieldIds.ConveyorSecondaryPositionZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor rotation x',
    id: SimConfigFieldIds.ConveyorSecondaryRotationX,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor rotation y',
    id: SimConfigFieldIds.ConveyorSecondaryRotationY,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor rotation z',
    id: SimConfigFieldIds.ConveyorSecondaryRotationZ,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor rotation w',
    id: SimConfigFieldIds.ConveyorSecondaryRotationW,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    disabled: false,
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryAdvanced,
        value: true,
      },
    ],
  },
  {
    label: 'Conveyor guide position',
    id: SimConfigFieldIds.ConveyorSecondaryGuideSide,
    validators: [],
    type: FieldType.SELECT_SINGLE,
    required: true,
    defaultValue: 'Left',
    options: ['Left', 'Right'],
    suffix: '',
    hint: '',
    disabled: false,
  },
  {
    label: 'Conveyor guide width',
    id: SimConfigFieldIds.ConveyorSecondaryGuideWidth,
    validators: [],
    type: FieldType.NUMBER,
    required: true,
    defaultValue: '',
    suffix: 'mm',
    hint: '',
    disabled: false,
  },
  {
    label: 'Boxes per minute',
    id: SimConfigFieldIds.ConveyorSecondaryPubRate,
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 40,
    suffix: 'box/min',
    hint: '(Pub rate)',
  },
  {
    label: 'Speed',
    id: SimConfigFieldIds.ConveyorSecondaryVelocity,
    validators: [],
    type: FieldType.NUMBER,
    required: false,
    defaultValue: 0.5,
    suffix: 'm/s',
    hint: '(Velocity)',
  },
  {
    label: 'Add at end',
    id: SimConfigFieldIds.ConveyorSecondaryAddAtEnd,
    validators: [],
    type: FieldType.CHECKBOX,
    required: false,
    defaultValue: false,
    suffix: '',
    hint: 'The width of the fixed conveyor (if the conveyor doesn´t have guide this should be the width of the conveyor divided by 2)',
    enabledByFields: [
      {
        id: SimConfigFieldIds.ConveyorSecondaryType,
        value: 'CUSTOM',
      },
    ],
  },

  /* ------------------------------ Scene fields ------------------------------ */
  {
    label: 'Scene theme',
    id: SimConfigFieldIds.ThemeType,
    validators: [],
    type: FieldType.SELECT_SINGLE_OBJECT,
    optionsType: 'theme',
    required: true,
    defaultValue: '',
    suffix: '',
    hint: '',
    showProperty: 'label',
    disabled: false,
  },
  /* ----------------------------- Strategy fields ---------------------------- */
  {
    label: 'Conveyor box free height',
    id: SimConfigFieldIds.ConveyorPrimaryBoxFreeHeight,
    validators: [],
    type: FieldType.NUMBER,
    required: false, // true,
    defaultValue: 100,
    suffix: 'mm',
    hint: '',
    disabled: false,
  },
];
