<mat-sidenav-container class="sidenav" *ngIf="inputContent">
  <mat-sidenav mode="side" opened class="inner_sidenav">
    <div class="background_column"></div>
    <mat-accordion displayMode="flat" hideToggle="true">
      <ng-container
        *ngFor="let data of inputContent; let l = count; let i = index"
      >
        <mat-expansion-panel
          [hideToggle]="data.expansion ? !(data.expansion.length > 0) : false"
          disabled="true"
          [expanded]="currentExpanded === data.id"
          class="mat-elevation-z0"
          [ngClass]="{
            disabled: currentExpanded !== data.id && disableStepSelection
          }"
        >
          <mat-expansion-panel-header
            collapsedHeight="60px"
            class="expansion_panel"
          >
            <mat-panel-title>
              <div class="left-bar">
                <svg style="float: left; width: 44px; height: 100%">
                  <g>
                    <line
                      x1="50%"
                      y1="0%"
                      x2="50%"
                      y2="50%"
                      stroke="rgba(57, 86, 146, 0.2)"
                      *ngIf="i > 0"
                    />
                    <line
                      x1="50%"
                      y1="50%"
                      x2="50%"
                      y2="100%"
                      stroke="rgba(57, 86, 146, 0.2)"
                      *ngIf="i < l - 1"
                    />
                    <circle
                      cx="50%"
                      cy="50%"
                      r="14px"
                      class="circle"
                      [attr.fill]="circleColor(data)"
                    />
                    <text
                      x="50%"
                      y="50%"
                      dy=".3em"
                      dx="-.3em"
                      class="circle_text"
                      [attr.fill]="textColor(data)"
                    >
                      {{ i + 1 }}
                    </text>
                  </g>
                </svg>
              </div>

              <div class="text-content">
                <div>
                  <div
                    (click)="
                      !disableStepSelection ? selectedStep.emit(data) : ''
                    "
                  >
                    <div class="header_text">
                      {{ data.header }}
                    </div>
                    <div *ngIf="data.expansion" class="content_text">
                      {{ data.expansion.length }} selected
                    </div>
                  </div>
                </div>

                <button
                  *ngIf="data.expansion"
                  mat-icon-button
                  color="accent"
                  (click)="setExpanded(data.id)"
                >
                  <mat-icon>keyboard_arrow_down</mat-icon>
                </button>
              </div>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-container *ngFor="let text of data.expansion">
            <div class="expansion_text">{{ text }}</div>
          </ng-container>
        </mat-expansion-panel>
      </ng-container>
    </mat-accordion>
  </mat-sidenav>
</mat-sidenav-container>
