import { Injectable, NgZone, OnDestroy } from '@angular/core';
import * as THREE from 'three';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  distinctUntilChanged,
  filter,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { RenderTrigger } from 'src/app/public/render-trigger';
import { CanvasStore } from 'src/app/public/canvas-store';
import { InputHandlerService } from './input-handler.service';
import { SceneStore } from 'src/app/public/scene-store';
import { app_colors } from 'src/app/models_new/config/app-theme';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { View3DEventsService } from './view3d-events.service';
import isEqual from 'lodash-es/isEqual';

@Injectable()
export class ThreeRendererService implements OnDestroy {
  public readonly threeID$: BehaviorSubject<string> =
    new BehaviorSubject<string>(undefined);
  get ID(): string {
    return this.threeID$.getValue();
  }

  private canvas: HTMLCanvasElement;
  public renderer: THREE.WebGLRenderer;

  private renderSetup = false;

  private curr: number;
  private delta: number;
  private prev: number;

  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  constructor(
    private ngZone: NgZone,
    private inputHandler: InputHandlerService,
    private threeEvents: View3DEventsService
  ) {
    this.threeEvents.canvasFetched$
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterFalse(), take(1))
      .subscribe((_: any) => {
        this.canvas = CanvasStore.getCanvas(this.ID);

        this.setupRenderer(this.canvas);
      });

    const threeViewSetUp$ = this.threeEvents.setup3DViewFinished$.pipe(
      RXJSUtils.filterFalse(),
      take(1)
    );

    // Render on trigger subscription.
    threeViewSetUp$
      .pipe(
        switchMap(() => RenderTrigger.render$),
        filter((value: string) => value === this.ID),
        takeUntil(this.destroy$)
      )
      .subscribe((threeID: string) =>
        this.render(
          SceneStore.getScene(threeID),
          this.inputHandler.getMainCamera()
        )
      );

    // Screenshot on trigger subscription.
    threeViewSetUp$
      .pipe(
        switchMap(() => RenderTrigger.screenshotTrigger$),
        filter((value: string) => value === this.ID),
        takeUntil(this.destroy$)
      )
      .subscribe((threeID: string) => {
        const img = this.screenshot();
        RenderTrigger.screenshotOutput$.next({ id: threeID, img: img });
      });

    // Resize on trigger subscription.
    threeViewSetUp$
      .pipe(
        switchMap(() => RenderTrigger.resize$),
        filter((event) => event.id === this.ID),
        // Resizing is an expensive operation.
        // We only want to do that if it actually changes
        distinctUntilChanged(isEqual), // isEqual compares object values
        takeUntil(this.destroy$)
      )
      .subscribe((event) => {
        this.setSize(event.width, event.height);
        this.render(
          SceneStore.getScene(event.id),
          this.inputHandler.getMainCamera()
        );
      });

    // Enable XR on trigger subscription.
    threeViewSetUp$
      .pipe(
        switchMap(() => RenderTrigger.enableXR$),
        takeUntil(this.destroy$)
      )
      .subscribe((enabled: boolean) => {
        // Rendering loop trigger has been set before setup, maintain this setting
        if (
          this.renderSetup ||
          (!this.renderSetup &&
            RenderTrigger.enableRenderingLoop$.getValue() === enabled)
        ) {
          RenderTrigger.enableRenderingLoop$.next(enabled);
        }
      });

    // Enable rendering loop on trigger subscription.
    threeViewSetUp$
      .pipe(
        switchMap(() => RenderTrigger.enableRenderingLoop$),
        takeUntil(this.destroy$)
      )
      .subscribe((enabled: boolean) => {
        if (enabled) {
          // Start deltatime for first frame is 0, => no jumping/skipping.
          this.curr = this.prev = new Date().getTime();

          this.renderer.setAnimationLoop(this.renderingLoop.bind(this));
        } else {
          this.renderer.setAnimationLoop(null);
        }
      });

    this.threeEvents.setup3DViewFinished$
      .pipe(takeUntil(this.destroy$), RXJSUtils.filterFalse(), take(1))
      .subscribe(() => {
        this.renderSetup = true;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.kill();
  }

  renderingLoop(): void {
    this.curr = new Date().getTime();
    this.delta = this.curr - this.prev;
    this.prev = this.curr;
    this.threeEvents.update$.next(this.delta / 1000);
    RenderTrigger.render$.next(this.ID);
  }

  setupRenderer(canvas: HTMLCanvasElement): void {
    this.renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
      alpha: true,
    });

    this.renderer.setClearColor(app_colors.background_color, 0);
    // this.renderer.setClearColor('#000000');

    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  }

  setSize(width: number, height: number) {
    this.renderer.setSize(
      width * window.devicePixelRatio,
      height * window.devicePixelRatio,
      false
    );

    this.renderer.domElement.style.width = width + 'px';
    this.renderer.domElement.style.height = height + 'px';
  }

  render(scene: THREE.Scene, camera: THREE.Camera): void {
    if (!scene || !camera) {
      console.error(
        'Scene or camera not defined! Scene: ',
        scene,
        ' | Camera: ',
        camera
      );
      return;
    }

    // Run rendering outside angular to avoid change detection
    this.ngZone.runOutsideAngular(() => {
      this.renderer.render(scene, camera);
    });
  }

  screenshot(): HTMLImageElement {
    // Render once more before grabbing the image
    this.render(
      SceneStore.getScene(this.threeID$.getValue()),
      this.inputHandler.getMainCamera()
    );
    const img = new Image();
    try {
      img.src = this.canvas.toDataURL();
    } catch (e) {
      console.error(e);
    } finally {
      return img;
    }
  }

  kill() {
    this.renderer.dispose();
  }
}
