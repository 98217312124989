import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUtils } from 'src/app/utils/file-utils';
import { first, from, map, Observable } from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss'],
})
export class PdfPreviewComponent implements OnInit {
  url: string;
  title: string;

  base64Url$: Observable<string>;
  base64UrlState$: Observable<DataRequestState<string>>;

  isDownloading: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<PdfPreviewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      data: {
        url: string | Observable<string>;
        title: string;
      };
    }
  ) {}

  ngOnInit(): void {
    this.title = this.input.data.title;

    if (this.input.data.url instanceof Observable) {
      this.base64Url$ = this.input.data.url;
    } else {
      this.url = this.input.data.url;
      this.base64Url$ = this.getPdf(this.url).pipe(
        map((base64) => FileUtils.base64PdfToUrl(base64))
      );
    }

    this.base64UrlState$ = this.base64Url$.pipe(toRequestState());
  }

  getPdf(url: string): Observable<string> {
    return from(
      fetch(url)
        .then((result) => result.blob())
        .then((blob) => this.blobToBase64(blob))
    );
  }

  blobToBase64(blob: Blob): Promise<string> {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve((reader.result as string).split(',')[1]);
      reader.readAsDataURL(blob);
    });
  }

  download() {
    if (this.isDownloading) {
      return;
    }

    this.isDownloading = true;
    this.base64Url$.pipe(first()).subscribe((base64Url) => {
      this.isDownloading = false;

      if (!base64Url) {
        return;
      }

      const fileName = this.title.split(' ').join('_').toLowerCase();
      FileUtils.downloadFile(base64Url, fileName);
      this.dialogRef.close();
    });
  }

  didClickClose() {
    this.dialogRef.close();
  }
}
