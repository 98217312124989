import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  catchError,
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  ReplaySubject,
  shareReplay,
  startWith,
  Subject,
  switchMap,
  take,
  takeUntil,
  throwError,
} from 'rxjs';
import * as THREE from 'three';
import { MathUtils } from 'three';
import { NewThreePallet } from '../../../../../models_new/classes/3dview/new-three-pallet';
import { ThreeViewContent } from '../../../../../models_new/classes/3dview/three-content';
import { Pallet } from '../../../../../models_new/classes/pallet';
import { palletViewColors } from '../../../../../models_new/config/pallet-view-colors';
import { LabelOrientation } from '../../../../../models_new/enums/label-orientation';
import { NewAssetStoreService } from '../../../../../services/3dview/asset-store.service';
import { milliToMeter } from '../../../../../utils/div';
import { ThreeUtils } from '../../../../../utils/three-utils';
import { New_ThreeViewComponent } from '../../three-view/three-view.component';
import { AssetIDs } from 'src/app/models_new/enums/asset-ids';

@Component({
  selector: 'app-three-pallet',
  templateUrl: './three-pallet.component.html',
  styleUrls: ['./three-pallet.component.scss'],
})
export class ThreePalletComponent
  extends ThreeViewContent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() pallet: Pallet;
  pallet$ = new ReplaySubject<Pallet>(1);
  @Input() sticker: THREE.Texture;
  @Input() productTypeId: string = AssetIDs.Box;
  @Input() labelOrientations: LabelOrientation[] = [];
  @Input() active = true;
  @Input() showBoxFront = false;
  @Input() showPalletFront = false;
  @Input() showOutLines = false;
  @Input() firstLayerOnly = false;

  @Input() position: THREE.Vector3 = new THREE.Vector3();

  boxModel$: Observable<THREE.Mesh>;
  palletModel$: Observable<THREE.Mesh>;

  handleBuild$: Observable<NewThreePallet>;

  model: NewThreePallet;

  destroy$ = new Subject<boolean>();

  constructor(
    threeView: New_ThreeViewComponent,
    private assetStore: NewAssetStoreService
  ) {
    super(threeView);
  }

  ngOnInit(): void {
    // The box model is known, the actual mesh is the first child
    this.boxModel$ = this.assetStore
      .get(this.productTypeId ?? AssetIDs.Box)
      .data$.pipe(
        map((model) => model.children[0]),
        shareReplay({ bufferSize: 1, refCount: false })
      );

    this.palletModel$ = this.pallet$.pipe(
      switchMap((pallet: Pallet) => {
        return this.makePalletModel(
          pallet.dimensions.length,
          pallet.dimensions.width,
          pallet.dimensions.palletHeight
        );
      }),
      shareReplay({ bufferSize: 1, refCount: false })
    );

    this.handleBuild$ = combineLatest([
      this.boxModel$,
      this.palletModel$,
      this.pallet$,
      this.pallet$.pipe(
        switchMap((p: Pallet) => p.update$),
        debounceTime(16) // Roughly 60 times per second, matching fps.
      ),
    ]).pipe(
      takeUntil(this.destroy$),
      map(([boxModel, palletModel, pallet, _]) => {
        if (this.model) {
          this.scene.remove(this.model);
          ThreeUtils.disposeObject(this.model);
        }
        this.model = new NewThreePallet(palletModel, boxModel, pallet);
        this.updateActive(this.active);
        if (this.sticker && this.labelOrientations) {
          this.model.setBoxStickerTexture(this.sticker, this.labelOrientations);
        }
        this.model.showBoxFront(this.showBoxFront);
        this.model.showPalletFront(this.showBoxFront);
        this.model.position.copy(this.position);
        this.scene.add(this.model);
        this.model.updateBoundingBox();
        this.model.showOutLines(this.showOutLines);
        this.model.showFirstLayerOnly(this.firstLayerOnly);
        this.render(); // Tell 3dview to render again.
        return this.model;
      }),
      // Stop subscriptions re-building everything
      shareReplay({ bufferSize: 1, refCount: false })
    );

    // Done loading once pallet is built.
    this.loading$ = this.handleBuild$.pipe(
      map(() => false),
      catchError((error) => {
        console.error(error);
        return throwError(
          () =>
            new Error(
              "Couldn't load asset. Please try to refresh. Contact MRC support if the error persists."
            )
        );
      }),
      startWith(true),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pallet?.currentValue) {
      this.pallet$.next(changes.pallet.currentValue);
      if (this.model) {
        this.model.updateBoundingBox();
      }
    }
    this.handleBuild$?.pipe(takeUntil(this.destroy$), take(1)).subscribe(() => {
      if (
        (changes.sticker || changes.labelOrientations) &&
        this.sticker &&
        this.labelOrientations
      ) {
        this.model.setBoxStickerTexture(this.sticker, this.labelOrientations);
      }
      if (changes.position?.currentValue) {
        this.model.setPosition(changes.position.currentValue);
        this.model.updateBoundingBox();
      }
      if (changes.showBoxFront) {
        this.model.showBoxFront(changes.showBoxFront.currentValue);
      }
      if (changes.active) {
        this.updateActive(changes.active.currentValue);
      }
      if (changes.showPalletFront) {
        this.model.showPalletFront(changes.showPalletFront.currentValue);
      }

      this.render(); // Tell 3dview to render again.
    });
  }

  ngOnDestroy(): void {
    this.scene?.remove(this.model);
    ThreeUtils.disposeObject(this.model);
    super.onDestroy();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private updateActive(active: boolean): void {
    if (active) {
      this.model.enable();
    } else {
      this.model.disable();
    }
  }

  private makePalletModel(
    length: number,
    width: number,
    height: number
  ): Observable<THREE.Mesh> {
    // Millimeters
    if (length === 1200 && width === 800) {
      return this.assetStore.get('pallet-eur1').data$.pipe(
        take(1),
        map((model: THREE.Object3D) => {
          const mesh: THREE.Mesh = model.children[0] as THREE.Mesh;
          mesh.rotation.z = MathUtils.degToRad(90);
          mesh.rotation.x = MathUtils.degToRad(-90);
          model.position.set(0, 0, 0);
          return mesh;
        })
      );
    } else {
      const model = new THREE.Mesh(
        new THREE.BoxGeometry(
          milliToMeter(width),
          milliToMeter(height),
          milliToMeter(length)
        ),
        new THREE.MeshLambertMaterial({
          color: this.active
            ? palletViewColors.selectedPallet
            : palletViewColors.unselectedPallet,
        })
      );
      model.position.y = milliToMeter(height) / 2;

      return of(model);
    }
  }
}
