import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  map,
  Observable,
  of,
  shareReplay,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  combineLatest,
  BehaviorSubject,
} from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { IApiOpenSimulation } from 'src/app/models_new/classes/api-models/ApiOpenSimulation';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { IInfoCard } from 'src/app/models_new/types/info-card-config';
import { InfoApiService } from 'src/app/services/api/info-api.service';
import { PublicApiService } from 'src/app/services/api/public-api.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ObjectUtils } from 'src/app/utils/object';
import { GoogleAnalyticsService } from '../../../services/google-analytics-service.service';
import {
  IFastTrackPipedriveInfo,
  PipedriveService,
} from '../../../services/pipedrive.service';
import { IReadyOpenSimulation, OpenSimService } from '../open-sim.service';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { OrganizationApiService } from '../../../services/api/organization-api.service';
import confetti from 'canvas-confetti';
import { IOpenSimPage } from 'src/app/models_new/config/fast-track-pages';
import { transformToSpacedValue } from 'src/app/utils/unit-utils';

interface IResolvedParams {
  si?: string;
  solution?: any;
  sp?: string;
  solution_provider?: string;
  vi?: string;
  vendor_name?: string;
  vn?: string;
}

interface ProgressStep {
  key: string; // name of the step
  value: number; // amount of simulations on this step.
  isComplete: boolean;
  tooltip?: string;
}

@Component({
  selector: 'app-fast-track-simulations',
  templateUrl: './fast-track-simulations.component.html',
  styleUrls: ['./fast-track-simulations.component.scss'],
})
export class FastTracSimulationsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  simulations$: Observable<DataRequestState<IReadyOpenSimulation[]>>;
  tableDataSource: IReadyOpenSimulation[];
  displayedColumns = [
    'name',
    'progress',
    'status',
    'cpm',
    'eta',
    'starting_price',
    'action',
  ];
  solutionsMetadata: [{ id: string; price?: string; externalUrl?: string }?] =
    [];
  overalProgress$: BehaviorSubject<ProgressStep[]> = new BehaviorSubject(null);
  generalError: boolean = false;

  currentPageContent$: Observable<IOpenSimPage>;
  exampleLink$: Observable<string>;
  sentSimulationLinks = false;

  constructor(
    private localStorage: LocalStorageService,
    private router: Router,
    private infoApi: InfoApiService,
    private openSimService: OpenSimService,
    private publicApi: PublicApiService,
    private gas: GoogleAnalyticsService,
    private pipedriveService: PipedriveService,
    private route: ActivatedRoute,
    private orgApi: OrganizationApiService,
    public appLayout: AppLayoutService
  ) {}

  ngOnInit(): void {
    this.exampleLink$ = this.infoApi.getLandingPageInfo().pipe(
      take(1),
      map((value: IInfoCard) => value.learnMoreLink)
    );

    this.currentPageContent$ = this.openSimService
      .setStepById('simulations-details')
      .pipe(
        switchMap((step) =>
          this.openSimService.getPageContentByStepId(step.id)
        ),
        switchMap((pageContent) =>
          combineLatest([
            of(pageContent),
            this.openSimService.prevIsValid(pageContent.step).pipe(take(1)),
          ])
        ),
        tap(([pageContent, isValid]) => {
          if (!isValid)
            this.openSimService.findAndNavigateToPreviousPage(pageContent.step);
        }),
        map(([pageContent, _]) => pageContent),
        shareReplay({ bufferSize: 1, refCount: true })
      );

    // Flag to avoid confetti being thrown on every update.
    let isConfettiThrown = false;
    const sims$ = this.openSimService.startSimulations().pipe(
      take(1),
      switchMap((pattern_id: string) =>
        this.publicApi.getOpenSimulationsByPatternId(pattern_id)
      ),
      shareReplay({ bufferSize: 1, refCount: false })
    );

    this.simulations$ = sims$.pipe(
      map((sims: IReadyOpenSimulation[]) =>
        this.updateSimulationTableData(sims)
      ),
      tap((_) => {
        if (!isConfettiThrown) {
          this.trhowConfetti();
          isConfettiThrown = true;
        }
      }),
      toRequestState(),
      tap((_) => {
        if (_.error) {
          console.error(_.error);
        } else {
          this.localStorage.removeData(
            LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN
          );
          this.localStorage.removeData(LocalStorageKey.PROJECT);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$)
    );

    // Pipedrive info "plug-in"
    sims$
      .pipe(
        take(1),
        switchMap((sims: IReadyOpenSimulation[]) => {
          // Already sent, continue with your business...
          if (this.sentSimulationLinks) return of(sims);
          // Not sent, let's send it.
          const opensimInfo: IApiOpenSimulation = this.localStorage.getData(
            LocalStorageKey.OPEN_SIM
          );
          const params = this.route?.snapshot?.queryParams;
          this.sentSimulationLinks = true;
          // If there are params, resolve them and send the info.
          if (Object.keys(params).length > 0) {
            return this.resolveParams(params).pipe(
              take(1),
              switchMap((paramInfo: IResolvedParams) => {
                return this.sendPipedriveInfo(opensimInfo, sims, paramInfo);
              })
            );
            // No params, just simulations to send.
          } else return this.sendPipedriveInfo(opensimInfo, sims);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe({
        error: (error) => console.error(error),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  private trhowConfetti() {
    const end = Date.now() + 0.3 * 1000;
    // MRC's themed confetti colors
    const colors = ['#4d9391', '#395692', '#af2b2b', '#92b4dA', '#f4f4f4'];
    (function frame() {
      confetti({
        particleCount: 5,
        angle: 60,
        spread: 55,
        origin: { x: 0 },
        colors: colors,
      });
      confetti({
        particleCount: 5,
        angle: 120,
        spread: 55,
        origin: { x: 1 },
        colors: colors,
      });
      if (Date.now() < end) {
        requestAnimationFrame(frame);
      }
    })();
  }

  private updateSimulationTableData(
    inputData: IReadyOpenSimulation[]
  ): IReadyOpenSimulation[] {
    if (!this.solutionsMetadata.length) {
      this.solutionsMetadata = this.localStorage.getData(
        LocalStorageKey.FASSTRACK_SOLUTION_METADATA
      );
    }
    if (inputData?.length !== this.tableDataSource?.length) {
      this.tableDataSource = ObjectUtils.cloneObject(inputData);
    }
    if (inputData[0]['id'] && !inputData[0].solutionId) {
      if (this.tableDataSource[0].solutionId) {
        this.tableDataSource = ObjectUtils.cloneObject(inputData);
      }
      const applyToFixed = (value: any): any => {
        if (typeof value === 'number') return value.toFixed(2);
        return value;
      };
      this.tableDataSource.forEach((tableData) => {
        const inputDataEntry = inputData.find(
          (inputEntry) => tableData['id'] === inputEntry['id']
        );
        tableData.progress = `${inputDataEntry?.progress || 0}%`;
        tableData.status = inputDataEntry?.status;
        tableData.cpm = `${applyToFixed(inputDataEntry?.cpm) || '~'}`;
        tableData.startingPrice = transformToSpacedValue(
          this.solutionsMetadata.find((entry) => entry.id === tableData.name)
            ?.price
        );
        tableData.externalUrl = this.solutionsMetadata.find(
          (entry) => entry.id === tableData.name
        )?.externalUrl;
        tableData.eta = inputDataEntry?.eta
          ? `${applyToFixed(inputDataEntry?.eta)} min`
          : '~';
      });
      this.checkStageCompletion();
    }
    return this.tableDataSource;
  }

  /**
   * Checks the completion status of each stage in the progress and updates overlaProgress$
   */
  private checkStageCompletion(): void {
    const progresKeys = [
      'Queued',
      'Setting_up',
      'Starting_robot',
      'Palletizing',
      'Completed',
    ];
    const stages: ProgressStep[] = progresKeys.map((key) => ({
      key,
      value: 0,
      tooltip: '',
      isComplete: false,
    }));
    let validSimulationCount = 0;
    let errorSimulationConut = 0;
    for (const simulation of this.tableDataSource) {
      let simulationStage = simulation.status;
      if (simulationStage === 'Finished' || simulationStage === 'Successful') {
        simulationStage = 'Completed';
      }
      if (!progresKeys.includes(simulationStage)) {
        if (simulationStage === 'Error') {
          errorSimulationConut++;
        }
        continue;
      }
      if (errorSimulationConut === this.tableDataSource.length) {
        this.generalError = true;
      }
      validSimulationCount++;
      const stageIndex = stages.findIndex(
        (stage) => stage.key === simulationStage
      );
      for (let i = 0; i <= stageIndex; i++) {
        stages[i].value++;
      }
    }
    for (let i = 0; i < stages.length; i++) {
      const stage = stages[i];
      if (i < stages.length - 1) {
        if (stages[i + 1].value === validSimulationCount) {
          stage.isComplete = true;
        }
      } else {
        if (stage.key === 'Completed' && stage.value === validSimulationCount) {
          stage.isComplete = true;
        }
      }
      if (stage.value !== 0 && stage.isComplete === false) {
        stage.tooltip = `${stage.value} simulation${
          stage.value > 1 ? 's' : ''
        } ${stage.key.toLowerCase().replaceAll('_', ' ')}`;
      }
    }
    this.overalProgress$.next(stages);
  }

  private resolveParams(params: any): Observable<IResolvedParams> {
    const ids: string[] = [];
    const operations: Observable<any>[] = [];
    if (params?.si) {
      ids.push('solution');
      operations.push(this.publicApi.getSolutionByID(params.si));
    }
    if (params?.sp) {
      ids.push('solution_provider');
      operations.push(this.orgApi.getPublicOrgNameByID(params.sp));
    }
    if (params?.vi || params?.vn) {
      ids.push('vendor_name');
      operations.push(this.orgApi.getPublicVendorName(params.vi, params.vn));
    }
    return combineLatest(operations).pipe(
      map((data: any) => {
        const solution_index = ids.indexOf('solution');
        const solution_provider_index = ids.indexOf('solution_provider');
        const vendor_name_index = ids.indexOf('vendor_name');
        const obj = {};
        if (solution_index > -1) {
          obj['solution'] = data[solution_index];
        }
        if (solution_provider_index > -1) {
          obj['solution_provider'] = data[solution_provider_index];
        }
        if (vendor_name_index > -1) {
          obj['vendor_name'] = data[vendor_name_index];
        }
        return obj;
      }),
      map(
        (data: {
          solution?: any;
          solution_provider?: string;
          vendor_name?: string;
        }) => {
          return {
            si: params?.si,
            solution: data?.solution,
            sp: params?.sp,
            solution_provider: data?.solution_provider
              ? data.solution_provider
              : data?.solution
              ? data.solution.solution_provider.name
              : 'UNKNOWN',
            vi: params?.vi,
            vendor_name: data?.vendor_name ? data.vendor_name : params?.vn,
            vn: params?.vn,
          } as IResolvedParams;
        }
      )
    );
  }

  private sendPipedriveInfo(
    opensimInfo: IApiOpenSimulation,
    sims: IReadyOpenSimulation[],
    paramInfo?: IResolvedParams
  ): Observable<any> {
    const info: IFastTrackPipedriveInfo = {
      fullName: opensimInfo.name,
      email: opensimInfo.email,
      region: opensimInfo.region,
      country: opensimInfo.country,
      organization: opensimInfo.organization_name,
      phase_of_research: opensimInfo.phase_of_research,
    };
    if (paramInfo?.solution_provider) {
      info['campaign'] = `${paramInfo.solution_provider}${
        paramInfo.vendor_name ? ' ' + paramInfo.vendor_name : ''
      } Fast Track Lead${
        paramInfo.solution ? ' - Solution: ' + paramInfo.solution.name : ''
      }`;
    }

    return this.pipedriveService.postPipedriveFastTrack(info, sims);
  }

  public goToReport(simId: string): void {
    this.gas.addEvent(
      'Confirmation - Fast Track - MyRobot.cloud - View report - Click',
      null,
      this.gas.getLastParam()
    );
    const url = this.router.serializeUrl(
      this.router.createUrlTree([pagesPATH.SIMULATIONS, simId], {
        queryParams: { o: 1 },
      })
    );
    window.open(url, '_blank');
  }

  public navigateTo(url: string): void {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'http://' + url;
    }
    window.open(url, '_blank');
  }
}
