import { Injectable } from '@angular/core';
import { AuthService, User } from '@auth0/auth0-angular';
import { gql, MutationResult } from 'apollo-angular';
import { AxisTypeValue } from 'highcharts';
import { isNaN } from 'lodash-es';
import { Observable, map, catchError, of, switchMap, take } from 'rxjs';
import {
  bo_graphSerieType,
  bo_graphType,
  IGraphConfig,
} from 'src/app/components/backoffice/backoffice-graphs/bo_graph';
import {
  IApiLicenseRequest,
  IApiLicenseRequestInsert,
} from 'src/app/models_new/classes/api-models/ApiLicenseRequest';
import {
  ApiOrganization,
  IApiOrganization,
} from 'src/app/models_new/classes/api-models/ApiOrganization';
import { SimulationStatus } from 'src/app/models_new/enums/simulation-status';
import { Role } from 'src/app/models_new/types/role';
import { ObjectUtils } from 'src/app/utils/object';
import { ClientApiService } from './client-api.service';
import { OrganizationApiService } from './organization-api.service';
import { ICountryNState } from './public-api.service';
import { ModelType } from '../../models_new/classes/model-type';
import { IDefaultSimConfig } from './api.service';
import { GraphDataHelper, IGraphData } from './graphData-helper';
import { ISearchedCustomerOrg } from 'src/app/components/backoffice/backoffice-customer-org/customer-org-list/customer-org-list.component';
import { IGlobalNotification } from '../notification.service';
import { RobotBrandType } from 'src/app/models_new/enums/robot-brand-types';

const GET_CUSTOMER_ORGS = gql`
  query getCustomerOrgs($salesOrgId: uuid) {
    organization_relation(where: { organization_a_id: { _eq: $salesOrgId } }) {
      organization_b {
        name
        created_at
        id
        updated_at
      }
    }
  }
`;

const GET_SALES_ORG_MEMBERS = gql`
  query getSalesOrgMembers($salesOrgId: uuid) {
    organization_member(
      where: { organization_id: { _eq: $salesOrgId } }
      order_by: { user_id: asc }
    ) {
      type
      updated_at
      user_id
      created_at
      user {
        name
        email
      }
    }
  }
`;

const GET_BACKOFFICE_ORG_METADATA = gql`
  query getBackofficeOrgMetadata {
    organization(where: { type: { _eq: backoffice_organization } }) {
      metadata
    }
  }
`;

export interface lightTableData {
  id: string;
  name: string;
  create_at: string;
  updated_at: string;
  type?: string;
  user_id?: string;
  date?: string;
}

export type salesOrgFilter = 'partner' | 'vendor' | 'favorites';
export type salesOrgExtraColumn = 'members' | 'simulations';

export interface ISalesOrgFavorite {
  orgIds: string[];
  userId: string;
}
export interface ISearchedSalesOrg {
  id: string;
  name: string;
  customers?: number;
  members?: number;
  simulations?: number;
  simObject?: { failed: number; success: number };
  favorite?: boolean;
  vendor?: boolean;
  partner?: boolean;
  subType: string;
  subEx: string;
  activeSinceTimeSat: number;
}

export interface IContentFulGraphConfig {
  series: {
    id: bo_graphSerieType;
    name: string;
    description: string;
  }[];
  title: string;
  type: bo_graphType;
  xAxisText: string;
  xAxisType: AxisTypeValue;
  yAxisText: string;
}

export interface IUser {
  id: string;
  name: string;
  email: string;
  organization_members?: {
    organization: { name: string; type: string };
  }[];
}

export interface IPartnerSalesArea {
  continents: string[];
  countries: string[];
  regions: string[];
}

export const OrganizationSubscriptionTypes = [
  {
    value: 'partner',
    label: 'Signed partner',
  },
  {
    value: 'end_user',
    label: 'End user',
  },
  {
    value: 'hardware_provider',
    label: 'Hardware provider',
  },
  {
    value: 'rf_sim',
    label: 'Internal simulation organization (rf_sim)',
  },
];

const OrgSubValues = OrganizationSubscriptionTypes.map((m) => m.value);
export type OrganizationSubscriptionType = typeof OrgSubValues[number];

export interface IEmbedKey {
  id: string;
  referrer: string;
  key: string;
  organization: {
    id: string;
    name: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class BackofficeService {
  constructor(
    private clientApi: ClientApiService,
    private orgApi: OrganizationApiService,
    private auth: AuthService
  ) {}

  /**
   * Queryfy.
   *
   * Prep javascript objects for interpolation within graphql queries.
   * Copied from https://stackoverflow.com/questions/50660045/graphql-build-query-arguments-from-object
   *
   * @param {mixed} obj
   * @return template string.
   */
  queryfy(obj: any): string | number {
    // Make sure we don't alter integers.
    if (typeof obj === 'number') {
      return obj;
    }

    if (Array.isArray(obj)) {
      const props = obj.map((value) => `${this.queryfy(value)}`).join(',');
      return `[${props}]`;
    }

    if (typeof obj === 'object') {
      const props = [];
      for (const key of Object.keys(obj)) {
        if (obj[key]) {
          props.push(`${key}:${this.queryfy(obj[key])}`);
        }
      }
      return `{${props.join(',')}}`;
    }

    return JSON.stringify(obj);
  }

  deleteDefaultSimConfig(id: string): Observable<string> {
    const m = gql`
    mutation deleteDefaultSimConfig {
      delete_default_sim_config_by_pk(id: "${id}") {
        id
      }
    }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.delete_default_sim_config_by_pk.id;
          }
        })
      );
  }

  updateDefaultSimConfig(dsc: IDefaultSimConfig): Observable<string> {
    const m = gql`
    mutation updateDefaultSimConfig($description: String, $vendor_ids: jsonb, $solution_provider_id: uuid, $regions: jsonb, $name: String, $liftkit_optimization: Boolean, $data: json, $countries: jsonb, $continents: jsonb) {
      update_default_sim_config_by_pk(pk_columns: {id: "${dsc.id}"}, _set: {description: $description, continents: $continents, countries: $countries, data: $data, liftkit_optimization: $liftkit_optimization, name: $name, regions: $regions, solution_provider_id: $solution_provider_id, vendor_ids: $vendor_ids}) {
        id
      }
    }    
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          continents: dsc.continents,
          countries: dsc.countries,
          data: dsc.data,
          description: dsc.description,
          liftkit_optimization: dsc.liftkit_optimization,
          name: dsc.name,
          regions: dsc.regions,
          solution_provider_id: dsc.solution_provider_id,
          vendor_ids: dsc.vendor_ids,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.update_default_sim_config_by_pk.id;
          }
        })
      );
  }

  createDefaultSimConfig(dsc: IDefaultSimConfig): Observable<string> {
    const m = gql`
      mutation createDefaultSimConfig(
        $continents: jsonb
        $countries: jsonb
        $data: json
        $description: String
        $liftkit_optimization: Boolean
        $name: String
        $regions: jsonb
        $solution_provider_id: uuid
        $vendor_ids: jsonb
      ) {
        insert_default_sim_config_one(
          object: {
            continents: $continents
            countries: $countries
            data: $data
            description: $description
            liftkit_optimization: $liftkit_optimization
            name: $name
            regions: $regions
            solution_provider_id: $solution_provider_id
            vendor_ids: $vendor_ids
          }
        ) {
          id
        }
      }
    `;
    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          continents: dsc.continents,
          countries: dsc.countries,
          data: dsc.data,
          description: dsc.description,
          liftkit_optimization: dsc.liftkit_optimization,
          name: dsc.name,
          regions: dsc.regions,
          solution_provider_id: dsc.solution_provider_id,
          vendor_ids: dsc.vendor_ids,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.insert_default_sim_config_one.id;
          }
        })
      );
  }

  getDefaultSimConfig(id: string): Observable<IDefaultSimConfig> {
    const q = gql`
      subscription getDefaultSimConfig {
        default_sim_config_by_pk(id: "${id}") {
          continents
          countries
          data
          description
          liftkit_optimization
          name
          id
          regions
          solution_provider_id
          vendor_ids
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return ObjectUtils.cloneObject(data.data.default_sim_config_by_pk);
        })
      );
  }

  getDefaultSimConfigs(): Observable<IDefaultSimConfig[]> {
    const q = gql`
      subscription getDefaultSimConfigs {
        default_sim_config(order_by: { updated_at: desc }) {
          continents
          countries
          data
          description
          liftkit_optimization
          name
          id
          regions
          solution_provider_id
          vendor_ids

          open_simulations_aggregate {
            aggregate {
              count
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.default_sim_config;
        })
      );
  }

  getLicenseRequests(): Observable<IApiLicenseRequest[]> {
    const q = gql`
      subscription getLicenseRequests {
        sw_license {
          id
          requested_by_organization_id
          requested_by_organization {
            name
          }
          generated_by_organization_id
          generated_by_organization {
            name
          }
          customer_organization_id
          customer_organization {
            name
          }
          order_number
          robot_serial_number
          product_type
          license_type
          service_pack
          options
          requested_by_user_id
          requested_by_user {
            name
          }
          generated_by_user_id
          generated_by_user {
            name
          }
          license_valid_to
          updated_at
          created_at
          license_request
          generated_license
          generated_at
          service_pack_valid_to
          installed_robot_id
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(map((data) => data?.data?.sw_license));
  }

  getLicenceOptions(): Observable<{ option: string; description: string }[]> {
    const q = gql`
      query getLicenceOptions {
        sw_license_option {
          option
          description
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(map((data) => data?.data?.sw_license_option));
  }

  getLicenceTypes(): Observable<{ type: string; description: string }[]> {
    const q = gql`
      query getLicenceTypes {
        sw_license_type {
          type
          description
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(map((data) => data?.data?.sw_license_type));
  }

  getLicenceProductTypes(): Observable<
    { type: string; description: string }[]
  > {
    const q = gql`
      query getLicenceProductTypes {
        sw_product_type {
          type
          description
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(map((data) => data?.data?.sw_product_type));
  }

  getPatternsDimensions(): Observable<number> {
    const q = gql`
      query getPatternsDimensions {
        pattern {
          data(path: "productDimensions")
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.pattern;
        })
      );
  }

  getTotalUsers(): Observable<number> {
    const q = gql`
      query getUsersAggregate {
        user_aggregate {
          aggregate {
            count
          }
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.user_aggregate.aggregate.count;
        })
      );
  }

  getUsers(): Observable<IUser[]> {
    const q = gql`
      query getUsers {
        user(order_by: { name: asc }) {
          id
          email
          name
          organization_members {
            organization {
              name
              type
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.user;
        })
      );
  }

  getUsersByOrganizationId(
    organizationId: string
  ): Observable<{ id: string; name: string; email: string }[]> {
    const q = gql`
      query getUsersByOrganizationId {
        user(
          order_by: { name: asc }
          where: {
            organization_members: {
              organization_id: { _eq: "${organizationId}" }
            }
          }
        ) {
          id
          email
          name
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data.user;
        })
      );
  }

  public insertLicenseRequest(
    values: IApiLicenseRequestInsert
  ): Observable<any> {
    let obj: any = JSON.parse(JSON.stringify(values));
    delete obj.id;

    // Handling nullish values. This solves having stringified "null" values on DB.
    function getStringifiedValue(inputValue: any) {
      return inputValue ? `"${inputValue}"` : null;
    }

    const m = gql`
      mutation insertLicenseRequest($license_type: sw_license_type_enum!, $service_pack: String!) {
        insert_sw_license_one(object: {
          requested_by_organization_id: ${getStringifiedValue(
            values.requested_by_organization_id
          )},
          generated_by_organization_id: ${getStringifiedValue(
            values.generated_by_organization_id
          )},
          customer_organization_id: ${getStringifiedValue(
            values.customer_organization_id
          )},
          order_number: ${getStringifiedValue(values.order_number)},
          robot_serial_number: ${getStringifiedValue(
            values.robot_serial_number
          )},
          product_type: ${getStringifiedValue(values.product_type)},
          license_type: $license_type,
          service_pack: $service_pack,
          service_pack_valid_to: ${getStringifiedValue(
            values.service_pack_valid_to
          )},
          options: ${this.queryfy(values.options)},
          requested_by_user_id: ${getStringifiedValue(
            values.requested_by_user_id
          )},
          generated_by_user_id: ${getStringifiedValue(
            values.generated_by_user_id
          )},
          license_valid_to: ${getStringifiedValue(values.license_valid_to)},
          ${
            values.license_request
              ? 'license_request: ' +
                getStringifiedValue(values.license_request)
              : ''
          }
        }) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .mutate<any>({
        mutation: m,
        variables: {
          license_type: values.license_type,
          service_pack: values.service_pack,
        },
      })
      .pipe(
        map((value) => {
          return value.data.insert_sw_license_one.id;
        })
      );
  }

  generateLicense(id: string): Observable<boolean> {
    const q = gql`
    query generateLicenseBackoffice {
      generateLicense(license_id: "${id}") {
        success
      }
    }
    `;

    return this.clientApi
      .useClient('rf_backoffice_license')
      .query<{ generateLicense: { success: boolean } }>({
        query: q,
      })
      .pipe(
        map(
          (
            value: MutationResult<{ generateLicense: { success: boolean } }>
          ) => {
            if (value.errors) {
              const errorMessage = value.errors[0]?.message;
              if (errorMessage === 'java.lang.NullPointerException') {
                throw new Error(
                  'Request registered but failed to generate license. Please try again or report the issue.'
                );
              } else {
                throw new Error(errorMessage);
              }
            }
            return value.data?.generateLicense.success;
          }
        )
      );
  }

  decryptLicense(license_request: any, license: any): Observable<any> {
    const m = gql`
      mutation {
        licenseDecrypt (request: "${license_request}", license: "${license}") {
        request, license
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .mutate<any>({
        mutation: m,
      })
      .pipe(map((data) => data.data));
  }

  getBackofficeOrgSalesMetadata(): Observable<any> {
    return this.clientApi
      .useClient('rf_backoffice_edit')
      .watchQuery<any>({
        query: GET_BACKOFFICE_ORG_METADATA,
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return ObjectUtils.cloneObject(data.data.organization[0].metadata);
          }
        })
      );
  }

  setSalesOrgFavorite(
    favoriteOrgId: string,
    favorite: boolean,
    backoffice_metadata: any = {}
  ): Observable<string> {
    const m = gql`
      mutation setSalesOrgFavorite($metadata: json) {
        update_organization(
          where: { type: { _eq: backoffice_organization } }
          _set: { metadata: $metadata }
        ) {
          affected_rows
        }
      }
    `;

    return this.auth.user$
      .pipe(
        take(1),
        switchMap((user: User) => {
          const preset: ISalesOrgFavorite = backoffice_metadata.favorites?.find(
            (f: any) => f.userId === user.sub
          );

          if (!preset && favorite) {
            backoffice_metadata.favorites = [
              {
                userId: user.sub,
                orgIds: [favoriteOrgId],
              },
            ];
          } else if (preset && favorite) {
            if (!preset.orgIds.includes(favoriteOrgId)) {
              preset.orgIds.push(favoriteOrgId);
            }
          } else if (preset && !favorite) {
            preset.orgIds = preset.orgIds.filter((f) => f !== favoriteOrgId);
          }

          return this.clientApi.useClient('rf_backoffice_edit').mutate<any>({
            mutation: m,
            variables: {
              metadata: backoffice_metadata,
            },
            refetchQueries: [
              {
                query: GET_BACKOFFICE_ORG_METADATA,
              },
            ],
          });
        })
      )

      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.affected_rows;
          }
        })
      );
  }

  getSalesOrgMemebers(orgId: string): Observable<lightTableData[]> {
    return this.clientApi
      .useClient('rf_backoffice_edit')
      .watchQuery<any>({
        query: GET_SALES_ORG_MEMBERS,
        variables: {
          salesOrgId: orgId,
        },
      })
      .valueChanges.pipe(
        map((value) => {
          if (value.errors) {
            console.error(value.errors[0].message);
            throw new Error(value.errors[0].message);
          } else {
            const d = ObjectUtils.cloneObject(value.data.organization_member);

            const dMapped = [];
            d.forEach((fe: lightTableData) => {
              if (dMapped.filter((m) => m.user_id === fe.user_id).length) {
                return;
              } else {
                const alikeByUserId = d.filter((f) => f.user_id === fe.user_id);
                const uMapped = {
                  user_id: fe.user_id,
                  name: fe['user'].name,
                  type: alikeByUserId.map((m) => m.type).join(', '),
                  email: fe['user'].email,
                  updated_at: alikeByUserId
                    .map((m) => m.updated_at)
                    .reduce((a, b) => (a > b ? a : b)),
                  created_at: alikeByUserId
                    .map((m) => m.updated_at)
                    .reduce((a, b) => (a < b ? a : b)),
                };
                dMapped.push(uMapped);
              }
            });
            return dMapped;
          }
        })
      );
  }

  insertOrganizationInvite(
    email: string,
    orgId: string,
    roles: Role[] = ['org_view', 'org_edit', 'org_delete']
  ): Observable<any> {
    const q = gql`
      query InviteOrg($invitation_data: json!) {
        NewOrganizationInvitation(invitation_data: $invitation_data) {
          id
        }
      }
    `;

    const variables = {
      invitation_data: {
        user_email: email,
        member_type: {
          types: roles,
        },
        organization_id: orgId,
      },
    };

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .query<any>({
        query: q,
        variables: variables,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            let message = data.errors[0].message;
            if (data.errors[0].message === 'JSONArray[0] not found.') {
              message = 'Unknown user';
            } else if (
              data.errors[0].message.search('Uniqueness violation') !== -1
            ) {
              message = 'Invitation already pending';
            }
            throw new Error(message);
          } else {
            return data.data;
          }
        })
      );
  }

  deleteOrganizationMembers(userIds: string[], orgId: string): Observable<any> {
    const m = gql`
      mutation deleteOrganizationMembers($orgId: uuid, $userIds: [String!]) {
        delete_organization_member(
          where: {
            organization_id: { _eq: $orgId }
            user_id: { _in: $userIds }
          }
        ) {
          affected_rows
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          orgId: orgId,
          userIds: userIds,
        },
        refetchQueries: [
          {
            query: GET_SALES_ORG_MEMBERS,
            variables: {
              salesOrgId: orgId,
            },
          },
        ],
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.delete_organization_member;
          }
        })
      );
  }

  getCustomerOrganizations(salesOrgId: string): Observable<lightTableData[]> {
    return this.clientApi
      .useClient('rf_backoffice_edit')
      .watchQuery<any>({
        query: GET_CUSTOMER_ORGS,
        variables: {
          salesOrgId: salesOrgId,
        },
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            const d = ObjectUtils.cloneObject(data.data.organization_relation);
            return d.map((m: any) => m.organization_b);
          }
        })
      );
  }

  getCustomerActivities(
    id: string,
    tables: string[]
  ): Observable<lightTableData[]> {
    const queryStrings: string[] = [];
    tables.forEach((table) => {
      queryStrings.push(`
        ${table} {
          ${table.includes('organization_members') ? 'user {name}' : 'name'}
          id
          date: created_at
        }`);
    });

    const q = gql`
    subscription getCustomerActivities {
      organization_by_pk(id: "${id}") {
        ${queryStrings.join()}
      }
    }`;

    return this.clientApi
      .useClient('rf_backoffice', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            const d = ObjectUtils.cloneObject(data.data.organization_by_pk);
            delete d.__typename;

            const concatedArray: any = [];

            for (let table in d) {
              d[table].forEach((row: any) => {
                if (table === 'organization_members') {
                  row.name = row.user.name;
                  delete row.user;
                }
                row.type = row.__typename.replaceAll('_', ' ');
                row.type = row.type.charAt(0).toUpperCase() + row.type.slice(1);
                delete row.__typename;

                concatedArray.push(row);
              });
            }

            return concatedArray.sort(
              (a: any, b: any) => Date.parse(b.date) - Date.parse(a.date)
            );
          }
        })
      );
  }

  deleteOrganization(id: string): Observable<string> {
    const m = gql`
      mutation deleteSalesOrg {
        delete_organization_by_pk(id: "${id}") {
          name
        }
      }
    `;
    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.delete_organization_by_pk.name;
          }
        })
      );
  }

  deleteCustomerOrganizations(
    ids: string[],
    salesOrgId: string
  ): Observable<string[]> {
    const deleteQueries = ids.map((id) => {
      return `delete_organization_by_pk(id: "${id}") {
        name
      }`;
    });

    const m = gql`
      mutation deleteOrgs {
        ${deleteQueries}
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        refetchQueries: [
          {
            query: GET_CUSTOMER_ORGS,
            variables: {
              salesOrgId: salesOrgId,
            },
          },
        ],
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.delete_organization_by_pk;
          }
        })
      );
  }

  getCustomerOrgs(): Observable<ISearchedCustomerOrg[]> {
    const q = gql`
      query getCustomerOrgs {
        organization(
          where: { type: { _eq: customer_organization } }
          order_by: { created_at: desc }
        ) {
          id
          name
          created_at
          parents: organization_relations {
            parent: organization_a {
              id
              name
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            const d = ObjectUtils.cloneObject(data.data.organization);
            return d.map((m: any) => {
              m.parentId = m.parents.length ? m.parents[0].parent.id : '';
              m.parentName = m.parents.length ? m.parents[0].parent.name : '';
              delete m.parents;
              return m;
            });
          }
        })
      );
  }

  getSalesOrgs(
    options: salesOrgExtraColumn[],
    lastLoginDateActive: string // ISO string
  ): Observable<ISearchedSalesOrg[]> {
    const option = new Map();
    const def = `
      {
        aggregate {
          count
        }
      }
    `;
    const destinctDef = `
    {
      aggregate {
        count(distinct: true, columns: user_id)
      }
    }
    `;
    option.set('members', `organization_members_aggregate ${destinctDef}`);
    option.set(
      'simulations',
      `simulations_aggregate ${def}` +
        `finishedSim: simulations_aggregate(where: {simulation_state: {_eq: Finished}}) {
        aggregate {
          count
        }
      }
      errorSim: simulations_aggregate(where: {simulation_state: {_eq: Error}}) {
        aggregate {
          count
        }
      }`
    );

    const extraOptions = [];
    for (let o of options) {
      extraOptions.push(option.get(o));
    }

    const q = gql`
    query getSalesOrgs {
      organization(where: {type: {_eq: sales_organization}}, order_by: {name: asc}) {
        id
        name
        partner
        vendor
        subType: subscription
        subscriptionByOrganizationSubscription {
          valid_to
        }
        loginsSinceDate: organization_members(where: {user: {updated_at: {_gte: "${lastLoginDateActive}"}}},  distinct_on: user_id) {
          user {
            updated_at
          }
        }
        customer_simulations: organizationRelationsByOrganizationAId {
          customer: organization_b {
            simulations_aggregate {
              aggregate {
                count
              }
            }
          }
        }
        ${extraOptions.join('')}
      }
    }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .query<any>({
        query: q,
      })
      .pipe(
        map((value) => {
          if (value.errors) {
            console.error(value.errors[0].message);
            throw new Error(value.errors[0].message);
          } else {
            const data = ObjectUtils.cloneObject(value.data.organization);
            data.forEach((d: any) => {
              d.customers = d.robot_configurations_aggregate?.aggregate.count;
              d.members = d.organization_members_aggregate?.aggregate.count;
              d.simulations =
                d.simulations_aggregate?.aggregate.count +
                (d.customer_simulations
                  ? d.customer_simulations.reduce(
                      (acc: number, c: any) =>
                        acc + c.customer.simulations_aggregate?.aggregate.count,
                      0
                    )
                  : 0);
              d.simObject = {
                failed: d.errorSim?.aggregate.count,
                success: d.finishedSim?.aggregate.count,
              };
              d.subEx = d.subscriptionByOrganizationSubscription?.valid_to;
              d.activeSinceTimeSat = d.loginsSinceDate.length;
              delete d.loginsSinceDate;
            });

            return data;
          }
        })
      );
  }

  createCustomerOrganization(org: {
    name: string;
    subId: string;
    subscription: OrganizationSubscriptionType;
    metadata: {
      country: ICountryNState;
      address?: string;
      state?: ICountryNState;
    };
  }): Observable<string> {
    const m = gql`
    mutation createCustomerOrg($subId: uuid, $subscription: organization_subscription_type_enum) {
      insert_organization_one(object: {metadata: ${org.metadata}, name: "${org.name}", type: customer_organization, subscription_id: $subId, subscription: $subscription}) {
        id
      }
    }`;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          subId: org.subId,
          subscription: org.subscription,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.insert_organization_one.id;
          }
        })
      );
  }

  createSalesCustomerRelation(
    salesOrgId: string,
    customerOrgId: string
  ): Observable<any> {
    const m = gql`
      mutation relateOrganizations {
        insert_organization_relation(objects: {organization_a_id: "${salesOrgId}", organization_b_id: "${customerOrgId}", type: sales_customer}) {
          affected_rows
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        refetchQueries: [
          {
            query: GET_CUSTOMER_ORGS,
            variables: {
              salesOrgId: salesOrgId,
            },
          },
        ],
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            throw new Error(data.errors[0].message);
          } else {
            return data;
          }
        })
      );
  }

  createOrganizationSubscription(sub: {
    trial: boolean;
    valid_to: Date;
    type: OrganizationSubscriptionType;
    subscription_data: any;
  }): Observable<string> {
    const m = gql`
      mutation createOrganizationSubscription(
        $trial: Boolean
        $valid_to: timestamptz
        $subscription_type: organization_subscription_type_enum
        $subscription_data: jsonb
      ) {
        insert_organization_subscription_one(
          object: {
            trial: $trial
            valid_to: $valid_to
            subscription_type: $subscription_type
            subscription_data: $subscription_data
          }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          trial: sub.trial,
          valid_to: sub.valid_to.toISOString(),
          subscription_type: sub.type,
          subscription_data: sub.subscription_data,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.insert_organization_subscription_one.id;
          }
        })
      );
  }

  updateOrganizationSubscriptionType(
    orgId: string,
    type: OrganizationSubscriptionType
  ): Observable<string> {
    const m = gql`mutation updateOrganizationSubscriptionType($type: organization_subscription_type_enum) {
      update_organization_by_pk(pk_columns: {id: "${orgId}"}, _set: {subscription: $type}) {
        id
      }
    }`;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          type: type,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.update_organization_by_pk.id;
          }
        })
      );
  }

  updateOrganizationSubscriptionId(
    orgId: string,
    subId: string
  ): Observable<string> {
    const m = gql`
    mutation updateOrganizationSubscription {
      update_organization_by_pk(pk_columns: {id: "${orgId}"}, _set: {subscription_id: "${subId}"}) {
        id
      }
    }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return orgId;
          }
        })
      );
  }

  updateOrganizationSubscription(sub: {
    id: string;
    trial: boolean;
    valid_to: Date;
    type: OrganizationSubscriptionType;
    converted_from_trial: boolean;
    subscription_data: any;
  }): Observable<string> {
    const m = gql`
      mutation updateOrganizationSubscription(
        $id: uuid!
        $cft: Boolean!
        $type: organization_subscription_type_enum
        $trial: Boolean!
        $valid_to: timestamptz
        $subscription_data: jsonb
      ) {
        update_organization_subscription_by_pk(
          pk_columns: { id: $id }
          _set: {
            converted_from_trial: $cft
            subscription_type: $type
            trial: $trial
            valid_to: $valid_to
            subscription_data: $subscription_data
          }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          id: sub.id,
          cft: sub.converted_from_trial,
          type: sub.type,
          trial: sub.trial,
          valid_to: sub.valid_to,
          subscription_data: sub.subscription_data,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.update_organization_subscription_by_pk.id;
          }
        })
      );
  }

  createSalesOrganization(org: {
    name: string;
    subscription: string;
    subId: string;
    metadata: {
      country: ICountryNState;
      address?: string;
      state?: ICountryNState;
    };
    partner: boolean;
    vendor: boolean;
    vendor_filter_name?: string;
  }): Observable<string> {
    const m = gql`
    mutation createSalesOrg($metadata: json, $subId: uuid) {
      insert_organization_one(object: {subscription_id: $subId, metadata: $metadata, name: "${org.name}", partner: ${org.partner}, subscription: ${org.subscription}, type: sales_organization, vendor: ${org.vendor}, vendor_filter_name: "${org.vendor_filter_name}"}) {
        id
      }
    }`;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          metadata: org.metadata,
          subId: org.subId,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.insert_organization_one.id;
          }
        })
      );
  }

  mapSimulationsAggregateByState(
    customer?: {
      simulation_states: SimulationStatus[];
      customerTotalSims: number;
    },
    sales?: {
      simulation_states_map: ApiOrganization['simulationsByStatus'][];
      salesTotalSims: number;
    }
  ): ApiOrganization['simulationsByStatus'] {
    const expectedTotal =
      customer && customer.customerTotalSims !== undefined
        ? customer?.customerTotalSims
        : sales.salesTotalSims;
    let total = 0;

    const map: ApiOrganization['simulationsByStatus'] = {
      Created: 0,
      Initializing: 0,
      Running: 0,
      Finished: 0,
      Error: 0,
      Canceled: 0,
      Queued: 0,
      Validate: 0,
      Successful: 0,
      Setting_up: 0,
      Starting_robot: 0,
      Palletizing: 0,
    };

    if (customer) {
      customer.simulation_states.forEach((s: SimulationStatus) => {
        for (let status in map) {
          if (status === s) {
            map[status]++;
            total++;
          }
        }
      });
    }

    if (sales) {
      sales.simulation_states_map.forEach(
        (ss: ApiOrganization['simulationsByStatus']) => {
          for (let status in ss) {
            if (ss[status]) {
              map[status] += ss[status];
              total += ss[status];
            }
          }
        }
      );
    }

    if (total !== expectedTotal) {
      console.group('Total mismach:');
      console.error('Expected total: ' + expectedTotal);
      console.error('Calculated total: ' + total);
      console.error(sales.simulation_states_map, map);
      console.groupEnd();
      throw new Error('Total mismach');
    }

    return map;
  }

  getSalesOrganizationsSuperLight(): Observable<ApiOrganization[]> {
    const q = gql`
      query getSalesOrganizationsSuperLight {
        organization(where: { type: { _eq: sales_organization } }) {
          name
          id
          partner
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .query<{ organization: ApiOrganization[] }>({
        query: q,
      })
      .pipe(map((data) => data.data.organization));
  }

  getOrganizationById(id: string): Observable<ApiOrganization> {
    const q = gql`
      query getOrganizationById {
        customers: organization_relation(where: {organization_a_id: {_eq: "${id}"}}) {
          relationId: id
          customer: organization_b {
            simulations {
              average_pallet_time: simulation_status(path: "calculated.average_pallet_time")
              simulation_state
            }
          }
        }
        organization_by_pk(id: "${id}") {
          id
          name
          type
          created_at
          updated_at
          vendor
          vendor_filter_name
          partner
          metadata
          robot_brands
          organization_relations {
            id
            organization_a_id
          }
          subscription_details: subscriptionByOrganizationSubscription {
            id
            trial
            subscription_data
            subscription_type
            valid_to
          }
          organization_members_aggregate {
            aggregate {
              count(distinct: true, columns: user_id)
            }
          }
          robot_configurations_aggregate {
            aggregate {
              count
            }
          }
          logo: assets(where: { type: { _eq: logo } }) {
            url: url
          }
          simulations {
            average_pallet_time: simulation_status(path: "calculated.average_pallet_time")
            simulation_state
          }  
        }
      }
    `;
    return this.clientApi
      .useClient('rf_backoffice_edit')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            return null;
          } else {
            const org: ApiOrganization = new ApiOrganization(
              ObjectUtils.cloneObject(data.data?.organization_by_pk)
            );
            org.customers = ObjectUtils.cloneObject(data.data.customers);

            let customersTotalSimulationTime = 0;
            const customersAvgSimulationTime = [];

            let customersTotalSims = 0;

            org.customers.forEach((obj) => {
              const customer: ApiOrganization = obj['customer'];

              // Avg. time simulated
              const pallet_times = customer.simulations.map(
                (m) => m['average_pallet_time']
              );
              let total = 0;
              for (let i = 0; i < pallet_times.length; i++) {
                total += pallet_times[i];
              }
              const avg = isNaN(total / pallet_times.length)
                ? 0
                : total / pallet_times.length;

              customersAvgSimulationTime.push(avg);
              customersTotalSimulationTime += total;

              customersTotalSims += customer.simulations.length;
              customer.simulationsByStatus =
                this.mapSimulationsAggregateByState({
                  simulation_states: customer.simulations.map(
                    (s) => s.simulation_state
                  ) as SimulationStatus[],
                  customerTotalSims: customer.simulations.length,
                });
            });

            const formatTime = (time: number): string => {
              const split = time.toString().split('.');
              const minutes = split[0].substring(0, 2);
              const sec = split[1]?.substring(0, 2) || '00';

              const date = new Date();
              date.setMinutes(+minutes);
              date.setSeconds(+sec);
              return date.toISOString().substring(14, 19);
            };

            const avg = isNaN(
              customersTotalSimulationTime / customersAvgSimulationTime.length
            )
              ? 0
              : customersTotalSimulationTime /
                customersAvgSimulationTime.length;

            org.avg_simulation_time = formatTime(avg);
            org.total_simulation_time = formatTime(
              customersTotalSimulationTime
            );

            const customersSimulationsByStateMaps = org.customers
              .map((m) => m['customer'])
              .map((c) => c.simulationsByStatus);

            customersSimulationsByStateMaps.push(
              this.mapSimulationsAggregateByState({
                simulation_states: org.simulations.map(
                  (s) => s.simulation_state
                ) as SimulationStatus[],
                customerTotalSims: org.simulations.length,
              })
            );

            org.simulationsByStatus = this.mapSimulationsAggregateByState(
              null,
              {
                simulation_states_map: customersSimulationsByStateMaps,
                salesTotalSims: customersTotalSims + org.simulations.length,
              }
            );
            org.simulations_aggregate = {
              aggregate: {
                count: customersTotalSims + org.simulations.length,
              },
            };

            this.orgApi.validateOrReplaceOrgLogo([org]);
            return org;
          }
        }),
        catchError((err) => {
          console.error(err.message);
          return of(null);
        })
      );
  }

  public getPartnerSalesAreasById(
    orgId: string
  ): Observable<IPartnerSalesArea> {
    const q = gql`
      query GetPartnerSalesAreasById($organizationId: uuid!) {
        organization_partner_area(
          where: { organization_id: { _eq: $organizationId } }
        ) {
          continent
          country
          region
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .query<any>({
        query: q,
        variables: {
          organizationId: orgId,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else {
            const area = data.data?.organization_partner_area[0];
            return {
              continents: area?.continent || [],
              countries: area?.country || [],
              regions: area?.region || [],
            };
          }
        })
      );
  }

  public setPartnerSalesAreas(
    orgId: string,
    area: IPartnerSalesArea
  ): Observable<any> {
    const m = gql`
      mutation UpsertOrganizationPartnerArea(
        $orgId: uuid!
        $region: jsonb!
        $country: jsonb!
        $continent: jsonb!
      ) {
        insert_organization_partner_area(
          objects: {
            organization_id: $orgId
            region: $region
            country: $country
            continent: $continent
          }
          on_conflict: {
            constraint: organization_partner_area_organization_id_key
            update_columns: [region, country, continent]
          }
        ) {
          returning {
            organization_id
            region
            country
            continent
          }
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          orgId: orgId,
          region: area?.regions || [],
          country: area?.countries || [],
          continent: area?.continents || [],
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else return data.data;
        })
      );
  }

  /**
   * Returns all existing organizations and its related org. Used for BackOffice purposes.
   * @returns Array<IApiOrganization>
   */
  public getAllOrganizations(): Observable<IApiOrganization[]> {
    const q = gql`
      query getAllOrganizations {
        organization(order_by: { name: asc }) {
          id
          name
          type
          created_at
          organization_members(distinct_on: user_id) {
            user_id
            user {
              name
            }
          }
          organization_relations {
            organization_a_id
          }

          simulations_aggregate {
            aggregate {
              count
            }
          }
          partner
        }
      }
    `;
    return this.clientApi
      .useClient('rf_backoffice', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            throw new Error(data.errors[0].message);
          } else {
            return data.data?.organization;
          }
        })
      );
  }

  makeSalesOrgGraphQuery(orgId: string, queryString: string): string {
    return `organization_by_pk(id: "${orgId}") {
      ${queryString.toUpperCase()}: ${queryString}(order_by: {created_at: asc}) {
        created_at
      }
      customers: organizationRelationsByOrganizationAId {
          customer: organization_b {
            ${queryString.toUpperCase()}: ${queryString}(order_by: {created_at: asc}) {
              created_at
            }
          }
        }
    }
    `;
  }

  makeDashboardQuery(alias: bo_graphType, queryString: string): string {
    return `${alias}: dashboard_data(order_by: {timestamp: asc}) {
      ${queryString}
      timestamp
    }
    `;
  }

  getGraphsConfig(
    isSalesOrgDashboard: boolean = false
  ): Observable<IGraphConfig[]> {
    const soq = gql`
      query GetGraphsConfig {
        salesOrgDashboardGraphConfigCollection(order: orderNo_ASC) {
          items {
            series
            title
            type
            xAxisText
            yAxisText
          }
        }
      }
    `;

    const q = gql`
      query GetGraphsConfig {
        backofficeGraphConfigCollection(order: orderNo_ASC) {
          items {
            series
            title
            type
            xAxisText
            yAxisText
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query({
        query: isSalesOrgDashboard ? soq : q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0]);
            return [];
          } else {
            const items: IContentFulGraphConfig[] = ObjectUtils.cloneObject(
              isSalesOrgDashboard
                ? (data as any).data['salesOrgDashboardGraphConfigCollection']
                    .items
                : (data as any).data['backofficeGraphConfigCollection'].items
            );
            const config: IGraphConfig[] = [];

            items.forEach((v: IContentFulGraphConfig) => {
              const c: IGraphConfig = {
                type: v.type,
                optionsConfig: {
                  title: v.title,
                  xAxis: {
                    title: { text: v.xAxisText },
                    type: v.xAxisType || 'datetime',
                  },
                  yAxis: {
                    title: { text: v.yAxisText },
                  },
                  series: v.series,
                },
              };
              config.push(c);
            });

            return config;
          }
        })
      );
  }

  getGraphData(
    queryStrings: string[],
    config: IGraphConfig[],
    isMarketingConfig: boolean = true
  ): Observable<IGraphData> {
    const q = gql`
      query getDashboardData {
        ${queryStrings.join()}
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0]);
            return null;
          } else {
            const graphDataHelper = new GraphDataHelper(
              data.data,
              config,
              isMarketingConfig
            );
            return graphDataHelper.series;
          }
        })
      );
  }

  sendModelEmail(
    email: string,
    organization_id,
    type: ModelType,
    name: string,
    stp: string
  ): Observable<any> {
    const q = gql`
      query RequestModelUpload(
        $email: String!
        $organization_id: String!
        $type: ComponentType!
        $hw_name: String!
        $stp: String!
      ) {
        componentRequest(
          email: $email
          organization_id: $organization_id
          type: $type
          hw_name: $hw_name
          stp: $stp
        ) {
          success
          reason
        }
      }
    `;

    const variables = {
      email: email,
      organization_id: organization_id,
      type: type.id.toUpperCase(),
      hw_name: name,
      stp: stp,
    };

    return this.clientApi
      .useClient('org_edit')
      .query<any>({
        query: q,
        variables: variables,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            let message = data.errors[0].message;
            throw new Error(message);
          } else {
            return data.data;
          }
        })
      );
  }

  getGlobalNotifications(): Observable<IGlobalNotification[]> {
    const q = gql`
      subscription getGlobalNotifications {
        global_notification {
          id
          type
          message
          expires_at
          created_at
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(map((res) => res.data.global_notification));
  }

  deleteGlobalNotification(id) {
    const q = gql`
      mutation deleteGlobalNotification($id: uuid!) {
        delete_global_notification_by_pk(id: $id) {
          id
        }
      }
    `;
    return this.clientApi
      .useClient('rf_backoffice_edit', 'ws')
      .mutate<any>({
        mutation: q,
        variables: {
          id: id,
        },
      })
      .pipe(map((res) => res.data.delete_global_notification_by_pk));
  }

  createGlobalNotification(notification: {
    type: any;
    expiration_date: string;
    message: any;
  }): Observable<any> {
    const q = gql`
      mutation createGlobalNotification(
        $type: String!
        $expires_at: timestamptz!
        $message: String!
      ) {
        insert_global_notification_one(
          object: { type: $type, expires_at: $expires_at, message: $message }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit', 'ws')
      .mutate<any>({
        mutation: q,
        variables: {
          type: notification.type,
          expires_at: notification.expiration_date,
          message: notification.message,
        },
      })
      .pipe(map((res) => res.data.insert_global_notification_one));
  }

  updateGlobalNotification(notification: {
    id: any;
    type: any;
    expiration_date: string;
    message: any;
  }): Observable<any> {
    const q = gql`
      mutation updateGlobalNotification(
        $id: uuid!
        $type: String!
        $expires_at: timestamptz!
        $message: String!
      ) {
        update_global_notification_by_pk(
          pk_columns: { id: $id }
          _set: { type: $type, expires_at: $expires_at, message: $message }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit', 'ws')
      .mutate<any>({
        mutation: q,
        variables: {
          id: notification.id,
          type: notification.type,
          expires_at: notification.expiration_date,
          message: notification.message,
        },
      })
      .pipe(map((res) => res.data.update_global_notification_by_pk));
  }

  getGlobalNotification(id: string): Observable<IGlobalNotification> {
    const q = gql`
      query getGlobalNotification($id: uuid!) {
        global_notification_by_pk(id: $id) {
          id
          type
          message
          expires_at
          created_at
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice', 'ws')
      .query<any>({
        query: q,
        variables: {
          id: id,
        },
      })
      .pipe(map((res) => res.data.global_notification_by_pk));
  }

  createEmbedKey(embedkey: {
    owner: string;
    referrer: string;
  }): Observable<any> {
    const q = gql`
      mutation createEmbedKey($owner: uuid!, $referrer: String!) {
        insert_embed_api_keys_one(
          object: { owner: $owner, referrer: $referrer }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit', 'http')
      .mutate<any>({
        mutation: q,
        variables: {
          owner: embedkey.owner,
          referrer: embedkey.referrer,
        },
      })
      .pipe(map((res) => res.data.insert_embed_api_keys_one));
  }

  updateEmbedKey(embedkey: {
    id: string;
    owner: string;
    referrer: string;
    key: string;
  }): Observable<any> {
    const q = gql`
      mutation updateGlobalNotification(
        $id: uuid!
        $owner: uuid!
        $referrer: String!
        $key: uuid!
      ) {
        update_embed_api_keys_by_pk(
          pk_columns: { id: $id }
          _set: { owner: $owner, referrer: $referrer, key: $key }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit', 'http')
      .mutate<any>({
        mutation: q,
        variables: {
          id: embedkey.id,
          owner: embedkey.owner,
          key: embedkey.key,
          referrer: embedkey.referrer,
        },
      })
      .pipe(map((res) => res.data.update_embed_api_keys_by_pk));
  }

  getEmbedKeys(): Observable<IEmbedKey[]> {
    const q = gql`
      subscription getEmbedKeys {
        embed_api_keys {
          id
          owner
          key
          organization {
            id
            name
          }
          referrer
          created_at
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(map((res) => res.data.embed_api_keys));
  }

  getEmbedKey(id: string): Observable<IEmbedKey> {
    const q = gql`
      query getEmbedKey($id: uuid!) {
        embed_api_keys_by_pk(id: $id) {
          id
          organization {
            id
            name
          }
          key
          referrer
        }
      }
    `;

    return this.clientApi
      .useClient('rf_backoffice_edit', 'http')
      .query<any>({
        query: q,
        variables: {
          id: id,
        },
      })
      .pipe(map((res) => res.data.embed_api_keys_by_pk));
  }

  deleteEmbedKey(id: string) {
    const q = gql`
      mutation deleteEmbedKey($id: uuid!) {
        delete_embed_api_keys_by_pk(id: $id) {
          id
        }
      }
    `;
    return this.clientApi
      .useClient('rf_backoffice_edit', 'http')
      .mutate<any>({
        mutation: q,
        variables: {
          id: id,
        },
      })
      .pipe(map((res) => res.data.delete_embed_api_keys_by_pk));
  }

  public setRobotBrandsToOrganization(
    orgId: string,
    robotBrands: RobotBrandType[]
  ): Observable<any> {
    const m = gql`
      mutation SetRobotBrandsToOrganization(
        $orgId: uuid!
        $robotBrands: jsonb
      ) {
        update_organization_by_pk(
          pk_columns: { id: $orgId }
          _set: { robot_brands: $robotBrands }
        ) {
          id
        }
      }
    `;
    return this.clientApi
      .useClient('rf_backoffice_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          orgId: orgId,
          robotBrands: robotBrands,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else return data.data;
        })
      );
  }
}
