/**
 * The file will be downloaded from the browser to disk. The content of the
 * file will be the byte[] that is base64 encoded. Not the base64 encoded
 * string.
 * @param filename filename on disk
 * @param base64encodedData byte[] to save in file, encoded in base64
 */
export function downloadAsFile(filename: string, base64encodedData: string) {
  let data = base64encodedData;
  let element = document.createElement('a');
  element.setAttribute(
    'href',
    'data:application/octet-stream;charset=utf-8;base64,' +
      encodeURIComponent(data)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
