import { Type } from 'src/app/utils/type';
import { BaseBracketTask } from '../../classes/simconf/tasks/base-bracket-task';
import { ConveyorGuideTask } from '../../classes/simconf/tasks/conveyor-guide-task';
import { ConveyorBoxTask } from '../../classes/simconf/tasks/conveyor-box-task';
import { ConveyorTask } from '../../classes/simconf/tasks/conveyor-task';
import { FrameTask } from '../../classes/simconf/tasks/frame-task';
import { GripperTask } from '../../classes/simconf/tasks/gripper-task';
import { LiftkitTask } from '../../classes/simconf/tasks/liftkit-task';
import { OffsetBracketTask } from '../../classes/simconf/tasks/offset-bracket-task';
import { PalletLipTask } from '../../classes/simconf/tasks/pallet-lip-task';
import { PalletTask } from '../../classes/simconf/tasks/pallet-task';
import { RobotTask } from '../../classes/simconf/tasks/robot-task';
import { SceneTask } from '../../classes/simconf/tasks/scene-task';
import { SimConfigFieldIds } from '../../enums/simconfig-field-ids';
import { DimensionsTask } from '../../classes/simconf/tasks/dimensions-task';

interface ISimConfigTaskConfig {
  type: Type;
  data?: any;
  triggers?: SimConfigFieldIds;
  relatedDimVizID?: string;
  additionalTasks?: ISimConfigTaskConfig[];
}

interface ISimConfigTaskConfigEntry {
  ids: string[];
  config: ISimConfigTaskConfig;
}

export const simConfigTasks: ISimConfigTaskConfigEntry[] = [
  {
    ids: [SimConfigFieldIds.FrameType],
    config: { type: FrameTask },
  },
  {
    ids: [SimConfigFieldIds.LiftkitType],
    config: { type: LiftkitTask },
  },
  {
    ids: [SimConfigFieldIds.BasebracketHeight],
    config: { type: BaseBracketTask },
  },
  {
    ids: [SimConfigFieldIds.RobotType],
    config: { type: RobotTask },
  },
  {
    ids: [
      SimConfigFieldIds.AddOffsetBracket,
      SimConfigFieldIds.OffsetBracketType,
      SimConfigFieldIds.OffsetBracketWidth,
      SimConfigFieldIds.OffsetBracketHeight,
      SimConfigFieldIds.OffsetBracketLength,
      SimConfigFieldIds.OffsetBracketOffsetX,
      SimConfigFieldIds.OffsetBracketOffsetY,
      SimConfigFieldIds.OffsetBracketOffsetZ,
    ],
    config: { type: OffsetBracketTask },
  },
  {
    ids: [
      SimConfigFieldIds.GripperType,
      SimConfigFieldIds.GripperCustomWidth,
      SimConfigFieldIds.GripperCustomLength,
      SimConfigFieldIds.GripperCustomHeight,
      SimConfigFieldIds.GripperCustomPositionX,
      SimConfigFieldIds.GripperCustomPositionY,
      SimConfigFieldIds.GripperCustomPositionZ,
      SimConfigFieldIds.GripperCustomRotationR,
      SimConfigFieldIds.GripperCustomRotationP,
      SimConfigFieldIds.GripperCustomRotationY,
    ],
    config: { type: GripperTask },
  },
  {
    ids: [
      SimConfigFieldIds.PalletRightPositionX,
      SimConfigFieldIds.PalletRightPositionY,
      SimConfigFieldIds.PalletRightPositionZ,
      SimConfigFieldIds.PalletRightRotationX,
      SimConfigFieldIds.PalletRightRotationY,
      SimConfigFieldIds.PalletRightRotationZ,
      SimConfigFieldIds.PalletRightRotationW,
      SimConfigFieldIds.PalletLeftPositionX,
      SimConfigFieldIds.PalletLeftPositionY,
      SimConfigFieldIds.PalletLeftPositionZ,
      SimConfigFieldIds.PalletLeftRotationX,
      SimConfigFieldIds.PalletLeftRotationY,
      SimConfigFieldIds.PalletLeftRotationZ,
      SimConfigFieldIds.PalletLeftRotationW,
    ],
    config: { type: PalletTask },
  },
  {
    ids: [
      SimConfigFieldIds.PalletRightLipHeight,
      SimConfigFieldIds.PalletLeftLipHeight,
    ],
    config: { type: PalletLipTask },
  },
  {
    ids: [
      SimConfigFieldIds.ConveyorPrimaryType,
      SimConfigFieldIds.ConveyorPrimaryWidth,
      SimConfigFieldIds.ConveyorPrimaryLength,
      SimConfigFieldIds.ConveyorPrimaryHeight,
      SimConfigFieldIds.ConveyorPrimaryPositionX,
      SimConfigFieldIds.ConveyorPrimaryPositionY,
      SimConfigFieldIds.ConveyorPrimaryPositionZ,
      SimConfigFieldIds.ConveyorPrimaryRotationX,
      SimConfigFieldIds.ConveyorPrimaryRotationY,
      SimConfigFieldIds.ConveyorPrimaryRotationZ,
      SimConfigFieldIds.ConveyorPrimaryRotationW,
      SimConfigFieldIds.ConveyorSecondaryType,
      SimConfigFieldIds.ConveyorSecondaryWidth,
      SimConfigFieldIds.ConveyorSecondaryLength,
      SimConfigFieldIds.ConveyorSecondaryHeight,
      SimConfigFieldIds.ConveyorSecondaryPositionX,
      SimConfigFieldIds.ConveyorSecondaryPositionY,
      SimConfigFieldIds.ConveyorSecondaryPositionZ,
      SimConfigFieldIds.ConveyorSecondaryRotationX,
      SimConfigFieldIds.ConveyorSecondaryRotationY,
      SimConfigFieldIds.ConveyorSecondaryRotationZ,
      SimConfigFieldIds.ConveyorSecondaryRotationW,
      SimConfigFieldIds.DualProductMode,
    ],
    config: {
      type: ConveyorTask,
      additionalTasks: [{ type: ConveyorGuideTask }, { type: ConveyorBoxTask }],
    },
  },
  {
    ids: [
      SimConfigFieldIds.ConveyorPrimaryGuideSide,
      SimConfigFieldIds.ConveyorPrimaryGuideWidth,
      SimConfigFieldIds.ConveyorSecondaryGuideSide,
      SimConfigFieldIds.ConveyorSecondaryGuideWidth,
      // SceneFieldIds.ConveyorBoxFreeHeight
    ],
    config: {
      type: ConveyorGuideTask,
      additionalTasks: [{ type: ConveyorBoxTask }],
    },
  },
  {
    ids: [SimConfigFieldIds.ThemeType],
    config: { type: SceneTask },
  },
  {
    ids: [SimConfigFieldIds.ShowDimensions],
    config: { type: DimensionsTask },
  },
];
