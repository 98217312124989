import { IApiPallyPathStrategy } from 'src/app/models_new/classes/api-models/ApiPallyPathStrategy';
import { ObjectUtils } from 'src/app/utils/object';
import { defaultPallyPathStrategy } from '../default-pally-path-strategy';
import { IPallyPathStrategy } from 'src/app/models_new/types/pally-path-strategy';

export const defaultApiPallyPathStrategy: IApiPallyPathStrategy = {
  id: null,
  name: 'New Pally program setting',
  description: '',
  data: ObjectUtils.cloneObject(defaultPallyPathStrategy) as IPallyPathStrategy,
  owner_id: null,
  organization_id: null,
  created_at: null,
  updated_at: null,
};
