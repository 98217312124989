<table mat-table [dataSource]="dataSource" class="inventory-table" matSort>
  <!-- Checkbox Column -->
  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef class="select-cell">
      <mat-checkbox
        color="primary"
        (change)="$event ? toggleAllRows() : null"
        [checked]="selection.hasValue() && isAllSelected()"
        [indeterminate]="selection.hasValue() && !isAllSelected()"
        [aria-label]="checkboxLabel()"
      >
      </mat-checkbox>
    </th>
    <td
      class="select-cell"
      mat-cell
      *matCellDef="let row"
      (mouseenter)="isTooltipDisabled = true"
      (mouseleave)="isTooltipDisabled = false"
    >
      <mat-checkbox
        color="primary"
        (click)="$event.stopPropagation()"
        (change)="$event ? selection.toggle(row) : null"
        [checked]="selection.isSelected(row)"
        [aria-label]="checkboxLabel(row)"
      >
      </mat-checkbox>
    </td>
  </ng-container>

  <!-- Dynamic columns -->
  <ng-container *ngFor="let c of displayedColumns">
    <ng-container [matColumnDef]="c.path">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ c.label }}</th>

      <!-- Special cells -->
      <ng-container *ngIf="c.specialCell">
        <ng-container *ngFor="let special of c.specialCell">
          <td mat-cell *matCellDef="let element">
            <!-- Link cells -->
            <ng-container *ngIf="special.type === 'link'">
              <!-- If the path is an array -->
              <ng-container
                *ngIf="Array.isArray(getNestedValue(element, c.path))"
              >
                <!-- Display the first two items -->
                <ng-container
                  *ngFor="
                    let relation of getNestedValue(element, c.path).slice(0, 2)
                  "
                >
                  <mat-chip
                    (mouseenter)="isTooltipDisabled = true"
                    (mouseleave)="isTooltipDisabled = false"
                    class="stroked-chip link"
                    (click)="$event.stopPropagation()"
                    [routerLink]="special.link(relation)"
                  >
                    {{ relation.name }}
                  </mat-chip>
                </ng-container>

                <!-- Display the "+X" if there are more than two items -->
                <ng-container
                  *ngIf="getNestedValue(element, c.path).length > 2"
                >
                  +{{ getNestedValue(element, c.path).length - 2 }}
                </ng-container>
              </ng-container>

              <!-- If the path is NOT an array -->
              <ng-container
                *ngIf="!Array.isArray(getNestedValue(element, c.path))"
              >
                <mat-chip
                  (mouseenter)="isTooltipDisabled = true"
                  (mouseleave)="isTooltipDisabled = false"
                  class="stroked-chip link"
                  (click)="$event.stopPropagation()"
                  [routerLink]="special.link(element)"
                  >{{ getNestedValue(element, c.path) }}</mat-chip
                >
              </ng-container>
            </ng-container>

            <!-- Badge cells -->
            <ng-container *ngIf="special.type === 'badge'">
              <mat-chip
                [color]="special.color(element)"
                class="special-cell-badge"
                >{{ getNestedValue(element, c.path) }}</mat-chip
              >
            </ng-container>

            <!-- Gauge cells -->
            <ng-container *ngIf="special.type === 'gauge'">
              <svg width="40px" height="40px" viewBox="0 0 200 200">
                <circle
                  cx="100"
                  cy="100"
                  r="90"
                  stroke="#ddd"
                  stroke-width="10"
                  fill="none"
                />

                <path
                  d="M 100,100 m 0,-90 a 90,90 0 1,1 0,180 a 90,90 0 1,1 0,-180"
                  stroke="#4d9391"
                  stroke-width="10"
                  fill="none"
                  stroke-dasharray="565.48"
                  [attr.stroke-dashoffset]="
                    565.48 *
                    (1 - getNestedValue(element, c.path).replace('%', '') / 100)
                  "
                  stroke-linecap="round"
                />

                <text
                  x="50%"
                  y="50%"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  font-size="3em"
                  fill="#333"
                >
                  {{ getNestedValue(element, c.path) }}
                </text>
              </svg>
            </ng-container>

            <!-- Badge with gauge -->
            <ng-container *ngIf="special.type === 'badge-n-gauge'">
              <svg
                width="100"
                height="40"
                xmlns="http://www.w3.org/2000/svg"
                font-family="Montserrat"
                viewBox="0 0 130 40"
              >
                <!-- Badge with rounded corners -->
                <rect
                  x="0"
                  y="0"
                  width="130"
                  height="40"
                  rx="20"
                  ry="20"
                  [ngClass]="special.color(element)"
                />

                <!-- Background circle -->
                <circle
                  cx="20"
                  cy="20"
                  r="15"
                  stroke="gray"
                  stroke-width="2"
                  fill="none"
                />

                <!-- Foreground progress circle -->
                <path
                  d="M 20,20 m 0,-15 a 15,15 0 1,1 0,30 a 15,15 0 1,1 0,-30"
                  stroke="white"
                  stroke-width="2"
                  fill="none"
                  stroke-dasharray="94.2"
                  [attr.stroke-dashoffset]="
                    94.2 *
                    (1 -
                      getNestedValue(element, special.paths[0]).replace(
                        '%',
                        ''
                      ) /
                        100)
                  "
                  stroke-linecap="round"
                />

                <!-- Value if progress -->
                <text
                  x="20"
                  y="21"
                  font-weight="bold"
                  dominant-baseline="middle"
                  text-anchor="middle"
                  font-size="9"
                  fill="white"
                >
                  {{ getNestedValue(element, special.paths[0]) }}
                </text>

                <!-- Status text next to the gauge with margin -->
                <text
                  x="40"
                  y="24"
                  style="text-transform: uppercase"
                  font-weight="bold"
                  font-size="12"
                  fill="white"
                >
                  {{ getNestedValue(element, special.paths[1]) }}
                </text>
              </svg>
            </ng-container>
          </td>
        </ng-container>
      </ng-container>

      <!-- Normal cells -->
      <ng-container *ngIf="!c.specialCell">
        <td mat-cell *matCellDef="let element">
          {{ getNestedValue(element, c.path) }}
        </td>
      </ng-container>
    </ng-container>
  </ng-container>

  <!-- CTA button column -->
  <ng-container matColumnDef="cta">
    <th mat-header-cell *matHeaderCellDef></th>
    <td
      mat-cell
      *matCellDef="let element"
      class="cta-cell"
      (mouseenter)="isTooltipDisabled = true"
      (mouseleave)="isTooltipDisabled = false"
    >
      <ng-container *ngFor="let cta of rowCta">
        <ng-container *ngIf="!cta.disabled(element)">
          <button
            mat-stroked-button
            color="accent"
            class="row-cta"
            (click)="
              $event.stopPropagation();
              rowCtaAction.emit({ action: cta, row: element })
            "
          >
            <mat-icon>{{ cta.icon }}</mat-icon>
            {{ cta.label }}
          </button>
        </ng-container>
      </ng-container>
    </td>
  </ng-container>

  <!-- Actions column -->
  <ng-container matColumnDef="actions">
    <th mat-header-cell *matHeaderCellDef></th>
    <td
      mat-cell
      *matCellDef="let element"
      (mouseenter)="isTooltipDisabled = true"
      (mouseleave)="isTooltipDisabled = false"
    >
      <button
        *ngIf="rowActions.length > 0"
        mat-icon-button
        [matMenuTriggerFor]="blockMenu"
        (click)="$event.stopPropagation()"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #blockMenu="matMenu">
        <ng-container *ngFor="let action of rowActions">
          <ng-container *ngIf="!action.disabled(element)">
            <button
              mat-button
              class="action-button"
              [color]="action.color"
              (click)="rowAction.emit({ action: action, row: element })"
              *appIfCommandAvailable="
                action.roleAction ? action.roleAction : 'default_action'
              "
            >
              <mat-icon *ngIf="action.icon">{{ action.icon }}</mat-icon>
              <mat-icon *ngIf="!action.icon">chevron_right</mat-icon>
              {{ action.label }}
            </button>
            <ng-container *ngIf="action.divideAfter">
              <mat-divider></mat-divider>
            </ng-container>
          </ng-container>
        </ng-container>
      </mat-menu>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="columnPaths"></tr>
  <ng-container *ngIf="clickableRows">
    <tr
      mat-row
      *matRowDef="let row; columns: columnPaths"
      (click)="rowSelected.emit(row)"
      matTooltip="Click to view: {{ row.name }}"
      [matTooltipDisabled]="isTooltipDisabled"
      matTooltipPosition="below"
    ></tr>
  </ng-container>
  <ng-container *ngIf="!clickableRows">
    <tr
      class="no-click"
      mat-row
      *matRowDef="let row; columns: columnPaths"
    ></tr>
  </ng-container>
</table>
<mat-paginator></mat-paginator>
