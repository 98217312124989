import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { forkJoin, Observable, Subject } from 'rxjs';
import { filter, map, switchMap, take } from 'rxjs/operators';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import {
  ApiProduct,
  IApiProduct,
} from 'src/app/models_new/classes/api-models/ApiProduct';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { ProductApiService } from '../../../services/api/product-api.service';
import { NotificationService } from '../../../services/notification.service';
import { RoleApiService } from '../../../services/api/role-api.service';
import { FilterType, IFilterData } from 'src/app/models_new/types/filter-data';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';
import { ObjectUtils } from 'src/app/utils/object';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { UnitSystemLabelPipe } from 'src/app/pipes/unit-system-label.pipe';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import {
  InventoryTableDisplayColumn,
  InvetoryTableAction,
} from '../../gui/inventory-table/inventory-table.component';
import { SortDirection } from '@angular/material/sort';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-inventory-products',
  templateUrl: './inventory-products.component.html',
  styleUrls: ['./inventory-products.component.scss'],
})
export class InventoryProductsComponent implements OnInit, OnDestroy {
  products$: Observable<DataRequestState<ApiProduct[]>>;

  displayedColumns: InventoryTableDisplayColumn[] = [
    { label: 'Name', path: 'name', sortType: 'string' },
    { label: 'Length', path: 'data.length', sortType: 'number' },
    { label: 'Width', path: 'data.width', sortType: 'number' },
    { label: 'Height', path: 'data.height', sortType: 'number' },
    { label: 'Weight', path: 'data.weight', sortType: 'number' },
    {
      label: 'Pattern',
      path: 'patterns',
      sortType: 'number',
      specialCell: [
        {
          type: 'link',
          link: (e: { name: string; id: string }) => {
            return '/' + pagesPATH.PATTERNS + '/' + e.id;
          },
        },
      ],
    },
    { label: 'Updated At', path: 'updated_at', sortType: 'date' },
  ];

  rowActions: InvetoryTableAction[] = [
    {
      label: 'Duplicate',
      actionId: 'duplicate',
      roleAction: 'duplicate_product',
      icon: 'content_copy',
      divideAfter: true,
    },
    {
      label: 'Delete',
      actionId: 'delete',
      roleAction: 'delete_product',
      color: 'warn',
      icon: 'delete',
    },
  ];

  blockSelected: ApiProduct[] = [];
  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };

  destroy$: Subject<boolean> = new Subject<boolean>();
  objUtil = ObjectUtils;

  resultFilter: IFilterData[] = [
    {
      filterType: FilterType.SLIDER,
      id: 'data.height',
      label: 'Height',
      value: 0,
      min: 0,
      max: 1000,
    },
    {
      filterType: FilterType.SLIDER,
      id: 'data.length',
      label: 'Length',
      value: 0,
      min: 0,
      max: 1000,
    },
    {
      filterType: FilterType.SLIDER,
      id: 'data.width',
      label: 'Width',
      value: 0,
      min: 0,
      max: 1000,
    },
    {
      filterType: FilterType.SLIDER,
      id: 'data.weight',
      label: 'Weight',
      value: 0,
      min: 0,
      max: 1000,
    },
  ];

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'data.length',
      label: 'Length',
    },
    {
      id: 'data.width',
      label: 'Width',
    },
    {
      id: 'data.height',
      label: 'Height',
    },
    {
      id: 'data.weight',
      label: 'Weight',
    },
  ];

  constructor(
    private router: Router,
    private productApi: ProductApiService,
    private notifier: NotificationService,
    private route: ActivatedRoute,
    private roleApi: RoleApiService,
    private unitSystemLabelPipe: UnitSystemLabelPipe,
    private localStorageService: LocalStorageService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    const useImperial =
      this.localStorageService.getData(
        LocalStorageKey.PREFERRED_UNIT_SYSTEM
      ) === 'imperial';
    this.products$ = this.route.data.pipe(
      take(1),
      switchMap((data: Data) =>
        this.productApi.fetchProductsLight(
          (data as IOrganizationContextResolverData).organization_id,
          true
        )
      ),
      map((products: IApiProduct[]) => {
        products.map((p: ApiProduct) => {
          p.data.length = this.unitSystemLabelPipe.transform(
            p.data.length + ' mm'
          );
          p.data.width = this.unitSystemLabelPipe.transform(
            p.data.width + ' mm'
          );
          p.data.height = this.unitSystemLabelPipe.transform(
            p.data.height + ' mm'
          );
          p.data.weight = this.unitSystemLabelPipe.transform(
            useImperial ? p.data.weight * 0.001 + ' kg' : p.data.weight + ' g'
          );
          p.updated_at =
            this.datePipe.transform(p.updated_at, 'dd/MM/yyyy HH:mm') || 'N/A';
          return p;
        });
        return products;
      }),
      toRequestState()
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onDelete() {
    if (this.blockSelected) {
      if (this.blockSelected.length === 0) {
        this.notifier.showError('Nothing is selected!');
        return;
      }

      this.deleteProductsAction(this.blockSelected)
        .pipe(take(1))
        .subscribe((result) => {
          if (result[0]) {
            this.notifier.showMessage(
              `The products were deleted successfully!`
            );
          } else {
            this.notifier.showError(
              'Unable to delete product! Do you have the correct user privilege in your organization?'
            );
          }
        });
    }
  }

  actionClicked(e: { action: InvetoryTableAction; row: ApiProduct }) {
    if (e.action.actionId === 'modify') {
      // Modify action
      this.navigateToProduct(e.row.id);
    } else if (e.action.actionId === 'delete') {
      // Delete action
      this.deleteProductsAction([e.row])
        .pipe(take(1))
        .subscribe((result) => {
          if (result[0]) {
            this.notifier.showMessage(
              `The products were deleted successfully!`
            );
          } else {
            this.notifier.showError(
              'Unable to delete product! Do you have the correct user privilege in your organization?'
            );
          }
        });
    } else if (e.action.actionId === 'duplicate') {
      this.cloneProduct(e.row);
    }
  }

  cloneProduct(product: ApiProduct) {
    const cleanKeyValue = (obj, key) => {
      if (obj.hasOwnProperty(key) && typeof obj[key] === 'string') {
        const numericValue = obj[key].match(/^\d+/); // Extract numeric part
        return numericValue ? Number(numericValue[0]) : obj[key]; // Return number if match, otherwise original value
      }
      return obj[key];
    };

    this.productApi
      .createProduct(
        product.name + ' (copy)',
        {
          height: cleanKeyValue(product.data, 'height'),
          length: cleanKeyValue(product.data, 'length'),
          width: cleanKeyValue(product.data, 'width'),
          weight: cleanKeyValue(product.data, 'weight'),
          label_orientation: product.data.label_orientation,
          model_type: product.data.model_type,
        },
        this.route.snapshot.data.organization_id
      )
      .pipe(take(1))
      .subscribe({
        next: (_) => {
          this.notifier.showSuccess('The product was duplicated successfully');
        },
        error: (_error) => {
          this.notifier.showError(
            'Duplicate failed. Are you the owner of this item, and do you have the correct role in your organization?'
          );
        },
      });
  }

  rowClicked(e: any) {
    this.roleApi.availableActions$.pipe(take(1)).subscribe((actions) => {
      if (actions.find((f) => f === 'modify_product')) {
        this.navigateToProduct(e.id);
      }
    });
  }

  navigateToProduct(id: string): void {
    this.router.navigate([pagesPATH.PRODUCTS, id]);
  }

  deleteProductsAction(products: ApiProduct[]): Observable<any> {
    let nrPatterns = 0;
    let nrSimulations = 0;
    for (const product of products) {
      if (product.patterns) {
        nrPatterns += product.patterns.length;
        for (const pattern of product.patterns) {
          if (pattern.simulations) {
            nrSimulations += pattern.simulations.length;
          }
        }
      }
    }

    const dialogRef = this.notifier.deletePrompt(
      'Delete',
      'product',
      products.map((m) => m.name),
      {
        title: 'Deleting more items!',
        text: `This action will delete:\n- ${nrPatterns} patterns \n- ${nrSimulations} simulations`,
      }
    );
    return dialogRef.afterDismissed().pipe(
      take(1),
      filter(Boolean),
      switchMap(() => {
        return this.deleteProducts(products.map((m) => m.id));
      })
    );
  }

  deleteProducts(ids: string[]): Observable<any> {
    const operations = {};
    for (let i = 0; i < ids.length; i++) {
      operations[i] = this.productApi.deleteProduct(ids[i]);
    }
    return forkJoin(operations);
  }
}
