<app-page
  class="page-container"
  [class.dialog]="isDialog"
  [dynamicHeight]="true"
>
  <ng-container *ngIf="!route.snapshot.queryParams.strategyId">
    <app-breadcrumb-nav *ngIf="!isDialog"></app-breadcrumb-nav>
    <app-header>
      <h1 app-overline *ngIf="!isDialog">
        {{ this.pathStrategyId === 'new' ? 'Create' : 'Edit' }}
      </h1>
      <h2 app-title>{{ title }}</h2>
    </app-header>
  </ng-container>

  <mat-divider *ngIf="!route.snapshot.queryParams.strategyId"></mat-divider>

  <ng-container *ngIf="pathStrategy$ | async as strategy; else loading">
    <div class="form-wrapper" *appVar="isSaving$ | async as isSaving">
      <form
        [formGroup]="formGroup"
        class="form-group"
        [class.loading]="isSaving"
      >
        <app-edit-page>
          <h3 app-heading>Name your strategy</h3>
          <div app-content class="fields-section">
            <mat-form-field appearance="fill" style="width: 100%">
              <mat-label>Name</mat-label>
              <input matInput formControlName="name" />
              <mat-error>Required field!</mat-error>
            </mat-form-field>
          </div>
        </app-edit-page>

        <!-- ------------------------- PATH PLANNING ------------------------- -->
        <app-edit-page>
          <h3 app-heading>Path planning</h3>
          <div
            app-content
            *appVar="formGroup.get('path_planning') as pathPlanningGroup"
            class="fields-section"
          >
            <div class="field-wrapper flex-row full-width">
              <!-- ---------------------- ABOVE PICKUP ----------------------- -->
              <ng-container
                [ngTemplateOutlet]="numberField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Above pickup',
                  formControl: pathPlanningGroup.get('above_pickup'),
                  suffix: 'mm',
                  min: 0,
                }
              }"
              ></ng-container>
            </div>
            <div class="field-wrapper flex-row full-width">
              <!-- --------------------- BOX FREE HEIGHT --------------------- -->
              <ng-container
                [ngTemplateOutlet]="numberField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Box free height',
                  formControl: pathPlanningGroup.get('box_free'),
                  suffix: 'mm',
                  min: 0,
                }
              }"
              ></ng-container>

              <!-- ------------------- BOX FREE HEIGHT AUTO ------------------ -->
              <ng-container
                [ngTemplateOutlet]="toggleField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Box free auto',
                  formControl: pathPlanningGroup.get('box_free_auto'),
                }
              }"
              ></ng-container>
            </div>
            <div class="field-wrapper flex-row full-width">
              <!-- ------------------------ APPROACH ------------------------- -->
              <ng-container
                [ngTemplateOutlet]="numberField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Approach',
                  formControl: pathPlanningGroup.get('approach'),
                  suffix: 'mm',
                  min: 0,
                }
              }"
              ></ng-container>

              <!-- ---------------------- APPROACH AUTO ---------------------- -->
              <ng-container
                [ngTemplateOutlet]="toggleField"
                [ngTemplateOutletContext]="{
              $implicit: {
                label: 'Approach auto',
                formControl: pathPlanningGroup.get('approach_auto'),
              }
            }"
              ></ng-container>
            </div>
            <div class="field-wrapper flex-row half-width">
              <!-- ------------------- HIGH APPROACH BOOST ------------------- -->
              <ng-container
                [ngTemplateOutlet]="numberField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'High approach boost',
                  formControl: pathPlanningGroup.get('high_approach_boost'),
                  suffix: '%',
                  min: 0,
                  max: 100,
                }
              }"
              ></ng-container>
            </div>
            <div class="field-wrapper flex-row full-width">
              <!-- ----------------------- PALLET EDGE ----------------------- -->
              <ng-container
                [ngTemplateOutlet]="numberField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Pallet edge',
                  formControl: pathPlanningGroup.get('pallet_edge'),
                  suffix: 'mm',
                  min: 0,
                },
              }"
              ></ng-container>
            </div>
            <div
              class="field-wrapper flex-row half-width"
              style="margin-bottom: 1em"
            >
              <!-- ---------------------- QUICK RETURN ----------------------- -->
              <ng-container
                [ngTemplateOutlet]="toggleField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Quick return',
                  formControl: pathPlanningGroup.get('quick_return'),
                }
              }"
              ></ng-container>
            </div>
            <div class="field-wrapper flex-row full-width">
              <mat-expansion-panel>
                <mat-expansion-panel-header>
                  <mat-panel-title>Smart exits</mat-panel-title>
                </mat-expansion-panel-header>
                <span formGroupName="path_planning">
                  <div class="expansion-panel" formGroupName="smart_exit">
                    <app-config-preset-picker
                      app-content
                      [disableAdd]="true"
                      [presetType]="'smart exit'"
                      [presets]="smartExits$ | async"
                      (pickerSelect)="updateSmartExits($event)"
                    ></app-config-preset-picker>
                    <div class="panel-content-wrapper">
                      <div class="content-column" formGroupName="p1">
                        <mat-form-field appearance="fill">
                          <mat-label>Left pallet X from</mat-label>
                          <input
                            matInput
                            type="number"
                            min="-1000"
                            max="1000"
                            formControlName="min_x"
                          />
                          <mat-error>Min: -1000, Max: 1000</mat-error>
                          <span matTextSuffix>mm</span>
                          <span
                            matPrefix
                            style="padding: 10px"
                            *ngIf="changedFields.includes('left_min_x')"
                          >
                            <mat-icon color="accent" class="rotate"
                              >published_with_changes</mat-icon
                            >
                          </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                          <mat-label>Left pallet X to</mat-label>
                          <input
                            matInput
                            type="number"
                            min="-1000"
                            max="1000"
                            formControlName="max_x"
                          />
                          <mat-error>Min: -1000, Max: 1000</mat-error>
                          <span matTextSuffix>mm</span>
                          <span
                            matPrefix
                            style="padding: 10px"
                            *ngIf="changedFields.includes('left_max_x')"
                          >
                            <mat-icon color="accent" class="rotate"
                              >published_with_changes</mat-icon
                            >
                          </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                          <mat-label>Left pallet Y from</mat-label>
                          <input
                            matInput
                            type="number"
                            min="-1000"
                            max="1000"
                            formControlName="min_y"
                          />
                          <mat-error>Min: -1000, Max: 1000</mat-error>
                          <span matTextSuffix>mm</span>
                          <span
                            matPrefix
                            style="padding: 10px"
                            *ngIf="changedFields.includes('left_min_y')"
                          >
                            <mat-icon color="accent" class="rotate"
                              >published_with_changes</mat-icon
                            >
                          </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                          <mat-label>Left pallet Y to</mat-label>
                          <input
                            matInput
                            type="number"
                            min="-1000"
                            max="1000"
                            formControlName="max_y"
                          />
                          <mat-error>Min: -1000, Max: 1000</mat-error>
                          <span matTextSuffix>mm</span>
                          <span
                            matPrefix
                            style="padding: 10px"
                            *ngIf="changedFields.includes('left_max_y')"
                          >
                            <mat-icon color="accent" class="rotate">
                              published_with_changes
                            </mat-icon>
                          </span>
                        </mat-form-field>
                      </div>
                      <div class="content-column" formGroupName="p2">
                        <mat-form-field appearance="fill">
                          <mat-label>Right pallet X from</mat-label>
                          <input
                            matInput
                            type="number"
                            min="-1000"
                            max="1000"
                            formControlName="min_x"
                          />
                          <mat-error>Min: -1000, Max: 1000</mat-error>
                          <span matTextSuffix>mm</span>
                          <span
                            matPrefix
                            style="padding: 10px"
                            *ngIf="changedFields.includes('right_min_x')"
                          >
                            <mat-icon color="accent" class="rotate"
                              >published_with_changes</mat-icon
                            >
                          </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                          <mat-label>Right pallet X to</mat-label>
                          <input
                            matInput
                            type="number"
                            min="-1000"
                            max="1000"
                            matInput
                            formControlName="max_x"
                          />
                          <mat-error>Min: -1000, Max: 1000</mat-error>
                          <span matTextSuffix>mm</span>
                          <span
                            matPrefix
                            style="padding: 10px"
                            *ngIf="changedFields.includes('right_max_x')"
                          >
                            <mat-icon color="accent" class="rotate"
                              >published_with_changes</mat-icon
                            >
                          </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                          <mat-label>Right pallet Y from</mat-label>
                          <input
                            matInput
                            type="number"
                            min="-1000"
                            max="1000"
                            matInput
                            formControlName="min_y"
                          />
                          <mat-error>Min: -1000, Max: 1000</mat-error>
                          <span matTextSuffix>mm</span>
                          <span
                            matPrefix
                            style="padding: 10px"
                            *ngIf="changedFields.includes('right_min_y')"
                          >
                            <mat-icon color="accent" class="rotate"
                              >published_with_changes</mat-icon
                            >
                          </span>
                        </mat-form-field>
                        <mat-form-field appearance="fill">
                          <mat-label>Right pallet Y to</mat-label>
                          <input
                            matInput
                            type="number"
                            min="-1000"
                            max="1000"
                            matInput
                            formControlName="max_y"
                          />
                          <mat-error>Min: -1000, Max: 1000</mat-error>
                          <span matTextSuffix>mm</span>
                          <span
                            matPrefix
                            style="padding: 10px"
                            *ngIf="changedFields.includes('right_max_y')"
                          >
                            <mat-icon color="accent" class="rotate"
                              >published_with_changes</mat-icon
                            >
                          </span>
                        </mat-form-field>
                      </div>
                    </div>
                  </div>
                </span>
              </mat-expansion-panel>
            </div>
          </div>
        </app-edit-page>

        <app-edit-page>
          <h3 app-heading>Gripper</h3>
          <div
            *appVar="formGroup.get('gripper') as gripperGroup"
            app-content
            class="fields-section"
          >
            <div class="field-wrapper flex-column half-width">
              <!-- ---------------------- GRIP ROTATION ---------------------- -->
              <ng-container
                [ngTemplateOutlet]="selectionField"
                [ngTemplateOutletContext]="{
                  $implicit: {
                    label: 'Gripper rotation',
                    formControl: gripperGroup.get('grip_rot'),
                    options: [
                      { label: 'One way', value: 0 },
                      { label: 'Two way', value: 2 },
                      { label: 'Four way', value: 4 }
                    ]
                  }
                }"
              ></ng-container>

              <!-- ------------------------ MAX GRIP ------------------------- -->
              <ng-container
                [ngTemplateOutlet]="numberField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Max grip',
                  formControl: gripperGroup.get('max_grip'),
                  min: 0,
                }
              }"
              ></ng-container>

              <!-- -------------------- MIN GRIP QUALITY --------------------- -->
              <ng-container
                [ngTemplateOutlet]="numberField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Min grip quality',
                  formControl: gripperGroup.get('min_grip_quality'),
                  min: 0,
                }
              }"
              ></ng-container>

              <!-- ----------------------- FOAM HEIGHT ----------------------- -->
              <ng-container
                [ngTemplateOutlet]="numberField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Foam height',
                  formControl: gripperGroup.get('foam_height'),
                  suffix: 'mm',
                  min: 0,
                }
              }"
              ></ng-container>

              <!-- --------------------- LENGTH COVERAGE --------------------- -->
              <ng-container
                [ngTemplateOutlet]="numberField"
                [ngTemplateOutletContext]="{
                $implicit: {
                  label: 'Length coverage',
                  formControl: gripperGroup.get('length_coverage'),
                  suffix: '%',
                  min: 0,
                }
              }"
              ></ng-container>
            </div>
            <div
              class="field-wrapper flex-row full-width"
              style="margin-top: 1em"
            >
              <!-- ------------------- GRIP ROTATION COST -------------------- -->
              <ng-container
                [ngTemplateOutlet]="expansionPanel"
                [ngTemplateOutletContext]="{
                  $implicit: {
                    title: 'Grip rotation cost',
                    id: 'grip_rot_cost',
                    bodyTemplate: arrayField,
                    bodyContext: {
                      $implicit: {
                        fieldLabels: ['Front', 'Back', 'Left', 'Right'],
                        formGroup: gripRotCostFormGroup
                      }
                    }
                  }
                }"
              ></ng-container>
            </div>
            <div
              class="field-wrapper flex-column half-width"
              style="margin-top: 1em"
            >
              <!-- --------------------- ALIGN TO EDGES ---------------------- -->
              <ng-container
                [ngTemplateOutlet]="toggleField"
                [ngTemplateOutletContext]="{
              $implicit: {
                label: 'Align to edges',
                formControl: gripperGroup.get('align_to_edges'),
              }
            }"
              ></ng-container>
            </div>
          </div>
        </app-edit-page>
      </form>
      <div *ngIf="isSaving" class="saving-overlay">
        <app-loading></app-loading>
      </div>
    </div>
  </ng-container>

  <!-- --------------------------- LOADING STATE --------------------------- -->
  <ng-template #loading>
    <div class="page-about-wrapper">
      <app-loading style="margin-top: 4em"></app-loading>
    </div>
  </ng-template>
</app-page>

<ng-container *ngIf="isDialog; else pageActions">
  <div class="dialog-actions-container">
    <app-dialog-bottom-navbar
      [title]="title"
      [primaryButton]="pathStrategyId === 'new' ? 'confirm' : 'save'"
      [secondaryButton]="'cancel'"
      (nextClick)="saveStrategy()"
      (backClick)="closeDialog()"
    ></app-dialog-bottom-navbar>
  </div>
</ng-container>
<ng-template #pageActions>
  <app-wizard-bottom-navbar
    [title]="title"
    [primaryButton]="pathStrategyId === 'new' ? 'confirm' : 'save'"
    [secondaryButton]="pathStrategyId === 'new' ? null : 'back'"
    [primaryButtonDisabled]="
      formGroup?.invalid || gripRotCostFormGroup?.invalid
    "
    (nextClick)="saveStrategy()"
    (backClick)="goBack()"
  ></app-wizard-bottom-navbar>
</ng-template>

<!-- ----------------------------- NUMBER FIELD ---------------------------- -->
<ng-template #numberField let-config>
  <mat-form-field appearance="fill">
    <mat-label>{{ config.label }}</mat-label>
    <input
      matInput
      type="number"
      [min]="config.min"
      [max]="config.max"
      [formControl]="config.formControl"
      matRipple
    />
    <mat-error *ngIf="config.min !== undefined && config.max === undefined">
      Min: {{ config.min }}
    </mat-error>
    <mat-error *ngIf="config.min === undefined && config.max !== undefined">
      Max: {{ config.max }}
    </mat-error>
    <mat-error *ngIf="config.min !== undefined && config.max !== undefined">
      Min: {{ config.min }}, Max: {{ config.max }}
    </mat-error>
    <span matSuffix *ngIf="config.suffix">
      <span matTextSuffix>{{ config.suffix }}</span>

      <!-- TODO: Info popup -->
      <!--<button class="infoPopupButton" (click)="showInfo('max_speed')">
        <mat-icon svgIcon="info"></mat-icon>
      </button>-->
    </span>
  </mat-form-field>
</ng-template>

<!-- ----------------------------- TOGGLE FIELD ---------------------------- -->
<ng-template #toggleField let-config>
  <mat-slide-toggle
    [formControl]="config.formControl"
    [style.color]="'primary'"
  >
    {{ config.label }}
  </mat-slide-toggle>
</ng-template>

<!-- --------------------------- SELECTION FIELD --------------------------- -->
<ng-template #selectionField let-config>
  <mat-form-field appearance="fill">
    <mat-label>{{ config.label }}</mat-label>
    <mat-select matInput [formControl]="config.formControl" matRipple>
      <mat-option *ngFor="let v of config.options" [value]="v.value">
        {{ v.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<!-- ----------------------------- ARRAY FIELD ----------------------------- -->
<ng-template #arrayField let-config>
  <ng-container *ngFor="let i of config.fieldLabels.length | toIndexArray">
    <ng-container
      [ngTemplateOutlet]="numberField"
      [ngTemplateOutletContext]="{
        $implicit: {
          label: config.fieldLabels[i],
          formControl: config.formGroup.get(i.toString()),
          suffix: config.suffix,
          min: config.min,
          max: config.max,
        }
      }"
    ></ng-container>
  </ng-container>
</ng-template>

<!-- --------------------------- EXPANSION PANEL --------------------------- -->
<ng-template #expansionPanel let-config>
  <mat-expansion-panel
    class="expansion-panel"
    (opened)="expandedTab = config.id"
    [expanded]="expandedTab === config.id"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 app-heading>{{ config.title }}</h3>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="panel-content-wrapper">
      <ng-container
        [ngTemplateOutlet]="config.bodyTemplate"
        [ngTemplateOutletContext]="config.bodyContext"
      ></ng-container>
    </div>
  </mat-expansion-panel>
</ng-template>
