import { Component, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  skipWhile,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { StateService } from 'src/app/auth/state.service';
import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { RobotBrandType } from 'src/app/models_new/enums/robot-brand-types';
import { INavbarItem } from 'src/app/models_new/types/navbar-item';
import { SidenavService } from '../../../services/sidenav.service';
import { OrganizationType } from 'src/app/models_new/enums/organization-type';
import {
  mainMenuCustomer,
  mainMenuSales,
} from 'src/app/models_new/config/main-menu-nav-items';
import { ObjectUtils } from 'src/app/utils/object';
import { OrganizationApiService } from 'src/app/services/api/organization-api.service';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { pagesPATH } from 'src/app/models_new/config/pages';

@Component({
  selector: 'app-main-sidenav',
  templateUrl: './main-sidenav.component.html',
  styleUrls: ['./main-sidenav.component.scss'],
})
export class MainSidenavComponent implements OnDestroy {
  activePath$: Observable<any>;

  /** State and context vars */
  ready$: Observable<boolean>;
  sidenavItems: INavbarItem[];
  activeOrganization$: BehaviorSubject<ApiOrganization> = new BehaviorSubject(
    null
  );
  isBeingHovered$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private activeOrgBrands: RobotBrandType[];

  /** Organization lists */
  organizations: ApiOrganization[];
  salesOrganizations: ApiOrganization[];
  allUsersOrganizations: ApiOrganization[];
  unrelatedCustomerOrgs: ApiOrganization[];
  filteredOrgs: ApiOrganization[];
  filteredUnrelatedOrgs: ApiOrganization[];

  /** Notification badges */
  notifications: number = 0;
  private unhandledLeads: number = 0;

  private destroy$ = new Subject<boolean>();

  constructor(
    private router: Router,
    private stateService: StateService,
    public navbarService: SidenavService,
    private orgApiService: OrganizationApiService
  ) {
    this.activePath$ = this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      map((event) => event.url),
      startWith(this.router.url)
    );

    this.ready$ = this.stateService.isUserLogged$.pipe(
      // If there's no user logged, or current URL is supposed to be public (Open sims), skip.
      skipWhile(
        (isUserLogged) => !isUserLogged && window.location.href.includes('o=1')
      ),
      switchMap((_) => this.stateService.sales_organization$),
      switchMap((salesOrg: ApiOrganization) =>
        combineLatest([
          of(salesOrg),
          salesOrg?.id
            ? this.orgApiService.getNoOfUnhandledLeads(salesOrg.id)
            : of(0),
        ])
      ),
      switchMap(([salesOrg, unhandledLeads]) => {
        this.unhandledLeads = unhandledLeads;
        return combineLatest([
          this.stateService.organizations$,
          salesOrg
            ? this.orgApiService
                .getRelatedCustomerOrganizationsById(salesOrg?.id)
                .pipe(
                  takeUntil(this.destroy$),
                  map((orgs) =>
                    orgs.map(
                      (customer_org) => new ApiOrganization(customer_org)
                    )
                  )
                )
            : of(null),
          this.stateService
            .getCustomerOrSalesOrganization()
            .pipe(RXJSUtils.filterUndefinedAndNull()),
          this.stateService.activeBrands$.pipe(
            startWith(null),
            distinctUntilChanged()
          ),
        ]);
      }),
      map(([organizations, child_organizations, activeOrg, activeBrands]) => {
        if (organizations && activeOrg) {
          /** Gets the current active and unrelated organization & backs them up: */
          this.activeOrganization$.next(activeOrg);
          this.allUsersOrganizations = ObjectUtils.cloneObject(organizations);
          this.unrelatedCustomerOrgs = ObjectUtils.cloneObject(
            this.getUnrelatedCustomerOrganizations()
          );
          if (activeOrg.type === OrganizationType.CUSTOMER_ORGANIZATION) {
            /** Customer-organization-only user: */
            this.organizations = organizations;
          } else {
            /** Sales-organization user */
            this.organizations = child_organizations;
            this.salesOrganizations = organizations.filter(
              (o) => o.type === OrganizationType.SALES_ORGANIZATION
            );
            this.filteredOrgs = ObjectUtils.cloneObject(this.organizations);
            this.filteredUnrelatedOrgs = ObjectUtils.cloneObject(
              this.unrelatedCustomerOrgs
            );
          }
          this.activeOrgBrands = activeBrands;
        }
        if (activeOrg) {
          this.buildNavbarMenu(activeOrg.type);
          this.navbarService.isSidenavReady$.next(true);
          return true;
        } else return false;
      }),
      catchError((err) => {
        console.error(err);
        return of(false);
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private buildNavbarMenu(orgType): void {
    this.sidenavItems =
      orgType === OrganizationType.CUSTOMER_ORGANIZATION
        ? mainMenuCustomer
        : mainMenuSales;

    if (orgType === OrganizationType.SALES_ORGANIZATION) {
      // Leads item
      const leadsMenu = mainMenuSales.find((menu) => menu.label === 'Leads');
      if (leadsMenu) leadsMenu.badge = this.unhandledLeads;
      this.updateNotifications(this.unhandledLeads);
    } else this.updateNotifications(0, true);
  }

  private updateNotifications(
    notifications: number,
    clearAll: boolean = false
  ) {
    if (!clearAll) this.notifications = notifications;
    else this.notifications = 0;
  }

  public selectSalesOrganization(org: ApiOrganization) {
    this.stateService.setSalesOrganization(org);
    this.activeOrganization$.next(org);
  }

  public selectCustomerOrganization(org): void {
    this.stateService.setCustomerOrganization(org);
    this.activeOrganization$.next(org);
  }

  public applyTextFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue) {
      this.filteredOrgs = [];
      this.filteredOrgs = this.organizations.filter((o) =>
        o.name.toLowerCase().includes(filterValue.toLowerCase())
      );
      this.filteredUnrelatedOrgs = [];
      this.filteredUnrelatedOrgs = this.unrelatedCustomerOrgs.filter((o) =>
        o.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    } else {
      this.filteredOrgs = ObjectUtils.cloneObject(this.organizations);
      this.filteredUnrelatedOrgs = ObjectUtils.cloneObject(
        this.unrelatedCustomerOrgs
      );
    }
  }

  public navItemClick(item?: INavbarItem, activeOrg?: ApiOrganization): void {
    if (!item && activeOrg) {
      activeOrg.type === OrganizationType.SALES_ORGANIZATION
        ? this.router.navigate(['/', pagesPATH.CUSTOMERS])
        : this.router.navigate(['/', pagesPATH.CUSTOMERS, activeOrg.id]);
    } else if (item.path.includes('{organization_id}')) {
      this.stateService
        .getCustomerOrSalesOrganization()
        .pipe(take(1))
        .subscribe((org) => {
          const path = [...item.path];
          path[path.indexOf('{organization_id}')] = org.id;
          this.router.navigate(
            path,
            item.queryParams ? { queryParams: item.queryParams } : {}
          );
        });
      return;
    } else {
      this.router.navigate(
        item.path,
        item.queryParams ? { queryParams: item.queryParams } : {}
      );
    }
  }

  /**
   * @function getUnrelatedCustomerOrganizations
   * @desc Gets unrelated (to salesOrgs) customerOrgs which the user have access to. Example scenarions;
   * - A SalesOrg/Backoffice user gets permission into another SalesOrg's customer-organization.
   * - A user gets permission into a customer with no sales organization associated.
   * If this is not handled, many organizations will lay unreachable.
   * @returns {ApiOrganizations[]} - A collection of unrelated customer organizations, if any.
   */
  private getUnrelatedCustomerOrganizations(): ApiOrganization[] {
    return this.allUsersOrganizations.filter((org) => {
      return org.type == OrganizationType.CUSTOMER_ORGANIZATION &&
        (!org['organization_relations'].length ||
          org['organization_relations'].filter(
            (relation) =>
              this.allUsersOrganizations.find(
                (orgI) => orgI.id === relation.organization_a_id
              ) == undefined
          ).length)
        ? org
        : null;
    });
  }

  public checkOptionRenderByBrand(
    restrictedTo: RobotBrandType[] | null
  ): boolean {
    if (
      !this.activeOrgBrands ||
      this.activeOrgBrands.length === 0 ||
      !restrictedTo
    )
      return true;
    return restrictedTo?.every((brand) =>
      this.activeOrgBrands?.includes(brand)
    );
  }

  public checkActivePathItem(
    itemPath: INavbarItem['path'],
    activePath: string
  ): boolean {
    const uuidv4Pattern =
      /[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}/g;
    const organizationIdPattern = /{organization_id}/g;
    const normalizedActivePath = activePath?.startsWith('/')
      ? activePath.slice(1)
      : activePath;
    const itemPathString = Array.isArray(itemPath)
      ? itemPath.join('/')
      : itemPath;
    const simplifiedActivePath = normalizedActivePath
      .replace(uuidv4Pattern, '')
      .replace(organizationIdPattern, '')
      .split('?')[0]
      .replace(/\/$/, '');
    const simplifiedItemPath = itemPathString
      .replace(uuidv4Pattern, '')
      .replace(organizationIdPattern, '')
      .replace(/\/$/, '');
    return simplifiedItemPath === simplifiedActivePath;
  }
}
