import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toIndexArray',
})
export class ToIndexArrayPipe implements PipeTransform {
  transform(length: number | any[]): number[] {
    if (Array.isArray(length)) {
      return length.map((_, i) => i);
    }
    return Array(length)
      .fill(0)
      .map((_, i) => i);
  }
}
