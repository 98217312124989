import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { combineLatest, map, Observable, switchMap, take, of } from 'rxjs';
import { ApiScene } from '../../models_new/classes/api-models/ApiScene';
import { UpdateResponse } from './api.service';
import {
  ApiRobotConfiguration,
  IApiRobotConfiguration,
} from '../../models_new/classes/api-models/ApiRobotConfiguration';
import { ObjectUtils } from '../../utils/object';
import { ApiStrategy } from '../../models_new/classes/api-models/ApiStrategy';
import { RXJSUtils } from '../../utils/rxjs-utils';
import { defaultApiRobotConfiguration } from '../../models_new/config/default/api-default/defailt-api-robot-configuration';
import { defaultApiScene } from '../../models_new/config/default/api-default/default-api-scene';
import { defaultApiStrategy } from '../../models_new/config/default/api-default/default-api-strategy';
import { HardwareApiService } from './hardware-api.service';
import { SoftwareApiService } from './software-api.service';
import { defaultStrategy } from 'src/app/models_new/config/default/default-strategy';
import { ClientApiService } from './client-api.service';
import { PublicApiService } from './public-api.service';
import { toIQuarternion } from '../../utils/three-utils';
import { FileUtils } from '../../utils/file-utils';
import { AssetApiService } from 'src/app/services/api/asset-api.service';
import { getDefaultScene } from 'src/app/models_new/types/simulation-api-file-type';

const rcPickerWhitelist = [
  'dd29b5ac-ef0e-434e-8f4e-86373ab28e81', // Default
  'dd24adcb-5cb1-4a0a-968d-eaa4a75fa868', // Minimal
  'd7d4bb3d-1dfe-48a3-9ab8-7d38bbda8887', // Vention + Piab-400
];

@Injectable({
  providedIn: 'root',
})
export class RobotConfigApiService {
  constructor(
    private hardwareApiService: HardwareApiService,
    private softwareApiService: SoftwareApiService,
    private clientApi: ClientApiService,
    private publicApi: PublicApiService,
    private assetApi: AssetApiService
  ) {}

  fetchDefaultRobotConfigs(): Observable<ApiRobotConfiguration[]> {
    return this.publicApi.fetchDefaultSimConfigs().pipe(
      take(1),
      map(
        (configs) =>
          configs.filter((config) => rcPickerWhitelist.includes(config.id)) // Filtering specific rc`s
      ),
      map((configs) => {
        const list = [];
        for (const config of configs) {
          const rc = new ApiRobotConfiguration(defaultApiRobotConfiguration);
          rc.id = config.id;
          rc.name = config.name;
          rc.scene = new ApiScene(defaultApiScene);
          rc.scene.data = config.data.scene;
          rc.strategy = new ApiStrategy(defaultApiStrategy);
          rc.strategy.data = config.data.strategy;
          list.push(rc);
        }
        return list;
      })
    );
  }

  fetchDefaultAndPrivateRobotconfigs(
    organizationId: string = null
  ): Observable<ApiRobotConfiguration[]> {
    return combineLatest([
      this.fetchRobotconfigs(organizationId),
      this.fetchDefaultRobotConfigs(),
    ]).pipe(
      map(([rcs, defaultRCs]) => {
        return [...rcs, ...defaultRCs]; // Merge user robotconfigs with default configs
      }),
      RXJSUtils.filterUndefinedAndNull()
    );
  }

  getValidSceneImageUrl(
    robotConfigs: ApiRobotConfiguration[]
  ): Observable<ApiRobotConfiguration[]> {
    const validSceneAssets = robotConfigs.filter(
      (rc) =>
        rc.scene.image?.url &&
        FileUtils.validAzureBlobStoreUrl(rc.scene.image.url).valid
    );
    const invalidSceneAssets = robotConfigs.filter(
      (rc) =>
        !rc.scene.image?.url ||
        !FileUtils.validAzureBlobStoreUrl(rc.scene.image?.url).valid
    );

    const sources$: Observable<ApiRobotConfiguration>[] =
      invalidSceneAssets.map((rc: ApiRobotConfiguration) => {
        if (rc.scene.image) {
          return this.assetApi.getAsset(rc.scene.image?.id).pipe(
            take(1),
            map((result) => {
              rc.scene.image.url = result
                ? result
                : '../../../../assets/illustrative/simulation.png';
              return rc;
            })
          );
        } else {
          rc.scene.image = {
            id: '00000000-0000-0000-0000-000000000000',
            url: '../../../../assets/illustrative/simulation.png',
          };
          return of(rc);
        }
      });

    if (sources$.length < 1) {
      return of([...validSceneAssets]);
    } else if (validSceneAssets.length < 1) {
      return combineLatest([...sources$]);
    } else {
      return combineLatest([
        ...sources$,
        ...validSceneAssets.map((m) => of(m)),
      ]);
    }
  }

  /* ------------------------ Database query functions ------------------------ */

  subscribeInventoryRobotConfigs(
    customer_org_id: string
  ): Observable<ApiRobotConfiguration[]> {
    const q = gql`
      subscription GetRobotConfigs {
        robot_configuration(
          where: {
            _or: [
              { organization: { organizationRelationsByOrganizationAId: { organization_b_id: { _eq: "${customer_org_id}" } } } },
              { organization_id: { _eq: "${customer_org_id}" } }
            ]
          }
        ) {
          id
          name
          owner_organization: organization {
            id
            name
            }
          updated_at
          scene {
            id
            name
          }
          strategy {
            name
            id
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          // Error handling
          if (data.errors) {
            console.group('GetRobotConfigs failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get robot configurations: ' + data.errors[0].message
            );
          }

          // Data handling
          if (!data.data || data.data.robot_configuration.length < 1) {
            return [];
          }

          const list: ApiRobotConfiguration[] = [];
          for (const s of data.data.robot_configuration) {
            const robotconfig = new ApiRobotConfiguration(
              ObjectUtils.cloneObject(s)
            );
            list.push(robotconfig);
          }
          return list;
        })
      );
  }

  fetchRobotConfigBasic(id: string) {
    const q = gql`
    query fetchRobotConfigBasic {
      robot_configuration_by_pk(id: "${id}") {
        scene_id
        strategy_id
        name
      }
    }`;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.robot_configuration_by_pk;
          }
        })
      );
  }

  fetchSalesOrgRobotConfigurations(
    customer_org_id: string
  ): Observable<ApiRobotConfiguration[]> {
    const q = gql`
      subscription GetSalesOrgsRobotConfigurations {
        robot_configuration(where: {organization: {organizationRelationsByOrganizationAId: {organization_b_id: {_eq: "${customer_org_id}"}}}}) {
          id
          name
          organization_id
          updated_at
          owner_organization: organization {
            name
          }
          owner_id
          scene: scene {
            id
            data
            description
            name
            owner_id
            organization_id
            created_at
            updated_at
            image {
              id
              url
            }
          }
          strategy: strategy {
            updated_at
            owner_id
            organization_id
            name
            id
            description
            data
            created_at
          }
        }
      }   
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('GetSalesOrgsRobotConfigurations failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get sales orgs robot configurations: ' +
                data.errors[0].message
            );
          } else {
            const list = [];
            for (const s of data.data.robot_configuration) {
              const robotconfig = new ApiRobotConfiguration(
                ObjectUtils.cloneObject(s)
              );
              robotconfig.scene = new ApiScene(robotconfig.scene);
              robotconfig.strategy = new ApiStrategy(robotconfig.strategy);
              list.push(robotconfig);
            }
            return list;
          }
        }),
        switchMap((robotConfigs: ApiRobotConfiguration[]) => {
          return this.getValidSceneImageUrl(robotConfigs);
        })
      );
  }

  fetchRobotconfigs(
    organizationId: string = null
  ): Observable<ApiRobotConfiguration[]> {
    const q = gql`
      subscription GetRobotConfigs {
        robot_configuration {
          id
          name
          organization_id
          updated_at
          owner_organization: organization {
            name
          }
          owner_id
          scene: scene {
            id
            data
            description
            name
            owner_id
            organization_id
            created_at
            updated_at
            image {
              id
              url
            }
          }
          strategy: strategy {
            updated_at
            owner_id
            organization_id
            name
            id
            description
            data
            created_at
          }
        }
      }
    `;

    const qf = gql`
      subscription GetRobotConfigs {
        robot_configuration(where: {organization_id: {_eq: "${organizationId}"}}) {
          id
          name
          organization_id
          updated_at
          owner_organization: organization {
            name
          }
          owner_id
          scene: scene {
            id
            data
            description
            name
            owner_id
            organization_id
            created_at
            updated_at
            image {
              id
              url
            }
          }
          strategy: strategy {
            updated_at
            owner_id
            organization_id
            name
            id
            description
            data
            created_at
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: organizationId ? qf : q,
      })
      .pipe(
        map((data) => {
          const list: ApiRobotConfiguration[] = [];
          for (const s of data.data.robot_configuration) {
            const robotconfig = new ApiRobotConfiguration(
              ObjectUtils.cloneObject(s)
            );
            robotconfig.scene = new ApiScene(robotconfig.scene);
            robotconfig.strategy = new ApiStrategy(robotconfig.strategy);
            list.push(robotconfig);
          }
          return list;
        }),
        switchMap((robotConfigs: ApiRobotConfiguration[]) => {
          return this.getValidSceneImageUrl(robotConfigs);
        })
      );
  }

  public fetchRobotconfigsLight(
    organizationId: string = null
  ): Observable<any[]> {
    const q = gql`
      subscription GetRobotConfigs {
        robot_configuration(where: {organization_id: {_eq: "${organizationId}"}}) {
          id
          name
          owner_organization: organization {
            name
          }
          owner_id
          scene: scene {
            id
            data
            name
            image {
              id
              url
            }
          }
          strategy: strategy {            
            name
            id
            data
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (!data?.data) return [];
          return data.data.robot_configuration;
        })
      );
  }

  fetchRobotconfigByID(
    id: string,
    lite: boolean = false
  ): Observable<ApiRobotConfiguration> {
    const qlite = gql`
      subscription GetRobotConfigByID($id: uuid!) {
        robot_configuration_by_pk(id: $id) {
          id
          name
          organization_id
          updated_at
          owner_organization: organization {
            name
          }
          owner_id
          scene: scene {
            id
          }
          strategy: strategy {
            id
          }
        }
      }
    `;

    const q = gql`
      subscription GetRobotConfigByID($id: uuid!) {
        robot_configuration_by_pk(id: $id) {
          id
          name
          organization_id
          updated_at
          owner_organization: organization {
            name
          }
          owner_id
          scene: scene {
            id
            data
            description
            name
            owner_id
            organization_id
            created_at
            updated_at
            image {
              id
              url
            }
          }
          strategy: strategy {
            updated_at
            owner_id
            organization_id
            name
            id
            description
            data
            created_at
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: lite ? qlite : q,
        variables: { id: id },
      })
      .pipe(
        map((data: any) => {
          const robotconfig = new ApiRobotConfiguration(
            ObjectUtils.cloneObject(data.data.robot_configuration_by_pk)
          );
          robotconfig.scene = new ApiScene(robotconfig.scene);
          robotconfig.strategy = new ApiStrategy(robotconfig.strategy);
          return robotconfig;
        }),
        switchMap((robotConfig: ApiRobotConfiguration) => {
          if (!lite) {
            return this.getValidSceneImageUrl([robotConfig]).pipe(
              map((rcs) => rcs[0])
            );
          }

          return of(robotConfig);
        })
      );
  }

  fetchRobotconfigInSceneAndStrategy(
    sceneId: string,
    strategyId: string
  ): Observable<any> {
    const q = gql`
      query getRobotconfigInSceneAndStrategy(
        $sceneId: uuid!
        $strategyId: uuid!
      ) {
        robot_configuration(
          where: {
            scene_id: { _eq: $sceneId }
            _and: { strategy_id: { _eq: $strategyId } }
          }
        ) {
          id
          name
        }
      }
    `;
    const variables = {
      sceneId: sceneId,
      strategyId: strategyId,
    };
    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
        variables: variables,
      })
      .valueChanges.pipe(map((data) => data.data?.robot_configuration[0]));
  }

  deleteRobotConfig(id: string): Observable<IApiRobotConfiguration> {
    const m = gql`
    mutation deleteRobotConfig {
      delete_robot_configuration_by_pk(id: "${id}") {
        name
        id
      }
    }`;

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0]);
            throw data.errors[0];
          } else {
            return data.data.delete_robot_configuration_by_pk;
          }
        })
      );
  }

  insertRobotConfig(
    name: string,
    org_id: string,
    scene_id: string,
    strategy_id: string
  ): Observable<IApiRobotConfiguration> {
    const m = gql`
    mutation insertRobotConfig {
      insert_robot_configuration_one(object: {name: "${name}", organization_id: "${org_id}", scene_id: "${scene_id}", strategy_id: "${strategy_id}"}) {
        id
        name
      }
    }`;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
      })
      .pipe(map((data) => data.data.insert_robot_configuration_one));
  }

  updateRobotConfig(
    id: string,
    name: string,
    scene_id: string,
    strategy_id: string
  ): Observable<UpdateResponse> {
    const m = gql`
    mutation updateRobotConfiguration($name: String!, $scene_id: uuid!, $strategy_id: uuid!) {
      update_robot_configuration_by_pk(pk_columns: {id: "${id}"}, _set: {name: $name,  scene_id: $scene_id, strategy_id: $strategy_id}) {
        id
      }
    }
    `;

    const variables = {
      name,
      scene_id,
      strategy_id,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((value) => value.data.update_robot_configuration_by_pk));
  }

  /** Inserts robot config with given name for given organization. New scene and strategy with default values are also created, and referenced */
  createNewRobotConfig(
    name: string,
    org_id: string,
    scene?: ApiScene,
    strategy?: ApiStrategy
  ): Observable<IApiRobotConfiguration> {
    const sceneData = scene ? scene.data : getDefaultScene();
    const strategyData = strategy
      ? strategy.data
      : ObjectUtils.cloneObject(defaultStrategy);

    // THREE.Quaternions produce "_x" properties when converted to JSON.
    // So we need to convert them manually
    for (const conveyor of sceneData.conveyors) {
      conveyor.box_corner_pose.orientation = toIQuarternion(
        conveyor.box_corner_pose.orientation as THREE.Quaternion
      );
    }
    for (const pallet of sceneData.place_targets) {
      pallet.corner_base_pose.orientation = toIQuarternion(
        pallet.corner_base_pose.orientation as THREE.Quaternion
      );
    }
    strategyData.waiting_position.orientation = toIQuarternion(
      strategyData.waiting_position.orientation as THREE.Quaternion
    );

    return combineLatest([
      this.hardwareApiService.insertScene(
        scene.name || name,
        sceneData,
        org_id
      ),
      this.softwareApiService.insertStrategy(
        strategy.name || name,
        '',
        strategyData,
        org_id
      ),
    ]).pipe(
      switchMap(([sceneResponse, strategyResponse]) =>
        this.insertRobotConfig(
          name,
          org_id,
          sceneResponse.id,
          strategyResponse.id
        )
      )
    );
  }
}
