import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  FormControlStatus,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import {
  map,
  Observable,
  of,
  shareReplay,
  skipWhile,
  Subject,
  switchMap,
  take,
  takeUntil,
  tap,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { IApiOpenSimulation } from 'src/app/models_new/classes/api-models/ApiOpenSimulation';
import { Field } from 'src/app/models_new/classes/field';
import { defaultApiOpenSimulation } from 'src/app/models_new/config/default/api-default/default-api-open-simulation';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { FieldType } from 'src/app/models_new/types/field-type';
import {
  InfoApiService,
  IOpenSimContent,
} from 'src/app/services/api/info-api.service';
import {
  LocalStorageService,
  StorageMethod,
} from 'src/app/services/local-storage.service';
import { ObjectUtils } from 'src/app/utils/object';
import {
  ICurrentStep,
  IReadyOpenSimulation,
  OpenSimService,
} from '../open-sim.service';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { DialogService } from 'src/app/services/dialog.service';
import { CaptchaDialogComponent } from '../../dialogs/captcha-dialog/captcha-dialog.component';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { NotificationService } from 'src/app/services/notification.service';
import { IOpenSimPage } from 'src/app/models_new/config/fast-track-pages';
import { transformToSpacedValue } from 'src/app/utils/unit-utils';

@Component({
  selector: 'app-fast-track-user-info',
  templateUrl: './fast-track-user-info.component.html',
  styleUrls: ['./fast-track-user-info.component.scss'],
})
export class FastTrackUserInfoComponent implements OnInit, OnDestroy {
  dataReady$: Observable<DataRequestState<Field[]>>;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});
  destroy$: Subject<boolean> = new Subject<boolean>();
  solutions$: Observable<DataRequestState<IReadyOpenSimulation[]>>;

  tableDataSource: IReadyOpenSimulation[];
  cardState: 'fields' | 'loading' | 'explore' = 'fields';
  exploreContent$: Observable<IOpenSimContent>;
  showTableActions: boolean = false;
  displayedColumns = ['name', 'starting_price'];
  currentPageContent$: Observable<IOpenSimPage>;
  #currentStep$: Observable<ICurrentStep>;

  slideOut: boolean = false;

  constructor(
    private localStorage: LocalStorageService,
    private infoApi: InfoApiService,
    private openSimService: OpenSimService,
    private route: ActivatedRoute,
    private dialogService: DialogService,
    private notificationService: NotificationService,
    public appLayout: AppLayoutService
  ) {}

  ngOnInit(): void {
    this.#currentStep$ = this.openSimService
      .setStepById('user-details')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.currentPageContent$ = this.openSimService
      .getPageContentByStepId('user-details')
      .pipe(shareReplay({ bufferSize: 1, refCount: true }));

    this.exploreContent$ = this.openSimService.pagesSortedList$.pipe(
      map(
        (m: IOpenSimPage[]) =>
          m.find((page) => page.contentfulStepId === 'simulations-details')
            ?.content
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    if (this.openSimService.readySimulations$ === undefined) {
      this.#currentStep$.pipe(
        map((step) =>
          this.openSimService.findAndNavigateToPreviousPage(step.index)
        )
      );
    }

    this.solutions$ = this.openSimService.readySimulations$.pipe(
      takeUntil(this.destroy$),
      map((sims: IReadyOpenSimulation[]) => {
        let solutionsMetadata: [
          { id: string; price?: string; externalUrl?: string }?
        ] = [];
        if (sims?.length !== this.tableDataSource?.length) {
          let hasParams = false;
          const queryParams = this.route.snapshot.queryParams;
          if (
            queryParams.vn ||
            queryParams.si ||
            queryParams.sp ||
            queryParams.vi
          ) {
            hasParams = true;
          }
          sims.forEach((sim, i) => {
            sim.progress = `${sim.progress || 0}%`;
            sim.name = hasParams ? sim.solution.name : `Solution No. ${i + 1}`;
            sim.cpm = `${sim?.cpm || '~'}`;
            sim.eta = sim?.eta ? `${sim?.eta} min` : '~';
            sim.startingPrice = sim.solution.metadata?.price
              ? `${sim.solution.metadata?.currency} ${transformToSpacedValue(
                  sim.solution.metadata?.price + ''
                )}`
              : '~';
            sim.externalUrl = hasParams ? sim.solution.externalUrl : null;
            solutionsMetadata.push({
              id: sim.solution.name,
              price: sim.startingPrice,
              externalUrl: sim.solution.externalUrl || null,
            });
          });
          this.tableDataSource = ObjectUtils.cloneObject(sims);
          this.localStorage.setData(
            LocalStorageKey.FASSTRACK_SOLUTION_METADATA,
            solutionsMetadata,
            StorageMethod.SESSION
          );
        }
        return this.tableDataSource;
      }),
      toRequestState(),
      tap((_) => {
        if (_.error) {
          console.error(_.error);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.dataReady$ = this.#currentStep$.pipe(
      switchMap((currentStep) =>
        combineLatest([
          this.#currentStep$,
          this.openSimService.prevIsValid(currentStep.index),
        ])
      ),
      take(1),
      tap(([step, isValid]) => {
        if (!isValid) {
          this.openSimService.findAndNavigateToPreviousPage(step.index);
        }
      }),
      switchMap((_) =>
        forkJoin([
          this.infoApi.getLandingPageInfo().pipe(
            take(1),
            map((info) => info.gdprLink)
          ),
          this.infoApi.getPhaseOfResearchConfig().pipe(take(1)),
          of(
            this.localStorage.getData(LocalStorageKey.OPEN_SIM) ||
              defaultApiOpenSimulation
          ),
        ])
      ),
      map(([gdprLink, phaseOfResearchConfig, openSim]) => {
        const fields = this.makeFields(
          gdprLink,
          openSim,
          phaseOfResearchConfig
        );
        fields.forEach((f: Field) => {
          this.formGroup.addControl(f.id, f.formControl);
        });
        return fields;
      }),
      toRequestState(),
      tap((_) => {
        if (_.error) {
          console.error(_.error);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.dataReady$
      .pipe(
        skipWhile((r) => !r.value),
        take(1),
        switchMap((_) => this.formGroup.statusChanges),
        takeUntil(this.destroy$),
        tap((isValid: FormControlStatus) => {
          if (isValid === 'VALID') {
            this.openSimService.setStepValidity(true);
          } else {
            this.openSimService.setStepValidity(false);
          }
        }),
        switchMap((_) => this.formGroup.valueChanges),
        tap((value: any) => {
          const openSim: IApiOpenSimulation =
            this.localStorage.getData(LocalStorageKey.OPEN_SIM) || {};
          openSim.name = value.full_name;
          openSim.email = value.email;
          openSim.organization_name = value.organization_name;
          openSim.phase_of_research = value.phase_of_research;

          this.localStorage.setData(
            LocalStorageKey.OPEN_SIM,
            openSim,
            StorageMethod.LOCAL
          );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /**
   * Sorts and triggers simulations based on the selected page index.
   * It adds an event to the gas service and starts the simulations if the selected page is both done and valid.
   */
  private sortAndTriggerSimultions(): void {
    combineLatest([
      this.openSimService.pagesSortedList$,
      this.openSimService.currentStep$,
    ])
      .pipe(take(1))
      .subscribe(([pagesSortedList, currentStep]) => {
        if (
          pagesSortedList[currentStep.index].done &&
          pagesSortedList[currentStep.index].valid
        ) {
          this.openSimService.next();
        }
      });
  }

  public checkCaptchaAndSubmit(): void {
    this.dialogService
      .showCustomDialog(
        CaptchaDialogComponent,
        DialogSize.SMALL,
        null,
        { isLoggable: false },
        true
      )
      .afterClosed()
      .subscribe((validated) => {
        if (!validated) {
          this.notificationService.showError(
            `Couldn't verify you are human. Try again`
          );
        } else {
          this.slideOut = true;
          setTimeout(() => this.sortAndTriggerSimultions(), 500);
        }
      });
  }

  public navigateTo(url: string): void {
    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      url = 'http://' + url;
    }
    window.open(url, '_blank');
  }

  private makeFields(
    gdprLink: string,
    openSim: IApiOpenSimulation,
    phase_of_research: { title: string; options: string[] }
  ): Field[] {
    return [
      new Field(
        FieldType.TEXT,
        true,
        openSim.name,
        [Validators.required],
        null,
        null,
        null,
        { name: 'full-name', label: 'Full name' },
        'full_name'
      ),
      new Field(
        FieldType.TEXT,
        true,
        openSim.email,
        [Validators.required, Validators.email],
        null,
        [],
        null,
        { name: 'email', label: 'Email address' },
        'email'
      ),
      new Field(
        FieldType.TEXT,
        true,
        openSim.organization_name,
        [Validators.required],
        null,
        null,
        null,
        { name: 'organization', label: 'Organization name' },
        'organization_name'
      ),
      new Field(
        FieldType.SELECT_SINGLE,
        true,
        openSim.phase_of_research,
        [Validators.required],
        null,
        phase_of_research.options,
        null,
        { name: 'phase_of_research', label: phase_of_research.title },
        'phase_of_research'
      ),
      new Field(
        FieldType.CHECKBOX,
        true,
        null,
        [Validators.required, Validators.requiredTrue],
        null,
        [],
        null,
        {
          name: 'gdpr',
          label: `I agree to the <a href="${gdprLink}" target="_blank">terms and conditions</a>`,
        },
        'gdpr'
      ),
    ];
  }
}
