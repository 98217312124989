import * as THREE from 'three';
import { ISimulationApiFileType } from '../../types/simulation-api-file-type';

export const defaultStrategy: ISimulationApiFileType['strategy'] = {
  safety_preset: 'LessRestricted',
  software: {
    polyscope_version: 'latest',
    pally_version: 'latest',
  },
  auto_sim: true,
  smart_acceleration: 'Medium',
  gripper_optimization: '4_WAY',
  above_pick_position_mm: 100,
  smart_exits: {
    left_pallet: {
      x: {
        from: 100,
        to: 700,
      },
      y: {
        from: 50,
        to: 250,
      },
    },
    right_pallet: {
      x: {
        from: 100,
        to: 700,
      },
      y: {
        from: 50,
        to: 250,
      },
    },
  },
  max_speed: 1500,
  max_acceleration: 1500,
  number_of_pallets: 2,
  high_approach_boost_percentage: 50,
  approach_distance: 30,
  fixed_approach: true,
  box_free_height: 200,
  waiting_position: {
    position: new THREE.Vector3(),
    orientation: new THREE.Quaternion(),
  },
  lifting_column: {
    dynamic_positioning: 1,
  },
  use_custom_grip_delay: false,
  grip_delay: null,
  grip_release_delay: null,
  use_shim_paper_delay: false,
  shim_paper_delay: null,
};
