// Original source: https://stackoverflow.com/a/23106997
// Modified by Kent Wincent Holt @ Rocketfarm AS
// Date: 24.08.2020

import { Subject } from 'rxjs';
import { Type } from './type';

export enum ListChange {
  Add,
  Remove,
  Clear,
  Pop,
  Shift,
}

export interface IListChange {
  type: ListChange;
  data?: any;
}

/**
 * Generic list class which wraps and extends "Array"s functionalities.
 */
export class List<T> implements Iterable<T>, Iterator<T> {
  private items: Array<T>;

  private iteratorIndex = 0;
  private ordered = false;
  private sortFN: (a: T, b: T) => number;

  public forEach;
  public every;
  public some;
  public filter;
  public sort;
  public map;

  public change$: Subject<IListChange> = new Subject<IListChange>();

  constructor(content?: Array<T>) {
    if (Type.isDefined(content)) {
      this.items = content;
    } else {
      // Prevents unecessary GC, if "content" isn't defined
      this.items = new Array<T>();
    }

    this.forEach = this.items.forEach.bind(this.items); // Convenience forEach function
    this.every = this.items.every.bind(this.items); // Convenience every function
    this.some = this.items.some.bind(this.items); // Convenience some function
    this.filter = this.items.filter.bind(this.items); // Convenience filter function
    this.sort = this.items.sort.bind(this.items); // Convenience sort function
    this.map = this.items.map.bind(this.items); // Convenience map function
  }

  setOrdered(sortFN: (a: T, b: T) => number): void {
    this.ordered = !sortFN;
    this.sortFN = sortFN;
  }

  /**
   * Get the size of the list.
   *
   * @returns - number - Size of list.
   */
  public size(): number {
    return this.items.length;
  }

  /**
   * Whether a value is contained or not.
   *
   * @param value - T - Value to look for.
   * @param predicate - (item: T) => boolean - Custom predicate function.
   *
   * @returns - number - Size of list.
   */
  public has(value: T, predicate?: (item: T) => boolean): boolean {
    return (
      null !=
      this.find(
        Type.isDefined_NotNull(predicate)
          ? predicate
          : (item: T) => item === value
      )
    );
  }

  /**
   * Gets an item in the list.
   *
   * @param index - number - Index of the item.
   *
   * @returns - T - Item at given index.
   */
  public get(index: number): T {
    if (index < 0 || index > this.size()) {
      // Invalid index.
      return null;
    }
    return this.items[index];
  }

  /**
   * Pops off the last item in the list.
   *
   * @returns - T - Last item in list.
   */
  public pop(): T {
    const index = this.items.length - 1;
    const item = this.items.pop();
    this.change$.next({
      type: ListChange.Pop,
      data: { item: item, index: index },
    });
    return item;
  }

  /**
   * Pops off the first item in the list.
   *
   * @returns - T - First item in list.
   */
  public shift(): T {
    const item = this.items.shift();
    this.change$.next({
      type: ListChange.Shift,
      data: { item: item, index: 0 },
    });
    return item;
  }

  /**
   * Adds the item to the list.
   *
   * @param item - T - Item to add.
   *
   * @returns - number - Index of added item.
   */
  public add(item: T): number {
    const index = this.items.push(item) - 1;
    if (this.ordered) {
      this.items.sort(this.sortFN);
    }
    this.change$.next({
      type: ListChange.Add,
      data: { item: item, index: index },
    });
    return index; // Add and return index
  }

  /**
   * Removes an item from the list.
   *
   * @param item - T - Item to be removed.
   */
  public remove(item: T): void {
    const index = this.items.indexOf(item);
    if (index !== -1) {
      this.items.splice(index, 1); // Remove one of the specifed item, if found

      this.change$.next({
        type: ListChange.Remove,
        data: { item: item, index: index },
      });
    }
  }

  /**
   * Clears the list.
   */
  public clear(): void {
    // Slice out all items from list
    this.items.slice(0, this.items.length - 1);
    this.change$.next({ type: ListChange.Clear });
  }

  /**
   * Finds the index of an item.
   *
   * @param item - T - Item to be searched for.
   *
   * @returns number - Index of that item.
   */
  public getIndexOf(item: T): number {
    return this.items.indexOf(item);
  }

  /**
   * Finds an item fulfilling the given predicate callback function.
   * If none do, null is returned.
   *
   * @param predicate - (item: T) => boolean - Search predicate function.
   *
   * @returns - T - First item the predicate function returns true for.
   */
  public find(predicate: (item: T) => boolean): T {
    let resItem = null;

    for (const item of this.items) {
      if (predicate(item)) {
        resItem = item;
      }
    }

    return resItem; // Null if no item is found.
  }

  /**
   * Finds all items fulfilling the given predicate callback function.
   * If none do, null is returned.
   *
   * @param predicate - (item: T) => boolean - Search predicate function.
   *
   * @returns - List<T> - All items the predicate function returns true for.
   */
  public findAll(predicate: (item: T) => boolean): List<T> {
    const list = new List<T>();

    for (const item of this.items) {
      if (predicate(item)) {
        list.add(item);
      }
    }

    return list; // Null if no item is found.
  }

  /**
   * Checks list if it contains a specific item.
   *
   * @param item - T - Object to check for.
   *
   * @returns - boolean - true, if contained, false otherwise.
   */
  public contains(item: T): boolean {
    return this.items.indexOf(item) >= 0;
  }

  /**
   * Appends list to this list.
   *
   * @param item - List\<T\> | T[] | T - List, Array or value to be concatenated.
   */
  public concat(items: List<T> | T[] | T): void {
    if (items instanceof List) {
      this.items = this.items.concat(items.getRawArray());
    } else if (Array.isArray(items)) {
      this.items = this.items.concat(items);
    } else {
      // Array or singular value
      this.items = this.items.concat(items);
    }
  }

  /**
   * Getter for the raw internal array.
   *
   * @returns - Array\<T\> - Raw JS Array with item of type "T".
   */
  public getRawArray(): Array<T> {
    return this.items;
  }

  // ########## Iterator functions ##########
  [Symbol.iterator](): Iterator<T> {
    /*
     * New list as "iteratorIndex" isn't reset on returning from a for-of loop.
     * Not allowing the for-loop to "finish" (reach done condition)
     * means "iteratorIndex" is not reset.
     *
     * Keep in mind the original array is still referenced!
     */
    return new List<T>(this.items);
  }

  public next(_value?: any): IteratorResult<T> {
    const temp = this.items[this.iteratorIndex]; // Get current item

    this.iteratorIndex++; // Update next index

    // Index of current item is with-in bounds
    if (this.iteratorIndex - 1 < this.items.length) {
      return { value: temp, done: false }; // Return item
    }

    this.iteratorIndex = 0; // Done iterating, reset iteration index.
    return { value: null, done: true };
  }
}
