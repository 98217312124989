import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-radio-check',
  templateUrl: './radio-check.component.html',
  styleUrls: ['./radio-check.component.scss'],
})
export class RadioCheckComponent implements OnInit {
  radioState: 'checked' | 'unchecked' | 'loading' = 'unchecked';
  @Input() isChecked: boolean;
  @Input() isLoading?: boolean;

  ngOnInit(): void {
    this.radioState = this.isLoading
      ? 'loading'
      : this.isChecked
      ? 'checked'
      : 'unchecked';
  }
}
