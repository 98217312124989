import { Injectable } from '@angular/core';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  switchMap,
  take,
  tap,
  throwError,
} from 'rxjs';
import {
  AssetApiService,
  assetData,
  IUploadAsset,
} from './api/asset-api.service';
import { NotificationService } from './notification.service';
import { DialogService } from './dialog.service';
import { InstalledRobotsApiService } from './api/robot-installations-api.service';
import { CalibrationsApiService } from './api/calibrations-api.service';
import { Router } from '@angular/router';
import { ImportFileDialogComponent } from '../components/dialogs/import-file-dialog/import-file-dialog.component';
import { DialogSize } from '../models_new/enums/dialogSize';
import { sanitizeUploadNames } from '../utils/div';
import {
  ApiCalibration,
  ICalibrationFileData,
} from '../models_new/classes/api-models/ApiCalibration';
import { pagesPATH } from '../models_new/config/pages';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';
import { LocalStorageService } from './local-storage.service';
import { RobotType } from '../models_new/types/robot-type';
import { FileUtils } from '../utils/file-utils';

/**
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
|       Aspect          |                        uploadCalibrationFile                             |                     uploadBackupFiles                              |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| File Type             | Handles `.json` calibration files.                                       |  Handles `.zip` backup files.                                      |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| Purpose               | Processes calibration files to update robot configurations               |  Processes backup files for restoring robot configurations         |
|                       | and save calibration data.                                               |  or creating new robots.                                           |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| Dialog Configuration  | Prompts for `.json` files with a label like                              |  Prompts for `.zip` files with a similar label.                    |
|                       | "Drag and drop your backup files here."                                  |                                                                    |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| Workflow              | - Parses the uploaded JSON file.                                         |  - Uploads the `.zip` file.                                        |
|                       | - Validates calibration file content.                                    |   - Updates or creates robots using backup data.                   |
|                       | - Updates or creates installed robots.                                   |   - Fetches and validates license information.                     |
|                       | - Links calibration to the robot.                                        |                                                                    |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| Data Validation       | Verifies that the calibration file contains a                            |  Verifies the presence of a valid `robot_serial_number` in         |
|                       | valid `serial_no` and raises an error if missing.                        |   the backup and raises an error if missing.                       |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| Robot Update/Create   | Uses `insertInstalledRobotsFromCalibration`                              |  Uses `createNewRobot` or updates an existing robot with           |
|                       | or updates an existing robot with `updateRobotId`.                       |  `updatedRobot`.                                                   |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| APIs Involved         | Calls `irApiService.insertInstalledRobotsFromCalibration` and            |  Calls `uploadAssets`, `updatedRobot`, or `createNewRobot`         |
|                       | `calibrationsApiService.insertCalibrations`.                             |  APIs, and fetches license info with `irApiService`.               |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| Success Workflow      | - Shows a success notification.                                          |  - Shows a success notification.                                   |
|                       | - Optionally routes to inventory if `routeToInventory` is true.          |  - Optionally redirects to robot verification if configuration     |
|                       |                                                                          |  exists.                                                           |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| Error Handling        | Handles errors such as missing `serial_no` in the                        |  Handles errors such as missing `robot_serial_number` and          |
|                       | calibration file and notifies the user.                                  |  notifies the user, including validation issues for licenses.      |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| Output                | Returns the `calibrationId` if the process is successful.                |  Returns the `robot_configuration_id` if the process is            |
|                       |                                                                          |  successful.                                                       |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
| Customization         | Allows updating a specific robot using `updateRobotId`.                  |  Includes options to skip redirection using `skipRedirect`.        |
+-----------------------+--------------------------------------------------------------------------+--------------------------------------------------------------------+
*/

type InstalledRobotResult = { id: string; name: string };

@Injectable({
  providedIn: 'root',
})
export class RobotBackupAssistantService {
  msgId: string | void;

  constructor(
    private assetApiService: AssetApiService,
    private notification: NotificationService,
    private dialogService: DialogService,
    private irApiService: InstalledRobotsApiService,
    private calibrationsApiService: CalibrationsApiService,
    private router: Router,
    private localstoreService: LocalStorageService
  ) {}

  downloadBackupFiles(
    robotType: RobotType,
    robotName: string,
    robot_id: string,
    org_id: string
  ) {
    this.notification.showMessage('Downloading backup file...', 5000, true);

    if (robotType === 'DOOSAN') {
      // Remove .json in the filename.
      robotName = robotName.replace('.json', '');

      this.calibrationsApiService
        .fetchCalibrationByRobotId(robot_id, org_id)
        .pipe(
          take(1),
          map((calibrations) => {
            if (!calibrations.length) {
              this.notification.showError('No calibration found!');
              return null;
            } else {
              return calibrations[0];
            }
          })
        )
        .subscribe((c: ApiCalibration) => {
          FileUtils.downloadJson(
            c.data,
            `${robotType}_${robotName}_${c.created_at}`
          );
          this.notification.showSuccess('Backup files downloaded!');
        });
    } else if (robotType === 'UR') {
      // Remove .zip in the filename.
      robotName = robotName.replace('.zip', '');

      this.irApiService
        .fetchInstalledRobotBackupZipId(robot_id)
        .pipe(
          take(1),
          switchMap((backupZipId: string) =>
            this.assetApiService.getAsset(backupZipId)
          ),
          map((asset: string) => {
            return FileUtils.base64ToFile(
              asset,
              'backup.zip',
              'application/zip'
            );
          })
        )
        .subscribe({
          next: (zip) => {
            FileUtils.downloadBlobFile(zip, `${robotType}_${robotName}`);
            this.notification.showSuccess('Backup files downloaded!');
          },
          error: (_err) => {
            this.notification.showError('Failed to download backup files.');
          },
        });
    } else {
      this.notification.showError(
        'Unknown robot type. Cannot download backup files.'
      );
    }
  }

  showUploadDialog(strictFileType?: 'json' | 'zip'): Observable<assetData[]> {
    const acceptFileType = strictFileType
      ? [`.${strictFileType}`]
      : ['.json', '.zip'];

    const uploadDialogRef = this.dialogService.showCustomDialog(
      ImportFileDialogComponent,
      DialogSize.MEDIUM,
      null,
      {
        label: 'Drag and drop your files here',
        fileCategory: 'mixed',
        accept: acceptFileType, // Accept both .json and .zip files
        sizeLimit: 52428800, // 50 MB
        showSuccessDialog: false,
      },
      false
    );

    // Wait for the user to submit the files
    return (
      uploadDialogRef.componentInstance as ImportFileDialogComponent
    ).submitted$.pipe(
      take(1), // Ensure the observable completes after one submission
      tap(() => uploadDialogRef.close()) // Automatically close the dialog after submission
    );
  }

  /**
   * Use this function to upload a backup file and/or calibration file.
   * @param orgId The organization ID.
   * @param name The name of the backup.
   * @param routeToInventory If true, the function will redirect to the inventory page after uploading.
   * @param updateRobotId The installed robot ID. If given, the robot will be updated.
   * @returns An array of calibration IDs.
   * @example
   * uploadBackupGeneric({
   *  orgId: 'org_id',
   *  name: 'backup_name',
   *  updateRobotId: 'robot_id',
   *  strictFileType: 'json'
   * })
   * .subscribe((calibrationIds: string[]) => {
   *  console.log(calibrationIds);
   * });
   */
  uploadBackupGeneric(p: {
    orgId: string;
    name: string;
    updateRobotId: string;
    strictFileType?: 'json' | 'zip';
  }): Observable<string[]> {
    return this.showUploadDialog(p.strictFileType).pipe(
      switchMap((assets: assetData[]) => {
        const sources$: Observable<string>[] = [];
        assets.forEach((asset) => {
          if (asset.file_type === 'application/json') {
            sources$.push(
              this.uploadCalibrationFile(
                asset,
                p.orgId,
                p.name,
                p.updateRobotId
              )
            );
          }
          if (asset.file_type === 'application/zip') {
            sources$.push(
              this.uploadBackupFiles(asset, p.orgId, p.name, p.updateRobotId)
            );
          }
        });

        return combineLatest([...sources$]);
      })
    );
  }

  /**
   * Use this function when uploading a calibration file (.json) to configure or update a robot’s calibration data.
   * @param orgId The organization ID.
   * @param name The name of the calibration.
   * @param robotId The robot ID.
   * @returns the calibration ID.
   */
  uploadCalibrationFile(
    asset: assetData,
    orgId: string,
    name: string,
    robotId: string
  ): Observable<string> {
    return combineLatest([
      // Use filename as backup name.
      of({
        name: name ?? sanitizeUploadNames(asset.name),
        data: JSON.parse(atob(asset.data)),
      }),
      of(orgId),
    ]).pipe(
      switchMap(
        ([calibrationFileInfo, org_id]: [
          { name: string; data: ICalibrationFileData },
          string
        ]) => {
          if (!calibrationFileInfo.data.file_info?.serial_no) {
            this.notification.showError(
              'Missing serial number in calibration file! Please try again. Contact MRC support if the error persists.'
            );

            return throwError(
              () => new Error('Missing serial number in calibration file!')
            );
          }

          const ir$: Observable<InstalledRobotResult> = of({
            id: robotId,
            name: name,
          });
          return combineLatest([ir$, of(calibrationFileInfo), of(org_id)]);
        }
      ),
      switchMap(
        ([installedRobot, calibrationFileInfo, org_id]: [
          InstalledRobotResult,
          { name: string; data: ICalibrationFileData },
          string
        ]) =>
          combineLatest([
            of(installedRobot),
            this.calibrationsApiService.insertCalibrations(
              org_id,
              installedRobot.id,
              calibrationFileInfo.data
            ),
            of(calibrationFileInfo),
          ])
      ),
      take(1),
      switchMap(([installedRobot, calibrationId, calibrationFileInfo]) =>
        this.updateInstalledRobotCalibration(
          installedRobot.id,
          calibrationId,
          calibrationFileInfo.data.file_info.serial_no,
          calibrationFileInfo.name
        )
      )
    );
  }

  private updateInstalledRobotCalibration(
    installedRobotId: string,
    calibrationId: string,
    serialNo: string,
    calibrationName: string
  ): Observable<string> {
    return this.irApiService.updateInstalledRobotsCalibration(
      installedRobotId,
      calibrationId,
      serialNo,
      calibrationName
    );
  }

  private verifyBackupData(asset: IUploadAsset): boolean {
    const backupData = asset.backup_data;
    if (!backupData?.robot_serial_number) {
      this.notification.showError(
        'Missing robot serial number in backup! Please try again. Contact MRC support if the error persists.'
      );
      return false;
    }
    if (!backupData?.mac_address) {
      this.notification.showError(
        'Missing MAC address in backup! Please try again. Contact MRC support if the error persists.'
      );
      return false;
    }

    if (!backupData?.robot_configuration_id) {
      this.notification.showError(
        'Missing robot configuration ID in backup! Please try again. Contact MRC support if the error persists.'
      );
      return false;
    }

    return true;
  }

  /**
   * Use this function when uploading a backup file (.zip) to restore a robot configuration or create a new robot.
   * @param orgId The organization ID.
   * @param name The name of the robot.
   * @param installed_robot_id The installed robot ID. If given, the robot will be updated.
   * @param skipRedirect If true, the function will not redirect to the robot verification page.
   * @returns The robot configuration ID.
   */
  uploadBackupFiles(
    asset: assetData,
    orgId: string,
    name: string,
    installed_robot_id?: string,
    showCalibrationVerification?: boolean
  ): Observable<string> {
    this.msgId = this.notification.showMessage(
      'Uploading backup file...',
      'infinity',
      true
    );

    asset.name = name;
    asset.asset_type = 'zip';

    return this.assetApiService.uploadAsset(asset, 'org_edit', true).pipe(
      take(1),
      switchMap((asset: IUploadAsset) => {
        if (asset.id === null || asset.url === null) {
          return throwError(() => new Error('Failed to upload backup file.'));
        }

        if (!this.verifyBackupData(asset)) {
          return throwError(
            () => new Error('Invalid backup data. Please try again.')
          );
        }

        // TODO: Faking calibration data for now. Just need something to show in configurations table.
        const calibrationData = {
          file_info: {
            pally_version: null,
            serial_no: asset.backup_data.robot_serial_number,
          },
          robot: {
            id: asset.backup_data.robot_configuration_id,
            name: name,
            conveyors: [
              {
                type: null,
              },
              {
                type: null,
              },
            ],
          },
          calibration: null,
        } as unknown as ICalibrationFileData;

        return combineLatest([
          this.calibrationsApiService.insertCalibrations(
            orgId,
            installed_robot_id,
            calibrationData
          ),
          of(asset), // Forwards the asset data
        ]);
      }),
      switchMap(([calibrationId, asset]) =>
        this.irApiService
          .updateInstalledRobots(
            installed_robot_id,
            name,
            orgId,
            asset.backup_data.robot_configuration_id,
            asset.backup_data.robot_serial_number,
            asset.backup_data.mac_address,
            asset.id,
            calibrationId
          )
          .pipe(
            take(1),
            catchError((error) => {
              this.notification.showError(error);
              return throwError(
                () => new Error('Failed to update robot configuration.')
              );
            })
          )
      ),
      map((robotUpdate) => {
        if (robotUpdate.id) {
          if (showCalibrationVerification) {
            this.localstoreService.setData(LocalStorageKey.IR_ROBOT_NAME, name);
            this.router.navigate([
              pagesPATH.INSTALLED_ROBOTS,
              pagesPATH.VERIFY,
              robotUpdate.installed_robots_robot_configuration.id,
            ]);
          }
          this.notification.deleteMessage(this.msgId as string);
          this.notification.showSuccess('Backup file uploaded!');
        } else {
          this.notification.showError(
            'No robot configuration was made. Contact MRC support if this issue persists.'
          );
        }

        return robotUpdate.id;
      }),
      catchError((error) => {
        this.notification.showError(error);
        return of(undefined);
      })
    );
  }
}
