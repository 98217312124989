import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  SimpleChanges,
} from '@angular/core';
import { pallyVersionBackground } from 'src/app/models_new/enums/pally-background';
import { PickerType } from 'src/app/models_new/enums/picker-type';
import { polyscopeVersionBackground } from 'src/app/models_new/enums/polyscope-background';
import {
  IPickerDescription,
  pickerDescription,
} from 'src/app/models_new/types/picker-description';
import { ITableAction, ITableData } from '../table/table.component';
import { ThreeViewComponent } from '../three-view/three-view.component';
import * as PPBTimeUtil from 'src/app/utils/time-utils';
import { BehaviorSubject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { pagesPATH } from 'src/app/models_new/config/pages';

@Component({
  selector: 'app-card-picker-list',
  templateUrl: './card-picker-list.component.html',
  styleUrls: ['./card-picker-list.component.scss'],
})
export class CardPickerListComponent<ActionType>
  implements OnInit, OnDestroy, OnChanges
{
  @ViewChild(ThreeViewComponent) threeView: ThreeViewComponent;
  @ViewChild('URVersion') urVersionView: ElementRef<HTMLDivElement>;
  @ViewChild('PallyVersion') pallyVersionView: ElementRef<HTMLDivElement>;

  @Input() type: PickerType;
  @Input() listData: ITableData<ActionType>;
  @Input() data: any;
  @Input() doesAllowActions: boolean;
  @Input() listColumns: string[];
  @Input() columnColors: string[];
  @Input() listColumnUnits: string[];
  @Input() selected: boolean = false;
  @Output() actionHandler: EventEmitter<any> = new EventEmitter();

  swConfigFadeInterval: any;
  fadeDelay = 8000;
  fadeType = 0;

  pickerDescription: IPickerDescription;
  PickerType = PickerType;
  polyscopeVersionBackground = polyscopeVersionBackground;
  pallyVersionBackground = pallyVersionBackground;
  timeUtils = PPBTimeUtil;

  updateListData: BehaviorSubject<ITableData<ActionType>> = new BehaviorSubject<
    ITableData<ActionType>
  >({ data: {} });

  constructor(private router: Router, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.updateListData.next(this.listData);
    this.pickerDescription = pickerDescription(this.type);

    if (this.type === PickerType.SOFTWARE_CONFIGURATION) {
      this.startVersionFade();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'inputData': {
            this.updateListData.next(this.listData);
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (this.type === PickerType.SOFTWARE_CONFIGURATION) {
      this.endVersionFade();
    }
  }

  get title(): string {
    return this.pickerDescription.name;
  }

  favouriteAction(data: ITableData<ActionType>): ITableAction<ActionType> {
    if (!this.doesAllowActions) {
      return undefined;
    }
    return data?.actions.find((a) => a.label === 'favourite');
  }

  getCellStyling(data: ITableData<ActionType>) {
    return data?.getCellClass;
  }

  getElementErrors(data: ITableData<ActionType>) {
    return data?.error;
  }

  getElementInfo(data: ITableData<ActionType>): any {
    return data?.info;
  }

  getElementTypeForField(
    data: ITableData<ActionType>,
    fieldName: string
  ): 'default' | 'error' | 'info' {
    if (this.getElementInfo(data)?.[fieldName]) {
      return 'info';
    }

    if (this.getElementErrors(data)?.[fieldName]) {
      return 'error';
    }

    return 'default';
  }

  didClickAction(e) {
    this.actionHandler.emit(e);
  }

  startVersionFade() {
    this.endVersionFade();
    this.swConfigFadeInterval = setInterval(() => {
      if (this.fadeType === 0) {
        this.fadeType += 1;
        this.urVersionView.nativeElement.style.opacity = '0';
        this.pallyVersionView.nativeElement.style.opacity = '1';
      } else {
        this.fadeType = 0;
        this.urVersionView.nativeElement.style.opacity = '1';
        this.pallyVersionView.nativeElement.style.opacity = '0';
      }
    }, this.fadeDelay);
  }

  endVersionFade() {
    if (this.swConfigFadeInterval) clearInterval(this.swConfigFadeInterval);
  }

  onSimulationClick(id: string) {
    this.router.navigate([
      pagesPATH.SIMULATIONS,
      pagesPATH.INSTALLED_ROBOT_SIMULATION_WIZARD,
      id,
    ]);
  }

  onGenerateWaypointsClick() {
    this.router.navigate([pagesPATH.WAYPOINTS, 'new']);
  }

  onViewRobotClick(id: string) {
    this.router.navigate(['robot', id], {
      relativeTo: this.route,
    });
  }
}
