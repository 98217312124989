import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ThreePerspective } from 'src/app/models_new/enums/three-perspective';

@Injectable({
  providedIn: 'root',
})
export class ThreePerspectiveService {
  perspective$: BehaviorSubject<ThreePerspective> =
    new BehaviorSubject<ThreePerspective>(ThreePerspective.THREE);
}
