import { Component } from '@angular/core';
import { OpenSimService } from '../open-sim.service';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { IOpenSimPage } from 'src/app/models_new/config/fast-track-pages';
import { Observable, of, shareReplay, skipWhile, switchMap } from 'rxjs';
import { DialogService } from 'src/app/services/dialog.service';
import { InfoPopupComponent } from '../../dialogs/info-popup/info-popup.component';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';

@Component({
  selector: 'app-fast-track-landing-partner',
  templateUrl: './fast-track-landing-partner.component.html',
  styleUrls: ['./fast-track-landing-partner.component.scss'],
})
export class FastTrackLandingPartnerComponent {
  currentPageContent$: Observable<IOpenSimPage>;

  constructor(
    private openSimService: OpenSimService,
    private dialogService: DialogService,
    public appLayout: AppLayoutService
  ) {
    this.currentPageContent$ = this.openSimService.setStepById('landing').pipe(
      skipWhile((step) => !step),
      switchMap((step) => this.openSimService.getPageContentByStepId(step.id)),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public next(): void {
    this.openSimService.next();
  }

  /** @todo Move the content of this pop-up to Contentful. Current blocker: Content-types limit reached on free-license */
  public openInfoPopup() {
    const data = {
      id: 'liftkit-optimization',
      // dataSource: this.infoApi.getSolutionInfoCards(),
      dataSource: of([
        {
          identifier: 'liftkit-optimization',
          title: 'How It Works',
          text: `Our digital twin simulation leverages the robot's native operating system, mirroring the exact software application used for real-life palletizing tasks. This approach ensures that the digital counterpart behaves precisely like its physical version by:

          - Utilizing the Robot's Native Software: We run the same palletizing application and robot operative system within the digital twin, ensuring that every action, decision, and movement reflects the real robot's capabilities and limitations.

          - Replicating Input/Output (I/O) Signals: The simulation triggers I/O signals at the exact points they occur in physical operations, mirroring real-world interactions and responses.

          - Predicting Movements with Accuracy: By using the robot's actual software and I/O signal processing, we accurately forecast the robot's movements, speed, and power consumption. This provides a realistic preview of how the robot will perform in your specific setting, including handling speeds and energy efficiency.

          - Maintaining Certified Safety Settings: The simulation runs with the robot's certified safety system and settings. Furthermore, users have the option to adjust these settings on a case-by-case basis to optimize safety further. This customization feature requires users to log in, offering an added layer of security and personalization.`,
        },
      ]),
    };
    this.dialogService.showCustomDialog(
      InfoPopupComponent,
      DialogSize.MEDIUM,
      null,
      data,
      true
    );
  }
}
