<app-page>
  <app-header>
    <h1 app-title>Organization invites</h1>
    <app-header-actions> </app-header-actions>
  </app-header>

  <ng-container *ngIf="invites$ | async as invites">
    <!-- Loading state -->
    <app-loading *ngIf="invites.isLoading || isSignUpInvite"></app-loading>

    <!-- Empty state -->
    <app-empty
      *ngIf="invites.didSucceed && invites.value?.length < 1"
      message="Organization invitations will show up here..."
    ></app-empty>

    <ng-container *ngIf="invites.didSucceed && invites.value?.length">
      <app-card-invitation
        (didAccept)="didAccept($event)"
        *ngFor="let invite of invites.value"
        [invite]="invite"
        style="margin-bottom: 8px"
      >
      </app-card-invitation>
    </ng-container>
  </ng-container>
</app-page>
