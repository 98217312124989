import { Injectable } from '@angular/core';
import { Akt } from '../models_new/classes/akt';
import { RectangleLocation, Rectangle } from '@rocketfarm/cartesian-rectangle';
import { pinwheel } from '@rocketfarm/packing';
import { ProjectData } from '../models_new/classes/project-data';
import { Pattern } from '../components/patterns/pattern/pattern-edit/boxsorting';

export type MagicStackOptionGenerator = (
  frame: Rectangle,
  box: Rectangle,
  padding?: number
) => RectangleLocation[];
// eslint-disable-next-line
export type MagicStackConfig = {
  packers: MagicStackOptionGenerator[];
  frame: Rectangle;
  box: Rectangle;
  padding: number;
};

@Injectable({
  providedIn: 'root',
})
export class MagicStackService {
  akt: Akt = new Akt();

  getConfig(projectData: ProjectData) {
    const palletData = projectData.getPalletData();
    const boxData = projectData.getBoxData();

    const palletWidthTotal =
      palletData.dimensions.width + palletData.overhang.sides * 2;
    const palletLengthTotal =
      palletData.dimensions.length + palletData.overhang.ends * 2;
    const productWidth = boxData.dimensions.width;
    const productLength = boxData.dimensions.length;
    const boxPadding = boxData.padding;

    const frame = new Rectangle(
      palletData.dimensions.width,
      palletData.dimensions.length
    );
    const box = new Rectangle(productWidth, productLength);
    const packers = this.akt.getAkt(
      palletWidthTotal,
      palletLengthTotal,
      productWidth,
      productLength,
      boxPadding
    );

    packers.forEach((boxes) => {
      this.overhangShiftAkt(
        boxes,
        palletData.overhang.sides,
        palletData.overhang.ends
      );
    });

    this.addPinWheelPattern(
      packers,
      this.overhangShiftPinwheel(
        pinwheel(
          palletWidthTotal,
          palletLengthTotal,
          productWidth,
          productLength,
          false,
          false,
          boxPadding
        ),
        palletData.overhang.sides,
        palletData.overhang.ends
      )
    );
    this.addPinWheelPattern(
      packers,
      this.overhangShiftPinwheel(
        pinwheel(
          palletWidthTotal,
          palletLengthTotal,
          productWidth,
          productLength,
          true,
          false,
          boxPadding
        ),
        palletData.overhang.sides,
        palletData.overhang.ends
      )
    );

    return {
      packers,
      frame,
      box,
      padding: boxPadding,
    };
  }

  rotatedRectangleLocationToPattern(rrl: RectangleLocation) {
    const pattern = new Pattern(
      false,
      [],
      rrl.getWidth(),
      rrl.getLength(),
      rrl.getXMin() + rrl.getWidth() / 2,
      rrl.getYMin() + rrl.getLength() / 2,
      null,
      rrl.isRotated() ? [90, 270] : [0, 180]
    );
    pattern.direction = rrl.isRotated() ? 'sides' : 'topBottom';
    return pattern;
  }

  /** Transform list of patterns to list of PatternPages. Ie. grouping sets of patterns
   * @param patterns Patterns to be transformed
   **/
  toOptionPages(config: MagicStackConfig, patternsPerPage: number) {
    // Creating the necesarry number of PatternPages
    const pages = [
      ...Array(Math.ceil(config.packers.length / patternsPerPage)),
    ].map(() => []);
    for (let i = 0; i < config.packers.length; i++) {
      pages[Math.floor(i / patternsPerPage)].push(config.packers[i]);
    }
    return pages;
  }

  private overhangShiftAkt(
    items: RectangleLocation[],
    overhangSides: number,
    overhangEnds: number
  ) {
    // TODO: Remove any and fix the private accessing
    items.forEach((rotatedRectangleLocation: any) => {
      // We need to move origo from center to lower left corner
      rotatedRectangleLocation.location.x -= rotatedRectangleLocation.rotated
        ? rotatedRectangleLocation.rectangle.length / 2
        : rotatedRectangleLocation.rectangle.width / 2;
      rotatedRectangleLocation.location.y -= rotatedRectangleLocation.rotated
        ? rotatedRectangleLocation.rectangle.width / 2
        : rotatedRectangleLocation.rectangle.length / 2;

      // Shift patterns based on overhang
      rotatedRectangleLocation.location.x -= overhangSides;
      rotatedRectangleLocation.location.y -= overhangEnds;
    });
  }
  private overhangShiftPinwheel(
    items: RectangleLocation[],
    overhangSides: number,
    overhangEnds: number
  ) {
    // Shift pinwheel patterns based on overhang
    if (items !== undefined && items.length > 0) {
      // TODO: Remove any and fix the private accessing
      items.forEach((rotatedRectangleLocation: any) => {
        rotatedRectangleLocation.location.x -= overhangSides;
        rotatedRectangleLocation.location.y -= overhangEnds;
      });
    }
    return items;
  }

  /**
   * Inserts a pinheel pattern into the patterns array, after any other patterns with the same
   * number of boxes
   * patterns
   * pinwheelPattern
   */
  private addPinWheelPattern(
    patterns: any[],
    pinwheelPattern: RectangleLocation[]
  ) {
    for (let i = 0; i < patterns.length; i++) {
      if (patterns[i].length < pinwheelPattern.length) {
        patterns.splice(i, 0, pinwheelPattern);
        break;
      }
    }
  }
}
