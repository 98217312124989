import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ModelUploadComponent } from '../model-upload/model-upload.component';
import { NotificationService } from '../../../../services/notification.service';
import { Generator } from '../../../../models_new/classes/config-generator';
import { Entries } from '../../../../models_new/types/entries';
import { Entry } from '../../../../models_new/classes/entry';
import { FileType } from '../../../../models_new/enums/file-type';
import { ApiService } from '../../../../services/api/api.service';
import { catchError, filter, map, switchMap } from 'rxjs/operators';
import { IComponentUploadResult } from 'src/app/models_new/types/component-upload-result';
import { transformValueNames } from '../../../../models_new/enums/transform-vector-type';
import { HardwareApiService } from '../../../../services/api/hardware-api.service';
import { StateService } from '../../../../auth/state.service';
import { forkJoin, from, Observable, of, Subject, take } from 'rxjs';
import { pagesPATH } from '../../../../models_new/config/pages';
import { Router } from '@angular/router';
import { MatDialogRef } from '@angular/material/dialog';
import { IHwSwType } from '../../../../models_new/types/robot-config/hw-sw-type';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent implements OnInit, OnDestroy {
  @Input() modelUpload: ModelUploadComponent;
  @ViewChild('descriptionLabel') descriptionLabel: ElementRef;
  @ViewChild('stroke') stroke: ElementRef;

  JSZip = require('jszip');

  transformValueNames: Array<Array<string>>;
  entries: Entries;
  showSpinner: boolean;
  isPublic: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private notification: NotificationService,
    public apiService: ApiService,
    public hardwareService: HardwareApiService,
    public stateService: StateService,
    private router: Router,
    public dialogRef: MatDialogRef<SummaryComponent>
  ) {
    this.transformValueNames = transformValueNames;
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit(): void {
    this.entries = [];
    /* eslint-disable */
    for (const j in this.transform()) {
      if (this.transform().hasOwnProperty(j)) {
        const e: Entry[][] = [];
        for (const i in this.transform()[j].vectors) {
          if (this.transform()[j].vectors.hasOwnProperty(i)) {
            e.push(this.transform()[j].vectors[i].vector.entries());
          }
        }
        this.entries.push(e);
      }
    }
    /* eslint-enable */
  }
  inputChange(
    e,
    vectorPosition: number,
    entryPosition: number,
    transformPosition
  ) {
    const value: string = e.target.value;
    if (this.validate(value)) {
      this.modelUpload.generatorParams.transforms[transformPosition].vectors[
        vectorPosition
      ].vector.set(entryPosition, Number(value));
    } else {
      e.target.value = 0;
    }
  }

  validate(str: string) {
    return !isNaN(Number(str));
  }

  transform() {
    return this.modelUpload.generatorParams.transforms;
  }

  getComponentName(): string {
    return this.modelUpload.generatorParams.name.split(' ').join('_');
  }

  getComponentTypeId(): string {
    return this.modelUpload.generatorParams.type.id;
  }

  generateConfigFiles(): Observable<Blob[]> {
    return this.stateService.getCustomerOrSalesOrganizationPreserveState().pipe(
      take(1),
      switchMap((organization) => {
        const visualPath =
          'package:/' +
          this.getPathForGH('meshes/visual', 'dae', organization.name);
        const collisionPath =
          'package:/' +
          this.getPathForGH('meshes/collision', 'stl', organization.name);

        const yamlBlob: Blob = this.getFile(
          FileType.YAML,
          visualPath,
          collisionPath
        );
        const xmlBlob: Blob = this.getFile(
          FileType.URDF,
          visualPath,
          collisionPath
        );
        return of([yamlBlob, xmlBlob]);
      })
    );
  }

  async uploadToGitHub() {
    this.showSpinner = true;

    await this.modelUpload.exportScene();

    this.generateConfigFiles()
      .pipe(
        switchMap(([yamlBlob, xmlBlob]) => {
          if (this.modelUpload.generatorParams.type != null) {
            this.notification.showMessage('Uploading to GitHub…');

            return this.uploadFilesToPallyDescriptions(yamlBlob, xmlBlob);
          } else {
            return of(undefined);
          }
        }),
        catchError((error) => {
          console.error(error);
          return of(undefined);
        }),
        switchMap((result: IComponentUploadResult) => {
          this.showSpinner = false;
          if (!result) {
            this.notification.showError(
              'An error occured while uploading files to GitHub!'
            );
            return of(false);
          }
          if (result.success) {
            this.notification.showSuccess(
              'Files successfully generated and uploaded to GitHub!'
            );
            return of(true);
          } else {
            this.notification.showError('Error: ' + result.reason);
            return of(false);
          }
        }),
        take(1),
        filter(Boolean),
        switchMap((_) => {
          return this.insertHWRow();
        })
      )
      .subscribe((hw: IHwSwType) => {
        this.router.navigate(['inventory/' + pagesPATH.HARDWARE_LIBRARY]);
        if (this.dialogRef) {
          this.dialogRef.close(hw);
        }
      });
  }

  insertHWRow(): Observable<IHwSwType> {
    const type = this.modelUpload.generatorParams.type.id;
    const name = this.modelUpload.generatorParams.name;

    return this.stateService.getCustomerOrSalesOrganizationPreserveState().pipe(
      take(1),
      switchMap((organization) => {
        let metadata = {
          organization_id: organization.id,
          public: this.isPublic,
        };
        if (this.stroke && this.stroke.nativeElement.value !== '') {
          metadata['stroke'] = this.stroke.nativeElement.value;
        }
        return this.hardwareService.insertHardWareType(
          name.replace(' ', '_').toUpperCase(),
          name,
          type,
          organization.id,
          metadata,
          this.isPublic
        );
      }),
      switchMap((hardware) => {
        return this.hardwareService
          .insertGlobalHardwareTypeToOrganization(
            hardware.id,
            hardware.organization_id
          )
          .pipe(map((_) => hardware));
      })
    );
  }
  uploadFilesToPallyDescriptions(
    yamlBlob,
    xmlBlob
  ): Observable<IComponentUploadResult> {
    const visual$ = from(this.blobToBase64(this.modelUpload.export.collada));
    const collision$ = from(this.btob(this.modelUpload.export.stl));
    const yaml$ = from(this.blobToBase64(yamlBlob));
    const urdf$ = from(this.blobToBase64(xmlBlob));

    return forkJoin([
      visual$,
      collision$,
      yaml$,
      urdf$,
      this.stateService
        .getCustomerOrSalesOrganizationPreserveState()
        .pipe(take(1)),
    ]).pipe(
      switchMap(([visual, collision, yaml, urdf, org]) => {
        return this.apiService.sendComponentToGitHub(
          org.id,
          visual,
          collision,
          yaml,
          urdf,
          this.getComponentName(),
          this.getComponentTypeId()
        );
      })
    );
  }

  async download() {
    this.showSpinner = true;
    await this.modelUpload.exportScene();
    this.generateConfigFiles().subscribe({
      next: (next) => {
        const model_name: string =
          this.modelUpload.generatorParams.file.name.split('.')[0];

        const zip = new this.JSZip();
        const folder = zip.folder('configs');
        folder.file(model_name + '.yml', next[0]);
        folder.file(model_name + '.urdf', next[1]);
        folder.file(model_name + '.stl', this.modelUpload.export.stl);
        folder.file(model_name + '.dae', this.modelUpload.export.collada);

        zip
          .generateAsync({ type: 'blob' })
          .then((blob) => {
            const blobURL = window.URL.createObjectURL(blob);
            const blobLink = document.createElement('a');

            blobLink.href = blobURL;
            blobLink.download = 'configs.zip';
            document.body.appendChild(blobLink);
            blobLink.click();
            document.body.removeChild(blobLink);
            this.showSpinner = false;
          })
          .catch(() => {
            this.showSpinner = false;
          });
      },
      error: (error) => {
        console.error(error);
        this.showSpinner = false;
      },
    });
  }

  getFile(type: FileType, visualPath: string, collisionPath: string) {
    return new Blob(
      [
        new Generator(
          this.modelUpload.generatorParams,
          visualPath,
          collisionPath
        ).generate(type),
      ],
      { type: 'text/plain' }
    );
  }

  getPathForGH(
    fileLocation: string,
    fileType: string,
    organizationName: string,
    shouldPrependDirectory: boolean = true
  ) {
    const org: string = organizationName;
    const type: string = this.getComponentTypeId();
    const projectName: string = this.getComponentName()
      .toLowerCase()
      .replaceAll(' ', '_');
    const cleanID = projectName.replaceAll(org + '_', '');

    return `${
      shouldPrependDirectory ? 'pally_descriptions/' : ''
    }${type}/${org}/${cleanID}/${fileLocation}/${projectName}.${fileType}`;
  }

  /**
   * Blob to binary (Converts blob to string)
   * @param blob
   * @returns string
   */
  btob(blob: Blob): Promise<string> {
    return new Promise((resolve, _reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function () {
        if (typeof reader.result === 'string') {
          resolve(reader.result.split(',')[1]);
        }
      };
    });
  }

  updateIsPublic() {
    this.isPublic = !this.isPublic;
  }

  async blobToBase64(blob: Blob): Promise<string> {
    const text = await blob.text();
    return btoa(text);
  }
}
