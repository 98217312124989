<app-new-three-view
  #view
  style="height: 100%"
  [interactive]="interactive"
  [camera]="camera.camera"
  class="pattern-view-styling"
>
  <app-orbit-controls
    #controls
    [canvas]="view.canvas"
    [camera]="camera.camera"
    (change$)="view.render()"
    [maxDistance]="10"
  ></app-orbit-controls>
  <app-perspective-camera
    #camera
    [controls]="view.controls"
    [position]="cameraPos"
  ></app-perspective-camera>

  <app-directional-light
    [position]="lightPos1"
    [intensity]="0.4"
  ></app-directional-light>
  <app-directional-light
    [position]="lightPos2"
    [intensity]="0.4"
  ></app-directional-light>
  <app-directional-light
    [position]="lightPos3"
    [intensity]="0.4"
  ></app-directional-light>
  <app-directional-light
    [position]="lightPos4"
    [intensity]="0.4"
  ></app-directional-light>
  <app-ambient-light [intensity]="0.3"></app-ambient-light>

  <app-three-pallet
    *ngIf="_pallet"
    [pallet]="_pallet"
    [sticker]="sticker"
    [labelOrientations]="_labelOrientations"
    [productTypeId]="productTypeId"
    [position]="_palletPosition"
    class="pattern-view-styling"
  ></app-three-pallet>
</app-new-three-view>
