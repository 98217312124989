import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { SubnavViewService } from 'src/app/services/subnav-view.service';
import {
  combineLatestWith,
  filter,
  map,
  shareReplay,
  skipWhile,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  merge,
  of,
} from 'rxjs';
import { ExportImportService } from 'src/app/services/export-import.service';
import { Project } from 'src/app/models_new/classes/project';
import { PalletEditorService } from 'src/app/services/pallet-editor.service';
import { ThreeViewComponent } from '../../../gui/three-view/three-view.component';
import { PalletPosition } from 'src/app/models_new/enums/pallet-position';
import { AltLayout } from 'src/app/models_new/enums/alt-layout';
import { LayerType } from 'src/app/models_new/enums/layer-type';
import { ThreeHandlerMode } from 'src/app/models_new/classes/3dview/three-handler-mode';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { PatternApiService } from '../../../../services/api/pattern-api.service';
import { ApiPattern } from '../../../../models_new/classes/api-models/ApiPattern';
import { toRequestState } from 'src/app/data-request/operators';
import { DataRequestState } from 'src/app/data-request/model';
import { OrganizationLogoService } from '../../../../services/organization-logo.service';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { EagerStateMatcher } from 'src/app/components/gui/field/field.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { nameRegexp } from 'src/app/models_new/config/validation/pally-file-values';
import { settings } from 'src/app/models_new/config/application-settings';
import { AlternativeLayoutService } from 'src/app/services/alternative-layout.service';
import { ProductApiService } from 'src/app/services/api/product-api.service';
import { FileUtils } from 'src/app/utils/file-utils';
import { PatternGenerator } from 'src/app/models_new/classes/pattern-generator';
import { MagicStackService } from 'src/app/services/magic-stack.service';
import { ApiProduct } from 'src/app/models_new/classes/api-models/ApiProduct';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-pallet',
  templateUrl: './pallet.component.html',
  styleUrls: ['./pallet.component.scss'],
})
export class PalletComponent implements OnInit, OnDestroy {
  @Input() inputProject?: Project;
  @ViewChild(ThreeViewComponent, { static: true })
  asyncProject$: Observable<DataRequestState<Project>>;
  fetchProject$: Observable<Project>;
  projectUpdate$ = new BehaviorSubject<Project>(null);
  initialPattern: ApiPattern;
  revisions: Project[] = [];
  activeRevision: number = 0;
  deadLock: boolean = false;
  product: ApiProduct;

  ThreeHandlerMode = ThreeHandlerMode;
  threeView: ThreeViewComponent;
  sticker$: Observable<THREE.Texture>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  organizationId$: Observable<string>;
  eagerMatcher = new EagerStateMatcher();
  formGroup: FormGroup;

  MultiGrips = [1, 2, 3, 4, 5, 6, 7, 8];
  ZeroToHoundred = new Array(100).fill(null).map((_, index) => index + 1);
  ZeroToThirty = new Array(30).fill(null).map((_, index) => index + 1);

  PalletPosition = PalletPosition;

  constructor(
    public subNavView: SubnavViewService,
    public exportImportService: ExportImportService,
    private palletEditor: PalletEditorService,
    private route: ActivatedRoute,
    private router: Router,
    private patternApi: PatternApiService,
    private productApi: ProductApiService,
    public orgLogo: OrganizationLogoService,
    private errorHandler: ErrorHandlerService,
    private fb: FormBuilder,
    private altLayoutService: AlternativeLayoutService,
    private magicStackService: MagicStackService,
    private notify: NotificationService
  ) {}

  ngOnInit() {
    this.formGroup = this.fb.group({
      selected_pallet: [1],
      name: ['', [Validators.pattern(nameRegexp), Validators.required]],
      description: ['', [Validators.maxLength(200)]],
      multi_grip: [8],
      cpm: [8, [Validators.required]],
      shim_paper_height: [3],
      left_pallet_layout: [settings.defaultAltLayout],
    });

    this.sticker$ = this.orgLogo.eitherLabelOrLogo$.pipe(map((v) => v.texture));

    this.organizationId$ = this.route.data.pipe(
      take(1),
      map(
        (data: Data) =>
          (data as IOrganizationContextResolverData).organization_id
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.fetchProject$ = this.organizationId$.pipe(
      take(1),
      switchMap((_organizationId: string) =>
        this.patternApi.fetchPatternById(
          this.route.snapshot.queryParams.patternId ||
            this.route.snapshot.params?.id
        )
      ),
      switchMap((data: ApiPattern) =>
        combineLatest([
          of(data),
          this.productApi.fetchProductById(data.product_id),
        ])
      ),
      map(([pattern, product]) => {
        this.product = product;

        // TODO: Should not deadlock if only weight is changed.
        if (
          new Date(product.updated_at).getTime() >
          new Date(pattern.updated_at).getTime()
        ) {
          this.deadLock = true;
        }
        this.initialPattern = pattern;

        return this.exportImportService.mapToProjectFormat(pattern.data);
      })
    );

    this.asyncProject$ = (
      this.inputProject ? of(this.inputProject) : this.fetchProject$
    ).pipe(
      tap((p) => {
        // Show left pallet if altLayout is custom.
        if (p.palletAdvancedSettings.altLayout === AltLayout.CUSTOM) {
          p.palletView.palletViewSettings.showLeftPallet = true;
          p.palletView.saveViewSettings();
          p.activePallet = PalletPosition.RIGHT;
        }

        // Show left pallet if only left pallet layers.
        if (p.pallets[0].layers.length && !p.pallets[1].layers.length) {
          p.palletView.palletViewSettings.showLeftPallet = true;
          p.palletView.saveViewSettings();
          p.activePallet = PalletPosition.LEFT;
        }
        this.patchForm(p);
      }),
      switchMap((project: Project) =>
        merge(this.projectUpdate$.pipe(skipWhile((v) => !v)), of(project))
      ),
      shareReplay({ bufferSize: 1, refCount: true }),
      toRequestState()
    );

    this.listenToNavbarActions();
  }

  patchForm(project: Project) {
    this.formGroup.patchValue(
      {
        selected_pallet: project.activePallet,
        name: project.data.name,
        description: project.data.description,
        multi_grip: project.data.box.maxGrip,
        cpm: project.data.cpm,
        shim_paper_height: project.data.shimPaper.height,
        left_pallet_layout: project.palletAdvancedSettings.altLayout,
      },
      { emitEvent: false }
    );
  }

  ngOnDestroy() {
    this.subNavView.resetAllLayersButtons();
    this.subNavView.resetAllPatternButtons();
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  addRevision(project: Project) {
    this.revisions.push(project);
    this.activeRevision = this.revisions.length - 1;
    this.selectRevision(project);
  }

  selectRevision(project: Project) {
    if (!this.revisions[this.activeRevision]) {
      console.error('Revision not found', this.activeRevision, this.revisions);
      return;
    }

    this.altLayoutService.selectedAltLayout$.next({
      selected: this.formGroup.get('left_pallet_layout').value,
      origin: 'pallet.component.ts',
      project: project,
    });

    this.projectUpdate$.next(this.revisions[this.activeRevision]);
  }

  undo(project: Project) {
    this.activeRevision = this.activeRevision - 1;
    this.selectRevision(project);
  }

  redo(project: Project) {
    this.activeRevision++;
    this.selectRevision(project);
  }

  selectPallet(position: PalletPosition) {
    this.formGroup.get('selected_pallet').setValue(position);
  }

  listenToNavbarActions() {
    this.formGroup
      .get('left_pallet_layout')
      .valueChanges.pipe(
        takeUntil(this.destroy$),
        combineLatestWith(
          this.asyncProject$.pipe(
            skipWhile((v) => v.isLoading),
            take(1)
          )
        )
      )
      .subscribe(([_altLayout, project]) => {
        if (_altLayout !== AltLayout.CUSTOM) {
          this.selectPallet(PalletPosition.RIGHT);
        } else {
          // Check if there are duplicated layerTypeId´s
          const duplicatedLayers = project.value
            .getPalletByPosition(settings.primaryPallet)
            .getDuplicateLayersByTypeId();

          if (duplicatedLayers.length) {
            this.notify.showMessage(
              'Cannot have duplicate layer types when custom left pallet. Don´t worry, boxes are duplicated.',
              5000
            );

            const allLayers = [
              ...project.value.getPalletByPosition(settings.primaryPallet)
                .layers,
            ].reverse();
            allLayers.forEach((layer) => {
              if (duplicatedLayers.map((m) => m.id).includes(layer.id)) {
                if (layer.layerPosition === layer.getTypeId('layer-no')) {
                  return;
                }
                layer.setTypeId(
                  project.value
                    .getPalletByPosition(settings.primaryPallet)
                    .getLowestUnusedTypeId(layer.type)
                    .toString()
                );
                layer.setName();

                const secondaryLayer = project.value
                  .getPalletByPosition(settings.secondaryPallet)
                  .getLayerByPosition(layer.layerPosition);
                const secondaryNewTypeId = project.value
                  .getPalletByPosition(settings.secondaryPallet)
                  .getLowestUnusedTypeId(secondaryLayer.type);
                secondaryLayer.setTypeId(secondaryNewTypeId.toString());
                secondaryLayer.setName();
              }
            });
          }
        }

        // Cool side effect
        if (!project.value.palletView.palletViewSettings.showLeftPallet) {
          project.value.palletView.palletViewSettings.showLeftPallet = true;
          project.value.palletView.saveViewSettings();
        }
      });

    this.formGroup.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(
          this.asyncProject$.pipe(
            skipWhile((v) => v.isLoading),
            take(1)
          )
        )
      )
      .subscribe(([formValues, project]) => {
        // Direct
        project.value.data.name = formValues.name;
        project.value.data.description = formValues.description;

        if (formValues.multi_grip === 'auto') {
          project.value.data.box.maxGrip = 8;
        } else {
          project.value.data.box.maxGrip = formValues.multi_grip;
        }

        project.value.data.cpm = formValues.cpm;

        project.value.palletAdvancedSettings.altLayout =
          formValues.left_pallet_layout;

        // Set functions
        project.value.setShimpaperHeight(formValues.shim_paper_height);
        project.value.setActivePallet(formValues.selected_pallet);

        // Cool side effects
        if (
          formValues.selected_pallet === PalletPosition.LEFT &&
          !project.value.palletView.palletViewSettings.showLeftPallet
        ) {
          project.value.palletView.palletViewSettings.showLeftPallet = true;
          project.value.palletView.saveViewSettings();
        }

        // Left pallet layout is set in revisions.
        this.addRevision(project.value);
      });

    this.subNavView.palletTools$
      .pipe(
        withLatestFrom(
          this.asyncProject$.pipe(
            skipWhile((v) => v.isLoading),
            take(1)
          )
        ),
        takeUntil(this.destroy$),
        // If v === 'add' we need to wait for the new layer to be added.
        // This is done in the palletEditor.addNewLayer() method.
        // We could also do this by listening to the projectUpdate$.
        // But this is more clear.
        filter(([v, _project]) => v !== 'add')
      )
      .subscribe(([v, project]) => {
        if (v) {
          this.palletEditor._makePalletDirty(true);
        }

        v === 'shim'
          ? this.palletEditor.addShimPaper(project.value)
          : v === 'dup'
          ? this.duplicateLayer(
              project.value.getActivePallet().getTopLayer().id,
              project.value
            )
          : v === 'hor'
          ? this.palletEditor.mirrorPrevHoriz(project.value)
          : v === 'ver'
          ? this.palletEditor.mirrorPrevVert(project.value)
          : v === 'rot'
          ? this.palletEditor.rotatePrev(project.value)
          : null;

        this.addRevision(project.value);
      });

    this.subNavView.palletTools$
      .pipe(
        withLatestFrom(
          this.asyncProject$.pipe(
            skipWhile((v) => v.isLoading),
            take(1)
          )
        ),
        takeUntil(this.destroy$),
        filter(([v, _project]) => v === 'add'), // If v === 'add' we need to wait for the new layer to be added.
        switchMap(([_, project]) => {
          return this.palletEditor.addNewLayer(project.value);
        })
      )
      .subscribe((project) => {
        this.addRevision(project);
      });
  }

  onResized() {
    this.threeView.onResize();
  }

  viewOptionClick(
    viewOption: 'showLeftPallet' | 'showPalletFront' | 'showFront',
    project: Project
  ) {
    // Set view option
    project.palletView.palletViewSettings[viewOption] =
      !project.palletView.palletViewSettings[viewOption];

    project.palletView.saveViewSettings();
  }

  /**
   * @param event: CdkDragDrop<string[]>
   */
  drop(event: CdkDragDrop<string[]>, project: Project) {
    if (
      project.getActivePallet().layers[event.previousIndex].type ===
        LayerType.SHIMPAPER &&
      project.getPalletAdvancedSettings().getAltLayout() === AltLayout.CUSTOM
    ) {
      const activePallet = project.activePallet === PalletPosition.LEFT ? 1 : 0;
      const activePalletLayer = project
        .getPalletByPosition(activePallet)
        .getLayerByIndex(event.previousIndex);
      if (activePalletLayer && activePalletLayer.type === LayerType.SHIMPAPER) {
        /**
         * @todo Refactor and fix the duplicateLayer method to ensure proper synchronization between pallets.
         * Consider implementing it as an observable for improved functionality and error handling.
         */
        project
          .getPalletByPosition(activePallet)
          .moveLayerToIndex(
            event.currentIndex,
            event.previousIndex,
            activePalletLayer
          );
      }
    }

    moveItemInArray(
      project.getActivePallet().layers,
      event.previousIndex,
      event.currentIndex
    );

    this.palletEditor._makePalletDirty(true);
    project.getActivePallet().setLayers(project.getActivePallet().layers);

    this.addRevision(project);
  }

  editShimpaperHeight(
    event: { layerId: string; height: number },
    project: Project
  ) {
    this.palletEditor.editShimpaperHeightById(event, project);
    this.addRevision(project);
  }

  removeLayer(layerId: string, project: Project) {
    this.palletEditor.removeLayerById(layerId, project);
    this.addRevision(project);
  }

  /**
   *
   * @param layerId
   * @param project
   *
   * When duplicating, we need to check if the alt layout is custom.
   * If it is, we need to make a new layer to preserve the left pallet layer.
   * Since we only keep one of each layerType in the json output. It cannot be different altPattern.
   */
  duplicateLayer(layerId: string, project: Project) {
    if (
      project.getPalletAdvancedSettings().getAltLayout() === AltLayout.CUSTOM
    ) {
      this.notify.showMessage(
        `Cannot duplicate layer type when custom left pallet. Don't worry, boxes are duplicated.`,
        5000
      );
    }

    this.palletEditor.duplicateOboveLayerById(
      layerId,
      project.getActivePallet(),
      project.getPalletAdvancedSettings().getAltLayout() === AltLayout.CUSTOM
    );
    this.addRevision(project);
  }

  changeApproach(
    event: { layerId: string; inverted: boolean },
    project: Project
  ) {
    this.palletEditor.ivertLayerApproachById(event, project);
    this.addRevision(project);
  }

  editLayer(layerId: string, allOfType: boolean, project: Project): void {
    let source$: Observable<Project | Error>;

    if (allOfType) {
      source$ = this.palletEditor.editAll(layerId, project);
    } else {
      source$ = this.palletEditor.editLayer(layerId, project);
    }
    this.subNavView.currentLayer$.next(
      project.getLayerById(project.getActivePallet()?.position, layerId)
    );

    source$.pipe(take(1)).subscribe({
      next: (p: Project | Error) => {
        if (p instanceof Error) {
          this.errorHandler.handleError(p);
          return;
        }

        if (p) {
          this.addRevision(p);
        }
      },
      error: (err) => {
        this.errorHandler.handleError(err);
      },
    });
  }

  downloadPatternJson(project: Project) {
    const pattern = new ApiPattern(this.initialPattern);
    pattern.data = this.exportImportService.mapToExportFormat(project);
    /**
     * @desc When exporting JSON from MRC, it is advised to remove or replace whitespace in the file name to
     * ensure compatibility with older versions of Pally URCap that cannot handle patterns with whitespace in the file name.
     * {@link https://rocketfarm.atlassian.net/browse/PALLY-3921?focusedCommentId=40602 Learn more} about this issue.
     */
    pattern.data.name = pattern.name = project.data.name.replace(/ /g, '_');
    pattern.description = project.data.description;

    FileUtils.downloadJson(pattern.data, pattern.name);
  }

  resetPattern(project: Project) {
    const patternGenerator = new PatternGenerator(
      this.palletEditor,
      this.exportImportService,
      this.magicStackService,
      this.altLayoutService
    );
    patternGenerator
      .setProduct(this.product)
      .setStackingMethod(settings.defaultStackingMethod)
      .setProjectData(project.data)
      .generateNewPattern();

    this.notify
      .deletePrompt('Reset', 'Pattern', [project.data.name])
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => patternGenerator.project$),
        take(1)
      )
      .subscribe((p: Project) => {
        this.projectUpdate$.next(p);
        this.saveEdit(p, true);
      });
  }

  cancelEdit() {
    this.router.navigate([pagesPATH.PATTERNS]);
  }

  saveEdit(project: Project, refresh: boolean = false) {
    const pattern = new ApiPattern(this.initialPattern);
    pattern.data = this.exportImportService.mapToExportFormat(project);
    pattern.name = project.data.name;
    pattern.description = project.data.description;

    this.patternApi
      .updatePattern(pattern.id, pattern, pattern.product_id)
      .pipe(
        take(1),
        tap((_) => {
          this.notify.showSuccess('Pattern saved');
        })
      )
      .subscribe({
        next: () => {
          if (refresh) {
            window.location.reload();
          } else {
            this.router.navigate([pagesPATH.PATTERNS]);
          }
        },
        error: (err) => {
          this.errorHandler.handleError(err);
        },
      });
  }
}
