import { Injectable } from '@angular/core';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import {
  BehaviorSubject,
  map,
  Observable,
  shareReplay,
  skipWhile,
  Subject,
  tap,
} from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import {
  IPromptDeletionData,
  PromptDeletionComponent,
} from '../components/prompt-deletion/prompt-deletion.component';
import { gql } from 'apollo-angular';
import { ClientApiService } from './api/client-api.service';
import { PromptUploadComponent } from '../components/prompt-upload/prompt-upload.component';

const colors = {
  error: ['#fdedef', '#ef4d62'],
  success: ['#ebfbf5', '#35d39d'],
  info: ['#e5fafd', '#03d0ea'],
  warning: ['#fff9e5', '#f5a623'],
};

type messageType = keyof typeof colors;

interface IMessageLink {
  text: string;
  url: string;
}

interface IMessage {
  type: messageType;
  bgColor: string;
  iconColor: string;
  icon: string;
  message: string;
  class: string;
  ttl: 'infinity' | number; // Error statuses have infinity as default.
  id: string;
  link?: IMessageLink;
}

export interface IGlobalNotification {
  id: string;
  type: messageType;
  message: string;
  expires_at?: string;
  created_at?: string;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  messages: IMessage[] = [];
  messages$: Observable<IMessage[]>;
  messSub$: Subject<IMessage[]> = new Subject();
  newMessage$: BehaviorSubject<IMessage> = new BehaviorSubject<IMessage>(null);
  removeMessage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private _bottomSheet: MatBottomSheet,
    private clientApi: ClientApiService
  ) {
    this.newMessage$
      .pipe(
        skipWhile((m) => !m),
        tap((m: IMessage) => {
          if (this.messages.length < 4) {
            this.messages.unshift(m);
          } else {
            this.messages.pop();
            this.messages.unshift(m);
          }

          this.messSub$.next(this.messages);

          if (m.ttl !== 'infinity') {
            setTimeout(() => {
              this.removeMessage$.next(m.id);
            }, m.ttl);
          }
        })
      )
      .subscribe();

    this.removeMessage$
      .pipe(
        skipWhile((m) => !m),
        tap((mId: string) => {
          this.messages = this.messages.filter((f) => f.id !== mId);
          this.messSub$.next(this.messages);
        })
      )
      .subscribe();

    this.messages$ = this.messSub$.pipe(
      map((_) => {
        return this.messages;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private addMessage(
    text: string,
    type: messageType,
    icon: string = 'info',
    ttl: IMessage['ttl'],
    skipDuplicationCheck?: boolean,
    link?: IMessageLink
  ): string | void {
    // Prevent bloating: Check if message already exists.
    if (
      !skipDuplicationCheck &&
      this.messages.find((f) => f.message === text)
    ) {
      return;
    }

    const message: IMessage = {
      type: type,
      bgColor: colors[type][0],
      iconColor: colors[type][1],
      icon: icon,
      message: text,
      class: 'new-message-card',
      ttl: ttl,
      id: uuidv4(),
      link: link,
    };

    this.newMessage$.next(message);

    return message.id;
  }

  showSuccess(
    message: string,
    ttl: IMessage['ttl'] = 5000,
    skipDuplicationCheck = false,
    link?: IMessageLink
  ): void {
    this.addMessage(
      message,
      'success',
      'check_circle',
      ttl,
      skipDuplicationCheck,
      link
    );
  }

  showMessage(
    message: string,
    ttl: IMessage['ttl'] = 5000,
    skipDuplicationCheck = false,
    link?: {
      text: string;
      url: string;
    }
  ): string | void {
    return this.addMessage(
      message,
      'info',
      'info',
      ttl,
      skipDuplicationCheck,
      link
    );
  }

  showError(
    message: string,
    ttl: IMessage['ttl'] = 'infinity',
    skipDuplicationCheck = false,
    link?: IMessageLink
  ): string | void {
    return this.addMessage(
      message,
      'error',
      'error',
      ttl,
      skipDuplicationCheck,
      link
    );
  }

  showWarning(
    message: string,
    ttl: IMessage['ttl'] = 5000,
    skipDuplicationCheck = false,
    link?: IMessageLink
  ): string | void {
    return this.addMessage(
      message,
      'warning',
      'warning',
      ttl,
      skipDuplicationCheck,
      link
    );
  }

  deletePrompt(
    title: IPromptDeletionData['title'] = 'Delete',
    type: IPromptDeletionData['type'],
    items: IPromptDeletionData['items'],
    extraWarning?: IPromptDeletionData['extraWarning']
  ): MatBottomSheetRef {
    const data: IPromptDeletionData = {
      title: title,
      items: items,
      type: type,
      extraWarning: extraWarning,
    };

    return this._bottomSheet.open(PromptDeletionComponent, {
      data: data,
    });
  }

  uploadPrompt(): MatBottomSheetRef {
    return this._bottomSheet.open(PromptUploadComponent);
  }

  deleteMessage(id: string): void {
    this.removeMessage$.next(id);
  }

  getLatestGlobalNotifications(): Observable<IGlobalNotification[]> {
    const q = gql`
      subscription getGlobalNotifications {
        global_notification(
          order_by: { expires_at: asc }
          where: { expires_at: { _gte: "now()" } }
        ) {
          id
          type
          message
        }
      }
    `;

    return this.clientApi
      .useClient('public', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(map((res) => res.data.global_notification));
  }
}
