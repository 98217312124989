import { Component, HostBinding, Input } from '@angular/core';

/**
 * A wrapper component for pages.
 */
@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss'],
})
export class PageComponent {
  @Input() dynamicHeight: boolean = false;

  @HostBinding('class.dynamic-height') get dynamicHeightClass() {
    return this.dynamicHeight;
  }
}
