import { IParentPartType } from '../classes/simconf/parent-part-type';
import { PartType } from '../enums/sim-config-part-type';

/**
 * Config for assigning possible parent part types
 * The order of possible parents dictate the search order, 0 -> n
 */
export const parentPartTypeConfig: IParentPartType[] = [
  {
    contextType: PartType.PALLET,
    possibleParentTypes: [],
  },
  {
    contextType: PartType.PALLET_LIP,
    possibleParentTypes: [PartType.PALLET],
  },
  {
    contextType: PartType.CONVEYOR,
    possibleParentTypes: [],
  },
  {
    contextType: PartType.SECONDARY_CONVEYOR,
    possibleParentTypes: [],
  },
  {
    contextType: PartType.FRAME,
    possibleParentTypes: [],
  },
  {
    contextType: PartType.LIFTKIT,
    possibleParentTypes: [PartType.FRAME],
  },
  {
    contextType: PartType.BASE_BRACKET,
    possibleParentTypes: [PartType.LIFTKIT, PartType.FRAME],
  },
  {
    contextType: PartType.ROBOT,
    possibleParentTypes: [
      PartType.BASE_BRACKET,
      PartType.LIFTKIT,
      PartType.FRAME,
    ],
  },
  {
    contextType: PartType.OFFSET_BRACKET,
    possibleParentTypes: [PartType.ROBOT],
  },
  {
    contextType: PartType.GRIPPER,
    possibleParentTypes: [PartType.GRIPPER, PartType.ROBOT],
  },
];
