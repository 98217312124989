import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LandingRoutingModule } from './fast-track-routing.module';
import { FastTrackPatternComponent } from 'src/app/components/fast-track/fast-track-pattern/fast-track-pattern.component';
import { FastTrackProductComponent } from 'src/app/components/fast-track/fast-track-product/fast-track-product.component';
import { FastTrackRegionComponent } from 'src/app/components/fast-track/fast-track-region/fast-track-region.component';
import { FastTrackUserInfoComponent } from 'src/app/components/fast-track/fast-track-user-info/fast-track-user-info.component';
import { FastTrackLandingComponent } from 'src/app/components/fast-track/fast-track-landing-page/fast-track-landing.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared.module';
import { ThreeModule } from '../three.module';
import { FastTrackOutletComponent } from 'src/app/components/fast-track/fast-track-outlet/fast-track-outlet.component';
import { FastTrackFooterComponent } from 'src/app/components/fast-track/fast-track-footer/fast-track-footer.component';
import { FastTrackNavbarComponent } from 'src/app/components/fast-track/fast-track-navbar/fast-track-navbar.component';
import { FastTrackLandingPartnerComponent } from 'src/app/components/fast-track/fast-track-landing-partner/fast-track-landing-partner.component';
import { FastTracSimulationsComponent } from 'src/app/components/fast-track/fast-track-simulations/fast-track-simulations.component';

@NgModule({
  declarations: [
    FastTrackOutletComponent,
    FastTrackLandingComponent,
    FastTrackLandingPartnerComponent,
    FastTrackProductComponent,
    FastTrackPatternComponent,
    FastTrackFooterComponent,
    FastTrackRegionComponent,
    FastTrackUserInfoComponent,
    FastTracSimulationsComponent,
    FastTrackNavbarComponent,
  ],
  imports: [
    CommonModule,
    LandingRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ThreeModule,
    SharedModule,
    CommonModule,
    RouterModule,
  ],
  exports: [FastTrackOutletComponent, FastTrackFooterComponent],
})
export class FastTrackModule {}
