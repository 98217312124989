import { RectangleLocation } from '@rocketfarm/cartesian-rectangle';

class Rectangle {
  constructor(public width: number, public length: number) {}

  shortestSide(): number {
    return this.width <= this.length ? this.width : this.length;
  }

  rotated90(): Rectangle {
    return new Rectangle(this.length, this.width);
  }

  area(): number {
    return this.width * this.length;
  }

  equals(comparer: Rectangle): boolean {
    return this.width === comparer.width && this.length === comparer.length;
  }
}

enum ApktAreaId {
  Area1,
  Area2,
  Area3,
  Area4,
}

class ApktArea {
  constructor(public id: ApktAreaId, public area: Rectangle) {}

  public getActualWidth(boxSize: Rectangle) {
    if (this.id === ApktAreaId.Area1 || this.id === ApktAreaId.Area3) {
      return this.area.width * boxSize.width;
    } else {
      return this.area.width * boxSize.length;
    }
  }

  public getActualLength(boxSize: Rectangle) {
    if (this.id === ApktAreaId.Area1 || this.id === ApktAreaId.Area3) {
      return this.area.length * boxSize.length;
    } else {
      return this.area.length * boxSize.width;
    }
  }
}

class Solution {
  /**
   *
   */
  constructor(private boxSize: Rectangle, private palletSize: Rectangle) {}

  /**
   *  Contains standing boxes in lower left corner
   */
  area1 = new ApktArea(ApktAreaId.Area1, new Rectangle(0, 0));

  /**
   *  Contains laying boxes in upper left corner
   */
  area2 = new ApktArea(ApktAreaId.Area2, new Rectangle(0, 0));

  /**
   *  Contains standing boxes in upper right corner
   */
  area3 = new ApktArea(ApktAreaId.Area3, new Rectangle(0, 0));

  /**
   *  Contains laying boxes in lower right corner
   */
  area4 = new ApktArea(ApktAreaId.Area4, new Rectangle(0, 0));

  getAreas(): Map<ApktAreaId, ApktArea> {
    const areas = new Map();
    areas.set(ApktAreaId.Area1, this.area1);
    areas.set(ApktAreaId.Area2, this.area2);
    areas.set(ApktAreaId.Area3, this.area3);
    areas.set(ApktAreaId.Area4, this.area4);
    return areas;
  }

  getSpacingYForArea(currentArea: ApktArea): number {
    let returnValue = 0;
    // exit if we have less than two boxes to distribute length amongst
    if (currentArea.area.length < 2) {
      return 0;
    }

    if (this.areasWithBoxesCount() === 2) {
      // find the other area
      let comparer: ApktArea;
      this.getAreas().forEach((element) => {
        if (element.id !== currentArea.id && element.area.area() > 0) {
          comparer = element;
        }
      });
      // Only do stuff for y-axis if areas are side by side
      if (
        (currentArea.id === ApktAreaId.Area1 &&
          comparer.id === ApktAreaId.Area4) ||
        (currentArea.id === ApktAreaId.Area2 &&
          comparer.id === ApktAreaId.Area3) ||
        (currentArea.id === ApktAreaId.Area3 &&
          comparer.id === ApktAreaId.Area2) ||
        (currentArea.id === ApktAreaId.Area4 &&
          comparer.id === ApktAreaId.Area1)
      ) {
        // If current area is shorter than the comparer area; calculate spacing
        const currentAreaLength = currentArea.getActualLength(this.boxSize);
        const compareLength = comparer.getActualLength(this.boxSize);
        if (currentAreaLength < compareLength) {
          returnValue =
            (compareLength - currentAreaLength) / (currentArea.area.length - 1);
        }
      }
    } else if (this.areasWithBoxesCount() === 3) {
      const area1And2Length =
        this.area1.getActualLength(this.boxSize) +
        this.area2.getActualLength(this.boxSize);
      const area3And4Length =
        this.area3.getActualLength(this.boxSize) +
        this.area4.getActualLength(this.boxSize);
      const compactPalletLength = this.getCompactedPalletLength();
      switch (currentArea.id) {
        case ApktAreaId.Area1:
        case ApktAreaId.Area2:
          if (
            area1And2Length < area3And4Length &&
            this.area3.getActualWidth(this.boxSize) <
              this.area4.getActualWidth(this.boxSize)
          ) {
            returnValue =
              (area3And4Length - area1And2Length) /
              (this.area1.area.length + this.area2.area.length - 1);
          } else if (
            area1And2Length < area3And4Length &&
            this.area3.getActualWidth(this.boxSize) >
              this.area4.getActualWidth(this.boxSize)
          ) {
            returnValue =
              (compactPalletLength -
                this.area3.getActualLength(this.boxSize) -
                this.area1.getActualLength(this.boxSize)) /
              (this.area1.area.length + this.area3.area.length - 1);
          }
          break;
        case ApktAreaId.Area3:
          if (
            area3And4Length < area1And2Length &&
            this.area3.getActualLength(this.boxSize) <
              this.area2.getActualLength(this.boxSize)
          ) {
            returnValue =
              (area1And2Length -
                this.area1.getActualLength(this.boxSize) -
                this.area3.getActualLength(this.boxSize)) /
              (this.area3.area.length + this.area1.area.length - 1);
          }
          break;
        case ApktAreaId.Area4:
          if (area3And4Length < compactPalletLength) {
            const extraSpace = this.area3.area.length === 0 ? 1 : 0;
            returnValue =
              (compactPalletLength -
                this.area3.getActualLength(this.boxSize) -
                this.area4.getActualLength(this.boxSize)) /
              (this.area4.area.length - extraSpace);
          }
          break;
        default:
          break;
      }
    }

    return Math.ceil(returnValue * 2) / 2;
  }

  getSpacingXForArea(currentArea: ApktArea): number {
    let returnValue = 0;
    // exit if we have less than two boxes to distribute width amongst
    if (currentArea.area.width < 2) {
      return 0;
    }

    // if area is not empty and there is only one more area with boxes
    if (this.areasWithBoxesCount() === 2) {
      // find the other area
      let comparer: ApktArea;
      this.getAreas().forEach((element) => {
        if (element.id !== currentArea.id && element.area.area() > 0) {
          comparer = element;
        }
      });
      // Only do stuff for x-axis if areas are on top of eachother
      if (
        (currentArea.id === ApktAreaId.Area1 &&
          comparer.id === ApktAreaId.Area2) ||
        (currentArea.id === ApktAreaId.Area2 &&
          comparer.id === ApktAreaId.Area1) ||
        (currentArea.id === ApktAreaId.Area3 &&
          comparer.id === ApktAreaId.Area4) ||
        (currentArea.id === ApktAreaId.Area4 &&
          comparer.id === ApktAreaId.Area3)
      ) {
        // If current area is narrower than the comparer area; calculate spacing
        const currentAreaWidth = currentArea.getActualWidth(this.boxSize);
        const comparerWidth = comparer.getActualWidth(this.boxSize);
        if (currentAreaWidth < comparerWidth) {
          returnValue =
            (comparerWidth - currentAreaWidth) / (currentArea.area.width - 1);
        }
      }
    } else if (this.areasWithBoxesCount() === 3) {
      const area1And4Width =
        this.area1.getActualWidth(this.boxSize) +
        this.area4.getActualWidth(this.boxSize);
      const area2And3Width =
        this.area2.getActualWidth(this.boxSize) +
        this.area3.getActualWidth(this.boxSize);
      switch (currentArea.id) {
        case ApktAreaId.Area1:
        case ApktAreaId.Area4:
          if (area1And4Width < area2And3Width) {
            returnValue =
              (area2And3Width - area1And4Width) /
              (this.area1.area.width + this.area4.area.width - 1);
          }
          break;

        case ApktAreaId.Area2:
          const area4Length = this.area4.getActualLength(this.boxSize);

          // If area2 does not collide with area3
          if (
            area2And3Width < area1And4Width &&
            area4Length < this.area1.getActualLength(this.boxSize)
          ) {
            returnValue =
              (area1And4Width - area2And3Width) /
              (this.area2.area.width + this.area3.area.width - 1);
          } else if (
            area2And3Width < area1And4Width &&
            area4Length > this.area1.getActualLength(this.boxSize)
          ) {
            returnValue =
              (this.area1.getActualWidth(this.boxSize) -
                this.area2.getActualWidth(this.boxSize)) /
              (this.area2.area.width + this.area4.area.width - 1);
          }
          break;
        case ApktAreaId.Area3:
          if (area2And3Width < area1And4Width) {
            returnValue =
              (area1And4Width - area2And3Width) /
              (this.area2.area.width + this.area3.area.width - 1);
          }
          break;
        default:
          break;
      }
    }
    // round value up to next half
    return Math.ceil(returnValue * 2) / 2;
  }

  getCenterOffsetX(spacing: number) {
    const area1And4Width =
      this.area1.area.width * this.boxSize.width +
      this.area4.area.width * this.boxSize.length;
    const area2And3Width =
      this.area2.area.width * this.boxSize.length +
      this.area3.area.width * this.boxSize.width;
    const compactedPalletWidth = Math.max(area1And4Width, area2And3Width);
    return (this.palletSize.width - compactedPalletWidth + spacing) / 2;
  }

  getCenterOffsetY(spacing: number) {
    return (
      (this.palletSize.length - this.getCompactedPalletLength() + spacing) / 2
    );
  }

  areasWithBoxesCount(): number {
    const area1 = this.area1.area.area() > 0 ? 1 : 0;
    const area2 = this.area2.area.area() > 0 ? 1 : 0;
    const area3 = this.area3.area.area() > 0 ? 1 : 0;
    const area4 = this.area4.area.area() > 0 ? 1 : 0;
    return area1 + area2 + area3 + area4;
  }

  boxes(): number {
    return (
      this.area1.area.area() +
      this.area2.area.area() +
      this.area3.area.area() +
      this.area4.area.area()
    );
  }

  equals(comparer: Solution): boolean {
    const plainComp =
      this.area1.area.equals(comparer.area1.area) &&
      this.area2.area.equals(comparer.area2.area) &&
      this.area3.area.equals(comparer.area3.area) &&
      this.area4.area.equals(comparer.area4.area);
    const rotationComp =
      this.area1.area.equals(comparer.area3.area) &&
      this.area2.area.equals(comparer.area4.area) &&
      this.area3.area.equals(comparer.area1.area) &&
      this.area4.area.equals(comparer.area2.area);
    return plainComp || rotationComp;
  }

  private getCompactedPalletLength(): number {
    const area1And2Length =
      this.area1.getActualLength(this.boxSize) +
      Math.max(
        this.area2.getActualLength(this.boxSize),
        this.area3.getActualLength(this.boxSize)
      );
    const area3And4Length =
      this.area3.getActualLength(this.boxSize) +
      this.area4.getActualLength(this.boxSize);
    return Math.max(area1And2Length, area3And4Length);
  }
}

export class Akt {
  private defaultSolutionCount = 50;

  public getAkt(
    palletWidth: number,
    palletLength: number,
    productWidth: number,
    productLength: number,
    spacing: number
  ) {
    const solutions: Solution[] = [];
    const pallets = [];

    // Allow box padding to extend outside pallet dimensions
    const palletSize = new Rectangle(
      palletWidth + spacing,
      palletLength + spacing
    );

    // Adding padding to box dimensions
    const boxSize = new Rectangle(
      productWidth + spacing,
      productLength + spacing
    );

    /**
     *  Find potential solutions
     */
    for (
      let boxWidthsCounter = 0;
      boxWidthsCounter * boxSize.width <= palletSize.width;
      boxWidthsCounter++
    ) {
      for (
        let boxLengthCounter = 0;
        boxLengthCounter * boxSize.length <= palletSize.length + spacing;
        boxLengthCounter++
      ) {
        const solution = new Solution(boxSize, palletSize);
        const _area1 = new Rectangle(boxWidthsCounter, boxLengthCounter);
        solution.area1 = new ApktArea(
          ApktAreaId.Area1,
          _area1.area() > 0 ? _area1 : new Rectangle(0, 0)
        );

        // calculate number of boxes in area2
        const area2Width = _area1.width * boxSize.width;
        const area2Length = palletSize.length - _area1.length * boxSize.length;
        const _area2 = this.getBoxcountForArea(
          _area1.area() === 0
            ? palletSize
            : new Rectangle(area2Width, area2Length),
          boxSize.rotated90()
        );
        solution.area2 = new ApktArea(ApktAreaId.Area2, _area2);

        // calculate number of boxes in area3
        const area3Width = palletSize.width - _area2.width * boxSize.length;
        const area3Length = palletSize.length - _area1.length * boxSize.length;
        const _area3 = this.getBoxcountForArea(
          new Rectangle(area3Width, area3Length),
          boxSize
        );
        solution.area3 = new ApktArea(ApktAreaId.Area3, _area3);

        // calculate number of boxes in area4
        if (_area1.area() === 0) {
          solution.area4 = new ApktArea(ApktAreaId.Area4, new Rectangle(0, 0));
        } else {
          const area4Width = palletSize.width - _area1.width * boxSize.width;
          const area4Length =
            palletSize.length - _area3.length * boxSize.length;
          solution.area4 = new ApktArea(
            ApktAreaId.Area4,
            this.getBoxcountForArea(
              new Rectangle(area4Width, area4Length),
              boxSize.rotated90()
            )
          );
        }

        this.addToSolutions(solutions, solution);
        if (_area1.width === 0) {
          boxWidthsCounter++;
        }
      }
    }

    /**
     * Calculate box coordinates for each solution
     */
    solutions.forEach((solution, _i) => {
      const pallet: RectangleLocation[] = [];

      const area1 = solution.area1;
      const area2 = solution.area2;
      const area3 = solution.area3;
      const area4 = solution.area4;

      // Compact boxes towards the pallet center
      const centerOffsetX = solution.getCenterOffsetX(spacing);
      const centerOffsetY = solution.getCenterOffsetY(spacing);

      // Get spacing for areas:
      const area1SpacingX = solution.getSpacingXForArea(area1);
      let area1SpacingY = solution.getSpacingYForArea(area1);
      const area2SpacingX = solution.getSpacingXForArea(solution.area2);
      const area2SpacingY = solution.getSpacingYForArea(solution.area2);
      const area3SpacingX = solution.getSpacingXForArea(solution.area3);
      let area3SpacingY = solution.getSpacingYForArea(solution.area3);
      let area4SpacingX = solution.getSpacingXForArea(solution.area4);
      const area4SpacingY = solution.getSpacingYForArea(solution.area4);

      let tempSpacingY = false;
      // place area1 boxes:
      for (let l = 1; l <= area1.area.length; l++) {
        for (let w = 1; w <= area1.area.width; w++) {
          if (
            area4.area.length === 0 &&
            area2.getActualLength(boxSize) > area3.getActualLength(boxSize) &&
            area3.area.area() > 0 &&
            w === area1.area.width
          ) {
            area1SpacingY =
              area3SpacingY > 0
                ? area3SpacingY
                : (area2.getActualLength(boxSize) -
                    area3.getActualLength(boxSize)) /
                  area1.area.length;
            tempSpacingY = true;
          }
          const xOffset = centerOffsetX - spacing + area1SpacingX * (w - 1);
          const yOffset = centerOffsetY - spacing + area1SpacingY * (l - 1);
          const x = boxSize.width * (w - 0.5) + xOffset;
          const y = boxSize.length * (l - 0.5) + yOffset;
          pallet.push(
            new RectangleLocation(productWidth, productLength, x, y, false)
          );
          if (tempSpacingY) {
            area1SpacingY = 0;
            tempSpacingY = false;
          }
        }
      }

      // place area2 boxes:
      for (let l = 1; l <= area2.area.length; l++) {
        for (let w = 1; w <= area2.area.width; w++) {
          const xOffset = centerOffsetX - spacing + area2SpacingX * (w - 1);
          const yOffset =
            palletSize.length -
            area2.getActualLength(boxSize) -
            centerOffsetY -
            // - spacing
            area2SpacingY * (area2.area.length - l);
          const x = boxSize.length * (w - 0.5) + xOffset;
          const y = boxSize.width * (l - 0.5) + yOffset;
          pallet.push(
            new RectangleLocation(productWidth, productLength, x, y, true)
          );
        }
      }

      // place area3 boxes:
      for (let l = 1; l <= area3.area.length; l++) {
        for (let w = 1; w <= area3.area.width; w++) {
          // Check if we should space boxes in first column
          if (
            area2.area.length === 0 &&
            area4.getActualLength(boxSize) > area1.getActualLength(boxSize) &&
            w === 1
          ) {
            area3SpacingY =
              area1SpacingY > 0
                ? area1SpacingY
                : (area4.getActualLength(boxSize) -
                    area1.getActualLength(boxSize)) /
                  area3.area.length;
            tempSpacingY = true;
          }

          const xOffset =
            palletSize.width -
            area3.getActualWidth(boxSize) -
            centerOffsetX -
            area3SpacingX * (area3.area.width - w);
          const yOffset =
            palletSize.length -
            area3.getActualLength(boxSize) -
            centerOffsetY -
            area3SpacingY * (area3.area.length - l);
          const x = boxSize.width * (w - 0.5) + xOffset;
          const y = boxSize.length * (l - 0.5) + yOffset;
          pallet.push(
            new RectangleLocation(productWidth, productLength, x, y, false)
          );
          if (tempSpacingY) {
            area3SpacingY = 0;
            tempSpacingY = false;
          }
        }
      }

      // place area4 boxes:
      for (let l = 1; l <= area4.area.length; l++) {
        for (let w = 1; w <= area4.area.width; w++) {
          // Check if we should space top most row
          if (
            area3.area.width === 0 &&
            area2.area.length > 0 &&
            area4.area.length * boxSize.width >=
              area1.getActualLength(boxSize) &&
            l === area4.area.length
          ) {
            area4SpacingX =
              area2SpacingX > 0
                ? area2SpacingX
                : (area1.getActualWidth(boxSize) -
                    area2.getActualWidth(boxSize)) /
                  area4.area.width;
          }
          const xOffset =
            palletSize.width -
            area4.getActualWidth(boxSize) -
            centerOffsetX -
            area4SpacingX * (area4.area.width - w);
          const yOffset = centerOffsetY - spacing + area4SpacingY * (l - 1);
          const x = boxSize.length * (w - 0.5) + xOffset;
          const y = boxSize.width * (l - 0.5) + yOffset;
          pallet.push(
            new RectangleLocation(productWidth, productLength, x, y, true)
          );
        }
      }

      pallets.push(pallet);
    });

    return pallets;
  }

  private getBoxcountForArea(area: Rectangle, boxSize: Rectangle): Rectangle {
    const temp = new Rectangle(
      Math.floor(area.width / boxSize.width),
      Math.floor(area.length / boxSize.length)
    );
    return temp.area() > 0 ? temp : new Rectangle(0, 0);
  }

  private addToSolutions(solutions: Solution[], suggestedSolution: Solution) {
    // Check if a variation of the same solution is already added to solutions
    let cloneFound = false;
    solutions.forEach((solution) => {
      if (!cloneFound) {
        cloneFound = solution.equals(suggestedSolution);
      }
    });
    // A similar solution was found, exiting
    if (cloneFound) {
      return;
    }

    // Insert into solutions in descending order
    let inserted = false;
    solutions.forEach((solution, i) => {
      if (
        !inserted &&
        // Insert into solutions here if suggestedSolution contains more boxes than the current solution
        (suggestedSolution.boxes() > solution.boxes() ||
          // Or if the suggestedSolution has the same number of boxes but contains less areas with boxes
          (suggestedSolution.boxes() === solution.boxes() &&
            suggestedSolution.areasWithBoxesCount() <
              solution.areasWithBoxesCount()))
      ) {
        solutions.splice(i, 0, suggestedSolution);
        inserted = true;
        // remove last item in solutions if we have passed allowed amount
        if (solutions.length > this.defaultSolutionCount) {
          solutions.pop();
        }
      }
    });

    // Append to the end of the collection if it is not already inserted
    if (!inserted && solutions.length < this.defaultSolutionCount) {
      solutions.splice(solutions.length, 0, suggestedSolution);
    }
  }
}
