<div
  class="self"
  [ngClass]="{
    clickable: isClickable,
    'selected-card': selected,
  }"
  matRipple
>
  <div *ngIf="isClickable" class="select-hover"></div>
  <div class="split2">
    <div class="left">
      <mat-icon class="selected-check" *ngIf="selected">done</mat-icon>
      <div class="horizontal-container">
        <div class="logo-container">
          <app-company-logo
            *ngIf="
              !isHardwareProvider() &&
                hardwareType.metadata?.organization_id !== '?' &&
                hardwareType.metadata?.organization_id !== '';
              else hardwareProviderLogo
            "
            [orgId]="hardwareType.metadata?.organization_id"
          ></app-company-logo>
          <ng-template #hardwareProviderLogo>
            <ng-container *ngIf="hardwareProviderCompanyLogo$ | async as logo">
              <img
                class="logo"
                src="{{ logo }}"
                alt="{{ hardwareType.label }}"
              />
            </ng-container>
          </ng-template>
        </div>
        <div class="metadata-container">
          <h3 class="tagline">
            {{ hardwareType.hw_sw_type.name }}
          </h3>
          <h1 class="hardware-name">{{ hardwareType.label }}</h1>
        </div>
        <ng-container *ngIf="shouldDisplayActionButton">
          <div
            class="max-two-buttons"
            *ngIf="hardwareType['cardActions']?.length <= 2; else moreButton"
          >
            <button
              *ngFor="let action of hardwareType['cardActions']"
              mat-icon-button
              [color]="action.color || 'primary'"
              aria-label="Example icon button with a delete icon"
              (click)="doAction(action.actionId)"
            >
              <mat-icon>{{ action.icon }}</mat-icon>
            </button>
          </div>
          <ng-template #moreButton>
            <button [matMenuTriggerFor]="menu" class="more-button">
              <mat-icon>more_vert</mat-icon>
            </button>
          </ng-template>
          <mat-menu #menu="matMenu" class="edit-menu">
            <ng-container
              *ngIf="hardwareType['cardActions']; else defaultActions"
            >
              <ng-container *ngFor="let action of hardwareType['cardActions']">
                <button mat-menu-item (click)="doAction(action.actionId)">
                  <mat-icon>{{ action.icon }}</mat-icon>
                  <span>{{ action.label }}</span>
                </button>
              </ng-container>
            </ng-container>
            <ng-template #defaultActions>
              <ng-container *ngIf="isComponentOwner$ | async as owner">
                <button
                  *appIfCommandAvailable="'update_hardware_type'"
                  mat-menu-item
                  (click)="doAction('edit_component')"
                >
                  <mat-icon>edit</mat-icon>
                  <span>Edit component</span>
                </button>
              </ng-container>
              <button
                *appIfCommandAvailable="'delete_organization_hardware_type'"
                mat-menu-item
                (click)="doAction('delete_component')"
              >
                <mat-icon>delete</mat-icon>
                <span>Remove component</span>
              </button>
            </ng-template>
          </mat-menu>
        </ng-container>
      </div>

      <div
        *ngIf="hardwareType.metadata.description as desc"
        class="description-box"
      >
        <span>{{ desc }}</span>
      </div>
      <div
        *ngIf="
          hardwareType.metadata?.organization_id !== '?' &&
          hardwareType.metadata?.organization_id !== ''
        "
        class="owner-box"
      >
        Owner: {{ hardwareType.metadata.organization_id }}
      </div>
      <div
        class="access-box"
        *ngIf="hardwareType.organization_hw_types?.length"
      >
        <span>Accessible to:</span>
        <mat-chip-set class="org-chip-list">
          <mat-chip-option
            [selectable]="false"
            *ngFor="let orgAcc of hardwareType.organization_hw_types"
            >{{ orgAcc.organization.name }}</mat-chip-option
          >
        </mat-chip-set>
      </div>

      <div
        class="horizontal-container bottom"
        *ngIf="
          shouldDisplayPrice && currencyMap[hardwareType.metadata.currency]
        "
      >
        <ng-container
          *appVar="hardwareType.metadata.discount > 0 as hasDiscount"
        >
          <div class="price-wrapper">
            <div class="discount-wrapper">
              <span class="discount" *ngIf="hasDiscount">
                -{{ getDiscountPercent }}%
              </span>
              <span
                class="price"
                *ngIf="hardwareType.metadata?.price"
                [ngClass]="{ strikethrough: hasDiscount }"
              >
                {{
                  hardwareType.metadata.price +
                    ' ' +
                    (currencyMap[hardwareType.metadata.currency] || 'EUR')
                }}
              </span>
            </div>

            <span class="price" *ngIf="hasDiscount">
              {{ priceDiscount }}
            </span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="right">
      <div class="public-icon">
        <div class="chip-icon">
          {{ hardwareType.public ? 'Public ' : 'Private ' }}
          <mat-icon>{{
            hardwareType.public ? 'public' : 'public_off'
          }}</mat-icon>
        </div>
      </div>
      <app-model-preview
        class="model-preview"
        [hardware]="hardwareType"
        [interactive]="false"
      ></app-model-preview>
    </div>
  </div>
</div>
