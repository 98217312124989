import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { debounceTime, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { LabelOrientation } from 'src/app/models_new/enums/label-orientation';
import { NotificationService } from 'src/app/services/notification.service';
import { ObjectUtils } from 'src/app/utils/object';
import { ProductHandlerDataService } from '../../../../models_new/classes/3dview/handler-services/product-handler-data.service';
import { ThreeHandlerMode } from '../../../../models_new/classes/3dview/three-handler-mode';
import { ApiProduct } from '../../../../models_new/classes/api-models/ApiProduct';
import { ApiProductionLine } from '../../../../models_new/classes/api-models/ApiProduction_line';
import { Field } from '../../../../models_new/classes/field';
import { defaultApiProduct } from '../../../../models_new/config/default/api-default/default-api-product';
import {
  CardFieldIds,
  labelOrientations,
} from '../../../../models_new/config/form_fields';
import { DialogSize } from '../../../../models_new/enums/dialogSize';
import { InfoApiService } from '../../../../services/api/info-api.service';
import { DialogService } from '../../../../services/dialog.service';
import { OrganizationLogoService } from '../../../../services/organization-logo.service';
import { InfoPopupComponent } from '../../../dialogs/info-popup/info-popup.component';
import {
  getProductCardsFormGroup,
  newProductCardFields,
} from './new-product-card-fields';
import { AppLayoutService } from 'src/app/services/app-layout.service';
import { UnitSystemType } from '../../../../utils/unit-utils';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { IAssetStoreLoadingConfig } from 'src/app/models_new/types/asset/new-asset-store-loading-config';
import { productAssetConfig } from 'src/app/models_new/config/asset/asset-config';
import { AssetIDs } from 'src/app/models_new/enums/asset-ids';
import { environment } from 'src/environments/environment';

export type ProductCardMode =
  | 'options'
  | 'create'
  | 'pick'
  | 'edit'
  | 'view'
  | null;

interface FieldUpdate {
  product_height: number;
  product_length: number;
  product_name: string;
  product_weight: number;
  product_width: number;
  product_label_orientation: {
    value: LabelOrientation;
    name: string;
    icon: string;
  };
  product_type: IAssetStoreLoadingConfig<any>;
}

@Component({
  selector: 'app-new-product-card',
  templateUrl: './new-product-card.component.html',
  styleUrls: ['./new-product-card.component.scss'],
  providers: [ProductHandlerDataService],
})
export class NewProductCardComponent implements OnInit, OnChanges {
  ThreeHandlerMode = ThreeHandlerMode;
  CardFieldIds = CardFieldIds;
  modeFieldConfigs: any;
  fields: Field[];
  formGroup: UntypedFormGroup;

  // A simplification of `this.unit === 'imperial'`, as its a recurrent check.
  private isImperial: boolean;
  // Wether it's used in an public setting
  @Input() isPublic = false;
  // Mode of the card
  @Input() mode: ProductCardMode = 'options';
  // Sets the save button text
  @Input() saveLabel = 'SAVE';
  // Sets the product card to the given product
  @Input() product: ApiProduct;
  // Forces cancel button to be visible regardless of mode
  @Input() enableCancel = true;
  // Unit to use (imperial or metric). Will be defaulted to metric if not set onInit
  @Input() unit: UnitSystemType;
  // Event output for options button
  @Output() option$ = new EventEmitter<ProductCardMode>();
  // Event output for save button
  @Output() save$ = new EventEmitter<void>();
  // Event output for save and create pattern button
  @Output() saveAndCreatePattern$ = new EventEmitter<void>();
  // Event output for cancel button
  @Output() cancel$ = new EventEmitter<void>();
  // Product in question
  @Output() product$ = new ReplaySubject<ApiProduct>(1);
  // Inner subject that debounces events and emits them to "this.product$"
  private _product$ = new ReplaySubject<ApiProduct>(1);
  // Produciton line selected
  @Output() prodline$ = new EventEmitter<ApiProductionLine>();

  destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  unitFields: CardFieldIds[] = [
    CardFieldIds.ProductLength,
    CardFieldIds.ProductWidth,
    CardFieldIds.ProductHeight,
    CardFieldIds.ProductWeight,
  ];

  get isProductVisible(): boolean {
    return this.mode === 'pick' || this.mode === 'edit';
  }
  get isProdlineVisible(): boolean {
    return (
      this.mode === 'create' || this.mode === 'pick' || this.mode === 'edit'
    );
  }
  get isLabelOrientationVisible(): boolean {
    return this.mode !== 'options';
  }

  showSide$ = new Subject<'front' | 'right' | 'back' | 'left' | 'default'>();

  constructor(
    private notify: NotificationService,
    public logoService: OrganizationLogoService,
    private infoApi: InfoApiService,
    private dialogService: DialogService,
    public appLayout: AppLayoutService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit(): void {
    if (!this.unit) {
      this.unit =
        this.localStorageService.getData(
          LocalStorageKey.PREFERRED_UNIT_SYSTEM
        ) || 'metric';
    }
    this.fields = newProductCardFields(this.isPublic, this.unit);
    this.isImperial = this.unit === 'imperial';
    this.formGroup = getProductCardsFormGroup(this.fields);
    if (this.mode === 'create' || this.mode === 'edit') {
      if (this.mode === 'create') {
        this.product = new ApiProduct(
          ObjectUtils.cloneObject(defaultApiProduct)
        );
      }
      // Set pre-selected values if loading an existing product.
      this.fields
        .find((f) => f.id === CardFieldIds.ProductName)
        .formControl.setValue(this.product.name);
      this.fields
        .find((f) => f.id === CardFieldIds.ProductLength)
        .formControl.setValue(this.product.data.length);
      this.fields
        .find((f) => f.id === CardFieldIds.ProductWidth)
        .formControl.setValue(this.product.data.width);
      this.fields
        .find((f) => f.id === CardFieldIds.ProductHeight)
        .formControl.setValue(this.product.data.height);
      this.fields
        .find((f) => f.id === CardFieldIds.ProductWeight)
        .formControl.setValue(
          this.isImperial
            ? this.product.data.weight * 0.001
            : this.product.data.weight
        );
      this.fields
        .find((f) => f.id === CardFieldIds.ProductType)
        .formControl.setValue(
          productAssetConfig.find(
            (prodModel) =>
              prodModel.id === (this.product.data.model_type || AssetIDs.Box)
          )
        );
      this.fields
        .find((f) => f.id === CardFieldIds.ProductLabelOrientation)
        .formControl.setValue(
          labelOrientations.find(
            (f) => f.value === this.product.data.label_orientation
          )
        );
    }

    this.modeFieldConfigs = [
      {
        field: this.fields.find((f) => f.id === CardFieldIds.ProductName),
        visible: () => this.mode !== 'options',
      },
      {
        field: this.fields.find((f) => f.id === CardFieldIds.ProductLength),
        visible: () => this.mode !== 'options',
      },
      {
        field: this.fields.find((f) => f.id === CardFieldIds.ProductWidth),
        visible: () => this.mode !== 'options',
      },
      {
        field: this.fields.find((f) => f.id === CardFieldIds.ProductHeight),
        visible: () => this.mode !== 'options',
      },
      {
        field: this.fields.find((f) => f.id === CardFieldIds.ProductWeight),
        visible: () => this.mode !== 'options',
      },
      {
        field: this.fields.find(
          (f) => f.id === CardFieldIds.ProductLabelOrientation
        ),
        visible: () => true,
        infoPopup: 'label_orientation',
      },
      /** @todo: Keep track of this disabled feature: Alternative Product Models. Disabled as rendered not ready for production yet, live on DEV only */
      {
        field: this.fields.find((f) => f.id === CardFieldIds.ProductType),
        visible: () =>
          environment.development ? this.mode !== 'options' : false,
      },
    ];
    this._product$
      .pipe(takeUntil(this.destroy$), debounceTime(100))
      .subscribe(this.product$);

    this.connectFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.mode?.currentValue && !changes.mode.isFirstChange()) {
      this.modeFieldConfigs.map((info) => info.field.enable());

      const modeChange = changes.mode.currentValue;
      if (modeChange === 'view' || modeChange === 'pick') {
        this.disableFields([
          CardFieldIds.ProductName,
          CardFieldIds.ProductType,
          CardFieldIds.ProductLength,
          CardFieldIds.ProductWidth,
          CardFieldIds.ProductHeight,
          CardFieldIds.ProductWeight,
          CardFieldIds.ProductLabelOrientation,
        ]);
      }
    }
    if (changes.product?.currentValue && !changes.product.isFirstChange()) {
      const p = ObjectUtils.cloneObject(changes.product.currentValue);
      this.fields
        .find((f) => f.id === CardFieldIds.ProductName)
        .formControl.setValue(p.name);
      this.fields
        .find((f) => f.id === CardFieldIds.ProductLength)
        .formControl.setValue(p.data.length);
      this.fields
        .find((f) => f.id === CardFieldIds.ProductWidth)
        .formControl.setValue(p.data.width);
      this.fields
        .find((f) => f.id === CardFieldIds.ProductHeight)
        .formControl.setValue(p.data.height);
      this.fields
        .find((f) => f.id === CardFieldIds.ProductWeight)
        .formControl.setValue(
          this.isImperial ? p.data.weight * 0.001 : p.data.weight
        );
      this.fields
        .find((f) => f.id === CardFieldIds.ProductLabelOrientation)
        .formControl.setValue(
          typeof p.data.label_orientation === 'undefined'
            ? labelOrientations[0]
            : labelOrientations.find(
                (f) => f.value === p.data.label_orientation
              )
        );
    }
  }

  connectFields(): void {
    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((update: FieldUpdate) => {
        this.fieldUpdate(update);
      });
  }

  save() {
    if (this.formGroup.invalid) {
      this.notify.showError('Please fill out all fields');
    } else {
      this.save$.emit();
    }
  }

  saveAndCreatePattern() {
    if (this.formGroup.invalid) {
      this.notify.showError('Please fill out all fields');
    } else {
      this.saveAndCreatePattern$.emit();
    }
  }

  fieldUpdate(update: FieldUpdate): void {
    this.product.name = update.product_name;
    this.product.data.length = +update.product_length;
    this.product.data.width = +update.product_width;
    this.product.data.height = +update.product_height;
    this.product.data.weight = this.isImperial
      ? +update.product_weight * 1000
      : +update.product_weight;
    update.product_label_orientation
      ? (this.product.data.label_orientation =
          update.product_label_orientation.value)
      : null;
    this.product.data.model_type = update.product_type.id;
    this._product$.next(this.product);
  }

  disableFields(ids: CardFieldIds[]): void {
    for (const id of ids) {
      this.getField(id).disable();
    }
  }

  getField(id: CardFieldIds): Field {
    return this.fields.find((field) => field.id === id);
  }

  option(option: ProductCardMode): void {
    this.option$.emit(option);
  }

  openInfoPopup(id: string, _values?: { [key: string]: any }) {
    const substitutions = {};

    const data = {
      id: id,
      dataSource: this.infoApi.getPatternInfoCards(),
      substitutions: substitutions,
    };

    this.dialogService.showCustomDialog(
      InfoPopupComponent,
      DialogSize.MEDIUM,
      null,
      data,
      true
    );
  }
}
