import { ISceneApiFileType } from '../../types/simulation-api-file-type';
import { ApiRobotConfiguration } from './ApiRobotConfiguration';
import { ApiSimulation } from './ApiSimulation';

export interface IApiScene {
  id: string;
  name: string;
  /**
   * @deprecated This field is deprecated and will be removed in a future release.
   * It has not been used for an extended period and should be avoided in new code.
   */
  description?: string;
  data: ISceneApiFileType;
  organization_id: string;
  created_at?: string;
  updated_at?: string;
  simulations: ApiSimulation[];
  robot_configurations?: ApiRobotConfiguration[];
  owner_organization?: {
    name: string;
  };
  image?: {
    id: string;
    url?: string;
  };
}

export class ApiScene implements IApiScene {
  id: string;
  name: string;
  /**
   * @deprecated This field is deprecated and will be removed in a future release.
   * It has not been used for an extended period and should be avoided in new code.
   */
  description?: string;
  data: ISceneApiFileType;
  organization_id: string;
  created_at?: string;
  updated_at?: string;
  simulations: ApiSimulation[];
  robot_configurations?: ApiRobotConfiguration[];
  owner_organization?: {
    name: string;
  };
  image?: {
    id: string;
    url?: string;
  };
  updatedImage?: string;

  gripper?: string;
  base?: string;
  conveyor?: string;
  lifting_column?: string;
  robot_name?: string;

  constructor(scene: IApiScene) {
    for (const d in scene) {
      if (scene.hasOwnProperty(d)) {
        this[d] = scene[d];
      }
    }
  }
}
