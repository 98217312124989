import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PalletSummary } from 'src/app/models_new/classes/pallet-summary';
import { Project } from 'src/app/models_new/classes/project';
import { SubnavViewService } from 'src/app/services/subnav-view.service';
import { ExportImportService } from 'src/app/services/export-import.service';

@Component({
  selector: 'app-advanced-navbar',
  templateUrl: './advanced-navbar.component.html',
  styleUrls: ['./advanced-navbar.component.scss'],
})
export class AdvancedNavbarComponent {
  @Input() project: Project;
  showSettings: boolean = false;
  @Output() settingsClick = new EventEmitter<boolean>();
  @Output() palletToggleClick = new EventEmitter<boolean>();

  constructor(
    public subnavView: SubnavViewService,
    public exportImportService: ExportImportService
  ) {}

  getPalletSummary(): PalletSummary {
    return this.project.getActivePallet().palletSummary
      ? this.project.getActivePallet().palletSummary
      : null;
  }
}
