import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Data,
  NavigationEnd,
  Router,
} from '@angular/router';
import { combineLatest, Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { filter, map } from 'rxjs/operators';
import { StateService } from '../auth/state.service';
import { pagesPATH } from '../models_new/config/pages';
import { IBreadcrumb } from '../models_new/types/breadcrumb';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  // Subject emitting the breadcrumb hierarchy
  private readonly _breadcrumbs$ = new BehaviorSubject<IBreadcrumb[]>([]);

  // Observable exposing the breadcrumb hierarchy
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();
  readonly organizationBreadcrumbs$: Observable<IBreadcrumb[]> =
    this.getOrganizationBreadcrumbs();

  private readonly organizationPages: { [key: string]: string[] } = {
    sales_organization: [pagesPATH.CUSTOMERS],
    customer_organization: [pagesPATH.CUSTOMERS],
  };

  constructor(private router: Router, private stateService: StateService) {
    this.router.events
      .pipe(
        // Filter the NavigationEnd events as the breadcrumb is updated only when the route reaches its end
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((_event) => {
        // Construct the breadcrumb hierarchy
        const root = this.router.routerState.snapshot.root;
        const breadcrumbs: IBreadcrumb[] = [];
        this.addBreadcrumb(root, [], breadcrumbs);

        // Emit the new hierarchy
        this._breadcrumbs$.next(breadcrumbs);
      });
  }

  private getOrganizationBreadcrumbs(): Observable<IBreadcrumb[]> {
    return combineLatest([
      this.stateService.sales_organization$,
      this.stateService.customer_organization$,
    ]).pipe(
      map(([salesOrg, customerOrg]) => {
        let orgBreadcrumbs = [];

        if (salesOrg) {
          let path = [...this.organizationPages['sales_organization']];
          orgBreadcrumbs.push({
            label: salesOrg.name,
            path: path,
          });
        }

        if (customerOrg) {
          const path = [...this.organizationPages['customer_organization']];
          path.push(customerOrg.id);
          orgBreadcrumbs.push({
            label: customerOrg.name,
            path: path,
          });
        }

        return orgBreadcrumbs;
      })
    );
  }

  private addBreadcrumb(
    route: ActivatedRouteSnapshot,
    parentUrl: string[],
    breadcrumbs: IBreadcrumb[]
  ) {
    if (route) {
      // Construct the route URL
      const routeUrl = parentUrl.concat(route.url.map((url) => url.path));

      // Add an element for the current route part
      if (route.data.breadcrumb) {
        const breadcrumb = {
          label: this.getLabel(route.data),
          path: '/' + routeUrl.join('/'),
          routeTo: route.data.bcRouteTo ? route.data.bcRouteTo : undefined,
        };

        breadcrumbs.push(breadcrumb);
      }

      // Add another element for the next route part
      this.addBreadcrumb(route.firstChild, routeUrl, breadcrumbs);
    }
  }

  private getLabel(data: Data) {
    // The breadcrumb can be defined as a static string or as a function to construct the breadcrumb element out of the route data
    return typeof data.breadcrumb === 'function'
      ? data.breadcrumb(data)
      : data.breadcrumb;
  }
}
