import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-patterns-wrapper-component',
  templateUrl: './patterns-wrapper.component.html',
  styleUrls: ['./patterns-wrapper.component.scss'],
})
export class PatternsWrapperComponent implements OnDestroy {
  ready = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
