import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import {
  URDFJoint,
  URDFLink,
  URDFRobot,
} from '@rocketfarm/urdf-loader/src/URDFClasses';
import { PartType } from '../models_new/enums/sim-config-part-type';
import { URDFUtils } from '../utils/urdf-utils';

export enum JointNames {
  WorldFrame = 'world_frame',
  FrameLiftkit = 'frame_liftkit',
  LiftkitBasebracket = 'liftkit_basebracket',
  BasebracketRobotbase = 'basebracket_robotbase',
  ToolmountOffsetBracket = 'toolmount_offsetbracket',
  OffsetBracketGripper = 'offsetbracket_gripper',
  PalletRight = 'pallet_right',
  PalletRightLip = 'pallet_right_lip',
  PalletLeft = 'pallet_left',
  PalletLeftLip = 'pallet_left_lip',
  WorldPrimaryConveyor = 'world_conveyor',
  ConveyorBoxFreeHeight = 'conveyor_boxFreeHeight_joint',
  ConveyorGuideOffsetX = 'conveyor_guide_xoffset',
  ConveyorGuide = 'conveyor_guide',
  ConveyorBox = 'conveyor_box',
  WorldSecondaryConveyor = 'world_secondary_conveyor',
  SecondaryConveyorBoxFreeHeight = 'secondary_conveyor_boxFreeHeight_joint',
  SecondaryConveyorGuideOffsetX = 'secondary_conveyor_guide_xoffset',
  SecondaryConveyorGuide = 'secondary_conveyor_guide',
  SecondaryConveyorBox = 'secondary_conveyor_box',
  Tool0 = 'tool0',
  SceneJoint = 'world_pally_joint',
}
export enum LinkNames {
  World = 'world',
  Frame = 'frame',
  Basebracket = 'base_bracket',
  RobotWrist3 = 'wrist_3',
  RobotTool0 = 'tool0',
  OffsetBracket = 'offset_bracket',
  Gripper = 'gripper',
  ConveyorMock = 'conveyor_mock',
  ConveyorBoxFreeHeight = 'conveyor_boxFreeHeight',
  ConveyorGuideOffsetX = 'guide_offsetx',
  ConveyorGuide = 'guide',
  ConveyorBox = 'conveyor_box',
  SecondaryConveyorMock = 'secondary_conveyor_mock',
  SecondaryConveyorBoxFreeHeight = 'secondary_conveyor_boxFreeHeight',
  SecondaryConveyorGuideOffsetX = 'secondary_guide_offsetx',
  SecondaryConveyorGuide = 'secondary_guide',
  SecondaryConveyorBox = 'secondary_conveyor_box',
  PalletRight = 'p1',
  PalletRightLip = 'p1_lip',
  PalletLeft = 'p2',
  PalletLeftlip = 'p2_lip',
  Scene = 'pally',
}

@Injectable({
  providedIn: 'root',
})
export class ProjectRobotDescriptorService implements OnDestroy {
  connection_joints: Map<string, URDFJoint> = new Map<string, URDFJoint>();
  connection_links: Map<string, URDFLink> = new Map<string, URDFLink>();
  partType: Map<string, PartType> = new Map<string, PartType>();
  root: URDFRobot;

  mapped$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

  ngOnDestroy(): void {
    this.connection_joints.clear();
    this.connection_links.clear();
    this.partType.clear();
    this.root = undefined;
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setRootObject(root: URDFRobot): void {
    this.root = root;
  }

  getJointByID(id: string): URDFJoint {
    return this.connection_joints.get(id);
  }

  setJoint(id: string, joint: URDFJoint, type: PartType): void {
    this.connection_joints.set(id, joint);
    this.partType.set(id, type);
  }

  getLinkByID(id: string): URDFLink {
    return this.connection_links.get(id);
  }

  setLink(id: string, link: URDFLink, type: PartType = PartType.NONE): void {
    this.connection_links.set(id, link);
    this.partType.set(id, type);
  }

  clear(): void {
    this.connection_joints.clear();
    this.connection_links.clear();
    this.partType.clear();
    this.setRootObject(URDFUtils.makeEmptyURDFRobot());
    this.mapped$.next(false);
  }

  mapRobotStructure(robot: URDFRobot): void {
    this.clear();
    this.setRootObject(robot);
    robot.traverse((child) => {
      for (const joint of URDFUtils.jointLookup) {
        if (joint.name === child.name) {
          this.setJoint(joint.tag, child as URDFJoint, joint.type);
          // Hide pallet lips by default
          if (
            joint.tag === JointNames.PalletLeftLip ||
            joint.tag === JointNames.PalletRightLip
          ) {
            (child as URDFJoint).visible = false;
          }
        }
      }
      for (const link of URDFUtils.linkLookup) {
        if (link.name === child.name) {
          this.setLink(link.tag, child as URDFLink);
        }
      }
    });
    this.mapped$.next(true);
  }
}
