<ng-container *appVar="showSidebar$ | async as showSidebar">
  <app-toolbar-component
    *ngIf="showToolbar$ | async"
    [showSidebar]="showSidebar"
  ></app-toolbar-component>
  <ng-container
    *ngIf="!isLandingPage && globalNotification$ | async as globalNotification"
  >
    <app-notification-banner
      [notification]="globalNotification"
    ></app-notification-banner>
  </ng-container>
  <div class="content-wrapper" [class.spacer-toolbar]="showToolbar$ | async">
    <div
      class="router-container"
      [class.spacer-sidebar]="
        showSidebar && sidenavService.isSidenavReady$ | async
      "
      [class.fixed-sidebar]="sidenavService.keepExpanded$ | async"
      (click)="sidenavService.toggleSidebar()"
    >
      <router-outlet></router-outlet>
    </div>
    <app-main-sidenav *ngIf="showSidebar"></app-main-sidenav>
  </div>
  <app-status-messages></app-status-messages>

  <div *ngIf="developmentWatermark" class="development-watermark">
    <span>Development</span>
  </div>
</ng-container>
