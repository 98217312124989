<app-page>
  <app-breadcrumb-nav></app-breadcrumb-nav>
  <app-header>
    <h1 app-title>Installed Robots</h1>
    <app-header-actions>
      <button
        mat-raised-button
        pally-rounded-button-m
        color="primary"
        (click)="newInstallation()"
        *appIfCommandAvailable="'create_installed_robot'"
      >
        <mat-icon>add</mat-icon>New
      </button>
    </app-header-actions>
  </app-header>

  <mat-divider></mat-divider>

  <ng-container *ngIf="robots$ | async as robots">
    <!-- Loading state -->
    <app-loading *ngIf="robots.isLoading"></app-loading>

    <app-content-switcher
      *ngIf="robots.didSucceed"
      [selected]="blockSelected"
      [sortingColumns]="sortingColumns"
      objectType="installed_robot"
      (selectedFiltering)="tableFilter = objUtil.cloneObject($event)"
      (order)="orderBy = $event"
      (delete)="onDelete()"
    >
      <ng-template #table>
        <app-inventory-table
          [filter]="tableFilter"
          [sortBy]="orderBy"
          [data]="robots.value"
          [displayedColumns]="displayedColumns"
          [rowActions]="rowActions"
          [rowCta]="rowCta"
          (rowCtaAction)="ctaClicked($event)"
          (rowAction)="actionClicked($event)"
          (rowSelected)="rowClicked($event)"
          (blockSelectedChange)="blockSelected = $event"
        >
        </app-inventory-table>
      </ng-template>
    </app-content-switcher>
  </ng-container>
</app-page>
