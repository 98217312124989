import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { PickerSortingType } from 'src/app/models_new/enums/picker-sorting-type';
import { PickerType } from 'src/app/models_new/enums/picker-type';
import { IPickerData } from 'src/app/models_new/types/picker-data';
import {
  IPickerDescription,
  IPickerField,
  pickerDescription,
} from 'src/app/models_new/types/picker-description';
import { HardwareApiService } from 'src/app/services/api/hardware-api.service';
import { PickerApiService } from 'src/app/services/api/picker-api.service';
import {
  ITableAction,
  ITableData,
  TableComponent,
} from '../table/table.component';
import { DeletePromptComponent } from '../../dialogs/delete-prompt/delete-prompt.component';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { EditComponent } from '../../dialogs/edit/edit.component';
import { FieldType } from 'src/app/models_new/types/field-type';
import { SoftwareApiService } from 'src/app/services/api/software-api.service';
import { get } from 'lodash-es';
import { combineLatest, of, Subject } from 'rxjs';
import { map, skipWhile, switchMap, take, tap } from 'rxjs/operators';
import { CardPickerListComponent } from '../card-picker-list/card-picker-list.component';
import { PickerSelectionAmount } from 'src/app/models_new/enums/picker-selection-amount';
import { StateService } from 'src/app/auth/state.service';
import { PickerDisplayMode } from 'src/app/models_new/enums/picker-display-mode';
import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { animate, style, transition, trigger } from '@angular/animations';
import { IApiScene } from 'src/app/models_new/classes/api-models/ApiScene';
import { IApiStrategy } from 'src/app/models_new/classes/api-models/ApiStrategy';
import { PageEvent } from '@angular/material/paginator';

type PickerAction = 'edit' | 'delete';

@Component({
  selector: 'app-generic-inventory-picker',
  templateUrl: './generic-inventory-picker.component.html',
  styleUrls: ['./generic-inventory-picker.component.scss'],
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class GenericInventoryPickerComponent implements OnInit, OnDestroy {
  @ViewChild('table') table: TableComponent<PickerAction>;
  @ViewChildren(CardPickerListComponent)
  pickerCards: QueryList<CardPickerListComponent<PickerAction>>;

  constructor(
    private pickerApi: PickerApiService,
    private hardwareApi: HardwareApiService,
    private softwareApi: SoftwareApiService,
    private dialog: MatDialog,
    private notification: NotificationService,
    public stateService: StateService
  ) {}

  @Input() pickerType: PickerType = PickerType.PRODUCTION_LINE;
  @Input() selectionAmount: PickerSelectionAmount = 'SELECT_MULTIPLE';
  @Input() initialSelection: IPickerData[] = [];
  @Output() didChangeSelection: EventEmitter<IPickerData[]> =
    new EventEmitter();
  @Output() shouldClose: EventEmitter<void> = new EventEmitter();
  selected: IPickerData[] = [];
  selectedTableData: ITableData[] = [];

  // Enum declarations
  PickerType = PickerType;

  _type: PickerType;
  pickerDescription: IPickerDescription;
  displayMode: PickerDisplayMode = 'TABLE';
  noDataTextTemplate = 'Your organization has no {type}s';
  noDataText: string;
  shouldDisplayCreateButton: boolean = false;
  isLoading = false;

  page: number = 0;
  numItemsInPage = 8;

  listColumns: string[] = [];
  listColumnUnits: string[] = [];
  listData: ITableData<any>[] = [];
  columnColors: string[] = [];

  searchTerm: string = '';

  data: { [key: string]: IPickerData } = {};
  filteredData: IPickerData[] = [];
  doesAllowActions: boolean = false;

  sortingType: PickerSortingType = 'NAME';
  allSortingTypes: { key: PickerSortingType; value: string }[] = [
    { key: 'NAME', value: 'Name' },
    { key: 'LAST_MODIFIED', value: 'Last Modified' },
    { key: 'CREATION_DATE', value: 'Creation Date' },
  ];

  hwTypes: IApiScene[] = [];
  swTypes: IApiStrategy[] = [];

  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  set type(newType: PickerType) {
    this._type = newType;
    this.pickerDescription = pickerDescription(newType);
    this.createNoDataText();
  }
  get type(): PickerType {
    return this._type;
  }
  get title(): string {
    return this.pickerDescription.name;
  }

  ngOnInit(): void {
    // Figure out the allowed actions the user may make.
    this.doesAllowActions = true;

    this.type = this.pickerType;

    this.selected = this.initialSelection;

    this.generateColumns();
    this.updateData();
  }

  createNoDataText() {
    let text = this.noDataTextTemplate.replace(
      '{type}',
      this.pickerDescription.name.toLowerCase()
    );
    if (this.searchTerm && this.searchTerm !== '') {
      text += ` matching “${this.searchTerm}”.`;
      this.shouldDisplayCreateButton = false;
    } else {
      text += '.';
      //NOTE: If reimplementing add-button set this to true;
      //this.shouldDisplayCreateButton = true;
      this.shouldDisplayCreateButton = false;
    }
    this.noDataText = text;
  }

  createUnitString(untreatedUnit: string): string {
    let unit = '';
    let superMode = 0;
    for (let i = 0; i < untreatedUnit.length; i++) {
      let char = untreatedUnit[i];
      if (char === ' ') {
        char = '&nbsp;';
      }

      if (superMode == 1 && char == '{') {
        superMode = 2;
        continue;
      }

      if (char == '^') {
        superMode = 1;
        unit += '<sup>';
        continue;
      }

      if (superMode == 2 && char == '}') {
        superMode = 0;
        unit += '</sup>';
        continue;
      }

      unit += char;

      if (superMode == 1) {
        superMode = 0;
        unit += '</sup>';
      }
    }

    return unit;
  }

  tableFieldName(field: IPickerField) {
    if (field.unit) {
      return field.name + ' [' + field.unit + ']';
    } else {
      return field.name;
    }
  }

  getValueForKeyPath(
    data: object,
    keyPath: string,
    unwrapAs: string = ''
  ): any {
    const keyArray = keyPath.split('.');
    let objectReference = data;
    for (let i = 0; i < keyArray.length; i++) {
      const key = keyArray[i];

      if (key.startsWith('$')) {
        switch (key.slice(1)) {
          case 'length':
            if (
              typeof objectReference == 'object' ||
              typeof objectReference == 'string'
            )
              return (objectReference as any).length;
            else return unwrapAs;
        }
      }

      if (i === keyArray.length - 1) {
        const value = objectReference[key];
        if (value === null || value === undefined) return unwrapAs;
        else return value;
      }

      if (typeof objectReference[key] == 'object')
        objectReference = objectReference[key];
      else return unwrapAs;
    }
  }

  generateColumns() {
    const availableFields = this.pickerDescription.fields.filter(
      (field) =>
        field.visibleIn === undefined ||
        field.visibleIn.includes(this.displayMode)
    );

    this.columnColors = availableFields.map((field) => field.color || '');

    if (this.displayMode === 'TABLE') {
      this.listColumns = ['select'].concat(
        availableFields.map((field) => this.tableFieldName(field))
      );
    } else {
      let columns: string[] = [];
      let units: string[] = [];
      availableFields.forEach((field) => {
        columns.push(field.name);
        if (field.unit) {
          units.push(field.unit);
        } else {
          units.push('');
        }
      });
      this.listColumns = columns;
      this.listColumnUnits = units;
    }
  }

  getHWSWLabel(hwswTypeToCheck: string): string {
    for (const hwType of this.hwTypes) {
      if (hwType.name === hwswTypeToCheck) return hwType.name;
    }
    for (const swType of this.swTypes) {
      if (swType.name === hwswTypeToCheck) return swType.name;
    }
    return hwswTypeToCheck;
  }

  pickerDataToTableData(cell: IPickerData): ITableData<PickerAction> {
    const columnOffset = this.displayMode === 'TABLE' ? 1 : 0;
    const fields = this.pickerDescription.fields;
    const rowData: Record<string, string> = {};
    for (let i = 0; i < fields.length; i++) {
      const field = fields[i];
      const columnKey = this.listColumns[i + columnOffset];
      let value = '' + get(cell, field.key, '--');
      if (field.hwSwType) {
        value = this.getHWSWLabel(value);
      }
      rowData[columnKey] = value;
    }
    rowData['updated_at'] = cell?.updated_at;
    rowData['id'] = cell?.id;
    let actions: ITableAction<PickerAction>[] = [
      { actionId: 'edit', label: 'Edit', icon: 'edit' },
      { actionId: 'delete', label: 'Delete', icon: 'delete' },
    ];
    if (this.type === PickerType.ROBOT_CONFIGURATION) {
      actions = [{ actionId: 'delete', label: 'Delete', icon: 'delete' }];
    }
    return {
      data: rowData,
      actions: actions,
    };
  }

  setListData(data: IPickerData[]) {
    this.generateColumns();

    const listData = data.map((cell) => {
      return this.pickerDataToTableData(cell);
    });

    this.listData = listData;
  }

  searchListData(datas: IPickerData[], searchTerm: string): IPickerData[] {
    const searchKeyPath = this.pickerDescription.searchKey;
    const term = searchTerm.toLowerCase().split(' ').join('');
    if (term === '') {
      return datas;
    }
    let results: IPickerData[] = [];
    for (const data of datas) {
      const value = get(data, searchKeyPath, 'none');
      if (value.toLowerCase().includes(term)) {
        results.push(data);
      }
    }
    return results;
  }

  sortListData(
    datas: IPickerData[],
    sortType?: PickerSortingType,
    customSortingKey?: string
  ): IPickerData[] {
    let resultDatas = [...datas];
    let sortingKeyPath = customSortingKey ? customSortingKey : null;
    if (!sortingKeyPath) {
      switch (sortType) {
        case 'NAME':
          sortingKeyPath = this.pickerDescription.sortingKeys.name;
          break;
        case 'LAST_MODIFIED':
          sortingKeyPath = this.pickerDescription.sortingKeys.lastModified;
          break;
        case 'CREATION_DATE':
          sortingKeyPath = this.pickerDescription.sortingKeys.createdAt;
          break;
        default:
          this.pickerDescription.sortingKeys.name;
      }
    }
    return resultDatas.sort((a, b) => {
      const value1 = get(a, sortingKeyPath, 'none') as string;
      const value2 = get(b, sortingKeyPath, 'none') as string;
      return value1.localeCompare(value2);
    });
  }

  filterListData(data: IPickerData[]): IPickerData[] {
    const filteredList = this.searchListData(data, this.searchTerm);
    const sortedList = this.sortListData(filteredList, this.sortingType);
    return sortedList;
  }

  arrayToObjectByKey(array: object[], key: string): { [key: string]: any } {
    let result = {};
    for (const elm of array) {
      const elmKey = elm[key];
      if (elmKey) result[elmKey] = elm;
    }
    return result;
  }

  fetchAllHwTypes(orgId: string) {
    combineLatest([
      this.hardwareApi.fetchSimWizSalesOrgsHardwares(orgId),
      this.hardwareApi.fetchSimWizHardwares(orgId),
    ])
      .pipe(
        take(1),
        map(([sos, cos]) => sos.concat(cos))
      )
      .subscribe({
        next: (hwTypes) => {
          this.hwTypes = hwTypes;
        },
        error: (err) => this.notification.showError(err),
      });
  }

  fetchSwTypes(orgId: string) {
    combineLatest([
      this.softwareApi.fetchSimWizSalesOrgsStrategies(orgId),
      this.softwareApi.fetchSimWizStrategies(orgId),
    ])
      .pipe(
        take(1),
        map(([sos, cos]) => sos.concat(cos))
      )
      .subscribe({
        next: (swTypes) => {
          this.swTypes = swTypes;
        },
        error: (err) => this.notification.showError(err),
      });
  }

  updateData() {
    this.isLoading = true;
    this.stateService
      .getCustomerOrSalesOrganizationPreserveState()
      .pipe(
        switchMap((org: ApiOrganization) =>
          combineLatest([
            this.pickerApi.fetchDataForType(this.type, org.id),
            of(org.id),
          ])
        ),
        map(([data, orgId]) => {
          this.data = this.arrayToObjectByKey(data, 'id');
          this.filteredData = this.filterListData(data);
          this.setListData(this.filteredData);
          this.isLoading = false;

          // Update selection
          this.selected =
            this.selected?.filter((cell) => this.data[cell.id]) || [];
          this.didChangeSelection.emit(this.selected);

          // Fetch additional types if necessary
          switch (this.type) {
            case PickerType.HARDWARE_CONFIGURATION:
              this.fetchAllHwTypes(orgId);
              break;
            case PickerType.SOFTWARE_CONFIGURATION:
              this.fetchSwTypes(orgId);
              break;
            case PickerType.ROBOT_CONFIGURATION:
              this.fetchAllHwTypes(orgId);
              this.fetchSwTypes(orgId);
              break;
            default:
              break;
          }
        }),
        take(1)
      )
      .subscribe({
        next: (_res) => {
          // Do nothing
        },
        error: (err) => console.error(err),
      });
  }

  didClickAction(e) {
    const element = e.element;
    const id = element.id;
    switch (e.actionId as PickerAction) {
      case 'edit':
        this.editElement(id);
        break;
      case 'delete':
        this.deleteElement(id);
        break;
    }
  }

  didSelectSortOption(newSortOption: string) {
    this.sortingType = newSortOption as PickerSortingType;
    this.filteredData = this.filterListData(Object.values(this.data));
    this.setListData(this.filteredData);
  }

  didChangeSearch(e) {
    this.searchTerm = e.target.value;
    this.filteredData = this.filterListData(Object.values(this.data));
    this.createNoDataText();
    this.setListData(this.filteredData);
  }

  mergeInMissingValues(obj: object, reference: object) {
    for (const key of Object.keys(reference)) {
      if (typeof obj[key] == 'object' && typeof reference[key] == 'object') {
        this.mergeInMissingValues(obj[key], reference[key]);
        continue;
      }
      if (obj[key] !== undefined) {
        continue;
      }
      obj[key] = reference[key];
    }
  }

  didChangePage(event: PageEvent) {
    this.numItemsInPage = event.pageSize;
    this.page = event.pageIndex;
  }

  nameForElementWithId(id: string): string {
    const element = this.data[id];
    if (!element) return '--';
    const name = element.name;
    return name;
  }

  getOptionsMatchingType(
    type: PickerType,
    hwSwType?: string
  ): { [key: string]: string } {
    let array: IApiScene[] | IApiStrategy[];
    switch (type) {
      case PickerType.HARDWARE_CONFIGURATION:
        array = [...(this.hwTypes as IApiScene[])];
        break;
      case PickerType.SOFTWARE_CONFIGURATION:
        array = [...(this.swTypes as IApiStrategy[])];
        break;
      default:
        return {};
    }

    let filtered: IApiScene[] | IApiStrategy[] = [];
    if (!hwSwType) {
      filtered = array;
    } else {
      filtered = (array as any).filter(
        (el: any) =>
          this.optional(el.hw_sw_type, { name: '' }).name === hwSwType
      );
    }

    let dict = {};
    for (const el of filtered) dict[el.name] = el.name;

    return dict;
  }

  deleteElement(id: string) {
    const name = this.nameForElementWithId(id);
    const dialogRef = this.dialog.open(DeletePromptComponent, {
      data: {
        data: {
          type: this.pickerDescription.name.toLowerCase(),
          element: name,
          id: id,
        },
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        skipWhile((res) => !res),
        map((res) => {
          return {
            dialogResult: res[0],
            idToDelete: res[0].id,
          };
        }),
        switchMap(
          (value: {
            dialogResult: { id: string; name: string; type: string };
            idToDelete: string;
          }) =>
            combineLatest([
              this.pickerApi.removeForTypeWithId(this.type, value.idToDelete),
              of(value.dialogResult),
            ])
        )
      )
      .subscribe({
        next: ([success, dialogResult]) => {
          if (success) {
            this.notification.showSuccess(
              `The ${this.pickerDescription.name} ${dialogResult.name} is now removed!`
            );
          } else {
            this.notification.showError(
              `The ${this.pickerDescription.name} ${dialogResult.name} could not be deleted.`
            );
          }
        },
        error: (err) => {
          this.notification.showError(err);
        },
      });
  }

  optional(value: any, def: any): any {
    if (value === undefined || value === null) return def;
    else return value;
  }

  editElement(id: string) {
    const element = this.data[id];
    const name = this.nameForElementWithId(id);
    let fields = {};
    for (const field of this.pickerDescription.fields) {
      if (!this.optional(field.isEditable, true)) continue;

      let options;
      if (field.type === FieldType.SELECT_SINGLE) {
        if (field.enumKeys) options = field.enumKeys;
        else if (field.hwSwType)
          options = this.getOptionsMatchingType(this.type, field.hwSwType);
      }

      fields[field.key] = {
        title: field.name,
        value: get(element, field.key, undefined),
        type: this.optional(field.type, FieldType.TEXT),
        options: options,
      };
    }
    const dialogRef = this.dialog.open(EditComponent, {
      data: {
        title: `Edit ${this.pickerDescription.name.toLowerCase()} ${name.toLowerCase()}`,
        fields: fields,
      },
    });
    dialogRef
      .afterClosed()
      .pipe(
        skipWhile((delta) => !delta),
        tap((delta) => {
          this.mergeInMissingValues(delta, element);
        }),
        switchMap((delta) =>
          combineLatest([
            this.pickerApi.editForTypeWidthId(this.type, id, delta),
            of(delta),
          ])
        )
      )
      .subscribe({
        next: ([success, delta]) => {
          if (success) {
            this.notification.showSuccess(
              `The ${this.pickerDescription.name} ${delta.name} is updated!`
            );
          } else {
            this.notification.showError(
              `The ${this.pickerDescription.name} ${delta.name} could not be updated.`
            );
          }
        },
        error: (err) => {
          this.notification.showError(err);
        },
      });
  }

  didClickChangeDisplayMode() {
    let newDisplayMode: PickerDisplayMode;

    switch (this.displayMode) {
      case 'LIST':
        newDisplayMode = 'TABLE';
        break;
      case 'TABLE':
        newDisplayMode = 'LIST';
        break;
    }

    this.changeDisplayModeTo(newDisplayMode);
  }

  changeDisplayModeTo(newMode: PickerDisplayMode) {
    this.searchTerm = '';
    this.displayMode = newMode;
    this.filteredData = this.filterListData(Object.values(this.data));
    this.setListData(this.filteredData);
  }

  // Selection handler from table
  didSelectRow(e) {
    this.selectedTableData = e;
    this.selected = e.map((el) => this.data[el.id]);

    this.didChangeSelection.emit(this.selected);
  }

  // Selection handler from list
  selectCard(data: IPickerData) {
    if (this.selectionAmount === 'SELECT_MULTIPLE') {
      if (this.selected.includes(data)) {
        const index = this.selected.indexOf(data);
        this.selected.splice(index, 1);
      } else {
        this.selected.push(data);
      }
    } else {
      this.selected = [data];
    }

    this.selectedTableData = this.selected.map(
      (cell) => this.pickerDataToTableData(cell).data
    );

    this.didChangeSelection.emit(this.selected);
  }
}
