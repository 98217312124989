import { SelectionModel } from '@angular/cdk/collections';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, SortDirection } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterTableData } from 'src/app/models_new/types/sorting-option';

export interface InvetoryTableAction {
  label: string;
  roleAction: string;
  actionId: string;
  icon?: string; // Defaults to chevron_right
  color?: 'primary' | 'accent' | 'warn'; // Defaults to nothing = black.
  divideAfter?: boolean;
  disabled?: (row: any) => boolean; // Defaults to false
}

export interface InventoryTableCta {
  label: string;
  roleAction: string;
  actionId: string;
  icon: string;
  disabled?: (row: any) => boolean; // Defaults to false
}

export interface InventoryTableDisplayColumn {
  path: string;
  label: string;
  initSort?: 'asc' | 'desc';
  sortType?: 'string' | 'number' | 'date';
  specialCell?: {
    type: 'badge' | 'gauge' | 'link' | 'badge-n-gauge';
    color?: (row: any) => 'primary' | 'accent' | 'warn';
    link?: (row: any) => string; // Only for link type
    paths?: string[]; // Only for badge-n-gauge. Here we need two paths to get the values.
  }[];
}

@Component({
  selector: 'app-inventory-table',
  templateUrl: './inventory-table.component.html',
  styleUrls: ['./inventory-table.component.scss'],
})
export class InventoryTableComponent
  implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() displayedColumns: InventoryTableDisplayColumn[] = [];
  columnPaths: string[] = [];

  @Input() data: any[] = [];
  dataSource: MatTableDataSource<any>;

  @Input() rowActions: InvetoryTableAction[] = [];
  @Input() rowCta: InventoryTableCta[] = [];

  @Input() clickableRows = true;

  currentFilter: FilterTableData = new FilterTableData();
  @Input() set filter(filter: FilterTableData) {
    this.currentFilter = filter;
    if (this.dataSource && this.data?.length) {
      this.dataSource.filter = 'trigger_filter';
    }
  }
  @Input() set sortBy(sort: { column: string; order: SortDirection }) {
    if (sort && this.dataSource) {
      this.setSort(sort.column, sort.order);
    }
  }

  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  @Output() rowAction = new EventEmitter<{
    action: InvetoryTableAction;
    row: any;
  }>();
  @Output() rowCtaAction = new EventEmitter<{
    action: InventoryTableCta;
    row: any;
  }>();
  @Output() rowSelected = new EventEmitter<any>();

  selection = new SelectionModel<any>(true, []);
  @Output() blockSelectedChange: EventEmitter<any[]> = new EventEmitter();

  // Table gui
  isTooltipDisabled = false;
  Array = Array;

  constructor(private cdr: ChangeDetectorRef) {}

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngAfterViewInit() {
    this.manipulateData();
    this.createDataTable(this.data);
    this.initializePaginatorAndSort();
    this.initializeFilters();
    this.cdr.detectChanges();

    this.selection.changed
      .pipe(takeUntil(this.destroy$))
      .subscribe((s) =>
        this.blockSelectedChange.emit(s.source.selected as any[])
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.manipulateData();
      this.createDataTable(changes.data.currentValue);
      this.cdr.detectChanges();
    }
    if (changes.displayedColumns) {
      this.columnPaths = changes.displayedColumns.currentValue.map(
        (c) => c.path
      );
      this.columnPaths.unshift('select');
    }
    if (changes.rowCta) {
      this.columnPaths.push('cta');
    }
    if (changes.rowActions) {
      this.columnPaths.push('actions');
    }
  }

  private initializeFilters() {
    this.dataSource.filterPredicate = (data: any) => {
      return (
        this.currentFilter.keywordFilter(data) &&
        this.currentFilter.sliderFilter(data) &&
        this.currentFilter.textFilter(data)
      );
    };

    //Trigger filter at initialization
    this.dataSource.filter = 'trigger_filter';
  }

  private initializePaginatorAndSort() {
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      if (this.paginator) {
        this.paginator.pageSize = 10;
      }
      this.setSort();
      this.selection.clear();
    }
  }

  private setSort(column?: string, order?: SortDirection) {
    if (column && order && this.sort) {
      this.sort.active = column;
      this.sort.direction = order;
      this.sort.sortChange.emit();
      this.dataSource.sort = this.sort;
      return;
    }

    let activeSortColumn = this.displayedColumns.find((c) => c.initSort);

    // If none of the columns have initSort, use updated_at if available
    if (!activeSortColumn) {
      activeSortColumn = this.displayedColumns.find(
        (c) => c.path === 'updated_at'
      );
      // If available, set initSort to desc
      if (activeSortColumn) {
        activeSortColumn.initSort = 'desc';
      }
    }

    if (activeSortColumn && this.sort) {
      this.sort.active = activeSortColumn.path;
      this.sort.direction = activeSortColumn.initSort;

      // Set the custom sort function
      this.dataSource.sortingDataAccessor = (data, sortHeaderId) => {
        // Find the column configuration for the current sort header
        const sortColumn = this.displayedColumns.find(
          (c) => c.path === sortHeaderId
        );

        if (sortColumn) {
          // Parse the value based on the column's sortType
          return this.parseSortableValue(
            this.getNestedValue(data, sortColumn.path),
            sortColumn.sortType
          );
        }

        return ''; // Default return if the column is not found
      };

      // Emit sort change to ensure the UI reflects the initial sort settings
      this.sort.sortChange.emit();
    }

    this.dataSource.sort = this.sort;
  }

  private manipulateData() {
    // Do any data manipulation here
    this.rowActions.map((d: InvetoryTableAction) => {
      // Disable false by default
      d.disabled = d.disabled || (() => false);
    });

    if (this.rowCta) {
      this.rowCta.map((d: InventoryTableCta) => {
        // Disable false by default
        d.disabled = d.disabled || (() => false);
      });
    }
  }

  private createDataTable(data: any) {
    this.dataSource = new MatTableDataSource<any>(data);
    this.initializePaginatorAndSort();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = 10;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  toggleAllRows() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    // Max selection is the same as pagination size.
    // Select all rows in the current page
    const maxSelection = this.paginator.pageSize;
    const currentPage = this.paginator.pageIndex;
    const selected = this.dataSource.data.slice(
      currentPage * maxSelection,
      currentPage * maxSelection + maxSelection
    );

    this.selection.select(...selected);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${
      row.position + 1
    }`;
  }

  getNestedValue(element: any, path: string): any {
    const v = path.split('.').reduce((acc, part) => acc && acc[part], element);
    // If v is empty, return '-' to avoid errors
    return v || '-';
  }

  private parseSortableValue(
    value: string | number,
    sortType: 'string' | 'number' | 'date'
  ): number | string {
    if (sortType === 'number') {
      if (typeof value === 'string') {
        const numericValue = parseFloat(value.replace(/[^0-9.]/g, ''));
        return isNaN(numericValue) ? 0 : numericValue;
      }
      return value as number;
    } else if (sortType === 'date' && typeof value === 'string') {
      // Parse the date from 'dd/MM/yyyy HH:mm' format
      const [datePart, timePart] = value.split(' ');
      const [day, month, year] = datePart.split('/').map(Number);
      const [hours, minutes] = timePart.split(':').map(Number);

      // Create a Date object from parsed values (month is zero-based)
      const parsedDate = new Date(year, month - 1, day, hours, minutes);

      // Return the timestamp for sorting
      return parsedDate.getTime();
    } else {
      // Default to string sorting
      return value.toString().toLowerCase(); // Case-insensitive string sorting
    }
  }
}
