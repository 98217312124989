import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from '../../app.component';
import { PalletComponent } from '../../components/patterns/pattern/pallet/pallet.component';
import { PatternEditComponent } from '../../components/patterns/pattern/pattern-edit/pattern-edit.component';
import { PalletCanvasComponent } from '../../components/patterns/pattern/pallet/pallet-canvas/pallet-canvas.component';
import { UnitConversionPipe } from '../../pipes/unit-conversion.pipe';
import { SubNavbarComponent } from '../../components/sub-navbar/sub-navbar.component';
import { AutoStackDialogComponent } from '../../components/dialogs/auto-stack/auto-stack.component';
import { ToolsComponent } from '../../components/patterns/pattern/pallet/sidebar/pallet-edit/tools/tools.component';
import { PalletEditComponent } from '../../components/patterns/pattern/pallet/sidebar/pallet-edit/pallet-edit.component';
import { GoogleAnalyticsService } from '../../services/google-analytics-service.service';
import { SupportWidgetService } from '../../services/support-widget.service';
import { DialogWrapperComponent } from '../../components/dialogs/dialog-wrapper/dialog-wrapper.component';
import { environment } from 'src/environments/environment';
import { MaterialModule } from '../material.module';
import { ThreeModule } from '../three.module';
import { SharedModule } from '../shared.module';
import { EditorComponent } from 'src/app/components/simulation/model-upload/editor/editor.component';
import { FileUploadComponent } from 'src/app/components/simulation/model-upload/file-upload/file-upload.component';
import { ModelUploadComponent } from 'src/app/components/simulation/model-upload/model-upload/model-upload.component';
import { SummaryComponent } from 'src/app/components/simulation/model-upload/summary/summary.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { APP_INITIALIZER } from '@angular/core';
import { AssetStoreService } from 'src/app/services/asset-store.service';
import { DeletePromptComponent } from 'src/app/components/dialogs/delete-prompt/delete-prompt.component';
import { StateChipsComponent } from 'src/app/components/gui/toolbar/state-chips/state-chips.component';
import { ToolbarComponent } from 'src/app/components/gui/toolbar/toolbar.component';
import { Apollo } from 'apollo-angular';
import { EditComponent } from '../../components/dialogs/edit/edit.component';
import { PickerComponent } from '../../components/dialogs/picker/picker.component';
import { LinkExpiredComponent } from 'src/app/components/gui/link-expired/link-expired.component';
import { BadPatternDialogComponent } from '../../components/dialogs/bad-pattern-dialog/bad-pattern-dialog.component';
import { AuthModule } from '@auth0/auth0-angular';
import { QuoteFormDialogComponent } from 'src/app/components/dialogs/quote-form-dialog/quote-form-dialog.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NewAssetStoreService } from '../../services/3dview/asset-store.service';
import { AboutComponent } from 'src/app/components/about/about.component';
import { FastTrackModule } from 'src/app/modules/fast-track/fast-track.module';
import { LandingComponent } from 'src/app/components/landing/landing.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { settings } from '../../models_new/config/application-settings';
import { GpuDetectService } from '../../services/3dview/gpu-detect.service';
import { NetSpeedService } from '../../services/net-speed.service';
import { NotificationBannerComponent } from '../../components/notification-banner/notification-banner.component';
import { ReleaseNotesComponent } from '../../components/release-notes/release-notes.component';
import { SimulationRetryComponent } from 'src/app/components/simulation/simulation-retry/simulation-retry.component';
import { EditRobotConfigDialogComponent } from 'src/app/components/dialogs/edit-robot-config-dialog/edit-robot-config-dialog.component';
import { RobotConfigurationModule } from '../robot-config/robot-config.module';
import { SoftwareModule } from '../software/software.module';
import { SelectPatternDialogComponent } from 'src/app/components/dialogs/select-pattern-dialog/select-pattern-dialog.component';
import { PatternModule } from '../pattern/pattern.module';
import { InstalledRobotsModule } from '../installed-robots/installed-robots.module';
import { NewRobotDialogComponent } from 'src/app/components/dialogs/new-robot-dialog/new-robot-dialog.component';
import { EditPathStrategyDialogComponent } from 'src/app/components/dialogs/edit-path-strategy-dialog/edit-path-strategy-dialog.component';
import { PallyPathStrategyModule } from '../pally-path-strategy/pally-path-strategy.module';

@NgModule({
  declarations: [
    PalletCanvasComponent,
    AppComponent,
    PalletComponent,
    SimulationRetryComponent,
    PatternEditComponent,
    UnitConversionPipe,
    SubNavbarComponent,
    AutoStackDialogComponent,
    ToolsComponent,
    PalletEditComponent,
    DialogWrapperComponent,
    ModelUploadComponent,
    FileUploadComponent,
    EditorComponent,
    SummaryComponent,
    DeletePromptComponent,
    ToolbarComponent,
    StateChipsComponent,
    EditComponent,
    PickerComponent,
    LinkExpiredComponent,
    BadPatternDialogComponent,
    QuoteFormDialogComponent,
    EditRobotConfigDialogComponent,
    AboutComponent,
    LandingComponent,
    ReleaseNotesComponent,
    NotificationBannerComponent,
    SelectPatternDialogComponent,
    NewRobotDialogComponent,
    EditPathStrategyDialogComponent,
  ],
  imports: [
    HttpClientModule,
    DragDropModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    MaterialModule,
    ThreeModule,
    SharedModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    AuthModule.forRoot(environment.auth0),
    FastTrackModule,
    RobotConfigurationModule,
    SoftwareModule,
    PatternModule,
    InstalledRobotsModule,
    PallyPathStrategyModule,
  ],
  providers: [
    Apollo,
    GoogleAnalyticsService,
    UnitConversionPipe,
    SupportWidgetService,
    {
      provide: APP_INITIALIZER,
      useFactory: (_assetStore: AssetStoreService) => () => {
        return new Promise<void>((resolve, _reject) => {
          resolve();
        });
      },
      deps: [AssetStoreService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (_assetStore: NewAssetStoreService) => () => {
        return new Promise<void>((resolve, _reject) => {
          resolve();
        });
      },
      deps: [NewAssetStoreService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (_gpuDetect: GpuDetectService) => () => {
        return new Promise<void>((resolve, _reject) => {
          resolve();
        });
      },
      deps: [GpuDetectService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (_netSpeedService: NetSpeedService) => () => {
        return new Promise<void>((resolve, _reject) => {
          resolve();
        });
      },
      deps: [GpuDetectService],
      multi: true,
    },
    { provide: MAT_DATE_LOCALE, useValue: settings.defaultGlobalDateLocale },
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
