<ng-container *ngIf="notify.messages$ | async as messages">
  <div class="messages-wrapper">
    <mat-card
      appearance="outlined"
      [class]="m.class"
      [style.background]="m.bgColor"
      [style.margin]="'.3em 0'"
      *ngFor="let m of messages"
    >
      <mat-card-content>
        <div class="card-content-wrapper">
          <mat-icon class="message-icon" [style.color]="m.iconColor">{{
            m.icon
          }}</mat-icon>

          <div class="message-column">
            <span class="message-container">{{ m.message }}</span>
            <a target="_blank" [href]="m.link.url" *ngIf="m.link"
              ><br />{{ m.link.text }}</a
            >
          </div>

          <ng-container *ngIf="m.type === 'error'">
            <span class="spacer" style="flex-grow: 1"></span>
            <button mat-button (click)="notify.removeMessage$.next(m.id)">
              OK
            </button>
          </ng-container>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</ng-container>
