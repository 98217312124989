import { ISimulationApiFileType } from '../../types/simulation-api-file-type';
import { ApiRobotConfiguration } from './ApiRobotConfiguration';
import { ApiSimulation } from './ApiSimulation';

export interface IApiStrategy {
  id: string;
  name: string;
  description?: string;
  data: ISimulationApiFileType['strategy'];
  organization_id?: string;
  created_at?: string;
  updated_at?: string;
  simulations?: ApiSimulation[];
  robot_configurations?: ApiRobotConfiguration[];
}

export class ApiStrategy implements IApiStrategy {
  id: string;
  name: string;
  description?: string;
  data: ISimulationApiFileType['strategy'];
  max_speed?: number | string;
  max_acceleration?: number | string;
  max_speed_max_acceleration?: string;
  polyscope_version?: string;
  pally_version?: string;
  organization?: {
    id: string;
    name: string;
  };
  organization_id?: string;
  created_at?: string;
  updated_at?: string;
  simulations?: ApiSimulation[];
  robot_configurations?: ApiRobotConfiguration[];
  image?: {
    id: string;
    url?: string;
  };
  updatedImage?: string;

  constructor(strat: IApiStrategy) {
    for (const d in strat) {
      if (strat.hasOwnProperty(d)) {
        this[d] = strat[d];
      }
    }
  }
}
