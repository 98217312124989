/** @enum Available list robot brands for MRC. */
export enum RobotBrandType {
  DOOSAN = 'doosan_robotics',
  UR = 'universal_robots',
}

/** @interface RobotBrand */
export interface RobotBrand {
  name: string;
  value: RobotBrandType;
  logoAsset?: string;
  robotAsset?: string;
}

/**
 * @constant {RobotBrand} RoboBrands
 * @description List of available robot brands for MRC.
 * If a new robot brand is added, it should be added here and in the RobotBrandType enum.
 */
export const RobotBrands = {
  UR: {
    name: 'Universal Robots',
    value: RobotBrandType.UR,
    logoAsset: 'assets/robot-brands/universal_robots_logo.png',
    robotAsset: 'assets/robot-brands/universal_robots_ur10.png',
  },
  DOOSAN: {
    name: 'Doosan Robotics',
    value: RobotBrandType.DOOSAN,
    logoAsset: 'assets/robot-brands/doosan_robotics_logo.png',
    robotAsset: 'assets/robot-brands/doosan_robot.png',
  },
};

// Function to convert string array to RobotBrandType array dynamically
export function convertToRobotBrandTypeArray(
  strings: string[]
): RobotBrandType[] {
  const robotBrandValues = Object.values(RobotBrandType);
  return strings.map((str) => {
    if (robotBrandValues.includes(str as RobotBrandType)) {
      return str as RobotBrandType;
    } else {
      console.warn(`Unknown RobotBrandType: ${str}`);
      return null;
    }
  });
}
