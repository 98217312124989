<div
  class="landing-step-item-wrapper"
  *appVar="appLayout.lowHeight$ | async as lowHeigth"
>
  <ng-container *ngIf="currentPageContent$ | async as page">
    <div class="card-header">
      <h1
        *appVar="currentPageContent$ | async as page"
        [ngStyle]="
          lowHeigth ? { 'margin-bottom': '0.5em', 'margin-top': '0.5em' } : {}
        "
      >
        {{ page.content?.cardTitle }}
      </h1>
      <h2>{{ page.content?.cardSubtitle }}</h2>
    </div>
    <div class="card-content">
      <div
        class="card-text"
        [innerHtml]="page.content?.cardLongText.json | richtextDocumentToHtml"
      ></div>
      <div class="card-image">
        <img [src]="page.content?.cardImage?.url" />
      </div>
    </div>
    <div class="card-actions">
      <button
        mat-flat-button
        pally-button-l
        pally-gradient
        color="accent"
        class="cta-button"
        (click)="next()"
      >
        {{ page.content?.callToActionButton1 }}
      </button>
      <div class="learn-more">
        <span (click)="openInfoPopup()">
          {{ page.content?.callToActionButton2 }}</span
        >
      </div>
    </div>
  </ng-container>
</div>
