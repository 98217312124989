import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import { IUser } from 'src/app/models_new/types/user';
import { IHasuraUser } from '../../models_new/types/hasura-user';
import { ClientApiService } from './client-api.service';
import { ICountryNState } from './public-api.service';
import { UserType } from 'src/app/models_new/types/signup-user-type';
import { RobotBrandType } from 'src/app/models_new/enums/robot-brand-types';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private clientApi: ClientApiService) {}

  public getUserData(userId: string): Observable<IHasuraUser> {
    const q = gql`
    query getUser {
      user_by_pk(id: "${userId}") {
        name
        email
        metadata
        organization_members {
          type
        }
      }
    }`;

    return this.clientApi
      .useClient('user')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          return data.data?.user_by_pk;
        })
      );
  }

  public updateUserMetadata(
    metadata: IUser['https://login.myrobot.cloud/user_metadata']
  ): Observable<IHasuraUser> {
    const q = gql`
      query UpdateUserMetadata($metadata: json!) {
        UpdateUserMetadata(metadata: $metadata) {
          success
        }
      }
    `;

    const variables = {
      metadata: metadata,
    };

    return this.clientApi
      .useClient('user')
      .watchQuery<any>({
        query: q,
        variables: variables,
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }
          if (data.data?.UpdateUserMetadata.success) {
            return data.data?.UpdateUserMetadata.success;
          } else {
            throw new Error(
              'Something went wrong when updating user metadata' + data
            );
          }
        })
      );
  }

  /**
   * Toggles the user preference to get notified for new leads.
   * @param {string} userId - The uuid of the user.
   * @param {string} orgId - The uuid of the organization.
   * @param {boolean} sendLeadEmail - The new value for the get_lead_email flag.
   * @param {boolean} isAdmin - Whether the user is an admin or not.
   * @returns An Observable that emits the success of the operation or throws an error.
   */
  public toggleGetLeadEmailFlag(
    userId: string,
    orgId: string,
    sendLeadEmail: boolean,
    isAdmin: boolean
  ): Observable<any> {
    const q = gql`
      mutation ToggleGetLeadEmailFlag(
        $getLeadEmail: Boolean!
      ) {
        update_organization_member(
          where: { user_id: { _eq: "${userId}" }, organization_id: { _eq: "${orgId}" } }
          _set: { get_lead_mail: $getLeadEmail }
        ) {
          affected_rows
          __typename
        }
      }
    `;
    return this.clientApi
      .useClient(isAdmin ? 'org_admin' : 'org_edit')
      .subscribe<any>({
        query: q,
        variables: {
          getLeadEmail: sendLeadEmail,
        },
      })
      .pipe(
        map((data) => {
          if (!data || data?.errors) throw new Error(data.errors[0].message);
          else return true;
        })
      );
  }

  public createSalesOrganization(org: {
    name: string;
    metadata: {
      country: ICountryNState;
      address?: string;
      state?: ICountryNState;
      sub_type?: UserType;
    };
    robot_brands: RobotBrandType[];
  }): Observable<string> {
    const m = gql`
      mutation createSalesOrg($metadata: json, $robotBrands: jsonb) {
        insert_organization_one(
          object: {
            type: sales_organization,
            metadata: $metadata,
            name: "${org.name}",
            robot_brands: $robotBrands,
          }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('user')
      .mutate<any>({
        mutation: m,
        variables: {
          metadata: org.metadata,
          robotBrands: org.robot_brands,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else return data.data.insert_organization_one.id;
        })
      );
  }

  public getMemberships(orgId?: string): Observable<string[]> {
    const s = gql`
    subscription getMemberInOrganization {
      organization_member(where: {organization_id: {_eq: "${orgId}"}}) {
        organization_id
      }
    }`;

    const sNoOrg = gql`
      subscription getMemberInOrganization {
        organization_member {
          organization_id
        }
      }
    `;
    return this.clientApi
      .useClient('user', 'ws')
      .subscribe<any>({
        query: orgId ? s : sNoOrg,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.organization_member;
          }
        })
      );
  }
}
