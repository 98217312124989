import { Injector } from '@angular/core';
import * as THREE from 'three';
import { ReplaySubject } from 'rxjs';
import {
  JointNames,
  LinkNames,
} from 'src/app/services/project-robot-descriptor.service';
import { Type } from 'src/app/utils/type';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { milliToMeter } from '../../../../utils/div';
import { DimensionVisualizer } from '../../3dview/timed-objects/dimension-visualizer';

export class ConveyorGuideTask extends Task {
  static [taskNameSymbol] = 'Conveyor guide';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, _reject: (reason?: any) => void): void {
    const isPrimary = this.data.fieldId.includes('.0.'),
      type = this.readConveyorType(isPrimary),
      dimension = this.readConveyorDimension(isPrimary),
      isGuideSideRIGHT = this.isGuideSideRIGHT(isPrimary),
      guideSide = isGuideSideRIGHT ? 1 : -1,
      guideWidth = this.readGuideWidth(isPrimary);

    // Ignore secondary conveyor guide if dual product mode is disabled.
    if (!isPrimary && !this.readDualProductMode()) {
      resolve();
      return;
    }

    // Called as additional task (data = undefined) OR Box free height changed
    if (
      this.data?.fieldId ===
      (isPrimary
        ? SimConfigFieldIds.ConveyorPrimaryBoxFreeHeight
        : SimConfigFieldIds.ConveyorSecondaryBoxFreeHeight)
    ) {
      const conveyorBoxFreeLink = this.robot.getLinkByID(
        isPrimary
          ? LinkNames.ConveyorBoxFreeHeight
          : LinkNames.SecondaryConveyorBoxFreeHeight
      );
      const end = conveyorBoxFreeLink.getWorldPosition(new THREE.Vector3());
      const boxFreeHeight = this.readBoxFreeHeight(isPrimary);
      end.y += boxFreeHeight / 1000;

      const dimvis = this.toService.getTimedObject(
        isPrimary
          ? SimConfigFieldIds.ConveyorPrimaryBoxFreeHeight
          : SimConfigFieldIds.ConveyorSecondaryBoxFreeHeight
      ) as DimensionVisualizer;
      dimvis.setEnd(end);
      dimvis.setValue(`${boxFreeHeight} mm`);
      dimvis.activate(this.readShowVisualizers() ? -1 : undefined);
    } else {
      // Was a change in guide side or width
      this.updateConveyorGuide(
        isPrimary,
        type,
        dimension,
        guideSide,
        guideWidth
      );

      const conveyorGuideOffsetXJoint = this.robot.getJointByID(
        isPrimary
          ? JointNames.ConveyorGuideOffsetX
          : JointNames.SecondaryConveyorGuideOffsetX
      );
      const dimvis = this.toService.getTimedObject(
        isPrimary
          ? SimConfigFieldIds.ConveyorPrimaryGuideWidth
          : SimConfigFieldIds.ConveyorSecondaryGuideWidth
      ) as DimensionVisualizer;
      dimvis.setValue(this.readGuideWidth(isPrimary));
      dimvis.activate(this.readShowVisualizers() ? -1 : undefined, false);
      conveyorGuideOffsetXJoint.add(dimvis);
    }

    resolve();
  }

  public updateConveyorGuide(
    isPrimary: boolean,
    type: string,
    dimension: THREE.Vector3,
    guideSide: number,
    guideWidth: number
  ): void {
    const conveyorGuideJoint = this.robot.getJointByID(
        isPrimary ? JointNames.ConveyorGuide : JointNames.SecondaryConveyorGuide
      ),
      conveyorBoxFreeJoint = this.robot.getJointByID(
        isPrimary
          ? JointNames.ConveyorBoxFreeHeight
          : JointNames.SecondaryConveyorBoxFreeHeight
      ),
      conveyorBoxFreeLink = URDFUtils.findLinkFromJoint(conveyorBoxFreeJoint),
      conveyorGuideOffsetXJoint = this.robot.getJointByID(
        isPrimary
          ? JointNames.ConveyorGuideOffsetX
          : JointNames.SecondaryConveyorGuideOffsetX
      ),
      conveyorGuideOffsetXLink = URDFUtils.findLinkFromJoint(
        conveyorGuideOffsetXJoint
      ),
      conveyorGuideLink = URDFUtils.findLinkFromJoint(conveyorGuideJoint),
      conveyorGuideVisual = URDFUtils.findVisualFromJoint(conveyorGuideJoint);

    ThreeUtils.zeroTransform(conveyorBoxFreeJoint);

    ThreeUtils.zeroTransform(conveyorBoxFreeLink);
    conveyorBoxFreeLink.position.set(dimension.x / 2, 0, 0);

    ThreeUtils.zeroTransform(conveyorGuideOffsetXJoint);

    ThreeUtils.zeroTransform(conveyorGuideOffsetXLink);
    conveyorGuideOffsetXLink.position.set((dimension.x / 2) * guideSide, 0, 0);

    ThreeUtils.zeroTransform(conveyorGuideJoint);

    ThreeUtils.zeroTransform(conveyorGuideLink);
    // Counteracts dimension offset to the side
    conveyorGuideLink.position.set(guideWidth * (guideSide * -1), 0, 0);

    conveyorGuideVisual.position.set(0.025 * guideSide, length / 2, 0.05);
    conveyorGuideVisual.rotation.set(0, 0, 0);
    conveyorGuideVisual.scale.set(0.05, length, 0.1);

    if (type === 'CUSTOM') {
      ThreeUtils.disposeObject(conveyorGuideVisual.children);
      conveyorGuideVisual.children = [];
      const guideGeo = new THREE.BoxGeometry(1, 1, 1);
      const guideMat = new THREE.MeshLambertMaterial({ color: '#B6B6B6' });
      const guideMesh = new THREE.Mesh(guideGeo, guideMat);
      conveyorGuideVisual.add(guideMesh);
    } else {
      ThreeUtils.disposeObject(conveyorGuideVisual.children);
      conveyorGuideVisual.children = [];
    }
  }

  protected readDualProductMode(): boolean {
    return this.readField<any>(SimConfigFieldIds.DualProductMode)
      ? true
      : false;
  }

  protected readConveyorType(isPrimary: boolean): string {
    return this.readField(
      isPrimary
        ? SimConfigFieldIds.ConveyorPrimaryType
        : SimConfigFieldIds.ConveyorSecondaryType
    );
  }

  protected readConveyorDimension(
    isPrimary: boolean,
    dimension?: THREE.Vector3
  ): THREE.Vector3 {
    const tempDim: Array<any> = isPrimary
      ? this.readFields([
          SimConfigFieldIds.ConveyorPrimaryWidth,
          SimConfigFieldIds.ConveyorPrimaryLength,
          SimConfigFieldIds.ConveyorPrimaryHeight,
        ])
      : this.readFields([
          SimConfigFieldIds.ConveyorSecondaryWidth,
          SimConfigFieldIds.ConveyorSecondaryLength,
          SimConfigFieldIds.ConveyorSecondaryHeight,
        ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempDim.length; i++) {
      if (tempDim[i] === -0 || tempDim[i] === '-0') {
        tempDim[i] = 0;
      } else if (Type.isOfType(tempDim[i], 'string')) {
        tempDim[i] = +tempDim[i];
      }
    }

    const dim = new THREE.Vector3(
      milliToMeter(tempDim[0]),
      milliToMeter(tempDim[1]),
      milliToMeter(tempDim[2])
    );

    if (Type.isDefined(dimension)) {
      dimension.copy(dim);
    }

    return dim;
  }

  protected isGuideSideRIGHT(isPrimary: boolean): boolean {
    return !(this.readGuideSide(isPrimary) > 0);
  }

  protected readGuideSide(isPrimary: boolean): number {
    let side = this.readField<string>(
      isPrimary
        ? SimConfigFieldIds.ConveyorPrimaryGuideSide
        : SimConfigFieldIds.ConveyorSecondaryGuideSide
    );
    return side.toLowerCase() === 'left' ? 1 : -1;
  }

  protected readGuideWidth(isPrimary: boolean): number {
    let height = this.readField<number>(
      isPrimary
        ? SimConfigFieldIds.ConveyorPrimaryGuideWidth
        : SimConfigFieldIds.ConveyorSecondaryGuideWidth
    );
    // To mitigate "-0" which messes with later calculations
    if (height === -0) {
      height = 0;
    } else if (Type.isOfType(height, 'string')) {
      height = +height;
    }
    return milliToMeter(height) as number;
  }

  protected readBoxFreeHeight(isPrimary: boolean): number {
    let height = this.readField<number>(
      isPrimary
        ? SimConfigFieldIds.ConveyorPrimaryBoxFreeHeight
        : SimConfigFieldIds.ConveyorSecondaryBoxFreeHeight
    );
    // To mitigate "-0" which messes with later calculations
    if (height === -0) {
      height = 0;
    } else if (Type.isOfType(height, 'string')) {
      height = +height;
    }
    return milliToMeter(height) as number;
  }
}
