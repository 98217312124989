<app-new-three-view
  #view
  style="height: 100%"
  [camera]="camera.camera"
  [controls]="controls.controls"
>
  <app-directional-light
    [position]="lightPos1"
    [intensity]="0.4"
  ></app-directional-light>
  <app-directional-light
    [position]="lightPos2"
    [intensity]="0.4"
  ></app-directional-light>
  <app-directional-light
    [position]="lightPos3"
    [intensity]="0.4"
  ></app-directional-light>
  <app-directional-light
    [position]="lightPos4"
    [intensity]="0.4"
  ></app-directional-light>
  <app-ambient-light [intensity]="0.3"></app-ambient-light>

  <app-orbit-controls
    #controls
    [canvas]="view.canvas"
    [camera]="camera.camera"
    [target]="controlsTarget"
    [maxDistance]="10"
    [enablePan]="false"
    (change$)="view.render()"
  ></app-orbit-controls>
  <app-perspective-camera
    #camera
    [controls]="controls.controls"
    [position]="cameraPos"
  ></app-perspective-camera>

  <app-three-pallet
    *ngIf="rightPallet"
    [pallet]="rightPallet"
    [sticker]="sticker"
    [labelOrientations]="labelOrientations"
    [position]="rightPalletPos"
    [active]="project.getActivePalletPosition() === rightPallet.position"
    [showBoxFront]="project.palletView.palletViewSettings.showFront"
    [showPalletFront]="project.palletView.palletViewSettings.showPalletFront"
    [productTypeId]="productTypeId"
  ></app-three-pallet>
  <app-three-pallet
    *ngIf="leftPallet && project.palletView.palletViewSettings.showLeftPallet"
    [pallet]="leftPallet"
    [sticker]="sticker"
    [labelOrientations]="labelOrientations"
    [position]="leftPalletPos"
    [active]="project.getActivePalletPosition() === leftPallet.position"
    [showBoxFront]="project.palletView.palletViewSettings.showFront"
    [showPalletFront]="project.palletView.palletViewSettings.showPalletFront"
    [productTypeId]="productTypeId"
  ></app-three-pallet>
</app-new-three-view>
