import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { assetData, assetType } from '../../../services/api/asset-api.service';

@Component({
  selector: 'app-import-file-dialog',
  templateUrl: './import-file-dialog.component.html',
  styleUrls: ['./import-file-dialog.component.scss'],
})
export class ImportFileDialogComponent {
  loading = false;

  // Assets inserted.
  assets: assetData[];

  // Triggers when user presses submit.
  public submitted$ = new Subject<assetData[]>();

  constructor(
    public dialogRef: MatDialogRef<ImportFileDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      data: {
        fileCategory?: assetType;
        multiple?: boolean;
        accept?: string[];
        sizeLimit?: number;
        label?: string;
      };
    }
  ) {}

  setAssets(assets: assetData[]): void {
    this.assets = assets;
  }

  submitAssets(): void {
    this.loading = true;
    this.submitted$.next(this.assets);
  }

  close(): void {
    this.loading = false;
    this.dialogRef.close();
  }

  cancel(): void {
    this.loading = false;
    this.dialogRef.close();
  }
}
