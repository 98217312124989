import { Injector } from '@angular/core';
import * as THREE from 'three';
import { ReplaySubject } from 'rxjs';
import { FieldUpdate } from 'src/app/models_new/types/field-update';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { PartType } from 'src/app/models_new/enums/sim-config-part-type';
import { takeUntil, take } from 'rxjs/operators';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { Type } from 'src/app/utils/type';
import { milliToMeter } from '../../../../utils/div';
import { URDFJoint } from '@rocketfarm/urdf-loader';

export class GripperTask extends Task {
  static [taskNameSymbol] = 'Gripper';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, reject: (reason?: any) => void): void {
    if (!this.isCustomGripper()) {
      this.updateGripper(this.data, resolve, reject);
    } else {
      this.insertCustomGripper();

      /*
      TODO: Later! Temporarily disabled!

      const offsetBracket_gripper = this.robot.getJointByID(
        JointNames.OffsetBracketGripper
      ) as THREE.Object3D;
      const start = URDFUtils.findVisualFromJoint(
        offsetBracket_gripper
      ).position;
      const pos = this.readGripperPosition();
      if (pos.x !== 0) {
        const dimvis = this.activateDimVis(
          SimConfigFieldIds.GripperCustomPositionX,
          {
            value: pos.x,
          },
          this.readShowVisualizers() ? -1 : undefined
        );
        offsetBracket_gripper.add(dimvis);
      }

      // Z and Y are swapped
      if (pos.z !== 0) {
        const dimvis = this.activateDimVis(SimConfigFieldIds.GripperCustomPositionY, {
          value: pos.z,
          start: start,
        });
        offsetBracket_gripper.add(dimvis);
      }

      // Z and Y are swapped
      if (pos.y !== 0) {
        const dimvis = this.activateDimVis(SimConfigFieldIds.GripperCustomPositionZ, {
          value: pos.y,
          start: start,
        });
        offsetBracket_gripper.add(dimvis);
      }*/

      resolve();
    }
  }

  public updateGripper(s: FieldUpdate, resolve, _reject): void {
    const offsetBracket_gripper = this.robot.getJointByID(
      JointNames.OffsetBracketGripper
    );
    const gripperLink = offsetBracket_gripper.getObjectByName('gripper');
    const gripperVisual = URDFUtils.findVisualFromJoint(offsetBracket_gripper);

    this.partStoreService
      .getPart<THREE.Object3D>(
        HwPartUtils.getPartAssetID(PartType.GRIPPER, this.readGripperType())
      )
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1)
      )
      .subscribe((part) => {
        const partJoint = part.getObjectByName('gripper_joint') as URDFJoint;
        const partVisual = URDFUtils.findVisualFromJoint(partJoint);

        // Copy over transform values
        ThreeUtils.copyTransform(gripperVisual, partVisual);
        gripperVisual.children = [];

        // Copy over children clones
        for (const child of partVisual.children) {
          const newChild = ThreeUtils.clone(child);
          gripperVisual.add(newChild);
        }

        ThreeUtils.zeroTransform(offsetBracket_gripper, true);

        ThreeUtils.zeroTransform(gripperLink, true);

        resolve();
      });
  }

  public insertCustomGripper(): void {
    const offsetBracket_gripper = this.robot.getJointByID(
      JointNames.OffsetBracketGripper
    );
    const gripperLink = URDFUtils.findLinkFromJoint(offsetBracket_gripper);
    const visual = URDFUtils.findVisualFromJoint(offsetBracket_gripper);

    const dim = this.readGripperDimensions();
    const pos = this.readGripperPosition();
    const rot = this.readGripperRotation();

    ThreeUtils.disposeObject(visual.children);
    visual.children = []; // Remove any previous model

    const newModel = new THREE.Mesh(
      new THREE.BoxGeometry(1, 1, 1),
      new THREE.MeshLambertMaterial({ color: '#A5A5A5' })
    );
    visual.add(newModel);

    offsetBracket_gripper.position.set(0, 0, 0);
    offsetBracket_gripper.rotation.copy(rot);

    gripperLink.position.copy(pos);
    gripperLink.rotation.set(0, 0, 0);

    visual.position.set(0, 0, dim.z / 2);
    visual.rotation.set(0, 0, 0);
    visual.scale.copy(dim);
  }

  protected isCustomGripper(): boolean {
    return this.readGripperType() === 'CUSTOM';
  }

  protected readGripperType(): string {
    return this.readField<any>(SimConfigFieldIds.GripperType).name;
  }

  protected readGripperDimensions(dimensions?: THREE.Vector3): THREE.Vector3 {
    const tempDim: Array<any> = this.readFields([
      SimConfigFieldIds.GripperCustomWidth,
      SimConfigFieldIds.GripperCustomLength,
      SimConfigFieldIds.GripperCustomHeight,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempDim.length; i++) {
      if (tempDim[i] === -0 || tempDim[i] === '-0') {
        tempDim[i] = 0;
      } else if (Type.isOfType(tempDim[i], 'string')) {
        tempDim[i] = +tempDim[i];
      }
    }

    const dim = new THREE.Vector3(
      milliToMeter(tempDim[0]),
      milliToMeter(tempDim[1]),
      milliToMeter(tempDim[2])
    );

    if (Type.isDefined(dimensions)) {
      dimensions.copy(dim);
    }

    return dim;
  }

  protected readGripperPosition(position?: THREE.Vector3): THREE.Vector3 {
    const tempPos: Array<any> = this.readFields([
      SimConfigFieldIds.GripperCustomPositionX,
      SimConfigFieldIds.GripperCustomPositionY,
      SimConfigFieldIds.GripperCustomPositionZ,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempPos.length; i++) {
      if (tempPos[i] === -0 || tempPos[i] === '-0') {
        tempPos[i] = 0;
      } else if (Type.isOfType(tempPos[i], 'string')) {
        tempPos[i] = +tempPos[i];
      }
    }

    const pos = new THREE.Vector3(
      milliToMeter(tempPos[0]),
      milliToMeter(tempPos[1]),
      milliToMeter(tempPos[2])
    );

    if (Type.isDefined(position)) {
      position.copy(pos);
    }

    return pos;
  }

  protected readGripperRotation(rotation?: THREE.Euler): THREE.Euler {
    const tempRot: Array<any> = this.readFields([
      SimConfigFieldIds.GripperCustomRotationP,
      SimConfigFieldIds.GripperCustomRotationR,
      SimConfigFieldIds.GripperCustomRotationY,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempRot.length; i++) {
      if (tempRot[i] === -0 || tempRot[i] === '-0') {
        tempRot[i] = 0;
      } else if (Type.isOfType(tempRot[i], 'string')) {
        tempRot[i] = +tempRot[i];
      }
    }

    const rot = new THREE.Euler(tempRot[0], tempRot[1], tempRot[2]);

    if (Type.isDefined(rotation)) {
      rotation.copy(rot);
    }

    return rot;
  }

  protected readBracketOffset(offset?: THREE.Vector3): THREE.Vector3 {
    const bracketOffset: Array<any> = this.readFields([
      SimConfigFieldIds.OffsetBracketOffsetX,
      SimConfigFieldIds.OffsetBracketOffsetY,
      SimConfigFieldIds.OffsetBracketOffsetZ,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < bracketOffset.length; i++) {
      if (bracketOffset[i] === -0 || bracketOffset[i] === '-0') {
        bracketOffset[i] = 0;
      } else if (Type.isOfType(bracketOffset[i], 'string')) {
        bracketOffset[i] = +bracketOffset[i];
      }
    }

    const tempOffset = new THREE.Vector3(
      milliToMeter(bracketOffset[0]),
      milliToMeter(bracketOffset[1]),
      milliToMeter(bracketOffset[2])
    );

    if (Type.isDefined(offset)) {
      offset.copy(tempOffset);
    }

    return tempOffset;
  }
}
