import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import {
  Box as packingBox,
  AxisDirection,
} from '@rocketfarm/packing-webcomponents';
import {
  BoxSorting,
  Pattern,
} from '../components/patterns/pattern/pattern-edit/boxsorting';
import { IEditing } from '../models_new/types/editing-pattern';
import { takeWhile } from 'rxjs/operators';
import { EditorBox } from '../models_new/classes/editor-box';
import { AutoStackDialogComponent } from '../components/dialogs/auto-stack/auto-stack.component';
import { ProjectData } from '../models_new/classes/project-data';
import { Layer } from '../models_new/classes/layer';
import { Box } from '../models_new/classes/box';
import { DataService } from './data.service';
import { Pallet } from '../models_new/classes/pallet';
import { MagicStackService } from './magic-stack.service';
import { DialogService } from './dialog.service';
import { DialogSize } from '../models_new/enums/dialogSize';
import { ObjectUtils } from '../utils/object';
import { GripperOrientation } from '../models_new/enums/gripper-orientation';
import { LabelOrientation } from '../models_new/enums/label-orientation';
import { LayerType } from '../models_new/enums/layer-type';
import { BoxRotation } from '../models_new/enums/box-rotation';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceService {
  sorter: BoxSorting;
  randomLigthColors: string[] = [];

  public layerEditing$ = new BehaviorSubject<IEditing>(null);
  public layerEditingComplete$ = new BehaviorSubject<boolean>(false);

  constructor(
    private dataService: DataService,
    private magicStackService: MagicStackService,
    private dialogService: DialogService
  ) {
    // Make array of random light layer colors for gui
    let i = 0;
    const getRandomColor = () => {
      const letters = 'BCDEF'.split('');
      let color = '#';
      for (let it = 0; it < 6; it++) {
        color += letters[Math.floor(Math.random() * letters.length)];
      }
      return color;
    };
    while (i < 100) {
      const randomColor = getRandomColor();
      if (!this.randomLigthColors.includes(randomColor)) {
        this.randomLigthColors.push(randomColor);
        i++;
      }
    }
  }

  /**
   * @param layerEditing: IEditing
   * @param pallet: Pallet
   */
  startLayerSavedTracking(layerEditing: IEditing, pallet: Pallet) {
    this.layerEditingComplete$.next(false);
    this.layerEditing$.next(layerEditing);

    this.layerEditingComplete$
      .pipe(takeWhile((val) => !val, true))
      .subscribe((c) => {
        if (c) {
          const newLayersInEdit = this.layerEditing$.getValue();

          // If not saved, remove new layer
          if (!newLayersInEdit.saved) {
            pallet.removeLayerById(newLayersInEdit.newLayer.id);
          }

          // If saved, remove old layer
          if (newLayersInEdit.saved) {
            pallet.removeLayerById(newLayersInEdit.oldLayer.id);
          }
        }
      });
  }

  /**
   * @param editorBoxes: EditorBox[]
   */
  editorBoxesToBoxes(editorBoxes: EditorBox[], boxHeight: number): Box[] {
    const projectData = this.dataService.getProjectData();

    const saveBoxes = editorBoxes.map((box) => {
      box.setLocation(box.getXCenter(), box.getYCenter());
      return box;
    });

    const boxes = saveBoxes.map((box) => {
      const newBox = new Box();
      newBox.setDimensions({
        height: boxHeight,
        width: box.getRectangleWidth(),
        length: box.getRectangleLength(),
      });

      newBox.setPosition({
        x: box.getXMin() - projectData.pallet.overhang.sides,
        y: box.getYMin() - projectData.pallet.overhang.ends,
        z: 0,
      });

      newBox.setRotation(box.getRotations());

      newBox.setEnforcedOrientations(box.gripperOrientations || []);

      newBox.setStopMultigrip(box.stopMultigripFlag);

      return newBox;
    });

    return boxes;
  }

  /**
   * @param projectData: ProjectData
   * @param boxes: Box[]
   * @param leftPallet: boolean
   */
  automaticSortBoxes(
    projectData: ProjectData,
    boxes: Box[],
    leftPallet = false,
    isIndexCustom = false
  ): Box[] {
    const clone = ObjectUtils.cloneObject(boxes);
    if (isIndexCustom) return boxes;
    const boxesToBoxSortingPattern = clone.map((b: Box) => {
      return new Pattern(
        b.gripper.stopMultigrip,
        b.getEnforcedOrientations(),
        b.dimensions.width,
        b.dimensions.length,
        b.position.x,
        b.position.y,
        null,
        b.rotation
      );
    });

    this.sorter = new BoxSorting(
      projectData.box.dimensions.length,
      projectData.box.dimensions.width,
      leftPallet
    );

    const sortedLayerType = this.sorter
      .sortLayerType(boxesToBoxSortingPattern)
      .map((b: Box) => {
        b.dimensions.height = projectData.box.dimensions.height;
        return b;
      });

    return sortedLayerType.length === boxes.length ? sortedLayerType : boxes;
  }

  /**
   * @param projectData: ProjectData
   * @param boxes: Box[]
   * @param leftPallet: boolean
   */
  hasCollision(
    projectData: ProjectData,
    boxes: Box[],
    leftPallet = false
  ): boolean {
    const clone = ObjectUtils.cloneObject(boxes);

    const boxesToBoxSortingPattern = clone.map((b: Box) => {
      return new Pattern(
        b.gripper.stopMultigrip,
        b.getEnforcedOrientations(),
        b.dimensions.width,
        b.dimensions.length,
        b.position.x,
        b.position.y,
        null,
        b.rotation
      );
    });

    this.sorter = new BoxSorting(
      projectData.box.dimensions.length,
      projectData.box.dimensions.width,
      leftPallet
    );
    const sortedLayerType = this.sorter.sortLayerType(boxesToBoxSortingPattern);

    return sortedLayerType.length !== boxes.length;
  }

  /**
   * @param boxTypeId: string
   */
  getLayerColor(layerType: LayerType, typeId: string): string {
    if (layerType === LayerType.SHIMPAPER) {
      return 'white';
    }

    const typeInLayer = Number(typeId.split('-')[1]);

    if (typeInLayer < 10) {
      const colors = {
        0: '#505E7B',
        1: '#7584A5',
        2: '#D4D9E3',
        3: '#C5C5C6',
        4: '#5E7B50',
        5: '#84A575',
        6: '#D9E3D4',
        7: '#7B6D50',
        8: '#A59575',
        9: '#E3DED4',
      };
      return colors[typeInLayer];
    } else {
      return this.randomLigthColors[typeInLayer];
    }
  }

  /**
   * @param boxTypeId: number
   */
  getFontColor(layerType: LayerType, typeId: string): string {
    const typeInLayer = Number(typeId.split('-')[1]);

    const colors = {
      0: 'white',
      1: 'white',
      2: 'black',
      3: 'black',
      4: 'white',
      5: 'white',
      6: 'black',
      7: 'white',
      8: 'white',
      9: 'black',
    };

    return layerType === LayerType.LAYER ? colors[typeInLayer] : 'black';
  }

  /**
   * @param newLayer: Layer
   */
  openPatternAutostack(newLayer: Layer): Observable<Layer> {
    const projectData: ProjectData = this.dataService.getProjectData();
    const options = this.magicStackService.getConfig(projectData);

    const dialogRef = this.dialogService.showStandardDialog({
      content: {
        title: 'Choose a pattern',
        component: AutoStackDialogComponent,
        data: { layer: newLayer, options: options },
      },
      button: { clickFn: true, text: 'Select pattern' },
      gui: { size: DialogSize.MEDIUM },
    });

    return dialogRef.afterClosed();
  }

  /**
   * @param palletDimensions: ProjectData['pallet']['dimensions']
   * @param boxes: Box[]
   */
  swapWertOnPallet(
    palletDimensions: ProjectData['pallet']['dimensions'],
    boxes: Box[]
  ): void {
    const palletLength = palletDimensions.length;

    boxes.forEach((box, _key) => {
      box.setY(palletLength - box.position.y);
      // Mirror boxes
      if (this.swapVertValidator(box)) {
        this.swapBoxDirection(box);
      }
    }, this);
  }

  /**
   * @param box: Box
   */
  swapVertValidator(box: Box): boolean {
    let valid = false;

    if (
      box.labelOrientations.includes(LabelOrientation.FRONT) ||
      box.labelOrientations.includes(LabelOrientation.BACK)
    ) {
      valid = true;
    }
    if (
      box.labelOrientations[0] === null &&
      box.labelOrientations[1] === null &&
      (box.rotation.includes(BoxRotation.ZERO) ||
        box.rotation.includes(BoxRotation.TWO))
    ) {
      valid = true;
    }

    return valid;
  }

  /**
   * @param box: Box
   */
  swapBoxDirection(box: Box): void {
    if (box.rotation.length === 1) {
      box.rotation[0] = (box.rotation[0] + 180) % 360;
    }
  }

  /**
   * @param palletDimensions: ProjectData['pallet']['dimensions']
   * @param boxes: Box[]
   */
  swapHorizOnPallet(
    palletDimensions: ProjectData['pallet']['dimensions'],
    boxes: Box[]
  ): void {
    const palletWidth = palletDimensions.width;

    boxes.forEach((box, _key) => {
      box.setX(palletWidth - box.position.x);
      // Mirrors boxes
      if (this.swapHorizValidator(box)) {
        this.swapBoxDirection(box);
      }
    }, this);
  }

  /**
   * @param box: Box
   */
  swapHorizValidator(box: Box): boolean {
    let valid = false;

    if (
      box.labelOrientations.includes(LabelOrientation.LEFT) ||
      box.labelOrientations.includes(LabelOrientation.RIGHT)
    ) {
      valid = true;
    }
    if (
      box.labelOrientations[0] === null &&
      box.labelOrientations[1] === null &&
      (box.rotation.includes(BoxRotation.ONE) ||
        box.rotation.includes(BoxRotation.THREE))
    ) {
      valid = true;
    }

    return valid;
  }

  /**
   * @param box: packingBox
   */
  moveOrigoToCorner(box: packingBox): void {
    box.setLocation(
      box.getXMin() - box.getWidth() / 2,
      box.getYMin() - box.getLength() / 2
    );
  }

  /**
   * @param boxes: Box[] | packingBox[]
   * @param projectData: ProjectData
   * @param existingBoxes: EditorBox[]
   */
  boxesToEditBoxes(
    boxes: Box[] | packingBox[],
    projectData: ProjectData,
    existingBoxes?: EditorBox[]
  ): EditorBox[] {
    const label = projectData.getBoxData().label.orientation;
    const boxOverflowX = projectData.getPalletData().overhang.sides;
    const boxOverflowY = projectData.getPalletData().overhang.ends;

    if (boxes.length > 0 && boxes[0] instanceof packingBox) {
      const tempboxes = boxes as packingBox[];
      return tempboxes.map((box) => {
        let directions: AxisDirection[] = [];
        let enforcedGripperOrientations: GripperOrientation[] = [];
        let stopMultigrip = false;
        if (existingBoxes && existingBoxes.length > 0) {
          const existing = existingBoxes.find(
            (existingBox) => existingBox.getId() === box.getId()
          );
          if (existing.getDirection() !== box.getDirection()) {
            existing.getBoxFrontDirections().forEach((dir) => {
              directions.push((dir + 4 - 1) % 4);
            });
          } else {
            directions = existing.getBoxFrontDirections();
          }

          if (
            existing.gripperOrientations &&
            existing.gripperOrientations.length
          ) {
            enforcedGripperOrientations = existing.gripperOrientations;
          } else {
            enforcedGripperOrientations = [];
          }

          stopMultigrip = existing.stopMultigripFlag;
        }
        return new EditorBox(
          box,
          directions,
          this.mapDirection(label),
          box.getId(),
          enforcedGripperOrientations,
          stopMultigrip
        );
      });
    } else {
      const tempboxes = boxes as Box[];
      return tempboxes.map((box, index) => {
        const tempbox = new packingBox(
          index,
          projectData.getBoxData().dimensions.width,
          projectData.getBoxData().dimensions.length,
          box.position.x + boxOverflowX,
          box.position.y + boxOverflowY,
          this.mapDirections(box.rotation).shift()
        );
        this.moveOrigoToCorner(tempbox);

        return new EditorBox(
          tempbox,
          this.mapDirections(box.rotation),
          this.mapDirection(label),
          index,
          box.getEnforcedOrientations(),
          box.gripper.stopMultigrip
        );
      });
    }
  }

  /**
   * @param rotation: number
   */
  mapDirection(rotation: number): number {
    let returnVal = null;
    if (rotation !== undefined && rotation != null) {
      returnVal = ((360 + rotation) % 360) / 90;
    }
    return returnVal;
  }

  /**
   * @param rotations: number[]
   */
  mapDirections(rotations: number[]): AxisDirection[] {
    const retVal: AxisDirection[] = [];
    rotations.forEach((rotation) => retVal.push(((360 + rotation) % 360) / 90));
    return retVal;
  }

  /**
   * @param boxes: EditorBox[]
   */
  getNextId(boxes: EditorBox[]): number {
    // Sort by id
    boxes.sort((a, b) => a.getId() - b.getId());
    // Get the id of the last box in the array, and increment id
    return boxes.length > 0 ? boxes[boxes.length - 1].getId() + 1 : 0;
  }

  /**
   * @param id: number
   * @param projectData: ProjectData
   */
  addBox(id: number, projectData: ProjectData): EditorBox {
    const length = projectData.getBoxData().dimensions.length;
    const width = projectData.getBoxData().dimensions.width;
    const x = width / 2;
    const y = projectData.getBoxData().dimensions.length - length / 2;
    const labels = projectData.getBoxData().label.orientation;
    const tempBox = new packingBox(
      id,
      width,
      length,
      x,
      y,
      AxisDirection.Y_NEGATIVE
    );
    this.moveOrigoToCorner(tempBox);
    return new EditorBox(
      tempBox,
      [AxisDirection.Y_NEGATIVE, AxisDirection.Y_POSITIVE],
      this.mapDirection(labels),
      id
    );
  }
}
