import { Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from '../../../enums/simconfig-field-ids';
import { Type } from '../../../../utils/type';
import { JointNames } from '../../../../services/project-robot-descriptor.service';
import { DimensionVisualizer } from '../../3dview/timed-objects/dimension-visualizer';

export class DimensionsTask extends Task {
  // Avoids reporting of this task.
  static [taskNameSymbol] = '';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, _reject: (reason?: any) => void): void {
    for (let dimvis of this.toService.getAllTimedObjects() as DimensionVisualizer[]) {
      // Base bracket visualizers
      const isBaseBracketVisualizer =
        dimvis.ID === SimConfigFieldIds.BasebracketHeight;
      // Offset bracket visualizers
      const isOffsetBracketVisualizer =
        dimvis.ID === SimConfigFieldIds.OffsetBracketOffsetX ||
        dimvis.ID === SimConfigFieldIds.OffsetBracketOffsetY ||
        dimvis.ID === SimConfigFieldIds.OffsetBracketOffsetZ;
      // Custom gripper visualizers
      const isGripperVisualizer =
        dimvis.ID === SimConfigFieldIds.GripperCustomLength ||
        dimvis.ID === SimConfigFieldIds.GripperCustomWidth ||
        dimvis.ID === SimConfigFieldIds.GripperCustomHeight ||
        dimvis.ID === SimConfigFieldIds.GripperCustomPositionX ||
        dimvis.ID === SimConfigFieldIds.GripperCustomPositionY ||
        dimvis.ID === SimConfigFieldIds.GripperCustomPositionZ;

      if (
        // Should show visualizers, and...
        this.readShowVisualizers() &&
        // only show basebracket visualizers if the robot has one, and...
        ((!this.hasBaseBracket() && !isBaseBracketVisualizer) ||
          this.hasBaseBracket()) &&
        // only show offsetbracket visualizers if the robot has one, and...
        ((!this.hasOffsetBracket() && !isOffsetBracketVisualizer) ||
          this.hasOffsetBracket()) &&
        // ignore disabled visualizers that doesn't work properly yet.
        !isGripperVisualizer &&
        dimvis.ID !== SimConfigFieldIds.ConveyorPrimaryBoxFreeHeight &&
        dimvis.ID !== SimConfigFieldIds.OffsetBracketOffsetX &&
        dimvis.ID !== SimConfigFieldIds.OffsetBracketOffsetZ
      ) {
        // Add to parent if specified.
        if (dimvis.config.notGlobal && dimvis.config.parent) {
          const parent = this.resolveParent(dimvis.config.parent);
          parent.add(dimvis);
        }
        dimvis.activate(-1, !dimvis.config.notGlobal);
      } else {
        dimvis.remove();
      }
    }
    resolve();
  }

  protected resolveParent(parent: string): THREE.Object3D {
    return Object.values(JointNames)
      .map((name: JointNames) => name as string)
      .includes(parent)
      ? this.robot.getJointByID(parent)
      : this.robot.getLinkByID(parent);
  }

  protected hasCustomGripper(): boolean {
    return this.readField<any>(SimConfigFieldIds.GripperType).name === 'CUSTOM';
  }

  protected hasBaseBracket(): boolean {
    let dim: any = this.readField(SimConfigFieldIds.BasebracketHeight);

    // To mitigate "-0" which messes with later calculations
    if (dim === -0 || dim === '-0') {
      dim = 0;
    } else if (Type.isOfType(dim, 'string')) {
      dim = +dim;
    }

    return dim !== 0;
  }

  protected hasOffsetBracket(): boolean {
    const dim: Array<any> = this.readFields([
      SimConfigFieldIds.OffsetBracketWidth,
      SimConfigFieldIds.OffsetBracketLength,
      SimConfigFieldIds.OffsetBracketHeight,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < dim.length; i++) {
      if (dim[i] === -0 || dim[i] === '-0') {
        dim[i] = 0;
      } else if (Type.isOfType(dim[i], 'string')) {
        dim[i] = +dim[i];
      }
    }

    return dim[0] !== 0 && dim[1] !== 0 && dim[2] !== 0;
  }
}
