import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { catchError, map, Observable, of, Subject, take } from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { OrganizationType } from 'src/app/models_new/enums/organization-type';
import { OrganizationApiService } from 'src/app/services/api/organization-api.service';
import { DialogService } from 'src/app/services/dialog.service';
import { OrganizationLogoService } from 'src/app/services/organization-logo.service';
import { FileUtils } from 'src/app/utils/file-utils';
import { EditCustomerDialogComponent } from '../../customers/edit-customer-dialog/edit-customer-dialog.component';
import { isUUIDv4 } from 'src/app/utils/div';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
})
export class CompanyLogoComponent implements OnChanges, OnDestroy {
  public logo$: Observable<DataRequestState<string>>;
  public fallbackAsset: string = '/assets/dummy/dummy-logo.jpg';
  isHovering: boolean;
  hasSubscribed: boolean = false;

  destroy$: Subject<boolean> = new Subject<boolean>();

  /** @Input orgId => when used to fetch particular organization's assets. */
  @Input() orgId?: string;
  /** @Input activeOrganizationType => when used to just display stored assets. */
  @Input() activeOrganizationType?: OrganizationType | 'active_organization';
  /** @Input isOpen => Lets know if is public session. */
  @Input() isOpen?: boolean = false;
  /** @Input assetUrl => gives an asset to display straight away. */
  @Input() assetUrl?: string;
  /** @Input canChooseNewLogo => If true shows a button to change the logo on hover */
  @Input() canChooseNewLogo: boolean = false;
  @Input() isBackoffice?: boolean = false; // For backoffice, we just want to return the change logo event.
  @Output() logoChange: EventEmitter<string> = new EventEmitter();
  @Output() ready$: EventEmitter<void> = new EventEmitter<void>();

  constructor(
    private organizationApi: OrganizationApiService,
    private organizationLogoService: OrganizationLogoService,
    private dialogService: DialogService
  ) {}

  ngOnChanges(_: SimpleChanges): void {
    if (this.assetUrl) {
      this.logo$ = FileUtils.urlToBase64(this.assetUrl).pipe(
        map((base64) =>
          FileUtils.isBase64Valid(base64).isValid
            ? this.assetUrl
            : this.fallbackAsset
        ),
        toRequestState()
      );
    } else if (this.isOpen) {
      this.logo$ = of('../../../../assets/dummy/pally_logo_blue.png').pipe(
        toRequestState()
      );
    } else {
      if (this.activeOrganizationType) {
        this.logo$ = this.getGivenActiveOrganizationObservable(
          this.activeOrganizationType
        ).pipe(toRequestState());
      } else if (
        this.orgId &&
        this.orgId !== '?' &&
        this.orgId !== 'n/a' &&
        isUUIDv4(this.orgId)
      ) {
        //Some default hardwares use '?' or 'n/a' as organization id
        this.logo$ = this.organizationApi.getOrganizationLogo(this.orgId).pipe(
          catchError((_) => of(null)),
          toRequestState()
        );
      } else {
        this.logo$ = of(this.fallbackAsset).pipe(toRequestState());
      }
    }

    // Since this happens inside ngOnChanges, we need to make sure we don't subscribe more than once.
    if (this.hasSubscribed) {
      return;
    }
    this.logo$.pipe(takeUntil(this.destroy$)).subscribe((logo) => {
      if (logo.didSucceed && logo.value) {
        this.ready$.emit();
      }
    });

    this.hasSubscribed = true;
  }

  private getGivenActiveOrganizationObservable(
    type?: OrganizationType | 'active_organization'
  ): Observable<string> {
    switch (type) {
      case OrganizationType.CUSTOMER_ORGANIZATION:
        return this.organizationLogoService.activeCustomerLogo$;
      case OrganizationType.SALES_ORGANIZATION:
        return this.organizationLogoService.activeSalesLogo$;
      case 'active_organization':
        return this.organizationLogoService.activeOrganizationLogo$;
      default:
        return of(null);
    }
  }

  didClickChangeLogo() {
    if (!this.orgId) {
      return;
    }

    const data = {
      orgId: this.orgId,
      isChangingLogo: true,
      successHandler: this.didChangeLogo,
      isBackoffice: this.isBackoffice,
    };

    this.dialogService
      .showCustomDialog(
        EditCustomerDialogComponent,
        DialogSize.SMALL,
        null,
        data,
        true
      )
      .afterClosed()
      .pipe(take(1))
      .subscribe({
        next: (base64: string) => {
          if (base64 && base64.length) {
            this.logoChange.emit(base64);
          }
        },
      });
  }

  didChangeLogo() {
    window.location.reload();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
