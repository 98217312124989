import {
  ElementRef,
  Input,
  Component,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ModelUploadComponent } from '../model-upload/model-upload.component';
import { transformValueNames } from '../../../../models_new/enums/transform-vector-type';
import { Entries } from '../../../../models_new/types/entries';
import { Entry } from '../../../../models_new/classes/entry';
import { LightButtonToggleComponent } from '../../../gui/light-button-toggle/light-button-toggle.component';

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class EditorComponent implements OnInit {
  @Input() modelUpload: ModelUploadComponent;

  @ViewChild('toggleGrid') toggleGrid: LightButtonToggleComponent;
  @ViewChild('toggleBounding') toggleBounding: LightButtonToggleComponent;
  @ViewChild('toggleRad') toggleRad: LightButtonToggleComponent;

  canvasContainer: HTMLElement;
  shouldUseRad: boolean;
  showBoundingBox: boolean;
  showGrid: boolean;
  transformValueNames: Array<Array<string>>;
  rotationStep = 1;

  entries: Entries;

  transform() {
    return this.modelUpload.generatorParams.transforms;
  }

  camera() {
    return this.modelUpload.camera;
  }

  constructor(private el: ElementRef) {
    this.transformValueNames = transformValueNames;
  }

  ngOnInit(): void {
    this.configure();
    this.updateRotationStep();
  }

  configure(): void {
    this.entries = [];
    /* eslint-disable */
    for (const j in this.transform()) {
      if (this.transform().hasOwnProperty(j)) {
        const e: Entry[][] = [];
        for (const i in this.transform()[j].vectors) {
          if (this.transform()[j].vectors.hasOwnProperty(i)) {
            e.push(this.transform()[j].vectors[i].vector.entries());
          }
        }
        this.entries.push(e);
      }
    }
    /* eslint-enable */

    const element = this.el.nativeElement;

    // Configure switches
    this.shouldUseRad = this.modelUpload.shouldUseRad;
    this.showBoundingBox = this.modelUpload.showBoundingBox;
    this.showGrid = this.modelUpload.showGrid;

    this.canvasContainer = element.querySelector('#container');
    this.onResize();

    this.canvasContainer.appendChild(this.modelUpload.renderer.domElement);
  }

  onResize() {
    const width = this.canvasContainer.offsetWidth;
    const height = this.canvasContainer.offsetHeight;

    this.modelUpload.renderer.setSize(2 * width, 2 * height);
    this.camera().aspect = width / height;
    this.camera().updateProjectionMatrix();

    const canvas = this.modelUpload.renderer.domElement;
    canvas.style.width = width + 'px';
    canvas.style.height = height + 'px';
  }

  inputChange(index, key, event, pointIndex = 0) {
    let value = parseFloat(event.target.value);
    // Convert to rad if mode is degrees and input is rotation(1).
    if (!this.shouldUseRad && index === 1) {
      value = (Math.PI / 180) * value;
    }
    this.transform()[pointIndex].vectors[index].vector[key] = value;
    this.modelUpload.updateTransformOnModel(pointIndex);
  }

  convertToDeg(rad: number) {
    return (180 / Math.PI) * rad;
  }

  updateRotationInputs() {
    const rotationInputs = this.el.nativeElement.querySelectorAll('.Rotation');
    rotationInputs.forEach(($0) => {
      if (this.shouldUseRad) {
        // convert to Radians
        const deg = parseFloat($0.value);
        $0.value = (Math.PI / 180) * deg;
      } else {
        // convert to Degrees
        const rad = parseFloat($0.value);
        $0.value = (180 / Math.PI) * rad;
      }
    });
  }

  updateRotationStep() {
    if (this.shouldUseRad) this.rotationStep = Math.PI / 12;
    else this.rotationStep = 1;
  }

  updateShouldUseRad() {
    this.shouldUseRad = !this.shouldUseRad;
    this.modelUpload.shouldUseRad = this.shouldUseRad;
    this.toggleRad.model = this.shouldUseRad;
    this.updateRotationStep();
    this.updateRotationInputs();
  }

  updateShowBoundingBox() {
    this.showBoundingBox = !this.showBoundingBox;
    this.modelUpload.showBoundingBox = this.showBoundingBox;
    this.toggleBounding.model = this.showBoundingBox;
    this.modelUpload.updateBoundingBoxMesh();
  }

  updateShowGrid() {
    this.showGrid = !this.showGrid;
    this.modelUpload.showGrid = this.showGrid;
    this.toggleGrid.model = this.showGrid;
    this.modelUpload.updateGrid();
  }
}
