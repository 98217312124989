import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from '@angular/core';

export interface ISidebarContent {
  id: number;
  header: string;
  expansion?: string[];
}

@Component({
  selector: 'app-progress-sidebar',
  templateUrl: './progress-sidebar.component.html',
  styleUrls: ['./progress-sidebar.component.scss'],
})
export class ProgressSidebarComponent implements OnChanges {
  @Input() inputContent: ISidebarContent[];
  @Input() currentPage: number;
  @Input() disableStepSelection: boolean = false;
  @Output() selectedStep: EventEmitter<ISidebarContent> = new EventEmitter();

  currentExpanded: number;

  circleColor(element: ISidebarContent) {
    return element.id === this.currentPage
      ? 'rgba(57, 86, 146, 1)'
      : 'rgba(247, 247, 251, 1)';
  }

  ngOnChanges() {
    this.currentExpanded = this.currentPage;
  }

  setExpanded(id: number) {
    if (id === this.currentExpanded) {
      this.currentExpanded = -1;
    } else {
      this.currentExpanded = id;
    }
  }

  textColor(element: ISidebarContent) {
    return element.id === this.currentPage ? 'white' : 'rgba(57, 86, 146, 0.2)';
  }
}
