import { IApiProduct } from 'src/app/models_new/classes/api-models/ApiProduct';
import { ApiUser } from 'src/app/models_new/classes/api-models/ApiUser';
import { defaultApiUser } from './default-api-user';

export const defaultApiProduct: IApiProduct = {
  id: '',
  owner_id: new ApiUser(defaultApiUser).id,
  organization_id: '001',
  name: '',
  data: {
    height: 190,
    length: 290,
    width: 200,
    weight: 1000,
    label_orientation: 0,
    model_type: 'box',
  },
  created_at: new Date().toISOString(),
  updated_at: new Date().toISOString(),
  product_production_lines: [],
  patterns: [],
  product_palletizing_projects: [],
};
