import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, of, shareReplay, switchMap } from 'rxjs';
import { StateService } from '../../../auth/state.service';
import { DataRequestState } from '../../../data-request/model';
import { toRequestState } from '../../../data-request/operators';
import { ApiOrganization } from '../../../models_new/classes/api-models/ApiOrganization';
import { ILicenseLinkInfo } from '../../../models_new/classes/api-models/ApiSoftwareLicense';
import { LicenseApiService } from 'src/app/services/api/license-api.service';

@Component({
  selector: 'app-ir-license',
  templateUrl: './ir-license.component.html',
  styleUrls: ['./ir-license.component.scss'],
})
export class IrLicenseComponent {
  org_id$: Observable<string>;
  licenses$: Observable<DataRequestState<ILicenseLinkInfo[]>>;

  linkedRobots$: Observable<ILicenseLinkInfo[]>;

  constructor(
    public dialogRef: MatDialogRef<IrLicenseComponent>,
    @Inject(MAT_DIALOG_DATA)
    public input: {
      data: {
        robot: any;
      };
    },
    private stateService: StateService,
    private licenseApi: LicenseApiService
  ) {
    this.org_id$ = this.stateService.getCustomerOrSalesOrganization().pipe(
      map((org: ApiOrganization) => org.id),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.linkedRobots$ = this.org_id$.pipe(
      switchMap((org_id: string) =>
        this.licenseApi.getAllLinkingLicenseInfoForCustomerWithId(org_id)
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.licenses$ = this.linkedRobots$.pipe(
      switchMap((licenses: ILicenseLinkInfo[]) => {
        const inputValue =
          input?.data?.robot?.installed_robot_sw_license?.robot_serial_number;
        return of(
          licenses.filter((l) =>
            this.isTheSameSerialNumber(l.robot_serial_number, inputValue)
          )
        );
      }),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  close() {
    this.dialogRef.close();
  }

  isTheSameSerialNumber(sn1: string, sn2: any): boolean {
    const serial1 = sn1?.toString();
    const serial2 = sn2?.toString();

    return (
      serial1?.trim().length > 0 &&
      serial2?.trim().length > 0 &&
      serial1 == serial2
    );
  }
}
