import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { pagesPATH } from '../../models_new/config/pages';
import { AdvancedRobotConfigComponent } from './advanced-robot-config/advanced-robot-config.component';
import { SimConfigFormComponent } from './sim-config-form/sim-config-form.component';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { SummaryComponent } from './summary/summary.component';
import { RobotWrapperComponent } from '../../components/inventory/robot-configs/robot-wrapper/robot-wrapper.component';
import { InventoryRobotConfigsComponent } from '../../components/inventory/robot-configs/inventory-robot-configs/inventory-robot-configs.component';
import { RobotConfigComponent } from '../../components/robot-configs/robot-config/robot-config.component';
import { OrganizationContextResolver } from 'src/app/resolvers/organization-context-resolver.resolver';

const routes: Routes = [
  {
    component: RobotWrapperComponent,
    path: '',
    resolve: { organization_id: OrganizationContextResolver },
    data: {
      breadcrumb: 'Robot configurations',
      bcRouteTo: [pagesPATH.INVENTORY, pagesPATH.ROBOT_CONFIGURATIONS],
    },
    children: [
      {
        path: '',
        component: InventoryRobotConfigsComponent,
        data: {
          breadcrumb: undefined, // We use the breadcrumb from the parent
        },
      },
      {
        path: ':id',
        resolve: { organization_id: OrganizationContextResolver },
        component: RobotConfigComponent,
        data: {
          breadcrumb: 'Robot configuration',
        },
      },
    ],
  },
  {
    component: AdvancedRobotConfigComponent,
    path: ':robotConfigurationId/' + pagesPATH.ADVANCED_CONFIG,
    children: [
      {
        path: pagesPATH.ADVANCED_CONFIG_HARDWARE + '/1',
        data: {
          header: 'Set the stage',
          simConfigFieldIds: [
            SimConfigFieldIds.PolyscopeVersion, // Input not shown. Missing from implementation
            //SimConfigFieldIds.PallyVersion,  // Software versions are located in "strategy" Could maybe be placed under "Robot" in software settings?
            SimConfigFieldIds.FrameType,
            SimConfigFieldIds.LiftkitType,
            SimConfigFieldIds.RobotType,
          ],
        },
        component: SimConfigFormComponent,
      },
      {
        path: pagesPATH.ADVANCED_CONFIG_HARDWARE + '/2',
        data: {
          header: 'Gripper',
          simConfigFieldIds: [
            SimConfigFieldIds.GripperType,
            SimConfigFieldIds.GripperAdvanced,
            SimConfigFieldIds.AddOffsetBracket,
          ],
        },
        component: SimConfigFormComponent,
      },
      {
        path: pagesPATH.ADVANCED_CONFIG_HARDWARE + '/3',
        data: {
          header: 'Pallet',
          simConfigFieldIds: [
            SimConfigFieldIds.PalletRightName,
            SimConfigFieldIds.PalletRightAdvanced,
            SimConfigFieldIds.PalletLeftName,
            SimConfigFieldIds.PalletLeftAdvanced,
          ],
        },
        component: SimConfigFormComponent,
      },
      {
        path: pagesPATH.ADVANCED_CONFIG_HARDWARE + '/4',
        data: {
          header: 'Conveyor',
          simConfigFieldIds: [
            SimConfigFieldIds.ConveyorPrimaryType,
            SimConfigFieldIds.ConveyorPrimaryAdvanced,
            // SimConfigFieldIds.ConveyorSecondaryType, // Is not shown, probably not in implementation
            // SimConfigFieldIds.ConveyorSecondaryAdvanced // Is not shown, probably not in implementation
          ],
        },
        component: SimConfigFormComponent,
      },
      {
        path: pagesPATH.ADVANCED_CONFIG_STRATEGY + '/1',
        data: {
          header: 'Gripper',
          simConfigFieldIds: [],
          //simConfigFieldIds: [SimConfigFieldIds.GripperOptimization], // Missing implementation
        },
        component: SimConfigFormComponent,
      },
      {
        path: pagesPATH.ADVANCED_CONFIG_STRATEGY + '/2',
        data: {
          header: 'Path planning',
          simConfigFieldIds: [],
          //simConfigFieldIds: [
          //  SimConfigFieldIds., // Above pickup position, missing implementation
          //  SimConfigFieldIds., // High approach boost percentage, missing implementation
          //  SimConfigFieldIds., // Box free height, missing implementation
          //  SimConfigFieldIds., // Approach distance, missing implementation
          //], //
        },
        component: SimConfigFormComponent,
      },
      {
        path: pagesPATH.ADVANCED_CONFIG_STRATEGY + '/3',
        data: {
          header: 'Smart exits',
          simConfigFieldIds: [],
          //simConfigFieldIds: [
          //  SimConfigFieldIds. // Smarte exit fields are missing implementation
          //],
        },
        component: SimConfigFormComponent,
      },
      {
        path: pagesPATH.ADVANCED_CONFIG_STRATEGY + '/4',
        data: {
          header: 'Robot',
          simConfigFieldIds: [],
          //simConfigFieldIds: [
          //  SimConfigFieldIds. // Robot smart speed, max speed, and max acceleration fields not implemented
          //],
        },
        component: SimConfigFormComponent,
      },
      {
        path: pagesPATH.ADVANCED_CONFIG_STRATEGY + '/5',
        data: {
          header: 'Waiting position',
          simConfigFieldIds: [],
          //simConfigFieldIds: [
          //  SimConfigFieldIds. // Waiting position fields not implemented
          //],
        },
        component: SimConfigFormComponent,
      },
      {
        component: SummaryComponent,
        path: pagesPATH.ADVANCED_CONFIG_SUMMARY,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class RobotConfigurationRoutingModule {}
