import { Injector } from '@angular/core';
import * as THREE from 'three';
import { ReplaySubject } from 'rxjs';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { Type } from 'src/app/utils/type';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { track } from '../../../../utils/resource-tracker';
import { milliToMeter } from '../../../../utils/div';
import { DimensionVisualizer } from '../../3dview/timed-objects/dimension-visualizer';

export class OffsetBracketTask extends Task {
  static [taskNameSymbol] = 'Offsetbracket';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, _reject: (reason?: any) => void): void {
    this.insertOffsetBracket();

    const toolmount_offsetBracket = this.robot.getJointByID(
      JointNames.ToolmountOffsetBracket
    ) as THREE.Object3D;
    const offset = this.readBracketOffset();
    /*
    TODO: Disabled for now. Kept for re-enabling.
    if (
      this.data.fieldId === SimConfigFieldIds.OffsetBracketOffsetX ||
      this.data.fieldId === SimConfigFieldIds.AddOffsetBracket
    ) {
      const dimvis = this.activateDimVis(SimConfigFieldIds.OffsetBracketOffsetX, {
        value: offset.x,
      }, undefined, false)
      toolmount_offsetBracket.add(
        dimvis
      );
    }*/

    if (this.data.fieldId === SimConfigFieldIds.OffsetBracketOffsetY) {
      if (offset.y !== 0) {
        const dimvis = this.activateDimVis(
          SimConfigFieldIds.OffsetBracketOffsetY,
          {
            value: offset.y,
          },
          this.readShowVisualizers() ? -1 : undefined,
          false
        );
        toolmount_offsetBracket.add(dimvis);
      } else {
        const dimvis = this.toService.getTimedObject(
          SimConfigFieldIds.OffsetBracketOffsetY
        ) as DimensionVisualizer;
        dimvis.setValue(offset.y);
        dimvis.remove();
      }
    }

    /*
    TODO: Disabled for now. Kept for re-enabling.
    if (
      this.data.fieldId === SimConfigFieldIds.OffsetBracketOffsetZ ||
      this.data.fieldId === SimConfigFieldIds.AddOffsetBracket
    ) {
      const dimvis = this.activateDimVis(SimConfigFieldIds.OffsetBracketOffsetZ, {
        value: offset.z,
      }, undefined, false)
      toolmount_offsetBracket.add(
        dimvis
      );
    }*/

    resolve();
  }

  public insertOffsetBracket(): void {
    const toolmount_offsetBracket = this.robot.getJointByID(
      JointNames.ToolmountOffsetBracket
    );
    const offsetBracketLink = URDFUtils.findLinkFromJoint(
      toolmount_offsetBracket
    );
    const offsetBracketVisual = URDFUtils.findVisualFromJoint(
      toolmount_offsetBracket
    );

    const bracketActive = this.readBracketActive();
    if (!bracketActive) {
      ThreeUtils.disposeObject(offsetBracketVisual.children);
      offsetBracketVisual.children = [];
      toolmount_offsetBracket.position.set(0, 0, 0);
      offsetBracketLink.position.set(0, 0, 0);
      offsetBracketVisual.position.set(0, 0, 0);
      return;
    }

    const offset = this.readBracketOffset();
    const dimensions = this.readBracketDimensions();

    // Add extra VISUAL length to bracket to make the bracket look better
    dimensions.add(
      new THREE.Vector3(
        0, // Extra width
        0 + dimensions.x, // Extra length
        0 // Extra height
      )
    );

    const model = new THREE.Mesh(
      track(new THREE.BoxGeometry(1, 1, 1)),
      track(new THREE.MeshLambertMaterial({ color: '#A5A5A5' }))
    );
    model.scale.copy(dimensions);
    ThreeUtils.disposeObject(offsetBracketVisual.children);
    offsetBracketVisual.children = [];
    offsetBracketVisual.add(model);

    toolmount_offsetBracket.position.set(0, 0, 0);
    offsetBracketLink.position.set(offset.x, -offset.y, offset.z);
    offsetBracketVisual.position.set(
      -offset.x / 2,
      offset.y / 2,
      -offset.z / 2
    );
  }

  protected readBracketActive(): boolean {
    return this.readField(SimConfigFieldIds.AddOffsetBracket);
  }

  protected readBracketDimensions(dimensions?: THREE.Vector3): THREE.Vector3 {
    const dim: Array<any> = this.readFields([
      SimConfigFieldIds.OffsetBracketWidth,
      SimConfigFieldIds.OffsetBracketLength,
      SimConfigFieldIds.OffsetBracketHeight,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < dim.length; i++) {
      if (dim[i] === -0 || dim[i] === '-0') {
        dim[i] = 0;
      } else if (Type.isOfType(dim[i], 'string')) {
        dim[i] = +dim[i];
      }
    }

    const tempDim = new THREE.Vector3(
      milliToMeter(dim[0]),
      milliToMeter(dim[1]),
      milliToMeter(dim[2])
    );

    if (Type.isDefined(dimensions)) {
      dimensions.copy(tempDim);
    }

    return tempDim;
  }

  protected readBracketOffset(offset?: THREE.Vector3): THREE.Vector3 {
    const bracketOffset: Array<any> = this.readFields([
      SimConfigFieldIds.OffsetBracketOffsetX,
      SimConfigFieldIds.OffsetBracketOffsetY,
      SimConfigFieldIds.OffsetBracketOffsetZ,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < bracketOffset.length; i++) {
      if (bracketOffset[i] === -0 || bracketOffset[i] === '-0') {
        bracketOffset[i] = 0;
      } else if (Type.isOfType(bracketOffset[i], 'string')) {
        bracketOffset[i] = +bracketOffset[i];
      }
    }

    const tempOffset = new THREE.Vector3(
      milliToMeter(bracketOffset[0]),
      milliToMeter(bracketOffset[1]),
      milliToMeter(bracketOffset[2])
    );

    if (Type.isDefined(offset)) {
      offset.copy(tempOffset);
    }

    return tempOffset;
  }
}
