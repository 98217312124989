import { IPallyPathStrategy } from '../../types/pally-path-strategy';
import { IApiOrganization } from './ApiOrganization';
import { ApiPattern } from './ApiPattern';

export interface IApiGenerateWaypoint {
  id: string;
  name?: string;
  organization: IApiOrganization;
  result?: any;
  calibration_id?: string;
  calibration?: {
    installed_robot?: {
      id: string;
      name: string;
      robot_serial_number: string;
      updated_at: string;
    };
  };
  pally_path_strategy?: {
    id: string;
    name: string;
    data?: IPallyPathStrategy;
  };
  pattern?: ApiPattern;
  simulation_status?: any;
  simulation_state?: string;
  error_message?: string;
  eta?: number | string;
  progress?: number | string;
  attempt_number?: number;
  created_at?: string;
  updated_at: string;
}

export class ApiGenerateWaypoint implements IApiGenerateWaypoint {
  id: string;
  name: string;
  organization: IApiOrganization;
  result?: any;
  installed_robot?: {
    id: string;
    name: string;
  };
  pally_path_strategy: {
    id: string;
    name: string;
    data?: IPallyPathStrategy;
  };
  simulation_status?: any;
  simulation_state?: string;
  error_details?: any;
  attempt_number?: number;
  created_at?: string;
  updated_at: string;

  constructor(data: IApiGenerateWaypoint) {
    for (const d in data) {
      // If calibration
      if (d === 'calibration') {
        this.installed_robot = data.calibration?.installed_robot;
        delete data.calibration;
      }

      if (data.hasOwnProperty(d)) {
        this[d] = data[d];
      }
    }
  }
}
