import { Observable } from 'rxjs';
import { New_ThreeViewComponent } from '../../../components/gui/3dview/three-view/three-view.component';
import { TrackingScene } from './tracking-scene';

/**
 * Base class for content inside a 3dview.
 */
export class ThreeViewContent {
  // Useful for allowing depending contents to run first.
  updateOrder = 0; // 0 = first, 1 = second, so on...

  // Tell the 3dview wether this content is loading or not.
  loading$: Observable<boolean>;

  get scene(): TrackingScene {
    return this.threeView.scene;
  }

  constructor(public threeView: New_ThreeViewComponent) {
    threeView.registerContent(this);
  }

  onDestroy(): void {
    this.threeView.ngOnDestroy();
  }

  /**
   * Updates the content.
   * @param dt {number} - Time since last frame. Can be ignored if not a looping or xr view.
   */
  update?(_dt?: number | null): void {
    // Do nothing by default.
  }

  /**
   * Function for reacting to resize events.
   * @param width {number} - New width of the canvas.
   * @param height {number} - New height of the canvas.
   * @param aspect {number} - New aspect of the canvas.
   */
  resize?(_width: number, _height: number, _aspect: number): void {
    // Do nothing by default.
  }

  render(): void {
    this.threeView.render();
  }
}
