import JSZip from 'jszip';
import { Observable } from 'rxjs';

export interface IBase64Validation {
  isValid: boolean;
  error?: string;
  message?: string;
}

export interface IValidUrl {
  valid: boolean;
  msg: 'expired_token' | 'missing_param' | 'success';
}

export class FileUtils {
  static zipFiles(files: File[]): Observable<Blob> {
    return new Observable((sub) => {
      const zip = new JSZip();
      files.forEach((file) => {
        zip.file(file.name, file);
      });
      zip
        .generateAsync({ type: 'blob' })
        .then((content) => {
          sub.next(content);
          sub.complete();
        })
        .catch((error) => {
          sub.error(error);
        });
    });
  }

  static validAzureBlobStoreUrl(url: string): IValidUrl {
    // Optimistic response.
    const response: IValidUrl = {
      valid: true,
      msg: 'success',
    };

    let regex = /sv=|se=|sr=|sp=|sig=/;

    // regex fails
    if (!regex.test(url)) {
      response.valid = false;
      response.msg = 'missing_param';
      return response;
    }

    const expiresMs = Date.parse(new URLSearchParams(url).get('se'));
    const nowMs = Date.parse(new Date().toISOString());

    // expired token
    if (expiresMs < nowMs) {
      response.valid = false;
      response.msg = 'expired_token';
      return response;
    }

    return response;
  }

  static urlToBase64(url: string): Observable<string> {
    return new Observable((sub) => {
      const xhr = new XMLHttpRequest();
      xhr.open('GET', url, true);
      xhr.responseType = 'blob';
      xhr.onload = function () {
        const reader = new FileReader();
        reader.onload = function (event) {
          sub.next(event.target.result as string);
          sub.complete();
        };
        const file = this.response;
        reader.readAsDataURL(file);
      };
      xhr.onerror = (err) => {
        sub.error(err);
      };
      xhr?.send();
    });
  }

  static uploadFile(event: any): Observable<string> {
    return new Observable((sub) => {
      const file = event.target.files[0];
      const fileReader = new FileReader();
      fileReader.onload = () => {
        sub.next(fileReader.result as string);
        sub.complete();
      };
      fileReader.onerror = (err) => {
        sub.error(err);
      };
      fileReader.readAsDataURL(file);
    });
  }

  static base64ToFile(base64String, fileName, mimeType) {
    // Decode the base64 string
    const byteCharacters = atob(base64String);

    // Convert to an array of bytes
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a blob from the byte array
    const blob = new Blob([byteArray], { type: mimeType });

    // Create a file from the blob
    const file = new File([blob], fileName, { type: mimeType });

    return file;
  }

  static base64PdfToUrl(base64: string): string {
    const linkSource = `data:application/pdf;base64,${base64}`;
    return linkSource;
  }

  /**
   * Cheks the validation for a given base64-file.
   * @param inputBase64String
   * @returns <IBase64Validation>
   */
  static isBase64Valid(inputBase64String: string): IBase64Validation {
    let result: IBase64Validation = {
      isValid: false,
      error: null,
      message: null,
    };

    const base64regex =
      /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/;

    const str = inputBase64String.split(',');
    if (base64regex.test(str[1])) {
      const jsonB64 = this.xmlToJson(
        new DOMParser().parseFromString(
          atob(str[1]).replaceAll('ï»¿', ''),
          'text/xml'
        )
      );
      if (jsonB64.Error) {
        for (let [_, value] of Object.entries(jsonB64.Error['Code']))
          if (typeof value === 'string') result.error = value;
        for (let [_, value] of Object.entries(jsonB64.Error['Message']))
          if (typeof value === 'string') result.message = value;
      } else result.isValid = true;
    } else {
      result = {
        isValid: false,
        error: 'The given file seems to be corrupt.',
      };
    }
    return result;
  }

  /**
   * @param data [][]
   * @example let csv = arrayToCsv([
   *  [1, '2', '"3"'],
   *  [true, null, undefined],
   * ]);
   */
  static arrayToCsv(
    data: any[][],
    fileName: string,
    download?: boolean
  ): string {
    const csv = data
      .map(
        (row) =>
          row
            .map(String) // convert every value to String
            .map((v) => v.replaceAll('"', '""')) // escape double colons
            .map((v) => `"${v}"`) // quote it
            .join(',') // comma-separated
      )
      .join('\r\n'); // rows starting on new lines

    if (!download) {
      return csv;
    } else {
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);

      // Create a link to download it
      const pom = document.createElement('a');
      pom.href = url;
      pom.setAttribute('download', fileName + '.csv');
      pom.click();

      return csv;
    }
  }

  static downloadBlobFile(blob: Blob, fileName: string): void {
    const url = URL.createObjectURL(blob);
    FileUtils.downloadFile(url, fileName);
    URL.revokeObjectURL(url);
  }

  static downloadFile(url: string, fileName: string) {
    const downloadLink = document.createElement('a');
    const fullFileName = fileName;

    downloadLink.href = url;
    downloadLink.download = fullFileName;
    downloadLink.click();
  }

  /**
   * Downloads base64 as pdf.
   */
  static downloadBase64Pdf(base64: string, fileName: string): void {
    const linkSource = FileUtils.base64PdfToUrl(base64);
    FileUtils.downloadFile(linkSource, fileName + '.pdf');
  }

  static validField(
    data: any,
    field: string,
    shouldBe: 'defined' | '>0'
  ): boolean {
    let valid = false;
    const lookup = data[field];
    if (!lookup) {
      return true;
    }
    switch (shouldBe) {
      case 'defined':
        valid = lookup !== undefined && lookup !== null;
        break;
      case '>0':
        valid = lookup.length > 0;
        break;
    }
    return valid;
  }

  static downloadJson(data: any, fileName: string): void {
    const linkSource =
      'data:text/json;charset=utf-8,' +
      encodeURIComponent(JSON.stringify(data, null, 4));
    FileUtils.downloadFile(linkSource, fileName + '.json');
  }

  /**
   * Converts XML to JSON
   * @param xml
   * @returns JSON Object | String<'Error'>
   */
  static xmlToJson(xml): any {
    let obj = {};
    if (xml.nodeType == 1) {
      // Do this level-node attributes
      if (xml.attributes.length > 0) {
        // const attrName =
        obj['@attributes'] = {};

        for (let j = 0; j < xml.attributes.length; j++) {
          const attribute = xml.attributes.item(j);
          obj['@attributes'][attribute.nodeName] = attribute.nodeValue;
        }
      }
    } else if (xml.nodeType == 3) {
      obj = xml.nodeValue;
    }
    // // Do children level-node
    if (xml.hasChildNodes()) {
      for (let i = 0; i < xml.childNodes.length; i++) {
        const item = xml.childNodes.item(i);
        const nodeName = item.nodeName;
        if (typeof obj[nodeName] == 'undefined') {
          obj[nodeName] = this.xmlToJson(item);
        } else {
          if (typeof obj[nodeName].push == 'undefined') {
            const old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(this.xmlToJson(item));
        }
      }
    }
    return obj;
  }
}
