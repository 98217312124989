import { Rectangle, RectangleLocation } from '@rocketfarm/cartesian-rectangle';

export class PalletLayerOverlapError extends Error {}

export function lineOverlap(
  aMin: number,
  aMax: number,
  bMin: number,
  bMax: number
) {
  return aMax >= bMin && bMax >= aMin;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recLocationOverlapX<T>(
  recL1: RectangleLocation,
  recL2: RectangleLocation
) {
  return lineOverlap(
    recL1.getXMin(),
    recL1.getXMax(),
    recL2.getXMin(),
    recL2.getXMax()
  );
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function recLocationOverlapY<T>(
  recL1: RectangleLocation,
  recL2: RectangleLocation
) {
  return lineOverlap(
    recL1.getYMin(),
    recL1.getYMax(),
    recL2.getYMin(),
    recL2.getYMax()
  );
}
export function rectangleLocationOverlap<T>(
  recL1: RectangleLocation,
  recL2: RectangleLocation
) {
  return (
    recLocationOverlapX<T>(recL1, recL2) && recLocationOverlapY<T>(recL1, recL2)
  );
}
export function rectangleLocationsOverlapingArrays<T>(
  recLs1: RectangleLocation[],
  recLs2: RectangleLocation[]
) {
  return (
    recLs1.find(
      (r1) =>
        recLs2.find((r2) => rectangleLocationOverlap<T>(r1, r2)) !== undefined
    ) !== undefined
  );
}
/**
 * Collection class for RectangleLocation. Makes sure contained RectangleLocations do not overlap each other
 */
export class PalletLayer {
  protected rectangleLocations: RectangleLocation[] = [];

  constructor(protected dimensions: Rectangle) {}

  clear() {
    this.rectangleLocations = [];
  }

  addRectangleLocation(rectangleLocation: RectangleLocation) {
    const newRectangleLocations = [rectangleLocation];
    this.rectangleLocations = this.rectangleLocations.concat(
      newRectangleLocations.map((rl) => rl.clone())
    );
  }
}

/**
 * Canvas drawer class. Visualizes the state of extended class PalletLayer
 */
export class PalletLayerCanvas extends PalletLayer {
  constructor(
    dimensions: Rectangle,
    private canvasElement: HTMLCanvasElement,
    private boxColor = PalletLayerCanvas.defaultBoxColor,
    private palletColor?: string
  ) {
    super(dimensions);
    this.canvasElement.setAttribute(
      'width',
      this.dimensions.getWidth().toString()
    );
    this.canvasElement.setAttribute(
      'height',
      this.dimensions.getLength().toString()
    );
    this.ctx = this.canvasElement.getContext('2d') as CanvasRenderingContext2D;

    // Clip canvas so origin of coordinate stystem starts from bottom left corner
    this.ctx.setTransform(1, 0, 0, -1, 0, dimensions.getLength());

    this.drawPallet();
  }
  static defaultPalletColor = '#f0d081';
  static defaultBoxColor = '#dbbb95';
  private ctx: CanvasRenderingContext2D;

  private drawPallet() {
    this.drawRectangleLocation(
      new RectangleLocation(
        this.dimensions.getWidth(),
        this.dimensions.getLength(),
        0,
        0,
        false
      ),
      this.palletColor
    );
  }

  private drawRectangleLocation(
    rl: RectangleLocation,
    color?: string,
    lineWidth = 1
  ) {
    if (color) {
      this.ctx.fillStyle = color;
      this.ctx.fillRect(
        rl.getXMin(),
        rl.getYMin(),
        rl.getWidth(),
        rl.getLength()
      );
    }

    this.ctx.lineWidth = lineWidth;
    this.ctx.strokeStyle = '#000000';
    this.ctx.strokeRect(
      rl.getXMin() + lineWidth / 2,
      rl.getYMin() + lineWidth / 2,
      rl.getWidth() - lineWidth,
      rl.getLength() - lineWidth
    );
  }

  clear() {
    super.clear();
    if (this.palletColor) {
      this.drawPallet();
    } else {
      this.ctx.clearRect(
        0,
        0,
        this.dimensions.getWidth(),
        this.dimensions.getLength()
      );
      this.drawPallet();
    }
  }

  addRectangleLocation(rectangleLocation: RectangleLocation, outlineWidth = 1) {
    super.addRectangleLocation(rectangleLocation);
    this.drawRectangleLocation(rectangleLocation, this.boxColor, outlineWidth);
  }
}
