import { ApiPattern } from './ApiPattern';
import { ApiProductionLine } from './ApiProduction_line';
import * as uuid from 'uuid';
import { ApiPalletizingProject } from './ApiPalletizingProject';

export interface IApiProduct {
  id: string;
  owner_id: string;
  organization_id: string;
  name: string;
  data: {
    length: number;
    width: number;
    height: number;
    weight: number;
    label_orientation: number;
    model_type?: string;
  };
  created_at: string;
  updated_at: string;
  product_production_lines: { production_line: ApiProductionLine }[];
  patterns: ApiPattern[];
  product_palletizing_projects: ApiPalletizingProject[];
}

export class ApiProduct implements IApiProduct {
  id: string;
  owner_id: string;
  organization_id: string;
  name: string;
  data: {
    length: number;
    width: number;
    height: number;
    weight: number;
    label_orientation: number;
    model_type?: string;
  };
  created_at: string;
  updated_at: string;
  product_production_lines: { production_line: ApiProductionLine }[];
  patterns: ApiPattern[];
  product_palletizing_projects: ApiPalletizingProject[];

  constructor(product: IApiProduct) {
    for (const d in product) {
      if (product.hasOwnProperty(d)) {
        this[d] = product[d];
      }
    }

    if (this.id === null) {
      this.id = uuid.v4();
    }
  }
}
