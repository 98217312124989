import { IHwSwType } from '../models_new/types/robot-config/hw-sw-type';
import { RobotType } from '../models_new/types/robot-type';
import { ISceneApiFileType } from '../models_new/types/simulation-api-file-type';

export class HardwareUtils {
  static getRobotModelBySerialNumber(serialNo: string): string {
    if (!serialNo) return 'Unknown robot type';
    const robotBrand = this.getRobotBrand(serialNo);

    if (robotBrand === 'DOOSAN') {
      return serialNo.split('-')[1];
    }

    const productCode =
      (Number(serialNo.charAt(4)) - 0) * 10 + (Number(serialNo.charAt(5)) - 0);

    switch (productCode) {
      case 30:
      case 50:
        return 'UR10/UR10e';
      case 52:
        return 'UR10e (12.5 kg)';
      case 33:
      case 53:
        return 'UR3/UR3e';
      case 35:
      case 55:
        return 'UR5/UR5e';
      case 56:
        return 'UR16e';
      case 68:
        return 'UR20';
      case 69:
        return 'UR30';
      default:
        return 'Unknown robot type';
    }
  }

  static labelifyScene(
    scene: ISceneApiFileType,
    hw_types: IHwSwType[]
  ): ISceneApiFileType {
    if (!scene.robot.frame.label) {
      scene.robot.frame.label = HardwareUtils.findHwTypeLabel(
        hw_types,
        scene.robot.frame.type,
        'frame'
      );
    }
    if (!scene.robot.lift_kit.label) {
      scene.robot.lift_kit.label = HardwareUtils.findHwTypeLabel(
        hw_types,
        scene.robot.lift_kit.type,
        'liftkit'
      );
    }
    if (!scene.robot.label) {
      scene.robot.label = HardwareUtils.findHwTypeLabel(
        hw_types,
        scene.robot.type,
        'robot'
      );
    }
    if (!scene.robot.gripper.label) {
      scene.robot.gripper.label = HardwareUtils.findHwTypeLabel(
        hw_types,
        scene.robot.gripper.type,
        'gripper'
      );
    }
    for (const conveyor of scene.conveyors) {
      if (!conveyor.label) {
        conveyor.label = HardwareUtils.findHwTypeLabel(
          hw_types,
          conveyor.type,
          'conveyor'
        );
      }
    }
    return scene;
  }

  static findHwTypeLabel(
    hw_types: IHwSwType[],
    name: string,
    type: string
  ): string {
    return (
      hw_types.find(
        (hw: IHwSwType) => hw.hw_sw_type.name === type && hw.name === name
      )?.label || 'N/A'
    );
  }

  static getRobotBrand(serial: string): RobotType {
    if (!serial || typeof serial !== 'string') {
      return 'Unknown Robot type';
    }

    if (/^\d+$/.test(serial)) {
      if (serial.length === 11) {
        return 'UR';
      } else {
        return `Unknown Robot type`;
      }
    }

    // Check if serial contains letters, digits, and a hyphen
    else if (/^[A-Z0-9\-]+$/.test(serial) && serial.includes('-')) {
      let parts = serial.split('-');
      if (
        parts.length === 2 &&
        parts[0].length === 6 &&
        parts[1].length === 5
      ) {
        return 'DOOSAN'; // Return "DOOSAN" for alphanumeric serials with a specific hyphenated pattern
      } else {
        return 'Unknown Robot type';
      }
    }

    // Unknown Serial Type
    else {
      return 'Unknown Robot type';
    }
  }
}
