import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChanges,
} from '@angular/core';
import * as THREE from 'three';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { ThreeViewContent } from '../../../../../../../models_new/classes/3dview/three-content';
import { settings } from '../../../../../../../models_new/config/application-settings';
import { New_ThreeViewComponent } from '../../../../three-view/three-view.component';

@Component({
  selector: 'app-perspective-camera',
  templateUrl: './perspective-camera.component.html',
  styleUrls: ['./perspective-camera.component.scss'],
})
export class PerspectiveCameraComponent
  extends ThreeViewContent
  implements OnChanges, OnDestroy
{
  camera: THREE.PerspectiveCamera;

  @Input() fov = 50;
  @Input() near = 0.01;
  @Input() far = 1000;
  @Input() position = settings.view3d.defaultCameraPosition.clone();
  @Input() rotation: THREE.Quaternion;
  @Input() target: THREE.Object3D | THREE.Vector3 | undefined;
  @Input() up = new THREE.Vector3(0, 1, 0);

  @Input() controls?: OrbitControls;

  // Adds a light helper for the light to the scene.
  @Input() debug: boolean;
  helper: THREE.CameraHelper;

  constructor(threeView: New_ThreeViewComponent) {
    super(threeView);
    this.updateOrder = 50; // Update this late

    this.camera = new THREE.PerspectiveCamera(
      this.fov,
      this.threeView.canvasAspect,
      this.near,
      this.far
    );
    this.camera.position.copy(this.position);
    this.camera.updateProjectionMatrix();
    this.scene.add(this.camera);

    this.helper = new THREE.CameraHelper(this.camera);
    this.helper.visible = false;
    this.scene.add(this.helper);
  }

  ngOnDestroy(): void {
    super.onDestroy();
  }

  update(_dt: number): void {
    if (this.target) {
      this.updateTrackingOfTarget(this.target);
    }
  }

  updateControls(): void {
    if (this.controls) {
      this.controls.update();
    } else {
      this.threeView.updateControls();
    }
  }

  resize(width: number, height: number, aspect: number): void {
    this.camera.aspect = aspect;
    this.camera.updateProjectionMatrix();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.fov?.currentValue &&
      this.camera instanceof THREE.PerspectiveCamera
    ) {
      this.camera.fov = changes.fov.currentValue;
      this.camera.updateProjectionMatrix();
    }
    if (changes.near?.currentValue) {
      this.camera.near = changes.near.currentValue;
      this.camera.updateProjectionMatrix();
    }
    if (changes.far?.currentValue) {
      this.camera.far = changes.far.currentValue;
      this.camera.updateProjectionMatrix();
    }
    if (changes.position?.currentValue) {
      this.camera.position.copy(changes.position?.currentValue);
      this.camera.updateProjectionMatrix();

      this.updateControls();
    }
    if (changes.rotation?.currentValue) {
      this.camera.rotation.copy(changes.rotation?.currentValue);
      this.camera.updateProjectionMatrix();

      this.updateControls();
    }
    if (changes.target?.currentValue) {
      this.updateTrackingOfTarget(changes.target.currentValue);

      this.updateControls();
    }
    if (changes.up?.currentValue) {
      this.camera.up.copy(changes.up?.currentValue);
      this.camera.updateProjectionMatrix();
    }
    if (changes.debug?.currentValue) {
      this.helper.visible = changes.debug.currentValue;
    }
  }

  private updateTrackingOfTarget(
    target: THREE.Object3D | THREE.Vector3 | undefined
  ): void {
    if (!this.target) {
      return;
    }

    let lookAtPos = new THREE.Vector3();
    if ('isObject3D' in target) {
      lookAtPos.copy(target.position);
    } else {
      lookAtPos = target;
    }
    this.camera.lookAt(lookAtPos);
    this.camera.updateProjectionMatrix();
    // Make sure I'm updated to my internal camera
    this.rotation = this.camera.quaternion;
  }
}
