import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import {
  filter,
  forkJoin,
  map,
  Observable,
  ReplaySubject,
  shareReplay,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { ApiRobotConfiguration } from 'src/app/models_new/classes/api-models/ApiRobotConfiguration';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { RobotConfigApiService } from 'src/app/services/api/robot-config-api.service';
import { ExportImportService } from 'src/app/services/export-import.service';
import { ITableAction } from '../../../gui/table/table.component';
import { FileUtils } from 'src/app/utils/file-utils';
import { NotificationService } from 'src/app/services/notification.service';
import { toRequestState } from 'src/app/data-request/operators';
import { DataRequestState } from 'src/app/data-request/model';
import { StateService } from '../../../../auth/state.service';
import { IApiOrganization } from '../../../../models_new/classes/api-models/ApiOrganization';
import { RobotActions } from '../../../gui/card-robot-config/card-robot-config.component';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';
import { SortDirection } from '@angular/material/sort';
import { ObjectUtils } from 'src/app/utils/object';
import {
  InventoryTableCta,
  InventoryTableDisplayColumn,
  InvetoryTableAction,
} from 'src/app/components/gui/inventory-table/inventory-table.component';
import { DatePipe } from '@angular/common';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';

@Component({
  selector: 'app-inventory-robot-configs',
  templateUrl: './inventory-robot-configs.component.html',
  styleUrls: ['./inventory-robot-configs.component.scss'],
})
export class InventoryRobotConfigsComponent implements OnInit {
  robotConfigs$: Observable<DataRequestState<ApiRobotConfiguration[]>>;
  objUtil = ObjectUtils;
  destroy$: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);
  cardActions: ITableAction<RobotActions>[] = [
    {
      label: 'Hardware configuration',
      actionId: 'hardware_configuration',
      roleAction: 'view_hardware',
    },
    {
      label: 'Software configuration',
      actionId: 'software_configuration',
      roleAction: 'view_software',
    },
    {
      label: 'Modify',
      actionId: 'modify',
      icon: 'edit',
      roleAction: 'modify_robot_configuration',
    },
    {
      label: 'Delete',
      actionId: 'delete',
      icon: 'delete',
      roleAction: 'delete_robot_configuration',
    },
  ];

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'owner_organization.name',
      label: 'Owner',
    },
    {
      id: 'scene.name',
      label: 'Hardware Configuration',
    },
    {
      id: 'strategy.name',
      label: 'Software Configuration',
    },
  ];

  displayedColumns: InventoryTableDisplayColumn[] = [
    {
      label: 'Name',
      path: 'name',
      sortType: 'string',
    },
    {
      label: 'Owner',
      path: 'owner_organization.name',
      sortType: 'string',
    },
    {
      label: 'Hardware Configuration',
      path: 'scene.name',
      sortType: 'string',
      specialCell: [
        {
          type: 'link',
          link: (row) => {
            if (!row.scene) {
              return '';
            } else {
              return `/${pagesPATH.INVENTORY}/${pagesPATH.HARDWARE_CONFIGURATIONS}/${row.scene.id}`;
            }
          },
        },
      ],
    },
    {
      label: 'Software Configuration',
      path: 'strategy.name',
      sortType: 'string',
      specialCell: [
        {
          type: 'link',
          link: (row) => {
            if (!row.strategy) {
              return '';
            } else {
              return `/${pagesPATH.INVENTORY}//${pagesPATH.SOFTWARE_LIST}/${row.strategy.id}`;
            }
          },
        },
      ],
    },
    {
      label: 'Updated At',
      path: 'updated_at',
      sortType: 'date',
    },
  ];

  rowCta: InventoryTableCta[] = [
    {
      label: 'Export',
      roleAction: 'export_robot_configuration',
      actionId: 'export_robot_configuration',
      icon: 'save_alt',
    },
  ];

  rowActions: InvetoryTableAction[] = [
    {
      label: 'Duplicate',
      actionId: 'clone_robot_configuration',
      roleAction: 'clone_robot_configuration',
      icon: 'content_copy',
      divideAfter: true,
    },
    {
      label: 'Delete',
      actionId: 'delete_robot_configuration',
      roleAction: 'delete_robot_configuration',
      icon: 'delete',
      color: 'warn',
    },
  ];

  blockSelected: ApiRobotConfiguration[] = [];
  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };

  constructor(
    private router: Router,
    private robotConfigApi: RobotConfigApiService,
    private exportImportService: ExportImportService,
    private notificationService: NotificationService,
    private stateService: StateService,
    private datePipe: DatePipe,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.robotConfigs$ = this.route.data.pipe(
      take(1),
      switchMap((data: Data) => {
        const orgId = (data as IOrganizationContextResolverData)
          .organization_id;
        return this.robotConfigApi.subscribeInventoryRobotConfigs(orgId);
      }),
      map((rcs: ApiRobotConfiguration[]) => {
        return rcs.map((rc) => {
          rc.updated_at =
            this.datePipe.transform(rc.updated_at, 'dd/MM/yyyy HH:mm') ||
            ('N/A' as any);
          return rc;
        });
      }),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$)
    );
  }

  routeToEdit(rcId?: string) {
    this.router.navigate([
      pagesPATH.INVENTORY,
      pagesPATH.ROBOT_CONFIGURATIONS,
      rcId ? rcId : 'new',
    ]);
  }

  rowClicked(e: ApiRobotConfiguration) {
    this.router.navigate([
      pagesPATH.INVENTORY,
      pagesPATH.ROBOT_CONFIGURATIONS,
      e.id,
    ]);
  }

  actionClicked(e: {
    action: InvetoryTableAction;
    row: ApiRobotConfiguration;
  }) {
    if (e.action.actionId === 'delete_robot_configuration') {
      this.onDelete([e.row]);
    } else if (e.action.actionId === 'clone_robot_configuration') {
      this.cloneRobotConfig(e.row);
    }
  }

  private cloneRobotConfig(rc: ApiRobotConfiguration) {
    this.route.data
      .pipe(
        take(1),
        switchMap((data: Data) => {
          const orgId = (data as IOrganizationContextResolverData)
            .organization_id;
          return this.robotConfigApi.insertRobotConfig(
            rc.name + ' (copy)',
            orgId,
            rc.scene.id,
            rc.strategy.id
          );
        }),
        take(1)
      )
      .subscribe({
        next: (_) => {
          this.notificationService.showSuccess(
            'The robot configuration was cloned successfully'
          );
        },
        error: (_error) => {
          this.notificationService.showError(
            'Clone failed. Are you the owner of this item, and do you have the correct role in your organization?'
          );
        },
      });
  }

  ctaClicked(e: { action: InventoryTableCta; row: ApiRobotConfiguration }) {
    if (e.action.actionId === 'export_robot_configuration') {
      FileUtils.downloadJson(e.row, e.row.name);
    }
  }

  onDelete(elements: ApiRobotConfiguration[]) {
    this.notificationService
      .deletePrompt(
        'Delete',
        'robot configuration',
        elements.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          const operations = elements
            .map((m) => m.id)
            .map((id) => this.robotConfigApi.deleteRobotConfig(id));
          return forkJoin(operations);
        })
      )
      .subscribe((result) => {
        if (result === null) {
          this.notificationService.showError(
            'Delete failed. Are you the owner of this item, and do you have the correct role in your organization?'
          );
        } else {
          this.notificationService.showMessage(
            `The robot configuration was deleted successfully`
          );
        }
      });
  }

  public onFileChanged(event): void {
    const selectedFile = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(selectedFile, 'UTF-8');
    fileReader.onload = () => {
      this.importRobotConfiguration(fileReader.result);
    };
  }

  private importRobotConfiguration(inputFile): void {
    const robotConfig = new ApiRobotConfiguration(JSON.parse(inputFile));
    this.stateService
      .getCustomerOrSalesOrganization()
      .pipe(
        take(1),
        switchMap((org: IApiOrganization) =>
          this.exportImportService.importRobotConfigurationFile(
            robotConfig,
            org.id
          )
        )
      )
      .subscribe(() => {
        this.notificationService.showSuccess(
          'Configuration imported successfully'
        );
      });
  }
}
