import { Pipe, PipeTransform } from '@angular/core';
import { UnitSystemType, validUnitRegex } from '../utils/unit-utils';
import { LocalStorageService } from '../services/local-storage.service';
import { UnitSystemPipe } from './unit-system.pipe';

@Pipe({
  name: 'unitSystemLabel',
})
export class UnitSystemLabelPipe implements PipeTransform {
  /**
   * @desc Handles unit conversions where the original unit forms part of a larger string (if any), like a label.
   * @param {any} label: A label-like string where the measurement is somewhere at the string. Like "Piab Palletizing Gripper 400 mm". Will return inputed value if not string or doesn't contain any unit.
   * @param {UnitSystemType} outputSystemFormat (Optional) Will use the preferred unit system unless its specified here otherwise.
   * @param {boolean} fractional (Optional) Will convert decimals into a single fractional character.
   * @returns {string} Label-like string with replaced original units to its conversion if any. Example: "Piab Palletizing Gripper 15¾ in"
   */
  transform(
    label: any,
    unitSystem?: UnitSystemType,
    fractional?: boolean
  ): string | any {
    if (typeof label === 'string') {
      const matches = label?.match(validUnitRegex);
      if (matches && matches[0]) {
        return label.replace(
          matches[0],
          new UnitSystemPipe(new LocalStorageService()).transform(
            matches[0],
            null,
            unitSystem,
            fractional ?? true
          ) as string
        );
      }
    }
    return label;
  }
}
