import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import {
  combineLatestWith,
  map,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { NotificationService } from 'src/app/services/notification.service';
import { StateService } from 'src/app/auth/state.service';
import {
  FormBuilder,
  Validators,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { InfoPopupComponent } from 'src/app/components/dialogs/info-popup/info-popup.component';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { InfoApiService } from 'src/app/services/api/info-api.service';
import { DialogService } from 'src/app/services/dialog.service';
import { IPallyPathStrategy } from 'src/app/models_new/types/pally-path-strategy';
import {
  ApiPallyPathStrategy,
  IApiPallyPathStrategy,
  createDefaultApiPallyPathStrategy,
} from 'src/app/models_new/classes/api-models/ApiPallyPathStrategy';
import {
  IPallyPathStrategyFields,
  IPallyPathStrategyFormGroup,
} from './pally-path-strategy-fields-type';
import { PallyPathStrategyApiService } from 'src/app/services/api/pally-path-strategy-api.service';
import { pagesPATH } from 'src/app/models_new/config/pages';
import {
  fracToPercent,
  meterToMilli,
  milliToMeter,
  percentToFrac,
} from 'src/app/utils/div';
import { PresetPickerData } from 'src/app/models_new/types/preset-picker-data';
import {
  ISmartExitConfig,
  pallyProgramSettingsSmartExitDefaultValues,
} from 'src/app/models_new/config/softwareConfigurationFields';
import { ConveyorDirection } from 'src/app/models_new/enums/sim-config-conveyor-dir';

export interface ArrayFieldFormGroup<T> extends FormGroup {
  value: Array<T>;
  controls: {
    [key: string]: FormControl<T>;
  };
}

/**
 * @description Component for displaying and editing Pally Path Strategies aka PallyPrograms.
 * @class PallyPathStrategyDetailsComponent
 * @implements OnInit, OnDestroy
 */
@Component({
  selector: 'app-pally-path-strategy-details',
  templateUrl: './pally-path-strategy-details.component.html',
  styleUrls: ['./pally-path-strategy-details.component.scss'],
})
export class PallyPathStrategyDetailsComponent implements OnInit, OnDestroy {
  @Input() presetPallyPathStrategy: ApiPallyPathStrategy;

  /** isDialog & configId & dialogEvent Meant to be used when the component is used as a dialog. */
  @Input() isDialog: boolean = false;
  @Input() configId: string;
  @Output() dialogEvent = new EventEmitter<ApiPallyPathStrategy>();
  @Output() savePallyPathStrategy = new EventEmitter<{
    name: string;
    strategy: IPallyPathStrategy;
  }>();

  public title: string = 'Pally program setting';
  public pathStrategy$: Observable<ApiPallyPathStrategy>;
  public expandedTab: null | 'grip_rot_cost' | 'smart_exit' = null;
  public formGroup: IPallyPathStrategyFormGroup;
  public gripRotCostFormGroup: ArrayFieldFormGroup<number>;
  public pathStrategyId: string = 'new';
  public isSaving$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  smartExits$: Observable<PresetPickerData[]>;
  changedFields: string[] = [];

  private destroy$ = new ReplaySubject<void>();

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private pathStrategyService: PallyPathStrategyApiService,
    private notification: NotificationService,
    private stateService: StateService,
    private infoApi: InfoApiService,
    private dialogService: DialogService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.pathStrategyId = this.route.snapshot.params.id || this.configId;
    if (this.presetPallyPathStrategy) {
      this.pathStrategy$ = of(this.presetPallyPathStrategy);
    } else {
      this.pathStrategy$ =
        this.pathStrategyId === 'new'
          ? of(createDefaultApiPallyPathStrategy())
          : this.pathStrategyService.fetchPathStrategyById(this.pathStrategyId);
    }
    this.pathStrategy$.subscribe((strategy: ApiPallyPathStrategy) =>
      this.createFormFields(strategy)
    );

    this.smartExits$ = this.pathStrategy$.pipe(
      take(1),
      combineLatestWith(of(pallyProgramSettingsSmartExitDefaultValues)),
      take(1),
      map(([strategy, m]) => {
        return m.map((m) => {
          return {
            id: m.id,
            label: 'From ' + m.direction,
            selected:
              JSON.stringify(strategy.data.path_planning.smart_exit) ===
              JSON.stringify(m.fields),
            image: m.image,
          };
        });
      })
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public saveStrategy(): void {
    if (this.formGroup.invalid) {
      this.notification.showError('Some fields have errors!');
      return;
    }

    const fieldValues = this.formGroup.value;
    const strategy = this.fieldToStrategy(fieldValues, this.pathStrategyId);
    if (this.presetPallyPathStrategy) {
      this.savePallyPathStrategy.emit({
        name: fieldValues.name,
        strategy: strategy.data,
      });
    } else {
      this.stateService
        .getCustomerOrSalesOrganization()
        .pipe(
          switchMap((org) => {
            this.isSaving$.next(true);
            if (this.pathStrategyId === 'new') {
              return this.pathStrategyService.insertPallyPathStrategy(
                org.id,
                strategy.name,
                strategy.data
              );
            } else {
              return this.pathStrategyService.updatePallyPathStrategy(
                this.pathStrategyId,
                strategy.name,
                strategy.data
              );
            }
          }),
          take(1)
        )
        .subscribe({
          next: (res) => {
            this.notification.showSuccess(`${strategy.name} saved!`);
            if (this.isDialog) {
              this.dialogEvent.emit({ ...strategy, id: res.id });
            } else {
              this.router.navigate([pagesPATH.PALLY_PATH_STRATEGIES]);
            }
          },
          error: (e) => {
            console.error(e);
            this.notification.showError(e.message);
            this.isSaving$.next(false);
          },
        });
    }
  }

  /**
   * @desc Updates the smart exits field with the selected smart exit.
   * @method updateSmartExits
   * @param {string} smartExitId
   */
  public updateSmartExits(smartExitId: string): void {
    const smartExitConfig: ISmartExitConfig =
      pallyProgramSettingsSmartExitDefaultValues.find(
        (f) => f.id === smartExitId
      );
    if (smartExitConfig) {
      const config =
        smartExitConfig.fields as IPallyPathStrategy['path_planning']['smart_exit'];

      for (let group in config.p1) {
        this.formGroup
          .get('path_planning')
          .get('smart_exit')
          .get('p1')
          .get(group)
          .setValue(config.p1[group]);
        this.changedFields.push('left_' + group);
        setTimeout(() => {
          this.changedFields.pop();
        }, 2000);
      }
      for (let group in config.p1) {
        this.formGroup
          .get('path_planning')
          .get('smart_exit')
          .get('p2')
          .get(group)
          .setValue(config.p2[group]);
        this.changedFields.push('right_' + group);
        setTimeout(() => {
          this.changedFields.pop();
        }, 2000);
      }
    }

    this.formGroup
      .get('path_planning')
      .get('smart_exit')
      .get('smart_exit_preset')
      .setValue(smartExitId);
  }

  /**
   * @desc Shows the info popup for the given field.
   * @method showInfo
   * @param {string} fieldId
   */
  public showInfo(fieldId: string): void {
    this.dialogService.showCustomDialog(
      InfoPopupComponent,
      DialogSize.MEDIUM,
      null,
      {
        id: fieldId,
        dataSource: this.infoApi.getSoftwareInfoCards(fieldId),
      },
      true
    );
  }

  /**
   * @desc Converts the form group values to a strategy object.
   * @method fieldToStrategy
   * @param {IPallyPathStrategyFields} fieldValues
   * @returns {ApiPallyPathStrategy}
   */
  private fieldToStrategy(
    fieldValues: IPallyPathStrategyFields,
    id: string = null
  ): ApiPallyPathStrategy {
    const strategy = createDefaultApiPallyPathStrategy();
    strategy.id = id;
    strategy.name = fieldValues.name;
    // Path planning
    const pathPlanning = strategy.data.path_planning;
    const fvPathPlanning = fieldValues.path_planning;
    pathPlanning.above_pickup = milliToMeter(fvPathPlanning.above_pickup);
    pathPlanning.box_free = milliToMeter(fvPathPlanning.box_free);
    pathPlanning.box_free_auto = fvPathPlanning.box_free_auto;
    pathPlanning.pallet_edge = milliToMeter(fvPathPlanning.pallet_edge);
    pathPlanning.approach = milliToMeter(fvPathPlanning.approach);
    pathPlanning.approach_auto = fvPathPlanning.approach_auto;
    pathPlanning.high_approach_boost = fvPathPlanning.high_approach_boost;
    pathPlanning.quick_return = fvPathPlanning.quick_return;
    // Path planning -> Smart exit
    const smartExit = pathPlanning.smart_exit;
    const fvSmartExit = fvPathPlanning.smart_exit;
    smartExit.p1.min_x = milliToMeter(fvSmartExit.p1.min_x);
    smartExit.p1.max_x = milliToMeter(fvSmartExit.p1.max_x);
    smartExit.p1.min_y = milliToMeter(fvSmartExit.p1.min_y);
    smartExit.p1.max_y = milliToMeter(fvSmartExit.p1.max_y);
    smartExit.p2.min_x = milliToMeter(fvSmartExit.p2.min_x);
    smartExit.p2.max_x = milliToMeter(fvSmartExit.p2.max_x);
    smartExit.p2.min_y = milliToMeter(fvSmartExit.p2.min_y);
    smartExit.p2.max_y = milliToMeter(fvSmartExit.p2.max_y);

    // Gripper
    const gripper = strategy.data.gripper;
    const fvGripper = fieldValues.gripper;
    gripper.min_grip_quality = fvGripper.min_grip_quality;
    gripper.grip_rot = fvGripper.grip_rot;
    gripper.max_grip = fvGripper.max_grip;
    gripper.foam_height = milliToMeter(fvGripper.foam_height);
    gripper.length_coverage = percentToFrac(fvGripper.length_coverage);
    gripper.grip_rot_cost = fvGripper.grip_rot_cost;
    gripper.align_to_edges = fvGripper.align_to_edges;
    return strategy;
  }

  /**
   * @desc Creates the form group and form fields for the pally program setting form.
   * @method createFormFields
   * @param {IApiPallyPathStrategy} strategy
   */
  private createFormFields(strategy?: IApiPallyPathStrategy): void {
    const pp = strategy?.data.path_planning;
    const se = pp?.smart_exit;
    const g = strategy?.data.gripper;

    this.formGroup = this.formBuilder.group({
      name: [strategy.name, Validators.required],
      path_planning: this.formBuilder.group({
        above_pickup: [
          meterToMilli(pp.above_pickup),
          [Validators.required, Validators.min(0)],
        ],
        box_free: [
          meterToMilli(pp.box_free),
          [Validators.required, Validators.min(0)],
        ],
        box_free_auto: [pp.box_free_auto, [Validators.required]],
        smart_exit: this.formBuilder.group({
          smart_exit_preset: [ConveyorDirection.FRONT, Validators.required],
          p1: this.formBuilder.group({
            min_x: [meterToMilli(se.p1.min_x), Validators.required],
            max_x: [meterToMilli(se.p1.max_x), Validators.required],
            min_y: [meterToMilli(se.p1.min_y), Validators.required],
            max_y: [meterToMilli(se.p1.max_y), Validators.required],
          }),
          p2: this.formBuilder.group({
            min_x: [meterToMilli(se.p2.min_x), Validators.required],
            max_x: [meterToMilli(se.p2.max_x), Validators.required],
            min_y: [meterToMilli(se.p2.min_y), Validators.required],
            max_y: [meterToMilli(se.p2.max_y), Validators.required],
          }),
        }),
        pallet_edge: [
          meterToMilli(pp.pallet_edge),
          [Validators.required, Validators.min(0)],
        ],
        approach: [
          meterToMilli(pp.approach),
          [Validators.required, Validators.min(0)],
        ],
        approach_auto: [pp.approach_auto, [Validators.required]],
        high_approach_boost: [
          pp.high_approach_boost,
          [Validators.required, Validators.min(0), Validators.max(100)],
        ],
        quick_return: [pp.quick_return, [Validators.required]],
      }),
      gripper: this.formBuilder.group({
        min_grip_quality: [
          g.min_grip_quality,
          [Validators.required, Validators.min(0)],
        ],
        grip_rot: [
          g.grip_rot,
          [Validators.required, Validators.pattern(/^(0|2|4)$/)],
        ],
        max_grip: [g.max_grip, [Validators.required]],
        foam_height: [meterToMilli(g.foam_height), [Validators.required]],
        length_coverage: [
          fracToPercent(g.length_coverage),
          [Validators.required, Validators.min(0)],
        ],
        grip_rot_cost: [
          g.grip_rot_cost,
          [
            Validators.required,
            Validators.minLength(4),
            Validators.maxLength(4),
          ],
        ],
        align_to_edges: [g.align_to_edges, [Validators.required]],
      }),
    }) as unknown as IPallyPathStrategyFormGroup;

    // Create the grip rot cost array form group.
    this.makeGripRotCostArrayFormGroup(
      this.formGroup.get('gripper').get('grip_rot_cost') as FormControl<
        number[]
      >,
      g.grip_rot_cost
    );
  }

  public makeGripRotCostArrayFormGroup(
    parentFormControl: FormControl<number[]>,
    array: number[]
  ): void {
    this.gripRotCostFormGroup = this.formBuilder.group({
      '0': [array[0], [Validators.required, Validators.min(0)]],
      '1': [array[1], [Validators.required, Validators.min(0)]],
      '2': [array[2], [Validators.required, Validators.min(0)]],
      '3': [array[3], [Validators.required, Validators.min(0)]],
    }) as unknown as ArrayFieldFormGroup<number>;

    // Transfer changes to parent form control.
    this.gripRotCostFormGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((value: number[]) => {
        const array = parentFormControl.value;
        for (let i = 0; i < 4; i++) {
          array[i] = value[i];
        }
        parentFormControl.setValue(array);
      });
  }

  /** Closes the dialog and emits a null value through the saveStrategy event emitter. */
  public closeDialog(): void {
    this.dialogEvent.emit(null);
  }

  /**
   * @method goBack
   * @desc Navigates back to the flow's inventory page.
   */
  public goBack(): void {
    this.router.navigate([pagesPATH.PALLY_PATH_STRATEGIES]);
  }
}
