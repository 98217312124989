import { Injectable, OnDestroy } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { gql, MutationResult, QueryRef } from 'apollo-angular';
import { combineLatest, Observable, of, Subject } from 'rxjs';
import { map, skipWhile, switchMap, take } from 'rxjs/operators';
import { compareItem } from 'src/app/components/inventory/compare-items/compare-items.component';
import { SimulationReport } from 'src/app/models_new/classes/api-models/simulation_report';
import { ISimulationDetails } from 'src/app/models_new/types/simulation-details';
import { IApiProductionLine } from '../../models_new/classes/api-models/ApiProduction_line';
import { ApiSimulation } from '../../models_new/classes/api-models/ApiSimulation';
import {
  SimulationStatus,
  simulationStatuses,
} from '../../models_new/enums/simulation-status';
import { ObjectUtils } from '../../utils/object';
import { ErrorHandlerService } from '../error-handler.service';
import {
  ApiService,
  IInsertSimulationResponse,
  IStartSimulation,
} from './api.service';
import { ClientApiService } from './client-api.service';
import { ISimulationRetry } from 'src/app/components/simulation/simulation-retry/simulation-retry.component';
import { AssetApiService, IUploadAsset } from './asset-api.service';
import {
  ISceneApiFileType,
  ISimulationApiEditedData,
  IStrategyApiFileType,
} from 'src/app/models_new/types/simulation-api-file-type';
import { LocalStorageService } from '../local-storage.service';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { IPallyFileType } from 'src/app/models_new/types/pally-file-type';

export interface ISimulationTableItem {
  id: string;
  name: string;
  nameSlice?: string;
  created_at: string;
  updated_at: string;
  simulation_state: SimulationStatus;
  simulation_status: ApiSimulation['simulation_status'];
  inventoryCpmCalc?: string;
  error_details: string;
  patterns?: {
    id: string;
    name: string;
    data: any;
  }[];
  pattern?: {
    id: string;
    name: string;
    data: any;
  };
  second_pattern?: {
    id: string;
    name: string;
    data: any;
  };
  cpm_requested: number;
  scene: {
    name: string;
    id: string;
    image: {
      id: string;
      url: string;
    };
  };
  strategy: {
    name: string;
    id: string;
  };
  metadata?: {
    unitSystem: string;
  };
}

export interface SimulationInfo {
  name: string;
  pattern_id: string;
  second_pattern_id?: string; // Dual product mode
  scene_id: string;
  strategy_id: string;
  simulation_state?: SimulationStatus;
  palletizing_project_id: string;
  owner_id?: string;
  metadata: {
    unitSystem: string;
  };
}

export interface IArtifactsUrl {
  bag: string;
  urdf: string;
  report?: string;
  sim_urp?: string;
  sim_installation?: string;
  robot_urp?: string;
  robot_installation?: string;
}

export interface ISimulationShare {
  id: string;
  valid_to: string;
  artifact_urls: IArtifactsUrl;
}

declare namespace Intl {
  class ListFormat {
    public constructor(locale?: string);
    public format: (items: string[]) => string;
  }
}

const GET_PUBLIC_SIM_SHARE = gql`
  query getPublicSimulationShare($simulation_id: uuid!) {
    simulation_share(where: { simulation_id: { _eq: $simulation_id } }) {
      id
      valid_to
      artifact_urls
    }
  }
`;

const GET_OPEN_SIM = gql`
  subscription getSimulationDetailsOpen($simulation_id: uuid!) {
    open_simulation_by_pk(id: $simulation_id) {
      static_report_data
      simulation_state
      simulation_status
    }
  }
`;

const GET_SIM_REPORT_PUBLIC = gql`
  subscription getSimulationDetailsPublic($simulation_id: uuid!) {
    simulation_by_pk(id: $simulation_id) {
      static_report_data
      metadata
    }
  }
`;

const GET_SIM_REPORT = gql`
  subscription getSimulationDetails($simulation_id: uuid!) {
    simulation_by_pk(id: $simulation_id) {
      id
      static_report_data
      simulation_state
      created_at
      pattern {
        updated_at
        product {
          updated_at
        }
      }
      hw: scene {
        updated_at
      }
      sw: strategy {
        updated_at
      }
      artifact_urls
      simulation_status
      metadata
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class SimulationApiService implements OnDestroy {
  publicSimulationSharedQuery: QueryRef<{
    simulation_share: ISimulationShare[];
  }>;

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private clientApi: ClientApiService,
    private assetApi: AssetApiService,
    private apiService: ApiService,
    private errorHandler: ErrorHandlerService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  /* ------------------------ Database query functions ------------------------ */

  fetchSimulationFilesByID(
    id: string,
    isPublic?: boolean,
    expiration?: number //ms
  ): Observable<any> {
    const expirationDays = expiration
      ? expiration / (1000 * 3600 * 24)
      : // 3 months as default expiration
        31 * 3;

    const q = gql`
      query GetArtifactsUrls($id: String!, $expiration: Int!) {
        getArtifactsUrls(simulationId: $id, expiration_days: $expiration) {
          files
        }
      }
    `;

    const pq = gql`
      query GetArtifactsUrlsPublic($id: String!, $expiration: Int!) {
        getPublicArtifactsUrls(
          simulationId: $id
          expiration_days: $expiration
        ) {
          files
        }
      }
    `;

    const variables = {
      id: id,
      expiration: expirationDays,
    };

    return this.clientApi
      .useClient(isPublic ? 'public' : 'org_view')
      .watchQuery<any>({
        query: isPublic ? pq : q,
        variables: variables,
      })
      .valueChanges.pipe(
        map((data) => {
          if (!data || data.errors?.length) {
            console.error(
              `GetArtifactsUrls failed: ${data?.errors[0]?.message}`
            );
            return null;
          } else {
            return isPublic
              ? data.data?.getPublicArtifactsUrls.files
              : data.data?.getArtifactsUrls.files;
          }
        })
      );
  }

  fetchApiSimulations(
    organizationId: string = null
  ): Observable<ApiSimulation[]> {
    const q = gql`
      subscription {
        simulation {
          id
          name
          created_at
          organization_id
          owner_id
          pattern_id
          palletizing_project_id
          scene_id
          strategy_id
        }
      }
    `;
    const qf = gql`
      subscription {
        simulation(where: {organization_id: {_eq: "${organizationId}"}}) {
          id
          name
          created_at
          organization_id
          owner_id
          pattern_id
          palletizing_project_id
          scene_id
          strategy_id
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: organizationId ? qf : q,
      })
      .pipe(
        map((data) => {
          const list = [];
          for (const s of data.data.simulation) {
            list.push(new ApiSimulation(ObjectUtils.cloneObject(s)));
          }
          return list;
        })
      );
  }

  fetchApiSimulationsTable(
    organizationId: string
  ): Observable<ISimulationTableItem[]> {
    const q = gql`
        subscription SimulationsTableItems {
          simulation(where: {organization_id: {_eq: "${organizationId}"}}) {
            id
            name
            created_at
            updated_at
            scene {
              id
              name
              image {
                id
                url
              }
            }
            strategy {
              id
              name
            }
            simulation_state
            simulation_status
            error_details
            pattern {
              id
              name
              product {
                name
              }
              data
            }
            second_pattern{
              id
              name
              product {
                name
              }
              data
            }
            cpm_requested: static_report_data(path: "$pattern['data']['cpm']")
          }
        }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            throw new Error(data.errors[0].message);
          } else {
            return data.data.simulation;
          }
        })
      );
  }

  fetchSimulationIdsWithoutEditedPatternSceneOrStrategy(
    organizationId: string
  ): Observable<string[]> {
    const q = gql`
      subscription SimulationIdsWithEditedPatternSceneOrStrategy {
        simulation(where: {
          _and: {
            organization_id: {
              _eq: "${organizationId}"
            },
            _or: {
              _or: {
                _or: {
                  edited_scene: {
                    _is_null: true
                  }
                },
                edited_pattern: {
                  _is_null: true
                }
              },
              edited_strategy: {
                _is_null: true
              }
            }
          }
        }) {
          id
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.errors[0]);
            throw new Error(data.errors[0].message);
          } else {
            return data.data.simulation.map((simulation: any) => simulation.id);
          }
        })
      );
  }

  fetchProdLineInSimulation(id: string): Observable<IApiProductionLine> {
    const q = gql`
      query getProdLineInSimulation($id: uuid!) {
        production_line(
          where: {
            production_line_palletizing_projects: {
              palletizing_project: { simulations: { id: { _eq: $id } } }
            }
          }
        ) {
          id
          name
        }
      }
    `;

    const variables = {
      id: id,
    };
    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
        variables: variables,
      })
      .valueChanges.pipe(map((data) => data.data?.production_line[0]));
  }

  getSimulationPathModelConfig(): Observable<compareItem['content'][]> {
    const q = gql`
      query getSimulationCompareConfig {
        simulationReportConfigCollection {
          items {
            config
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.error) {
            this.errorHandler.handleError(data.errors[0]);
            throw new Error(data.errors[0].message);
          } else {
            return ObjectUtils.cloneObject(
              data.data.simulationReportConfigCollection.items[0].config
            );
          }
        })
      );
  }

  fetchEditedSceneStrategyAndPatternForSimulationWithId(
    simulationId: string
  ): Observable<ISimulationApiEditedData> {
    const q = gql`
      query FetchEditedSceneStrategyAndPatternForSimulationWithId {
        simulation_by_pk(id: "${simulationId}") {
          name
          editedPattern: edited_pattern
          editedScene: edited_scene
          editedStrategy: edited_strategy
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.error) {
            this.errorHandler.handleError(data.errors[0]);
            throw new Error(data.errors[0].message);
          } else {
            return data.data.simulation_by_pk as ISimulationApiEditedData;
          }
        })
      );
  }

  getSimulationsCompare(ids: string[]): Observable<ISimulationDetails[]> {
    const q = gql`
      query getSimulationsCompare($ids: [uuid!]) {
        simulation(where: { id: { _in: $ids } }) {
          static_report_data
          id
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
        variables: {
          ids: ids,
        },
      })
      .pipe(
        map((data) => {
          if (data.error) {
            this.errorHandler.handleError(data.errors[0]);
            throw new Error(data.errors[0].message);
          } else {
            const d = ObjectUtils.cloneObject(data.data.simulation);

            return d
              .filter((m) => {
                if (!m.static_report_data) {
                  this.errorHandler.handleError(
                    new Error('Simulation is missing data: ' + m.id)
                  );
                } else {
                  return m;
                }
              })
              .map((m) => m.static_report_data);
          }
        })
      );
  }

  /**
   * @param simulationId row id
   */
  fetchSimulationReport(simulationId: string): Observable<string> {
    return this.fetchSimulationFilesByID(simulationId).pipe(
      take(1),
      map((files) => {
        const lookUp = '_new_report.pdf';
        let simulationReport;

        for (const file in files) {
          if (file.includes(lookUp)) {
            simulationReport = files[file];
          }
        }

        return simulationReport ? simulationReport : null;
      })
    );
  }

  /**
   * @param simulation_id row id
   */
  regenerateReportFromSimReportData(simulation_id: string): Observable<string> {
    const q = gql`
      query getRegeneratedReportFromReportData {
        getSimulationReport(simulation_id: "${simulation_id}") {
          pdfBase64
        }
      }`;
    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
        // Caching causes problems if the user renames multiple times
        // after one another, disabled here to mitigate this.
        fetchPolicy: 'no-cache',
      })
      .valueChanges.pipe(
        map((data) => data.data?.getSimulationReport.pdfBase64)
      );
  }

  startSimulation(simulationId: string): Observable<IStartSimulation> {
    const props = {
      simulation_state: { value: 'Queued', isString: false },
    };

    return this.updateSimulation(simulationId, props, `simulation_state`);
  }

  insertSimulationRetry(
    simulation: {
      name: string;
      simulation_state: SimulationStatus;
      strategy_id: string;
      scene_id: string;
      pattern_id: string;
      organization_id: string;
      id: string;
    },
    patternData: IPallyFileType,
    sceneData: ISceneApiFileType,
    strategyData: IStrategyApiFileType
  ): Observable<any> {
    const m = gql`
      mutation insertSimulationRetry(
        $id: uuid!
        $name: String!
        $organization_id: uuid!
        $pattern_id: uuid!
        $scene_id: uuid!
        $strategy_id: uuid!
        $edited_pattern: json
        $edited_scene: json
        $edited_strategy: json
        $metadata: json 
      ) {
        insert_simulation_one(
          object: {
            name: $name
            organization_id: $organization_id
            simulation_state: ${simulation.simulation_state}
            origin_simulation_id: $id
            scene_id: $scene_id
            pattern_id: $pattern_id
            strategy_id: $strategy_id
            edited_strategy: $edited_strategy
            edited_scene: $edited_scene
            edited_pattern: $edited_pattern
            metadata: $metadata
          }
        ) {
          id
        }
      }
    `;

    let variables = {
      // Original Simulation Data:
      id: simulation.id,
      name: simulation.name,
      organization_id: simulation.organization_id,
      pattern_id: simulation.pattern_id,
      scene_id: simulation.scene_id,
      strategy_id: simulation.strategy_id,
      // Edited fields:
      edited_pattern: patternData,
      edited_scene: sceneData,
      edited_strategy: strategyData,
      metadata: {
        unitSystem:
          this.localStorageService.getData(
            LocalStorageKey.PREFERRED_UNIT_SYSTEM
          ) || 'metric',
      },
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(
              'Simulation retry failed. ' + data.errors[0].message
            );
          }
          return data.data?.insert_simulation_one;
        })
      );
  }

  insertSimulation(properties: {
    [key: string]: { isString?: boolean; value: any };
  }): Observable<IInsertSimulationResponse> {
    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: this.apiService.makeInsertMutationQuery(
          'insert_simulation_one',
          properties
        ),
      })
      .pipe(map((data) => data.data.insert_simulation_one));
  }

  insertSimulationBatch(
    info: SimulationInfo[],
    org_id?: string
  ): Observable<any> {
    let str = '';
    // Run through each object to be added
    for (let i = 0; i < info.length; i++) {
      const obj = info[i];

      let objStr = '{';
      const keys = Object.keys(obj); // Run through properties of each objects
      for (let j = 0; j < keys.length; j++) {
        if (keys[j] === 'metadata') {
          objStr += `${keys[j]}:{unitSystem:"${obj[keys[j]]?.unitSystem}"}`;
        } else if (keys[j] !== 'simulation_state') {
          console.warn('sjdngbjiadngibnsdgb => ', keys[j], ' | ', obj[keys[j]]);
          if (obj[keys[j]] === null) {
            // null values should not be strings!
            objStr += `${keys[j]}:null`;
          } else if (typeof obj[keys[j]] === 'string') {
            objStr += `${keys[j]}:"${obj[keys[j]]}"`;
          }
        } else {
          // "simulation_state" is enum and should not be string
          objStr += `${keys[j]}:${obj[keys[j]]}`;
        }

        // More than one property and not the last property, append comma
        // OTHER WORDS, adds a comma separating properties if more than 1
        if (keys.length > 1 && j < keys.length - 1) {
          objStr += ',';
        }
      }

      // Append org_id to end of object if available
      if (org_id) {
        objStr += `,organization_id:"${org_id}"`;
      }

      // End object to be added
      objStr += '}';

      // More than one object and not last
      // OTHER WORDS, adds a comma separating objects if more than 1
      if (info.length > 1 && i < info.length - 1) {
        objStr += ',';
      }

      // Append object to list
      str += objStr;
      str += '\n';
    }

    const m = gql`
    mutation InsertSimulation {
      insert_simulation(objects: [${str}]) {
        affected_rows
        returning {
          id
          organization_id
        }
      }
    }
    `;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
      })
      .pipe(
        map((value) => {
          if (value.errors) {
            this.errorHandler.handleError(value.errors[0]);
          } else {
            return value.data.insert_simulation;
          }
        })
      );
  }

  updateSimulation(
    simID: string,
    properties: { [key: string]: { isString?: boolean; value: any } },
    returnValues?: string
  ): Observable<any> {
    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: this.apiService.makeUpdateMutationQuery(
          'update_simulation_by_pk',
          simID,
          properties,
          returnValues
        ),
      })
      .pipe(map((data) => data.data.update_simulation_by_pk));
  }

  updateSimulationStaticReportData(
    id: string,
    static_report_data: any
  ): Observable<MutationResult<{ id: string }>> {
    const m = gql`
      mutation updateSimulationStaticReportData(
        $id: uuid!
        $static_report_data: jsonb!
      ) {
        update_simulation_by_pk(
          pk_columns: { id: $id }
          _set: { static_report_data: $static_report_data }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          id,
          static_report_data,
        },
      })
      .pipe(map((data) => data.data.update_simulation_by_pk));
  }

  renameSimulation(id: string, name: string): Observable<any> {
    const q = gql`
    query getReportDataForRenamingSimulation {
      simulation_by_pk(id: "${id}") {
        static_report_data
      }
    }`;

    /**
     * NOTE: Coun't find a way to edit both static_report_data's name and
     * the simulation name in one query
     */
    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
        // Caching causes problems if the user renames multiple times
        // after one another, disabled here to mitigate this.
        fetchPolicy: 'no-cache',
      })
      .pipe(
        switchMap((data) => {
          const reportData = ObjectUtils.cloneObject(
            data.data.simulation_by_pk?.static_report_data
          );

          // Reportdata was missing, update the name of the simualtion and let back-end handle the rest.
          if (!reportData) {
            return this.clientApi.useClient('org_edit').mutate<any>({
              mutation: gql`
              mutation renameSimulation {
                update_simulation_by_pk(
                  pk_columns: { id: "${id}" },
                  _set: {
                    name: "${name}"
                  },
                ) {
                  id
                }
              }`,
            });

            // Static report data found, update name and feed it back.
          } else {
            reportData.name = name;

            return this.clientApi.useClient('org_edit').mutate<any>({
              mutation: gql`
              mutation renameSimulation($data: jsonb!) {
                update_simulation_by_pk(
                  pk_columns: { id: "${id}" },
                  _set: {
                    name: "${name}",
                    static_report_data: $data
                  },
                ) {
                  id
                }
              }`,
              variables: { data: reportData },
            });
          }
        }),
        map((data) => data.data.update_simulation_by_pk)
      );
  }

  deleteSimulationById(id: string): Observable<any> {
    const m = gql`
      mutation deleteSimulation($id: uuid!) {
        delete_simulation_by_pk(id: $id) {
          name
        }
      }
    `;

    const variables = {
      id: id,
    };

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
        variables: variables,
      })
      .pipe(map((data) => data.data.delete_simulation_by_pk));
  }

  getTotalNoOfSimulations(): Observable<any> {
    const q = gql`
      query getNumberOfSimulations {
        all: simulation_aggregate {
          aggregate {
            count
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          return [0, 50, data.data.all.aggregate.count];
        })
      );
  }

  getPublicSimulationShared(
    simulation_id: string
  ): Observable<ApolloQueryResult<{ simulation_share: ISimulationShare[] }>> {
    const variables = {
      simulation_id: simulation_id,
    };

    this.publicSimulationSharedQuery = this.clientApi
      .useClient('public')
      .watchQuery<{ simulation_share: ISimulationShare[] }>({
        query: GET_PUBLIC_SIM_SHARE,
        variables: variables,
      });

    return this.publicSimulationSharedQuery.valueChanges;
  }

  insertPublicSimulationShare(
    simulation_id: string,
    urls: { bag: string; urdf: string; report?: string },
    expiration_time?: number //ms
  ): Observable<
    MutationResult<
      ApolloQueryResult<{ insert_simulation_share_one: { id: string } }>
    >
  > {
    // 3 months expiration default
    const share_expiration = expiration_time
      ? new Date(Date.now() + expiration_time)
      : new Date(Date.now() + 31 * 3 * 24 * 3600000 + 3600000);

    const m = gql`
    mutation insertPublicSimulationShare($urls: jsonb!) {
      insert_simulation_share_one(object: {simulation_id: "${simulation_id}", valid_to: "${share_expiration.toISOString()}" share_type: public, model_tokens: {}, artifact_urls: $urls}) {
        id
      }
    }`;

    const variables = {
      simulation_id: simulation_id,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<
        ApolloQueryResult<{ insert_simulation_share_one: { id: string } }>
      >({
        mutation: m,
        variables: {
          urls: urls,
        },
        refetchQueries: [{ query: GET_PUBLIC_SIM_SHARE, variables: variables }],
      })
      .pipe(
        map((data) => {
          this.publicSimulationSharedQuery.refetch();
          if (data.errors) {
            throw new Error('Share link failed. ' + data.errors[0].message);
          }
          return data;
        })
      );
  }

  updatePublicSimulationShare(
    simulation_id: string,
    expiration_time?: number //ms
  ): Observable<
    MutationResult<
      ApolloQueryResult<{ update_simulation_share: { id: number } }>
    >
  > {
    const share_expiration = new Date(Date.now() + expiration_time);
    const m = gql`
    mutation updatePublicSimulationShare {
      update_simulation_share(where: {simulation_id: {_eq: "${simulation_id}"}}, _set: {valid_to: "${share_expiration.toISOString()}"}) {
        returning {
          id
        }
      }
    }`;

    const variables = {
      simulation_id: simulation_id,
    };

    return this.clientApi
      .useClient('org_edit')
      .mutate<
        ApolloQueryResult<{
          update_simulation_share: { id: number };
        }>
      >({
        mutation: m,
        refetchQueries: [{ query: GET_PUBLIC_SIM_SHARE, variables: variables }],
      })
      .pipe(
        map((data) => {
          this.publicSimulationSharedQuery.refetch();
          if (data.errors) {
            throw new Error('Share link failed. ' + data.errors[0].message);
          }
          return data;
        })
      );
  }

  deletePublicSimulationShare(simulation_id: string): Observable<string> {
    const m = gql`
    mutation deletePublicSimulationShare {
      delete_simulation_share(where: {simulation_id: {_eq: "${simulation_id}"}}) {
        returning {
          id
        }
      }
    }`;

    const variables = {
      simulation_id: simulation_id,
    };

    return this.clientApi
      .useClient('org_delete')
      .mutate<{ delete_simulation_share: { returning: { id: string }[] } }>({
        mutation: m,
        refetchQueries: [{ query: GET_PUBLIC_SIM_SHARE, variables: variables }],
      })
      .pipe(
        map((data) => {
          if (data.data.delete_simulation_share.returning.length) {
            this.publicSimulationSharedQuery.refetch();
            return data.data.delete_simulation_share.returning[0].id;
          } else {
            this.publicSimulationSharedQuery.refetch();
            throw new Error(
              'No public shares to delete for simulation: ' + simulation_id
            );
          }
        })
      );
  }

  findStaticReportData(data: any): any | null {
    let d;
    if (data?.data?.open_simulation_by_pk?.static_report_data) {
      d = data.data.open_simulation_by_pk?.static_report_data;
    } else if (data?.data?.simulation_by_pk?.static_report_data) {
      d = data.data.simulation_by_pk?.static_report_data;
    } else {
      d = null;
    }
    return d;
  }

  public getSimulationRetryData(id: string): Observable<ISimulationRetry> {
    const q = gql`
      query getSimulationDetailsDynamic {
        simulation_by_pk(id: "${id}") {
          id
          name
          error_details
          simulation_state
          simulation_status
          edited_scene
          edited_pattern
          edited_strategy
          pattern {
            id
            data
          }
          scene {
            id
            data
          }
          strategy {
            id
            data
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          }

          if (!data.data.simulation_by_pk) {
            throw new Error('Simulation not found');
          }

          return data.data.simulation_by_pk;
        })
      );
  }

  getSimulationDetails(
    simulationId: string,
    isPublic: boolean,
    isOpen: boolean = false
  ): Observable<ISimulationDetails> {
    // Get correct client
    let client = this.clientApi.useClient(
      isPublic || isOpen ? 'public' : 'org_view',
      'ws'
    );

    let query_name = 'getStaticReportDataForValidation';
    let operation_name = 'simulation_by_pk';

    // Correct quert for public of open simulations.
    if (isOpen) {
      query_name = 'getStaticReportDataForValidationOpen';
      operation_name = 'open_simulation_by_pk';
    } else if (isPublic) {
      query_name = 'getStaticReportDataForValidationPublic';
    }
    const vq = gql`
      query ${query_name} {
      ${operation_name}(id: "${simulationId}") {
        static_report_data
      }
    }`;

    return client
      .watchQuery<any>({
        query: vq,
      })
      .valueChanges.pipe(
        switchMap(() => {
          return client.subscribe<any>({
            query: isOpen
              ? GET_OPEN_SIM
              : isPublic
              ? GET_SIM_REPORT_PUBLIC
              : GET_SIM_REPORT,
            variables: {
              simulation_id: simulationId,
            },
          });
        }),
        skipWhile(
          (data: any) =>
            data?.data?.open_simulation_by_pk?.simulation_state ===
              SimulationStatus.QUEUED ||
            data?.data?.open_simulation_by_pk?.simulation_state ===
              SimulationStatus.STARTING_ROBOT ||
            data?.data?.open_simulation_by_pk?.simulation_state ===
              SimulationStatus.SETTING_UP ||
            data?.data?.simulation_by_pk?.simulation_state ===
              SimulationStatus.QUEUED ||
            data?.data?.simulation_by_pk?.simulation_state ===
              SimulationStatus.STARTING_ROBOT ||
            data?.data?.simulation_by_pk?.simulation_state ===
              SimulationStatus.SETTING_UP
        ),
        map((data: any) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return {
              data: this.findStaticReportData(data),
              full:
                data?.data?.open_simulation_by_pk ||
                data?.data?.simulation_by_pk,
            };
          }
        }),
        switchMap(({ data, full }) => {
          if (Array.isArray(data) || !data || Object.keys(data).length === 0) {
            // Fallback if static_data is not
            if (!isOpen) {
              return combineLatest([
                this.clientApi.useClient('public', 'ws').subscribe<any>({
                  query: GET_SIM_REPORT_PUBLIC,
                  variables: {
                    simulation_id: simulationId,
                  },
                }),
                of(full),
              ]);
            } else {
              throw new Error(
                'Shared simulation is missing data. It might be an old simulation'
              );
            }
          } else {
            return combineLatest([of(data), of(full)]);
          }
        }),
        switchMap(([data, full]) => {
          if (!full && !data?.simulation_state) {
            full = data?.data?.simulation_by_pk;
            data = this.findStaticReportData(data);
          }
          if (!full && !data) {
            throw new Error('This simulation may have expired.');
          }
          // Check if children have been updated since simulation creation.

          if (!isOpen) {
            const simCreated = Date.parse(full.created_at);
            const prodUpdated = Date.parse(full.pattern?.product?.updated_at);
            const patternUpdated = Date.parse(full.pattern?.updated_at);
            const hwUpdated = Date.parse(full.hw?.updated_at);
            const swUpdated = Date.parse(full.sw?.updated_at);

            if (
              simCreated &&
              prodUpdated &&
              patternUpdated &&
              hwUpdated &&
              swUpdated
            ) {
              const message: string[] = [];
              if (prodUpdated > simCreated) {
                message.push('Product');
              }
              if (patternUpdated > simCreated) {
                message.push('Pattern');
              }
              if (hwUpdated > simCreated) {
                message.push('Hardware');
              }
              if (swUpdated > simCreated) {
                message.push('Software');
              }
              const formatter = new Intl.ListFormat('en');
              data.updatedMessage = message.length
                ? formatter.format(message) +
                  ' has been updated since the simulation was created'
                : null;
            }
            if (!data?.artifact_urls) {
              data.artifact_urls = full.artifact_urls;
            }
            if (!data?.metadata) {
              data.metadata = full.metadata;
            }
            if (!data?.id) {
              data.id = full.id;
            }
          } else {
            const unitSystem = data.pattern?.data?.guiSettings?.units || null;
            if (!data?.metadata) {
              data.metadata = {
                unitSystem: unitSystem,
              };
            } else if (!data.metadata.unitSystem) {
              data.metadata.unitSystem = unitSystem;
            }
          }

          if (data && !data?.valid_to) {
            const v = new Date();
            v.setMonth(v.getMonth() + 1);
            data.valid_to = v.toISOString();
          }

          // Skip if open or public mode.
          if (
            !(isPublic || isOpen) &&
            data.organization?.assets?.length > 0 &&
            data.organization?.assets[0]?.url
          ) {
            const logoUrl = new URL(
              decodeURIComponent(data.organization?.assets[0]?.url)
            );
            // Logo url is missing OR missing SAS token
            // AND full sim data is available.
            if (
              (!logoUrl ||
                !(
                  logoUrl.search.includes('sv=') &&
                  logoUrl.search.includes('sig=') &&
                  logoUrl.search.includes('se=')
                )) &&
              full
            ) {
              const pathSegs = logoUrl.pathname.split('/');
              const fullFilename = pathSegs[pathSegs.length - 1];
              const filenameTerminatorIndex = fullFilename.lastIndexOf('.');
              const file_name = fullFilename.slice(0, filenameTerminatorIndex);
              const file_type = fullFilename.slice(filenameTerminatorIndex);

              // Update the asset to fix the missing SAS token issue.
              return this.assetApi
                .updateAsset({
                  id: data.organization?.assets[0]?.id,
                  file_type: file_type,
                  name: file_name,
                })
                .pipe(
                  // Then update the static_simulation_report organization assets
                  // to complete the update.
                  switchMap((assetData: IUploadAsset) => {
                    const asset =
                      full.static_report_data.organization.assets.find(
                        (a) => a.id === assetData?.id
                      );
                    asset.url = assetData?.url;
                    return this.updateSimulationStaticReportData(
                      simulationId,
                      full.static_report_data
                    );
                  }),
                  // Continue on with the flow.
                  map(() => {
                    return data;
                  })
                );
            }
          }
          return of(data);
        }),
        switchMap((data: any) => {
          const isSimStarting = simulationStatuses.notStarted.includes(
            data.simulation_state
          );
          if (isSimStarting || data?.artifact_urls || isOpen) {
            return combineLatest([
              of(data.artifact_urls),
              of(data),
              of(isSimStarting),
            ]);
          } else if (
            simulationStatuses.inProgress.includes(data.simulation_state)
          ) {
            return combineLatest([of(data.artifact_urls), of(data)]);
          } else {
            if (!isPublic) {
              return combineLatest([
                this.getPublicSimulationShared(data.id).pipe(
                  map(
                    (shares: any) =>
                      shares.data.simulation_share[0]?.artifact_urls
                  )
                ),
                of(data),
              ]);
            } else {
              return combineLatest([
                this.getPublicSimulationShared(data.id).pipe(
                  map(
                    (shares: any) =>
                      shares.data.simulation_share[0]?.artifact_urls
                  )
                ),
                of(data),
              ]);
            }
          }
        }),
        map(([artifacts, data, isSimStarting]) => {
          // TODO: fix the namin on artifacts AND this is hacky AF!
          if (!isSimStarting && !isOpen) {
            if (artifacts) {
              let a = ObjectUtils.cloneObject(artifacts);
              a.bag = artifacts.bag || artifacts['simulation_recording.bag'];
              a.urdf = artifacts.urdf || artifacts['model.urdf'];
              if (!data.artifact_urls) data.artifact_urls = a;
              if (!data.artifactsURL) data.artifactsURL = a;
            }
          }
          return data;
        }),
        map((data: any) => {
          const d: ISimulationDetails = ObjectUtils.cloneObject(data);

          if (d.hw.updated_at) {
            delete d.hw.updated_at;
          }
          if (d.sw.updated_at) {
            delete d.sw.updated_at;
          }

          try {
            const dn = new SimulationReport(d, isOpen).createAll();
            return dn.srd;
          } catch (error) {
            console.error(error);
            throw new Error(error);
          }
        })
      );
  }
}
