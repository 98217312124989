import { Component } from '@angular/core';
import {
  combineLatest,
  firstValueFrom,
  map,
  Observable,
  of,
  shareReplay,
  switchMap,
  take,
} from 'rxjs';
import { toScene } from 'src/app/services/robot-config-helper';
import { SimConfigService } from 'src/app/services/sim-config.service';
import { saveAs } from 'file-saver';
import { ActivatedRoute } from '@angular/router';
import { reduce } from 'lodash-es';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { Field } from 'src/app/models_new/classes/field';
import { ApiRobotConfiguration } from 'src/app/models_new/classes/api-models/ApiRobotConfiguration';
import { StateService } from 'src/app/auth/state.service';
import { RobotConfigApiService } from 'src/app/services/api/robot-config-api.service';

export interface StepSummary {
  header: string;
  fields: Field[];
}

export interface Summary {
  scene: StepSummary[];
  strategy: StepSummary[];
}
@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.scss'],
})
export class SummaryComponent {
  summary$: Observable<Summary>;
  robotConfiguration$: Observable<ApiRobotConfiguration>;
  constructor(
    private simConfigService: SimConfigService,
    private activatedRoute: ActivatedRoute,
    private route: ActivatedRoute,
    private stateService: StateService,
    private robotConfigurationService: RobotConfigApiService
  ) {
    this.summary$ = this.simConfigService.getFields().pipe(
      switchMap((fields) =>
        of(
          reduce(
            this.activatedRoute.parent.snapshot.routeConfig.children,
            function (result, route, _key) {
              if (route.path.indexOf(pagesPATH.ADVANCED_CONFIG_HARDWARE) > -1) {
                result.scene.push({
                  header: route.data?.header,
                  fields: fields.filter((field) =>
                    route.data?.simConfigFieldIds.includes(field.id)
                  ),
                });
              } else if (
                route.path.indexOf(pagesPATH.ADVANCED_CONFIG_STRATEGY) > -1
              ) {
                result.strategy.push({
                  header: route.data?.header,
                  fields: fields.filter((field) =>
                    route.data?.simConfigFieldIds.includes(field.id)
                  ),
                });
              }
              return result;
            },
            { scene: [], strategy: [] }
          ) as Summary
        )
      )
    );

    // TODO PALLY-2342 remove/simplify workaround after bugfix
    this.robotConfiguration$ = combineLatest([
      this.route.parent.params,
      this.stateService.getCustomerOrSalesOrganization().pipe(take(1)),
    ]).pipe(
      switchMap(([params, organization]) =>
        this.robotConfigurationService
          .fetchRobotconfigs(organization.id)
          .pipe(
            map((robotConfigs) =>
              robotConfigs.find(
                (config) => config.id === params.robotConfigurationId
              )
            )
          )
      ),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  async exportScene() {
    const sceneId = await firstValueFrom(
      this.robotConfiguration$.pipe(map((rConfig) => rConfig.scene.id))
    );

    const scene = await firstValueFrom(
      this.simConfigService.getFields().pipe(map((scene) => toScene(scene)))
    );
    const blob = new Blob([JSON.stringify(scene, null, 4)], {
      type: 'text/json;charset=utf-8',
    });
    saveAs(blob, `hardware-${sceneId}.json`);
  }

  async exportStrategy() {
    const blob = new Blob(['{}'], {
      type: 'text/json;charset=utf-8',
    });

    const strategyId = await firstValueFrom(
      this.robotConfiguration$.pipe(map((rConfig) => rConfig.strategy.id))
    );

    // TODO export strategy when fields are ready
    saveAs(blob, `strategy-${strategyId}-not-implemented.json`);
  }

  async exportSimulationConfig() {
    const blob = new Blob(['{}'], {
      type: 'text/json;charset=utf-8',
    });
    // TODO
    //saveAs(blob, `simulation-${id}.json`);
    saveAs(blob, `simulation-not-implemented.json`);
  }
}
