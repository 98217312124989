import { Injector } from '@angular/core';
import * as THREE from 'three';
import {
  URDFJoint,
  URDFLink,
  URDFRobot,
  URDFVisual,
} from '@rocketfarm/urdf-loader';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PartType } from 'src/app/models_new/enums/sim-config-part-type';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { Type } from 'src/app/utils/type';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { milliToMeter } from '../../../../utils/div';
import { DimensionVisualizer } from '../../3dview/timed-objects/dimension-visualizer';
import { ThreeUtils } from 'src/app/utils/three-utils';

export class ConveyorTask extends Task {
  static [taskNameSymbol] = 'Conveyor';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  private isTypeFieldIds(id: SimConfigFieldIds): boolean {
    return (
      id === SimConfigFieldIds.ConveyorPrimaryType ||
      id === SimConfigFieldIds.ConveyorSecondaryType
    );
  }
  private isPositionFieldIds(id: SimConfigFieldIds): boolean {
    return (
      id === SimConfigFieldIds.ConveyorPrimaryPositionX ||
      id === SimConfigFieldIds.ConveyorPrimaryPositionY ||
      id === SimConfigFieldIds.ConveyorPrimaryPositionZ ||
      id === SimConfigFieldIds.ConveyorSecondaryPositionX ||
      id === SimConfigFieldIds.ConveyorSecondaryPositionY ||
      id === SimConfigFieldIds.ConveyorSecondaryPositionZ
    );
  }

  public operation(resolve: () => void, _reject: (reason?: any) => void): void {
    const isPrimary = this.data.fieldId.includes('.0.'),
      dualProductMode = this.readDualProductMode(),
      type = this.readConveyorType(isPrimary),
      dimension = this.readConveyorDimension(isPrimary),
      offset = this.readConveyorOffset(isPrimary),
      rotation = this.readConveyorRotation(isPrimary),
      conveyorJoint = this.robot.getJointByID(
        isPrimary
          ? JointNames.WorldPrimaryConveyor
          : JointNames.WorldSecondaryConveyor
      ),
      conveyorLink = URDFUtils.findLinkFromJoint(conveyorJoint),
      conveyorVisual = URDFUtils.findVisualFromJoint(conveyorJoint);

    if (this.isTypeFieldIds(this.data.fieldId)) {
      this.updateConveyorType(
        resolve,
        conveyorJoint,
        conveyorLink,
        conveyorVisual,
        isPrimary,
        type,
        dimension,
        offset,
        rotation
      );
    } else if (
      this.data.fieldId === SimConfigFieldIds.DualProductMode &&
      !dualProductMode
    ) {
      // Clear secondary conveyor if dual product mode is disabled
      ThreeUtils.disposeObject(conveyorVisual.children);
      conveyorVisual.children = [];
    } else {
      // Assume some property got updated
      this.updateConveyorProperties(
        conveyorJoint,
        conveyorLink,
        conveyorVisual,
        isPrimary,
        dimension,
        offset,
        rotation
      );
    }

    this.handleDimensionVisualizers(dualProductMode, offset);

    if (
      this.data.fieldId !== SimConfigFieldIds.ConveyorPrimaryType ||
      this.data.fieldId !== SimConfigFieldIds.ConveyorSecondaryType ||
      this.isCustomConveyor(isPrimary)
    ) {
      resolve();
    }
  }

  public updateConveyorType(
    resolve: () => void,
    conveyorJoint: URDFJoint,
    conveyorLink: URDFLink,
    conveyorVisual: URDFVisual,
    isPrimary: boolean,
    type: string,
    dimension: THREE.Vector3,
    offset: THREE.Vector3,
    rotation: THREE.Quaternion
  ): void {
    if (type === 'CUSTOM') {
      this.updateConveyorProperties(
        conveyorJoint,
        conveyorLink,
        conveyorVisual,
        isPrimary,
        dimension,
        offset,
        rotation
      );

      conveyorVisual.children = [];
      const conveyorGeo = new THREE.BoxGeometry(1, 1, 1);
      const conveyorMat = new THREE.MeshLambertMaterial({ color: '#B6B6B6' });
      const conveyorMesh = new THREE.Mesh(conveyorGeo, conveyorMat);
      conveyorMesh.name = 'CUSTOM';
      conveyorVisual.add(conveyorMesh);

      resolve();
    } else {
      this.partStoreService
        .getPart<URDFRobot>(HwPartUtils.getPartAssetID(PartType.CONVEYOR, type))
        .pipe(
          takeUntil(this.destroy$),
          RXJSUtils.filterUndefinedAndNull(),
          take(1)
        )
        .subscribe((part) => {
          const partJoint = part.getObjectByName('conveyor_joint') as URDFJoint;
          const partLink = URDFUtils.findLinkFromJoint(partJoint);
          const partVisual = URDFUtils.findVisualFromJoint(partJoint);

          ThreeUtils.copyTransform(conveyorJoint, partJoint);
          // Maintain user's position / offset
          conveyorJoint.position.copy(offset);

          ThreeUtils.copyTransform(conveyorLink, partLink);
          conveyorLink.quaternion.copy(rotation); // Maintain user's rotation

          ThreeUtils.copyTransform(conveyorVisual, partVisual);

          // Remove any previous model
          ThreeUtils.disposeObject(conveyorVisual.children);
          conveyorVisual.children = [];

          // Copy parts models.
          for (const child of partVisual.children) {
            conveyorVisual.add(URDFUtils.clone(child));
          }

          resolve();
        });
    }
  }

  protected updateConveyorProperties(
    conveyorJoint: URDFJoint,
    conveyorLink: URDFLink,
    conveyorVisual: URDFVisual,
    isPrimary: boolean,
    dimension: THREE.Vector3,
    offset: THREE.Vector3,
    rotation: THREE.Quaternion
  ): void {
    ThreeUtils.zeroTransform(conveyorJoint);
    conveyorJoint.position.copy(offset);

    ThreeUtils.zeroTransform(conveyorLink);
    conveyorLink.quaternion.copy(rotation);

    if (this.isCustomConveyor(isPrimary)) {
      conveyorVisual.position.set(0, dimension.y / 2, -(dimension.z / 2));
      conveyorVisual.rotation.set(0, 0, 0);
      conveyorVisual.scale.copy(dimension);
    }
  }

  protected handleDimensionVisualizers(
    dualProductMode: boolean,
    offset: THREE.Vector3
  ): void {
    // Only react on interesting fields, ignore others.
    if (!this.isPositionFieldIds(this.data.fieldId)) {
      return;
    }

    // Get the dimension visualizer for the field in question.
    const dimvis = this.toService.getTimedObject(
      this.data.fieldId
    ) as DimensionVisualizer;

    // Set default value to N/A.
    dimvis.setValue('N/A');

    // Override if field value is legal.
    const axis = this.data.fieldId.split('.').pop() ?? '';
    if (axis) {
      dimvis.setValue(offset[axis]);
    }

    // Flip offset direction for primary conveyor height in dual product mode
    dimvis.setOffsetAmount(
      dimvis.config.data.offset.amount *
        ((this.data.fieldId === SimConfigFieldIds.ConveyorPrimaryPositionZ ||
          this.data.fieldId === SimConfigFieldIds.ConveyorPrimaryPositionY) &&
        dualProductMode
          ? -1
          : 1)
    );

    // Activate the visualizer.
    dimvis.activate(
      this.readShowVisualizers() ? -1 : undefined,
      dimvis.config.notGlobal
    );
  }

  protected isCustomConveyor(isPrimary: boolean): boolean {
    return this.readConveyorType(isPrimary) === 'CUSTOM';
  }

  protected readConveyorType(isPrimary: boolean): string {
    return this.readField<any>(
      isPrimary
        ? SimConfigFieldIds.ConveyorPrimaryType
        : SimConfigFieldIds.ConveyorSecondaryType
    ).name;
  }

  protected readDualProductMode(): boolean {
    return this.readField<any>(SimConfigFieldIds.DualProductMode)
      ? true
      : false;
  }

  protected readConveyorDimension(
    isPrimary: boolean,
    dimension?: THREE.Vector3
  ): THREE.Vector3 {
    const tempDim: Array<any> = isPrimary
      ? this.readFields([
          SimConfigFieldIds.ConveyorPrimaryWidth,
          SimConfigFieldIds.ConveyorPrimaryLength,
          SimConfigFieldIds.ConveyorPrimaryHeight,
        ])
      : this.readFields([
          SimConfigFieldIds.ConveyorSecondaryWidth,
          SimConfigFieldIds.ConveyorSecondaryLength,
          SimConfigFieldIds.ConveyorSecondaryHeight,
        ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempDim.length; i++) {
      if (tempDim[i] === -0 || tempDim[i] === '-0') {
        tempDim[i] = 0;
      } else if (Type.isOfType(tempDim[i], 'string')) {
        tempDim[i] = +tempDim[i];
      }
    }

    const dim = new THREE.Vector3(
      milliToMeter(tempDim[0]),
      milliToMeter(tempDim[1]),
      milliToMeter(tempDim[2])
    );

    if (Type.isDefined(dimension)) {
      dimension.copy(dim);
    }

    return dim;
  }

  protected readConveyorOffset(
    isPrimary: boolean,
    offset?: THREE.Vector3
  ): THREE.Vector3 {
    const tempOffset: Array<any> = isPrimary
      ? this.readFields([
          SimConfigFieldIds.ConveyorPrimaryPositionX,
          SimConfigFieldIds.ConveyorPrimaryPositionY, // Conveyor distance from robot
          SimConfigFieldIds.ConveyorPrimaryPositionZ,
        ])
      : this.readFields([
          SimConfigFieldIds.ConveyorSecondaryPositionX,
          SimConfigFieldIds.ConveyorSecondaryPositionY, // Conveyor distance from robot
          SimConfigFieldIds.ConveyorSecondaryPositionZ,
        ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempOffset.length; i++) {
      if (tempOffset[i] === -0 || tempOffset[i] === '-0') {
        tempOffset[i] = 0;
      } else if (Type.isOfType(tempOffset[i], 'string')) {
        tempOffset[i] = +tempOffset[i];
      }
    }

    const o = new THREE.Vector3(
      milliToMeter(tempOffset[0]),
      milliToMeter(tempOffset[1]),
      milliToMeter(tempOffset[2])
    );

    if (Type.isDefined(offset)) {
      offset.copy(o);
    }

    return o;
  }

  protected readConveyorRotation(
    isPrimary: boolean,
    rotation?: THREE.Quaternion
  ): THREE.Quaternion {
    const tempRot: Array<any> = isPrimary
      ? this.readFields([
          SimConfigFieldIds.ConveyorPrimaryRotationX,
          SimConfigFieldIds.ConveyorPrimaryRotationY,
          SimConfigFieldIds.ConveyorPrimaryRotationZ,
          SimConfigFieldIds.ConveyorPrimaryRotationW,
        ])
      : this.readFields([
          SimConfigFieldIds.ConveyorSecondaryRotationX,
          SimConfigFieldIds.ConveyorSecondaryRotationY,
          SimConfigFieldIds.ConveyorSecondaryRotationZ,
          SimConfigFieldIds.ConveyorSecondaryRotationW,
        ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempRot.length; i++) {
      if (tempRot[i] === -0 || tempRot[i] === '-0') {
        tempRot[i] = 0;
      } else if (Type.isOfType(tempRot[i], 'string')) {
        tempRot[i] = +tempRot[i];
      }
    }

    const rot = new THREE.Quaternion(
      tempRot[0],
      tempRot[1],
      tempRot[2],
      tempRot[3]
    );

    if (Type.isDefined(rotation)) {
      rotation.copy(rot);
    }

    return rot;
  }
}
