import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { forkJoin, map, Observable, of, Subject } from 'rxjs';
import { filter, switchMap, take, takeUntil } from 'rxjs/operators';
import { ApiScene } from 'src/app/models_new/classes/api-models/ApiScene';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { HardwareApiService } from '../../../../services/api/hardware-api.service';
import { NotificationService } from '../../../../services/notification.service';
import { DataRequestState } from '../../../../data-request/model';
import { toRequestState } from '../../../../data-request/operators';
import { ObjectUtils } from 'src/app/utils/object';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';
import { SortDirection } from '@angular/material/sort';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { AssetApiService } from 'src/app/services/api/asset-api.service';
import {
  InventoryTableDisplayColumn,
  InvetoryTableAction,
} from 'src/app/components/gui/inventory-table/inventory-table.component';
import { ApiStrategy } from 'src/app/models_new/classes/api-models/ApiStrategy';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-inventory-hardware-wrapper',
  templateUrl: './inventory-hardwares.component.html',
  styleUrls: ['./inventory-hardwares.component.scss'],
})
export class InventoryHardwaresComponent implements OnInit, OnDestroy {
  hardwares$: Observable<DataRequestState<ApiScene[]>>;

  objUtil = ObjectUtils;
  blockSelected: ApiScene[] = [];
  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };
  destroy$: Subject<boolean> = new Subject<boolean>();

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'gripper',
      label: 'Gripper',
    },
    {
      id: 'base',
      label: 'Base',
    },
    {
      id: 'conveyor',
      label: 'Conveyor',
    },
    {
      id: 'lifting_column',
      label: 'Lifting Column',
    },
    {
      id: 'robot_name',
      label: 'Robot',
    },
  ];

  rowActions: InvetoryTableAction[] = [
    // {
    //   actionId: 'favourite',
    //   icon: 'star',
    //   tooltip: 'Favourite',
    //   color: 'primary',
    // },
    {
      label: 'Duplicate',
      roleAction: 'duplicate_hardware_configuration',
      actionId: 'duplicate_hardware_configuration',
      icon: 'content_copy',
      divideAfter: true,
    },
    {
      label: 'Delete',
      roleAction: 'delete_hardware_configuration',
      actionId: 'delete_hardware_configuration',
      icon: 'delete',
      color: 'warn',
    },
  ];

  displayedColumns: InventoryTableDisplayColumn[] = [
    {
      label: 'Name',
      path: 'name',
      sortType: 'string',
    },
    {
      label: 'Gripper',
      path: 'gripper',
      sortType: 'string',
    },
    {
      label: 'Base',
      path: 'base',
      sortType: 'string',
    },
    {
      label: 'Conveyor',
      path: 'conveyor',
      sortType: 'string',
    },
    {
      label: 'Lifting Column',
      path: 'lifting_column',
      sortType: 'string',
    },
    {
      label: 'Robot',
      path: 'robot_name',
      sortType: 'string',
    },
    {
      label: 'Robot Configurations',
      path: 'robot_configurations',
      sortType: 'string',
      specialCell: [
        {
          type: 'link',
          link: (e: { name: string; id: string }) => {
            return `/${pagesPATH.INVENTORY}/${pagesPATH.ROBOT_CONFIGURATIONS}/${e.id}`;
          },
        },
      ],
    },
    {
      label: 'Updated At',
      path: 'updated_at',
      sortType: 'date',
    },
  ];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private hardwareApi: HardwareApiService,
    private notifier: NotificationService,
    private assetApi: AssetApiService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.hardwares$ = this.route.data.pipe(
      take(1),
      switchMap((data: Data) =>
        this.hardwareApi.subscribeInventoryHardware(
          (data as IOrganizationContextResolverData).organization_id
        )
      ),
      map((hardwares: ApiScene[]) => {
        return hardwares.map((hardware) => {
          return {
            ...hardware,
            updated_at:
              this.datePipe.transform(
                hardware.updated_at,
                'dd/MM/yyyy HH:mm'
              ) || ('N/A' as any),
            // Gripper, base, conveyor, lifting_column: replace "_", to lowercase, and capitalize first letter
            gripper: hardware.gripper
              ?.replace(/_/g, ' ')
              .toLowerCase()
              .replace(/\b\w/g, (l) => l.toUpperCase()),
            base: hardware.base
              ?.replace(/_/g, ' ')
              .toLowerCase()
              .replace(/\b\w/g, (l) => l.toUpperCase()),
            conveyor: hardware.conveyor
              ?.replace(/_/g, ' ')
              .toLowerCase()
              .replace(/\b\w/g, (l) => l.toUpperCase()),
            lifting_column: hardware.lifting_column
              ?.replace(/_/g, ' ')
              .toLowerCase()
              .replace(/\b\w/g, (l) => l.toUpperCase()),
          };
        });
      }),
      toRequestState(),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  rowClicked(row: ApiStrategy) {
    this.navigateToHardware(row.id);
  }

  actionClicked(e: { action: InvetoryTableAction; row: ApiScene }) {
    // Favourite action
    // if (e.action.actionId === 'favourite') {
    //   e.row.favourite = !e.row.favourite;
    //   e.row.data.favourite = e.row.favourite;
    //   this.hardwareApi
    //     .updateScene(
    //       action.element.id,
    //       action.element.name,
    //       action.element.description,
    //       action.element.data
    //     )
    //     .subscribe();
    // }
    // Delete action
    if (e.action.actionId === 'delete_hardware_configuration') {
      this.onDelete([e.row]);
    }
    // Duplicate action
    if (e.action.actionId === 'duplicate_hardware_configuration') {
      this.onDuplicate(e.row);
    }
  }

  navigateToHardware(id: string): void {
    this.router.navigate(
      [pagesPATH.INVENTORY, pagesPATH.HARDWARE_CONFIGURATIONS, id],
      {
        queryParams: { edit: true },
      }
    );
  }

  onDuplicate(element: ApiScene) {
    this.hardwareApi
      .fetchHardwareByID(element.id)
      .pipe(
        take(1),
        switchMap((hardware: ApiScene) =>
          this.hardwareApi.insertScene(
            hardware.name + ' (Copy)',
            hardware.data,
            this.route.snapshot.data.organization_id
          )
        ),
        take(1)
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.notifier.showMessage(
              'The hardware configuration was duplicated successfully!'
            );
          }
        },
        error: (error) => {
          this.notifier.showError(error.message);
        },
      });
  }

  onDelete(elements: ApiScene[]) {
    this.notifier
      .deletePrompt(
        'Delete',
        'hardware configuration',
        elements.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap((_) => this.deleteHardwares(elements.map((m) => m.id)))
      )
      .subscribe({
        next: (res) => {
          if (res) {
            this.notifier.showMessage(
              'The hardwares were deleted successfully!'
            );
            this.blockSelected = [];
          }
        },
        error: (error) => {
          this.notifier.showError(error.message);
        },
      });
  }

  deleteHardwares(ids: string[]): Observable<any> {
    const operations = {};
    for (let i = 0; i < ids.length; i++) {
      operations[i] = this.hardwareApi.deleteSceneById(ids[i]).pipe(
        map((response) => {
          if (!response) {
            this.notifier.showError(
              'Could not delete the hardware configuration. Check that you have permission to delete them.'
            );
          }

          return response;
        }),
        switchMap((response) =>
          response.image
            ? this.assetApi.deleteAsset(response.image.id)
            : of(response)
        )
      );
    }
    return forkJoin(operations);
  }
}
