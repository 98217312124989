import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Layer } from 'src/app/models_new/classes/layer';
import { settings } from 'src/app/models_new/config/application-settings';
import { LayerApproach } from 'src/app/models_new/enums/layer-approach';
import { LayerType } from 'src/app/models_new/enums/layer-type';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { PalletPosition } from 'src/app/models_new/enums/pallet-position';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { WorkspaceService } from 'src/app/services/workspace.service';

@Component({
  selector: 'app-pallet-edit',
  templateUrl: './pallet-edit.component.html',
  styleUrls: ['./pallet-edit.component.scss'],
})
export class PalletEditComponent {
  settings = settings;
  layerTypes = LayerType;
  shimpaperHeights = new Array(150).fill(null).map((m, index) => index + 1);
  LayerApproach = LayerApproach;

  @Input() palletPosition: PalletPosition;
  @Input() panelWidth: number;
  @Input() activeLayers: Layer[];
  @Input() shimPaperInProject: boolean;

  @Output() editLayer: EventEmitter<string> = new EventEmitter();
  @Output() editAllOfLayer: EventEmitter<string> = new EventEmitter();
  @Output() duplicateLayer: EventEmitter<string> = new EventEmitter();
  @Output() removeLayer: EventEmitter<string> = new EventEmitter();
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() drop: EventEmitter<any> = new EventEmitter();
  @Output() editShimpaperHeight: EventEmitter<{
    layerId: string;
    height: number;
  }> = new EventEmitter();
  @Output() changeApproach: EventEmitter<{
    layerId: string;
    inverted: boolean;
  }> = new EventEmitter();
  isMetric: boolean;

  constructor(
    public workspaceService: WorkspaceService,
    private localStorageService: LocalStorageService
  ) {
    this.isMetric =
      this.localStorageService.getData(
        LocalStorageKey.PREFERRED_UNIT_SYSTEM
      ) === 'metric';
  }

  _getAlikeLayers(typeId: string) {
    return this.activeLayers.filter((f) => f.typeId === typeId);
  }
}
