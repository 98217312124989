import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  combineLatest,
  map,
  Observable,
  ReplaySubject,
  Subject,
  switchMap,
} from 'rxjs';
import { ThreeHandlerMode } from 'src/app/models_new/classes/3dview/three-handler-mode';
import { ApiScene } from 'src/app/models_new/classes/api-models/ApiScene';
import { SimConfigService } from 'src/app/services/sim-config.service';
import { ISplitCardTableData } from '../split-card-table/split-card-table.component';
import * as uuid from 'uuid';
import { HardwareApiService } from '../../../services/api/hardware-api.service';
import { HardwareUtils } from '../../../utils/hardware-utils';
import { meterToMilli } from '../../../utils/div';

@Component({
  selector: 'app-card-hardware-display',
  templateUrl: './card-hardware-display.component.html',
  styleUrls: ['./card-hardware-display.component.scss'],
})
export class CardHardwareDisplayComponent {
  @Input() set hwConfig(hw: ApiScene) {
    this.hwConfigSubject$.next(hw);
  }
  @Input() actions: {
    id: string;
    label: string;
  }[];
  @Output() actionClicked$ = new EventEmitter<string>();

  threeMode = ThreeHandlerMode.RobotConfig;
  threeId = uuid.v4();

  hwConfig$: Observable<ApiScene>;
  hwConfigSubject$: Subject<ApiScene> = new ReplaySubject<ApiScene>();
  tableData$: Observable<ISplitCardTableData>;
  updateModel$: Observable<any>;

  constructor(
    private simConfigService: SimConfigService,
    private hardwareApi: HardwareApiService
  ) {
    this.hwConfig$ = this.hwConfigSubject$.asObservable();

    this.updateModel$ = this.hwConfig$.pipe(
      switchMap((config) => {
        return this.simConfigService.setScene(config.data);
      })
    );

    this.tableData$ = combineLatest([
      this.hwConfig$,
      this.hardwareApi.fetchHwTypes(),
    ]).pipe(
      map(([config, hw_types]) => {
        HardwareUtils.labelifyScene(config.data, hw_types);
        return {
          overline: {
            type: 'Hardware Configuration',
            last_edit: config.updated_at,
          },
          name: config.name,
          content: [
            {
              header: 'Gripper',
              value: config.data.robot.gripper.label,
            },
            {
              header: 'Frame',
              value: config.data.robot.frame.label,
            },
            {
              header: 'Conveyor',
              value: config.data.conveyors[0].label,
            },
            {
              header: 'Lift Column',
              value: config.data.robot.lift_kit.label,
            },
            {
              header: 'Height Extender',
              value:
                meterToMilli(
                  parseFloat(
                    config.data.robot_mounting_height.position.z.toFixed(2)
                  )
                ) + ' mm',
            },
            {
              header: 'Robot',
              value: config.data.robot.label,
            },
          ],
        };
      })
    );
  }
}
