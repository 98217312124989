import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as THREE from 'three';
import { Project } from '../../../../../models_new/classes/project';
import { PalletPosition } from '../../../../../models_new/enums/pallet-position';
import { Pallet } from '../../../../../models_new/classes/pallet';
import { milliToMeter } from '../../../../../utils/div';
import { LabelOrientation } from '../../../../../models_new/enums/label-orientation';
import { settings } from '../../../../../models_new/config/application-settings';

@Component({
  selector: 'app-lite-pattern-view',
  templateUrl: './lite-pattern-view.component.html',
  styleUrls: ['./lite-pattern-view.component.scss'],
})
export class LitePatternViewComponent implements OnChanges {
  @Input() project: Project;
  @Input() sticker: THREE.Texture;
  @Input() productTypeId: string;
  @Input() palletPosition: PalletPosition = settings.primaryPallet;
  @Input() interactive: boolean = true;

  _pallet: Pallet;
  _palletPosition: THREE.Vector3;
  _labelOrientations: LabelOrientation[];
  lightPos1 = new THREE.Vector3(4, 3.5, 5);
  lightPos2 = new THREE.Vector3(4, 3.5, -5);
  lightPos3 = new THREE.Vector3(-4, 3.5, 5);
  lightPos4 = new THREE.Vector3(-4, 3.5, -5);
  cameraPos = settings.view3d.defaultCameraPosition;

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.project?.currentValue || changes.palletPosition?.currentValue) &&
      Boolean(this.project) &&
      this.palletPosition !== undefined &&
      this.palletPosition !== null
    ) {
      this.updatePallet();
    }
  }

  updatePallet(): void {
    const isLabelEnabled =
      typeof this.project.data.box.label.orientation !== 'undefined' &&
      this.project.data.box.label.orientation !== null;
    if (isLabelEnabled) {
      let oppositeLabelOrientation =
        (this.project.data.box.label.orientation + 180) % 360;
      if (oppositeLabelOrientation === 270) {
        oppositeLabelOrientation = LabelOrientation.LEFT;
      }

      this._labelOrientations = [
        this.project.data.box.label.orientation,
        oppositeLabelOrientation,
      ];
    }
    this._pallet = this.project.getPalletByPosition(this.palletPosition);
    this._palletPosition = new THREE.Vector3(
      0,
      -milliToMeter(this._pallet.dimensions.totalHeight) / 2,
      0
    );
  }
}
