import { Input, Component } from '@angular/core';
import { TooltipPosition } from '@angular/material/tooltip';
import { Layer } from 'src/app/models_new/classes/layer';
import { LayerType } from 'src/app/models_new/enums/layer-type';
import { SubnavViewService } from 'src/app/services/subnav-view.service';

@Component({
  selector: 'app-tools',
  templateUrl: './tools.component.html',
  styleUrls: ['./tools.component.scss'],
})
export class ToolsComponent {
  @Input() activeLayers: Layer[];
  @Input() shimPaperInProject: boolean;
  @Input() palletPosition: number;
  toolTipPosition: TooltipPosition = 'above';
  showTools = false;

  constructor(public subNavView: SubnavViewService) {}
  hasSavedLayers(): boolean {
    const layers = this.activeLayers.filter((f) => f.type === LayerType.LAYER);
    return layers.length ? true : false;
  }

  lastLayerIsLayer(): boolean {
    const lastLayer = this.activeLayers[0];
    return lastLayer.type === LayerType.LAYER ? true : false;
  }
}
