<mat-card
  appearance="outlined"
  style="background-color: transparent !important; padding: 2em"
>
  <ng-container *ngIf="licenses$ | async as result">
    <!-- Error -->
    <app-error
      *ngIf="result.error"
      [error]="result.error"
      [message]="result.error.message"
      [hideActions]="true"
      [suggestion]="' '"
    ></app-error>

    <div *ngIf="!result.error" class="license_list_wrapper">
      <!-- Loading state -->
      <div *ngIf="result.isLoading" class="loading_wrapper">
        <app-loading></app-loading>
      </div>

      <!-- Empty state -->
      <ng-container
        *ngIf="
          result.didSucceed &&
          (result.value === undefined || result.value?.length === 0)
        "
      >
        <app-highlighted-message-card
          [type]="'info'"
          [title]="'Robot license'"
          [message]="'No valid robot license found.'"
          style="margin-bottom: 1em"
        ></app-highlighted-message-card>
      </ng-container>

      <!-- Successful -->
      <div
        *ngIf="result.value"
        style="max-height: 20em; overflow: auto !important"
      >
        <mat-card
          appearance="outlined"
          class="license_card"
          *ngFor="let license of result.value"
        >
          <h2 style="margin-bottom: 0; margin-right: auto">Robot License</h2>
          <div class="key-val">
            <span class="key">Serial Number:</span>
            <span class="val">{{ license.robot_serial_number }}</span>
          </div>
          <div class="key-val">
            <span class="key">Product Type:</span>
            <span class="val">{{ license.product_type }}</span>
          </div>
          <div class="key-val">
            <span class="key">Valid To:</span>
            <span class="val">{{ license.license_valid_to | date }}</span>
          </div>
        </mat-card>
      </div>
    </div>
  </ng-container>
  <div style="display: flex; justify-content: end">
    <button mat-flat-button pally-button-m color="primary" (click)="close()">
      Ok
    </button>
  </div>
</mat-card>
