import { Injector } from '@angular/core';
import * as THREE from 'three';
import { Observable, ReplaySubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { PartStoreService } from 'src/app/services/part-store.service';
import {
  JointNames,
  ProjectRobotDescriptorService,
} from 'src/app/services/project-robot-descriptor.service';
import { SimConfigService } from 'src/app/services/sim-config.service';
import { Type } from 'src/app/utils/type';
import { URDFTypes } from 'src/app/utils/urdf-utils';
import { PartType } from '../../enums/sim-config-part-type';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { SimConfigFieldIds } from '../../enums/simconfig-field-ids';
import { parentPartTypeConfig } from '../../config/parent-part-type';
import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { TimedObject3DService } from '../../../services/timed-object3d.service';
import { DimensionVisualizer } from '../3dview/timed-objects/dimension-visualizer';

export const taskNameSymbol = Symbol('taskName');

export class Task {
  static [taskNameSymbol] = 'Task';

  public data: any;
  public partReg: Map<PartType, string>;
  public relatedDimVizID: string;

  public attempts = 0;

  protected simConfigService: SimConfigService;
  protected toService: TimedObject3DService;
  protected partStoreService: PartStoreService;
  protected robot: ProjectRobotDescriptorService;

  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>,
    protected shouldAutoResolve: boolean = true
  ) {
    this.simConfigService = injector.get(SimConfigService);
    this.toService = injector.get(TimedObject3DService);
    this.partStoreService = injector.get(PartStoreService);
    this.robot = injector.get(ProjectRobotDescriptorService);
  }

  init(data: any): void {
    this.data = data;
  }

  run(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.operation(resolve, reject);
    });
  }

  public operation(resolve: () => void, _reject: (reason?: any) => void): void {
    resolve(); // Base class, just resolve.
  }

  // NOTE! DO NOT REMOVE, SHELVED FOR LATER
  activateDimVis(
    id: string,
    properties?: {
      value?: number | string;
      start?: THREE.Vector3 | URDFTypes;
      end?: THREE.Vector3 | URDFTypes;
    },
    duration?: number,
    addToScene?: boolean
  ): DimensionVisualizer {
    const dimvis = this.toService.getTimedObject(id) as DimensionVisualizer;
    if (dimvis) {
      if (properties?.start) {
        dimvis.setStart(properties.start);
      }
      if (properties?.end) {
        dimvis.setEnd(properties.end);
      }
      if (
        properties?.value ||
        properties?.value === 0 ||
        properties?.value === ''
      ) {
        dimvis.setValue(properties.value);
      }
      dimvis.activate(duration, addToScene);
    }
    return dimvis;
  }

  public readField<Tret>(id: SimConfigFieldIds): Tret {
    return this.simConfigService
      .getFieldForThreeView(id, this.threeID)
      .getValue() as unknown as Tret;
  }

  public readFields<Tret>(ids: SimConfigFieldIds[]): Tret[] {
    const values = [];
    for (let i = 0; i < ids.length; i++) {
      values[i] = this.readField<Tret>(ids[i]) as unknown as Tret;
    }
    return values;
  }

  protected readShowVisualizers(): boolean {
    return this.readField<boolean>(SimConfigFieldIds.ShowDimensions);
  }

  public setField(
    id: SimConfigFieldIds,
    value: string | number | boolean
  ): void {
    const field = this.simConfigService.getFieldForThreeView(id, this.threeID);
    field.formControl.setValue(value);
  }

  public getParentPart(partType: PartType): Observable<any[]> {
    let possibleParents = [];
    for (const config of parentPartTypeConfig) {
      if (partType === config.contextType) {
        possibleParents = config.possibleParentTypes;
      }
    }

    for (const type of possibleParents) {
      let fieldValue;
      let parentPartID;
      let parentPartType;

      if (type === PartType.FRAME) {
        const field = this.readField<any>(SimConfigFieldIds.FrameType);
        fieldValue = field.name;
        if (fieldValue !== 'NONE') {
          parentPartID = HwPartUtils.getPartAssetID(PartType.FRAME, fieldValue);
        }
        parentPartType = PartType.FRAME;
      } else if (type === PartType.LIFTKIT) {
        fieldValue = this.readField<any>(SimConfigFieldIds.LiftkitType).name;
        if (fieldValue !== 'NONE') {
          parentPartID = HwPartUtils.getPartAssetID(PartType.FRAME, fieldValue);
        }
        parentPartType = PartType.FRAME;

        // Base bracket is always present for consistency sake
      } else if (type === PartType.BASE_BRACKET) {
        const obs = new ReplaySubject<any>(1);
        obs.next([
          this.robot.getJointByID(JointNames.LiftkitBasebracket),
          PartType.BASE_BRACKET,
        ]);
        return obs;
      } else if (type === PartType.ROBOT) {
        fieldValue = this.readField<any>(SimConfigFieldIds.RobotType).name;
        if (fieldValue !== 'NONE') {
          parentPartID = HwPartUtils.getPartAssetID(PartType.FRAME, fieldValue);
        }
        parentPartType = PartType.ROBOT;

        // Offset bracket is always present for consistency sake
      } else if (type === PartType.OFFSET_BRACKET) {
        const obs = new ReplaySubject<any>(1);
        obs.next([
          this.robot.getJointByID(JointNames.ToolmountOffsetBracket),
          PartType.OFFSET_BRACKET,
        ]);
        return obs;
      }

      if (Type.isDefined(parentPartID)) {
        const obs = new ReplaySubject<any>(1);
        this.partStoreService
          .getPart(parentPartID)
          .pipe(RXJSUtils.filterUndefinedAndNull(), take(1))
          .subscribe((data: any) => {
            obs.next([
              data,
              !Type.isDefined(parentPartType) ? PartType.NONE : parentPartType,
            ]);
          });
        return obs;
      }
    }

    const obs = new ReplaySubject<any>(1);
    obs.next([undefined, PartType.NONE]);
    return obs;
  }
}
