import { Injectable, OnDestroy } from '@angular/core';
import { of, Observable, ReplaySubject, concat, from } from 'rxjs';
import {
  switchMap,
  map,
  catchError,
  shareReplay,
  take,
  pairwise,
  takeUntil,
} from 'rxjs/operators';
import * as THREE from 'three';
import { StateService } from '../auth/state.service';
import { IApiOrganization } from '../models_new/classes/api-models/ApiOrganization';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';
import { OrganizationType } from '../models_new/enums/organization-type';
import { ResourceTracker } from '../utils/resource-tracker';
import { NewAssetStoreService } from './3dview/asset-store.service';
import {
  IOrganizationLogoResult,
  OrganizationApiService,
} from './api/organization-api.service';
import { LocalStorageService, StorageMethod } from './local-storage.service';

interface IOrganizationAsset {
  orgId: string;
  url: string;
  asset?: string;
}
interface IOrganizationTypeAsset {
  customer_organization?: IOrganizationAsset;
  sales_organization?: IOrganizationAsset;
}
@Injectable({
  providedIn: 'root',
})
export class OrganizationLogoService implements OnDestroy {
  // Acvite-org logos in base64. => NOTE! Throws error if logo isn't available.
  activeCustomerLogo$: Observable<string>;
  activeSalesLogo$: Observable<string>;
  activeOrganizationLogo$: Observable<string>;

  // Only Logo. NOTE! Throws error if logo isn't available.
  logoTexture$: Observable<THREE.Texture>;

  // Only the label.
  labelTexture$: Observable<THREE.Texture>;

  // Logo if available. Defaults to label if logo isn't available.
  eitherLabelOrLogo$: Observable<{
    texture: THREE.Texture;
    type: 'logo' | 'label';
  }>;

  private rt = new ResourceTracker();

  private destroy$ = new ReplaySubject<void>();

  constructor(
    stateService: StateService,
    private organizationApiService: OrganizationApiService,
    private localStorageService: LocalStorageService,
    private assetStoreService: NewAssetStoreService
  ) {
    // Get the label from assetstore.
    this.labelTexture$ = this.assetStoreService
      .get('label')
      .data$.pipe(map((texture) => this.rt.track(texture))); // Track the label asset.

    this.activeCustomerLogo$ = this.getActiveOrganizationLogo(
      stateService.customer_organization$
    );
    this.activeSalesLogo$ = this.getActiveOrganizationLogo(
      stateService.sales_organization$
    );

    this.activeOrganizationLogo$ = this.getActiveOrganizationLogo(
      stateService.getCustomerOrSalesOrganization()
    );

    this.logoTexture$ = this.activeOrganizationLogo$.pipe(
      shareReplay({ bufferSize: 1, refCount: true }),
      switchMap((data: string) => {
        const promise = new Promise<THREE.Texture>((resolve, _reject) => {
          const texture = new THREE.Texture();
          texture.image = new Image();
          texture.image.crossOrigin = 'Anonymous';
          texture.image.onload = () => {
            texture.needsUpdate = true;
            resolve(texture);
          };
          texture.image.src = data;
        });

        return from(promise);
      })
    );

    // Give either comany logo or labels to the boxes.
    // Use label until org logo arrives.
    this.eitherLabelOrLogo$ = concat(
      this.labelTexture$.pipe(
        takeUntil(this.logoTexture$),
        map((texture) => {
          // Need this casting due to "string" type and "logo" | "label" type isn't compatible.
          return { texture: texture, type: 'label' as 'logo' | 'label' };
        })
      ),
      this.logoTexture$.pipe(
        map((texture) => {
          // Need this casting due to "string" type and "logo" | "label" type isn't compatible.
          return { texture: texture, type: 'logo' as 'logo' | 'label' };
        })
      )
    ).pipe(shareReplay({ bufferSize: 1, refCount: true }));

    // If we get a new texture, dispose of the previous one.
    this.eitherLabelOrLogo$
      .pipe(takeUntil(this.destroy$), pairwise())
      .subscribe(([prev, _]) => {
        this.rt.disposeResource(prev.texture);
      });
    this.logoTexture$
      .pipe(takeUntil(this.destroy$), pairwise())
      .subscribe(([prev, _]) => {
        this.rt.disposeResource(prev);
      });

    // If destroyed, dispose resources.
    this.destroy$.pipe(take(1)).subscribe(() => {
      this.rt.dispose();
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * @param organization$ Organization observable object
   * @returns Organization's asset or null if any
   */
  private getActiveOrganizationLogo(
    organization$: Observable<IApiOrganization>
  ): Observable<string> {
    /** Gets the given Org's Logo and handles caching */
    return organization$.pipe(
      switchMap((org: IApiOrganization) => {
        //if (!org) {
        //  return of('missing_org');
        //}

        let assets: IOrganizationTypeAsset = this.localStorageService.getData(
          LocalStorageKey.ASSETS
        );

        const organizationAsset: IOrganizationAsset = assets
          ? assets[org.type]
          : null;

        if (organizationAsset?.orgId === org.id) {
          /** If a logo is stored for this entity, return it */
          return this.organizationApiService
            .getOrganizationLogoUrl(org.id)
            .pipe(
              switchMap((logoUrl) => {
                // If url of logo has not changed, serve the cached version
                if (organizationAsset.url === logoUrl) {
                  return of(organizationAsset.asset);
                } else {
                  return this.#fetchOrganizationLogo(org);
                }
              })
            );
        } else {
          return this.#fetchOrganizationLogo(org);
        }
      })
    );
  }

  #fetchOrganizationLogo(org: IApiOrganization): Observable<string> {
    return this.organizationApiService
      .getOrganizationLogoExposingUrl(org.id)
      .pipe(
        map((result: IOrganizationLogoResult) =>
          this.setActiveOrganizationAsset(org, result.url, result.data)
        ),
        catchError(() => of(this.setActiveOrganizationAsset(org, '', null)))
      );
  }

  /**
   * Stores the given Asset in LocalStorage for further usage.
   * @param organization : Observable<IApiOrganization>
   * @param url : string
   * @param asset : string<base64> || NULL
   * @returns Asset: String(base64) || NULL if any
   */
  private setActiveOrganizationAsset(
    organization: IApiOrganization,
    url: string,
    asset?: string
  ): string {
    const organizationAsset: IOrganizationAsset = {
      orgId: organization.id,
      asset: asset,
      url: url,
    };
    let assets: IOrganizationTypeAsset = this.localStorageService.getData(
      LocalStorageKey.ASSETS
    ) || { sales_organization: null, customer_organization: null };
    organization.type === OrganizationType.SALES_ORGANIZATION
      ? (assets.sales_organization = organizationAsset)
      : (assets.customer_organization = organizationAsset);
    this.localStorageService.setData(
      LocalStorageKey.ASSETS,
      assets,
      StorageMethod.LOCAL
    );
    return asset;
  }
}
