export enum LocalStorageKey {
  PROJECT = 'project',
  IMPORT_NOT_DIRTY = 'importNotDirty',
  VALID_PROJECT_DATA = 'validProjectData',
  REVISIONS = 'revisions',
  DEBUG = 'debug',
  DEBUGGING = 'debugging',
  ERROR_LOG = 'error_log',
  SIMULATION_FORM = 'simulation_form',
  SIMULATION_GUI_SETTINGS = 'simulation_gui_settings',
  UTM = 'utm',
  STATE = 'state',
  ASSETS = 'assets',
  OPEN_SIM = 'open_sim',
  OPEN_SIM_PALLETIZING_PATTERN = 'open_sim_pp',
  PREFERRED_UNIT_SYSTEM = 'preferred_unit_system',
  SIMULATION_WIZARD_STATE = 'zim_wiz_state',
  BACKOFFICEGRAPHS_CONFIG = 'bo_graphs_config',
  PUBLIC_COMMENT_METADATA = 'comment_metadata',
  CUSTOM_PALLET = 'custom_pallet',
  EULA = 'eula',
  CUSTOM_GRIPPER = 'custom_gripper',
  IR_ROBOT_NAME = 'ir_robot_name',
  LATEST_VIEWED_GLOBAL_NOTIFICATION = 'latest_viewed_global_notification',
  RELOAD_AFTER_UPDATE = 'reload_after_update',
  ROBOT_CONFIG = 'robot_config',
  FASTTRACK_SOLUTION = 'ft_solution',
  FASSTRACK_SOLUTION_METADATA = 'ft_solution_metadata',
  WAYPOINTS_GENERATION_SELECTED = 'waypoints_generation_selected',
  WAYPOINTS_GENERATION_STEP = 'waypoints_generation_step',
}
