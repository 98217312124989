<ng-container *appVar="appLayout.lowHeight$ | async as lowHeigth">
  <ng-container *appVar="openSimService.currentStep$ | async as currentStep">
    <ng-container *ngIf="pageIndexDisplay$ | async as pagesSortedList">
      <div
        class="landing-outlet-nav-top"
        id="landing-outlet-nav-top"
        [ngStyle]="
          lowHeigth
            ? {
                'padding-top': '0.8em',
                'padding-bottom': '0.5em',
                height: '5rem'
              }
            : {}
        "
        [ngClass]="{ 'slide-out': currentStep.id === 'simulations-details' }"
      >
        <button
          mat-raised-button
          color="accent"
          id="fastTrackBackBtn"
          (click)="openSimService.back()"
          [disabled]="
            currentStep.index <= 0 ||
            currentStep.index >= pagesSortedList?.length - 1
          "
        >
          <mat-icon>chevron_left</mat-icon>
          BACK
        </button>
        <div class="landing-outlet-stepper" *ngIf="pagesSortedList?.length">
          <ng-container
            *ngFor="let page of pagesSortedList; let i = index; let l = last"
          >
            <div *ngIf="page?.content?.cardText" class="stepper-wrapper">
              <ng-container *ngIf="!openSimService.embed">
                <div
                  queryParamsHandling="preserve"
                  [class.done-step]="page.done"
                  [class.active-step]="currentStep.id === page.contentfulStepId"
                  class="step"
                >
                  <span
                    *ngIf="
                      page.done && currentStep.id !== page.contentfulStepId
                    "
                    ><mat-icon>done</mat-icon></span
                  >
                  <span
                    *ngIf="
                      !page.done || currentStep.id === page.contentfulStepId
                    "
                    >{{ i + 1 }}</span
                  >
                  <span
                    class="step-text"
                    [ngStyle]="lowHeigth ? { 'margin-bottom': '0.5em' } : {}"
                  >
                    {{ page.content.cardText }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="openSimService.embed">
                <div
                  [class.done-step]="page.done"
                  [class.active-step]="currentStep.id === page.contentfulStepId"
                  class="step"
                >
                  <span
                    *ngIf="
                      page.done && currentStep.id !== page.contentfulStepId
                    "
                    ><mat-icon>done</mat-icon></span
                  >
                  <span
                    *ngIf="
                      !page.done || currentStep.id === page.contentfulStepId
                    "
                    >{{ i + 1 }}</span
                  >
                  <span
                    class="step-text"
                    [ngStyle]="lowHeigth ? { 'margin-bottom': '0.5em' } : {}"
                  >
                    {{ page.content.cardText }}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngIf="!l">
                <div
                  class="line"
                  [ngClass]="page.done ? 'done-line' : 'not-done-line'"
                ></div>
              </ng-container>
            </div>
          </ng-container>
        </div>
        <ng-container *ngIf="pagesList$ | async as allPages">
          <button
            mat-raised-button
            id="fastTrackNextBtn"
            color="accent"
            (click)="openSimService.next()"
            [disabled]="
              !allPages[currentStep.index].valid ||
              currentStep.index >= pagesSortedList?.length - 1 ||
              !allPages[currentStep.index].analyticsTags?.nextClick
            "
          >
            NEXT
            <mat-icon iconPositionEnd>chevron_right</mat-icon>
          </button>
        </ng-container>
      </div>
      <div
        *ngIf="currentStep.id === 'simulations-details'"
        class="reset-button-container slide-in"
      >
        <button
          mat-stroked-button
          color="accent"
          (click)="openSimService.resetFlow()"
          matTooltip="Reset Fast-track"
        >
          Try again
          <mat-icon iconPositionEnd>rotate_left</mat-icon>
        </button>
      </div>
    </ng-container>
  </ng-container>
</ng-container>
