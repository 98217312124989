<app-page>
  <app-breadcrumb-nav></app-breadcrumb-nav>
  <app-header>
    <h1 app-title>Products</h1>
    <app-header-actions>
      <button
        *appIfCommandAvailable="'create_product'"
        mat-flat-button
        pally-rounded-button-m
        color="primary"
        (click)="navigateToProduct('new')"
      >
        <mat-icon>add</mat-icon> New
      </button>
    </app-header-actions>
  </app-header>

  <mat-divider></mat-divider>

  <ng-container *ngIf="products$ | async as products">
    <!-- Loading state -->
    <app-loading *ngIf="products.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="products.error as error" [error]="error"></app-error>

    <app-content-switcher
      *ngIf="products.didSucceed"
      [selected]="blockSelected"
      [sortingColumns]="sortingColumns"
      [resultFilter]="resultFilter"
      objectType="product"
      (selectedFiltering)="tableFilter = objUtil.cloneObject($event)"
      (order)="orderBy = $event"
      (delete)="onDelete()"
    >
      <ng-template #table>
        <app-inventory-table
          [filter]="tableFilter"
          [sortBy]="orderBy"
          [data]="products.value"
          [displayedColumns]="displayedColumns"
          [rowActions]="rowActions"
          (rowAction)="actionClicked($event)"
          (rowSelected)="rowClicked($event)"
          (blockSelectedChange)="blockSelected = $event"
        >
        </app-inventory-table>
      </ng-template>
    </app-content-switcher>
  </ng-container>
</app-page>
