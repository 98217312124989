import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductsComponent } from 'src/app/components/products/products.component';
import { ProductComponent } from 'src/app/components/products/product/product.component';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared.module';
import { ProductRoutingModule } from './product-routing.module';
import { ThreeModule } from '../three.module';

@NgModule({
  declarations: [ProductsComponent, ProductComponent],
  exports: [ProductsComponent, ProductComponent],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
    CommonModule,
    ThreeModule,
    ProductRoutingModule,
  ],
})
export class ProductModule {}
