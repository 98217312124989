import { Injector } from '@angular/core';
import * as THREE from 'three';
import { ReplaySubject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { PartType } from 'src/app/models_new/enums/sim-config-part-type';
import { FieldUpdate } from 'src/app/models_new/types/field-update';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { URDFJoint, URDFLink } from '@rocketfarm/urdf-loader';

export class FrameTask extends Task {
  static [taskNameSymbol] = 'Frame';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, reject: (reason?: any) => void): void {
    this.updateFrame(this.data, resolve, reject);

    if (this.data.newVal.name === 'NONE') {
      resolve();
    }
  }

  public updateFrame(
    s: FieldUpdate,
    resolve: () => void,
    _reject: (reason?: any) => void
  ): void {
    const world_frameJoint = this.robot.getJointByID(JointNames.WorldFrame);
    const frameLink = world_frameJoint.getObjectByName('frame_link');
    const visual = URDFUtils.findVisualFromJoint(world_frameJoint);
    const frame_liftkitJoint = this.robot.getJointByID(JointNames.FrameLiftkit);
    // eslint-disable-next-line eqeqeq
    if (s.newVal.name == 'NONE') {
      ThreeUtils.zeroTransform(world_frameJoint);

      ThreeUtils.zeroTransform(frameLink);

      ThreeUtils.zeroTransform(visual);
      ThreeUtils.disposeObject(visual.children);
      visual.children = []; // Remove any previous model

      ThreeUtils.zeroTransform(frame_liftkitJoint);

      this.visualize();
    } else {
      const frame_ColumnJoint = this.robot.getJointByID(
        JointNames.FrameLiftkit
      );

      this.partStoreService
        .getPart<THREE.Object3D>(
          HwPartUtils.getPartAssetID(PartType.FRAME, s.newVal.name)
        )
        .pipe(
          takeUntil(this.destroy$),
          RXJSUtils.filterUndefinedAndNull(),
          take(1)
        )
        .subscribe((part) => {
          const partJoint = part.getObjectByName('frame_joint') as URDFJoint;
          ThreeUtils.copyTransform(world_frameJoint, partJoint);

          const partLink = partJoint.getObjectByName('frame') as URDFLink;
          ThreeUtils.copyTransform(frameLink, partLink);

          const partVisual = URDFUtils.findVisualFromJoint(partJoint);
          ThreeUtils.copyTransform(visual, partVisual);
          visual.children = [];

          // Copy over children clones
          for (const child of partVisual.children) {
            const newChild = ThreeUtils.clone(child);
            visual.add(newChild);
          }

          const mountJoint = URDFUtils.findMountJoint(part);
          ThreeUtils.copyTransform(frame_ColumnJoint, mountJoint);

          this.visualize();

          resolve();
        });
    }
  }

  visualize(): void {
    this.activateDimVis(
      'robot_mount_height_full',
      undefined,
      this.readShowVisualizers() ? -1 : undefined
    );
  }
}
