import { Injector } from '@angular/core';
import * as THREE from 'three';
import { CanvasStore } from 'src/app/public/canvas-store';
import { SceneStore } from 'src/app/public/scene-store';
import { InputHandlerService } from 'src/app/services/3dview/input-handler.service';
import { ReplaySubject } from 'rxjs';
import { RenderTrigger } from 'src/app/public/render-trigger';
import { ThreePerspective } from '../../enums/three-perspective';
import { DataService } from 'src/app/services/data.service';
import { View3DEventsService } from 'src/app/services/3dview/view3d-events.service';
import { Project } from '../project';

export class ThreeHandler {
  protected inputHandler = this.injector.get(InputHandlerService);
  protected dataService = this.injector.get(DataService);
  protected threeEvents = this.injector.get(View3DEventsService);

  loading$ = new ReplaySubject<boolean>(1);
  destroy$ = new ReplaySubject<boolean>(1);

  constructor(
    protected ID: string,
    protected injector: Injector,
    protected project?: Project
  ) {}

  public init(): void {
    this.inputHandler.setMaxCameraDistance(5);
    this.inputHandler.updateControls();
  }

  public render(): void {
    RenderTrigger.render$.next(this.ID);
  }

  public resize(): void {
    RenderTrigger.resize$.next({
      id: this.ID,
      width: this.canvas.clientWidth,
      height: this.canvas.clientHeight,
    });
  }

  /**
   * Subscribe to RenderTrigger.screenshotOutput$ and
   * filter with the threeID to get screenshot.
   */
  public screenshot() {
    RenderTrigger.screenshotTrigger$.next(this.ID);
  }

  /**
   * @deprecated Called internally! Subscribe to the destroy$ ReplaySubject instead of overriding!
   */
  public destroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public getID(): string {
    return this.ID;
  }

  public get canvas(): HTMLCanvasElement {
    return CanvasStore.getCanvas(this.ID);
  }

  public get canvasParent(): HTMLElement {
    return CanvasStore.getCanvasParent(this.ID);
  }

  public get scene(): THREE.Scene {
    return SceneStore.getScene(this.ID);
  }

  public get camera(): THREE.PerspectiveCamera | THREE.OrthographicCamera {
    return this.inputHandler.getMainCamera();
  }

  public addLighting(): void {
    //
  }

  public handlePerspective(_p: ThreePerspective): void {
    //
  }
}
