import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiStrategy } from 'src/app/models_new/classes/api-models/ApiStrategy';
import { ReplaySubject, Subject, Observable, map } from 'rxjs';
import { ISplitCardTableData } from '../split-card-table/split-card-table.component';

@Component({
  selector: 'app-card-software-display',
  templateUrl: './card-software-display.component.html',
  styleUrls: ['./card-software-display.component.scss'],
})
export class CardSoftwareDisplayComponent {
  swConfigSubject$: Subject<ApiStrategy> = new ReplaySubject<ApiStrategy>();
  swConfig$: Observable<ApiStrategy>;
  tableData$: Observable<ISplitCardTableData>;

  @Input() set swConfig(sw: ApiStrategy) {
    this.swConfigSubject$.next(sw);
  }
  @Input() actions: {
    id: string;
    label: string;
  }[];
  @Output() actionClicked$ = new EventEmitter<string>();

  constructor() {
    this.swConfig$ = this.swConfigSubject$.asObservable();
    this.tableData$ = this.swConfig$.pipe(
      map((config) => {
        return {
          overline: {
            type: 'Software Configuration',
            last_edit: config.updated_at || '',
          },
          name: config.name,
          content: [
            {
              header: 'Robot OS',
              value: config.data.software.polyscope_version,
            },
            {
              header: 'Pally Software Version',
              value: config.data.software.pally_version,
            },
            {
              header: 'Gripper Optimization',
              value: config.data.gripper_optimization,
            },
            {
              header: 'Maximum Speed',
              value: config.data.max_speed.toString(),
            },
            {
              header: 'Maximum Acceleration',
              value: config.data.max_acceleration.toString(),
            },
            {
              header: 'Approach Distance',
              value: config.data.approach_distance.toString(),
            },
          ],
        };
      })
    );
  }
}
