<ng-container *ngIf="asyncProject$ | async as asyncProject">
  <!-- Loading state -->
  <app-loading *ngIf="asyncProject.isLoading"></app-loading>

  <!-- Error state -->
  <app-error *ngIf="asyncProject.error as error" [error]="error"></app-error>

  <ng-container *ngIf="asyncProject.didSucceed && asyncProject.value">
    <app-page>
      <ng-container *ngIf="deadLock">
        <section class="delete_prompt_wrapper">
          <mat-card appearance="outlined">
            <div mat-card-avatar>
              <mat-icon>warning</mat-icon>
            </div>
            <div>
              <mat-card-title>Product warning!</mat-card-title>
              <mat-card-content
                >The associated product has been updated since the creation of
                this pattern. This pattern is not updated with the product
                changes.</mat-card-content
              >
              <mat-card-actions style="margin-top: 2em">
                <button
                  mat-raised-button
                  color="warn"
                  style="margin-right: 10px"
                  (click)="resetPattern(asyncProject.value)"
                >
                  Reset pattern
                </button>
                <button
                  mat-raised-button
                  color="accent"
                  (click)="downloadPatternJson(asyncProject.value)"
                >
                  Download pattern file (.json)
                </button>
              </mat-card-actions>
            </div>
          </mat-card>
        </section>
      </ng-container>

      <ng-container>
        <mat-card appearance="outlined" class="pallet_card">
          <app-advanced-navbar
            [project]="asyncProject.value"
            (settingsClick)="palletDrawer.toggle(); settingsDrawer.toggle()"
            (palletToggleClick)="
              selectPallet(
                asyncProject.value.getActivePalletPosition() === 0 ? 1 : 0
              )
            "
          ></app-advanced-navbar>

          <mat-drawer-container
            style="overflow-y: hidden; height: 100%; border-radius: 4px"
            autosize
          >
            <mat-drawer
              #palletDrawer
              mode="side"
              position="start"
              opened
              class="pallet_drawer"
            >
              <div
                class="transition pallet_edit_wrapper"
                [style.width.px]="327"
              >
                <app-pallet-edit
                  [palletPosition]="
                    asyncProject.value.getActivePalletPosition()
                  "
                  [shimPaperInProject]="
                    asyncProject.value.getProjectData().getShimPaperData()
                      .enabled
                  "
                  [activeLayers]="asyncProject.value.getActivePallet().layers"
                  (editLayer)="editLayer($event, false, asyncProject.value)"
                  (editAllOfLayer)="editLayer($event, true, asyncProject.value)"
                  (changeApproach)="changeApproach($event, asyncProject.value)"
                  (duplicateLayer)="duplicateLayer($event, asyncProject.value)"
                  (removeLayer)="removeLayer($event, asyncProject.value)"
                  (drop)="drop($event, asyncProject.value)"
                  (editShimpaperHeight)="
                    editShimpaperHeight($event, asyncProject.value)
                  "
                ></app-pallet-edit>
              </div>
            </mat-drawer>
            <mat-drawer
              #settingsDrawer
              mode="side"
              position="start"
              class="pallet_drawer"
            >
              <div class="transition settings_wrapper" [style.width.px]="327">
                <mat-list>
                  <form [formGroup]="formGroup">
                    <div mat-list-item>
                      <mat-form-field appearance="outline">
                        <mat-label>Pattern name</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="name"
                          [errorStateMatcher]="eagerMatcher"
                        />
                      </mat-form-field>
                    </div>
                    <div mat-list-item>
                      <mat-form-field appearance="outline">
                        <mat-label>Pattern description</mat-label>
                        <input
                          matInput
                          type="text"
                          formControlName="description"
                          [errorStateMatcher]="eagerMatcher"
                        />
                      </mat-form-field>
                    </div>
                    <mat-divider></mat-divider>
                    <div mat-list-item>
                      <mat-form-field appearance="outline">
                        <mat-label>Speed</mat-label>
                        <mat-hint>Cases per minute</mat-hint>
                        <mat-select formControlName="cpm">
                          <mat-option
                            *ngFor="let l of ZeroToThirty"
                            [value]="l"
                          >
                            {{ l }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div mat-list-item>
                      <mat-form-field appearance="outline">
                        <mat-label>Max grip</mat-label>
                        <mat-select formControlName="multi_grip">
                          <mat-option value="auto">Auto</mat-option>
                          <mat-option *ngFor="let l of MultiGrips" [value]="l">
                            {{ l }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div mat-list-item>
                      <mat-form-field appearance="outline">
                        <mat-label>Shim paper default height</mat-label>
                        <mat-select formControlName="shim_paper_height">
                          <mat-option
                            *ngFor="let l of ZeroToHoundred"
                            [value]="l"
                          >
                            {{ l }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <div mat-list-item>
                      <mat-form-field appearance="outline">
                        <mat-label>Left pallet layout</mat-label>
                        <mat-select formControlName="left_pallet_layout">
                          <mat-option [value]="'null'">None</mat-option>
                          <mat-option [value]="'copy'"
                            >Copy - the right pallet</mat-option
                          >
                          <mat-option [value]="'rotate'"
                            >Rotate - the right pallet</mat-option
                          >
                          <mat-option [value]="'mirror'"
                            >Mirror - the right pallet</mat-option
                          >
                          <mat-option [value]="'custom'"
                            >Custom - independent pallet</mat-option
                          >
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </form>
                </mat-list>
              </div>
            </mat-drawer>
            <mat-drawer-content
              style="
                max-height: 100%;
                min-height: 100%;
                overflow: hidden;
                margin-left: 327px;
              "
            >
              <div class="viewOptions">
                <!-- Commented out since it does not work properly -->
                <!-- <button
                  matRipple
                  mat-mini-fab
                  matTooltip="Undo"
                  matTooltipPosition="left"
                  color="accent"
                  [disabled]="activeRevision === 0"
                  (click)="undo(asyncProject.value)"
                >
                  <mat-icon>undo</mat-icon>
                </button>
                <button
                  matRipple
                  mat-mini-fab
                  matTooltip="Redo"
                  matTooltipPosition="left"
                  color="accent"
                  [disabled]="
                    revisions.length === 0 ||
                    activeRevision === revisions.length - 1
                  "
                  (click)="redo(asyncProject.value)"
                >
                  <mat-icon>redo</mat-icon>
                </button> -->

                <ng-container
                  *ngIf="
                    asyncProject.value.activePallet === PalletPosition.RIGHT
                  "
                >
                  <button
                    matRipple
                    mat-mini-fab
                    matTooltip="Edit left pallet"
                    matTooltipPosition="left"
                    color="accent"
                    (click)="selectPallet(0)"
                  >
                    <mat-icon style="transform: rotate(90deg)"
                      >move_down</mat-icon
                    >
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="
                    asyncProject.value.activePallet === PalletPosition.LEFT
                  "
                >
                  <button
                    matRipple
                    mat-mini-fab
                    matTooltip="Edit right pallet"
                    matTooltipPosition="left"
                    color="accent"
                    (click)="selectPallet(1)"
                  >
                    <mat-icon style="transform: rotate(90deg)"
                      >move_up</mat-icon
                    >
                  </button>
                </ng-container>

                <button
                  matRipple
                  mat-mini-fab
                  matTooltip="3D Options"
                  matTooltipPosition="left"
                  color="accent"
                  [matMenuTriggerFor]="dmenu"
                >
                  <mat-icon>view_in_ar</mat-icon>
                </button>

                <mat-menu
                  #dmenu="matMenu"
                  yPosition="below"
                  xPosition="before"
                  (click)="$event.stopPropagation()"
                >
                  <section
                    mat-menu-item
                    (click)="
                      $event.stopPropagation();
                      viewOptionClick('showPalletFront', asyncProject.value)
                    "
                  >
                    <mat-slide-toggle
                      color="accent"
                      [checked]="
                        asyncProject.value.palletView.palletViewSettings[
                          'showPalletFront'
                        ]
                      "
                    >
                      Pallet front
                    </mat-slide-toggle>
                  </section>
                  <!-- TODO - Add back pallet view -->
                  <!-- <section
                    mat-menu-item
                    (click)="
                      $event.stopPropagation();
                      viewOptionClick('showLeftPallet', asyncProject.value)
                    "
                  >
                    <mat-slide-toggle
                      color="accent"
                      [checked]="
                        asyncProject.value.palletView.palletViewSettings[
                          'showLeftPallet'
                        ]
                      "
                    >
                      Multiple pallets
                    </mat-slide-toggle>
                  </section> -->
                  <section
                    mat-menu-item
                    (click)="
                      $event.stopPropagation();
                      viewOptionClick('showFront', asyncProject.value)
                    "
                  >
                    <mat-slide-toggle
                      color="accent"
                      [checked]="
                        asyncProject.value.palletView.palletViewSettings[
                          'showFront'
                        ]
                      "
                    >
                      Box front
                    </mat-slide-toggle>
                  </section>
                </mat-menu>
              </div>
              <div class="canvasWrapper">
                <div
                  class="w-100 p-0"
                  style="max-width: 100vw"
                  (resized)="onResized()"
                >
                  <app-pattern-view
                    class="pattern-view"
                    [project]="asyncProject.value"
                    [sticker]="sticker$ | async"
                    [productTypeId]="product?.data?.model_type || 'box'"
                  ></app-pattern-view>
                </div>
              </div>
            </mat-drawer-content>
          </mat-drawer-container>
        </mat-card>
      </ng-container>
    </app-page>

    <app-wizard-bottom-navbar
      [title]="'Customize pattern'"
      [secondaryButton]="'cancel'"
      [primaryButton]="'confirm'"
      (nextClick)="saveEdit(asyncProject.value)"
      (backClick)="cancelEdit()"
    ></app-wizard-bottom-navbar>
  </ng-container>
</ng-container>
