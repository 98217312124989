import { Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { Type } from 'src/app/utils/type';

export class PalletLipTask extends Task {
  static [taskNameSymbol] = 'Pallet lip';

  side: 'RIGHT' | 'LEFT';

  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public init(data: any): void {
    super.init(data);
    this.side =
      this.data.fieldId === SimConfigFieldIds.PalletRightLipHeight
        ? 'RIGHT'
        : 'LEFT';
  }

  public operation(resolve: () => void, _reject: (reason?: any) => void): void {
    this.updateLip();

    resolve();
  }

  protected updateLip(): void {
    const isRight = this.side === 'RIGHT';
    const palletLipJoint = this.robot.getJointByID(
      isRight ? JointNames.PalletRightLip : JointNames.PalletLeftLip
    );
    const lipVisual = URDFUtils.findVisualFromJoint(palletLipJoint);

    const lipHeight = this.readPalletLip(isRight);
    //          Lip model is 100mm high
    //                     V
    lipVisual.scale.z = (1 / 100) * lipHeight;
    palletLipJoint.visible = lipHeight > 0;
  }

  protected readPalletLip(isRight: boolean): number {
    let height = this.readField(
      isRight
        ? SimConfigFieldIds.PalletRightLipHeight
        : SimConfigFieldIds.PalletLeftLipHeight
    );
    // To mitigate "-0" which messes with later calculations
    if (height === -0) {
      // } || height === '-0') {
      height = 0;
    } else if (Type.isOfType(height, 'string')) {
      height = +height;
    }
    return height as number;
  }
}
