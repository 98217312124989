import { ConveyorDirection } from '../enums/sim-config-conveyor-dir';
import { IPallyPathStrategy } from '../types/pally-path-strategy';
import { IStrategyApiFileType } from '../types/simulation-api-file-type';

export interface ISmartExitConfig {
  id: string;
  direction: ConveyorDirection;
  image: string;
  fields:
    | IStrategyApiFileType['smart_exits']
    | IPallyPathStrategy['path_planning']['smart_exit'];
}

export const pallyProgramSettingsSmartExitDefaultValues: ISmartExitConfig[] = [
  {
    id: '0',
    image: '../../../assets/illustrative/front-conveyor_doosan.png',
    direction: ConveyorDirection.FRONT,
    fields: {
      auto: false,
      p1: {
        min_x: 100,
        max_x: 700,
        min_y: 50,
        max_y: 250,
      },
      p2: {
        min_x: 100,
        max_x: 700,
        min_y: 50,
        max_y: 250,
      },
    },
  },
  {
    id: '1',
    image: '../../../assets/illustrative/left-conveyor_doosan.png',
    direction: ConveyorDirection.LEFT,
    fields: {
      auto: false,
      p1: {
        min_x: 100,
        max_x: 350,
        min_y: -400,
        max_y: 0,
      },
      p2: {
        min_x: 100,
        max_x: 350,
        min_y: 100,
        max_y: 800,
      },
    },
  },
  {
    id: '2',
    image: '../../../assets/illustrative/right-conveyor_doosan.png',
    direction: ConveyorDirection.RIGHT,
    fields: {
      auto: false,
      p1: {
        min_x: 100,
        max_x: 350,
        min_y: 100,
        max_y: 800,
      },
      p2: {
        min_x: 100,
        max_x: 350,
        min_y: -400,
        max_y: 0,
      },
    },
  },
];

export const softwareSmartExitDefaultValues: ISmartExitConfig[] = [
  {
    id: '0',
    image: '../../../assets/illustrative/front_conveyor_dir.png',
    direction: ConveyorDirection.FRONT,
    fields: {
      left_pallet: {
        x: {
          from: 100,
          to: 700,
        },
        y: {
          from: 50,
          to: 250,
        },
      },
      right_pallet: {
        x: {
          from: 100,
          to: 700,
        },
        y: {
          from: 50,
          to: 250,
        },
      },
    },
  },
  {
    id: '1',
    image: '../../../assets/illustrative/left_conveyor_dir.png',
    direction: ConveyorDirection.LEFT,
    fields: {
      left_pallet: {
        x: {
          from: 100,
          to: 350,
        },
        y: {
          from: -400,
          to: 0,
        },
      },
      right_pallet: {
        x: {
          from: 100,
          to: 350,
        },
        y: {
          from: 100,
          to: 800,
        },
      },
    },
  },
  {
    id: '2',
    image: '../../../assets/illustrative/right_conveyor_dir.png',
    direction: ConveyorDirection.RIGHT,
    fields: {
      left_pallet: {
        x: {
          from: 100,
          to: 350,
        },
        y: {
          from: 100,
          to: 800,
        },
      },
      right_pallet: {
        x: {
          from: 100,
          to: 350,
        },
        y: {
          from: -400,
          to: 0,
        },
      },
    },
  },
];
