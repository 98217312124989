import { AssetIDs } from '../../enums/asset-ids';
import { IAssetStoreLoadingConfig } from '../../types/asset/new-asset-store-loading-config';
import { settings } from '../application-settings';

/**
 * @desc Configuration for product assets. @type {IAssetStoreLoadingConfig[]}
 * When adding new assets, make sure to add the asset to the AssetIDs enum.
 *@todo: Externalize the array of assets to can be loaded dynamically as they are added to pally descriptions.
 */
export const productAssetConfig: IAssetStoreLoadingConfig<any>[] = [
  {
    id: AssetIDs.Box,
    label: 'Cardboard Box',
    src: settings.publicmodelsPallyDescriptionsURL + 'boxes/box_sized.dae',
    type: 'dae',
    prefetch: true,
  },
  {
    id: AssetIDs.CanSixPack,
    label: 'Wrapped Can Pack',
    src: 'https://pallydescriptions.blob.core.windows.net/public-models/boxes/six_pack.dae',
    type: 'dae',
    prefetch: true,
  },
  {
    id: AssetIDs.BucketRound,
    label: 'Round Bucket',
    src: 'https://pallydescriptions.blob.core.windows.net/public-models/boxes/bucket_round.dae',
    type: 'dae',
    prefetch: true,
  },
  // {
  //   id: AssetIDs.BucketSquared,
  //   src: 'https://pallydescriptions.blob.core.windows.net/public-models/boxes/bucket_squared.dae',
  //   type: 'dae',
  //   prefetch: true,
  // },
];

export const openAssetConfig: IAssetStoreLoadingConfig<any>[] = [
  {
    id: AssetIDs.Box,
    label: 'Cardboard Box',
    src: settings.publicmodelsPallyDescriptionsURL + 'boxes/box_sized.dae',
    type: 'dae',
    prefetch: true,
  },
  {
    id: 'font',
    src: '../../../../assets/design-elements/helvetiker.typeface.json',
    type: 'three_font',
    prefetch: true,
  },
  {
    id: 'label',
    src: '../../../../assets/illustrative/label.png',
    type: 'texture',
    prefetch: true,
  },
  ...productAssetConfig,
  {
    id: 'pallet-eur1',
    src:
      settings.publicmodelsPallyDescriptionsURL +
      'pallet/EUR/EUR1/meshes/visual/euro_pallet.dae',
    type: 'dae',
    prefetch: true,
  },
  {
    id: 'conveyor',
    src:
      settings.pallyDescriptionsURL +
      'pally_descriptions/conveyor/standard/conveyor/meshes/visual/conveyor.dae',
    type: 'dae',
    prefetch: true,
    needsSASToken: true,
  },
  {
    id: 'simulation-scene',
    src:
      settings.publicmodelsPallyDescriptionsURL +
      'studio-scene-redesign_v9/MAIN_studio-scene-redesign_v9_fix.fbx',
    type: 'fbx',
    prefetch: false,
  },
  /*
    TODO: Should be moved to seperate config, saved for convenience
  {
    id: 'sim-config-urdf',
    src: settings.pallyDescriptionsURL +
      'pally_descriptions/urdfs/simconfig_robot.urdf',
    type: 'urdf',
    prefetch: true,
    needsSASToken: true,
  },*/
];
