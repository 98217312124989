import { AssetType } from '../../classes/asset/asset';
import { AssetIDs } from '../../enums/asset-ids';
import { IAssetStoreLoadingConfig } from '../../types/asset/asset-store-loading-config';

const openPallyDescriptionsURL =
  'https://pallydescriptions.blob.core.windows.net/public-models/';

export const productAssetConfig: IAssetStoreLoadingConfig[] = [
  {
    id: AssetIDs.Box,
    urls: new Map<string, string>([
      [AssetIDs.Box, openPallyDescriptionsURL + 'boxes/box_sized.dae'],
    ]),
    type: AssetType.DAEModel,
  },
  {
    id: AssetIDs.CanSixPack,
    urls: new Map<string, string>([
      [AssetIDs.CanSixPack, openPallyDescriptionsURL + 'boxes/six_pack.dae'],
    ]),
    type: AssetType.DAEModel,
  },
];

export const globalAssetConfig: IAssetStoreLoadingConfig[] = [
  // Composite asset! (Multiple "models" (urdf structure is counted as model) per asset, model determined by mode)
  ...productAssetConfig,
  {
    id: AssetIDs.CompanyLogo,
    type: AssetType.Texture,
  },
  {
    id: AssetIDs.CompanyLogoPreppedBox,
    type: AssetType.Texture,
  },
  {
    id: AssetIDs.LabelTexture,
    urls: new Map<string, string>([
      [AssetIDs.LabelTexture, '../assets/illustrative/label.png'],
    ]),
    type: AssetType.Texture,
  },
  {
    id: AssetIDs.LabelCompactTexture,
    urls: new Map<string, string>([
      [
        AssetIDs.LabelCompactTexture,
        '../assets/illustrative/label_compact.png',
      ],
    ]),
    type: AssetType.Texture,
  },
  {
    id: AssetIDs.HelvetikerFont,
    urls: new Map<string, string>([
      [
        AssetIDs.HelvetikerFont,
        '../assets/design-elements/helvetiker.typeface.json',
      ],
    ]),
    type: AssetType.Font,
  },
  {
    id: AssetIDs.EURPallet,
    urls: new Map<string, string>([
      [
        AssetIDs.EURPallet,
        openPallyDescriptionsURL +
          'pallet/EUR/EUR1/meshes/visual/euro_pallet.dae',
      ],
    ]),
    type: AssetType.DAEModel,
  },
];
