import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { timer } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogWrapperComponent } from '../components/dialogs/dialog-wrapper/dialog-wrapper.component';
import { dialogSizesConfig } from '../models_new/config/dialog-sizes';
import { DialogSize } from '../models_new/enums/dialogSize';
import { IDialogData } from '../models_new/types/dialog-data';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  dialogSizes = dialogSizesConfig;

  constructor(private dialog: MatDialog) {}

  /**
   * @param size DialogSize
   * @param autoHeight boolean
   */
  private getDialogSize(size: DialogSize, autoHeight?: boolean) {
    let width;
    let height;

    if (size === DialogSize.SMALL) {
      width = this.dialogSizes.small.width;
      height = autoHeight ? null : this.dialogSizes.small.height;
    }

    if (size === DialogSize.MEDIUM) {
      width = this.dialogSizes.medium.width;
      height = autoHeight ? null : this.dialogSizes.medium.height;
    }

    if (size === DialogSize.LARGE) {
      width = this.dialogSizes.large.width;
      height = autoHeight ? null : this.dialogSizes.large.height;
    }

    return {
      width: width,
      height: height,
    };
  }

  /**
   * @param content title, ComponentType(optional), contentAsString(optional), data object(optional)
   * @param button clickFunction(optional), button text(optional), enable cancel(optional)
   * @param gui size, autoHeight
   */
  showConditionalDialog<T = any>(input: {
    content: {
      title?: string;
      component?: ComponentType<T>;
      contentAsString?: string;
      text?: string;
      condition?: boolean;
    };
    gui: {
      centerVert?: boolean;
      centerHoriz?: boolean;
      size: DialogSize;
      autoHeight?: boolean;
    };
  }): MatDialogRef<any> {
    const dialogData: IDialogData = {
      content: input.content,
      title: input.content.title,
      gui: {
        centerHoriz: input.gui.centerHoriz,
        centerVert: input.gui.centerVert,
      },
    };

    const size = this.getDialogSize(input.gui.size, input.gui.autoHeight);

    // Open dialog
    const conditionalDialog = this.dialog.open(DialogWrapperComponent, {
      width: size.width,
      height: size.height,
      data: dialogData,
    });

    return conditionalDialog;
  }

  /**
   * @param timeToLive in ms
   * @param content title, ComponentType(optional), contentAsString(optional), data object(optional)
   * @param button clickFunction(optional), button text(optional), enable cancel(optional), button type(optional
   * @param gui size, autoHeight
   */
  showTimedDialog<T = any>(input: {
    content: {
      title?: string;
      component?: ComponentType<T>;
      contentAsString?: string;
      text?: string;
    };
    gui: {
      centerVert?: boolean;
      centerHoriz?: boolean;
      size: DialogSize;
      autoHeight?: boolean;
      timeToLive: number;
    };
  }): MatDialogRef<any> {
    const dialogData: IDialogData = {
      content: input.content,
      title: input.content.title,
      gui: {
        centerHoriz: input.gui.centerHoriz,
        centerVert: input.gui.centerVert,
      },
    };

    const size = this.getDialogSize(input.gui.size, input.gui.autoHeight);

    // Open dialog
    const timedDialog = this.dialog.open(DialogWrapperComponent, {
      width: size.width,
      height: size.height,
      data: dialogData,
    });

    // Start timer
    timer(input.gui.timeToLive)
      .pipe(take(1))
      .subscribe((_timeOut) => timedDialog.close());

    return timedDialog;
  }

  /**
   * @param component custom component
   * @param size DialogSize
   * @returns MatDialogRef
   */
  showCustomDialog<T = any>(
    component: ComponentType<T>,
    size: DialogSize,
    selectedStepId?: number,
    data?: any,
    enableClose?: boolean
  ): MatDialogRef<any> {
    const dialogSize = this.getDialogSize(size, true);

    return this.dialog.open(component, {
      width: dialogSize.width,
      height: dialogSize.height,
      maxHeight: '100vh',
      data: { selectedStepId: selectedStepId, data: data },
      panelClass: 'no_padding_dialog',
      autoFocus: false,
      disableClose: enableClose ? false : true,
      scrollStrategy: new NoopScrollStrategy(),
    });
  }

  /**
   * @param content title, ComponentType(optional), contentAsString(optional), data object(optional)
   * @param button clickFunction(optional), button text(optional), enable cancel(optional), button type(optional
   * @param gui size, autoHeight
   */
  showStandardDialog<T = any>(input: {
    content: {
      title: string;
      component?: ComponentType<T>;
      contentAsString?: string;
      text?: string;
      data?: any;
    };
    button?: {
      clickFn?: boolean;
      text?: string;
      type?: string;
      enableCancel?: boolean;
    };
    gui: {
      centerVert?: boolean;
      centerHoriz?: boolean;
      size: DialogSize;
      autoHeight?: boolean;
    };
  }): MatDialogRef<any> {
    if (!input.content.title) {
      input.content.title = 'No title';
    }
    if (!input.button?.text) {
      input.button = input.button || {
        text: 'Continue',
        enableCancel: undefined,
      };
      input.button.text = input.button.text ?? 'Continue';
    }
    if (input.button?.enableCancel === undefined) {
      input.button.enableCancel = true;
    }
    if (input.gui.autoHeight === null || input.gui.autoHeight === undefined) {
      input.gui.autoHeight = true;
    }

    const size = this.getDialogSize(input.gui.size, input.gui.autoHeight);

    const dialogData: IDialogData = {
      content: input.content,
      title: input.content.title,
      button: input.button,
      gui: {
        centerHoriz: input.gui.centerHoriz,
        centerVert: input.gui.centerVert,
      },
    };

    return this.dialog.open(DialogWrapperComponent, {
      width: size.width,
      height: size.height,
      data: dialogData,
      autoFocus: false,
      disableClose: true,
    });
  }
}
