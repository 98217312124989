import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  combineLatest,
  map,
  of,
  skipWhile,
  Subject,
  switchMap,
  take,
  takeUntil,
} from 'rxjs';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { StateService } from 'src/app/auth/state.service';
import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { OrganizationType } from 'src/app/models_new/enums/organization-type';
import { INavbarItem } from 'src/app/models_new/types/navbar-item';
import { OrganizationApiService } from 'src/app/services/api/organization-api.service';
import { PublicApiService } from 'src/app/services/api/public-api.service';
import { OrganizationLogoService } from 'src/app/services/organization-logo.service';
import { SidenavService } from 'src/app/services/sidenav.service';
import { ObjectUtils } from 'src/app/utils/object';

@Component({
  selector: 'app-toolbar-component',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  ready = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  pagesPATH = pagesPATH;
  activeOrganization: ApiOrganization;
  organizations: ApiOrganization[];
  salesOrganizations: ApiOrganization[];
  allUsersOrganizations: ApiOrganization[];
  unrelatedCustomerOrgs: ApiOrganization[];
  filteredOrgs: ApiOrganization[];
  filteredUnrelatedOrgs: ApiOrganization[];
  sales_org: ApiOrganization;
  notifications: number = 0;

  constructor(
    public stateService: StateService,
    private router: Router,
    private organizationApi: OrganizationApiService,
    public organizationLogoService: OrganizationLogoService,
    public publicApi: PublicApiService,
    public sidenavService: SidenavService
  ) {}

  ngOnInit(): void {
    this.ready = false;
    this.stateService.isUserLogged$
      .pipe(
        skipWhile(
          (isUserLogged) =>
            !isUserLogged && window.location.href.includes('o=1')
        ),
        switchMap((_) => this.stateService.sales_organization$),
        switchMap((salesOrg: ApiOrganization) =>
          combineLatest([
            of(salesOrg),
            salesOrg?.id
              ? this.organizationApi.getNoOfUnhandledLeads(salesOrg.id)
              : of(0),
          ])
        ),
        switchMap(([salesOrg, unhandledLeads]) => {
          this.notifications = unhandledLeads;
          this.sales_org = salesOrg;
          return combineLatest([
            this.stateService.organizations$,
            salesOrg
              ? this.organizationApi
                  .getRelatedCustomerOrganizationsById(salesOrg?.id)
                  .pipe(
                    takeUntil(this.destroy$),
                    map((orgs) =>
                      orgs.map(
                        (customer_org) => new ApiOrganization(customer_org)
                      )
                    )
                  )
              : of(null),
            this.stateService
              .getCustomerOrSalesOrganization()
              .pipe(RXJSUtils.filterUndefinedAndNull()),
          ]);
        })
      )
      .subscribe({
        next: ([organizations, child_organizations, activeOrg]) => {
          if (organizations && activeOrg) {
            /** Gets the current active and unrealted organization & backs them up: */
            this.activeOrganization = activeOrg;
            this.allUsersOrganizations = ObjectUtils.cloneObject(organizations);
            if (
              !this.sales_org &&
              activeOrg.type === OrganizationType.CUSTOMER_ORGANIZATION
            ) {
              /** Customer-organization-only user: */
              this.organizations = organizations;
            } else {
              /** Sales-organization user */
              (this.organizations = child_organizations),
                (this.salesOrganizations = organizations.filter(
                  (o) => o.type === OrganizationType.SALES_ORGANIZATION
                ));
              this.filteredOrgs = ObjectUtils.cloneObject(this.organizations);
              this.filteredUnrelatedOrgs = ObjectUtils.cloneObject(
                this.unrelatedCustomerOrgs
              );
            }
          }
          if (this.activeOrganization) this.ready = true;
          else this.ready = false;
        },
        error: (err) => console.error(err),
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public navItemClick(item: INavbarItem) {
    if (item.path.includes('{organization_id}')) {
      this.stateService
        .getCustomerOrSalesOrganization()
        .pipe(take(1))
        .subscribe((org) => {
          const path = [...item.path];
          path[path.indexOf('{organization_id}')] = org.id;
          this.router.navigate(
            path,
            item.queryParams ? { queryParams: item.queryParams } : {}
          );
        });
      return;
    }
    this.router.navigate(
      item.path,
      item.queryParams ? { queryParams: item.queryParams } : {}
    );
  }

  public selectCustomerOrganization(org): void {
    this.stateService.setCustomerOrganization(org);
    this.activeOrganization = org;
  }

  public selectSalesOrganization(org: ApiOrganization) {
    this.stateService.setSalesOrganization(org);
    this.activeOrganization = this.sales_org;
  }

  public applyTextFilter(event: Event): void {
    const filterValue = (event.target as HTMLInputElement).value;
    if (filterValue) {
      this.filteredOrgs = [];
      this.filteredOrgs = this.organizations.filter((o) =>
        o.name.toLowerCase().includes(filterValue.toLowerCase())
      );
      this.filteredUnrelatedOrgs = [];
      this.filteredUnrelatedOrgs = this.unrelatedCustomerOrgs.filter((o) =>
        o.name.toLowerCase().includes(filterValue.toLowerCase())
      );
    } else {
      this.filteredOrgs = ObjectUtils.cloneObject(this.organizations);
      this.filteredUnrelatedOrgs = ObjectUtils.cloneObject(
        this.unrelatedCustomerOrgs
      );
    }
  }
}
