import {
  IGraphConfig,
  IOptionsConcatWith,
} from 'src/app/components/backoffice/backoffice-graphs/bo_graph';
import { ObjectUtils } from 'src/app/utils/object';

interface IDataObject {
  organization_by_pk?: {
    robot_configurations: ISerieItem[];
    simulations: ISerieItem[];
    customers: {
      customer: IDataObject;
    }[];
  };
  SIMULATIONS?: ISerieItem[];
  PARTNER_SIMULATIONS?: ISerieItem[];
  TOTAL_PLANS_SOLD?: ISerieItem[];
  UNIQUE_PATTERNS?: ISerieItem[];
  DIGITAL_TWINS?: ISerieItem[];
  COMPONENT_PROVIDERS?: ISerieItem[];
  SOLUTION_PROVIDERS?: ISerieItem[];
  SOLUTION_PROVIDER_CUSTOMERS?: ISerieItem[];
  PRODUCTS?: ISerieItem[];
  ERROR_SIMULATIONS?: ISerieItem[];
}

interface ISerieItem {
  [v: string]: ISerie[];
}

interface ISerie {
  timestamp: number;
  value: number;
}

export interface IGraphData {
  default: IDataObject;
  acc: IDataObject;
  concat: IDataObject;
  salesOrg: IDataObject;
}

export class GraphDataHelper {
  series: IGraphData = {
    default: {},
    acc: {},
    concat: {},
    salesOrg: {},
  };

  constructor(
    data: IDataObject,
    config: IGraphConfig[],
    isMarketingConfig: boolean = true
  ) {
    const d: IDataObject = ObjectUtils.cloneObject(data);

    if (isMarketingConfig) {
      this.mapMarketingSeries(d, config);
    } else {
      this.mapSalesOrgSeries(d);
    }
  }

  mapSalesOrgSeries(d: IDataObject) {
    delete d.organization_by_pk['__typename'];

    const today = new Date();
    const twoYearsAgo = new Date();
    twoYearsAgo.setFullYear(twoYearsAgo.getFullYear() - 2);

    const weeks = [];
    const one_week = 604800000;

    for (
      let index = 0;
      index < Math.ceil((today.getTime() - twoYearsAgo.getTime()) / one_week);
      index++
    ) {
      weeks.push({
        weekNo: index,
        from:
          index > 0
            ? new Date(twoYearsAgo.getTime() + one_week * index).getTime()
            : new Date(twoYearsAgo.getTime()).getTime(),
        to:
          index > 0
            ? new Date(twoYearsAgo.getTime() + one_week * (index + 1)).getTime()
            : new Date(twoYearsAgo.getTime() + one_week).getTime(),
        timestamp:
          index > 0
            ? new Date(twoYearsAgo.getTime() + one_week * (index + 1)).getTime()
            : new Date(twoYearsAgo.getTime() + one_week).getTime(),
        data: [],
        value: 0,
      });
    }

    for (let item in d.organization_by_pk) {
      this.series.salesOrg[item] = ObjectUtils.cloneObject(weeks);

      if (item === '__typename' || item === 'customers') {
        continue;
      }
      // Add in customer data
      const items = d.organization_by_pk[item].concat(
        d.organization_by_pk.customers.map((c) => c.customer[item]).flat()
      );
      items.forEach((s) => {
        delete s.__typename;
        const timeStamp = new Date(s.created_at).getTime();
        const week = this.series.salesOrg[item].find(
          (f) => f.from < timeStamp && f.to > timeStamp
        );
        week.data.push(timeStamp);
        week.value++;
      });
    }
  }

  mapMarketingSeries(d: IDataObject, config: IGraphConfig[]) {
    for (const dataArray in d) {
      if (Object.prototype.hasOwnProperty.call(d, dataArray)) {
        this.series.default[dataArray] = [];
        this.series.acc[dataArray] = [];
        this.series.concat[dataArray] = [];

        d[dataArray].forEach((serie: ISerie, index: number) => {
          for (const serieItem in serie) {
            if (serieItem !== '__typename' && serieItem !== 'timestamp') {
              const value: number = serie[serieItem];
              const timeStamp: number = this.getTimeStamp(
                serie['timestamp'].toString()
              );

              if (!this.series.default[dataArray][serieItem]) {
                this.series.default[dataArray][serieItem] = [];
              }
              if (!this.series.acc[dataArray][serieItem]) {
                this.series.acc[dataArray][serieItem] = [];
              }
              if (!this.series.concat[dataArray][serieItem]) {
                this.series.concat[dataArray][serieItem] = [];
              }

              // Concatinate
              const concatArray = this.series.concat[dataArray][serieItem];
              // Find concatinate in config
              const type = config
                .find((f) => f.type === dataArray)
                ?.optionsConfig.series.find(
                  (f) => f.id === serieItem
                )?.concatWith;

              if (type) {
                type.value = d[type.column.toUpperCase()][index][type.column];
                this.addValue(concatArray, [
                  timeStamp,
                  this.updateValue(value, null, type),
                ]);
              } else {
                // No config for concat? Add default value
                this.addValue(concatArray, [
                  timeStamp,
                  this.updateValue(value),
                ]);
              }

              const defaultArray = this.series.default[dataArray][serieItem];
              const accArray = this.series.acc[dataArray][serieItem];

              // Default
              this.addValue(defaultArray, [
                timeStamp,
                this.updateValue(concatArray[index][1]),
              ]);

              // Accumulate
              const prev = accArray[accArray.length - 1];
              this.addValue(accArray, [
                timeStamp,
                this.updateValue(concatArray[index][1], prev ? prev[1] : null),
              ]);
            }
          }
        });
      }
    }
  }

  getTimeStamp(timeStamp: string) {
    return Date.parse(timeStamp) + 518400000;
  }

  addValue(arrVariable: any[], arr: any[]) {
    if (!arrVariable) {
      arrVariable = [];
    }
    arrVariable.push(arr);
  }

  concatMethod(method: IOptionsConcatWith['method']) {
    if (method === 'subtract') {
      return '-';
    } else {
      return '+';
    }
  }

  updateValue(
    value: number,
    acc: number = null,
    concat: IOptionsConcatWith = null
  ) {
    if (concat === null && acc === null) {
      return value;
    } else {
      if (concat !== null && acc === null) {
        return eval(value + this.concatMethod(concat.method) + concat.value);
      }
      if (concat === null && acc !== null) {
        return value + acc;
      }
      if (concat !== null && acc !== null) {
        return (
          eval(value + this.concatMethod(concat.method) + concat.value) + acc
        );
      }
    }
  }
}
