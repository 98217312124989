import { jsPDF } from 'jspdf';

// Missing methods on FontFaceSet to suppress TS warning.
// Web API feature available from Chrome 35 (2014), Firefox 41 (2015), Safari 10 (2016).
interface FontFaceSetExtended {
  add: (FontFace: any) => FontFaceSet;
}

export async function addElementToPDF(
  doc: jsPDF,
  parentElement: HTMLElement,
  x: number,
  y: number
) {
  return new Promise(async (resolve, _) => {
    const elementClone = parentElement.cloneNode(true) as HTMLElement;

    // The foreground color on cards. Usually blue on white cards, and white on colored cards.
    const cardColor =
      getComputedStyle(parentElement).getPropertyValue('--cardColor');

    // Canvas used to render svgs (mat-icon) to rasterized images.
    const canvas = document.createElement('CANVAS') as HTMLCanvasElement;
    document.body.appendChild(canvas);

    // Convert all images to data URLs to contain them within the PDF.
    await Promise.all(
      Array.from(elementClone.querySelectorAll('img')).map(async (el) => {
        const element = el as HTMLImageElement;
        const image = await renderImage(el.src, canvas, true);
        element.src = image;
      })
    );

    // Modifications to mat-card to render them properly in the PDF.
    await Promise.all(
      Array.from(elementClone.querySelectorAll('mat-card')).map(async (el) => {
        const element = el as HTMLElement;
        const classAttrib = element.getAttribute('class');
        const successState = successStateFromClassList(classAttrib);
        const backgroundColor = getComputedStyle(
          parentElement
        ).getPropertyValue('--' + (successState || 'no-state-sim'));

        // SVGs are rasterized through a canvas due to limitations of PDF library.
        const color = !!successState ? 'white' : cardColor;
        await replaceMatIconsWithRasterizedImages(element, canvas, color);

        // The PDF-library cannot understand `mat-icon`, so we convert to div.
        element.outerHTML = `<div style="background-color: ${backgroundColor};" class="mat-card-pdf ${classAttrib}">${element.innerHTML}</div>`;
      })
    );

    // SVGs cannot be rendered in the PDF, so we find all MatIcons and
    // render them to images on a canvas.
    await replaceMatIconsWithRasterizedImages(elementClone, canvas, null);

    // Set the correct color on the card subtitle.
    elementClone.querySelectorAll('mat-card-subtitle').forEach((el) => {
      const element = el as HTMLElement;
      element.style.color = 'var(--cardColor)';
    });

    canvas.remove();

    // Set a constant width to make PDF look the same on all screen sizes.
    const windowWidth = 1400;
    doc.html(elementClone, {
      callback: resolve,
      x: x,
      y: y,
      width: 200,
      margin: 5,
      windowWidth: windowWidth,
      html2canvas: {
        windowWidth: windowWidth,
        width: windowWidth,
      },
      autoPaging: 'text',
      fontFaces: [
        {
          family: 'Montserrat',
          src: [
            {
              url: '/assets/fonts/Montserrat-Regular.ttf',
              format: 'truetype',
            },
          ],
        },
      ],
    });
  });
}

export async function replaceMatIconsWithRasterizedImages(
  element: HTMLElement,
  canvas: HTMLCanvasElement,
  color: string
) {
  canvas.width = 48;
  canvas.height = 48;

  return Promise.all(
    Array.from(element.querySelectorAll('mat-icon')).map(async (el) => {
      // Skip if element should not be shown in PDF. E.g. help icons.
      if (el.classList.contains('pdf-hide')) {
        return;
      }

      const iconName = el.innerHTML;
      const imageNode = document.createElement('IMG') as HTMLImageElement;
      imageNode.width = 24;

      if (iconName.startsWith('<svg')) {
        imageNode.src = await renderSVGAsImage(iconName, canvas);
      } else {
        imageNode.src = await renderIconAsImage(iconName, canvas, color);
      }

      el.replaceWith(imageNode);
    })
  );
}

export async function renderIconAsImage(
  iconName: string,
  canvas: HTMLCanvasElement,
  color: string
): Promise<string> {
  try {
    const materialIconsFont = new FontFace(
      'MaterialIcons',
      'url(https://fonts.gstatic.com/s/materialicons/v140/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)'
    );
    // Add font so it can be referenced in canvas.
    const documentFonts = document.fonts as FontFaceSet & FontFaceSetExtended;

    if (documentFonts.add === undefined) {
      // The icon will still be rendered, but will dot be displayed correctly.
      throw new Error('Requires newer browser version');
    }

    if (!documentFonts.check('12px MaterialIcons')) {
      documentFonts.add(materialIconsFont);
      await materialIconsFont.load();
    }
  } catch (error) {
    console.debug('Could not load font', error);
  }

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const ctx = canvas.getContext('2d')!;
  ctx.clearRect(0, 0, canvas.width, canvas.height);
  ctx.fillStyle = color;
  ctx.font = `${canvas.width}px MaterialIcons`;
  ctx.fillText(iconName, 0, canvas.width);
  return canvas.toDataURL('image/png');
}

export async function renderSVGAsImage(
  svg: string,
  canvas: HTMLCanvasElement
): Promise<string> {
  const svgBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
  const url = URL.createObjectURL(svgBlob);

  return await renderImage(url, canvas, false);
}

export async function renderImage(
  url: string,
  canvas: HTMLCanvasElement,
  adaptSize: boolean
): Promise<string> {
  return new Promise((resolve, _) => {
    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = function () {
      if (adaptSize) {
        canvas.width = img.width;
        canvas.height = img.height;
      }
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const ctx = canvas.getContext('2d')!;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(img, 0, 0);
      const image = canvas.toDataURL('image/png');
      resolve(image);
    };

    img.src = url;
  });
}

export function waitForElement(
  selector: string,
  document: Document
): Promise<any> {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((_) => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true,
    });
  });
}

export function successStateFromClassList(classList: string): string {
  const states = ['success-sim', 'error-sim', 'default-sim'];
  return states.find((state) => classList.includes(state));
}
