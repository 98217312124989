import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import {
  catchError,
  combineLatest,
  map,
  Observable,
  of,
  switchMap,
} from 'rxjs';
import { UpdateResponse } from './api.service';
import { ObjectUtils } from '../../utils/object';
import { ClientApiService } from './client-api.service';
import {
  ApiInstalledRobot,
  IInstalledRobotLicenceInfo,
} from '../../models_new/classes/api-models/ApiRobotInstallation';
import { ApiScene } from '../../models_new/classes/api-models/ApiScene';
import { LicenseApiService } from './license-api.service';
import { WaypointsApiService } from './waypoints-api.service';

export interface IUrSimContainerStarted {
  fileShareUrl: string;
  ip: string;
  port: string;
}

export interface IUrSimContainerAssets {
  installations: IUrSimContainerAsset[];
  others: IUrSimContainerAsset[];
  programs: IUrSimContainerAsset[];
}

export interface IUrSimContainerAsset {
  name: string;
  url: string;
}

export interface IUrSimContainerAssetInsert {
  url: string;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class InstalledRobotsApiService {
  constructor(
    private clientApi: ClientApiService,
    private licenseApi: LicenseApiService,
    private waypointsApi: WaypointsApiService
  ) {}

  /* ------------------------ Database query functions ------------------------ */

  /**
   * @param polyscopeVersion as string
   * @param robot as string
   */
  startUrSimContainer(
    polyscopeVersion: string,
    robot: string
  ): Observable<IUrSimContainerStarted> {
    const q = gql`
      query startUrSimContainer {
        startUrsimContainer(
          ursimInput: { polyscopeVersion: "${polyscopeVersion}", robotModel: "${robot}" }
        ) {
          fileShareUrl
          ip
          port
        }
      }
    `;

    return this.clientApi
      .useClient('org_admin')
      .query<any>({
        query: q,
      })
      .pipe(
        map((res) => {
          if (res.errors) {
            throw new Error(res.errors[0].message);
          } else {
            return res.data.startUrsimContainer;
          }
        })
      );
  }

  getUrSimAssets(): Observable<IUrSimContainerAssets> {
    const q = gql`
      query getUrSimAssets {
        getUrsimAssets {
          assets
        }
      }
    `;

    return this.clientApi
      .useClient('org_admin')
      .query<any>({
        query: q,
      })
      .pipe(
        map((res) => {
          if (res.errors) {
            throw new Error(res.errors[0].message);
          } else {
            const assetsMapToArray: IUrSimContainerAssets = {
              installations: [],
              others: [],
              programs: [],
            };

            if (res.data.getUrsimAssets.assets) {
              const assets = res.data.getUrsimAssets.assets;

              const pushToList = (
                list: 'installations' | 'others' | 'programs',
                value: string
              ) => {
                assetsMapToArray[list].push({
                  name: value,
                  url: assets[list][value].url,
                });
              };

              if (assets.installations) {
                for (let i in assets.installations) {
                  pushToList('installations', i);
                }
              }
              if (assets.others) {
                for (let i in assets.others) {
                  pushToList('others', i);
                }
              }
              if (assets.programs) {
                for (let i in assets.programs) {
                  pushToList('programs', i);
                }
              }

              return assetsMapToArray;
            } else {
              return assetsMapToArray;
            }
          }
        })
      );
  }

  /**
   * @param fileBase64 file as base64 format
   */
  insertUrSimAsset(
    fileBase64: string,
    asset_type: 'urp' | 'installation',
    file_type: string,
    name: string,
    organization_id: string,
    isURSimSession: boolean = true
  ): Observable<IUrSimContainerAssetInsert> {
    const m = gql`
    mutation insertUrSimAsset($data: String!) {
      uploadAsset(asset_type: "${asset_type}", data: $data, file_type: "${file_type}", name: "${name}", organization_id: "${organization_id}", isURSimSession: ${isURSimSession}) {
        url
        id
      }
    }`;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          data: fileBase64,
        },
      })
      .pipe(
        map((res) => {
          if (res.errors) {
            throw new Error(res.errors[0].message);
          } else {
            return res.data.uploadAsset;
          }
        })
      );
  }

  fetchOrgInstalledRobots(org_id: string): Observable<ApiInstalledRobot[]> {
    const q = gql`
      subscription GetLiteInstalledRobots {
        installed_robots(where: {organization_id: {_eq: "${org_id}"}}) {
          id
          name
          robot_serial_number
          created_at
          updated_at
          robot_configuration: installed_robots_robot_configuration {
            id
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('GetFullInstalledRobots failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get robot installations: ' + data.errors[0].message
            );
          } else {
            const list = [];
            for (const s of data.data.installed_robots) {
              const robot = new ApiInstalledRobot(ObjectUtils.cloneObject(s));
              list.push(robot);
            }

            return list;
          }
        }),
        switchMap((robots) => {
          if (!robots.length) {
            return of([]);
          }

          const licenseRequests$ = robots.map((robot) =>
            this.licenseApi
              .getValidLicenseForRobotSerialNumber(
                org_id,
                robot.robot_serial_number
              )
              .pipe(
                catchError((error) => {
                  console.warn(
                    `Failed to fetch license for robot ${robot.robot_serial_number}`,
                    error
                  );
                  return of(null); // Return null or a default value in case of error
                })
              )
          );

          const waypointRequests$ = robots.map((robot) =>
            this.waypointsApi
              .fetchWaypointsByInstalledRobotIdLigth(robot.id, org_id)
              .pipe(
                catchError((error) => {
                  console.warn(
                    `Failed to fetch waypoints for robot ${robot.id}`,
                    error
                  );
                  return of([]); // Return empty array in case of error
                })
              )
          );

          // Use combineLatest to combine license and waypoint requests
          return combineLatest([
            combineLatest(licenseRequests$),
            combineLatest(waypointRequests$),
          ]).pipe(
            map(([licenses, waypoints]) => {
              for (let i = 0; i < robots.length; i++) {
                robots[i].installed_robot_sw_license = licenses[i];
                robots[i].waypoints = waypoints[i];
              }
              return robots;
            })
          );
        })
      );
  }

  fetchInstalledRobotBackupZipId(robot_id: string) {
    const q = gql`
      query GetInstalledRobotBackupZips {
        installed_robots_by_pk(id: "${robot_id}") {
        backup_zip_id
       }
      }`;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('GetInstalledRobotBackupZips failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get robot backup zips: ' + data.errors[0].message
            );
          } else {
            return data.data.installed_robots_by_pk.backup_zip_id;
          }
        })
      );
  }

  fetchInstalledRobotsByID(
    id: string,
    lite: boolean = true
  ): Observable<ApiInstalledRobot> {
    // Full, unrestrained query
    const q = gql`
      subscription GetFullInstalledRobotByID {
        installed_robots_by_pk(id: "${id}") {
          id
          name
          organization {
            id
            name
          }
          robot_serial_number
          installed_robot_sw_licenses {
            id
            license_valid_to
          }
          installed_robots_calibration {
            created_at
            id
            pally_version: data(path: "file_info.pally_version")
          }
          mac_address
          backup_zip_id
          calibration_id
          created_at
          updated_at
          robot_configuration_id
          robot_configuration: installed_robots_robot_configuration {
            id
            name
            owner_id
            scene {
              id
              data
              name
              description
              simulations {
                id
                name
                updated_at
                simulation_state
                simulation_status
                error_details
                cpm_requested: static_report_data(path: "$pattern['data']['cpm']")
                pattern {
                  id
                  name
                  product {
                    name
                  }
                  data
                }
                scene {
                  id
                  name
                }
                strategy {
                  id
                  name
                }
              }
              image {
                url
              }
            }
            strategy {
              id
              name
              description
              data
            }
          }
        }
      }
    `;
    // Lite, unrestrained query
    const ql = gql`
      subscription GetLiteInstalledRobotByID {
        installed_robots_by_pk(id: "${id}") {
          id
          name
          robot_serial_number
          mac_address
          backup_zip_id
          organization {
            id
            name
          }
          robot_configuration: installed_robots_robot_configuration {
            id
            name
          }
          created_at
          updated_at
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: lite ? ql : q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group(
              `${
                lite ? 'GetLiteInstalledRobotByID' : 'GetFullInstalledRobotByID'
              } failed: `
            );
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get robot installations: ' + data.errors[0].message
            );
          } else {
            return new ApiInstalledRobot(
              ObjectUtils.cloneObject(data?.data?.installed_robots_by_pk)
            );
          }
        })
      );
  }

  fetchInstalledRobotHardwareBySerialnumber(
    serial_number: string
  ): Observable<ApiScene> {
    // Full, unrestrained query
    const q = gql`
      query GetInstalledRobotHardwareBySerialnumber($serial_number: String!) {
        installed_robots(
          where: { robot_serial_number: { _eq: $serial_number } }
        ) {
          robot_configuration: installed_robots_robot_configuration {
            scene {
              id
              data
              name
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .query<any>({
        query: q,
        variables: { serial_number: serial_number },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('GetInstalledRobotHardwareBySerialnumber failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get robot installations: ' + data.errors[0].message
            );
          } else {
            if (data?.data?.installed_robots?.length === 0) {
              throw Error('No installed robots');
            }

            if (
              data.data.installed_robots[0].robot_configuration?.length === 0
            ) {
              throw Error('No robot configuration for installed robot');
            }

            const scene =
              data.data.installed_robots[0].robot_configuration[0].scene;

            return scene
              ? new ApiScene(ObjectUtils.cloneObject(scene))
              : undefined;
          }
        })
      );
  }

  fetchInstalledRobotLicenceInfoByID(
    id: string
  ): Observable<IInstalledRobotLicenceInfo> {
    // Full, unrestrained query
    const q = gql`
      query GetFullInstalledRobots {
        installed_robots_by_pk(id: "${id}") {
          id
          name
          organization {
            id
            name
          }
          robot_serial_number
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('GetFullInstalledRobots failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get robot installations: ' + data.errors[0].message
            );
          } else {
            return ObjectUtils.cloneObject(data.data.installed_robots_by_pk);
          }
        })
      );
  }

  /**
   *
   * @param params
   * @param properties
   * @param variables
   * @returns
   */

  insertInstalledRobotOne(
    variables: any,
    params: string = `$name: String! $organization_id: uuid!`,
    properties: string = `name: $name organization_id: $organization_id`
  ): Observable<{ id: string; name: string }> {
    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: gql`
      mutation insertInstalledRobot(
      ${params}
      ) {
      insert_installed_robots_one(
        object: {
        ${properties}
        }
      ) {
        id
        name
      }
      }
    `,
        variables: variables,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('insertInstalledRobot failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to insert installed robot: ' + data.errors[0].message
            );
          } else {
            return {
              id: data.data.insert_installed_robots_one.id,
              name: data.data.insert_installed_robots_one.name,
            };
          }
        }),
        catchError((error) => {
          console.error('Error inserting installed robot:', error);
          throw error;
        })
      );
  }

  // Only supports zip for now
  insertInstalledRobotsFromBackupZip(
    name: string,
    org_id: string,
    zip_id: string,
    robot_config_id?: string,
    serial_number?: string,
    mac_address?: string
  ): Observable<{ id: string; name: string }> {
    let params = `$name: String!
    $organization_id: uuid!
    $robot_serial_number: String!
    $mac_address: String!
    $backup_zip_id: uuid!
    `;

    let properties = `name: $name
    organization_id: $organization_id
    backup_zip_id: $backup_zip_id
    robot_serial_number: $robot_serial_number
    mac_address: $mac_address
    `;
    const variables = {
      name: name,
      organization_id: org_id,
      robot_serial_number: serial_number,
      mac_address: mac_address,
      backup_zip_id: zip_id,
    };
    if (robot_config_id) {
      params += '$robot_configuration_id: uuid!';
      properties += 'robot_configuration_id: $robot_configuration_id';
      variables['robot_configuration_id'] = robot_config_id;
    }

    return this.insertInstalledRobotOne(variables, params, properties);
  }

  insertInstalledRobotsFromCalibration(
    name: string,
    org_id: string,
    calibration_id?: string,
    robot_config_id?: string,
    serial_number?: string,
    mac_address?: string
  ): Observable<{ id: string; name: string }> {
    let params = `$name: String!
    $organization_id: uuid!
    $robot_serial_number: String!
    $mac_address: String!
    $calibration_id: uuid
    `;

    let properties = `name: $name
    organization_id: $organization_id
    calibration_id: $calibration_id
    robot_serial_number: $robot_serial_number
    mac_address: $mac_address
    `;
    const variables = {
      name: name,
      organization_id: org_id,
      robot_serial_number: serial_number,
      mac_address: mac_address,
      calibration_id: calibration_id,
    };
    if (robot_config_id) {
      params += '$robot_configuration_id: uuid!';
      properties += 'robot_configuration_id: $robot_configuration_id';
      variables['robot_configuration_id'] = robot_config_id;
    }

    return this.insertInstalledRobotOne(variables, params, properties);
  }

  public insertInstalledRobotFromExistingEntry(
    name: string,
    org_id: string,
    zip_id: string,
    calibration_id: string,
    robot_config_id?: string,
    serial_number?: string,
    mac_address?: string
  ): Observable<{ id: string; name: string }> {
    let params = `$name: String!
    $organization_id: uuid!
    $robot_serial_number: String
    $mac_address: String
    $backup_zip_id: uuid
    $calibration_id: uuid
    `;
    let properties = `name: $name
    organization_id: $organization_id
    backup_zip_id: $backup_zip_id
    calibration_id: $calibration_id
    robot_serial_number: $robot_serial_number
    mac_address: $mac_address
    `;
    const variables = {
      name: name,
      organization_id: org_id,
      robot_serial_number: serial_number,
      mac_address: mac_address,
      backup_zip_id: zip_id,
      calibration_id: calibration_id,
    };
    if (robot_config_id) {
      params += '$robot_configuration_id: uuid';
      properties += 'robot_configuration_id: $robot_configuration_id';
      variables['robot_configuration_id'] = robot_config_id;
    }
    return this.insertInstalledRobotOne(variables, params, properties);
  }

  updateInstalledRobots(
    id: string,
    name: string,
    org_id: string,
    robot_config_id: string,
    serial_number: string,
    mac_address: string,
    zip_id: string,
    calibration_id: string
  ): Observable<{
    id: string;
    installed_robots_robot_configuration: { id: string };
  }> {
    const m = gql`
      mutation updateInstalledRobots(
        $id: uuid!
        $name: String!
        $organization_id: uuid!
        $robot_configuration_id: uuid!
        $robot_serial_number: String!
        $mac_address: String!
        $backup_zip_id: uuid!
        $calibration_id: uuid!
      ) {
        update_installed_robots_by_pk(
          pk_columns: { id: $id }
          _set: {
            name: $name
            organization_id: $organization_id
            robot_configuration_id: $robot_configuration_id
            robot_serial_number: $robot_serial_number
            mac_address: $mac_address
            backup_zip_id: $backup_zip_id
            calibration_id: $calibration_id
          }
        ) {
          id
          installed_robots_robot_configuration {
            id
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          id: id,
          name: name,
          organization_id: org_id,
          robot_configuration_id: robot_config_id,
          robot_serial_number: serial_number,
          mac_address: mac_address,
          backup_zip_id: zip_id,
          calibration_id: calibration_id,
        },
      })
      .pipe(
        map((value) => {
          if (value.errors) {
            console.group('updateInstalledRobots failed: ');
            value.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to update installed robot: ' + value.errors[0].message
            );
          }
          return value.data.update_installed_robots_by_pk;
        })
      );
  }

  updateInstalledRobotsCalibration(
    id: string,
    calibration_id: string,
    robot_serial_number: string,
    name: string
  ): Observable<string> {
    const m = gql`
      mutation updateInstalledRobots(
        $id: uuid!
        $calibration_id: uuid!
        $robot_serial_number: String!
        $name: String!
      ) {
        update_installed_robots_by_pk(
          pk_columns: { id: $id }
          _set: {
            calibration_id: $calibration_id
            robot_serial_number: $robot_serial_number
            name: $name
          }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          id: id,
          calibration_id: calibration_id,
          robot_serial_number: robot_serial_number,
          name: name,
        },
      })
      .pipe(map((value) => value.data.update_installed_robots_by_pk));
  }

  deleteInstalledRobots(id: string): Observable<UpdateResponse> {
    const m = gql`
    mutation deleteInstalledRobots {
      delete_installed_robots_by_pk(id: "${id}") {
        id
      }
    }`;

    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
      })
      .pipe(map((data) => data.data.delete_installed_robots_by_pk));
  }
}
