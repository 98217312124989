import { Injectable } from '@angular/core';
import { Observable, of, forkJoin } from 'rxjs';
import {
  take,
  shareReplay,
  switchMap,
  catchError,
  map,
  tap,
} from 'rxjs/operators';
import { IInfoCardConfig } from 'src/app/models_new/types/info-card-config';
import { gql } from 'apollo-angular';
import { ClientApiService } from './client-api.service';
import { IDefaultSimConfig, IReportConfig } from './api.service';
import { IUserSimApi } from 'src/app/models_new/types/user-sim-api';
import { ObjectUtils } from 'src/app/utils/object';
import { ISimulationRequest } from 'src/app/models_new/types/simulation-request';
import { ISimulationApiFileType } from 'src/app/models_new/types/simulation-api-file-type';
import { countries as fallbackCuntryList } from 'src/app/models_new/config/countries';
import { unitedKingdom } from 'src/app/models_new/config/countries';
import { IApiOpenSimulation } from 'src/app/models_new/classes/api-models/ApiOpenSimulation';
import { ApiPattern } from 'src/app/models_new/classes/api-models/ApiPattern';
import { ApiProduct } from 'src/app/models_new/classes/api-models/ApiProduct';
import { ErrorHandlerService } from '../error-handler.service';
import { IReadyOpenSimulation } from 'src/app/components/fast-track/open-sim.service';
import { SimulationStatus } from 'src/app/models_new/enums/simulation-status';
import { environment } from 'src/environments/environment';
import { Document as richtextDocument } from '@contentful/rich-text-types';
import {
  ILandingOnboardContentful,
  IRawLandingOnboardContentful,
} from 'src/app/models_new/types/landing-contentful-data';
import { settings } from 'src/app/models_new/config/application-settings';
import { ISignupUserType } from '../../models_new/types/signup-user-type';

export interface ILandingContentful {
  logo: {
    url: string;
  };
  mainText: {
    json: richtextDocument;
  };
  ctaLink: string;
  ctaText: string;
  ctaIcon: string;
  leftBackgroundImage: {
    url: string;
  };
  rightBackgroundImage: {
    url: string;
  };
  overlayImage: {
    url: string;
  };
  cardsCollection: {
    items: {
      backgroundColor: string;
      textColor: string;
      category: string;
      title: string;
      link: string;
      buttonText: string;
      text: {
        json: richtextDocument;
      };
    }[];
  };
}
export interface IHasuraSimulationRequest {
  readonly id?: string;
  e_mail: string;
  data: object;
}

export interface ICountryNState {
  value: string;
  name: string;
  country?: string;
  emoji?: string;
  continent?: {
    name: string;
  };
}

export interface IContactInfo {
  email: string;
  phone?: string;
  details?: string;
}

export interface IFastTrackQueryParams {
  vendorName?: string;
  vendorId?: string;
  providerId?: string;
  solutionId?: string;
  metadata?: any;
}

export interface IVendorAndSolution {
  default_sim_config: { solution_provider_id: string; id: string }[];
  vendor_organizations: IVendorOrganization[];
}

export interface IVendorOrganization {
  name: string;
  id: string;
  organization?: {
    id: string;
    name: string;
  };
  label: string;
}

/**
 * All public request should be made here
 */
@Injectable({
  providedIn: 'root',
})
export class PublicApiService {
  mrcLogoWhite: string = '../../../assets/mrc-logo/MRC-logo-white.png';
  mrcLogoBlue: string = '../../../assets/mrc-logo/MRC-logo-blue.png';
  pallyLogoBlue: string = '../../../assets/dummy/pally_logo_blue.png';

  constructor(
    private clientApi: ClientApiService,
    private errorHandler: ErrorHandlerService
  ) {
    this.getContentfulAsset(settings.mrcLogoBlue)
      .pipe(
        take(1),
        catchError((err) => {
          console.warn('Failed to get blue logo, using dummy logo', err);
          return of('../../../assets/mrc-logo/MRC-logo-blue.png');
        }),
        tap((url: string) => (this.mrcLogoBlue = url))
      )
      .subscribe();

    this.getContentfulAsset(settings.mrcLogoWhite)
      .pipe(
        take(1),
        catchError((err) => {
          console.warn('Failed to get white logo, using dummy logo', err);
          return of('../../../assets/mrc-logo/MRC-logo-white.png');
        }),
        tap((url: string) => (this.mrcLogoWhite = url))
      )
      .subscribe();
  }

  getContentfulAsset(assetId: string): Observable<string> {
    const q = gql`
      query getContentfulAsset {
        asset(id: "${assetId}") {
          url
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<{ asset: { url: string } }>({
        query: q,
      })
      .pipe(
        map((data) => {
          return data.data.asset.url;
        })
      );
  }

  /**
   * @returns ILandingOnboardContentful
   */

  public getLandingContent(): Observable<ILandingOnboardContentful> {
    const q = gql`
      query getLandingOnboardContent($contentful_id: String!) {
        landingOnboardContent(id: $contentful_id) {
          headerText {
            json
          }
          headerActionButtons
          headerCarouselCollection {
            items {
              description
              title
              url
            }
          }
          userGroupsHeader {
            json
          }
          userGroupsCardsCollection {
            items {
              title
              icon {
                url
              }
              subtitle {
                json
              }
              featureHighlights
              card1Title
              card1Image {
                url
              }
              card1Description {
                json
              }
              card2Title
              card2Image {
                url
              }
              card2Description {
                json
              }
              card3Title
              card3Image {
                url
              }
              card3Description {
                json
              }
              card4Title
              card4Image {
                url
              }
              card4Description {
                json
              }
            }
          }
          testimonialsHeader {
            json
          }
          testimonialsCollection {
            items {
              name
              organizationAndLocation
              valoration
              jobTitle
              comment {
                json
              }
              profileImage {
                url
              }
            }
          }
          testimonialsOrganizationLogosCollection {
            items {
              url
              title
            }
          }
          learnMoreDigitalTwin {
            json
          }
          learnMoreDigitalTwinCompareAssetsCollection {
            items {
              url
              title
            }
          }
          learnMoreTryNow {
            json
          }
          learnMoreTryNowActionButtons
          learnMoreFaqHeader {
            json
          }
          learnMoreFaq
          contactFormActionButtons
          contactFormHeader {
            json
          }
          contactFormPrivacyDisclaimer {
            json
          }
          links
        }
      }
    `;

    const variables = {
      contentful_id: environment.contentfulLandingPageId,
    };

    return this.clientApi
      .useClient('public')
      .query<{ landingOnboardContent: IRawLandingOnboardContentful }>({
        query: q,
        variables: variables,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.logError(data.errors[0]);
            return null;
          }
          let rawContent = ObjectUtils.cloneObject(
            data.data.landingOnboardContent
          );
          let groupSubCards: ILandingOnboardContentful['userGroups']['cards'] =
            [];
          rawContent.userGroupsCardsCollection?.items.forEach((group) => {
            group.subCards = [
              {
                title: group.card1Title,
                image:
                  group.card1Image?.url ||
                  '../../assets/illustrative/dashboard_card_customers.png',
                description: group.card1Description?.json,
              },
              {
                title: group.card2Title,
                image:
                  group.card2Image?.url ||
                  '../../assets/illustrative/dashboard_card_hw_lib.png',
                description: group.card2Description?.json,
              },
              {
                title: group.card3Title,
                image:
                  group.card3Image?.url ||
                  '../../assets/illustrative/dashboard_card_patterns.png',
                description: group.card3Description?.json,
              },
              {
                title: group.card4Title,
                image:
                  group.card4Image?.url ||
                  '../../assets/illustrative/dashboard_card_simulations.png',
                description: group.card4Description?.json,
              },
            ];
            groupSubCards.push({
              title: group.title,
              subtitle: group.subtitle,
              featureHighlights: group.featureHighlights,
              subCards: group.subCards,
              icon: group.icon,
            });
          });

          return {
            header: {
              text: rawContent.headerText?.json,
              actions: rawContent.headerActionButtons,
              images: rawContent.headerCarouselCollection?.items,
            },
            userGroups: {
              text: rawContent.userGroupsHeader?.json,
              cards: groupSubCards,
            },
            testimonials: {
              text: rawContent.testimonialsHeader?.json,
              testimonials: rawContent.testimonialsCollection?.items,
              logos: rawContent.testimonialsOrganizationLogosCollection?.items,
            },
            learnMore: {
              digitalTwin: {
                text: rawContent.learnMoreDigitalTwin?.json,
                images:
                  rawContent.learnMoreDigitalTwinCompareAssetsCollection?.items,
              },
              tryItNow: {
                text: rawContent.learnMoreTryNow?.json,
                actions: rawContent.learnMoreTryNowActionButtons,
              },
              faq: {
                text: rawContent.learnMoreFaqHeader?.json,
                faq: rawContent.learnMoreFaq,
              },
            },
            contact: {
              text: rawContent.contactFormHeader?.json,
              actions: rawContent.contactFormActionButtons,
              disclaimer: rawContent.contactFormPrivacyDisclaimer?.json,
            },
            links: rawContent.links,
          };
        }),
        shareReplay({ bufferSize: 1, refCount: true }),
        catchError((e) => {
          this.errorHandler.logError(e);
          return of(null);
        })
      );
  }

  getAvailableSolutions(
    continent: string,
    country: string,
    email: string,
    fullName: string,
    region: string,
    queriedParams: IFastTrackQueryParams
  ) {
    const qv = gql`
      query getAvailableSolutions(
        $vn: [String]
        $vi: [uuid]
        $sp: [uuid]
        $si: [uuid]
      ) {
        getAvailableSolutions(simulationInput: {continent: "${continent}", country: "${country}", email: "${email}", name: "${fullName}", region: "${
      region || ' '
    }", vendorId: $vi, vendorName: $vn, solutionId: $si, solutionProviderId: $sp}) {
          ids {
            sceneId
            strategyId
            name
            solutionId
            metadata
            externalUrl
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<{ getAvailableSolutions: { ids: IReadyOpenSimulation[] } }>({
        query: qv,
        fetchPolicy: 'no-cache',
        variables: {
          vn: queriedParams.vendorName
            ? queriedParams.vendorName.split(',')
            : [],
          vi: queriedParams.vendorId ? queriedParams.vendorId.split(',') : [],
          sp: queriedParams.providerId
            ? queriedParams.providerId.split(',')
            : [],
          si: queriedParams.solutionId
            ? queriedParams.solutionId.split(',')
            : [],
        },
      })
      .pipe(
        map((res) => {
          if (res.errors) {
            this.errorHandler.handleError(res.errors[0]);
            return [];
          } else {
            let as: IReadyOpenSimulation[] = ObjectUtils.cloneObject(
              res.data.getAvailableSolutions.ids
            );
            return as.map((a) => {
              const { name, metadata, externalUrl, ...rest } = a;
              return {
                ...rest,
                solution: {
                  name,
                  metadata,
                  externalUrl,
                },
                progress: 0,
                status: SimulationStatus.CREATED,
              } as IReadyOpenSimulation;
            });
          }
        })
      );
  }

  getSolutionByID(id: string): Observable<{
    id: string;
    name: string;
    solition_provider_id: string;
    solution_provider: {
      name: string;
    };
    vendor_ids: string[];
  }> {
    const qv = gql`
      query getAvailableSolutions {
        default_sim_config_by_pk(id: "${id}") {
          id
          name
          solution_provider_id
          solution_provider: organization {
            name
          }
          vendor_ids
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<{
        default_sim_config_by_pk: {
          id: string;
          name: string;
          solition_provider_id: string;
          solution_provider: {
            name: string;
          };
          vendor_ids: string[];
        };
      }>({
        query: qv,
      })
      .pipe(
        map((res) => {
          if (res.errors) {
            this.errorHandler.handleError(res.errors[0]);
            return undefined;
          } else {
            return ObjectUtils.cloneObject(res?.data?.default_sim_config_by_pk);
          }
        })
      );
  }

  getOpenSimulationsByPatternId(
    patternId: string
  ): Observable<IReadyOpenSimulation[]> {
    const q = gql`
      subscription getOpenSimulationsByPatternId {
        open_simulation(where: {open_pattern: {id: {_eq: "${patternId}"}}}) {
          id
          name
          status: simulation_state
          progress: simulation_status(path: "progress"),
          cpm: simulation_status(path: "cpm"),
          eta: simulation_status(path: "eta"),
          solution: default_sim_config {
            name
            solution_provider_id
            solution_provider: organization {
              name
            }
          }
        }
      }
    `;
    return this.clientApi
      .useClient('public', 'ws')
      .subscribe<{ open_simulation: IReadyOpenSimulation[] }>({
        query: q,
      })
      .pipe(
        map((res) => {
          if (res.errors) {
            this.errorHandler.handleError(res.errors[0]);
            return [];
          } else {
            return res.data.open_simulation;
          }
        })
      );
  }

  insertOpenProduct(product: ApiProduct, email: string) {
    const m = gql`
    mutation insertOpenProduct($data: json!) {
      insert_open_product_one(object: {data: $data, email: "${email}", name: "${product.name}"}) {
        id
      }
    }
  `;

    return this.clientApi
      .useClient('public')
      .mutate<{ insert_open_product_one: { id: string } }>({
        mutation: m,
        variables: {
          data: product.data,
        },
      })
      .pipe(
        map((m) => {
          return m.data.insert_open_product_one.id;
        })
      );
  }

  insertOpenPattern(
    palletizingPattern: ApiPattern,
    email: string,
    product_id: string,
    name: string,
    cpm: string,
    fullName: string,
    queriedParams: IFastTrackQueryParams
  ) {
    const m = gql`
    mutation insertOpenPattern($data: json!, $metadata: jsonb) {
      insert_open_pattern_one(object: {data: $data, email: "${email}", name: "${name}", product_id: "${product_id}", metadata: $metadata}) {
        id
      }
    }
  `;

    return this.clientApi
      .useClient('public')
      .mutate<{ insert_open_pattern_one: { id: string } }>({
        mutation: m,
        variables: {
          data: palletizingPattern.data,
          metadata: {
            vi: queriedParams.vendorId,
            vn: queriedParams.vendorName,
            si: queriedParams.solutionId,
            sp: queriedParams.providerId,
            cpm: cpm,
            fullName: fullName,
          },
        },
      })
      .pipe(
        map((m) => {
          return m.data.insert_open_pattern_one.id;
        })
      );
  }

  validateEmbedApiKey(apiKey: string, referrer: string): Observable<boolean> {
    return this.clientApi
      .useClient('public')
      .query<{ ValidateEmbed: { message: string } }>({
        query: gql`
          query validateEmbedApiKey {
            ValidateEmbed(input: {key: "${apiKey}", referrer: "${referrer}"}) {
              message
            }
          }
        `,
      })
      .pipe(
        map((data) => {
          if (data.errors && data.errors[0].message === 'Access Denied') {
            return false;
          }

          return data.data.ValidateEmbed.message === 'Access Granted';
        })
      );
  }

  insertOpenSimulations(
    palletizingPattern: ApiPattern,
    openSim: IApiOpenSimulation,
    as: IReadyOpenSimulation[],
    queriedParams: IFastTrackQueryParams
  ): Observable<string> {
    const name =
      'opensim_' +
      palletizingPattern.product.name.replace(' ', '_') +
      '_' +
      new Date().toISOString();

    const insertOpenSim = (
      pattern_id: string,
      a: IReadyOpenSimulation
    ): Observable<string> => {
      const m = gql`
        mutation insertOpenSimulation($lead_owner: uuid!, $lead_owner_type: lead_owner_type_enum!) {
          insert_open_simulation_one(object: {lead_owner: $lead_owner, lead_owner_type: $lead_owner_type, solution_id: "${a.solutionId}", simulation_state: Queued, strategy_id: "${a.strategyId}", scene_id: "${a.sceneId}", metadata: {}, country: "${openSim.country}", email: "${openSim.email}", name: "${a.solution.name}", pattern_id: "${pattern_id}", organization_name: "${openSim.organization_name}", phase_of_research: "${openSim.phase_of_research}", region: "${openSim.region}"}) {
            id
          }
        }
      `;

      return this.clientApi
        .useClient('public')
        .mutate<any>({
          mutation: m,
          variables: {
            lead_owner: a.leadOwner,
            lead_owner_type: a.leadOwnerType,
          },
        })
        .pipe(
          map((m) => {
            return m.data.insert_open_simulation_one.id;
          })
        );
    };

    return this.fetchDefaultSimConfigs().pipe(
      switchMap((dsc: IDefaultSimConfig[]) => {
        // Find lead owner
        as.forEach((as: IReadyOpenSimulation) => {
          if (queriedParams.providerId) {
            as.leadOwner = queriedParams.providerId;
            as.leadOwnerType = 'provider';
          } else if (queriedParams.solutionId) {
            const found = dsc.find(
              (f) => f.id === queriedParams.solutionId
            )?.solution_provider_id;
            as.leadOwner = found ? found : null;
            as.leadOwnerType = 'provider';
          } else if (queriedParams.vendorId) {
            as.leadOwner = queriedParams.vendorId;
            as.leadOwnerType = 'vendor';
          } else if (queriedParams.vendorName) {
            const found = dsc
              .find((f) =>
                f.vendor_ids
                  ?.map((n) => n.name)
                  .includes(queriedParams.vendorName)
              )
              .vendor_ids.find((f) => f.name === queriedParams.vendorName).id;
            as.leadOwner = found ? found : null;
            as.leadOwnerType = 'vendor';
          } else {
            as.leadOwner = environment.boo;
            as.leadOwnerType = 'backoffice';
          }
        });

        return this.insertOpenProduct(
          palletizingPattern.product,
          openSim.email
        );
      }),
      switchMap((product_id: string) =>
        this.insertOpenPattern(
          palletizingPattern,
          openSim.email,
          product_id,
          name,
          openSim.cpm,
          openSim.fullName,
          queriedParams
        )
      ),
      switchMap((pattern_id: string) => {
        const openSimSources = [];
        as.forEach((a: IReadyOpenSimulation) =>
          openSimSources.push(insertOpenSim(pattern_id, a))
        );

        return forkJoin(openSimSources).pipe(map((_m) => pattern_id));
      })
    );
  }

  getCountries(
    includeContinent: boolean = false,
    includeContinentAndEmoji: boolean = false
  ): Observable<ICountryNState[]> {
    const q = gql`
      query getCountries {
        countries {
          name
          value: code
        }
      }
    `;

    const qc = gql`
      query getCountries {
        countries {
          name
          value: code
          continent {
            name
          }
        }
      }
    `;

    const qce = gql`
      query getCountries {
        countries {
          name
          emoji
          value: code
          continent {
            name
          }
        }
      }
    `;

    let query = q;
    if (includeContinent) {
      query = qc;
    }
    if (includeContinentAndEmoji) {
      query = qce;
    }

    return this.clientApi
      .useClient('public')
      .query<{ countries: ICountryNState[] }>({
        query: query,
      })
      .pipe(
        catchError((err) => {
          console.warn(
            'Failed to get countries, using dummy countries list',
            err
          );
          return of({ data: { countries: fallbackCuntryList } });
        }),
        map((data) => {
          if (data['errors']?.length) return fallbackCuntryList;
          let countries: ICountryNState[] = ObjectUtils.cloneObject(
            data.data.countries
          );
          if (
            !countries.find(
              (c) =>
                c.name === 'England' ||
                c.name === 'Scotland' ||
                c.name === 'Wales' ||
                c.name === 'Northern Ireland'
            )
          ) {
            countries = countries.concat(unitedKingdom);
          }
          countries = countries.sort((a, b) => a.name.localeCompare(b.name));
          countries.length
            ? countries.push({
                name: 'Other',
                value: 'DEFAULT',
              })
            : null;
          return countries;
        })
      );
  }

  getStates(countryCode: string): Observable<ICountryNState[]> {
    const q = gql`
      query getStates {
        countries(filter: { code: { eq: "${countryCode}" } }) {
          states {
            name
            value: code
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<{ countries: { states: ICountryNState[] }[] }>({
        query: q,
      })
      .pipe(
        catchError(() => {
          return of({ data: { countries: [] } });
        }),
        map((data) => {
          const states = ObjectUtils.cloneObject(
            data.data.countries[0]?.states || []
          );
          states.length
            ? states.push({
                name: 'Other',
                value: null,
              })
            : null;
          return states;
        })
      );
  }

  getLoginCards(): Observable<IInfoCardConfig[]> {
    const q = gql`
      query getLoginCards {
        loginCardsCollection {
          items {
            id
            text
            img {
              url(transform: { width: 350 })
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((res: any) => {
          const infoCards: IInfoCardConfig[] =
            res.data.loginCardsCollection.items.map((item) => {
              return {
                img: item.img.url,
                title: '',
                index: item.id,
                text: item.text,
              };
            });

          return infoCards;
        })
      );
  }

  getReportInserts(): Observable<IReportConfig[]> {
    const q = gql`
      query getReportInsert {
        report_config {
          content: conentful_content
          title: contentful_title
          contentful_children {
            items {
              ... on ReportInsert {
                content
                title
              }
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .watchQuery<{ report_config: IReportConfig[] }>({
        query: q,
      })
      .valueChanges.pipe(map((data) => data.data.report_config));
  }

  getVendorOrganizations() {
    const q = gql`
      query getVendorOrganizations {
        vendor_organizations {
          organization {
            id
            name
          }
          id
          name
          label
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<{ vendor_organizations: IVendorOrganization[] }>({
        query: q,
      })
      .pipe(map((data) => data.data.vendor_organizations));
  }

  fetchVendorAndSolutions(
    vn?: string,
    vi?: string,
    sp?: string,
    si?: string
  ): Observable<IVendorAndSolution> {
    const q = gql`
      query fetchVendorAndSolutions(
        $vn: [String]
        $vi: [uuid]
        $sp: [uuid]
        $si: [uuid]
      ) {
        default_sim_config(
          where: {
            _or: [{ solution_provider_id: { _in: $sp } }, { id: { _in: $si } }]
          }
        ) {
          sp: solution_provider_id
          si: id
        }
        vendor_organizations(
          where: { _or: [{ name: { _in: $vn } }, { id: { _in: $vi } }] }
        ) {
          vn: name
          vi: id
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<IVendorAndSolution>({
        query: q,
        variables: {
          vn: vn ? vn.split(',') : [],
          vi: vi ? vi.split(',') : [],
          sp: sp ? sp.split(',') : [],
          si: si ? si.split(',') : [],
        },
      })
      .pipe(
        map((data) => {
          return data.data;
        })
      );
  }

  getUserTypes(): Observable<ISignupUserType[]> {
    const q = gql`
      query getSignupUserTypeCollection {
        signUpUserTypeCollection {
          items {
            type
            title
            greetingFriendlyTitle
            subtitle
            image {
              url
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          return data.data.signUpUserTypeCollection.items;
        }),
        shareReplay({ bufferSize: 1, refCount: true })
      );
  }

  fetchDefaultSimConfigs(): Observable<IDefaultSimConfig[]> {
    const q = gql`
      query defaultConfigs {
        default_sim_config {
          id
          name
          description
          data
          vendor_ids
          solution_provider_id
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<{ default_sim_config: IDefaultSimConfig[] }>({
        query: q,
      })
      .pipe(map((data) => data.data.default_sim_config));
  }

  getDefaultSimConfigById(id: string): Observable<IDefaultSimConfig> {
    const q = gql`
      query defaultConfigById {
        default_sim_config_by_pk(id: "${id}") {
          data
          description
          name
          id
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<{ default_sim_config_by_pk: IDefaultSimConfig }>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            this.errorHandler.handleError(data.error[0]);
            throw new Error(data.error[0]);
          } else {
            return ObjectUtils.cloneObject(data.data.default_sim_config_by_pk);
          }
        })
      );
  }

  createSimRequest(
    userData: IUserSimApi,
    simulationRequest: ISimulationRequest,
    simulationApiData: ISimulationApiFileType
  ): Observable<{
    simulationRequest: ISimulationRequest;
    simulationApiData: ISimulationApiFileType;
  }> {
    const apiObject = {
      e_mail: userData.email,
      data: { ...simulationRequest, ...userData },
    };

    const m = gql`
      mutation insertSimRequest($data: json!, $e_mail: String!) {
        insert_simulation_requests_one(
          object: { data: $data, e_mail: $e_mail }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .mutate<{ insert_simulation_requests_one: { id: string } }>({
        mutation: m,
        variables: {
          data: apiObject.data,
          e_mail: apiObject.e_mail,
        },
      })
      .pipe(
        map(({ data }) => {
          const simReqId = data.insert_simulation_requests_one.id;

          simulationRequest.simulationId = simReqId;
          simulationRequest.required_cpm = simulationRequest.cpm
            ? simulationRequest.cpm
            : null;

          if (simulationApiData.default_sim_config_name) {
            simulationRequest.defaultConfig =
              simulationApiData.default_sim_config_name;
          }

          return {
            simulationRequest: simulationRequest,
            simulationApiData: simulationApiData,
          };
        })
      );
  }

  requestSolutionQuote(
    contactFormContent: IContactInfo,
    simulationId: string
  ): Observable<string> {
    const q = gql`
      query sendSimulationQuoteRequest(
        $contact_data: json!
        $simulation_id: String!
      ) {
        ${environment.quoteReqestActionKey}(
          contact_data: $contact_data
          simulation_id: $simulation_id
        ) {
          status
        }
      }
    `;

    const variables = {
      simulation_id: simulationId,
      contact_data: contactFormContent,
    };
    return this.clientApi
      .useClient('public')
      .query<any>({
        query: q,
        variables: variables,
      })
      .pipe(map(({ data }) => data?.SimulationQuoteRequest?.status));
  }
}
