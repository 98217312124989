<ng-container *ngIf="ready$ | async">
  <ng-container *appVar="isBeingHovered$ | async as menuOnHover">
    <div
      *appVar="navbarService.keepExpanded$ | async as keepExpanded"
      class="sidenav-content-wrapper"
      [class.expanded]="keepExpanded"
      [class.on-hover]="menuOnHover"
    >
      <div
        class="sidenav-list"
        *appVar="activePath$ | async as activePath"
        (mouseenter)="isBeingHovered$.next(true)"
        (mouseleave)="isBeingHovered$.next(false)"
      >
        <div class="sidenav-category">
          <div class="sidenav-list-item">
            <ng-container *ngIf="activeOrganization$ | async as activeOrg">
              <a
                (click)="navItemClick(null, activeOrg)"
                [class.active]="checkActivePathItem(['customers/'], activePath)"
              >
                <mat-icon class="nav-item-icon">dashboard</mat-icon>
                <div class="nav-item-label">Dashboard</div>
              </a>
            </ng-container>
          </div>
        </div>
        <ng-container *ngIf="sidenavItems">
          <ng-container *ngFor="let parentItem of sidenavItems">
            <div
              class="sidenav-category"
              *ngIf="checkOptionRenderByBrand(parentItem.restrictToBrands)"
            >
              <h5 class="sidenav-list-header">
                <span>
                  {{ parentItem.label }}
                </span>
              </h5>
              <ng-container *ngFor="let childItem of parentItem.children">
                <div
                  class="sidenav-list-item"
                  *ngIf="checkOptionRenderByBrand(childItem.restrictToBrands)"
                >
                  <a
                    (click)="navItemClick(childItem)"
                    [class.active]="
                      checkActivePathItem(childItem.path, activePath)
                    "
                  >
                    <mat-icon
                      *ngIf="childItem.customIcon; else materialIcon"
                      [svgIcon]="childItem.customIcon"
                      class="nav-item-icon"
                    ></mat-icon>
                    <ng-template #materialIcon>
                      <mat-icon class="nav-item-icon material">{{
                        childItem.materialIcon
                      }}</mat-icon>
                    </ng-template>
                    <div class="nav-item-label">
                      {{ childItem.label }}
                    </div>
                  </a>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </ng-container>
      </div>
      <div
        class="expansion-toggle"
        [class.active]="keepExpanded"
        [class.menu-hovered]="menuOnHover"
        (click)="navbarService.keepExpanded$.next(!keepExpanded)"
        matTooltip="Keep menu expanded"
      >
        <mat-icon> chevron_right </mat-icon>
      </div>
    </div>
  </ng-container>
</ng-container>
