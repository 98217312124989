<div *ngIf="content$ | async as state">
  <div class="verify-robot-side-menu">
    <app-progress-sidebar
      [inputContent]="sidebarContent$ | async"
      [currentPage]="step$ | async"
      (selectedStep)="nextStep($event.id)"
    >
    </app-progress-sidebar>
  </div>

  <!-- Loading state -->
  <div *ngIf="state.isLoading" class="verify-robot-wizard-state">
    <app-loading></app-loading>
  </div>

  <!-- Error state -->
  <div *ngIf="state.error as error" class="verify-robot-wizard-state">
    <app-error [error]="error"></app-error>
  </div>

  <!-- Successful state -->
  <div class="verify-robot-wrapper" *ngIf="state.value as content">
    <div class="verify-robot-wizard-wrapper">
      <div class="verify-robot-wizard-container">
        <div class="step-header">
          <span>Step {{ content.stepInfo.id + 1 }}</span>
          <h2>
            {{ content.stepInfo.header }}
          </h2>
          <mat-divider></mat-divider>

          <app-highlighted-message-card
            style="margin-top: 1em"
            *ngIf="getSubText(content.stepInfo.id) as subtext"
            [type]="'info'"
            [message]="subtext"
          ></app-highlighted-message-card>
        </div>

        <!-- Hardware selection steps -->
        <ng-container *ngIf="content.stepInfo.id < Steps.ConfirmHardware">
          <div class="step-header">
            <h2>Selected from uploaded file</h2>
            <mat-divider></mat-divider>
            <app-hardware-card
              *ngIf="content.configHW"
              class="hardware-from-config-card"
              [hardwareType]="content.configHW"
              [shouldDisplayActionButton]="false"
              [isClickable]="true"
              [selected]="isSelected(content.configHW)"
              (click)="hwSelected(content.configHW)"
            ></app-hardware-card>

            <span>hardware library</span>
            <h2>
              {{ content.stepInfo.listTitle }}
            </h2>
            <!--
              Note! The empty lists and values are to override default
              values. They're needed to hide stuff.
            -->
            <app-hardware-card-list
              [title]="''"
              [sortedContent]="content.hwtypes"
              [sortOptions]="[]"
              [pagination]="3"
              [clickedAsSelected]="true"
              [selected]="selectedHW"
              [isGlobal]="true"
              [actionButtons]="[]"
              (cardClicked)="hwSelected($event.hardware)"
            ></app-hardware-card-list>
          </div>
        </ng-container>

        <!-- Full hardware confirmation step -->
        <ng-container *ngIf="content.stepInfo.id === Steps.ConfirmHardware">
          <div [hidden]="editingHardware">
            <app-card-hardware-display
              [hwConfig]="content?.rc?.scene"
              [actions]="[{ id: 'edit', label: 'Edit' }]"
              (actionClicked$)="editingHardware = $event === 'edit'"
            ></app-card-hardware-display>
          </div>
          <div [hidden]="!editingHardware">
            <app-hardware-configuration-card
              *ngIf="content?.rc?.scene?.id"
              [hardwareID]="content.rc.scene.id"
              (hardwareIDChange)="hardwareUpdated()"
            ></app-hardware-configuration-card>
          </div>
        </ng-container>

        <!-- Software confirmation step -->
        <ng-container *ngIf="content.stepInfo.id === Steps.ConfirmSoftware">
          <div *ngIf="!editingStrategy">
            <app-card-software-display
              [swConfig]="content.rc.strategy"
              [actions]="[{ id: 'edit', label: 'Edit' }]"
              (actionClicked$)="editingStrategy = $event === 'edit'"
            ></app-card-software-display>
          </div>
          <div *ngIf="editingStrategy">
            <app-software-details
              [presetStrategy]="content.rc.strategy"
              (saveStrategy)="
                saveSoftwareConfiguration(
                  content.rc.strategy.id,
                  $event.name,
                  content.rc.strategy.description,
                  $event.strategy
                )
              "
            ></app-software-details>
          </div>
        </ng-container>
      </div>
    </div>
  </div>

  <mat-toolbar class="verify-robot-footer" *appVar="step$ | async as step">
    <div class="footer_text">{{ stepContextData[step]?.header }}</div>
    <div class="next-container">
      <p
        *ngIf="
          (editingHardware || editingStrategy) && step >= Steps.ConfirmHardware
        "
        class="footer-help-text"
        (click)="
          nextStep(
            editingHardware ? Steps.ConfirmHardware : Steps.ConfirmSoftware
          )
        "
      >
        {{
          editingHardware ? 'Hardware not confirmed' : 'Software not confirmed'
        }}
      </p>
      <button
        mat-flat-button
        color="accent"
        (click)="nextStep(step + 1)"
        [disabled]="
          (editingHardware || editingStrategy) && step >= Steps.ConfirmHardware
        "
      >
        {{ step < stepContextData.length - 1 ? 'Next' : 'CONFIRM' }}
        <mat-icon> arrow_forward </mat-icon>
      </button>
    </div>
  </mat-toolbar>
</div>
