<app-page>
  <app-breadcrumb-nav></app-breadcrumb-nav>
  <app-header>
    <h1 app-title>Patterns</h1>

    <app-header-actions>
      <button
        *appIfCommandAvailable="'upload_pattern'"
        mat-flat-button
        pally-rounded-button-m
        pally-hollow-button-blue
        color="primary"
        (click)="uploadPattern()"
      >
        <mat-icon>upload_file</mat-icon>Upload file
      </button>

      <button
        *appIfCommandAvailable="'create_pattern'"
        mat-flat-button
        pally-rounded-button-m
        color="primary"
        (click)="navigateToPattern('new')"
      >
        <mat-icon>add</mat-icon>New
      </button>
    </app-header-actions>
  </app-header>

  <mat-divider></mat-divider>

  <ng-container *ngIf="patterns$ | async as patterns">
    <!-- Loading state -->
    <app-loading *ngIf="patterns.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="patterns.error as error" [error]="error"></app-error>

    <app-content-switcher
      *ngIf="patterns.didSucceed"
      [selected]="blockSelected"
      [sortingColumns]="sortingColumns"
      [resultFilter]="resultFilter"
      objectType="pattern"
      (order)="orderBy = $event"
      (delete)="onDelete(blockSelected)"
      (selectedFiltering)="tableFilter = objUtil.cloneObject($event)"
    >
      <ng-template #table>
        <app-inventory-table
          [filter]="tableFilter"
          [sortBy]="orderBy"
          [data]="patterns.value"
          [rowCta]="rowCta"
          [displayedColumns]="displayedColumns"
          [rowActions]="rowActions"
          (rowAction)="actionClicked($event)"
          (rowCtaAction)="ctaClicked($event)"
          (rowSelected)="rowClicked($event)"
          (blockSelectedChange)="blockSelected = $event"
        >
        </app-inventory-table>
      </ng-template>
    </app-content-switcher>
  </ng-container>
  <!-- Needs to be here for the icons to render in the pallet report. -->
  <mat-icon style="opacity: 0" svgIcon="product"></mat-icon>
  <mat-icon style="opacity: 0" svgIcon="pallet"></mat-icon>
</app-page>

<app-pallet-report
  class="pdf-report"
  *ngIf="patternId && showReport"
  [pattern]="patternId"
  [orgId]="route.snapshot.data.organization_id"
  #palletReport
></app-pallet-report>
