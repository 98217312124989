<mat-card
  appearance="outlined"
  style="padding: 0px; border-radius: 4px 4px 4px 4px"
  *ngIf="tableData"
>
  <div class="left">
    <table mat-table [dataSource]="tableData.content">
      <ng-container matColumnDef="product-name">
        <th mat-header-cell *matHeaderCellDef class="name">
          {{ tableData.name }}
        </th>
      </ng-container>
      <ng-container matColumnDef="last-edit" class="name">
        <th mat-header-cell *matHeaderCellDef></th>
      </ng-container>

      <ng-container matColumnDef="headers">
        <th mat-header-cell *matHeaderCellDef>
          <div class="header">
            {{ tableData.overline.type }}
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          class="header-text"
          [style.color]="row.color"
        >
          {{ row.header }}
        </td>
      </ng-container>

      <ng-container matColumnDef="values">
        <th mat-header-cell *matHeaderCellDef style="text-align: right">
          <div class="last-edit">
            last edited <span> {{ lastEdited }} </span>
          </div>
        </th>
        <td
          mat-cell
          *matCellDef="let row"
          class="value-text"
          style="text-align: right"
          [style.color]="row.color"
        >
          {{ row.value | unitSystem }}
        </td>
      </ng-container>

      <tr
        mat-header-row
        *matHeaderRowDef="displayedColumns"
        class="example-first-header-row"
      ></tr>
      <tr mat-header-row *matHeaderRowDef="['product-name', 'last-edit']"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <div *ngIf="actions?.length" class="actions">
      <button
        mat-stroked-button
        *ngFor="let action of actions"
        color="primary"
        (click)="actionClicked$.emit(action.id)"
      >
        {{ action.label }}
      </button>
    </div>
  </div>

  <div class="right">
    <ng-content></ng-content>
  </div>
</mat-card>
