import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { combineLatest, forkJoin, from, Observable, of, Subject } from 'rxjs';
import { filter, map, switchMap, take, takeUntil } from 'rxjs/operators';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { ThreeHandlerMode } from 'src/app/models_new/classes/3dview/three-handler-mode';
import {
  ApiPattern,
  IApiPattern,
} from 'src/app/models_new/classes/api-models/ApiPattern';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { CameraType } from 'src/app/services/3dview/input-handler.service';
import { FileUtils } from 'src/app/utils/file-utils';
import { DialogSize } from '../../../models_new/enums/dialogSize';
import { PatternApiService } from '../../../services/api/pattern-api.service';
import { DialogService } from '../../../services/dialog.service';
import { NotificationService } from '../../../services/notification.service';
import { RoleApiService } from '../../../services/api/role-api.service';
import {
  FilterTableData,
  ISortingOption,
} from 'src/app/models_new/types/sorting-option';
import { SortDirection } from '@angular/material/sort';
import { FilterType, IFilterData } from 'src/app/models_new/types/filter-data';
import { ObjectUtils } from 'src/app/utils/object';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { IPatternTableData } from 'src/app/models_new/types/patternTableData';
import { PickerType } from 'src/app/models_new/enums/picker-type';
import { UnitSystemLabelPipe } from 'src/app/pipes/unit-system-label.pipe';
import { PdfPreviewComponent } from '../../dialogs/pdf-preview/pdf-preview.component';
import { jsPDF } from 'jspdf';
import * as pdfUtils from '../../../utils/pdf-utils';
import { AppLayoutService } from '../../../services/app-layout.service';
import { PalletReportComponent } from '../../patterns/pattern/pattern-report/pallet-report.component';
import { Project } from 'src/app/models_new/classes/project';
import { ApiProduct } from 'src/app/models_new/classes/api-models/ApiProduct';
import { defaultApiProduct } from 'src/app/models_new/config/default/api-default/default-api-product';
import { ProductApiService } from 'src/app/services/api/product-api.service';
import { defaultApiPattern } from 'src/app/models_new/config/default/api-default/default-api-pattern';
import { ExportImportService } from 'src/app/services/export-import.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import {
  InventoryTableCta,
  InventoryTableDisplayColumn,
  InvetoryTableAction,
} from '../../gui/inventory-table/inventory-table.component';
import { DatePipe } from '@angular/common';
import { StateService } from 'src/app/auth/state.service';

@Component({
  selector: 'app-patterns',
  templateUrl: './patterns.component.html',
  styleUrls: ['./patterns.component.scss'],
})
export class PatternsComponent implements OnInit, OnDestroy {
  @ViewChild('palletReport') palletReport: PalletReportComponent;
  @ViewChild('palletReport', { read: ElementRef }) palletReportRef: ElementRef;

  protected readonly Math = Math;

  patterns$: Observable<DataRequestState<IPatternTableData[]>>;
  displayedColumns: InventoryTableDisplayColumn[] = [
    {
      label: 'Name',
      path: 'name',
      sortType: 'string',
    },
    {
      label: 'Cube Efficiency',
      path: 'cube_efficiency',
      sortType: 'number',
    },
    {
      label: 'Total Height',
      path: 'total_height',
      sortType: 'number',
    },
    {
      label: 'Quantity',
      path: 'product_amount',
      sortType: 'number',
    },
    {
      label: 'Product',
      path: 'product.name',
      sortType: 'string',
      specialCell: [
        {
          type: 'link',
          link: (element: IPatternTableData) => {
            return '/' + pagesPATH.PRODUCTS + '/' + element.product.id;
          },
        },
      ],
    },
    {
      label: 'Updated At',
      path: 'updated_at',
      sortType: 'date',
      initSort: 'desc',
    },
  ];

  rowActions: InvetoryTableAction[] = [
    {
      label: 'Report',
      roleAction: 'download_pallet_report',
      actionId: 'pallet_report',
      icon: 'save_alt',
      divideAfter: true,
    },
    {
      label: 'Simulation',
      roleAction: 'start_simulation',
      actionId: 'simulate',
      icon: 'add',
    },
    {
      label: 'Pally program',
      roleAction: 'create_waypoints',
      actionId: 'generate_waypoints',
      icon: 'add',
      divideAfter: true,
    },
    {
      label: 'Duplicate',
      roleAction: 'create_simulation',
      actionId: 'duplicate',
      icon: 'content_copy',
    },
    {
      label: 'Delete',
      roleAction: 'delete_pattern',
      actionId: 'delete',
      color: 'warn',
      icon: 'delete',
    },
  ];

  rowCta: InventoryTableCta[] = [
    {
      label: 'Export',
      roleAction: 'download_json_pattern',
      actionId: 'json',
      icon: 'save_alt',
    },
  ];

  showReport = false;
  patternId: string;

  blockSelected: ApiPattern[] = [];
  searchText: string = '';

  pickerType: PickerType = PickerType.PATTERN;

  ThreeHandlerMode = ThreeHandlerMode;
  Perspective = CameraType;
  objUtil = ObjectUtils;

  destroy$: Subject<boolean> = new Subject<boolean>();

  resultFilter: IFilterData[] = [
    {
      filterType: FilterType.SLIDER,
      id: 'product_amount',
      label: 'Product Amount',
      value: 0,
      min: 0,
      max: 100,
    },
    {
      filterType: FilterType.SLIDER,
      id: 'total_height',
      label: 'Total Height',
      value: 0,
      min: 0,
      max: 3000,
    },
  ];

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'cube_efficiency',
      label: 'Cube Efficiency',
    },
    {
      id: 'total_height',
      label: 'Total Height',
    },
    {
      id: 'product_amount',
      label: 'Product Amount',
    },
    {
      id: 'updated_at',
      label: 'Updated At',
    },
  ];

  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };

  constructor(
    private router: Router,
    public route: ActivatedRoute,
    private patternApi: PatternApiService,
    private dialogService: DialogService,
    private notifier: NotificationService,
    private unitSystemPipe: UnitSystemLabelPipe,
    private roleApi: RoleApiService,
    public appLayout: AppLayoutService,
    private productApi: ProductApiService,
    private exportImportService: ExportImportService,
    private localstore: LocalStorageService,
    private datePipe: DatePipe,
    private stateService: StateService
  ) {}

  ngOnInit(): void {
    this.stateService.isExportingAllowed$.subscribe((canExport) => {
      if (!canExport) this.rowCta = [];
    });

    this.patterns$ = this.route.data.pipe(
      take(1),
      switchMap((data: Data) =>
        this.patternApi.fetchInventoryPatterns(
          (data as IOrganizationContextResolverData).organization_id
        )
      ),
      takeUntil(this.destroy$),
      map((ptd: IPatternTableData[]) => {
        ptd.map((p: IPatternTableData) => {
          if (typeof p.cube_efficiency === 'number') {
            p.cube_efficiency = p.cube_efficiency.toFixed(2) + '%';
          }
          p.total_height = this.unitSystemPipe.transform(
            p.total_height + ' mm'
          );
          p.updated_at =
            this.datePipe.transform(p.updated_at, 'dd/MM/yyyy HH:mm') || 'N/A';
          return p;
        });
        return ptd;
      }),
      toRequestState()
    );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ctaClicked(e: { action: InventoryTableCta; row: ApiPattern }) {
    if (e.action.actionId === 'json') {
      this.downloadJson(e.row.id);
    }
  }

  actionClicked(e: { action: InvetoryTableAction; row: ApiPattern }) {
    if (e.action.actionId === 'pallet_report') {
      this.downloadPalletReport(e.row.id);
    }
    if (e.action.actionId === 'simulate') {
      this.router.navigate([
        pagesPATH.SIMULATIONS,
        pagesPATH.SIMPLE_SIMULATION,
        e.row.id,
      ]);
    }
    if (e.action.actionId === 'generate_waypoints') {
      this.localstore.setData(LocalStorageKey.WAYPOINTS_GENERATION_SELECTED, {
        patterns: [e.row.id],
      });
      this.router.navigate([pagesPATH.WAYPOINTS, 'new']);
    }
    // Delete action
    if (e.action.actionId === 'delete') {
      this.onDelete([e.row]);
    }
    // Duplicate action
    if (e.action.actionId === 'duplicate') {
      combineLatest([
        this.patternApi.fetchPatternById(e.row.id),
        this.patternApi.fetchInventoryPatternNames(
          this.route.snapshot.data.organization_id
        ),
      ])
        .pipe(
          take(1),
          switchMap(([pattern, patternNames]) => {
            const patternCopy = ObjectUtils.cloneObject(pattern);

            patternCopy.data.name = this.getCopyName(
              patternCopy.data.name,
              patternNames
            );
            return this.patternApi.insertPattern(
              patternCopy,
              {
                id: patternCopy.product.id,
                data: patternCopy.product,
              },
              this.route.snapshot.data.organization_id
            );
          })
        )
        .subscribe();
    }
  }

  rowClicked(e: any) {
    this.roleApi.availableActions$.pipe(take(1)).subscribe((actions) => {
      if (actions.find((f) => f === 'modify_pattern')) {
        this.navigateToPattern(e.id);
      }
    });
  }

  downloadJson(pattern_id: string) {
    this.patternApi
      .fetchPatternById(pattern_id)
      .pipe(
        take(1),
        map((pattern: IApiPattern) => {
          // Validate
          const palletNotEmpty1 = FileUtils.validField(
            pattern.data,
            'layers',
            '>0'
          );
          const palletNotEmpty2 = FileUtils.validField(
            pattern.data,
            'layerTypes',
            '>0'
          );
          const descNotEmpty = FileUtils.validField(
            pattern.data,
            'description',
            'defined'
          );

          // Fix
          if (!palletNotEmpty1) {
            this.notifier.showMessage('The pallet in this pattern is empty...');
            pattern.data.layers = [];
          }
          if (!palletNotEmpty2) {
            this.notifier.showMessage('The pallet in this pattern is empty...');
            pattern.data.layerTypes = [];
          }
          if (!descNotEmpty) {
            pattern.data.description = '';
            this.notifier.showMessage(
              'The description in this pattern is empty...'
            );
          }

          /**
           * @desc When exporting JSON from MRC, it is advised to remove or replace whitespace in the file name to
           * ensure compatibility with older versions of Pally URCap that cannot handle patterns with whitespace in the file name.
           * {@link https://rocketfarm.atlassian.net/browse/PALLY-3921?focusedCommentId=40602 Learn more} about this issue.
           */
          pattern.data.name = pattern.name = pattern.name.replace(/ /g, '_');
          return pattern;
        })
      )
      .subscribe({
        next: (pattern: IApiPattern) =>
          FileUtils.downloadJson(pattern.data, pattern.name),
        error: (err) => this.notifier.showError(err.message),
      });
  }

  downloadPalletReport(patternId: string) {
    this.showReport = true;
    this.patternId = patternId;

    // Wait for the report component to render
    from(pdfUtils.waitForElement('.pdf-report', document))
      .pipe(
        switchMap(() => this.palletReport.ready$),
        take(1)
      )
      .subscribe(() => {
        this.openPdfPreviewDialog(
          this.generatePalletReportPDF(),
          'Pallet report'
        );
      });
  }

  private generatePalletReportPDF(): Observable<string> {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'A4',
    });
    return from(
      pdfUtils.addElementToPDF(doc, this.palletReportRef.nativeElement, 0, 0)
    ).pipe(
      switchMap(() => {
        this.showReport = false;
        return of(doc.output('bloburl').toString());
      })
    );
  }

  private openPdfPreviewDialog(
    pdfUrl: Observable<string> | string,
    title: string
  ): void {
    const data = {
      url: pdfUrl,
      title: title.replace(/\./g, ' '),
    };

    this.dialogService.showCustomDialog(
      PdfPreviewComponent,
      DialogSize.LARGE,
      null,
      data,
      true
    );
  }

  navigateToPattern(id: string): void {
    this.router.navigate([pagesPATH.PATTERNS, id]);
  }

  uploadPattern() {
    const statusRef$ = this.notifier
      .uploadPrompt()
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap((project: Project) => {
          const product = new ApiProduct(defaultApiProduct);
          product.data = {
            length: project.data.box.dimensions.length,
            height: project.data.box.dimensions.height,
            width: project.data.box.dimensions.width,
            weight: project.data.box.weight,
            label_orientation: project.data.box.label.orientation,
          };

          return forkJoin([
            this.productApi.createProduct(
              project.data.name,
              product.data,
              this.route.snapshot.data.organization_id
            ),
            of(project),
            of(product),
          ]);
        }),
        take(1),
        switchMap(([productId, project, product]) => {
          const pattern = new ApiPattern(defaultApiPattern);
          pattern.data = this.exportImportService.mapToExportFormat(project);
          pattern.name = pattern.data.name;

          if (!pattern.name || pattern.name === '') {
            throw new Error('Unable to upload pattern: Name is missing.');
          }

          return this.patternApi.insertPattern(
            pattern,
            {
              id: productId.id,
              data: product,
            },
            this.route.snapshot.data.organization_id
          );
        }),
        take(1)
      );

    statusRef$.subscribe({
      next: (res) => {
        if (res) {
          this.notifier.showSuccess('File uploaded successfully!');
        }
      },
      error: (err) => {
        this.notifier.showError(err.message);
      },
    });
  }

  onDelete(patterns: ApiPattern[]) {
    this.deletePatternsAction(patterns)
      .pipe(take(1))
      .subscribe((result) => {
        if (result[0]) {
          this.notifier.showMessage('The pattern was deleted successfully!');
        } else {
          this.notifier.showError(
            'Unable to delete pattern! Do you have the correct user privilege in your organization?'
          );
        }
      });
  }

  deletePatternsAction(patterns: ApiPattern[]) {
    return this.notifier
      .deletePrompt(
        'Delete',
        'pattern',
        patterns.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          return this.deletePatterns(patterns.map((pattern) => pattern.id));
        })
      );
  }

  deletePatterns(ids: string[]): Observable<any> {
    const operations = {};
    for (let i = 0; i < ids.length; i++) {
      operations[i] = this.patternApi.deletePatternById(ids[i]);
    }
    return forkJoin(operations);
  }

  getCopyName(originalName: string, existingNames: string[]): string {
    let name = originalName.replace(/ \(Copy\).*/, '') + ' (Copy)';
    let copyNumber = 0;
    while (existingNames.includes(name)) {
      name = name.replace(/ \(Copy\).*/, '') + ' (Copy)';
      if (copyNumber > 0) {
        name += ' (' + copyNumber + ')';
      }
      copyNumber++;
    }
    return name;
  }

  startSimpleSimulation(element: IApiPattern) {
    this.router.navigate([
      pagesPATH.SIMULATIONS,
      pagesPATH.SIMPLE_SIMULATION,
      element.id,
    ]);
  }
}
