export enum SimConfigFieldIds {
  PolyscopeVersion = 'mainFormGroup.scene.software.polyscope_version',
  PallyVersion = 'mainFormGroup.scene.software.pally_version',
  /* ------------------------------- Info fields ------------------------------ */
  Name = 'robot_config.name',
  ShowDimensions = 'show_dimensions',

  /* ------------------------------ Frame fields ------------------------------ */
  FrameId = 'robot.frame.id',
  FrameType = 'robot.frame.type',
  FrameName = 'robot.frame.name',
  FrameTypeLabel = 'robot.frame.label', // NOTE! Field is not defined.

  /* ----------------------------- Liftkit fields ----------------------------- */
  LiftkitId = 'robot.lift_kit.id',
  LiftkitName = 'robot.lift_kit.name',
  LiftkitType = 'robot.lift_kit.type',
  LiftkitTypeLabel = 'robot.lift_kit.label', // NOTE! Field is not defined.
  LiftkitMaxStroke = 'robot.lift_kit.max_stroke',

  /* --------------------------- Basebracket fields --------------------------- */
  BasebracketHeight = 'robot_mounting_height.position.z',
  BasebracketRotation = 'robot_mounting_height.rotation.y',

  /* ------------------------------ Robot fields ------------------------------ */
  RobotId = 'robot.id',
  RobotType = 'robot.type',
  RobotName = 'robot.name',
  RobotTypeLabel = 'robot.label', // NOTE! Field is not defined.

  /* -------------------------- Offsetbracket fields -------------------------- */
  OffsetBracketType = 'offset_bracket.type',
  AddOffsetBracket = 'offset_bracket.add',
  OffsetBracketWidth = 'robot.offset_bracket.collision_object.width',
  OffsetBracketLength = 'robot.offset_bracket.collision_object.length',
  OffsetBracketHeight = 'robot.offset_bracket.collision_object.height',
  OffsetBracketAdvanced = 'offset_bracket.advanced',
  OffsetBracketPositionX = 'robot.offset_bracket.collision_object.position.x',
  OffsetBracketPositionY = 'robot.offset_bracket.collision_object.position.y',
  OffsetBracketPositionZ = 'robot.offset_bracket.collision_object.position.z',
  OffsetBracketOffsetX = 'robot.offset_bracket.offset.x',
  OffsetBracketOffsetY = 'robot.offset_bracket.offset.y',
  OffsetBracketOffsetZ = 'robot.offset_bracket.offset.z',

  /* ----------------------------- Gripper fields ----------------------------- */
  GripperId = 'robot.gripper.id',
  GripperType = 'robot.gripper.type',
  GripperTypeLabel = 'robot.gripper.label', // NOTE! Field is not defined.
  GripperName = 'robot.gripper.name',
  GripperCustomWidth = 'robot.gripper.custom_collision_box.width',
  GripperCustomLength = 'robot.gripper.custom_collision_box.length',
  GripperCustomHeight = 'robot.gripper.custom_collision_box.height',
  GripperAdvanced = 'robot.gripper.advanced',
  GripperCustomPositionX = 'robot.gripper.custom_offset_pose.position.x',
  GripperCustomPositionY = 'robot.gripper.custom_offset_pose.position.y',
  GripperCustomPositionZ = 'robot.gripper.custom_offset_pose.position.z',
  GripperCustomRotationR = 'robot.gripper.custom_offset_pose.rotation.r',
  GripperCustomRotationP = 'robot.gripper.custom_offset_pose.rotation.p',
  GripperCustomRotationY = 'robot.gripper.custom_offset_pose.rotation.y',

  /* -------------------------- Custom pallet fields -------------------------- */
  PalletSideOffset = 'pallet_side_offset',
  PalletEndOffset = 'pallet_end_offset',
  PalletVerticalOffset = 'pallet_vert_offset',

  /* ------------------------------ Pallet fields ----------------------------- */
  PalletRightName = 'place_targets.[name:p1].name',
  PalletRightAdvanced = 'place_targets.[name:p1].advanced',
  PalletRightPositionX = 'place_targets.[name:p1].corner_base_pose.position.x',
  PalletRightPositionY = 'place_targets.[name:p1].corner_base_pose.position.y',
  PalletRightPositionZ = 'place_targets.[name:p1].corner_base_pose.position.z',
  PalletRightRotationX = 'place_targets.[name:p1].corner_base_pose.orientation.x',
  PalletRightRotationY = 'place_targets.[name:p1].corner_base_pose.orientation.y',
  PalletRightRotationZ = 'place_targets.[name:p1].corner_base_pose.orientation.z',
  PalletRightRotationW = 'place_targets.[name:p1].corner_base_pose.orientation.w',
  PalletRightLipEnabled = 'place_targets.[name:p1].lip.enabled',
  PalletRightLipHeight = 'place_targets.[name:p1].lip',

  PalletLeftName = 'place_targets.[name:p2].name',
  PalletLeftAdvanced = 'place_targets.[name:p2].advanced',
  PalletLeftPositionX = 'place_targets.[name:p2].corner_base_pose.position.x',
  PalletLeftPositionY = 'place_targets.[name:p2].corner_base_pose.position.y',
  PalletLeftPositionZ = 'place_targets.[name:p2].corner_base_pose.position.z',
  PalletLeftRotationX = 'place_targets.[name:p2].corner_base_pose.orientation.x',
  PalletLeftRotationY = 'place_targets.[name:p2].corner_base_pose.orientation.y',
  PalletLeftRotationZ = 'place_targets.[name:p2].corner_base_pose.orientation.z',
  PalletLeftRotationW = 'place_targets.[name:p2].corner_base_pose.orientation.w',
  PalletLeftLipEnabled = 'place_targets.[name:p2].lip.enabled',
  PalletLeftLipHeight = 'place_targets.[name:p2].lip',

  /* ----------------------------- Conveyor fields ---------------------------- */
  ConveyorPrimaryId = 'conveyors.0.id',
  ConveyorPrimaryType = 'conveyors.0.type',
  ConveyorPrimaryTypeLabel = 'conveyors.0.label', // NOTE! Field is not defined.
  ConveyorPrimaryTypeName = 'conveyors.0.name',
  ConveyorPrimaryName = 'conveyors.0.custom_description.name',
  ConveyorPrimaryAdvanced = 'conveyors.0.advanced',
  ConveyorPrimaryDirection = 'conveyors.0.direction',
  ConveyorPrimaryWidth = 'conveyors.0.custom_description.dimension.x',
  ConveyorPrimaryLength = 'conveyors.0.custom_description.dimension.y',

  // TODO: Add support for multiple product mode.
  // As we currently only support single product mode only ConveyorPrimaryHeight
  // is used to represent the conveyor height.
  ConveyorPrimaryHeight = 'conveyors.0.custom_description.dimension.z',
  ConveyorPrimaryPositionX = 'conveyors.0.box_corner_pose.position.x',
  ConveyorPrimaryPositionY = 'conveyors.0.box_corner_pose.position.y',
  ConveyorPrimaryPositionZ = 'conveyors.0.box_corner_pose.position.z',
  ConveyorPrimaryRotationX = 'conveyors.0.box_corner_pose.orientation.x',
  ConveyorPrimaryRotationY = 'conveyors.0.box_corner_pose.orientation.y',
  ConveyorPrimaryRotationZ = 'conveyors.0.box_corner_pose.orientation.z',
  ConveyorPrimaryRotationW = 'conveyors.0.box_corner_pose.orientation.w',
  ConveyorPrimaryGuideSide = 'conveyors.0.custom_description.guide_left',
  ConveyorPrimaryGuideWidth = 'conveyors.0.custom_description.guide_width',
  ConveyorPrimaryVelocity = 'conveyors.0.custom_description.velocity',
  ConveyorPrimaryPubRate = 'conveyors.0.custom_description.pub_rate',
  ConveyorPrimaryAddAtEnd = 'conveyors.0.custom_description.add_at_end',

  DualProductMode = 'dualProduct',
  ConveyorSecondaryId = 'conveyors.1.id',
  ConveyorSecondaryType = 'conveyors.1.type',
  ConveyorSecondaryTypeName = 'conveyors.1.name',
  ConveyorSecondaryTypeLabel = 'conveyors.1.label', // NOTE! Field is not defined.
  ConveyorSecondaryName = 'conveyors.1.custom_description.name',
  ConveyorSecondaryAdvanced = 'conveyors.1.advanced',
  ConveyorSecondaryDirection = 'conveyors.1.direction',
  ConveyorSecondaryWidth = 'conveyors.1.custom_description.dimension.x',
  ConveyorSecondaryLength = 'conveyors.1.custom_description.dimension.y',

  ConveyorSecondaryHeight = 'conveyors.1.custom_description.dimension.z',
  ConveyorSecondaryPositionX = 'conveyors.1.box_corner_pose.position.x',
  ConveyorSecondaryPositionY = 'conveyors.1.box_corner_pose.position.y',
  ConveyorSecondaryPositionZ = 'conveyors.1.box_corner_pose.position.z',
  ConveyorSecondaryRotationX = 'conveyors.1.box_corner_pose.orientation.x',
  ConveyorSecondaryRotationY = 'conveyors.1.box_corner_pose.orientation.y',
  ConveyorSecondaryRotationZ = 'conveyors.1.box_corner_pose.orientation.z',
  ConveyorSecondaryRotationW = 'conveyors.1.box_corner_pose.orientation.w',
  ConveyorSecondaryGuideSide = 'conveyors.1.custom_description.guide_left',
  ConveyorSecondaryGuideWidth = 'conveyors.1.custom_description.guide_width',
  ConveyorSecondaryVelocity = 'conveyors.1.custom_description.velocity',
  ConveyorSecondaryPubRate = 'conveyors.1.custom_description.pub_rate',
  ConveyorSecondaryAddAtEnd = 'conveyors.1.custom_description.add_at_end',

  /* ------------------------------ Scene fields ------------------------------ */
  ThemeType = 'theme',

  /* ----------------------------- Strategy fields ---------------------------- */
  ConveyorPrimaryBoxFreeHeight = 'conveyors.0.box_free_height',
  ConveyorSecondaryBoxFreeHeight = 'conveyors.1.box_free_height',
}
