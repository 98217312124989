import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loader-icon',
  templateUrl: './loader-icon.component.html',
  styleUrls: ['./loader-icon.component.scss'],
})
export class LoaderIconComponent {
  /** @param {boolean} darkMode: Will toggle the color scheme to work on dark backgrounds. */
  @Input() darkMode: boolean = false;
}
