<ng-container *ngIf="isEmbedded; else StandAloneComponent">
  <ng-container *ngTemplateOutlet="productCard"></ng-container>
</ng-container>

<ng-template #StandAloneComponent>
  <app-page>
    <app-breadcrumb-nav></app-breadcrumb-nav>
    <ng-container *ngTemplateOutlet="productCard"></ng-container>
  </app-page>
</ng-template>

<ng-template #productCard>
  <app-header *ngIf="!isEmbedded">
    <h1 app-title>
      {{ product.name }}
    </h1>
  </app-header>
  <ng-container *ngIf="ready">
    <app-new-product-card
      [mode]="mode"
      [product]="product"
      (product$)="productUpdate($event)"
      (save$)="onSaveAction()"
      (saveAndCreatePattern$)="onSaveAndCreatePatternAction()"
      (cancel$)="onCancelAction()"
    ></app-new-product-card>
  </ng-container>
</ng-template>
