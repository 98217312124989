import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormControlStatus,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Data, Params, Router } from '@angular/router';
import {
  Observable,
  combineLatest,
  filter,
  of,
  shareReplay,
  skipWhile,
  switchMap,
  take,
  tap,
} from 'rxjs';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import { ApiPattern } from 'src/app/models_new/classes/api-models/ApiPattern';
import { ApiProduct } from 'src/app/models_new/classes/api-models/ApiProduct';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { ProductApiService } from 'src/app/services/api/product-api.service';
import { NotificationService } from 'src/app/services/notification.service';
import { ITableData } from '../../../gui/table/table.component';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { PatternApiService } from 'src/app/services/api/pattern-api.service';
import { UnitSystemType, getConvertedValue } from 'src/app/utils/unit-utils';
import { BehaviorSubject, map, startWith } from 'rxjs';
import { AppLayoutService } from 'src/app/services/app-layout.service';

const validValueValidator: ValidatorFn = (control) => {
  const validValue = 'VALID';
  return control.value === validValue ? null : { invalidValue: true };
};

type SelectProductActions = 'delete';
@Component({
  selector: 'app-new-pattern',
  templateUrl: './new-pattern.component.html',
  styleUrls: ['./new-pattern.component.scss'],
})
export class NewPatternComponent implements AfterViewInit, OnInit {
  /** isDialog & orgId Meant to be used when the component is used as a dialog. */
  @Input() isDialog: boolean = false;
  @Input() orgId: string;
  @Output() dialogEvent = new EventEmitter<{ id: string; name: string }>();

  init$: Observable<DataRequestState<boolean>>;
  fetchedProducts$: Observable<ApiProduct[]>;
  fetchSelectedProduct$: Observable<ApiProduct>;
  organizationId$: Observable<string>;
  @ViewChild('stepper') stepper: MatStepper;

  createdPattern: ApiPattern;
  stepFormGroup: FormGroup;

  productsTableData$: Observable<
    DataRequestState<ITableData<SelectProductActions>[]>
  >;
  selectedProduct$: BehaviorSubject<ApiProduct> =
    new BehaviorSubject<ApiProduct>(null);
  columnsToDisplay: string[] = [
    'select',
    'name',
    'length',
    'width',
    'height',
    'weight',
  ];
  showNewProductCard: boolean = false;
  private newProductId$: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  unit: UnitSystemType = 'metric';

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productApi: ProductApiService,
    private patternApi: PatternApiService,
    private formBuilder: FormBuilder,
    private notify: NotificationService,
    private localStorageService: LocalStorageService,
    public appLayout: AppLayoutService
  ) {
    this.unit = this.localStorageService.getData(
      LocalStorageKey.PREFERRED_UNIT_SYSTEM
    );
  }

  ngOnInit(): void {
    this.organizationId$ = this.isDialog
      ? of(this.orgId)
      : this.route.data.pipe(
          take(1),
          map(
            (data: Data) =>
              (data as IOrganizationContextResolverData).organization_id
          ),
          shareReplay({ bufferSize: 1, refCount: true })
        );

    this.fetchedProducts$ = this.organizationId$.pipe(
      switchMap((orgId: string) =>
        this.productApi.fetchProductsLight(orgId, false)
      ),
      map((products: ApiProduct[]) =>
        products.sort((a, b) => b.updated_at.localeCompare(a.updated_at))
      )
    );

    this.productsTableData$ = combineLatest([
      this.fetchedProducts$,
      this.newProductId$.pipe(startWith(null)),
    ]).pipe(
      map(([products, newProductId]) => {
        if (products.length === 0) return [];
        let tableData: ITableData<SelectProductActions>[] = [];
        for (let product of products) {
          const weight =
            this.unit === 'imperial'
              ? product.data.weight * 0.001 + ' kg'
              : product.data.weight + ' g';
          const productRow = {
            data: {
              id: product.id,
              name: product.name,
              length: getConvertedValue(product.data.length + ' mm', this.unit),
              width: getConvertedValue(product.data.width + ' mm', this.unit),
              height: getConvertedValue(product.data.height + ' mm', this.unit),
              weight: getConvertedValue(weight, this.unit),
              selected: newProductId === product.id,
            },
          };
          tableData.push(productRow);
          if (newProductId === product.id)
            this.selectProduct([productRow.data]);
        }
        return tableData;
      }),
      toRequestState()
    );

    this.fetchSelectedProduct$ = this.selectedProduct$.pipe(
      skipWhile((product) => !product),
      switchMap((product: ApiProduct) => {
        // Checking if the product is corrupted => No decimals should be allowed past this point.
        if (
          product &&
          (product.data.length % 1 !== 0 ||
            product.data.width % 1 !== 0 ||
            product.data.height % 1 !== 0)
        ) {
          /**
           *  @desc This is part of a temporary measure to cope with corrupted patterns derived from the 'product's decimal-issue'.
           *  This should be removed within a certain timeframe. If corrupted patterns remain past that point, they should be regenerated entirely.
           *  @todo TODO: keep track of this. Remove within a couple of months 221223.
           *  @see {@link https://rocketfarm.slack.com/archives/C038B666N3D/p1701157173252079} for more context.
           */
          return of(product).pipe(
            switchMap((product) => {
              // Product dimentions correction.
              const ceiledLength = Math.ceil(product.data.length);
              const ceiledWidth = Math.ceil(product.data.width);
              const ceiledHeight = Math.ceil(product.data.height);
              // Persisting changes.
              return this.productApi.updateProduct(product.id, product.name, {
                ...product.data,
                length: ceiledLength,
                width: ceiledWidth,
                height: ceiledHeight,
              });
            }),
            switchMap((product) =>
              // Getting the same product with updated values.
              this.productApi.fetchProductById(product.id)
            ),
            take(1)
          );
        } else return of(product);
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );

    this.stepFormGroup = this.formBuilder.group({
      product: new FormControl<ApiProduct>(null, [Validators.required]),
      pattern: new FormControl<ApiPattern>(null, [Validators.required]),
      patternFormGroupStatus: new FormControl<FormControlStatus>('INVALID', [
        validValueValidator,
      ]),
    });

    this.init$ = this.productsTableData$.pipe(
      skipWhile((productTableData) => !productTableData.value),
      tap((productTableData) => {
        if (!this.isDialog)
          this.patchFormValues(
            this.route.snapshot.queryParams,
            productTableData.value?.length
              ? productTableData.value[0].data
              : null
          );
      }),
      map((_) => true),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  ngAfterViewInit(): void {
    if (!this.stepFormGroup.get('product').value) return;
  }

  private patchFormValues(
    queryParams: Params,
    firstPatternInArray: ITableData<SelectProductActions>
  ): void {
    if (!queryParams.cp || firstPatternInArray === null) return;
    this.selectProduct([firstPatternInArray]);
  }

  public patternFromGroupStatusChange(status: FormControlStatus): void {
    this.stepFormGroup.get('patternFormGroupStatus').setValue(status);
  }

  public nextClick(stepper: MatStepper): void {
    const status: FormControlStatus = stepper.selected.stepControl
      .status as FormControlStatus;
    if (status === 'INVALID') {
      this.stepper.next();
      this.notify.showError(`${stepper.selected.errorMessage}`, 3000);
      return;
    }
    this.stepper.next();
  }

  public backClick(stepper: MatStepper): void {
    if (stepper.selectedIndex === 1 && !this.isDialog) {
      this.openRestartDialog().subscribe((result) => {
        if (result) this.stepper.previous();
      });
    } else if (this.isDialog) {
      /** Closes the dialog through the event emitter.*/
      this.dialogEvent.emit(null);
    } else if (stepper.selectedIndex === 0) {
      this.router.navigate([pagesPATH.PATTERNS]);
    } else this.stepper.previous();
  }

  public confirmClick(customizeOnSave: boolean = false): void {
    // Pattern index has two formControls. The pattern and the formGroupStatus in the pattern maker.
    if (
      this.stepFormGroup.get('patternFormGroupStatus').invalid ||
      this.stepFormGroup.get('pattern').invalid
    ) {
      this.notify.showError(`The create pattern form has an error...`, 3000);
      return;
    }

    if (this.stepFormGroup.get('product').invalid) {
      this.notify.showError(`Missing a product...`, 3000);
      return;
    }

    this.organizationId$
      .pipe(
        take(1),
        switchMap((orgId: string) =>
          this.patternApi.insertPattern(
            this.createdPattern,
            { id: this.stepFormGroup.get('product').value.id },
            orgId
          )
        ),
        take(1)
      )
      .subscribe({
        next: (pattern: { id: string; name: string }) => {
          this.notify.showSuccess(
            `Pattern ${pattern.name} created successfully`,
            3000
          );
          if (this.isDialog) {
            this.dialogEvent.emit({ ...pattern, id: pattern.id });
          } else if (customizeOnSave) {
            this.router.navigate([pagesPATH.PATTERNS, pattern.id]);
          } else {
            this.router.navigate([pagesPATH.PATTERNS]);
          }
        },
        error: (err) => {
          this.notify.showError(`Error creating pattern: ${err}`, 3000);
        },
      });
  }

  public createProduct(): void {
    if (this.isDialog) {
      this.showNewProductCard = !this.showNewProductCard;
    } else {
      this.router.navigate([pagesPATH.PRODUCTS, 'new'], {
        queryParams: {
          origin: pagesPATH.PATTERNS + '/new' + '?cp=true',
        },
      });
    }
  }

  public selectProduct(event: any[]): void {
    if (!event[0]) return;
    combineLatest([
      this.fetchedProducts$.pipe(take(1)),
      this.selectedProduct$.pipe(startWith(null)),
    ]).subscribe(([fetchedProds, selectedProd]) => {
      if (selectedProd?.id === event[0].id) return;
      const product = fetchedProds.find((p) => p.id === event[0].id);
      if (!product) {
        this.selectedProduct$.next(null);
        this.notify.showError('Product not found: ', event[0].id);
      }
      // If the form value already are this product, unselect it.
      if (this.stepFormGroup.get('product').value === product) {
        this.selectedProduct$.next(null);
        this.stepFormGroup.get('product').setValue(null);
        return;
      }
      // If the form value is not this product, select it.
      this.selectedProduct$.next(product);
      this.stepFormGroup.get('product').setValue(product);
    });
  }

  public patternEmitted(pattern: ApiPattern): void {
    this.createdPattern = pattern;
    this.stepFormGroup.get('pattern').setValue(pattern);
  }

  private openRestartDialog(): Observable<boolean> {
    return this.notify
      .deletePrompt('Delete', 'Pattern', [
        this.stepFormGroup.get('pattern').value.name,
      ])
      .afterDismissed()
      .pipe(take(1), filter(Boolean));
  }

  public handleProductEvent(event: any): void {
    if (event?.id) this.newProductId$.next(event.id);
    this.showNewProductCard = false;
  }
}
