<div class="pdf-preview-container">
  <div class="content" *ngIf="base64UrlState$ | async as base64UrlState">
    <!-- Loading state -->
    <app-loading *ngIf="base64UrlState.isLoading"></app-loading>

    <!-- Error state -->
    <app-error
      *ngIf="base64UrlState.error as error"
      [error]="error"
    ></app-error>

    <embed
      *ngIf="base64UrlState.didSucceed"
      [src]="base64UrlState.value + '#toolbar=0' | trust: 'resourceUrl'"
    />
  </div>

  <div class="bottomBar">
    <button (click)="download()">
      <mat-icon>download</mat-icon>
      Download
    </button>
    <button (click)="didClickClose()">Close</button>
  </div>
</div>
