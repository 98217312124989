import { Component, OnDestroy } from '@angular/core';
import { Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { Field } from 'src/app/models_new/classes/field';
import { nameRegexp } from 'src/app/models_new/config/validation/pally-file-values';
import { FieldType } from 'src/app/models_new/types/field-type';
import { RobotType } from 'src/app/models_new/types/robot-type';
import { HardwareUtils } from 'src/app/utils/hardware-utils';

// Regex for the name of the robot, should not be "New robot".
// This is to avoid confusion with the default name of the robot.
// I know its not the best solution, but its something.
const newRobotNameRegexp = /^(?!New robot$).*/;

@Component({
  selector: 'app-new-robot-dialog',
  templateUrl: './new-robot-dialog.component.html',
  styleUrls: ['./new-robot-dialog.component.scss'],
})
export class NewRobotDialogComponent implements OnDestroy {
  fields: Field[];

  constructor(public dialogRef: MatDialogRef<NewRobotDialogComponent>) {
    this.fields = [
      new Field(
        FieldType.TEXT,
        true,
        '',
        [
          Validators.minLength(1),
          Validators.pattern(nameRegexp),
          Validators.required,
          Validators.pattern(newRobotNameRegexp),
        ],
        null,
        null,
        null,
        { label: 'Robot name' },
        'name'
      ),
    ];
  }

  ngOnDestroy() {
    this.fields.forEach((m) => m.formControl.reset());
  }

  findRootBrand(serialNumber: string): RobotType {
    return HardwareUtils.getRobotBrand(serialNumber);
  }

  fieldsValid() {
    return this.fields.every((m) => m.formControl.valid);
  }

  close() {
    const selected = this.fields.map((m) => ({
      name: m.id,
      value: m.formControl.value,
    }));
    this.dialogRef.close(selected);
  }
}
