import { Component, OnDestroy, OnInit } from '@angular/core';
import { PickerType } from '../../../models_new/enums/picker-type';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  combineLatest,
  map,
  of,
  partition,
  shareReplay,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { ISidebarContent } from '../../gui/progress-sidebar/progress-sidebar.component';
import { ActivatedRoute, Router } from '@angular/router';
import { RobotConfigApiService } from '../../../services/api/robot-config-api.service';
import { HardwareApiService } from '../../../services/api/hardware-api.service';
import { ApiRobotConfiguration } from '../../../models_new/classes/api-models/ApiRobotConfiguration';
import { NotificationService } from '../../../services/notification.service';
import { IStrategyApiFileType } from '../../../models_new/types/simulation-api-file-type';
import { pagesPATH } from '../../../models_new/config/pages';
import { SoftwareApiService } from '../../../services/api/software-api.service';
import { toRequestState } from '../../../data-request/operators';
import { DataRequestState } from '../../../data-request/model';
import { SupportWidgetService } from '../../../services/support-widget.service';
import { LocalStorageService } from '../../../services/local-storage.service';
import { LocalStorageKey } from '../../../models_new/enums/local-storage-keys';
import { IHwSwType } from '../../../models_new/types/robot-config/hw-sw-type';

export interface IVerifyRobotStepInfo extends ISidebarContent {
  type: 'frame' | 'gripper' | 'liftkit' | 'conveyor' | 'hardware' | 'software';
  listTitle?: string;
}
export interface IVerifyRobotContent {
  rc: ApiRobotConfiguration;
  hwtype: string;
  hwtypes: IHwSwType[];
  configHW: IHwSwType;
  stepInfo: IVerifyRobotStepInfo;
}

enum Steps {
  SelectFrame,
  SelectGripper,
  SelectLiftkit,
  // NOTE: Disabled until pickup position issue is fixed.
  // See https://rocketfarm.atlassian.net/browse/PALLY-3530
  //SelectConveyor,
  ConfirmHardware,
  ConfirmSoftware,
}

@Component({
  selector: 'app-verify-robot-config',
  templateUrl: './verify-robot-config.component.html',
  styleUrls: ['./verify-robot-config.component.scss'],
})
export class VerifyRobotConfigComponent implements OnInit, OnDestroy {
  Steps = Steps;
  step$ = new BehaviorSubject<number>(0);
  nextButtonDisabled = false;
  pickerType = PickerType;

  content$: Observable<DataRequestState<IVerifyRobotContent>>;
  uploadedConfigHW: IHwSwType[];
  selectedHW: IHwSwType[] = [];

  sidebarContent$: Observable<ISidebarContent[]>;
  stepContextData: IVerifyRobotStepInfo[] = [
    {
      id: Steps.SelectFrame,
      header: 'Select a Frame',
      type: 'frame',
      listTitle: 'Frames',
    },
    {
      id: Steps.SelectGripper,
      header: 'Select a Gripper',
      type: 'gripper',
      listTitle: 'Grippers',
    },
    {
      id: Steps.SelectLiftkit,
      header: 'Select a Lifting column',
      type: 'liftkit',
      listTitle: 'Lifting columns',
    },
    // NOTE: See https://rocketfarm.atlassian.net/browse/PALLY-3530
    /*{
      id: Steps.SelectConveyor,
      header: 'Select a Conveyor',
      type: 'conveyor',
      listTitle: 'Conveyors',
    },*/
    {
      id: Steps.ConfirmHardware,
      header: 'Confirm hardware',
      type: 'hardware',
    },
    {
      id: Steps.ConfirmSoftware,
      header: 'Confirm software',
      type: 'software',
    },
  ];

  editingHardware = true;
  editingStrategy = false;

  name: string;
  generatedNameRegex =
    /([a-f0-9]{8}-[a-f0-9]{4}-4[a-f0-9]{3}-[89ab][a-f0-9]{3}-[a-f0-9]{12})_(.*?)_(.*)/i;

  destroy$ = new ReplaySubject<boolean>(1);

  constructor(
    private route: ActivatedRoute,
    private robotconfigApi: RobotConfigApiService,
    private hardwareApi: HardwareApiService,
    private softwareApi: SoftwareApiService,
    private notification: NotificationService,
    private router: Router,
    private supportWidgetService: SupportWidgetService,
    private localstoreService: LocalStorageService
  ) {
    this.sidebarContent$ = of(this.stepContextData);

    this.name = this.localstoreService.getData(LocalStorageKey.IR_ROBOT_NAME);

    const start$ = this.route.params.pipe(
      switchMap((params) =>
        combineLatest([
          this.robotconfigApi.fetchRobotconfigByID(params?.id),
          this.hardwareApi.fetchHwTypes(),
        ])
      ),
      tap(([rc, types]: [ApiRobotConfiguration, IHwSwType[]]) => {
        /**
         * Gather all the hardwares in the config for easier
         * filtering + auto select them.
         */
        const frame = this.getHWFromConfigAndType(rc, types, 'frame');
        const liftkit = this.getHWFromConfigAndType(rc, types, 'liftkit');
        const gripper = this.getHWFromConfigAndType(rc, types, 'gripper');
        // NOTE: See https://rocketfarm.atlassian.net/browse/PALLY-3530
        //const conveyor = this.getHWFromConfigAndType(rc, types, 'conveyor');

        /**
         * Save the hardware that was used in the config and auto-select them.
         * NOTE! Have to declare these seperately to keep the lists independent.
         */
        this.uploadedConfigHW = [frame, liftkit, gripper]; //, conveyor];  // NOTE: See https://rocketfarm.atlassian.net/browse/PALLY-3530
        this.selectedHW = [frame, liftkit, gripper]; //, conveyor];   // NOTE: See https://rocketfarm.atlassian.net/browse/PALLY-3530
      }),
      // Reuse data and hinder subscription-based re-triggers.
      shareReplay({ bufferSize: 1, refCount: false }),

      // Add step number to pipeline.
      switchMap(([rc, types]: [ApiRobotConfiguration, IHwSwType[]]) =>
        combineLatest([of(rc), of(types), this.step$])
      )
    );

    const [inProgress$, done$] = partition(
      start$,
      ([_, __, step]: [ApiRobotConfiguration, IHwSwType[], number]) =>
        step < this.stepContextData.length
    );

    this.content$ = inProgress$.pipe(
      // Quality of life injection of step info based on step number
      switchMap(
        ([rc, types, step]: [ApiRobotConfiguration, IHwSwType[], number]) => {
          return combineLatest([
            of(rc),
            of(types),
            // Replace step number with all step info.
            of(this.stepContextData.find((d) => d.id === step)),
          ]);
        }
      ),
      /**
       * Filter out unecessary hardware for the current step if possible.
       */
      map(
        ([rc, types, stepInfo]: [
          ApiRobotConfiguration,
          IHwSwType[],
          IVerifyRobotStepInfo
        ]) => {
          return {
            rc: rc,
            hwtype: stepInfo.type,
            hwtypes: stepInfo.type
              ? types.filter(
                  (hw: IHwSwType) =>
                    hw.hw_sw_type.name === stepInfo.type &&
                    !this.uploadedConfigHW.find(
                      (t: IHwSwType) => t.id === hw.id
                    )
                )
              : types,
            configHW: this.uploadedConfigHW.find(
              (t: IHwSwType) => t.hw_sw_type.name === stepInfo.type
            ),
            stepInfo: stepInfo,
          };
        }
      ),
      /**
       * NOTE
       * Be careful with "updateScene" and "updateStrategy".
       * They can easily create infinite update loops to the database.
       */
      switchMap((content: IVerifyRobotContent) => {
        // Hardware confirmation step selected, inject the proper hardware if any was selected.
        if (content.stepInfo.id === Steps.ConfirmHardware) {
          let updateScene = false;
          for (const hw of this.selectedHW) {
            // Hardware is not the same as the uploaded, inject it.
            if (!this.uploadedConfigHW.find((h: IHwSwType) => h.id === hw.id)) {
              switch (hw.hw_sw_type.name) {
                case 'frame':
                  content.rc.scene.data.robot.frame.type = hw.name;
                  content.rc.scene.data.robot.frame.label = hw.label;
                  break;
                case 'liftkit':
                  content.rc.scene.data.robot.lift_kit.type = hw.name;
                  content.rc.scene.data.robot.lift_kit.label = hw.label;
                  break;
                case 'gripper':
                  content.rc.scene.data.robot.gripper.type = hw.name;
                  content.rc.scene.data.robot.gripper.label = hw.label;
                  break;
                // NOTE: See https://rocketfarm.atlassian.net/browse/PALLY-3530
                /*case 'conveyor':
                  content.rc.scene.data.conveyors[0].type = hw.name;
                  content.rc.scene.data.conveyors[0].label = hw.label;
                  break;*/
              }
              updateScene = true;
            }
          }

          // Check if we should update the name to the name of installed robot.
          if (
            // Name is given and...
            this.name &&
            // Has the ugly name from generation.
            content.rc.scene.name.match(this.generatedNameRegex)
          ) {
            content.rc.scene.name = this.name;
            updateScene = true;
          }

          let updateStrategy = false;
          // Check if we should update the name to the name of installed robot.
          if (
            // Name is given and...
            this.name &&
            // Has the ugly name from generation.
            content.rc.strategy.name.match(this.generatedNameRegex)
          ) {
            content.rc.strategy.name = this.name;
            updateStrategy = true;
          }

          /**
           * NOTE
           * Be careful with "updateScene" and "updateStrategy".
           * They can easily create infinite update loops to the database.
           */
          return combineLatest([
            of(content),
            updateScene
              ? this.hardwareApi.updateScene(
                  content.rc.scene.id,
                  content.rc.scene.name,
                  content.rc.scene.description,
                  content.rc.scene.data
                )
              : of(undefined),
            updateStrategy
              ? this.softwareApi.updateStrategy(
                  content.rc.strategy.id,
                  content.rc.strategy.name,
                  content.rc.strategy.description,
                  content.rc.strategy.data
                )
              : of(undefined),
          ]);
        }

        return combineLatest([of(content), of(undefined), of(undefined)]);
      }),
      map(([content, _, __]: [IVerifyRobotContent, any, any]) => {
        return content;
      }),
      toRequestState<IVerifyRobotContent>()
    );

    // Confirmation step.
    done$
      .pipe(
        switchMap(
          ([rc, _, ___]: [ApiRobotConfiguration, IHwSwType[], number]) => {
            return this.robotconfigApi.updateRobotConfig(
              rc.id,
              this.name && rc.name.match(this.generatedNameRegex)
                ? // Name is unchanged from generation, give it the name of the IR.
                  this.name
                : // Name was changed, stick with the given name.
                  rc.name,
              rc.scene.id,
              rc.strategy.id
            );
          }
        ),
        tap(() => {
          this.router.navigate([pagesPATH.INSTALLED_ROBOTS]);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.supportWidgetService.elevateSupportButton();
  }

  ngOnDestroy(): void {
    this.supportWidgetService.resetSupportButtonStyling();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  nextStep(id: number): void {
    this.step$.next(id);
  }

  hwSelected(hw: IHwSwType): void {
    const hardware = this.selectedHW.find(
      (h: IHwSwType) => h.hw_sw_type.name === hw.hw_sw_type.name
    );
    if (hardware) {
      const index = this.selectedHW.indexOf(hardware);
      this.selectedHW[index] = hw;
    } else {
      this.selectedHW.push(hw);
    }
  }

  getListTitle(step: number): string {
    return this.stepContextData.find((d) => d.id === step).listTitle;
  }

  getSubText(step: number): string {
    let text: string;
    if (step < Steps.ConfirmHardware) {
      const hwtype = this.stepContextData
        .find((d) => d.id === step)
        .listTitle.toLowerCase()
        .replace(/s$/, '');
      text = `Confirm the ${hwtype} loaded from the file uploaded or pick another one from the hardware library below.`;
    } else if (
      step === Steps.ConfirmHardware ||
      step === Steps.ConfirmSoftware
    ) {
      text = `Edit and confirm the ${
        step === Steps.ConfirmHardware
          ? 'hardware configuration'
          : 'software settings'
      } loaded from the uploaded file.`;
    }
    return text;
  }

  isSelected(hardware: IHwSwType): boolean {
    return Boolean(
      this.selectedHW.find((hw: IHwSwType) => hw.id === hardware.id)
    );
  }

  getHWFromConfigAndType(
    rc: ApiRobotConfiguration,
    hws: IHwSwType[],
    type: string
  ): IHwSwType {
    let hwName;
    switch (type) {
      case 'frame':
        hwName = rc.scene.data.robot.frame.type;
        break;
      case 'liftkit':
        hwName = rc.scene.data.robot.lift_kit.type;
        break;
      case 'gripper':
        hwName = rc.scene.data.robot.gripper.type;
        break;

      // NOTE: See https://rocketfarm.atlassian.net/browse/PALLY-3530
      /*case 'conveyor':
        hwName = rc.scene.data.conveyors[0].type;
        break;*/
    }

    // Assign this hardware to special card.
    return hws.find(
      (t: IHwSwType) => t.name === hwName && t.hw_sw_type.name === type
    );
  }

  hardwareUpdated(): void {
    // Navigate to new scene
    this.notification.showSuccess(
      `The hardware configuration was saved successfully!`
    );
    this.editingHardware = false;
  }

  saveSoftwareConfiguration(
    id: string,
    name: string,
    description: string,
    strategy: IStrategyApiFileType
  ): void {
    this.softwareApi
      .updateStrategy(
        id,
        this.name && name.match(this.generatedNameRegex)
          ? // Name is unchanged from generation, give it the name of the IR.
            this.name
          : // Name was changed, stick with the given name.
            name,
        description,
        strategy
      )
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.notification.showSuccess(
            `The software configuration was saved successfully!`
          );
          this.editingStrategy = false;
        },
        error: (error) => {
          console.error(error);
          this.notification.showError('Something went wrong. Please try again');
        },
      });
  }
}
