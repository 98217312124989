import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LoadStatus } from '../models_new/classes/load-status';

@Injectable({
  providedIn: 'root',
})
export class ProcessingOverlayService {
  activateProcessingOverlay$ = new BehaviorSubject<LoadStatus>(
    new LoadStatus()
  );
  progress$: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  activateOverlay() {
    this.activateProcessingOverlay$.next(new LoadStatus(true));
  }

  deactivateOverlay() {
    this.activateProcessingOverlay$.next(new LoadStatus(false));
  }

  setFailed() {
    this.activateProcessingOverlay$.next(new LoadStatus(false, true));
  }

  hasFailed$(): Observable<boolean> {
    return this.activateProcessingOverlay$.pipe(map((o) => o.failed));
  }

  isLoading$(): Observable<boolean> {
    return this.activateProcessingOverlay$.pipe(map((o) => o.status));
  }

  isLoading(): boolean {
    return this.activateProcessingOverlay$.getValue().status;
  }

  getTitle$(): Observable<string> {
    return this.activateProcessingOverlay$.pipe(map((o) => o.title));
  }

  getMessage$(): Observable<string> {
    return this.activateProcessingOverlay$.pipe(map((o) => o.msg));
  }

  setLoadProgress(progress: number) {
    this.progress$.next(Math.min(100, progress));
  }
}
