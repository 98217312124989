import { Injectable, OnDestroy } from '@angular/core';
import * as THREE from 'three';
import { GpuDetectService, GpuInfo } from './gpu-detect.service';
import {
  Observable,
  ReplaySubject,
  fromEvent,
  map,
  shareReplay,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import { app_colors } from '../../models_new/config/app-theme';
import { ObjectUtils } from '../../utils/object';

export interface IRenderingOptions {
  shadowMap?: {
    enabled?: boolean;
    type?: THREE.ShadowMapType;
  };
  // NOTE! Temporary rollback of THREE.js
  //outputColorSpace?: THREE.ColorSpace;
  outputColorSpace?: THREE.TextureEncoding;
}

@Injectable({
  providedIn: 'root',
})
export class RenderingService implements OnDestroy {
  public renderer: THREE.WebGLRenderer;
  private xrRenderer: THREE.WebGLRenderer | null = null;

  public rendererReady$: Observable<boolean>;

  public contextLost = false;

  renderingContextLost$: Observable<Event>;
  renderingContextRestored$: Observable<Event>;

  destroy$ = new ReplaySubject<boolean>(1);

  constructor(private gpuDetect: GpuDetectService) {
    this.rendererReady$ = this.gpuDetect.gpuInfo$.pipe(
      take(1),
      takeUntil(this.destroy$),
      map((gpuInfo: GpuInfo) => {
        this.renderer = new THREE.WebGLRenderer({
          antialias: gpuInfo.tier >= 1,
          alpha: true,
          // Choose power preference, "default" lets the client decide.
          powerPreference: gpuInfo.tier <= 1 ? 'low-power' : 'default',
        });
        this.renderer.setPixelRatio(window.devicePixelRatio);
        this.renderer.setClearColor(app_colors.background_color, 0);
        // Forces the rendering canvas to be in a legal size, not (0x0) px.
        this.renderer.domElement.style.minWidth = '10px';
        this.renderer.domElement.style.minHeight = '10px';

        this.renderingContextLost$ = fromEvent(
          this.renderer.getContext().canvas,
          'webglcontextlost'
        ).pipe(
          tap((event: Event) => {
            event.preventDefault();
            this.contextLost = true;

            // Takes a little while before we can try to recover the drawing context.
            setTimeout(this.tryRestoreContext.bind(this), 10);
          })
        );
        this.renderingContextRestored$ = fromEvent(
          this.renderer.getContext().canvas,
          'webglcontextrestored'
        ).pipe(
          tap((_event: Event) => {
            this.contextLost = this.renderer.getContext().isContextLost();
          })
        );

        return true;
      }),
      shareReplay({ bufferSize: 1, refCount: false })
    );
  }

  // New method to get the XR renderer
  getXRRenderer(): THREE.WebGLRenderer | null {
    return this.xrRenderer;
  }

  /**
   * Renders the scene and camera on an offscreen canvas of a given size.
   *
   * @param size THREE.Vector2 - Size of the view.
   * @param scene THREE.Scene - Scene to be rendered.
   * @param camera THREE.Camera - Camera to be used for rendering.
   * @param options IRenderingOptions - Options to render with.
   * @returns HTMLCanvasElement - DO **NOT** MODIFY. ONLY READ FROM THIS.
   */
  render(
    size: THREE.Vector2,
    scene: THREE.Scene,
    camera: THREE.Camera,
    options?: IRenderingOptions,
    info?: THREE.WebGLInfo
  ): HTMLCanvasElement | undefined {
    if (!this.renderer || this.contextLost) {
      return undefined;
    }

    if (options?.shadowMap?.enabled) {
      this.renderer.shadowMap.enabled = options?.shadowMap.enabled;
    } else {
      this.renderer.shadowMap.enabled = false;
    }
    if (options?.shadowMap?.type) {
      this.renderer.shadowMap.type = options?.shadowMap?.type;
    } else {
      this.renderer.shadowMap.type = THREE.PCFShadowMap;
    }
    if (options?.outputColorSpace) {
      this.renderer.outputEncoding = options?.outputColorSpace;
    } else {
      this.renderer.outputEncoding = THREE.LinearEncoding;
    }
    /*
    NOTE: Temporarily disabled due to rollback of THREE.js from 0.152.0 to 0.134.0
    if (options?.outputColorSpace) {
      this.renderer.outputColorSpace = options?.outputColorSpace;
    } else {
      this.renderer.outputColorSpace = THREE.SRGBColorSpace;
    }*/
    this.renderer.setSize(size.x, size.y);
    this.renderer.render(scene, camera);
    // If info is given, fill it with the information about the rendering.
    if (info) {
      Object.assign(info, ObjectUtils.cloneObject(this.renderer.info));
    }
    return this.renderer.domElement;
  }

  tryRestoreContext(): void {
    this.renderer.forceContextRestore();
    // Takes a little while before the context restores.
    setTimeout(() => {
      this.contextLost = this.renderer.getContext().isContextLost();
    }, 10);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.renderer.setAnimationLoop(null);
    this.renderer.renderLists.dispose();
    this.renderer.dispose();
  }
}
