import { UnitSystemType } from 'src/app/utils/unit-utils';
import { ApiPalletizingProject } from './ApiPalletizingProject';
import { ApiPattern } from './ApiPattern';
import { ApiScene } from './ApiScene';
import { ApiStrategy } from './ApiStrategy';

export interface IApiSimulation {
  id: string;
  name: string;
  organization_id: string;
  pattern_id: string;
  scene_id: string;
  strategy_id: string;
  created_at?: string;
  updated_at?: string;
  simulation_state?: string;
  simulation_status?: {
    status: string;
    progress: number | string;
    eta: number;
    cpm: number;
    robot_mount_height: number;
    pallet1_time: number;
    pallet2_time: number;
  };
  pattern?: ApiPattern;
  scene?: ApiScene;
  strategy?: ApiStrategy;
  palletizing_project?: ApiPalletizingProject;
  metadata?: {
    unitSystem?: UnitSystemType;
  };
}
export class ApiSimulation implements IApiSimulation {
  id: string;
  name: string;
  organization_id: string;
  pattern_id: string;
  scene_id: string;
  strategy_id: string;
  created_at?: string;
  updated_at?: string;
  simulation_state?: string;
  simulation_status?: {
    status: string;
    progress: number | string;
    eta: number;
    cpm: number;
    robot_mount_height: number;
    pallet1_time: number;
    pallet2_time: number;
    report_data?: {
      wear_and_tear?: string;
    };
    calculated?: {
      cpm: string;
      total_tcp_offset: string;
      robot_mount_height: string;
      average_pallet_time: number;
      average_pallet_time_formatted: string;
    };
  };
  pattern?: ApiPattern;
  scene?: ApiScene;
  strategy?: ApiStrategy;
  palletizing_project?: ApiPalletizingProject;
  metadata?: {
    unitSystem?: UnitSystemType;
  };

  constructor(sim: IApiSimulation) {
    for (const d in sim) {
      if (sim.hasOwnProperty(d)) {
        this[d] = sim[d];
      }
    }
  }
}
