import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { pagesPATH } from '../../models_new/config/pages';
import { ModelUploadComponent } from 'src/app/components/simulation/model-upload/model-upload/model-upload.component';
import { EditorComponent } from 'src/app/components/simulation/model-upload/editor/editor.component';
import { LinkExpiredComponent } from 'src/app/components/gui/link-expired/link-expired.component';
import { OrgInviteComponent } from 'src/app/components/user/org-invite/org-invite.component';
import { OrganizationContextResolver } from 'src/app/resolvers/organization-context-resolver.resolver';
import { AuthGuard } from '@auth0/auth0-angular';
import { RoleGuard } from 'src/app/auth/role-guard';
import { LandingComponent } from 'src/app/components/landing/landing.component';
import { AboutComponent } from 'src/app/components/about/about.component';
import { ReleaseNotesComponent } from '../../components/release-notes/release-notes.component';

const routes: Routes = [
  {
    path: pagesPATH.UPLOAD,
    component: ModelUploadComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '__tempMUEditor',
    component: EditorComponent,
  },
  {
    path: pagesPATH.ORG_INVITE,
    component: OrgInviteComponent,
  },
  {
    path: pagesPATH.SIMULATIONS || pagesPATH.INSTALLED_ROBOT_SIMULATION_WIZARD,
    loadChildren: () =>
      import('../simulation/simulation.module').then((m) => m.SimulationModule),
  },
  {
    path: pagesPATH.PRODUCTS,
    canActivate: [AuthGuard],
    resolve: { organization_id: OrganizationContextResolver },
    loadChildren: () =>
      import('../product/product.module').then((m) => m.ProductModule),
  },
  {
    path: pagesPATH.PRODUCTION_LINES,
    canActivate: [AuthGuard],
    resolve: { organization_id: OrganizationContextResolver },
    loadChildren: () =>
      import('../production-line/production-line.module').then(
        (m) => m.ProductionLineModule
      ),
  },
  {
    path: pagesPATH.PATTERNS,
    canActivate: [AuthGuard],
    resolve: { organization_id: OrganizationContextResolver },
    loadChildren: () =>
      import('../pattern/pattern.module').then((m) => m.PatternModule),
  },
  {
    path: pagesPATH.CUSTOMERS,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../customers/customers.module').then((m) => m.CustomersModule),
  },
  {
    path: pagesPATH.USER,
    loadChildren: () => import('../user/user.module').then((m) => m.UserModule),
  },
  {
    path: pagesPATH.INVENTORY,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../inventory/inventory.module').then((m) => m.InventoryModule),
  },
  {
    path: pagesPATH.ORGANIZATIONS,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../organization/organization.module').then(
        (m) => m.OrganizationModule
      ),
  },
  {
    path: pagesPATH.INSTALLED_ROBOTS,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../installed-robots/installed-robots.module').then(
        (m) => m.InstalledRobotsModule
      ),
  },
  {
    path: pagesPATH.WAYPOINTS,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../waypoints/waypoints.module').then((m) => m.WaypointsModule),
  },
  {
    path: pagesPATH.PALLY_PATH_STRATEGIES,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('../pally-path-strategy/pally-path-strategy.module').then(
        (m) => m.PallyPathStrategyModule
      ),
  },
  {
    path: pagesPATH.BACKOFFICE,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      role: 'rf_backoffice',
      strictRF: true,
    },
    loadChildren: () =>
      import('../backoffice/backoffice.module').then((m) => m.BackofficeModule),
  },
  {
    path: pagesPATH.SHARED_LINK_EXPIRED,
    component: LinkExpiredComponent,
  },
  {
    path: '__component-library',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import(
        '../../components/component-library/component-library.module'
      ).then((m) => m.ComponentLibraryModule),
  },
  {
    path: pagesPATH.FAST_TRACK,
    loadChildren: () =>
      import('../fast-track/fast-track.module').then((m) => m.FastTrackModule),
  },
  {
    path: pagesPATH.ABOUT,
    component: AboutComponent,
    data: {
      showToolbar: false,
    },
  },
  {
    path: pagesPATH.RELEASE_NOTES,
    component: ReleaseNotesComponent,
    data: {
      showToolbar: false,
    },
  },
  {
    path: '',
    component: LandingComponent,
    data: {
      showToolbar: false,
    },
  },
  {
    path: 'simulation/simulations-details/:id',
    redirectTo: pagesPATH.SIMULATIONS + '/:id',
  },
  {
    path: pagesPATH.FAST_TRACK_LADNING,
    redirectTo: pagesPATH.FAST_TRACK,
  },
  {
    path: pagesPATH.FAST_TRACK_PRODUCT,
    redirectTo: pagesPATH.FAST_TRACK + '/' + pagesPATH.FAST_TRACK_PRODUCT,
  },
  {
    path: pagesPATH.FAST_TRACK_PATTERN,
    redirectTo: pagesPATH.FAST_TRACK,
  },
  {
    path: pagesPATH.FAST_TRACK_REGION,
    redirectTo: pagesPATH.FAST_TRACK,
  },
  {
    path: pagesPATH.FAST_TRACK_EXPLORE,
    redirectTo: pagesPATH.FAST_TRACK,
  },
  {
    path: pagesPATH.FAST_TRACK_USER_INFO,
    redirectTo: pagesPATH.FAST_TRACK,
  },
  {
    path: pagesPATH.MY_ROBOTS_DEPRECATED,
    redirectTo: pagesPATH.INSTALLED_ROBOTS,
  },
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard],
})
export class AppRoutingModule {}
