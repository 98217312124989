import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';

export interface ISplitCardTableData {
  overline: { type: string; last_edit: string };
  name: string;
  content: { header: string; value: string; color?: string }[];
}

@Component({
  selector: 'app-split-card-table',
  templateUrl: './split-card-table.component.html',
  styleUrls: ['./split-card-table.component.scss'],
})
export class SplitCardTableComponent implements OnInit, OnChanges {
  @Input() tableData: ISplitCardTableData = undefined;
  @Input() tableHeader: { type: string; last_edit: string } = undefined;
  @Input() actions: {
    id: string;
    label: string;
  }[];
  @Output() actionClicked$ = new EventEmitter<string>();

  displayedColumns: string[] = ['headers', 'values'];
  lastEdited: string = undefined;

  ngOnInit() {
    this.lastEdited = this.tableData?.overline?.last_edit
      ? this.getLastEdited()
      : null;
  }

  ngOnChanges() {
    this.lastEdited = this.tableData?.overline?.last_edit
      ? this.getLastEdited()
      : null;
  }

  getLastEdited() {
    const MILISECPERDAY = 1000 * 60 * 60 * 24;
    let today = new Date();
    let last_edited = new Date(this.tableData.overline.last_edit);

    if (last_edited.getDate() != today.getDate()) {
      let days = Math.floor(
        (Date.now() - last_edited.getTime()) / MILISECPERDAY
      );
      return days >= 0 && days < 2 ? 'yesterday' : days + ' days ago';
    } else {
      let minutes = last_edited.getMinutes().toString();
      minutes = minutes.length === 1 ? minutes + '0' : minutes;
      return last_edited.getHours().toString() + ':' + minutes;
    }
  }
}
