<ng-container *ngIf="hwConfig$ | async as config">
  <app-split-card-table
    [tableData]="tableData$ | async"
    [actions]="actions"
    (actionClicked$)="actionClicked$.emit($event)"
    *ngIf="updateModel$ | async"
  >
    <img
      class="scene-image"
      style="height: 500px"
      [src]="config?.image?.url"
      alt="Cannot load scene image."
      onerror="this.src='../../../../assets/illustrative/simulation.png';"
    />
  </app-split-card-table>
</ng-container>
