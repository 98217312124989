import { IStandardPallet, StandardPallet } from '../types/standard-pallet';
import {
  UntypedFormControl,
  Validators,
  UntypedFormGroup,
} from '@angular/forms';
import { Field } from '../classes/field';
import { FieldType } from '../types/field-type';
import { SimulationStatus } from '../enums/simulation-status';
import { ConveyorDirection } from '../enums/sim-config-conveyor-dir';
import { floatWithinTolerance } from '../../utils/div';
import { settings } from './application-settings';
import { LabelOrientation } from '../enums/label-orientation';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { LocalStorageKey } from '../enums/local-storage-keys';
import { SimConfigFieldIds } from '../enums/simconfig-field-ids';

export const simulationConfigDownloadDialogFields: Field[] = [
  new Field(
    FieldType.CHECKBOX,
    false,
    '',
    [],
    null,
    null,
    null,
    { name: 'send_to_sheet', label: 'Send to google sheets?' },
    'send_to_sheet'
  ),
  new Field(
    FieldType.TEXT,
    false,
    '',
    [],
    null,
    null,
    null,
    { name: 'sim_id', label: 'Simulation id', hint: 'If existing simulation' },
    'sim_id'
  ),
];

export const simulationReportDialogFields: Field[] = [
  new Field(
    FieldType.NUMBER,
    true,
    '',
    [Validators.required, Validators.minLength(1)],
    null,
    null,
    null,
    { name: 'cpm', label: 'Achieved cpm' },
    'cpm'
  ),
  new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required, Validators.minLength(1)],
    null,
    null,
    null,
    { name: 'pallet_time', label: 'Pallet time' },
    'pallet_time'
  ),
  new Field(
    FieldType.SELECT_SINGLE,
    true,
    '',
    [Validators.required, Validators.minLength(1)],
    null,
    Object.keys(SimulationStatus).map((k) => SimulationStatus[k as any]),
    null,
    { name: 'simulation_state', label: 'Result' },
    'simulation_state'
  ),
  new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required, Validators.minLength(1)],
    null,
    null,
    null,
    { name: 'simulation_url', label: 'Simulation URL' },
    'simulation_url'
  ),
  new Field(
    FieldType.NUMBER,
    true,
    '',
    [Validators.required, Validators.minLength(1)],
    null,
    null,
    null,
    { name: 'total_gripper_offset', label: 'Total gripper offset' },
    'total_gripper_offset'
  ),
  new Field(
    FieldType.TEXT,
    false,
    '',
    [],
    null,
    null,
    null,
    { name: 'desc', label: 'Description' },
    'desc'
  ),
];

export const dialogGeneralFields: Field[] = [
  new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required, Validators.minLength(1)],
    null,
    null,
    null,
    { name: 'first_name', label: 'First name' },
    'first_name'
  ),
  new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required, Validators.minLength(1)],
    null,
    null,
    null,
    { name: 'last_name', label: 'Last name' },
    'last_name'
  ),
  new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required, Validators.minLength(1)],
    null,
    null,
    null,
    { name: 'job_title', label: 'Job title' },
    'job_title'
  ),
  new Field(
    FieldType.TEXT,
    true,
    '',
    [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$'),
    ],
    null,
    null,
    null,
    { name: 'email', label: 'E-mail' },
    'email'
  ),
  new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required, Validators.minLength(1)],
    null,
    null,
    null,
    { name: 'company', label: 'Company' },
    'company'
  ),
  new Field(
    FieldType.CHECKBOX,
    false,
    '',
    [],
    null,
    null,
    null,
    {
      name: 'subscribe',
      label: 'Subscribe to our mailing list. You can unsubscribe at any time.',
    },
    'subscribe'
  ),
  new Field(
    FieldType.CHECKBOX,
    true,
    '',
    [Validators.requiredTrue],
    null,
    null,
    null,
    {
      name: 'agreement',
      label: `* I agree to Rocketfarms <a href="${settings.privacyPolicyUrl}" target="_new">privacy policy</a> (GDPR Required)`,
    },
    'agreement'
  ),
  new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required, Validators.pattern('^[0-9a-zA-Z-_. ]{1,}$')],
    null,
    null,
    null,
    { name: 'name', label: 'Project name' },
    'projectName'
  ),
  new Field(
    FieldType.TEXT,
    false,
    '',
    [],
    null,
    null,
    null,
    { name: 'description', label: 'Description' },
    'projectDescription'
  ),
  new Field(
    FieldType.NUMBER,
    false,
    '',
    [],
    null,
    null,
    null,
    { name: 'cpm', label: 'Cases per minute' },
    'cpm'
  ),
  new Field(
    FieldType.TEXT,
    false,
    '',
    [Validators.maxLength(200)],
    null,
    null,
    null,
    { name: 'message', label: 'Message' },
    'message'
  ),
];

export const dialogRequiredFields: Field[] = [
  new Field(
    FieldType.TEXT,
    true,
    '',
    [Validators.required, Validators.pattern('^[0-9a-zA-Z-_. ]{1,}$')],
    null,
    null,
    null,
    { name: 'name', label: 'Project name' },
    'projectName'
  ),
  new Field(
    FieldType.TEXT,
    false,
    '',
    [],
    null,
    null,
    null,
    { name: 'description', label: 'Description' },
    'projectDescription'
  ),
  new Field(
    FieldType.NUMBER,
    false,
    '',
    [],
    null,
    null,
    null,
    { name: 'cpm', label: 'Cases per minute' },
    'cpm'
  ),
  new Field(
    FieldType.TEXT,
    false,
    '',
    [Validators.maxLength(200)],
    null,
    null,
    null,
    { name: 'message', label: 'Message' },
    'message'
  ),
];

export const projectDataFormGroup = new UntypedFormGroup({
  project: new UntypedFormGroup({
    name: new UntypedFormControl('', []),
    description: new UntypedFormControl('', Validators.maxLength(200)),
    units: new UntypedFormControl(''),
  }),

  product: new UntypedFormGroup({
    productLength: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(1500),
    ]),
    productWidth: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(1500),
    ]),
    productHeight: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(5000),
    ]),
    productWeight: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
    ]),
    labelOrientation: new UntypedFormControl(''),
  }),

  pallet: new UntypedFormGroup({
    standardPallet: new UntypedFormControl(''),

    palletLength: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(1500),
    ]),
    palletWidth: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(1500),
    ]),
    noOfLayers: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(100),
    ]),
    palletLoadHeight: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(16000),
    ]),
    palletHeight: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(1000),
    ]),

    palletOverhangEnds: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(1500),
    ]),
    palletOverhangSides: new UntypedFormControl('', [
      Validators.min(0),
      Validators.max(1500),
    ]),
  }),

  palletizing: new UntypedFormGroup({
    productionCycle: new UntypedFormControl(''),
    maxGrip: new UntypedFormControl('', [
      Validators.required,
      Validators.min(1),
      Validators.max(8),
    ]),
    boxPadding: new UntypedFormControl('', [
      Validators.required,
      Validators.min(0),
      Validators.max(100),
    ]),
    shimpaperHeight: new UntypedFormControl('', [
      Validators.min(1),
      Validators.max(150),
    ]),
  }),
});

export const reportMeasurement = [
  {
    value: 'metric',
    name: 'Metric: mm / kg',
    suffixLW: 'mm',
    suffixW: 'kg',
    divideMM: 1,
    divideG: 1,
  },
  {
    value: 'imperial',
    name: 'Imperial: inches / pounds',
    suffixLW: 'inches',
    suffixW: 'lbs',
    divideMM: 25.4,
    divideG: 454,
  },
];

export const projectDataMeasurement = [
  {
    value: 'metric',
    name: 'Metric: mm / g',
    suffixLW: 'mm',
    suffixW: 'g',
    divideMM: 1,
    divideG: 1,
  },
  {
    value: 'imperial',
    name: 'Imperial: inches / pounds',
    suffixLW: 'inches',
    suffixW: 'lbs',
    divideMM: 25.4,
    divideG: 454,
  },
];

export const labelOrientations: {
  value: LabelOrientation;
  name: string;
  icon: string;
}[] = [
  {
    value: LabelOrientation.NULL,
    name: 'None',
    icon: 'border_clear',
  },
  {
    value: LabelOrientation.FRONT,
    name: 'Front',
    icon: 'border_bottom',
  },
  {
    value: LabelOrientation.RIGHT,
    name: 'Right',
    icon: 'border_right',
  },
  {
    value: LabelOrientation.BACK,
    name: 'Back',
    icon: 'border_top',
  },
  {
    value: LabelOrientation.LEFT,
    name: 'Left',
    icon: 'border_left',
  },
];
export const labelDirections = ['Optimized', 'Outwards', 'Locked'];

export const conveyorDirections = new Map<string, { name: string; value: any }>(
  [
    [ConveyorDirection.FRONT, { name: ConveyorDirection.FRONT, value: 0 }],
    [ConveyorDirection.RIGHT, { name: ConveyorDirection.RIGHT, value: -90 }],
    [ConveyorDirection.LEFT, { name: ConveyorDirection.LEFT, value: 90 }],
  ]
);
export function getConveyorOrientationFromAngle(angle: number) {
  for (const [_, o] of conveyorDirections) {
    if (floatWithinTolerance(o.value, angle, 0.1)) {
      return o;
    }
  }

  // Default to front direction
  return conveyorDirections.get(ConveyorDirection.FRONT);
}

const standardPalletObjects: IStandardPallet[] = [
  {
    name: 'US 1',
    length: 1219,
    width: 1016,
    loadHeight: 1200,
    palletHeight: 165,
  },
  {
    name: 'US 2',
    length: 1067,
    width: 1067,
    loadHeight: 1200,
    palletHeight: 165,
  },
  {
    name: 'EU 1',
    length: 1200,
    width: 800,
    loadHeight: 1200,
    palletHeight: 144,
  },
  {
    name: 'EU 2',
    length: 1200,
    width: 1000,
    loadHeight: 1200,
    palletHeight: 144,
  },
  {
    name: 'EU 6',
    length: 800,
    width: 600,
    loadHeight: 1200,
    palletHeight: 144,
  },
  {
    name: 'AU 1',
    length: 1165,
    width: 1165,
    loadHeight: 1200,
    palletHeight: 150,
  },
  {
    name: 'ASIA 1',
    length: 1100,
    width: 1100,
    loadHeight: 1200,
    palletHeight: 145,
  },
  {
    name: 'CHEP',
    length: 1200,
    width: 1200,
    loadHeight: 1200,
    palletHeight: 150,
  },
];

export const defaultPalletStandard = 2;

const getCustomPallet = () => {
  const service = new LocalStorageService();
  return service.getData(LocalStorageKey.CUSTOM_PALLET)
    ? service.getData(LocalStorageKey.CUSTOM_PALLET)
    : [];
};

export function addPalletToList(pallet: StandardPallet) {
  ListOfAllPallets = ListOfAllPallets.concat(pallet);
}

export function removePalletFromList(palletName: string) {
  ListOfAllPallets = ListOfAllPallets.filter((p) => {
    return p.name !== palletName;
  });
}

export let ListOfAllPallets = standardPalletObjects
  .concat(getCustomPallet())
  .map((obj) => new StandardPallet(obj, new LocalStorageService()));

export const excludedConversionFieldIds: string[] = [
  SimConfigFieldIds.LiftkitType,
  SimConfigFieldIds.GripperType,
];

export enum CardFieldIds {
  // Product
  ProductName = 'product_name',
  ProductType = 'product_type',
  ProductLength = 'product_length',
  ProductWidth = 'product_width',
  ProductHeight = 'product_height',
  ProductWeight = 'product_weight',
  ProductLabelOrientation = 'product_label_orientation',

  // Pallet
  PalletSelect = 'pallet_select',
  PalletLength = 'pallet_length',
  PalletWidth = 'pallet_width',
  PalletHeight = 'pallet_height',

  // Pattern
  PatternName = 'pattern_name',
  PatternDescription = 'pattern_description',
  PatternStackingMethod = 'pattern_stacking_method',
  PatternProductPadding = 'pattern_product_padding',
  PatternLabelDirection = 'pattern_label_direction',
  PatternLabelOutwardYesNo = 'pattern_label_outward_yes_no',
  PatternNumberLayers = 'pattern_nr_layers',
  PatternOverhangEnds = 'pattern_overhang_ends',
  PatternOverhangSides = 'pattern_overhang_sides',
  PatternMaxGrip = 'pattern_max_grip',
  Cpm = 'cpm',

  // Prodline
  ProdlineName = 'prodline_name',
  ProdlineCpm = 'prodline_cpm',
  ProdlineHop = 'prodline_hop',
}
