import { Injector } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { FieldUpdate } from 'src/app/models_new/types/field-update';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { Type } from 'src/app/utils/type';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { PartType } from 'src/app/models_new/enums/sim-config-part-type';
import { take, takeUntil } from 'rxjs/operators';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { URDFJoint, URDFRobot } from '@rocketfarm/urdf-loader';

export class SceneTask extends Task {
  // Avoids reporting of this task. Not really used.
  static [taskNameSymbol] = '';

  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, reject: (reason?: any) => void): void {
    this.updateScene(this.data, resolve, reject);
  }

  public updateScene(
    s: FieldUpdate,
    resolve: () => void,
    _reject: (reason?: any) => void
  ): void {
    const sceneJoint = this.robot.getJointByID(JointNames.SceneJoint);
    const sceneVisual = URDFUtils.findVisualFromJoint(sceneJoint);

    // No scene selected, remove any possible scene.
    if (s.newVal.name === 'NONE') {
      ThreeUtils.disposeObject(sceneVisual.children);
      sceneVisual.children = [];
      resolve();
      return;
    }

    const partID = HwPartUtils.getPartAssetID(PartType.SCENE, s.newVal);

    // Part not added / supported, skip.
    if (!Type.isDefined(partID)) {
      resolve();
      return;
    }

    this.partStoreService
      .getPart<URDFRobot>(partID)
      .pipe(
        takeUntil(this.destroy$),
        RXJSUtils.filterUndefinedAndNull(),
        take(1)
      )
      .subscribe((part) => {
        const partJoint = part.getObjectByName(
          JointNames.SceneJoint
        ) as URDFJoint;
        const partVisual = URDFUtils.findVisualFromJoint(partJoint);

        // Copy over transform values
        ThreeUtils.copyTransform(sceneVisual, partVisual);

        for (const child of partVisual.children) {
          sceneVisual.add(ThreeUtils.clone(child));
        }

        resolve();
      });
  }
}
