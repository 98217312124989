import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  combineLatest,
  delay,
  filter,
  map,
  Observable,
  of,
  ReplaySubject,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import * as THREE from 'three';
import { Project } from '../../../../../models_new/classes/project';
import { settings } from '../../../../../models_new/config/application-settings';
import { LabelOrientation } from '../../../../../models_new/enums/label-orientation';
import { PalletPosition } from '../../../../../models_new/enums/pallet-position';
import { Pallet } from '../../../../../models_new/classes/pallet';
import { calcPalletEdgeOffset, milliToMeter } from '../../../../../utils/div';

@Component({
  selector: 'app-pattern-view',
  templateUrl: './pattern-view.component.html',
  styleUrls: ['./pattern-view.component.scss'],
})
export class PatternViewComponent implements OnInit, OnChanges {
  @Input() project: Project;
  @Input() sticker: THREE.Texture;
  @Input() productTypeId: string;
  sticker$: Observable<THREE.Texture>;

  rightPallet: Pallet;
  rightPalletPos: THREE.Vector3;
  leftPallet: Pallet;
  leftPalletPos: THREE.Vector3;

  labelOrientations: LabelOrientation[];

  ngOnChanges$ = new ReplaySubject<SimpleChanges>(1);

  // Default position is a little close, so go twice as far.
  cameraPos = settings.view3d.defaultCameraPosition.clone().multiplyScalar(2);
  lightPos1 = new THREE.Vector3(4, 3.5, 5);
  lightPos2 = new THREE.Vector3(4, 3.5, -5);
  lightPos3 = new THREE.Vector3(-4, 3.5, 5);
  lightPos4 = new THREE.Vector3(-4, 3.5, -5);
  controlsTarget = new THREE.Vector3();

  private readonly palletXOffset = 0.333;

  destroy$ = new Subject<boolean>();

  ngOnInit(): void {
    this.sticker$ = this.ngOnChanges$.pipe(
      filter((c) => Boolean(c.sticker)),
      map((c) => c.sticker.currentValue)
    );

    this.ngOnChanges$
      .pipe(
        takeUntil(this.destroy$),
        filter((c: SimpleChanges) => Boolean(c.project?.currentValue)),
        map((c: SimpleChanges) => c.project.currentValue),
        switchMap((project: Project) =>
          combineLatest([of(project), project.update$])
        ),
        delay(0)
      )
      .subscribe(([project, updates]) => {
        // Also wait for updates when boxes are first added.
        if (project && updates) {
          this.rightPallet = project.getPalletByPosition(PalletPosition.RIGHT);
          this.rightPalletPos = this.getPalletPosition(this.rightPallet);

          this.leftPallet = project.getPalletByPosition(PalletPosition.LEFT);
          this.leftPalletPos = this.getPalletPosition(this.leftPallet);

          const totalHeight = milliToMeter(
            this.rightPallet.getPalletLoadHeight() +
              this.rightPallet.getPalletHeight()
          );

          if (project.palletView.palletViewSettings.showLeftPallet) {
            this.controlsTarget = new THREE.Vector3(0, totalHeight / 2, 0);
          } else {
            this.controlsTarget = this.rightPalletPos.clone();
            this.controlsTarget.y += totalHeight / 2;
          }

          const isLabelEnabled =
            typeof this.project.data.box.label.orientation !== 'undefined' &&
            this.project.data.box.label.orientation !== null;
          if (isLabelEnabled) {
            let oppositeLabelOrientation =
              (project.data.box.label.orientation + 180) % 360;
            if (oppositeLabelOrientation === 270) {
              oppositeLabelOrientation = LabelOrientation.LEFT;
            }

            this.labelOrientations = [
              project.data.box.label.orientation,
              oppositeLabelOrientation,
            ];
          } else {
            this.labelOrientations = [null];
          }
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.ngOnChanges$.next(changes);
  }

  getPalletPosition(pallet: Pallet): THREE.Vector3 {
    // Get pallet edges
    const edgeOffset = calcPalletEdgeOffset(
      pallet.dimensions.width / 1000,
      pallet.dimensions.length / 1000
    );

    const pos = new THREE.Vector3();
    // Move pallet position into position
    if (pallet.position === PalletPosition.RIGHT) {
      pos.x = edgeOffset.right + this.palletXOffset;
    } else {
      pos.x = edgeOffset.left - this.palletXOffset;
    }
    return pos;
  }
}
