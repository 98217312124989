import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationExtras,
  Router,
} from '@angular/router';
import { BehaviorSubject, Observable, combineLatest, of } from 'rxjs';
import {
  shareReplay,
  take,
  map,
  switchMap,
  filter,
  startWith,
  skipWhile,
} from 'rxjs/operators';
import { settings } from 'src/app/models_new/config/application-settings';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { LocalStorageKey } from 'src/app/models_new/enums/local-storage-keys';
import { IInfoCard } from 'src/app/models_new/types/info-card-config';
import { InfoApiService } from 'src/app/services/api/info-api.service';
import {
  IFastTrackQueryParams,
  PublicApiService,
} from 'src/app/services/api/public-api.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ObjectUtils } from 'src/app/utils/object';
import { GoogleAnalyticsService } from '../../services/google-analytics-service.service';
import { IApiSimulation } from '../../models_new/classes/api-models/ApiSimulation';
import {
  IOpenSimPage,
  fastTrackPages,
} from 'src/app/models_new/config/fast-track-pages';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';

export interface IReadyOpenSimulation {
  id?: string;
  leadOwner?: string;
  leadOwnerType?: 'vendor' | 'backoffice' | 'provider';
  solutionId?: string;
  name: string;
  // Filled if fetched with pattern id
  solution?: {
    name: string;
    solution_provider_id?: string;
    solution_provider?: {
      name: string;
    };
    metadata?: {
      [key: string]: string;
    };
    externalUrl?: string;
  };
  sceneId?: string;
  strategyId?: string;
  progress?: IApiSimulation['simulation_status']['progress'] | string;
  cpm?: IApiSimulation['simulation_status']['cpm'] | string;
  eta?: IApiSimulation['simulation_status']['eta'] | string;
  status?: IApiSimulation['simulation_state'];
  metadata?: {
    [key: string]: string;
  };
  externalUrl?: string;
  startingPrice?: string;
}

export interface ICurrentStep {
  index: number;
  id: string;
}

@Injectable({
  providedIn: 'root',
})
export class OpenSimService {
  queriedParams: IFastTrackQueryParams = {};

  currentStep$: BehaviorSubject<ICurrentStep> =
    new BehaviorSubject<ICurrentStep>({ index: 0, id: null });

  _defaultPages: IOpenSimPage[] = fastTrackPages;
  // This is the filtered and sorted pages actally shown to the user.
  pagesSortedList$: BehaviorSubject<IOpenSimPage[]> = new BehaviorSubject<
    IOpenSimPage[]
  >(null);

  contentFulFooter$: Observable<IInfoCard[]>;
  readySimulations$: Observable<IReadyOpenSimulation[]> = of(null);
  embedHandler$: Observable<DataRequestState<boolean>>;

  embed: boolean = false;
  started: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private infoApi: InfoApiService,
    private localStorage: LocalStorageService,
    private errorHandler: ErrorHandlerService,
    private publicApi: PublicApiService,
    private gas: GoogleAnalyticsService
  ) {
    if (this.pageInIframe()) {
      this.embed = true;
      this.started = true;
    }

    this.embedHandler$ = combineLatest([
      of(this.route.snapshot.queryParams.apiKey || null),
      of(this.checkReferrer()),
    ]).pipe(
      take(1),
      switchMap(([apiKey, referrer]) => {
        if (this.embed && this.pageInIframe()) {
          if (!apiKey || !referrer) {
            return of(false);
          } else {
            return this.publicApi.validateEmbedApiKey(apiKey, referrer);
          }
        } else {
          return of(true);
        }
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
      toRequestState()
    );

    this.router.events
      .pipe(
        filter((route_event) => route_event instanceof NavigationEnd),
        startWith(null),
        map((_) => {
          const providerId = (this.queriedParams.providerId =
            this.route.snapshot.queryParams?.sp);
          const solutionId = (this.queriedParams.solutionId =
            this.route.snapshot.queryParams?.si);
          this.queriedParams.vendorName = this.route.snapshot.queryParams?.vn;
          this.queriedParams.vendorId = this.route.snapshot.queryParams?.vi;
          if (providerId || solutionId) {
            // Skip the Region step if the query params already point
            // to a solution or priovider.
            return this._defaultPages.filter(
              (page) => page.path !== pagesPATH.FAST_TRACK_REGION
            );
          } else {
            // Skip the PartnerLanding step if the query params point
            // to a solution or priovider.
            return this._defaultPages.filter(
              (page) => page.path !== pagesPATH.FAST_TRACK_LADNING
            );
          }
        }),
        switchMap((pages) =>
          combineLatest([
            of(
              // Dynamically adds the step index to the pages.
              pages.map((page, index) => {
                return { ...page, step: index };
              })
            ),
            this.infoApi.getOpenSimInfo(),
            this.currentStep$.pipe(take(1)),
          ])
        )
      )
      .subscribe(([pages, content, currentStep]) => {
        // Initializes the current-step and the pagesSortedList if not already set.
        if (!currentStep.id) {
          this.currentStep$.next({
            index: 0,
            id: pages[0].contentfulStepId,
          });
          pages.map(
            (page) =>
              (page.content = content.find(
                (c) => c.stepId === page.contentfulStepId
              ))
          );
          this.pagesSortedList$.next(pages);
          this.routerNavigate([pages[0].path]);
        }
      });

    this.contentFulFooter$ = this.infoApi.getLandingPageFooterInfo().pipe(
      take(1),
      map((v: IInfoCard[]) => {
        const clone = ObjectUtils.cloneObject(v);
        clone.forEach((v) => {
          v.text = v.text?.split('\n') as any;
        });
        return clone;
      }),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  public getPageContentByStepId(stepId: string): Observable<IOpenSimPage> {
    return this.pagesSortedList$.pipe(
      skipWhile((v) => !v),
      take(1),
      map((pagesSortedList) =>
        pagesSortedList.find((page) => page.contentfulStepId === stepId)
      )
    );
  }

  private pageInIframe(): boolean {
    return window.location !== window.parent.location;
  }

  private checkReferrer(): string {
    return document.referrer ? document.referrer : null;
  }

  public reset(): void {
    this.pagesSortedList$
      .pipe(
        skipWhile((pages) => !pages),
        take(1)
      )
      .subscribe((pagesSortedList) => {
        pagesSortedList.forEach((s) => {
          if (s.contentfulStepId !== 'landing') {
            s.done = false;
            s.valid = false;
          }
        });
        this.pagesSortedList$.next(pagesSortedList);
        this.currentStep$.next({
          index: 0,
          id: pagesSortedList[0].contentfulStepId,
        });
      });
  }

  public start(): void {
    this.started = !this.started;
  }

  public showNavTopBottom(): boolean {
    return settings.fastTrackRoutes.some((v) =>
      this.router.routerState.snapshot.url.includes(v)
    );
  }

  public setStepById(stepId: string): Observable<ICurrentStep> {
    return this.pagesSortedList$.pipe(
      skipWhile((v) => !v),
      take(1),
      map((pagesSortedList) => {
        const stepToSet = pagesSortedList.find(
          (page) => page.contentfulStepId === stepId
        );
        const currentStep = {
          index: stepToSet.step,
          id: stepToSet.contentfulStepId,
        };
        this.currentStep$.next(currentStep);
        return currentStep;
      })
    );
  }

  private routerNavigate(commands?: any[], extras?: NavigationExtras): void {
    if (!this.embed)
      this.router.navigate([pagesPATH.FAST_TRACK, ...commands], {
        queryParamsHandling: 'preserve',
        ...extras,
      });
  }

  public back(): void {
    combineLatest([this.pagesSortedList$, this.currentStep$])
      .pipe(take(1))
      .subscribe(([pagesSortedList, currentStep]) => {
        const analyticsTag =
          pagesSortedList[currentStep.index].analyticsTags.backClick;
        if (analyticsTag) {
          this.gas.addEvent(analyticsTag, null, this.gas.getLastParam());
        }
        if (currentStep.index > 0) {
          this.navigateToPreviousPage(pagesSortedList);
        } else if (currentStep.index === 0 && this.started) {
          this.started = false;
        } else {
          this.routerNavigate();
        }
      });
  }

  public next(): void {
    combineLatest([this.pagesSortedList$, this.currentStep$])
      .pipe(take(1))
      .subscribe(([pagesSortedList, currentStep]) => {
        const analyticsTag =
          pagesSortedList[currentStep.index].analyticsTags.nextClick;
        if (analyticsTag) {
          this.gas.addEvent(analyticsTag, null, this.gas.getLastParam());
        }
        // If next step is the user-details step, fetch the available solutions.
        if (
          pagesSortedList[currentStep.index + 1]?.contentfulStepId ===
          'user-details'
        ) {
          const os = this.localStorage.getData(LocalStorageKey.OPEN_SIM);
          if (
            os ||
            this.queriedParams.providerId ||
            this.queriedParams.solutionId
          ) {
            this.getSolutionsThenNavigateToNextPage(os, pagesSortedList);
          } else {
            this.errorHandler.handleError(
              new Error('We are missing your info or region...')
            );
          }
        } else {
          this.navigateToNextPage(pagesSortedList);
        }
      });
  }

  private getSolutionsThenNavigateToNextPage(os: any, pagesSortedList): void {
    this.getAvailableSolutions(os ?? {});
    this.readySimulations$.pipe(take(1)).subscribe({
      next: (_) => this.navigateToNextPage(pagesSortedList),
      error: (err) => this.errorHandler.handleError(err),
    });
  }

  private navigateToNextPage(pagesSortedList: IOpenSimPage[]): void {
    this.currentStep$
      .pipe(
        take(1),
        switchMap((currentIndex) =>
          combineLatest([
            of(currentIndex.index),
            this.embed
              ? this.setStepById(
                  pagesSortedList[currentIndex.index + 1].contentfulStepId
                ).pipe(map((p) => p.index))
              : of(currentIndex.index + 1),
          ])
        )
      )
      .subscribe(([currentIndex, nextIndex]) => {
        if (currentIndex === pagesSortedList.length - 1 || this.embed) return;
        this.routerNavigate([pagesSortedList[nextIndex].path]);
      });
  }

  private navigateToPreviousPage(pagesSortedList: IOpenSimPage[]): void {
    this.currentStep$.pipe(take(1)).subscribe((currentStep) => {
      if (currentStep.index === 0) return;
      const newIndex = currentStep.index - 1;
      this.currentStep$.next({
        index: newIndex,
        id: pagesSortedList[newIndex].contentfulStepId,
      });
      this.routerNavigate([pagesSortedList[newIndex].path]);
    });
  }

  public findAndNavigateToPreviousPage(currentStep: number): void {
    this.pagesSortedList$.pipe(take(1)).subscribe((pagesSortedList) => {
      const previousPageIndexStep = currentStep - 1;
      this.routerNavigate([pagesSortedList[previousPageIndexStep].path]);
    });
  }

  public prevIsValid(currentStep: number): Observable<boolean> {
    return this.pagesSortedList$.pipe(
      skipWhile((v) => !v),
      take(1),
      map((pagesSortedList) => {
        const previousPageIndex = currentStep - 1;
        if (previousPageIndex < 0) return true;
        return pagesSortedList[previousPageIndex].valid;
      })
    );
  }

  public setStepValidity(valid: boolean): void {
    combineLatest([
      this.pagesSortedList$.pipe(skipWhile((v) => !v)),
      this.currentStep$.pipe(skipWhile((v) => !v)),
    ])
      .pipe(take(1))
      .subscribe(([pagesSortedList, currentStep]) => {
        pagesSortedList.forEach((page) => {
          if (page.contentfulStepId === currentStep.id) {
            page.valid = valid;
            page.done = valid;
          }
        });
        this.pagesSortedList$.next(pagesSortedList);
      });
  }

  private getAvailableSolutions(os: {
    continent: string;
    country: string;
    email: string;
    fullName: string;
    region: string;
  }): void {
    this.readySimulations$ = this.getVendorIdFromVendorName(
      this.queriedParams.vendorName
    ).pipe(
      switchMap((vendorId) => {
        // Temporary fix to avoid sending both vendorId and vendorName to back-end, as it causes an error.
        if (vendorId) {
          this.queriedParams.vendorId = vendorId;
          this.queriedParams.vendorName = null;
        }
        return this.publicApi.getAvailableSolutions(
          os.continent,
          os.country,
          os.email,
          os.fullName,
          os.region,
          this.queriedParams
        );
      }),
      take(1),
      shareReplay({ bufferSize: 1, refCount: true })
    );
  }

  private getVendorIdFromVendorName(vendorName: string): Observable<string> {
    return vendorName
      ? this.publicApi
          .getVendorOrganizations()
          .pipe(
            map(
              (vendorOrganizations) =>
                vendorOrganizations.find((vendor) => vendor.name === vendorName)
                  ?.id || null
            )
          )
      : of(null);
  }

  public startSimulations(): Observable<string> {
    const pp = this.localStorage.getData(
      LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN
    );
    const os = this.localStorage.getData(LocalStorageKey.OPEN_SIM);

    if (!pp) {
      this.errorHandler.handleError(
        new Error('We are missing your product or pattern configuration...')
      );
      return of('');
    }
    if (!os) {
      this.errorHandler.handleError(
        new Error('We are missing your info or region...')
      );
      return of('');
    } else {
      return this.readySimulations$.pipe(
        switchMap((as: IReadyOpenSimulation[]) =>
          this.publicApi.insertOpenSimulations(pp, os, as, this.queriedParams)
        )
      );
    }
  }

  public resetFlow(): void {
    this.localStorage.removeData(LocalStorageKey.OPEN_SIM_PALLETIZING_PATTERN);
    this.readySimulations$ = of(null);
    if (this.embed) {
      this.setStepById('product-details').subscribe(() => {
        this.reset();
      });
    } else {
      this.router.navigate(
        [pagesPATH.FAST_TRACK, pagesPATH.FAST_TRACK_PRODUCT],
        {
          queryParamsHandling: 'preserve',
        }
      );
    }
  }
}
