import { ConveyorDirection } from '../../enums/sim-config-conveyor-dir';
import { MathUtils } from 'three';
import * as THREE from 'three';
import { IHwSwType } from '../../types/robot-config/hw-sw-type';
import { Field } from '../../classes/field';
import { floatWithinTolerance } from 'src/app/utils/div';
import { SimConfigFieldIds } from '../../enums/simconfig-field-ids';

export class FieldUpdateFn {
  static changeDualProductMode(
    value: any,
    valueToGet: string,
    fields: Field[]
  ): any {
    // Dictates the extra space between the two conveyors in dual product mode,
    // if side offset is 0.
    const defaultConveyorSpacing = 500; // Millimeters

    const typeField = fields.find(
      (f) =>
        f.id === SimConfigFieldIds.ConveyorPrimaryType ||
        f.id === SimConfigFieldIds.ConveyorSecondaryType
    );
    const sideOffsetField = fields.find(
      (f) =>
        f.id === SimConfigFieldIds.ConveyorPrimaryPositionX ||
        f.id === SimConfigFieldIds.ConveyorSecondaryPositionX
    );
    if (
      valueToGet.includes('offsetX') &&
      typeField &&
      sideOffsetField &&
      floatWithinTolerance(sideOffsetField.formControl.value, 0, 0.001)
    ) {
      const type = FieldUpdateFn.conveyorTypesDefaults(
        typeField.formControl.value.name
      );
      return (
        ((type.width + defaultConveyorSpacing) / 2) *
        (valueToGet.includes('primary') ? 1 : -1)
      );
    }

    return floatWithinTolerance(value, 0, 0.001)
      ? 0
      : sideOffsetField.formControl.value;
  }

  static bracket_type(
    value: string,
    valueToGet: 'add' | 'length' | 'width' | 'height' | 'offX' | 'offY' | 'offZ'
  ): number | boolean {
    const enabled = value !== 'NONE';

    // Choose correct length
    switch (value) {
      case '5 cm':
        length = 50;
        break;
      case '10 cm':
        length = 100;
        break;
      case '15 cm':
        length = 150;
        break;
      // Handles 'NONE' and illigal values!
      default:
        length = 0;
        break;
    }

    switch (valueToGet) {
      case 'add':
        return enabled;
      case 'width':
        return enabled ? 63 : 0;
      case 'length':
        return enabled ? length : 0;
      case 'height':
        return enabled ? 10 : 0;
      case 'offX':
        return 0;
      case 'offY':
        return enabled ? length : 0;
      case 'offZ':
        return enabled ? 7 : 0;
      default:
        return 0;
    }
  }

  static frame(
    type: IHwSwType,
    valueToGet: 'right_posX' | 'left_posX' | 'posY' | 'posZ'
  ): number {
    let res: number;

    switch (valueToGet) {
      case 'right_posX':
      case 'left_posX':
        res =
          (type.metadata?.pallet_offset_x
            ? type.metadata.pallet_offset_x
            : 330) * (valueToGet.includes('left') ? -1 : 1);
        break;
      case 'posY':
        /** Note! This is in relation to -600, which is the actual "0" pallet offset value for y. */
        res = type.name === 'MANTIS' ? -270 : 0;
        break;
      case 'posZ':
        res = type.metadata.pallet_offset_z ?? 0;
        break;
    }
    return res;
  }
  static conveyorTypesDefaults(type: string): {
    height: number;
    width: number;
    length: number;
  } {
    // Field values in millimeter!
    const typesMap = {
      CUSTOM: {
        height: 600,
        width: 600,
        length: 2,
      },
      MINIPAL: {
        height: 820,
        width: 450,
        length: 1200,
      },
      KAMELEON: {
        height: 700,
        width: 387,
        length: 2500,
      },
      STANDARD: {
        height: 740,
        width: 387,
        length: 2330,
      },
      VENTION: {
        height: 830,
        width: 600,
        length: 1600,
      },
      HANNAFIN: {
        length: 1800,
        width: 360,
        height: 800,
      },
      BACHMANN: {
        length: 1200,
        width: 400,
        height: 750,
      },
    };

    if (!typesMap[type]) {
      console.warn('No config found for', type);
    }
    return typesMap[type] ? typesMap[type] : typesMap['CUSTOM'];
  }

  static changeConveyorType(
    type: any,
    valueToGet: 'dimX' | 'dimY' | 'dimZ' | 'posY'
  ): number {
    const conveyor = FieldUpdateFn.conveyorTypesDefaults(type.name);

    switch (valueToGet) {
      case 'dimZ':
        return conveyor.height;
      case 'dimX':
        return conveyor.width;
      case 'dimY':
      case 'posY':
        return conveyor.length;
      default:
        throw new Error('changeConveyorType: Invalid fieldId: ' + valueToGet);
    }
  }

  static changeConveyorHeight(value: number) {
    return value;
  }

  /**
   * @param direction Conveyor direction
   * @returns rotation value in radians
   */
  static changeConveyorDirection(direction: any, axis: string): number {
    const euler = new THREE.Euler(0, 0, MathUtils.degToRad(direction.value));
    return new THREE.Quaternion().setFromEuler(euler)[axis];
  }

  /*static conveyor_direction_to_smart_exit(dir: ConveyorDirection, fieldId: string): number {
    let range = 0;
    const fieldConf = smart_exit_fields.filter(f => f.id === fieldId)[0];

    if (dir === ConveyorDirection.FRONT) {
      range = fieldConf.Front;
    }
    if (dir === ConveyorDirection.LEFT) {
      range = fieldConf.Left;
    }
    if (dir === ConveyorDirection.RIGHT) {
      range = fieldConf.Right;
    }
    return range;
  }*/

  static conveyor_direction_to_boost_percantage(
    dir: ConveyorDirection
  ): number {
    let boost = 0;
    if (dir === ConveyorDirection.FRONT) {
      boost = 50;
    }
    if (dir === ConveyorDirection.LEFT) {
      boost = 90;
    }
    if (dir === ConveyorDirection.RIGHT) {
      boost = 90;
    }
    return boost;
  }

  /**
   * Hard coded from string to radian
   * @param dir as string
   */
  /*static conveyor_direction_changed(dir: ConveyorDirection, fieldId: string = 'x'): number {

    if (fieldId === 'high_approach_boost_percentage' || smart_exit_fields.map(m => m.id).includes(fieldId)) {

      if (fieldId === 'high_approach_boost_percentage') {
        return FieldUpdateFn.conveyor_direction_to_boost_percantage(dir);
      } else {
        return FieldUpdateFn.conveyor_direction_to_smart_exit(dir, fieldId);
      }

    } else {
      let rad;
      if (dir === ConveyorDirection.FRONT) {
        rad = 0;
      }
      if (dir === ConveyorDirection.LEFT) {
        rad = MathUtils.degToRad(-90);
      }
      if (dir === ConveyorDirection.RIGHT) {
        rad = MathUtils.degToRad(90);
      }

      const toQuaternion = FieldUpdateFn.euler_to_quaternion(rad);
      const value = toQuaternion[fieldId];

      return value % 1 !== 0 ? value.toFixed(6) : value;
      }
  }*/

  static euler_to_quaternion(
    yaw: number,
    pitch: number = 0,
    roll: number = 0
  ): { x: number; y: number; z: number; w: number } {
    const np = Math;
    const qx =
      np.sin(roll / 2) * np.cos(pitch / 2) * np.cos(yaw / 2) -
      np.cos(roll / 2) * np.sin(pitch / 2) * np.sin(yaw / 2);
    const qy =
      np.cos(roll / 2) * np.sin(pitch / 2) * np.cos(yaw / 2) +
      np.sin(roll / 2) * np.cos(pitch / 2) * np.sin(yaw / 2);
    const qz =
      np.cos(roll / 2) * np.cos(pitch / 2) * np.sin(yaw / 2) -
      np.sin(roll / 2) * np.sin(pitch / 2) * np.cos(yaw / 2);
    const qw =
      np.cos(roll / 2) * np.cos(pitch / 2) * np.cos(yaw / 2) +
      np.sin(roll / 2) * np.sin(pitch / 2) * np.sin(yaw / 2);

    return { x: qx, y: qy, z: qz, w: qw };
  }

  /**
   * @param valueToGet which field to change
   */
  static bracket_length_changed(
    bracketLength: number,
    valueToGet: string
  ): number {
    let value;
    if (valueToGet === 'offsetY' || valueToGet === 'posY') {
      value = -bracketLength;
    }
    return value;
  }

  /**
   * @param valueToGet which field to change
   */
  static bracket_height_changed(
    bracketHeight: number,
    valueToGet: string
  ): number {
    let value;
    if (valueToGet === 'posZ') {
      value = bracketHeight / 2;
    } else if (valueToGet === 'offsetZ') {
      value = bracketHeight;
    }
    return value;
  }

  /**
   * @param valueToGet which field to change
   */
  static gripper_length_changed(
    gripperLength: number,
    valueToGet: string
  ): number {
    let value;
    if (valueToGet === 'posY') {
      value = gripperLength / 2;
    }
    return value;
  }

  /**
   * @param valueToGet which field to change
   */
  static gripper_height_changed(
    gripperHeight: number,
    valueToGet: string
  ): number {
    let value;
    if (valueToGet === 'posZ') {
      value = gripperHeight / 2;
    }
    return value;
  }
}
