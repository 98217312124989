export enum PartType {
  NONE = 'none',
  PALLET = 'pallet',
  PALLET_LIP = 'pallet_lip',
  CONVEYOR = 'conveyor', // Primary conveyor
  CONVEYOR_GUIDE = 'conveyor_guide', // Primary conveyor guide
  SECONDARY_CONVEYOR = 'secondary_conveyor',
  SECONDARY_CONVEYOR_GUIDE = 'secondary_conveyor_guide',
  FRAME = 'frame',
  LIFTKIT = 'liftkit',
  BASE_BRACKET = 'base_bracket',
  ROBOT = 'robot',
  OFFSET_BRACKET = 'offset_bracket',
  GRIPPER = 'gripper',
  SCENE = 'scene',
}
