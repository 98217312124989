import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { DataService } from './data.service';
import {
  IPallyBox,
  IPallyFileType,
  IPallyLayer,
} from '../models_new/types/pally-file-type';
import { Project } from '../models_new/classes/project';
import { AltLayout } from '../models_new/enums/alt-layout';
import { Pallet } from '../models_new/classes/pallet';
import { PalletPosition } from '../models_new/enums/pallet-position';
import { Layer } from '../models_new/classes/layer';
import { LayerType } from '../models_new/enums/layer-type';
import { SubnavViewService } from './subnav-view.service';
import { Box } from '../models_new/classes/box';
import { StackingMethod } from '../models_new/enums/stacking-method';
import { IExportCombination } from '../models_new/types/export-combination';
import { PallyFileFormatter } from '../utils/pally-file-formatter';
import { exportCombinations } from '../models_new/config/export';
import { settings } from '../models_new/config/application-settings';
import { ObjectUtils } from '../utils/object';
import { environment } from 'src/environments/environment';
import { LayerApproach } from '../models_new/enums/layer-approach';
import { ISimulationApiFileType } from '../models_new/types/simulation-api-file-type';
import { Observable } from 'rxjs/internal/Observable';
import { ApiRobotConfiguration } from '../models_new/classes/api-models/ApiRobotConfiguration';
import { NotificationService } from './notification.service';
import { RobotConfigApiService } from './api/robot-config-api.service';
import { EMPTY } from 'rxjs';
import { ILabelDirection } from '../models_new/types/labelDirection';
import { UpdateAction } from '../models_new/enums/update-action';

@Injectable({
  providedIn: 'root',
})
export class ExportImportService {
  primaryPalletPosition = settings.primaryPallet;
  secondaryPalletPosition = settings.secondaryPallet;
  exportCombinations: IExportCombination[] = exportCombinations;

  constructor(
    private dataService: DataService,
    private subNavView: SubnavViewService,
    private robotConfigService: RobotConfigApiService,
    private notificationService: NotificationService
  ) {}

  downloadPdf(dataUrl: string, fileName: string) {
    fetch(dataUrl)
      .then((response) => response.blob())
      .then((blob) => {
        saveAs(blob, `${fileName}.pdf`);
      })
      .catch((e) => console.error(e));
  }

  importSimulationFile(importedJSON: ISimulationApiFileType) {
    const project: Project = this.mapToProjectFormat(importedJSON.pattern);
    this.dataService.setProject(project);
  }

  exportSimulationFile(data: ISimulationApiFileType) {
    const project: Project = this.dataService.getProject();
    const final: IPallyFileType = this.mapToExportFormat(project);

    data.pattern = final;
    const projectName = final.name || 'no_name';

    const blob = new Blob([JSON.stringify(data, null, 4)], {
      type: 'text/json;charset=utf-8',
    });

    const name = `${projectName}_simulation.json`;

    saveAs(blob, name);
  }

  getSimulationFile(data: ISimulationApiFileType, convert: boolean) {
    const project: Project = this.dataService.getProject();
    const final: IPallyFileType = this.mapToExportFormat(project);

    data.pattern = final;

    // Add "data" object to match hasura payload
    const pData = {
      data: ObjectUtils.cloneObject(data.pattern),
    };
    const sceneData = {
      data: ObjectUtils.cloneObject(data.scene),
    };
    const stratData = {
      data: ObjectUtils.cloneObject(data.strategy),
    };

    delete data.pattern;
    delete data.scene;
    delete data.strategy;

    if (convert) {
      // Convert conveyor height, conveyor distance and stroke to mm
      sceneData.data.robot.lift_kit.max_stroke =
        sceneData.data.robot.lift_kit.max_stroke * 1000;

      for (const conveyors of sceneData.data.conveyors) {
        conveyors.custom_description.dimension.z =
          conveyors.custom_description.dimension.z * 1000;

        conveyors.box_corner_pose.position.y =
          conveyors.box_corner_pose.position.y * 1000;
      }
    }

    (data as any).project = pData;
    (data as any).scene = sceneData;
    (data as any).strategy = stratData;

    return data;
  }

  /**
   * @param project
   * // Project => IPallyFileType
   */
  mapToExportFormat(
    project: Project,
    updateDateModified: boolean = true
  ): IPallyFileType {
    const layers = this.mapToExportLayerTypes(project);
    const pallyFile: IPallyFileType = {
      id: project.data.id,
      name: project.data.name,
      description: project.data.description,
      dimensions: {
        height: project.data.pallet.dimensions.loadHeight,
        width: project.data.pallet.dimensions.width,
        length: project.data.pallet.dimensions.length,
        palletHeight: project.data.pallet.dimensions.palletHeight,
      },
      productDimensions: {
        weight: project.data.box.weight,
        height: project.data.box.dimensions.height,
        width: project.data.box.dimensions.width,
        length: project.data.box.dimensions.length,
      },
      maxGrip: project.data.box.maxGrip,
      maxGripAuto: project.data.box.maxGrip > 6, // maxGripAuto is true if maxGrip is above 6
      labelOrientation: project.data.box.label.orientation,
      labelDirection: project.data.box.label.direction,
      guiSettings: {
        PPB_VERSION_NO: environment.version,
        boxPadding: project.data.box.padding,
        units: project.data.unit,
        overhangSides: project.data.pallet.overhang.sides,
        overhangEnds: project.data.pallet.overhang.ends,
        altLayout: project.palletAdvancedSettings.altLayout,
        stackingMethod: project.palletAdvancedSettings.stackingMethod,
        baseIndex: project.getActivePallet().autoStack.baseIndex,
        shimPaperDefaultHeight: project.data.shimPaper.height,
        boxIndexOverride: project.data.box.indexOverride,
      },
      dateModified:
        updateDateModified || !project.data.dateModified
          ? new Date().toISOString()
          : project.data.dateModified,
      layerTypes: layers.layerTypes,
      layers: layers.layers,
      cpm: project.data.cpm,
    };

    if (project.zones) {
      pallyFile.zones = project.zones;
    }
    if (project.altZones) {
      pallyFile.altZones = project.altZones;
    }

    return pallyFile;
  }

  /**
   * @param project: Project
   * @returns IPallyLayer[] unique layers, with altPattern(left pallet layer) if existing
   */
  private mapToExportLayerTypes(project: Project): {
    layerTypes: IPallyLayer[];
    layers: string[];
  } {
    const layersConcat: IPallyLayer[] = [];
    const rightPalletLayers: IPallyLayer[] = [
      ...project.pallets.find((f) => f.position === PalletPosition.RIGHT)
        .layers,
    ]
      .reverse()
      .map((l: Layer) => ({
        height: l.height,
        ...PallyFileFormatter.toPallyLayer(l),
      }));
    const leftPalletLayers: IPallyLayer[] = [
      ...project.pallets.find((f) => f.position === PalletPosition.LEFT).layers,
    ]
      .reverse()
      .map((l: Layer) => ({
        height: l.height,
        ...PallyFileFormatter.toPallyLayer(l),
      }));
    let maxLoopLength = Math.max(
      rightPalletLayers.length,
      leftPalletLayers.length
    );

    // Loop as many times as maxLoopLength. This value can increase if there is any desync on shimpaper layers.
    for (let i = 0, j = 0; i < maxLoopLength; i++, j++) {
      const rightLayer = rightPalletLayers[i];
      const leftLayer = leftPalletLayers[j];
      if (!rightLayer && !leftLayer) {
        continue;
      } else if (
        // This checks for desync on shimpaper layers. If not dealed with, will cause legit layers on left pallet to not be persisted.
        rightLayer?.class === LayerType.SHIMPAPER &&
        rightLayer?.class !== leftLayer?.class
      ) {
        // Next loop will go through this left-layer again, so we won't loose it.
        j--;
        // Increment the maxLoopLength to compensate the extra iteration.
        maxLoopLength++;
      }

      const newLayer: IPallyLayer = {
        name: null,
        class: null,
        pattern: [],
        altPattern: [],
        height: rightLayer?.height || leftLayer?.height || 0,
        approach: LayerApproach.NULL,
        altApproach: LayerApproach.NULL,
        altName: null,
      };

      const setShimpaper = (layer: IPallyLayer): void => {
        delete layer.pattern;
        delete layer.altPattern;
        delete layer.approach;
        delete layer.altApproach;
      };

      if (rightLayer && leftLayer) {
        newLayer.name = rightLayer.name;
        newLayer.altName = leftLayer.name;

        newLayer.class = rightLayer.class;

        if (newLayer.class === LayerType.LAYER) {
          newLayer.pattern = rightLayer.pattern;
          newLayer.altPattern = leftLayer.pattern;
          newLayer.approach = rightLayer.approach;
          newLayer.altApproach = leftLayer.approach;
        } else {
          setShimpaper(newLayer);
        }
      }

      if (rightLayer && !leftLayer) {
        newLayer.name = rightLayer.name;
        newLayer.class = rightLayer.class;

        if (rightLayer.class === LayerType.LAYER) {
          newLayer.pattern = rightLayer.pattern;
          newLayer.approach = rightLayer.approach;
        } else {
          setShimpaper(newLayer);
        }
      }

      if (!rightLayer && leftLayer) {
        newLayer.altName = leftLayer.name;
        newLayer.name = leftLayer.name + ' - left only'; // Has to be set
        newLayer.class = leftLayer.class;
        if (leftLayer.class === LayerType.LAYER) {
          newLayer.pattern = [];
          newLayer.approach = LayerApproach.NULL;
          newLayer.altPattern = leftLayer.pattern;
          newLayer.altApproach = leftLayer.approach;
        } else {
          setShimpaper(newLayer);
        }
      }
      layersConcat.push(newLayer);
    }

    const uniqueNames = new Set();
    const uniqueData = layersConcat.filter(
      (item) => !uniqueNames.has(item.name) && uniqueNames.add(item.name)
    );

    return {
      layerTypes: uniqueData,
      layers: layersConcat.map((l) => l.name),
    };
  }

  /**
   * @param json
   * // IPallyFileType => Project
   */
  mapToProjectFormat(
    json: IPallyFileType,
    preserveLeftPalletLayers: boolean = true,
    updateDataService: boolean = true
  ): Project {
    // Init project
    const project = new Project('import');

    // Default to customized stacking method if not present on pattern
    this.subNavView.selectedStackingMethod$.next(
      json.guiSettings && json.guiSettings.stackingMethod
        ? json.guiSettings.stackingMethod
        : StackingMethod.CUSTOMIZED
    );

    // Set dirty flag
    if (updateDataService) {
      this.dataService.setImportDirty(false);
    }

    project.pallets.forEach((pallet: Pallet) => {
      // Calculate pallet height
      pallet.setPalletLoadHeight();

      pallet.autoStack.baseIndex = json.guiSettings.baseIndex
        ? json.guiSettings.baseIndex
        : 0;

      // Set pallet.preservedLayers key
      if (pallet.position === PalletPosition.LEFT) {
        // Left pallet
        if (preserveLeftPalletLayers) {
          pallet.preserveLayers = true;
          pallet.autoStack.method = StackingMethod.CUSTOMIZED;
        } else {
          pallet.preserveLayers = false;
          pallet.autoStack.method = settings.defaultStackingMethod;
        }
      } else {
        // Right pallet
        pallet.preserveLayers = true;
        pallet.autoStack.method = StackingMethod.CUSTOMIZED;
      }
    });

    // Map project data
    this.mapToProjectData(project, json);

    // Map layers
    this.mapToProjectLayers(project, json);

    // Set view all layers
    project.setViewAllLayers();

    if (json.zones) {
      project.zones = json.zones;
    }
    if (json.altZones) {
      project.altZones = json.altZones;
    }

    if (updateDataService) {
      this.dataService.setProject(project);
    }

    return project;
  }

  /**
   * @param project: Project
   * @param json: IPallyFileType
   */
  private mapToProjectData(project: Project, json: IPallyFileType): void {
    project.data.id = json.id ? json.id : null;
    project.data.name = json.name;
    project.data.description = json.description;
    project.data.dateModified = !json.dateModified
      ? new Date().toISOString()
      : json.dateModified;
    project.data.unit = json.guiSettings.units;

    project.data.cpm = json.cpm;

    if (
      json.dimensions.palletHeight === null ||
      json.dimensions.palletHeight === undefined
    ) {
      json.dimensions.palletHeight = 145;
    }

    project.data.pallet.dimensions = {
      loadHeight: json.dimensions.height,
      palletHeight: json.dimensions.palletHeight,
      totalHeight: json.dimensions.height + json.dimensions.palletHeight,
      length: json.dimensions.length,
      width: json.dimensions.width,
    };
    project.data.pallet.overhang = {
      enabled: false,
      sides: json.guiSettings.overhangSides,
      ends: json.guiSettings.overhangEnds,
    };

    project.data.box.maxGrip = json.maxGrip;
    project.data.shimPaper.height = json.guiSettings.shimPaperDefaultHeight
      ? json.guiSettings.shimPaperDefaultHeight
      : 3;

    project.data.box.label = {
      enabled: json.labelOrientation !== null,
      orientation: json.labelOrientation,
      direction: json.labelDirection
        ? json.labelDirection
        : (settings.defaultLabelDirection as ILabelDirection),
    };

    project.data.box.dimensions = {
      length: json.productDimensions.length,
      width: json.productDimensions.width,
      height: json.productDimensions.height,
    };
    project.data.box.weight = json.productDimensions.weight;
    project.data.box.padding = json.guiSettings.boxPadding;
    project.data.box.indexOverride = json.guiSettings.boxIndexOverride;

    project.palletAdvancedSettings.altLayout = json.guiSettings
      .altLayout as AltLayout;
    project.palletAdvancedSettings.stackingMethod = json.guiSettings
      .stackingMethod as StackingMethod;

    // Enable overhang if present
    if (json.guiSettings.overhangSides || json.guiSettings.overhangEnds) {
      project.data.pallet.overhang.enabled = true;
    }

    // Enable label orientation if present
    if (json.labelOrientation !== null && json.labelOrientation !== undefined) {
      project.data.box.label.enabled = true;

      // Update 3dview label orientations
      project.getPalletView().palletViewSettings.labelOrientation =
        project.data.box.label.orientation;
    }

    // Enable shimpaper if present in some layer
    const shimpaperLayers = json.layerTypes.filter(
      (l: IPallyLayer) => l.class === LayerType.SHIMPAPER
    );

    if (shimpaperLayers.length) {
      project.data.shimPaper.enabled = true;
    }
  }

  private mapPattern(patterns: IPallyBox[], project: Project): Box[] {
    const newBoxes = [];

    patterns.forEach((pattern: IPallyBox) => {
      const box: Box = new Box();
      box.boxPadding = project.data.box.padding;
      box.dimensions = project.data.box.dimensions;
      box.weight = project.data.box.weight;
      box.labelOrientations = [project.data.box.label.orientation];
      box.rotation = pattern.r;
      box.position = {
        x: pattern.x,
        y: pattern.y,
        z: 0,
      };
      box.gripper.enforcedOrientation =
        pattern.g && pattern.g.length
          ? PallyFileFormatter.toProjectEnforcedOrientation(pattern.g)
          : [];
      box.gripper.stopMultigrip = pattern.f === 0;

      newBoxes.push(box);
    });

    return newBoxes;
  }

  /**
   * @param project: Project
   * @param json: IPallyFileType
   */
  private mapToProjectLayers(project: Project, json: IPallyFileType): void {
    if (
      !json.layerTypes ||
      !json.layerTypes.length ||
      !json.layers ||
      !json.layers.length
    ) {
      return;
    }

    // Function for creating layers
    const makeLayer = (
      layer: IPallyLayer,
      palletPosition: PalletPosition
    ): Layer => {
      const newLayer = new Layer({
        palletPosition: palletPosition,
        type: PallyFileFormatter.toProjectType(layer.class),
        name: palletPosition === 0 ? layer.altName : layer.name,
        typeId: Number(layer.name.replace(/\D+/g, '')).toString(),
        height: layer.height,
      });

      newLayer.preserve();

      if (newLayer.type === LayerType.SHIMPAPER) {
        return newLayer;
      }

      const boxes = this.mapPattern(
        palletPosition === 0 ? layer.altPattern : layer.pattern,
        project
      );
      newLayer.setBoxes(boxes);
      newLayer.approach =
        palletPosition === PalletPosition.RIGHT
          ? layer.approach
          : layer.altApproach;
      return newLayer;
    };

    const leftPalletLayers: Layer[] = [];
    const rightPalletLayers: Layer[] = [];

    json.layerTypes.forEach((Jsonlayer: IPallyLayer) => {
      // Has right pallet layer
      if (Jsonlayer.pattern && Jsonlayer.pattern.length) {
        const layer = makeLayer(
          ObjectUtils.cloneObject(Jsonlayer),
          settings.primaryPallet
        );

        rightPalletLayers.push(layer);
      }

      // Has left pallet layer
      if (Jsonlayer.altPattern && Jsonlayer.altPattern.length) {
        const layer = makeLayer(
          ObjectUtils.cloneObject(Jsonlayer),
          settings.secondaryPallet
        );

        leftPalletLayers.push(layer);
      }

      // Most likely a shimpaper layer
      if (
        !Jsonlayer.altPattern &&
        !Jsonlayer.pattern &&
        Jsonlayer.class === LayerType.SHIMPAPER
      ) {
        const layerR = makeLayer(
          ObjectUtils.cloneObject(Jsonlayer),
          settings.primaryPallet
        );

        const layerL = makeLayer(
          ObjectUtils.cloneObject(Jsonlayer),
          settings.secondaryPallet
        );

        rightPalletLayers.push(layerR);
        leftPalletLayers.push(layerL);
      }
    });

    // Add layers to pallets
    json.layers.forEach((layerNameFromLayers: string) => {
      const rightLayer = ObjectUtils.cloneObject(
        rightPalletLayers.find((f) => {
          if (layerNameFromLayers.toLowerCase().includes('shim')) {
            return (
              f.type === LayerType.SHIMPAPER &&
              f.getTypeId('layer-no') ===
                Number(layerNameFromLayers.replace(/\D+/g, ''))
            );
          } else {
            return (
              f.type === LayerType.LAYER &&
              f.getTypeId('layer-no') ===
                Number(layerNameFromLayers.replace(/\D+/g, ''))
            );
          }
        })
      );
      const leftLayer = ObjectUtils.cloneObject(
        leftPalletLayers.find((f) => {
          if (layerNameFromLayers.toLowerCase().includes('shim')) {
            return (
              f.type === LayerType.SHIMPAPER &&
              f.getTypeId('layer-no') ===
                Number(layerNameFromLayers.replace(/\D+/g, ''))
            );
          } else {
            return (
              f.type === LayerType.LAYER &&
              f.getTypeId('layer-no') ===
                Number(layerNameFromLayers.replace(/\D+/g, ''))
            );
          }
        })
      );

      if (!rightLayer && !leftLayer) {
        return;
      }

      if (rightLayer) {
        project
          .getPalletByPosition(PalletPosition.RIGHT)
          .addLayer(rightLayer, UpdateAction.ADD_NEW);
      }

      if (leftLayer) {
        project
          .getPalletByPosition(PalletPosition.LEFT)
          .addLayer(leftLayer, UpdateAction.ADD_NEW);
      }
    });
  }

  importRobotConfigurationFile(
    robotConfig: ApiRobotConfiguration,
    orgId: string
  ): Observable<any> {
    if (!robotConfig) {
      this.notificationService.showError(
        "Incompatible JSON formatting with 'IApiRobotConfiguration'"
      );
      return EMPTY;
    }
    return this.robotConfigService.createNewRobotConfig(
      robotConfig.name,
      orgId,
      robotConfig.scene,
      robotConfig.strategy
    );
  }
}
