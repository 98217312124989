import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { Observable, Subject, combineLatest, forkJoin } from 'rxjs';
import {
  filter,
  map,
  shareReplay,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { DataRequestState } from 'src/app/data-request/model';
import { toRequestState } from 'src/app/data-request/operators';
import {
  FilterTableData,
  ISortingOption,
} from '../../../models_new/types/sorting-option';
import { SortDirection } from '@angular/material/sort';
import { ObjectUtils } from 'src/app/utils/object';
import { IOrganizationContextResolverData } from 'src/app/resolvers/organization-context-resolver.resolver';
import { NotificationService } from 'src/app/services/notification.service';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { PallyPathStrategyApiService } from 'src/app/services/api/pally-path-strategy-api.service';
import {
  ApiPallyPathStrategy,
  IApiPallyPathStrategy,
} from 'src/app/models_new/classes/api-models/ApiPallyPathStrategy';
import {
  InventoryTableDisplayColumn,
  InvetoryTableAction,
} from '../../gui/inventory-table/inventory-table.component';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-pally-path-strategies-component',
  templateUrl: './pally-path-strategies.component.html',
  styleUrls: ['./pally-path-strategies.component.scss'],
})
export class PallyPathStrategiesComponent implements OnInit, OnDestroy {
  displayedColumns: InventoryTableDisplayColumn[] = [
    {
      label: 'Name',
      path: 'name',
      sortType: 'string',
    },
    {
      label: 'Box free height (Auto)',
      path: 'box_free_height_obj.formated',
      sortType: 'string',
    },
    {
      label: 'Approach (Auto)',
      path: 'approach_obj.formated',
      sortType: 'string',
    },
    {
      label: 'Gripper rotation',
      path: 'gripper_rotation',
      sortType: 'string',
    },
    {
      label: 'Max grip',
      path: 'max_grip',
      sortType: 'number',
    },
    {
      label: 'Updated at',
      path: 'updated_at',
      sortType: 'date',
    },
  ];
  rowActions: InvetoryTableAction[] = [
    {
      label: 'Duplicate',
      actionId: 'duplicate',
      roleAction: 'create_pally_path_strategy',
      divideAfter: true,
      icon: 'file_copy',
    },
    {
      label: 'Delete',
      actionId: 'delete',
      roleAction: 'delete_pally_path_strategy',
      color: 'warn',
      icon: 'delete',
    },
  ];

  sortingColumns: ISortingOption[] = [
    {
      id: 'name',
      label: 'Name',
    },
    {
      id: 'created_at',
      label: 'Created At',
    },
    {
      id: 'updated_at',
      label: 'Updated At',
    },
  ];

  destroy$: Subject<boolean> = new Subject<boolean>();
  strategies$: Observable<DataRequestState<ApiPallyPathStrategy[]>>;

  blockSelected: ApiPallyPathStrategy[] = [];
  tableFilter: FilterTableData = new FilterTableData();
  orderBy: { column: string; order: SortDirection };
  objUtil = ObjectUtils;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private notification: NotificationService,
    private pathStrategyApi: PallyPathStrategyApiService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.strategies$ = this.route.data.pipe(
      take(1),
      switchMap((data: Data) => {
        const orgId = (data as IOrganizationContextResolverData)
          .organization_id;
        return this.pathStrategyApi.fetchPallyPathStrategies(
          orgId,
          'inventory'
        );
      }),
      map((pathStrategies: ApiPallyPathStrategy[]) => {
        pathStrategies.map((p) => {
          p.updated_at =
            this.datePipe.transform(p.updated_at, 'dd/MM/yyyy HH:mm') || 'N/A';
          // switchcase on gripper_rotation
          switch (p.gripper_rotation) {
            case 0:
              p.gripper_rotation = '-';
              break;
            case 1:
              p.gripper_rotation = 'One way';
              break;
            case 2:
              p.gripper_rotation = 'Two way';
              break;
            case 3:
              p.gripper_rotation = 'Three way';
              break;
            case 4:
              p.gripper_rotation = 'Four way';
              break;
            default:
              p.gripper_rotation = '-';
              break;
          }
          return p;
        });

        return pathStrategies;
      }),
      toRequestState(),
      shareReplay({ bufferSize: 1, refCount: true }),
      takeUntil(this.destroy$)
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  rowClicked(selectedPathStrat: ApiPallyPathStrategy) {
    this.router.navigate([
      pagesPATH.PALLY_PATH_STRATEGIES,
      selectedPathStrat.id,
    ]);
  }

  actionClicked(e: { action: InvetoryTableAction; row: ApiPallyPathStrategy }) {
    switch (e.action.actionId) {
      case 'duplicate':
        this.duplicatePathStrategy(e.row);
        break;
      case 'delete':
        this.onDelete([e.row]);
        break;
      default:
        console.warn('Unknown action;', e.action);
    }
  }

  private duplicatePathStrategy(strategy: ApiPallyPathStrategy) {
    combineLatest([
      this.route.data,
      this.pathStrategyApi.fetchPathStrategyById(strategy.id),
    ])
      .pipe(
        take(1),
        switchMap(([routeData, strategy]) =>
          this.pathStrategyApi.insertPallyPathStrategy(
            (routeData as IOrganizationContextResolverData)?.organization_id,
            strategy.name + ' (copy)',
            strategy.data
          )
        )
      )
      .subscribe((result) => {
        if (result) {
          this.notification.showMessage(
            'The pally program setting was duplicated successfully!'
          );
        } else {
          this.notification.showError(
            'Unable to duplicate pally program setting! Do you have the correct user privilege in your organization?'
          );
        }
      });
  }

  onDelete(strategies: IApiPallyPathStrategy[]) {
    this.deletePallyPathStrategiesAction(strategies)
      .pipe(take(1))
      .subscribe((result) => {
        if (result[0]) {
          this.notification.showMessage(
            'The pally program setting was deleted successfully!'
          );
        } else {
          this.notification.showError(
            'Unable to delete pally program setting! Do you have the correct user privilege in your organization?'
          );
        }
      });
  }

  deletePallyPathStrategiesAction(strategies: IApiPallyPathStrategy[]) {
    return this.notification
      .deletePrompt(
        'Delete',
        'pally program settings',
        strategies.map((m) => m.name)
      )
      .afterDismissed()
      .pipe(
        take(1),
        filter(Boolean),
        switchMap(() => {
          const ids = strategies.map((wp) => wp.id);
          const operations = {};
          for (let i = 0; i < ids.length; i++) {
            operations[i] = this.pathStrategyApi.deletePallyPathStrategy(
              ids[i]
            );
          }
          return forkJoin(operations);
        })
      );
  }

  navigateToPallyPathStrategy(page: 'new' = 'new') {
    if (page == 'new') {
      this.router.navigate([pagesPATH.PALLY_PATH_STRATEGIES, 'new']);
    }
  }
}
