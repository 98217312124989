import { Injector } from '@angular/core';
import * as THREE from 'three';
import { ReplaySubject } from 'rxjs';
import { FieldUpdate } from 'src/app/models_new/types/field-update';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { track } from 'src/app/utils/resource-tracker';
import { ThreeUtils } from 'src/app/utils/three-utils';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from '../../../enums/simconfig-field-ids';
import { milliToMeter } from '../../../../utils/div';
import { DimensionVisualizer } from '../../3dview/timed-objects/dimension-visualizer';

export class BaseBracketTask extends Task {
  static [taskNameSymbol] = 'Basebracket';
  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$, false);
  }
  public operation(resolve: () => void, _reject: (reason?: any) => void): void {
    this.insertBaseBracket(this.data);

    // Value worth reporting? visualize it.
    if (this.data.newVal) {
      this.activateDimVis(
        SimConfigFieldIds.BasebracketHeight,
        {
          value: this.data.newVal / 1000,
        },
        this.readShowVisualizers() ? -1 : undefined
      );

      // Just update the visualizer, no need to visualize.
    } else {
      const dimvis = this.toService.getTimedObject(
        SimConfigFieldIds.BasebracketHeight
      ) as DimensionVisualizer;
      dimvis.setValue(this.data.newVal);
    }

    this.activateDimVis(
      'robot_mount_height_full',
      undefined,
      this.readShowVisualizers() ? -1 : undefined
    );

    resolve();
  }

  public insertBaseBracket(s: FieldUpdate): void {
    const liftkit_baseBracket = this.robot.getJointByID(
      JointNames.LiftkitBasebracket
    );
    const baseBracketLink = URDFUtils.findLinkFromJoint(liftkit_baseBracket);

    const visual = URDFUtils.findVisualFromJoint(liftkit_baseBracket);

    const bracketHeight = milliToMeter(s.newVal);

    const newModel = new THREE.Mesh(
      track(new THREE.CylinderGeometry(0.095, 0.095, 1, 30)),
      track(new THREE.MeshPhongMaterial({ color: '#A5A5A5' }))
    );
    newModel.setRotationFromEuler(new THREE.Euler((-90 * Math.PI) / 180, 0, 0));
    newModel.scale.set(1, bracketHeight, 1);
    ThreeUtils.disposeObject(visual.children);
    visual.children = [];
    visual.visible = bracketHeight !== 0;
    visual.add(newModel);

    baseBracketLink.position.z = bracketHeight;
    visual.position.set(0, 0, -(bracketHeight / 2));
  }
}
