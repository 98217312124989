import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import * as THREE from 'three';
import { Observable, throwError } from 'rxjs';
import {
  catchError,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
} from 'rxjs/operators';
import { LabelOrientation } from '../../../../../models_new/enums/label-orientation';
import { DimensionIndicator } from '../../../../../models_new/classes/3dview/dimension-indicator';
import { ThreeUtils } from '../../../../../utils/three-utils';
import { New_ThreeViewComponent } from '../../three-view/three-view.component';
import { NewAssetStoreService } from '../../../../../services/3dview/asset-store.service';
import { settings } from '../../../../../models_new/config/application-settings';
import { DynamicBox } from '../../../../../models_new/classes/3dview/dynamic-box';
import { Easing } from '@tweenjs/tween.js';
import { toRequestState } from '../../../../../data-request/operators';
import { RenderingService } from '../../../../../services/3dview/rendering.service';
import { AssetIDs } from 'src/app/models_new/enums/asset-ids';

@Component({
  selector: 'app-new-product-view',
  templateUrl: './product-view.component.html',
  styleUrls: [
    '../../three-view/three-view.component.scss',
    './product-view.component.scss',
  ],
})
export class NewProductViewComponent
  extends New_ThreeViewComponent
  implements OnInit, OnChanges, OnDestroy
{
  BOX_ANIMATION_DURATION = 250; // milliseconds
  CAMERA_ANIMATION_DURATION = 1500; // milliseconds
  boxAnimationMS = Infinity; // this keeps the animation from running too early
  cameraAnimationMS = Infinity; // This keeps the animation from running too early
  holdbackFirstCameraAnimation = true;

  @Input() length: number;
  @Input() width: number;
  @Input() height: number;
  @Input() productTypeId: string = AssetIDs.Box;

  @Input() showSide: 'front' | 'right' | 'back' | 'left' | 'default';
  @Input() hideNav = true;

  @Input() sticker: THREE.Texture;
  @Input() labelOrientations: LabelOrientation[];

  scene$: Observable<any>;
  box: DynamicBox;
  productModel: DynamicBox; // Todo: fix naming consistencies now that not all product models are boxes.
  conveyor: THREE.Object3D;

  boxSize = new THREE.Vector3();
  targetBoxSize = new THREE.Vector3();
  // NOTE! Couldn't find the reason as of why tween doesn't update, manually smoothing the scaling for now.
  //tweenGroup = new TweenGroup();
  //tween: Tween<THREE.Vector3>;

  xIndicator: DimensionIndicator;
  yIndicator: DimensionIndicator;
  zIndicator: DimensionIndicator;

  boxStartingPos = new THREE.Vector3(0, 0.175, 0.3);

  conveyorGroup = new THREE.Group();

  defaultCamPos = new THREE.Vector3(-0.6, 0.7, 0.8);
  targetCameraPos = new THREE.Vector3();

  listensForPointerDown = false;

  constructor(
    ngZone: NgZone,
    private assetStoreSerivce: NewAssetStoreService,
    renderingService: RenderingService,
    cdRef: ChangeDetectorRef
  ) {
    super(ngZone, renderingService, cdRef);

    this.renderingOptions = {
      outputColorSpace: THREE.sRGBEncoding,
      /*
      NOTE: Temporarily disabled due to rollback of THREE.js.
      outputColorSpace: THREE.SRGBColorSpace,
      */
      shadowMap: {
        enabled: true,
        type: THREE.VSMShadowMap,
      },
    };

    this.scene$ = this.rendererReady$.pipe(
      switchMap(() => {
        return this.assetStoreSerivce.load(
          'prodline-asset',
          settings.publicmodelsPallyDescriptionsURL +
            '04-10-22_prod-line_v13/production%20line_v13_TESTFIX.fbx',
          'fbx'
        ).data$;
      }),
      map((model: THREE.Object3D) => {
        model.scale.set(1, 1, 1);
        model.traverse((child: THREE.Object3D) => {
          if (child.name === 'box') {
            child.scale.set(0, 0, 0);
          }
          if (child.name === 'conveyor_frame') {
            this.conveyor = child;
          }
          if (child.name === 'Cylinder__bg') {
            ThreeUtils.enableObjectShadows(child, true);
          }
        });
        this.scene.add(model);
        ThreeUtils.enableObjectShadows(this.conveyor);
        this.loadProductModelById(this.productTypeId ?? AssetIDs.Box);
        return model;
      }),
      catchError((e) => {
        // TODO: Remove or keep?
        console.error(e);
        return throwError(
          () =>
            new Error(
              "Couldn't load asset. Please try to refresh. Contact MRC support if the error persists."
            )
        );
      }),
      // Mitigates re-adding of models to the scene on view update
      shareReplay({ bufferSize: 1, refCount: false })
    );

    this.loading$ = this.scene$.pipe(
      map(() => false),
      take(1),
      startWith(true),
      toRequestState(),
      takeUntil(this.destroy$)
    );

    // Add lighting
    this.addLights();

    this.xIndicator = new DimensionIndicator(
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, 0),
      0xff0000,
      5
    );
    this.yIndicator = new DimensionIndicator(
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, 0),
      0x000fe6,
      5
    );
    this.zIndicator = new DimensionIndicator(
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, 0),
      0x0fff33,
      5
    );

    this.scene.add(this.xIndicator);
    this.scene.add(this.yIndicator);
    this.scene.add(this.zIndicator);
  }

  override ngOnInit(): void {
    super.ngOnInit();

    // Set the box at the center right away to mitigate start glithing of camera.
    this.controls.target.copy(this.boxStartingPos);
    this.controls.enablePan = false;
    // Don't let the camera outside the background cylinder
    this.controls.maxDistance = 9;

    this.controls.update();

    this.enableRenderingLoop(true);
  }

  override ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    // Wait until we have what we need.
    this.scene$.pipe(take(1)).subscribe((_v) => {
      if (
        // A dimension changed and all are defined
        (changes.length || changes.width || changes.height) &&
        this.length &&
        this.width &&
        this.height
      ) {
        if (this.length < 1 || this.width < 1) {
          return;
        }

        this.boxSize = this.targetBoxSize;
        this.targetBoxSize = new THREE.Vector3(
          this.width,
          this.height,
          this.length
        );

        // Wait a bit to let the processing calm down,
        // so animations runs smoothly.
        // Not the best solution but works as a temporary fix for now.
        setTimeout(() => {
          this.holdbackFirstCameraAnimation = false;
          this.boxAnimationMS = 0;
        }, 300);
      }
      if (changes.productTypeId?.currentValue) {
        this.productTypeId = changes.productTypeId.currentValue;
        this.loadProductModelById(this.productTypeId);
      }
      if (changes.sticker?.currentValue) {
        this.sticker = changes.sticker.currentValue;
        this.box.setLabels(this.sticker);
      }
      if (changes.labelOrientations) {
        this.labelOrientations = changes.labelOrientations.currentValue;
        this.box.setLabelOrientations(this.labelOrientations);
        let side = 'default';
        if (this.labelOrientations[0] === LabelOrientation.BACK) {
          side = 'back';
        } else if (this.labelOrientations[0] === LabelOrientation.RIGHT) {
          side = 'right';
        }
        this.placeCamera(
          side as 'front' | 'right' | 'back' | 'left' | 'default'
        );
      }
      if (changes.showSide?.currentValue) {
        this.placeCamera(this.showSide);
      }
    });
  }

  ngOnDestroy(): void {
    this.scene.dispose();
    super.ngOnDestroy();
  }

  override update(dt?: number) {
    //this.tweenGroup.update(dt);
    //this.tween.update(dt);

    // NOTE! Couldn't find the reason as of why tween doesn't update, manually smoothing the scaling for now.
    // Smooth linear interpolation between current box size and targeted box size.
    const isBoxAnimationRunning =
      this.boxAnimationMS <= this.BOX_ANIMATION_DURATION;
    if (isBoxAnimationRunning) {
      const newSize = new THREE.Vector3().lerpVectors(
        this.boxSize,
        this.targetBoxSize,
        Easing.Quartic.Out(
          Math.min(this.boxAnimationMS / this.BOX_ANIMATION_DURATION, 1)
        )
      );

      this.boxSize.copy(newSize);
      this.updateObjects(newSize);
      this.boxAnimationMS += dt * 1000;

      // If side is selected, update the targeted camera position.
      // Fixes outzooming on first load of the product
      if (this.showSide !== undefined || this.showSide !== 'default') {
        this.placeCamera(this.showSide);
      }
    }

    // Smooth linear interpolation for camera
    // Update side camera placements
    const isCameraAnimationRunning =
      this.cameraAnimationMS <= this.CAMERA_ANIMATION_DURATION;
    if (
      isCameraAnimationRunning &&
      !isBoxAnimationRunning &&
      !this.holdbackFirstCameraAnimation
    ) {
      this.camera.position.copy(
        new THREE.Vector3().lerpVectors(
          this.camera.position,
          this.targetCameraPos,
          Math.min(this.cameraAnimationMS / this.CAMERA_ANIMATION_DURATION, 1)
        )
      );
      this.cameraAnimationMS += dt * 1000;
    }
    if (
      this.showSide !== undefined &&
      this.showSide !== 'default' &&
      this.box
    ) {
      this.controls.target.copy(this.box.position);
    }
    this.controls.update();
  }

  // Updates scene objects
  updateObjects(scale: THREE.Vector3, _dt?: number) {
    this.updateBox(scale);
    const size = this.box.size;
    this.updateConveyor(size);
    this.updateIndicators(this.box.min, this.box.max);
  }

  private loadProductModelById(id: string): void {
    this.scene.children.find((c) => {
      if (c.name === 'product-model') this.scene.remove(c);
    });
    this.assetStoreSerivce
      .load(id)
      .data$.pipe(map((model) => model.children[0]))
      .subscribe((mesh: THREE.Mesh) => {
        if (mesh) {
          mesh.scale.set(0, 0, 0);
          mesh.position.set(0, 0, 0);
          this.box = new DynamicBox(mesh);
          this.box.name = 'product-model';
          this.box.position.copy(mesh.position);
          ThreeUtils.enableObjectShadows(this.box);
          this.scene.add(this.box);
          this.box.setLabels(this.sticker);
          this.box.setLabelOrientations(this.labelOrientations);
          this.updateObjects(this.boxSize);
        }
      });
  }

  private updateBox(scale: THREE.Vector3): void {
    this.box.setSize({
      length: scale.z / 1000,
      width: scale.x / 1000,
      height: scale.y / 1000,
    });
    this.box.position.copy(this.boxStartingPos);
    const size = this.box.size;
    this.box.position.y += size.y / 2;
    this.box.position.z -= size.z / 2;
  }

  private updateConveyor(size: THREE.Vector3): void {
    // Default to max size if size is outside the box limits (50 - 1300 mm).
    let conveyorWidth = 1.3;
    if (size.x < 0.4) {
      conveyorWidth = 0.4;
    } else if (size.x > 0.4 && size.x <= 0.8) {
      conveyorWidth = 0.8;
    }

    this.conveyor.scale.x = conveyorWidth + 0.35;
  }

  private updateIndicators(min: THREE.Vector3, max: THREE.Vector3): void {
    this.xIndicator.setStart(new THREE.Vector3(min.x, min.y, max.z + 0.001));
    this.xIndicator.setEnd(new THREE.Vector3(max.x, min.y, max.z + 0.001));
    this.yIndicator.setStart(
      new THREE.Vector3(min.x - 0.001, min.y, max.z + 0.001)
    );
    this.yIndicator.setEnd(
      new THREE.Vector3(min.x - 0.001, max.y, max.z + 0.001)
    );
    this.zIndicator.setStart(new THREE.Vector3(min.x - 0.001, min.y, min.z));
    this.zIndicator.setEnd(new THREE.Vector3(min.x - 0.001, min.y, max.z));
  }

  placeCamera(side?: 'front' | 'right' | 'back' | 'left' | 'default'): void {
    // Just a value reset, ignore and maintain camera position.
    if (side === undefined) {
      this.showSide = side;
      return;
    }

    this.scene$.pipe(take(1)).subscribe(() => {
      // Default the camera and start camera animation
      if (side === 'default') {
        this.targetCameraPos.copy(this.defaultCamPos);
        this.cameraAnimationMS = 0;
        // Calculate new camera position based on side to show
      } else {
        const camPos = this.box.position.clone();
        const size = this.box.size;

        camPos.y += size.z / 2;

        if (side === 'front' || side === 'back') {
          camPos.x += size.x;
          camPos.z +=
            (size.z / 2 + Math.max(size.y * 2, size.x * 2)) *
            (side === 'front' ? 1 : -1);
        } else if (side === 'right' || side === 'left') {
          camPos.z += size.z;
          camPos.x +=
            (size.x / 2 + Math.max(size.y * 2, size.z * 2)) *
            (side === 'right' ? 1 : -1);
        }
        this.targetCameraPos.copy(camPos);
      }

      // If the side shown changes, start the camera animation.
      if (side !== this.showSide) {
        this.cameraAnimationMS = 0;
      }

      this.showSide = side;

      // Listen for wether the user moves the view.
      // If the user moves the view, reset the side shown
      // and enter "free mode".
      if (!this.listensForPointerDown) {
        this.listensForPointerDown = true;

        const resetShowSide = () => {
          this.placeCamera(undefined); // No camera updates.
          this.canvas.removeEventListener(
            'pointerdown',
            resetShowSide.bind(this)
          );
          this.listensForPointerDown = false;
        };
        // Reset side shown on input
        this.canvas.addEventListener('pointerdown', resetShowSide.bind(this));
      }
    });
  }

  private addLights(): void {
    const ambientIntensity = 0.2;
    const spotLightIntensity = 0.6;
    const pointLightIntensity = 0.4;

    const ambient = new THREE.AmbientLight('#ffffff', ambientIntensity);
    this.scene.add(ambient);

    const spotLight = new THREE.SpotLight('#ffffff');
    spotLight.position.set(0.5, 2, 0.5);
    spotLight.intensity = spotLightIntensity;
    spotLight.penumbra = 0.25;
    spotLight.angle = Math.PI / 4;
    spotLight.distance = 5;
    this.scene.add(spotLight);
    spotLight.target.position.set(0, 0, -0.5);
    this.scene.add(spotLight.target);
    this.scene.add(spotLight.shadow.camera);
    ThreeUtils.enablePerspectiveTypeShadows(spotLight, {
      camFar: 4,
      camNear: 0.1,
      camTarget: spotLight.target.position,
    });

    const pointLight = new THREE.PointLight('#ffffff');
    pointLight.position.set(0.5, 2, 0.5);
    pointLight.intensity = pointLightIntensity;
    pointLight.distance = 20;
    this.scene.add(pointLight);
  }
}
