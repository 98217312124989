import { ApiOrganization, IApiOrganization } from './ApiOrganization';
import { ApiInstalledRobot, IApiInstalledRobot } from './ApiRobotInstallation';

export interface ICalibrationFileInfo {
  timestamp: string;
  serial_no: string;
  os_version: string;
  pally_version: string;
  format: {
    weight: string;
    length: string;
    angle: string;
    pose: string;
  };
}
export interface ICalibrationRobotInfo {
  type: string;
  max_payload: number;
  joint_limits: {
    min: number[];
    max: number[];
  };
  gripper: {
    type: string;
    tcp: number[];
    dimension: {
      x: number;
      y: number;
      z: number;
    };
    weight: number;
    zones: {
      id: number;
      x: number;
      y: number;
      width: number;
      length: number;
      grip: {
        type: string;
        channel: number;
        inverse: boolean;
      };
      release: {
        type: string;
        channel: number;
      };
    }[];
    configurations: number[][];
  };
  lifting_column: {
    type: string;
    stroke: number;
  };
}
export interface ICalibrationData {
  calibration_box_dimensions: {
    height: number;
    width: number;
    length: number;
  };

  conveyors: {
    pickup_point: number[];
    type: string;
    conveyor_width: number;
    conveyor_guide_left: boolean;
    conveyor_guide_width: number;
    sensors: number;
    enabled: boolean;
  }[];
  pallets: {
    name: string;
    calibration_pallet_height: number;
    pallet_guide_pos: number;
    enabled: boolean;
    calibration_points: {
      c1: number[];
      c2: number[];
      c3: number[];
    };
  }[];
}

export interface ICalibrationFileData {
  file_info: ICalibrationFileInfo;
  robot: ICalibrationRobotInfo;
  calibration: ICalibrationData;
}

export interface IApiCalibration {
  id: string;
  name?: string; // Take from installed_robot.name
  active?: string;
  data: ICalibrationFileData;
  installed_robot: IApiInstalledRobot;
  organization: IApiOrganization;
  created_at?: string;
  updated_at: string;
}

export class ApiCalibration implements IApiCalibration {
  id: string;
  name?: string; // Take from installed_robot.name
  data: ICalibrationFileData;
  installed_robot: ApiInstalledRobot;
  organization: ApiOrganization;
  created_at?: string;
  updated_at: string;
  lifting_column_type: string;
  conveyor_type_1: string;
  conveyor_type_2: string;
  gripper_type: string;

  constructor(calibration: IApiCalibration) {
    for (const d in calibration) {
      if (calibration.hasOwnProperty(d)) {
        this[d] = calibration[d];
      }
    }
  }
}
