import { IPalletView } from 'src/app/models_new/types/pallet-view';
import { LabelOrientation } from 'src/app/models_new/enums/label-orientation';

export const defaultPalletView: IPalletView = {
  palletViewLayersNo: 0,
  palletViewSettings: {
    showLeftPallet: false,
    showSuggestedRobotMounting: false,
    showPalletFront: false,
    showLabelOrientation: false,
    labelOrientation: LabelOrientation.FRONT,
    showRobotModel: false,
    showConveyorFront: false,
    showConveyorPrimary: false,
    showConveyorSecondary: false,
    showFront: false,
    showBack: false,
    showRight: false,
    showLeft: false,
    showLayerColor: false,
  },
};
