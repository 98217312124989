import { ObjectUtils } from 'src/app/utils/object';
import { defaultApiPallyPathStrategy } from '../../config/default/api-default/default-api-pally-path-strategy';
import { IPallyPathStrategy } from '../../types/pally-path-strategy';

export interface IApiPallyPathStrategy {
  id: string;
  name?: string;
  description?: string;
  data?: IPallyPathStrategy;
  owner_id?: string;
  organization_id?: string;
  box_free_height?: number;
  box_free_auto?: boolean;
  approach?: number;
  approach_auto?: boolean;
  gripper_rotation?: number | string;
  max_grip?: number;
  created_at?: string;
  updated_at?: string;
}

export class ApiPallyPathStrategy implements IApiPallyPathStrategy {
  id: string;
  name: string;
  description: string;
  data?: IPallyPathStrategy;
  owner_id: string;
  organization_id: string;
  box_free_height_obj?: {
    formated: string;
    box_free_height: number;
    box_free_auto: boolean;
  };
  approach_obj?: {
    formated: string;
    approach: number;
    approach_auto: boolean;
  };
  gripper_rotation?: number | string;
  max_grip?: number;
  created_at?: string;
  updated_at?: string;

  constructor(data: IApiPallyPathStrategy) {
    if (data.box_free_height) {
      this.box_free_height_obj = {
        formated: `${data.box_free_height} (${data.box_free_auto})`,
        box_free_height: data.box_free_height,
        box_free_auto: data.box_free_auto,
      };
      delete data.box_free_height;
      delete data.box_free_auto;

      this.approach_obj = {
        formated: `${data.approach} (${data.approach_auto})`,
        approach: data.approach,
        approach_auto: data.approach_auto,
      };
      delete data.approach;
      delete data.approach_auto;
    }

    for (const d in data) {
      if (data.hasOwnProperty(d)) {
        this[d] = data[d];
      }
    }
  }
}

export function createDefaultApiPallyPathStrategy(): ApiPallyPathStrategy {
  return new ApiPallyPathStrategy(
    ObjectUtils.cloneObject(defaultApiPallyPathStrategy)
  );
}
