<div
  *appVar="appLayout.lowHeight$ | async as lowHeigth"
  class="landing-step-item-wrapper justify-content"
  [ngClass]="{ 'low-height': lowHeigth, 'slide-out': slideOut }"
>
  <ng-container *ngIf="dataReady$ | async as dataReady">
    <!-- Loading state -->
    <app-loading *ngIf="dataReady.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="dataReady.error as error" [error]="error"></app-error>

    <!-- Success state -->
    <ng-container *ngIf="dataReady.didSucceed && dataReady.value">
      <div class="page-header">
        <h1
          *appVar="currentPageContent$ | async as page"
          class="page-index-title"
          [ngClass]="{ 'low-height': lowHeigth }"
        >
          {{ page.content.cardTitle }}
        </h1>
      </div>
      <div
        class="landing-user-info-cards-wrapper"
        [ngClass]="{ 'low-height': lowHeigth }"
      >
        <mat-card
          appearance="outlined"
          class="landing-user-info-cards-left-card"
          [ngStyle]="
            lowHeigth ? { height: 'auto', 'justify-content': 'start' } : {}
          "
        >
          <mat-card-content>
            <app-field
              *ngFor="let field of dataReady.value"
              [field]="field"
            ></app-field>
          </mat-card-content>
        </mat-card>

        <ng-container *ngIf="solutions$ | async as solutions">
          <div class="landing-user-info-cards-right-card">
            <mat-card appearance="outlined">
              <!-- Error state -->
              <app-error
                *ngIf="solutions.error as error"
                [error]="error"
                [hideActions]="true"
              ></app-error>
              <ng-container *ngIf="solutions.didSucceed && solutions.value">
                <mat-card-content>
                  <div class="table-container">
                    <table
                      mat-table
                      [dataSource]="tableDataSource"
                      matSort
                      matSortActive="name"
                      matSortDirection="desc"
                    >
                      <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef>Solution name</th>
                        <td mat-cell *matCellDef="let element">
                          <ng-container
                            *ngIf="element.externalUrl; else nameOnly"
                          >
                            <div
                              class="link-like-container"
                              (click)="navigateTo(element.externalUrl)"
                              [matTooltip]="element.externalUrl"
                            >
                              {{ element.name }} &nbsp;
                              <mat-icon> open_in_new</mat-icon>
                            </div>
                          </ng-container>
                          <ng-template #nameOnly>
                            {{ element.name }}
                          </ng-template>
                        </td>
                      </ng-container>
                      <ng-container matColumnDef="starting_price">
                        <th mat-header-cell *matHeaderCellDef>
                          Starting price
                        </th>
                        <td
                          mat-cell
                          *matCellDef="let element"
                          style="text-align: left"
                        >
                          {{ element.startingPrice }}
                        </td>
                      </ng-container>
                      <tr
                        mat-header-row
                        *matHeaderRowDef="displayedColumns; sticky: true"
                      ></tr>
                      <tr
                        mat-row
                        *matRowDef="let row; columns: displayedColumns"
                      ></tr>
                    </table>
                  </div>

                  <div
                    *ngIf="!tableDataSource?.length"
                    class="no-solutions-available"
                  >
                    <span
                      >The selected region seems not to have any solutions
                      available.</span
                    >
                    <span>Please select another and try again.</span>
                  </div>

                  <ng-container *appVar="currentPageContent$ | async as page">
                    <button
                      mat-raised-button
                      color="accent"
                      style="padding: 0.5em 2em; width: 100%; margin: 1em 0"
                      (click)="checkCaptchaAndSubmit()"
                      data-testId="fastTrackStartSimulations"
                      [disabled]="!page.valid || !tableDataSource?.length"
                    >
                      {{
                        page.content?.callToActionButton1 || 'START SIMULATIONS'
                      }}
                    </button>
                  </ng-container>
                </mat-card-content>
              </ng-container>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>
