import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { IApiScene } from 'src/app/models_new/classes/api-models/ApiScene';
import { defaultApiOrganization } from './default-api-organization';
import { getDefaultScene } from 'src/app/models_new/types/simulation-api-file-type';

export const defaultApiScene: IApiScene = {
  id: '001',
  name: 'Default Hardware Configuration',
  data: getDefaultScene(),
  description: 'Default dummy data',
  organization_id: new ApiOrganization(defaultApiOrganization).id,
  created_at: new Date().getTime() + '',
  updated_at: new Date().getTime() + '',
  simulations: [],
  image: null,
};
