<app-page>
  <app-breadcrumb-nav></app-breadcrumb-nav>

  <ng-container *ngIf="robot$ | async as robot">
    <app-loading *ngIf="robot.isLoading"></app-loading>

    <ng-container *ngIf="robot.didSucceed">
      <app-header>
        <h1 app-title>{{ robot.value.name }}</h1>
      </app-header>

      <ng-container *ngIf="robotTypeState === 'Unknown Robot type'">
        <div class="unknown-robot-type">
          <app-highlighted-message-card
            [type]="'info'"
            [title]="missingConfigurationTitle"
            [message]="missingConfigurationMessage"
          ></app-highlighted-message-card>
          <button
            mat-raised-button
            pally-rounded-button-m
            color="primary"
            (click)="uploadConfiguration()"
          >
            <mat-icon>add</mat-icon>{{ missingConfigurationAction }}
          </button>
        </div>
      </ng-container>

      <div class="robot-entity-hero">
        <div class="robot-entity-info">
          <mat-card appearance="outlined" class="robot-entity-info__text">
            <mat-list role="list">
              <mat-list-item role="listitem"
                >Robot:
                <b
                  >{{ robotTypeState }} - {{ robotModel }} -
                  {{ robot.value.robot_serial_number }}</b
                ></mat-list-item
              >
            </mat-list>
          </mat-card>

          <mat-card appearance="outlined" class="robot-entity-info__text">
            <mat-list role="list">
              <mat-list-item role="listitem"
                >Pally version: <b>{{ pallyVersion }}</b></mat-list-item
              >
            </mat-list>
          </mat-card>

          <mat-card appearance="outlined" class="robot-entity-info__text">
            <mat-list role="list">
              <ng-container *ngIf="calibrations$ | async as calibrations">
                <app-loading *ngIf="calibrations.isLoading"></app-loading>
                <app-error
                  *ngIf="calibrations.error as error"
                  [error]="error"
                ></app-error>
                <mat-list-item role="listitem" *ngIf="calibrations.didSucceed">
                  <ng-container *ngIf="!calibrations.value.length">
                    No active configuration
                  </ng-container>
                  <ng-container *ngIf="calibrations.value.length">
                    Active configuration:
                    <b>{{ calibrations.value[0].name }}</b> -
                    {{ calibrations.value[0].updated_at }}
                  </ng-container>
                </mat-list-item>
              </ng-container>
            </mat-list>
          </mat-card>
        </div>

        <mat-card appearance="outlined" class="robot-entity-info__img">
          <img
            *ngIf="robotTypeState === 'UR'"
            [src]="robot.value.robot_configuration[0]?.scene?.image?.url"
            alt="Robot image"
          />
          <img
            *ngIf="robotTypeState === 'DOOSAN'"
            src="../../../../assets/dummy/doosan_scene_image.png"
            alt="Robot image"
          />
        </mat-card>

        <ng-container *ngIf="license$ | async as license">
          <mat-card
            appearance="outlined"
            class="robot-entity-license-status"
            [ngClass]="license.status"
          >
            <div class="license_text_1">
              <mat-card-subtitle>LICENSE STATUS:</mat-card-subtitle>
              <mat-card-title>{{
                license.status === 'no-license' ? 'No license' : license.status
              }}</mat-card-title>
            </div>
            <div class="license_text_2">
              <button
                *ngIf="license.status === 'active'"
                mat-stroked-button
                pally-rounded-button-m
                class="button"
                (click)="viewLicense(robot.value.robot_serial_number)"
              >
                View
              </button>
              <button
                *ngIf="license.status === 'active'"
                mat-flat-button
                pally-icon-button-m
                (click)="downloadLicense(license)"
                style="
                  background-color: transparent;
                  border: 1px solid;
                  margin-left: 5px;
                "
              >
                <mat-icon>download</mat-icon>
              </button>
              <button
                *ngIf="license.status !== 'active'"
                mat-stroked-button
                pally-rounded-button-m
                class="button"
                (click)="requestLicense(robot.value.robot_serial_number)"
              >
                Request license
              </button>
              <p *ngIf="license.license_valid_to">
                Valid to:
                <span class="secondary-span">{{
                  license.license_valid_to | date: 'dd/MM/yyyy HH:mm'
                }}</span>
              </p>
              <p *ngIf="!license.license_valid_to">...</p>
            </div>
          </mat-card>
        </ng-container>
      </div>

      <mat-tab-group
        color="accent"
        mat-stretch-tabs="false"
        mat-align-tabs="start"
      >
        <ng-container *ngIf="robotTypeState === 'UR'">
          <mat-tab label="Simulations">
            <ng-container *ngIf="simulations$ | async as simulations">
              <!-- Loading state -->
              <app-loading *ngIf="simulations.isLoading"></app-loading>
              <!-- Error state -->
              <app-error
                *ngIf="simulations.error as error"
                [error]="error"
              ></app-error>

              <app-header>
                <h1 app-title>Simulations</h1>
                <app-header-actions>
                  <button
                    mat-raised-button
                    pally-rounded-button-m
                    color="primary"
                    (click)="onSimulationClick()"
                  >
                    <mat-icon>add</mat-icon>New
                  </button>
                </app-header-actions>
              </app-header>

              <mat-divider></mat-divider>

              <ng-container *ngIf="simulations$ | async as simulations">
                <!-- Loading state -->
                <app-loading *ngIf="simulations.isLoading"></app-loading>
                <!-- Error state -->
                <app-error
                  *ngIf="simulations.error as error"
                  [error]="error"
                ></app-error>

                <app-content-switcher
                  *ngIf="simulations.didSucceed"
                  [selected]="blockSelectedSimulations"
                  [sortingColumns]="sortingColumnsSimulations"
                  [resultFilter]="resultFilterSimulations"
                  objectType="simulation"
                  (order)="orderBySimulations = $event"
                  (delete)="onDeleteSimulations(blockSelectedSimulations)"
                  (selectedFiltering)="
                    tableFilterSimulations = objUtil.cloneObject($event)
                  "
                >
                  <ng-template #table>
                    <app-inventory-table
                      [filter]="tableFilterSimulations"
                      [sortBy]="orderBySimulations"
                      [data]="simulations.value"
                      [displayedColumns]="displayedColumnsSimulations"
                      [rowActions]="rowActionsSimulations"
                      [rowCta]="rowCtaSimulations"
                      (rowAction)="actionClickedSimulations($event)"
                      (rowSelected)="rowClickedSimulations($event)"
                      (blockSelectedChange)="blockSelectedSimulations = $event"
                      (rowCtaAction)="ctaClickedSimulations($event)"
                    >
                    </app-inventory-table>
                  </ng-template>
                </app-content-switcher>
              </ng-container>
            </ng-container>
          </mat-tab>
        </ng-container>
        <ng-container *ngIf="robotTypeState === 'DOOSAN'">
          <mat-tab label="Pally programs">
            <app-header>
              <h1 app-title>Pally programs</h1>
              <app-header-actions>
                <button
                  mat-raised-button
                  pally-rounded-button-m
                  color="primary"
                  (click)="navigateToGenerateWaypoint()"
                >
                  <mat-icon>add</mat-icon>New
                </button>
              </app-header-actions>
            </app-header>
            <mat-divider></mat-divider>
            <ng-container *ngIf="pallyPrograms$ | async as pallyPrograms">
              <!-- Loading state -->
              <app-loading *ngIf="pallyPrograms.isLoading"></app-loading>

              <!-- Error state -->
              <app-error
                *ngIf="pallyPrograms.error as error"
                [error]="error"
              ></app-error>

              <app-content-switcher
                *ngIf="pallyPrograms.didSucceed"
                [selected]="blockSelectedPallyPrograms"
                [sortingColumns]="sortingColumnsPallyPrograms"
                objectType="pally_programs"
                (order)="orderByPallyPrograms = $event"
                (delete)="onDeletePallyPrograms(blockSelectedPallyPrograms)"
                (selectedFiltering)="
                  tableFilterPallyPrograms = objUtil.cloneObject($event)
                "
              >
                <ng-template #table>
                  <app-inventory-table
                    [filter]="tableFilterPallyPrograms"
                    [sortBy]="orderByPallyPrograms"
                    [data]="pallyPrograms.value"
                    [rowCta]="rowCtaPallyPrograms"
                    [displayedColumns]="displayedPallyProgramsColumns"
                    [rowActions]="rowActionsPallyPrograms"
                    (rowAction)="pallyProgramsActionClicked($event)"
                    (rowCtaAction)="pallyProgramsCtaClicked($event)"
                    (rowSelected)="navigateToWaypointFlow($event?.id)"
                    (blockSelectedChange)="blockSelectedPallyPrograms = $event"
                  >
                  </app-inventory-table>
                </ng-template>
              </app-content-switcher>
            </ng-container>
          </mat-tab>
        </ng-container>
        <mat-tab label="Configurations">
          <ng-container *ngIf="calibrations$ | async as calibrations">
            <!-- Loading state -->
            <app-loading *ngIf="calibrations.isLoading"></app-loading>

            <!-- Error state -->
            <app-error
              *ngIf="calibrations.error as error"
              [error]="error"
            ></app-error>

            <ng-container *ngIf="calibrations.didSucceed">
              <ng-container *ngIf="!calibrations.value.length">
                <div class="unknown-robot-type">
                  <app-highlighted-message-card
                    [type]="'info'"
                    [title]="missingConfigurationTitle"
                    [message]="missingConfigurationMessage"
                  ></app-highlighted-message-card>
                  <button
                    mat-raised-button
                    pally-rounded-button-m
                    color="primary"
                    (click)="uploadConfiguration()"
                  >
                    <mat-icon>add</mat-icon>{{ missingConfigurationAction }}
                  </button>
                </div>
              </ng-container>

              <ng-container *ngIf="calibrations.value.length">
                <app-header>
                  <h1 app-title>Configurations</h1>
                  <app-header-actions>
                    <button
                      mat-raised-button
                      pally-rounded-button-m
                      color="primary"
                      (click)="uploadConfiguration()"
                    >
                      <mat-icon>add</mat-icon>New
                    </button>
                  </app-header-actions>
                </app-header>
                <mat-divider></mat-divider>

                <app-content-switcher
                  [selected]="blockSelectedCalibrations"
                  [sortingColumns]="sortingColumnsCalibrations"
                  objectType="calibrations"
                  (order)="orderByCalibrations = $event"
                  (delete)="onDeleteCalibrations(blockSelectedCalibrations)"
                  (selectedFiltering)="
                    tableFilterCalibrations = objUtil.cloneObject($event)
                  "
                >
                  <ng-template #table>
                    <app-inventory-table
                      [filter]="tableFilterCalibrations"
                      [sortBy]="orderByCalibrations"
                      [data]="calibrations.value"
                      [rowCta]="rowCtaCalibrations"
                      [displayedColumns]="displayedCalibrationsColumns"
                      [rowActions]="rowActionsCalibrations"
                      [clickableRows]="false"
                      (rowAction)="calibrationsActionClicked($event)"
                      (rowCtaAction)="calibrationsCtaClicked($event)"
                      (blockSelectedChange)="blockSelectedCalibrations = $event"
                    >
                    </app-inventory-table>
                  </ng-template>
                </app-content-switcher>
              </ng-container>
            </ng-container>
          </ng-container>
        </mat-tab>
        <mat-tab label="Specifications">
          <app-table
            [strictFirstColWidth]="true"
            [showPaginator]="false"
            [actions]="false"
            [clickableRows]="false"
            [topRow]="false"
            [inputData]="specs"
            [displayedColumns]="['name', 'value']"
          ></app-table>
        </mat-tab>
      </mat-tab-group>

      <mat-divider></mat-divider>
    </ng-container>
  </ng-container>
</app-page>
