import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { IGuiButtonInput } from 'src/app/models_new/types/button-gui-input';

enum buttonType {
  STROKED = 'stroked',
  FLAT = 'flat',
}
enum buttonColor {
  ACCENT = 'accent',
  PRIMARY = 'primary',
  WARN = 'warn',
}
enum buttonEffect {
  NONE = '',
  BORDER = 'border',
  BOLD = 'bold',
  DISABLED = 'disabled',
  ROUNDED = 'rounded',
  ICONONYLY = 'iconOnly',
  GRADIENT = 'gradient',
  SLIM = 'slim',
  LOWERCASE = 'lowerCase',
  UPPERCASE = 'upperCase',
}
interface IButtonIcon {
  type: string;
  position: 'after' | 'before';
}
interface IButtonStyle {
  type: buttonType;
  color: buttonColor;
  effect?: string;
  icon?: IButtonIcon;
}
/**
 * @component @title Button
 * @description Custom button component for MRC. This component is used to create buttons with different styles and effects following the MRC design guidelines.
 * @deprecated The usage of this component is deprecated. Use the Angular Material button instead.
 * @example
 * <app-button
 *   [type]="type"
 *   [label]="label"
 *   [color]="color"
 *   [icon]="icon"
 *   [iconPosition]="iconPosition"
 *   [effects]="effects"
 *   [disabled]="disabled"
 *   (buttonClick)="buttonClick($event)"
 * ></app-button>
 */
@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit, OnChanges {
  buttonStyle: IButtonStyle;
  ready = false;

  @Input() type?: string;
  @Input() buttonType?: string;
  @Input() label?: string;
  @Input() color?: string;
  @Input() icon?: string;
  @Input() iconPosition?: 'after' | 'before';
  @Input() effects?: string | string[];
  @Input() disabled?: boolean;
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter();

  // Button Input. To be deprecated
  @Input() buttonInput?: IGuiButtonInput;

  ngOnInit(): void {
    this.setStyle();
  }

  ngOnChanges(): void {
    this.setStyle();
  }

  emitClick() {
    if (!this.disabled) {
      this.buttonClick.emit(true);
    }
  }

  private setStyle(): void {
    let type = buttonType.FLAT;
    let color = buttonColor.ACCENT;
    let effect = buttonEffect.NONE.toString();
    let icon: IButtonIcon;

    if (!this.type) {
      type = buttonType.FLAT;
      color = buttonColor.PRIMARY;
      effect = this.checkEffects(
        [buttonEffect.BOLD, buttonEffect.GRADIENT, buttonEffect.LOWERCASE],
        this.effects
      );
      icon = this.checkIcon(null, null, this.icon, this.iconPosition);
    }
    // TODO: Consider using a switch-case here
    else if (this.type === 'cta' || this.type === 'create') {
      this.label = this.label || this.type;
      type = buttonType.FLAT;
      color = buttonColor.ACCENT;
      effect = this.checkEffects(
        [buttonEffect.BOLD, buttonEffect.GRADIENT, buttonEffect.LOWERCASE],
        this.effects
      );
      icon = this.checkIcon(
        this.type === 'cta' ? null : 'done',
        'before',
        this.icon,
        this.iconPosition
      );
    } else if (this.type === 'edit') {
      this.label = this.label || this.type;
      type = buttonType.STROKED;
      color = buttonColor.ACCENT;
      effect = this.checkEffects(
        [buttonEffect.BOLD, buttonEffect.LOWERCASE],
        this.effects
      );
      icon = this.checkIcon('edit', 'before', this.icon, this.iconPosition);
    } else if (this.type === 'add' || this.type === 'save') {
      this.label = this.label || this.type;
      type = this.type === 'add' ? buttonType.STROKED : buttonType.FLAT;
      color = buttonColor.PRIMARY;
      effect = this.checkEffects([buttonEffect.UPPERCASE], this.effects);
      icon = this.checkIcon('add', 'before', this.icon, this.iconPosition);
    } else if (this.type === 'clear') {
      this.label = this.label || this.type;
      type = buttonType.STROKED;
      color = buttonColor.PRIMARY;
      effect = this.checkEffects(
        [buttonEffect.SLIM, buttonEffect.UPPERCASE],
        this.effects
      );
      icon = this.checkIcon('', 'before', this.icon, this.iconPosition);
    } else if (this.type === 'delete') {
      this.label = this.label || this.type;
      type = buttonType.FLAT;
      color = buttonColor.WARN;
      effect = this.checkEffects(
        [buttonEffect.BOLD, buttonEffect.GRADIENT, buttonEffect.LOWERCASE],
        this.effects
      );
      icon = this.checkIcon('clear', 'before', this.icon, this.iconPosition);
    } else if (this.type === 'back' || this.type === 'next') {
      this.label = this.label || null;
      type = buttonType.FLAT;
      color = buttonColor.PRIMARY;
      effect = this.checkEffects(
        [buttonEffect.ROUNDED, !this.label ? buttonEffect.ICONONYLY : ''],
        this.effects
      );
      icon = this.checkIcon(
        this.type === 'next' ? 'chevron_right' : 'chevron_left',
        'before',
        this.icon,
        this.iconPosition
      );
    } else if (this.type === 'custom') {
      this.label = this.label || this.type;
      type = buttonType.FLAT;
      color = buttonColor.ACCENT;
      effect = this.checkEffects(buttonEffect.NONE, this.effects);
      icon = this.checkIcon(null, null, this.icon, this.iconPosition);
    } else {
      this.label = this.label || this.type;
      type = this.checkType(buttonType.FLAT, this.buttonType);
      color = this.checkColor(buttonColor.PRIMARY, this.type, this.color);
      effect = this.checkEffects(
        [buttonEffect.BOLD, buttonEffect.UPPERCASE],
        this.effects
      );
      icon = this.checkIcon('done', 'before', this.icon, this.iconPosition);
    }

    this.buttonStyle = {
      type: type,
      color: color,
      effect: effect,
      icon: icon,
    };
    this.ready = true;
  }

  private checkColor(
    defaultColor: buttonColor,
    inputAction: string,
    inputColor?: string
  ) {
    let outputColor = defaultColor;
    if (inputColor) {
      if (buttonColor[inputColor.toUpperCase()]) {
        outputColor = buttonColor[inputColor.toUpperCase()];
      } else {
        outputColor =
          inputAction === 'delete' ? buttonColor.WARN : buttonColor.ACCENT;
      }
    }
    return outputColor;
  }

  private checkType(defaultType: buttonType, inputType?: string) {
    let outputType = defaultType;
    if (inputType) {
      if (buttonType[inputType.toUpperCase()]) {
        outputType = buttonType[inputType.toUpperCase()];
      }
    }
    return outputType;
  }

  private checkEffects(
    defaultEffect: string | string[],
    inputEffect: string | string[]
  ): buttonEffect | string {
    let outputEffect: string;
    if (inputEffect) {
      if (Array.isArray(inputEffect)) {
        outputEffect = '';
        inputEffect.forEach((e) => {
          outputEffect += ` ${e === 'slim' ? 'normal' : e}`;
        });
      } else {
        outputEffect = inputEffect;
      }
    } else {
      if (Array.isArray(defaultEffect)) {
        outputEffect = '';
        defaultEffect.forEach((e) => {
          outputEffect += ` ${e === 'slim' ? 'normal' : e}`;
        });
      } else {
        outputEffect = defaultEffect || buttonEffect.NONE;
      }
    }
    return outputEffect;
  }

  private checkIcon(
    defaultIcon: string | null,
    defaultPosition: 'before' | 'after',
    inputIcon?: string | null,
    inputPosition?: 'before' | 'after'
  ): IButtonIcon {
    let outputIcon: IButtonIcon;
    if ((defaultIcon || inputIcon) && inputIcon !== 'none') {
      outputIcon = {
        type: defaultIcon,
        position: defaultPosition,
      };
      if (inputIcon) {
        outputIcon = {
          type: inputIcon,
          position: inputPosition || 'before',
        };
      }
    }
    return outputIcon;
  }
}
