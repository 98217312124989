<ng-container *appVar="appLayout.lowHeight$ | async as lowHeigth">
  <app-split-card>
    <div split-card-content>
      <div class="card-fields-wrapper">
        <ng-container *ngFor="let conf of modeFieldConfigs">
          <ng-container
            *ngIf="conf.field.tabGroupId !== 'half-width'"
            [ngTemplateOutlet]="fieldTemplate"
            [ngTemplateOutletContext]="{
              conf: conf
            }"
          >
          </ng-container>
        </ng-container>
        <div class="double-field-container">
          <ng-container *ngFor="let conf of modeFieldConfigs">
            <ng-container
              *ngIf="conf.field.tabGroupId === 'half-width'"
              [ngTemplateOutlet]="fieldTemplate"
              [ngTemplateOutletContext]="{
                conf: conf
              }"
            ></ng-container>
          </ng-container>
        </div>
        <ng-template #fieldTemplate let-conf="conf">
          <app-field
            *ngIf="conf.field && conf.visible() && !conf.infoPopup"
            [id]="conf.field.id"
            [ngClass]="{ 'low-height': lowHeigth }"
            [field]="conf.field"
          ></app-field>
          <app-field
            *ngIf="conf.field && conf.visible() && conf.infoPopup"
            [id]="conf.field.id"
            [ngClass]="{ 'low-height': lowHeigth }"
            [field]="conf.field"
            (openInfoPopup)="openInfoPopup(conf.infoPopup)"
          ></app-field>
        </ng-template>
      </div>
      <div class="actions">
        <!-- Create + Edit mode save button-->
        <ng-container *appIfCommandAvailable="'save_product'">
          <app-button
            class="button"
            *ngIf="
              mode === 'create' ||
              mode === 'pick' ||
              (mode === 'edit' && !isPublic)
            "
            [type]="'save'"
            [label]="saveLabel"
            [icon]="'edit'"
            (buttonClick)="save()"
          >
          </app-button>
        </ng-container>
        <!-- Cancel button-->
        <app-button
          class="button"
          *ngIf="
            mode === 'create' ||
            mode === 'pick' ||
            (mode === 'edit' && enableCancel)
          "
          [type]="'clear'"
          [label]="'CANCEL'"
          (buttonClick)="cancel$.emit()"
        >
        </app-button>
      </div>
    </div>
    <div split-card-artwork>
      <app-new-product-view
        class="product-view"
        [length]="product?.data?.length"
        [width]="product?.data?.width"
        [height]="product?.data?.height"
        [sticker]="(logoService.eitherLabelOrLogo$ | async)?.texture"
        [productTypeId]="product?.data?.model_type || 'box'"
        [labelOrientations]="[product?.data?.label_orientation]"
        [showSide]="showSide$ | async"
        [hideNav]="false"
      ></app-new-product-view>
    </div>
  </app-split-card>
</ng-container>
