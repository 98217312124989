import { Injectable } from '@angular/core';
import { AxisDirection as AD } from '@rocketfarm/packing-webcomponents';
import { EditorBox } from '../models_new/classes/editor-box';
import { ProjectData } from '../models_new/classes/project-data';
import { PatternEditComponent } from '../components/patterns/pattern/pattern-edit/pattern-edit.component';

@Injectable({
  providedIn: 'root',
})
export class PatternlogicService {
  // Handle keyboard events
  onKeydown(event: KeyboardEvent, self: PatternEditComponent) {
    const packingElements = [self.boxPacking];
    switch (event.code) {
      case 'ArrowUp': // Up arrow, deprecated KeyCode.38
        packingElements.forEach((e) => e.nativeElement.move(AD.Y_POSITIVE));
        event.preventDefault();
        break;
      case 'ArrowRight': // right arrow, deprecated KeyCode.39
        packingElements.forEach((e) => e.nativeElement.move(AD.X_POSITIVE));
        event.preventDefault();
        break;
      case 'ArrowDown': // down arrow, deprecated KeyCode.40
        packingElements.forEach((e) => e.nativeElement.move(AD.Y_NEGATIVE));
        event.preventDefault();
        break;
      case 'ArrowLeft': // left arrow, deprecated KeyCode.37
        packingElements.forEach((e) => e.nativeElement.move(AD.X_NEGATIVE));
        event.preventDefault();
        break;
      case 'Delete': // delete key, deprecated KeyCode.46
        packingElements.forEach((e) => e.nativeElement.delete());
        break;
    }
  }

  /**
   * @param palletDimensions: ProjectData['pallet']
   * @param boxes: EditorBox[]
   */
  alignBoxVert(pallet: ProjectData['pallet'], boxes: EditorBox[]) {
    let minYOfBoxes: number = pallet.dimensions.length;
    let maxYOfBoxes = 0;
    boxes.forEach((box) => {
      if (box.getYMin() < minYOfBoxes) {
        minYOfBoxes = box.getYMin();
      }
      if (box.getYMaxReal() > maxYOfBoxes) {
        maxYOfBoxes = box.getYMaxReal();
      }
    });
    const availablePalletLength =
      pallet.dimensions.length + pallet.overhang.ends * 2;
    const moveY = (availablePalletLength - minYOfBoxes - maxYOfBoxes) / 2;
    boxes.forEach((box) => {
      box.location.setY(Math.floor(box.location.getY() + moveY));
    });
  }

  /**
   * @param palletDimensions: ProjectData['pallet']
   * @param boxes: EditorBox[]
   */
  alignBoxHoriz(pallet: ProjectData['pallet'], boxes: EditorBox[]) {
    let minXOfBoxes: number = pallet.dimensions.width;
    let maxXOfBoxes = 0;
    boxes.forEach((box) => {
      if (box.getXMin() < minXOfBoxes) {
        minXOfBoxes = box.getXMin();
      }
      if (box.getXMaxReal() > maxXOfBoxes) {
        maxXOfBoxes = box.getXMaxReal();
      }
    });
    const availablePalletWidth =
      pallet.dimensions.width + pallet.overhang.sides * 2;
    const moveX = (availablePalletWidth - minXOfBoxes - maxXOfBoxes) / 2;
    boxes.forEach((box) => {
      box.location.setX(Math.floor(box.location.getX() + moveX));
    });
  }

  rotatePattern(pallet: ProjectData['pallet'], boxes: EditorBox[]) {
    const availablePalletLength =
      pallet.dimensions.length + pallet.overhang.ends * 2;
    const availablePalletWidth =
      pallet.dimensions.width + pallet.overhang.sides * 2;
    boxes.forEach((box) => {
      box.location.setY(availablePalletLength - box.getYMaxReal());
      box.location.setX(availablePalletWidth - box.getXMaxReal());
      box.setDirection((box.getDirection() + 2) % 4);
    });
  }

  mirrorPatternVert(pallet: ProjectData['pallet'], boxes: EditorBox[]) {
    const availablePalletLength =
      pallet.dimensions.length + pallet.overhang.ends * 2;
    boxes.forEach((box) => {
      box.location.setY(availablePalletLength - box.getYMaxReal());
      if (box.isRotated()) {
        box.setDirection((box.getDirection() + 2) % 4);
      }
    });
  }

  mirrorPatternHoriz(pallet: ProjectData['pallet'], boxes: EditorBox[]) {
    const availablePalletWidth =
      pallet.dimensions.width + pallet.overhang.sides * 2;
    boxes.forEach((box) => {
      box.location.setX(availablePalletWidth - box.getXMaxReal());
      if (!box.isRotated()) {
        box.setDirection((box.getDirection() + 2) % 4);
      }
    });
  }
}
