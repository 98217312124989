import { Injectable, OnDestroy } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, Observable, Subject } from 'rxjs';
import { ObjectUtils } from '../../utils/object';
import { ClientApiService } from './client-api.service';
import {
  ApiGenerateWaypoint,
  IApiGenerateWaypoint,
} from 'src/app/models_new/classes/api-models/ApiGeneratedWaypoint';
import { ApiPattern } from 'src/app/models_new/classes/api-models/ApiPattern';
import { ApiCalibration } from 'src/app/models_new/classes/api-models/ApiCalibration';
import { ApiPallyPathStrategy } from 'src/app/models_new/classes/api-models/ApiPallyPathStrategy';

@Injectable({
  providedIn: 'root',
})
export class WaypointsApiService implements OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private clientApi: ClientApiService) {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public fetchWaypointsByInstalledRobotIdLigth(
    installed_robot_id: string,
    org_id: string
  ): Observable<IApiGenerateWaypoint[]> {
    const q = gql`
      subscription fetchWaypointsByInstalledRobotIdLigth {
        waypoints_generation(where: {organization_id: {_eq: "${org_id}"}, calibration: {installed_robot_id: {_eq: "${installed_robot_id}"}}}) {
          id
          name
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('FetchWaypointsByInstalledRobotId failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to fetch waypoints by installed robot id: ' +
                data.errors[0].message
            );
          } else {
            const list = [];
            for (const p of data.data.waypoints_generation) {
              list.push(ObjectUtils.cloneObject(p));
            }
            return list;
          }
        })
      );
  }

  public fetchWaypointsByInstalledRobotId(
    installed_robot_id: string,
    org_id: string
  ): Observable<IApiGenerateWaypoint[]> {
    const q = gql`
      subscription FetchWaypointsByInstalledRobotId {
        waypoints_generation(where: {organization_id: {_eq: "${org_id}"}, calibration: {installed_robot_id: {_eq: "${installed_robot_id}"}}}) {
          id
          name
          updated_at
          simulation_state
          progress:simulation_status(path: "progress")
          result
          pattern {
            name
            id
            product {
              id
              name
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('FetchWaypointsByInstalledRobotId failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to fetch waypoints by installed robot id: ' +
                data.errors[0].message
            );
          } else {
            const list = [];
            for (const p of data.data.waypoints_generation) {
              list.push(ObjectUtils.cloneObject(p));
            }
            return list;
          }
        })
      );
  }

  getWaypointsGenerations(org_id: string): Observable<IApiGenerateWaypoint[]> {
    const qi = gql`
      subscription getWaypointGenerations {
        waypoints_generation(where: {organization_id: {_eq: "${org_id}"}}) {
          id
          name
          simulation_state
          updated_at
          result
          eta :simulation_status(path: "eta")
          progress: simulation_status(path: "progress")
          error_message: simulation_status(path:"error_message")
          calibration {
            installed_robot {
              id
              name
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: qi,
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else {
            const list = [];
            for (const w of data.data.waypoints_generation) {
              const waypoint = new ApiGenerateWaypoint(
                ObjectUtils.cloneObject(w)
              );
              list.push(waypoint);
            }
            return list;
          }
        })
      );
  }

  public getWaypointGenerationById(
    id: string
  ): Observable<IApiGenerateWaypoint> {
    const q = gql`
      query getWaypointGenerationById($id: uuid!) {
        waypoints_generation_by_pk(id: $id) {
          id
          name
          artifact_urls
          created_at
          updated_at
          simulation_state
          eta: simulation_status(path: "eta")
          progress: simulation_status(path: "progress")
          error_message: simulation_status(path: "error_message")
          result
          calibration_id
          calibration {
            installed_robot {
              id
              name
              updated_at
              robot_serial_number
            }
          }
          pattern {
            id
            name
            data
          }
          pally_path_strategy {
            id
            name
            data
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
        variables: {
          id,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else {
            return ObjectUtils.cloneObject(
              data.data.waypoints_generation_by_pk
            );
          }
        })
      );
  }

  public insertWaypointsGeneration(
    orgID: string,
    name: string,
    pattern_id: string,
    calibration_id: string,
    path_strategy_id: string
  ): Observable<IApiGenerateWaypoint[]> {
    const m = gql`
      mutation insertWaypointsGenerationOne(
        $name: String!
        $organization_id: uuid!
        $pattern_id: uuid!
        $calibration_id: uuid!
        $pally_path_strategy_id: uuid!
      ) {
        insert_waypoints_generation_one(
          object: {
            name: $name
            organization_id: $organization_id
            pattern_id: $pattern_id
            calibration_id: $calibration_id
            pally_path_strategy_id: $pally_path_strategy_id
          }
        ) {
          id
          name
        }
      }
    `;
    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          name,
          organization_id: orgID,
          pattern_id,
          calibration_id,
          pally_path_strategy_id: path_strategy_id,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('insertWaypointsGenerationOne failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to insert waypoints generation: ' + data.errors[0].message
            );
          } else {
            return ObjectUtils.cloneObject(
              data.data.insert_waypoints_generation_one
            );
          }
        })
      );
  }

  public insertWaypointsGenerations(
    orgID: string,
    patterns: ApiPattern[],
    calibrations: ApiCalibration[],
    path_strategies: ApiPallyPathStrategy[],
    baseName: string
  ): Observable<IApiGenerateWaypoint[]> {
    const m = gql`
      mutation InsertWaypointsGeneration(
        $objects: [waypoints_generation_insert_input!]!
      ) {
        insert_waypoints_generation(objects: $objects) {
          affected_rows
        }
      }
    `;

    const objects = [];
    for (let pi = 0, pLen = patterns.length; pi < pLen; pi++) {
      for (let ci = 0, cLen = calibrations.length; ci < cLen; ci++) {
        for (
          let ppsi = 0, ppsLen = path_strategies.length;
          ppsi < ppsLen;
          ppsi++
        ) {
          objects.push({
            name:
              baseName +
              '-' +
              patterns[pi].name +
              '-' +
              calibrations[ci].installed_robot.name +
              '-' +
              path_strategies[ppsi].name,
            organization_id: orgID,
            pattern_id: patterns[pi].id,
            calibration_id: calibrations[ci].id,
            pally_path_strategy_id: path_strategies[ppsi].id,
          });
        }
      }
    }

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
        variables: {
          objects: objects,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('InsertWaypointsGeneration failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to insert waypoints generation: ' + data.errors[0].message
            );
          } else {
            return ObjectUtils.cloneObject(
              data.data.insert_waypoints_generation
            );
          }
        })
      );
  }

  public deleteWaypointGeneration(id: string) {
    const m = gql`
      mutation deleteWaypointsGeneration($id: uuid!) {
        delete_waypoints_generation_by_pk(id: $id) {
          id
        }
      }
    `;
    return this.clientApi
      .useClient('org_delete')
      .mutate<any>({
        mutation: m,
        variables: {
          id,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('deleteWaypointsGeneration failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to delete waypoints generation: ' + data.errors[0].message
            );
          } else {
            return ObjectUtils.cloneObject(
              data.data.delete_waypoints_generation_by_pk
            );
          }
        })
      );
  }
}
