import * as THREE from 'three';
import {
  JointNames,
  LinkNames,
} from '../../services/project-robot-descriptor.service';
import { SimConfigFieldIds } from '../enums/simconfig-field-ids';
import { ITimedObjectConfig } from '../types/simconf/timed-object-config';

/**
 * Config for any dimension visualizers in sim-config.
 */
export const simConfigDimVisConfig: ITimedObjectConfig[] = [
  // Robot base bracket
  {
    id: SimConfigFieldIds.BasebracketHeight,
    data: {
      start: JointNames.LiftkitBasebracket,
      end: LinkNames.Basebracket,
      mayorAxis: 'y',
      offset: {
        amount: 0.2,
        axis: 'x',
      },
      duration: 5000,
    },
  },
  // Distance from floor => robot base, called "mouting height".
  {
    id: 'robot_mount_height_full',
    data: {
      start: LinkNames.World,
      end: LinkNames.Basebracket,
      value: 'dist',
      mayorAxis: 'y',
      offset: {
        amount: -0.5,
        axis: 'x',
      },
      label: {
        axis: 'x',
        offset: 0.05,
      },
      duration: 5000,
    },
  },

  // Offset bracket
  {
    id: SimConfigFieldIds.OffsetBracketOffsetX,
    data: {
      start: LinkNames.OffsetBracket,
      end: JointNames.ToolmountOffsetBracket,
      mayorAxis: 'x',
      offset: {
        axis: 'y',
        amount: 0.1,
      },
      label: {
        axis: 'y',
        offset: 0.05,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.ToolmountOffsetBracket,
  },
  {
    id: SimConfigFieldIds.OffsetBracketOffsetY,
    data: {
      start: LinkNames.OffsetBracket,
      end: JointNames.ToolmountOffsetBracket,
      mayorAxis: 'y',
      offset: {
        axis: 'x',
        amount: -0.1,
      },
      label: {
        axis: 'x',
        offset: -0.05,
        xRot: -90,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.ToolmountOffsetBracket,
  },
  {
    id: SimConfigFieldIds.OffsetBracketOffsetZ,
    data: {
      start: LinkNames.OffsetBracket,
      end: JointNames.ToolmountOffsetBracket,
      mayorAxis: 'z',
      offset: {
        axis: 'x',
        amount: -0.15,
        xRot: -90,
      },
      label: {
        axis: 'y',
        offset: -0.05,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.ToolmountOffsetBracket,
  },

  // Gripper
  {
    id: SimConfigFieldIds.GripperCustomPositionX,
    data: {
      start: new THREE.Vector3(), // Position set on activation
      end: JointNames.OffsetBracketGripper,
      mayorAxis: 'x',
      offset: {
        amount: 0.1,
        axis: 'y',
      },
      label: {
        axis: 'y',
        offset: 0.05,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.OffsetBracketGripper,
  },
  {
    id: SimConfigFieldIds.GripperCustomPositionY,
    data: {
      start: new THREE.Vector3(), // Position set on activation
      end: LinkNames.OffsetBracket,
      mayorAxis: 'y',
      offset: {
        amount: 0.2,
        axis: 'x',
      },
      label: {
        axis: 'y',
        offset: 0.05,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.OffsetBracketGripper,
  },
  {
    id: SimConfigFieldIds.GripperCustomPositionZ,
    data: {
      start: new THREE.Vector3(), // Position set on activation
      end: LinkNames.OffsetBracket,
      mayorAxis: 'z',
      offset: {
        amount: -0.2,
        axis: 'x',
      },
      label: {
        axis: 'y',
        offset: 0.05,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.OffsetBracketGripper,
  },

  // Primary conveyor
  {
    id: SimConfigFieldIds.ConveyorPrimaryPositionX,
    data: {
      start: JointNames.WorldPrimaryConveyor,
      end: JointNames.WorldFrame,
      mayorAxis: 'x',
      offset: {
        amount: 0.1,
        axis: 'z',
      },
      duration: 5000,
    },
  },
  {
    id: SimConfigFieldIds.ConveyorPrimaryPositionY,
    data: {
      start: JointNames.BasebracketRobotbase,
      end: JointNames.WorldPrimaryConveyor,
      mayorAxis: 'z',
      offset: {
        amount: -0.2,
        axis: 'x',
      },
      duration: 5000,
    },
  },
  {
    id: SimConfigFieldIds.ConveyorPrimaryPositionZ,
    data: {
      start: JointNames.WorldPrimaryConveyor,
      end: LinkNames.World,
      mayorAxis: 'y',
      offset: {
        amount: -0.5,
        axis: 'x',
      },
      label: {
        axis: 'x',
        offset: 0.05,
      },
      duration: 5000,
    },
  },
  {
    id: SimConfigFieldIds.ConveyorPrimaryGuideWidth,
    data: {
      start: LinkNames.ConveyorGuideOffsetX,
      end: LinkNames.ConveyorGuide,
      mayorAxis: 'x',
      offset: {
        amount: 0.2,
        axis: 'z',
      },
      label: {
        axis: 'z',
        offset: 0.05,
        xRot: 90,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.ConveyorGuideOffsetX,
  },
  {
    id: SimConfigFieldIds.ConveyorPrimaryBoxFreeHeight,
    data: {
      start: LinkNames.ConveyorBoxFreeHeight,
      end: new THREE.Vector3(), // Position set on activation
      mayorAxis: 'y',
      offset: {
        amount: 0.1,
        axis: 'x',
      },
      label: {
        axis: 'x',
        offset: 0.05,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.ConveyorBoxFreeHeight,
  },

  // Secondary conveyor
  {
    id: SimConfigFieldIds.ConveyorSecondaryPositionX,
    data: {
      start: JointNames.WorldSecondaryConveyor,
      end: JointNames.WorldFrame,
      mayorAxis: 'x',
      offset: {
        amount: 0.1,
        axis: 'z',
      },
      duration: 5000,
    },
  },
  {
    id: SimConfigFieldIds.ConveyorSecondaryPositionY,
    data: {
      start: JointNames.BasebracketRobotbase,
      end: JointNames.WorldSecondaryConveyor,
      mayorAxis: 'z',
      offset: {
        amount: -0.2,
        axis: 'x',
      },
      duration: 5000,
    },
  },
  {
    id: SimConfigFieldIds.ConveyorSecondaryPositionZ,
    data: {
      start: JointNames.WorldSecondaryConveyor,
      end: LinkNames.World,
      mayorAxis: 'y',
      offset: {
        amount: -0.5,
        axis: 'x',
      },
      label: {
        axis: 'x',
        offset: 0.05,
      },
      duration: 5000,
    },
  },
  {
    id: SimConfigFieldIds.ConveyorSecondaryGuideWidth,
    data: {
      start: LinkNames.SecondaryConveyorGuideOffsetX,
      end: LinkNames.SecondaryConveyorGuide,
      mayorAxis: 'x',
      offset: {
        amount: 0.2,
        axis: 'z',
      },
      label: {
        axis: 'z',
        offset: 0.05,
        xRot: 90,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.SecondaryConveyorGuideOffsetX,
  },
  {
    id: SimConfigFieldIds.ConveyorSecondaryBoxFreeHeight,
    data: {
      start: LinkNames.SecondaryConveyorBoxFreeHeight,
      end: new THREE.Vector3(), // Position set on activation
      mayorAxis: 'y',
      offset: {
        amount: 0.1,
        axis: 'x',
      },
      label: {
        axis: 'x',
        offset: 0.05,
      },
      duration: 5000,
    },
    notGlobal: true,
    parent: JointNames.SecondaryConveyorBoxFreeHeight,
  },

  // Right pallet
  {
    id: SimConfigFieldIds.PalletRightPositionX,
    data: {
      start: LinkNames.Basebracket,
      end: LinkNames.PalletRight,
      mayorAxis: 'x',
      offset: {
        amount: 0.5,
        axis: 'z',
      },
      label: {
        axis: 'y',
        offset: 0.05,
      },
      duration: 5000,
    },
  },
  {
    id: SimConfigFieldIds.PalletRightPositionZ,
    data: {
      start: LinkNames.World,
      end: LinkNames.PalletRight,
      mayorAxis: 'z',
      offset: {
        amount: 0.6,
        axis: 'z',
      },
      label: {
        axis: 'y',
        offset: 0.1,
      },
      duration: 5000,
    },
  },

  // Left pallet
  {
    id: SimConfigFieldIds.PalletLeftPositionX,
    data: {
      start: LinkNames.Basebracket,
      end: LinkNames.PalletLeft,
      mayorAxis: 'x',
      offset: {
        amount: 0.5,
        axis: 'z',
      },
      label: {
        axis: 'y',
        offset: 0.05,
      },
      duration: 5000,
    },
  },
];
