import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SidenavService {
  public keepExpanded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  public isSidenavReady$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  public toggleSidebar(): void {
    // Will auto-collapse the sidebar if the viewport width is less than 1400px
    if (window.innerWidth < 1400) this.keepExpanded$.next(false);
  }
}
