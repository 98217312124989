import { IOpenSimContent } from 'src/app/services/api/info-api.service';
import { MarketingTagID } from './marketing-tags';
import { pagesPATH } from './pages';

export interface IOpenSimPage {
  path: string;
  contentfulStepId?: string;
  valid: boolean;
  content?: IOpenSimContent;
  done: boolean;
  analyticsTags?: {
    backClick?: MarketingTagID;
    nextClick?: MarketingTagID;
  };
  step?: number;
}

/** Definition of the pages and order that are part of the fast track process. */
export const fastTrackPages: IOpenSimPage[] = [
  /** @todo: Enable back when merging dev to qa @ibernia */
  // {
  //   path: pagesPATH.FAST_TRACK_LADNING,
  //   contentfulStepId: 'landing',
  //   valid: true,
  //   content: null,
  //   done: false,
  //   analyticsTags: {
  //     nextClick: 'Partner Landing - Fast Track - MyRobot.cloud - Next - Click',
  //   },
  // },
  {
    path: pagesPATH.FAST_TRACK_PRODUCT,
    contentfulStepId: 'product-details',
    valid: false,
    content: null,
    done: false,
    analyticsTags: {
      backClick:
        'Product Specifications - Fast Track - MyRobot.cloud - Back - Click',
      nextClick:
        'Product Specifications - Fast Track - MyRobot.cloud - Next - Click',
    },
  },
  {
    path: pagesPATH.FAST_TRACK_PATTERN,
    contentfulStepId: 'pattern-details',
    valid: false,
    content: null,
    done: false,
    analyticsTags: {
      backClick:
        'Pallet Specifications - Fast Track - MyRobot.cloud - Back - Click',
      nextClick:
        'Pallet Specifications - Fast Track - MyRobot.cloud - Next - Click',
    },
  },
  {
    path: pagesPATH.FAST_TRACK_REGION,
    contentfulStepId: 'region-details',
    valid: false,
    content: null,
    done: false,
    analyticsTags: {
      backClick: 'Region - Fast Track - MyRobot.cloud - Back - Click',
      nextClick: 'Region - Fast Track - MyRobot.cloud - Next - Click',
    },
  },
  {
    path: pagesPATH.FAST_TRACK_USER_INFO,
    contentfulStepId: 'user-details',
    valid: false,
    content: null,
    done: false,
    analyticsTags: {
      backClick: 'User Info - Fast Track - MyRobot.cloud - Back - Click',
      nextClick:
        'User Info - Fast Track - MyRobot.cloud - Start Simulation - Click',
    },
  },
  {
    path: pagesPATH.FAST_TRACK_EXPLORE,
    contentfulStepId: 'simulations-details',
    valid: false,
    content: null,
    done: false,
    analyticsTags: {
      backClick: 'Explore - Fast Track - MyRobot.cloud - Reset - Click',
    },
  },
];
