<app-page>
  <app-breadcrumb-nav></app-breadcrumb-nav>
  <app-header>
    <h1 app-title>Hardware configurations</h1>
    <app-header-actions>
      <button
        *appIfCommandAvailable="'create_hardware'"
        mat-raised-button
        pally-rounded-button-m
        color="primary"
        (click)="navigateToHardware('new')"
      >
        <mat-icon>add</mat-icon>New
      </button>
    </app-header-actions>
  </app-header>

  <mat-divider></mat-divider>

  <ng-container *ngIf="hardwares$ | async as hardwares">
    <!-- Loading state -->
    <app-loading *ngIf="hardwares.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="hardwares.error as error" [error]="error"></app-error>

    <app-content-switcher
      *ngIf="hardwares.didSucceed"
      [enableToggle]="false"
      [selected]="blockSelected"
      [sortingColumns]="sortingColumns"
      objectType="hardware"
      (selectedFiltering)="tableFilter = objUtil.cloneObject($event)"
      (order)="orderBy = $event"
      (delete)="onDelete(blockSelected)"
    >
      <ng-template #table>
        <app-inventory-table
          [filter]="tableFilter"
          [sortBy]="orderBy"
          [data]="hardwares.value"
          [displayedColumns]="displayedColumns"
          [rowActions]="rowActions"
          (rowAction)="actionClicked($event)"
          (rowSelected)="rowClicked($event)"
          (blockSelectedChange)="blockSelected = $event"
        >
        </app-inventory-table>
      </ng-template>

      <ng-template #cardList>
        <!-- <app-card-list-wrapper
          [inputData]="inputData.value"
          [filter]="tableFilter"
          [sortBy]="orderBy"
        >
          <ng-template #card let-data>
            <app-card-picker-list
              [type]="pickerType"
              [listData]="data"
              [columnColors]="[]"
              [listColumnUnits]="[]"
              [doesAllowActions]="true"
              [data]="data.data"
              (actionHandler)="actionClicked($event)"
              [listColumns]="cardColumns"
            >
            </app-card-picker-list>
          </ng-template>
        </app-card-list-wrapper> -->
      </ng-template>
    </app-content-switcher>
  </ng-container>
</app-page>
