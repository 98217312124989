import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OrganizationContextResolver } from '../../resolvers/organization-context-resolver.resolver';
import { PallyPathStrategiesOutletComponent } from 'src/app/components/pally-path-strategies/pally-path-strategies-outlet/pally-path-strategies-outlet.component';
import { PallyPathStrategiesComponent } from 'src/app/components/inventory/pally-path-strategies/pally-path-strategies.component';
import { PallyPathStrategyDetailsComponent } from 'src/app/components/pally-path-strategies/pally-path-setting-details/pally-path-strategy-details.component';

const routes: Routes = [
  {
    component: PallyPathStrategiesOutletComponent,
    path: '',
    resolve: { organization_id: OrganizationContextResolver },
    data: {
      breadcrumb: 'Pally program settings',
    },
    children: [
      {
        component: PallyPathStrategiesComponent,
        path: '',
        data: {
          breadcrumb: undefined,
        },
      },
      {
        component: PallyPathStrategyDetailsComponent,
        path: ':id',
        data: {
          breadcrumb: 'Editing Pally program setting',
        },
      },
      {
        component: PallyPathStrategyDetailsComponent,
        path: 'new',
        data: {
          breadcrumb: 'New Pally program setting',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PallyPathStrategyRoutingModule {}
