import { settings } from 'src/app/models_new/config/application-settings';
import { TimeUtil } from 'rosbag';
import { Time } from '../models_new/types/rosbag/rosbag-types';

// true if 'value' is between 'min' and 'max' (including 'max'), false otherwise
export function isWithin(min: Time, max: Time, value: Time) {
  if (!TimeUtil.isLessThan(min, max)) {
    console.error('"min" is larger than "max"! Defaulting to false!');
    return false;
  }

  return (
    (TimeUtil.isGreaterThan(value, min) || TimeUtil.areSame(value, min)) &&
    (TimeUtil.isLessThan(value, max) || TimeUtil.areSame(value, max))
  );
}

// Subtracts right from left time, i.e. "left - right"
export function sub(left: Time, right: Time) {
  const diffMS = toMilliseconds(left) - toMilliseconds(right);
  const sec = Math.floor(diffMS / 1000);
  const nano = (diffMS - Math.floor(diffMS)) * 10 ** 6;
  return {
    sec: sec,
    nsec: nano,
  } as Time;
}

export function addSeconds(time: Time, sec: number): Time {
  const timeSec = toSeconds(time) + sec;
  return fromSeconds(timeSec);
}

export function subSeconds(time: Time, sec: number): Time {
  const timeSec = toSeconds(time) - sec;
  return fromSeconds(timeSec);
}

export function addMillisecs(time: Time, ms: number): Time {
  const timeMS = toMilliseconds(time) + ms;
  return fromMilliseconds(timeMS);
}

export function subMillisecs(time: Time, ms: number): Time {
  const timeMS = toMilliseconds(time) - ms;
  return fromMilliseconds(timeMS);
}

// Converts timestamp to seconds
export function toSeconds(value: Time): number {
  return value.sec + value.nsec / 10 ** 9;
}

// Converts timestamp to milliseconds
export function toMilliseconds(value: Time): number {
  return value.sec * 10 ** 3 + value.nsec / 10 ** 6;
}

// Converts seconds to timestamp
export function fromSeconds(value: number): Time {
  const sec = Math.floor(value);
  const nsec = Math.floor((value - sec) * 10 ** 9);

  return { sec: sec, nsec: nsec } as Time;
}

// Converts milliseconds to timestamp
export function fromMilliseconds(value: number): Time {
  return fromSeconds(value / 10 ** 3);
}

// Conerts to pretty human readable format
export function toPrettyString(value: Time, includeMS?: boolean): string {
  const longTimeSec = toSeconds(value);
  const ms = longTimeSec - Math.floor(longTimeSec);
  const sec = (longTimeSec % 60) + ms;
  const min = Math.floor((longTimeSec / 60) % 60);
  const hour = Math.floor(longTimeSec / 3600);

  const defNrStyle = {
    minimumIntegerDigits: 2,
    maximumIntegerDigits: 2,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
    useGrouping: false,
  };
  const secNrStyle = {
    minimumIntegerDigits: 2,
    maximumIntegerDigits: 2,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: false,
  };
  return (
    (hour > 0 ? hour.toLocaleString('en-US', defNrStyle) + ':' : '') +
    min.toLocaleString('en-US', defNrStyle) +
    ':' +
    (typeof includeMS !== 'undefined' && includeMS
      ? sec
      : Math.floor(sec)
    ).toLocaleString(
      'en-US',
      typeof includeMS !== 'undefined' && includeMS ? secNrStyle : defNrStyle
    )
  );
}

export const timeDifferenceApprox = (
  current: number,
  previous: number
): string => {
  if (isNaN(current)) return 'N/A';
  const msPerMinute = 60 * 1000;
  const msPerHour = msPerMinute * 60;
  const msPerDay = msPerHour * 24;
  const msPerMonth = msPerDay * 30;
  const msPerYear = msPerDay * 365;

  const elapsed = current - previous;

  if (elapsed < msPerMinute) {
    return 'Less than a minute';
  } else if (elapsed < msPerHour) {
    const minutes = Math.round(elapsed / msPerMinute);
    return minutes + (minutes > 1 ? ' minutes' : ' minute');
  } else if (elapsed < msPerDay) {
    const hours = Math.round(elapsed / msPerHour);
    return hours + (hours > 1 ? ' hours' : ' hour');
  } else if (elapsed < msPerMonth) {
    const days = Math.round(elapsed / msPerDay);
    return days + (days > 1 ? ' days' : ' day');
  } else if (elapsed < msPerYear) {
    const months = Math.round(elapsed / msPerMonth);
    return months + (months > 1 ? ' months' : ' month');
  } else {
    const years = Math.round(elapsed / msPerYear);
    return years + (years > 1 ? ' years' : ' year');
  }
};

/**
 *
 * @params {number} date Date data input
 * @params {string} options Type of date/time representation.
 * @params {boolean} showSecondsInTwoDigit Boolean switch for showing seconds in two-digit time-format in en-GB locale.
 * @params {string} locale Locale to use when outputting date string.
 *
 */

export const getFormattedDate = (
  date: number | Date,
  options: '2-digit' | 'numeric' | 'time-since',
  showSecondsInTwoDigit = false,
  locale: string = settings.defaultGlobalDateLocale
): string => {
  const dateObj = new Date(date);
  if (options === 'time-since') {
    const daysAgo = Math.round(
      (Date.now() - dateObj.getTime()) / (1000 * 60 * 60 * 24)
    );
    const hoursAgo = Math.round(
      (Date.now() - dateObj.getTime()) / (1000 * 60 * 60)
    );
    const minutesAgo = Math.round(
      (Date.now() - dateObj.getTime()) / (1000 * 60)
    );

    if (daysAgo > 0) {
      return daysAgo + (daysAgo > 1 ? ' days' : ' day');
    } else if (hoursAgo > 0) {
      return hoursAgo + (hoursAgo > 1 ? ' hours' : ' hour');
    } else if (minutesAgo > 0) {
      return minutesAgo + (minutesAgo > 1 ? ' minutes' : ' minute');
    } else {
      return 'less than a minute';
    }
  }

  const dateString = dateObj.toLocaleDateString(locale, {
    hour: options,
    minute: options,
    second: options,
  });

  if (locale == 'en-GB') {
    return showSecondsInTwoDigit
      ? dateString.replace(',', '')
      : dateString.substring(0, dateString.length - 3).replace(',', '');
  } else {
    return dateString;
  }
};

export const getLastEdited = (updated_at: string): string => {
  const updated_at_num = Date.parse(updated_at);
  const MILISECPERDAY = 1000 * 60 * 60 * 24;
  let today = new Date();
  let last_edited = new Date(updated_at_num);

  if (last_edited.getDate() != today.getDate()) {
    let days = Math.floor((Date.now() - last_edited.getTime()) / MILISECPERDAY);
    return days >= 0 && days < 2 ? 'yesterday' : days + ' days ago';
  } else {
    let minutes = last_edited.getMinutes().toString();
    minutes = minutes.length === 1 ? minutes + '0' : minutes;
    return last_edited.getHours().toString() + ':' + minutes;
  }
};

/**
 * Formats a given value in minutes and seconds.
 * @param {number} value - The value to be formatted.
 * @param {boolean} isMiliseconds - Optional parameter to indicate if the value is in milliseconds. Default is false.
 * @returns The formatted string in the format "minutes:seconds".
 */
export const getFormatedMinuteSeconds = (
  value: number,
  isMiliseconds = false
): string => {
  const totalSeconds = isMiliseconds ? value / 1000 : value;
  let minutes = Math.floor(totalSeconds / 60);
  let seconds = Math.floor(totalSeconds % 60);
  // Pad the seconds with a zero if it's less than 10
  const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
  return `${minutes}:${formattedSeconds}`;
};
