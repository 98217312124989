import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { InstalledRobotsRoutingModule } from './installed-robots-routing.module';
import { SharedModule } from '../shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { ThreeModule } from '../three.module';
import { InstalledRobotsOutletComponent } from '../../components/installed-robots/installed-robots-outlet/installed-robots-outlet.component';
import { VerifyRobotConfigComponent } from '../../components/installed-robots/verify-robot-config/verify-robot-config.component';
import { RobotEntityComponent } from 'src/app/components/installed-robots/robot-entity/robot-entity.component';
import { InstalledRobotsComponent } from 'src/app/components/inventory/installed-robots/installed-robots.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ThreeModule,
    SharedModule,
    CommonModule,
    InstalledRobotsRoutingModule,
  ],
  declarations: [
    RobotEntityComponent,
    InstalledRobotsOutletComponent,
    InstalledRobotsComponent,
    VerifyRobotConfigComponent,
  ],
  exports: [RobotEntityComponent],
  providers: [DatePipe],
})
export class InstalledRobotsModule {}
