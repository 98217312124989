import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as THREE from 'three';
import { ThreeViewContent } from '../../../../../../../models_new/classes/3dview/three-content';
import { New_ThreeViewComponent } from '../../../../three-view/three-view.component';

@Component({
  selector: 'app-directional-light',
  templateUrl: './directional-light.component.html',
  styleUrls: ['./directional-light.component.scss'],
})
export class DirectionalLightComponent
  extends ThreeViewContent
  implements OnChanges
{
  @Input() color = '#ffffff';
  @Input() intensity = 1;

  @Input() position = new THREE.Vector3(0, 0, 0);
  @Input() target: THREE.Vector3 | THREE.Object3D = new THREE.Vector3(0, -1, 0);

  light = new THREE.DirectionalLight(this.color, this.intensity);

  // Adds a light helper for the light to the scene.
  @Input() debug: boolean;
  helper = new THREE.DirectionalLightHelper(this.light);

  constructor(threeView: New_ThreeViewComponent) {
    super(threeView);

    this.helper.visible = false;
    this.light.target.name = 'dirLight_target';
    this.scene.add(this.light, this.light.target, this.helper);
  }

  override update(_dt: number): void {
    this.helper.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.color?.currentValue) {
      this.light.color = changes.color.currentValue;
      this.helper.update();
    }
    if (changes.intensity?.currentValue) {
      this.light.intensity = changes.intensity.currentValue;
      this.helper.update();
    }
    if (changes.position?.currentValue) {
      this.light.position.copy(changes.position.currentValue);
      this.helper.update();
    }
    if (changes.target?.currentValue) {
      if (changes.target.currentValue instanceof THREE.Object3D) {
        this.light.target = changes.target.currentValue;
      } else {
        this.light.target.position.copy(changes.target.currentValue);
      }
      this.helper.update();
    }
    if (changes.debug?.currentValue) {
      this.helper.visible = changes.debug.currentValue;
    }
  }
}
