import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { ThreeModule } from '../three.module';
import { PallyPathStrategiesOutletComponent } from 'src/app/components/pally-path-strategies/pally-path-strategies-outlet/pally-path-strategies-outlet.component';
import { PallyPathStrategyRoutingModule } from './pally-path-strategy-routing.module';
import { PallyPathStrategiesComponent } from 'src/app/components/inventory/pally-path-strategies/pally-path-strategies.component';
import { PallyPathStrategyDetailsComponent } from 'src/app/components/pally-path-strategies/pally-path-setting-details/pally-path-strategy-details.component';

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    ThreeModule,
    SharedModule,
    CommonModule,
    PallyPathStrategyRoutingModule,
  ],
  declarations: [
    PallyPathStrategiesOutletComponent,
    PallyPathStrategiesComponent,
    PallyPathStrategyDetailsComponent,
  ],
  exports: [
    PallyPathStrategiesOutletComponent,
    PallyPathStrategiesComponent,
    PallyPathStrategyDetailsComponent,
  ],
})
export class PallyPathStrategyModule {}
