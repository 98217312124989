import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  BehaviorSubject,
  combineLatest,
  fromEvent,
  Observable,
  of,
  ReplaySubject,
  Subject,
  zip,
  filter,
} from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { toScene } from 'src/app/services/robot-config-helper';
import { ThreeHandlerMode } from '../../../models_new/classes/3dview/three-handler-mode';
import { SimConfigService } from 'src/app/services/sim-config.service';
import { NotificationService } from '../../../services/notification.service';
import { SimConfigFieldIds } from '../../../models_new/enums/simconfig-field-ids';
import { HardwareApiService } from '../../../services/api/hardware-api.service';
import * as uuid from 'uuid';
import { ISceneApiFileType } from '../../../models_new/types/simulation-api-file-type';
import { StateService } from '../../../auth/state.service';
import { ApiOrganization } from '../../../models_new/classes/api-models/ApiOrganization';
import { DataRequestState } from '../../../data-request/model';
import { toRequestState } from '../../../data-request/operators';
import { Field } from '../../../models_new/classes/field';
import { RenderTrigger } from 'src/app/public/render-trigger';
import { AssetApiService } from 'src/app/services/api/asset-api.service';
import * as THREE from 'three';
import { ObjectUtils } from 'src/app/utils/object';
import { ImageProcessing } from 'src/app/utils/image-utils';
import { IInsertSimulationResponse } from 'src/app/services/api/api.service';
import { settings } from '../../../models_new/config/application-settings';
import { LocalStorageKey } from '../../../models_new/enums/local-storage-keys';
import { LocalStorageService } from '../../../services/local-storage.service';
import { DialogService } from '../../../services/dialog.service';
import { CustomGripperComponent } from '../../dialogs/custom-gripper/custom-gripper.component';
import { DialogSize } from '../../../models_new/enums/dialogSize';
import { FieldUpdate } from '../../../models_new/types/field-update';
import { ICustomGripper } from '../../../models_new/types/custom-gripper';
import { MatExpansionPanel } from '@angular/material/expansion';

enum Actions {
  SaveRobotStructure = 'save_robotstructure',
  Advanced = 'advanced_robotstructure',
}

interface IHardwareAction {
  label: string;
  value: string;
  icon?: string;
  iconPosition?: 'after' | 'before';
  filled?: boolean;
  isBlue?: boolean;
  disabled?: boolean;
}

interface IHardwareContent {
  fieldGroups: IFieldGroup[];
  threeFields: Field[];
  underText?: string;
  boldText?: string;
  img?: string;
  threeId?: string;
  threeMode?: ThreeHandlerMode;
  allowPerspective?: boolean;
  validateFields?: boolean;
  missingContentText?: string;
}

const whitelistedFields = [
  SimConfigFieldIds.Name,
  SimConfigFieldIds.RobotType,
  SimConfigFieldIds.FrameType,
  SimConfigFieldIds.LiftkitType,
  SimConfigFieldIds.LiftkitMaxStroke,
  SimConfigFieldIds.GripperType,
  SimConfigFieldIds.GripperCustomLength,
  SimConfigFieldIds.GripperCustomWidth,
  SimConfigFieldIds.GripperCustomHeight,
  SimConfigFieldIds.GripperCustomPositionX,
  SimConfigFieldIds.GripperCustomPositionY,
  SimConfigFieldIds.BasebracketHeight,
  SimConfigFieldIds.OffsetBracketType,
  SimConfigFieldIds.ConveyorPrimaryType,
  SimConfigFieldIds.ConveyorPrimaryPositionY,
  SimConfigFieldIds.ConveyorPrimaryPositionX,
  SimConfigFieldIds.ConveyorPrimaryDirection,
  SimConfigFieldIds.ConveyorPrimaryGuideSide,
  SimConfigFieldIds.ConveyorPrimaryDirection,
  SimConfigFieldIds.ConveyorPrimaryGuideWidth,
  SimConfigFieldIds.ConveyorPrimaryHeight,
  SimConfigFieldIds.ConveyorSecondaryType,
  SimConfigFieldIds.ConveyorSecondaryPositionY,
  SimConfigFieldIds.ConveyorSecondaryPositionX,
  SimConfigFieldIds.ConveyorSecondaryDirection,
  SimConfigFieldIds.ConveyorSecondaryGuideSide,
  SimConfigFieldIds.ConveyorSecondaryDirection,
  SimConfigFieldIds.ConveyorSecondaryGuideWidth,
  SimConfigFieldIds.ConveyorSecondaryHeight,
  SimConfigFieldIds.PalletLeftPositionX,
  SimConfigFieldIds.PalletLeftPositionY,
  SimConfigFieldIds.PalletLeftPositionZ,
  SimConfigFieldIds.PalletRightPositionX,
  SimConfigFieldIds.PalletRightPositionY,
  SimConfigFieldIds.PalletRightPositionZ,
  SimConfigFieldIds.PalletSideOffset,
  SimConfigFieldIds.PalletEndOffset,
  SimConfigFieldIds.PalletVerticalOffset,
  SimConfigFieldIds.DualProductMode,
];

type GroupStyle =
  | 'single'
  | 'inline'
  | 'rows'
  | 'columns'
  | 'collapsable'
  | 'collapsable_togglable';

interface ITemplateFieldGroup {
  id?: string; // Can be omitted if not needed, but can be used with extraExpandableActions
  style?: GroupStyle;
  name?: string; // Only used if containing other groups.
  isTrailing?: boolean; // Enables separation lines on the right.
  borderBottom?: boolean; // Disables the bottom border for the whole group.

  // Only used in collapsable_togglable
  enabledControl?: SimConfigFieldIds;

  // Either of these two should be defined.
  fields?: SimConfigFieldIds[];
  groups?: ITemplateFieldGroup[];
}

interface IFieldGroup {
  id?: string;
  style: GroupStyle;
  fields?: Field[];
  groups?: IFieldGroup[];
  isTrailing: boolean;
  borderBottom?: boolean;
  name?: string; // Only used if containing other groups.
}

const threeFields = [SimConfigFieldIds.ShowDimensions];

@Component({
  selector: 'app-hardware-configuration-card',
  templateUrl: './hardware-configuration-card.component.html',
  styleUrls: ['./hardware-configuration-card.component.scss'],
})
export class HardwareConfigurationCardComponent implements OnInit {
  @ViewChild('threeView') three: ElementRef;

  whitelistFieldGroups: ITemplateFieldGroup[] = [
    {
      style: 'single',
      isTrailing: false,
      fields: [SimConfigFieldIds.Name],
    },
    {
      style: 'inline',
      isTrailing: false,
      borderBottom: true,
      fields: [SimConfigFieldIds.RobotType],
    },
    {
      style: 'inline',
      isTrailing: true,
      borderBottom: true,
      fields: [SimConfigFieldIds.FrameType],
    },
    {
      style: 'columns',
      isTrailing: false,
      borderBottom: true,
      fields: [
        SimConfigFieldIds.LiftkitType,
        SimConfigFieldIds.BasebracketHeight,
      ],
    },
    {
      style: 'columns',
      isTrailing: false,
      borderBottom: true,
      fields: [
        SimConfigFieldIds.GripperType,
        SimConfigFieldIds.OffsetBracketType,
      ],
    },
    {
      name: 'Primary conveyor',
      style: 'collapsable',
      isTrailing: false,
      groups: [
        {
          style: 'columns',
          isTrailing: false,
          borderBottom: true,
          fields: [
            SimConfigFieldIds.ConveyorPrimaryType,
            SimConfigFieldIds.ConveyorPrimaryGuideSide,
            SimConfigFieldIds.ConveyorPrimaryDirection,
            SimConfigFieldIds.ConveyorPrimaryGuideWidth,
          ],
        },
        {
          style: 'columns',
          fields: [
            SimConfigFieldIds.ConveyorPrimaryPositionY,
            SimConfigFieldIds.ConveyorPrimaryPositionX,
            SimConfigFieldIds.ConveyorPrimaryHeight,
          ],
        },
      ],
    },
    {
      name: 'Secondary conveyor',
      style: 'collapsable_togglable',
      enabledControl: SimConfigFieldIds.DualProductMode,
      isTrailing: false,
      groups: [
        {
          style: 'columns',
          isTrailing: false,
          borderBottom: true,
          fields: [
            SimConfigFieldIds.ConveyorSecondaryType,
            SimConfigFieldIds.ConveyorSecondaryGuideSide,
            SimConfigFieldIds.ConveyorSecondaryDirection,
            SimConfigFieldIds.ConveyorSecondaryGuideWidth,
          ],
        },
        {
          style: 'columns',
          fields: [
            SimConfigFieldIds.ConveyorSecondaryPositionY,
            SimConfigFieldIds.ConveyorSecondaryPositionX,
            SimConfigFieldIds.ConveyorSecondaryHeight,
          ],
        },
      ],
    },
    {
      name: 'Advanced',
      style: 'collapsable',
      groups: [
        {
          style: 'columns',
          borderBottom: true,
          fields: [SimConfigFieldIds.LiftkitMaxStroke],
        },
        {
          style: 'columns',
          fields: [
            SimConfigFieldIds.PalletSideOffset,
            SimConfigFieldIds.PalletEndOffset,
            SimConfigFieldIds.PalletVerticalOffset,
          ],
        },
      ],
    },
  ];

  mainCardContent$: Observable<DataRequestState<IHardwareContent>>;
  keepFields = false;
  threeID = uuid.v4();
  orgID: string;

  hardwareID$: Subject<string> = new ReplaySubject<string>(1);
  loading$: BehaviorSubject<boolean> = new BehaviorSubject(true);
  saving$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  saveScene$: Observable<any>;
  saveSceneAndUploadImage$: Observable<
    DataRequestState<IInsertSimulationResponse>
  >;
  croppedImage$: Observable<{ id: string; img: HTMLImageElement }>;
  triggerSave$: Subject<string> = new Subject();

  cameraPosition: THREE.Vector3;
  cameraRepositionConfirmation: Subject<boolean> = new Subject();
  imageProcessing: ImageProcessing = new ImageProcessing();

  mainCardActions$: Observable<IHardwareAction[]>;

  @Input() set hardwareID(id: string) {
    this.hardwareID$.next(id);
  }
  @Input() forceNew?: boolean;
  @Input() inputScene?: ISceneApiFileType;
  @Input() enableDefaultValueRestore?: boolean = false;
  @Input() returnChanges?: boolean = false;
  @Input() enableCancel?: boolean = false;
  @Input() disableActions?: boolean = false;
  @Output() sceneConfigUpdate: EventEmitter<any> = new EventEmitter();
  @Output() hardwareIDChange: EventEmitter<{
    id: string;
    name: string;
    updatedImage?: string;
  }> = new EventEmitter();
  @Output() cancel: EventEmitter<boolean> = new EventEmitter();

  couldGiveBasebracketReccomendation = true;

  constructor(
    public simConfigService: SimConfigService,
    private notification: NotificationService,
    private hardwareApi: HardwareApiService,
    private stateService: StateService,
    private assetApi: AssetApiService,
    private localstoreService: LocalStorageService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {
    if (this.inputScene) this.hardwareID$.next('edit');
    this.croppedImage$ = RenderTrigger.screenshotOutput$.pipe(
      take(1),
      // Need to wait for HTMLImageElement onload to be called before image processing
      switchMap((data) =>
        combineLatest([of(data), fromEvent(data.img, 'load')])
      ),
      // Crop
      map(([data, _]) => {
        let resizedImg = this.imageProcessing.crop(data.img, 930, 750);
        return { id: data.id, img: resizedImg };
      })
    );

    this.saveScene$ = this.triggerSave$.pipe(
      take(1),
      // Check if name is given
      filter((scene_name) => {
        if (!scene_name) {
          this.notification.showError('No name given');
          return false;
        } else {
          return true;
        }
      }),
      //Update/Insert new scene
      switchMap((name) => zip([this.hardwareID$, of(name)])),
      switchMap(([hardwareID, name]) => {
        return hardwareID === 'new' || this.forceNew
          ? this.hardwareApi.insertScene(
              name as string,
              toScene(this.simConfigService.getAllFields(this.threeID)),
              // Get orgId or undefined from route queryParams
              this.orgID
            )
          : this.hardwareApi.updateScene(
              hardwareID,
              name as string,
              '',
              toScene(
                this.simConfigService.getAllFields(this.threeID),
                hardwareID
              )
            );
      }),
      filter((response) => {
        if (response.id) {
          if (!this.forceNew) {
            this.hardwareID$.next(response.id);
          }
          return true;
        } else {
          this.notification.showError(`Save failed with unknown error`);
          return false;
        }
      })
    );

    // Fetch the changed/new hardware, get screenshot of the scene and upload it.
    // Attach asset id to scene
    this.saveSceneAndUploadImage$ = zip([
      this.saveScene$,
      this.croppedImage$,
    ]).pipe(
      tap(([scene_mutation_response, screenshot]) => {
        this.hardwareIDChange.emit({
          id: scene_mutation_response.id,
          name: scene_mutation_response.name,
          updatedImage: screenshot.img.src,
        });
      }),
      switchMap(([scene_mutation_response, screenshot]) => {
        if (scene_mutation_response?.image) {
          // update
          return zip([
            this.assetApi.updateAsset({
              id: scene_mutation_response.image.id,
              asset_type: 'scene_image',
              data: screenshot.img.src.substring(
                screenshot.img.src.indexOf('base64') + 7,
                screenshot.img.src.length
              ),
              file_type:
                '.' +
                screenshot.img.src.substring(
                  screenshot.img.src.indexOf('/') + 1,
                  screenshot.img.src.indexOf('base64,') - 1
                ),
              name: 'rc_' + scene_mutation_response.id,
            }),
            of(scene_mutation_response),
          ]);
        } else {
          // upload
          return zip([
            this.assetApi.uploadAsset({
              asset_type: 'scene_image',
              data: screenshot.img.src.substring(
                screenshot.img.src.indexOf('base64') + 7,
                screenshot.img.src.length
              ),
              file_type:
                '.' +
                screenshot.img.src.substring(
                  screenshot.img.src.indexOf('/') + 1,
                  screenshot.img.src.indexOf('base64,') - 1
                ),
              name: 'rc_' + scene_mutation_response.id,
              organization_id: this.orgID,
            }),
            of(scene_mutation_response),
          ]);
        }
      }),
      // Update scene with asset id
      switchMap(([upload_response, hw]) => {
        return this.hardwareApi.updateScene(
          hw.id,
          hw.name,
          '',
          toScene(this.simConfigService.getAllFields(this.threeID), hw.id),
          upload_response.id
        );
      }),
      toRequestState()
    );

    this.mainCardActions$ = combineLatest([
      this.hardwareID$.asObservable(),
      this.loading$,
      this.saving$,
    ]).pipe(
      map(([hardwareID, _loading, _saving]) => {
        const isNewHardware =
          hardwareID === 'new' || this.forceNew || this.keepFields;
        return [
          {
            label: isNewHardware ? 'ADD CONFIGURATION' : 'SAVE CONFIGURATION', //disable save/add before three-view has loaded
            value: Actions.SaveRobotStructure,
            filled: true,
            isBlue: true,
            icon: isNewHardware ? 'add' : undefined,
            disabled: false,
          },
        ];
      })
    );

    this.mainCardContent$ = this.hardwareID$.asObservable().pipe(
      switchMap((hardwareID) => {
        const sceneId =
          hardwareID === 'edit'
            ? null
            : hardwareID === 'new' || this.forceNew
            ? null
            : hardwareID;
        return this.simConfigService.configureScene(
          sceneId,
          whitelistedFields,
          threeFields,
          this.keepFields,
          this.threeID,
          this.inputScene
        );
      }),
      map(([fields, threeFields]) => {
        const fieldGroups = this.#groupFields(
          fields,
          this.whitelistFieldGroups
        );

        return {
          fieldGroups,
          threeFields,
          threeId: this.threeID,
          threeMode: ThreeHandlerMode.RobotConfig,
        };
      }),
      take(1),
      shareReplay({ bufferSize: 1, refCount: true }),
      toRequestState()
    );

    this.stateService
      .getCustomerOrSalesOrganization()
      .pipe(take(1))
      .subscribe((org: ApiOrganization) => {
        this.orgID = org.id;
      });

    /**
     * Open the custom gripper dialog automatically if not defined in localstore
     * and not previously selected.
     */
    this.simConfigService.update$
      .pipe(
        filter(
          (update: FieldUpdate) =>
            update?.fieldId === SimConfigFieldIds.GripperType &&
            update.newVal?.name === 'CUSTOM' &&
            update.prevVal?.name !== 'CUSTOM'
        ),
        tap(() => {
          const custom = this.localstoreService.getData(
            LocalStorageKey.CUSTOM_GRIPPER
          );
          // Has no custom gripper? have the user define one.
          if (!custom) {
            this.handleCustomGripperClick();

            // Already defined, apply the gripper values.
          } else {
            this.setCustomGripperValues(custom);
          }
        })
      )
      .subscribe();
    /**
     * Warning notification on none lifting column.
     */
    this.simConfigService.update$
      .pipe(
        filter(
          (update: FieldUpdate) =>
            (update?.fieldId === SimConfigFieldIds.LiftkitType &&
              update.newVal?.name === 'NONE' &&
              update.prevVal?.name !== 'NONE') ||
            update?.fieldId === SimConfigFieldIds.BasebracketHeight ||
            (update?.fieldId === SimConfigFieldIds.FrameType &&
              update.newVal?.name === 'NONE' &&
              update.prevVal?.name !== 'NONE')
        )
      )
      .subscribe(() => {
        // Your mintue isn't up, keep shut.
        if (!this.couldGiveBasebracketReccomendation) {
          return;
        }

        const frameField = this.simConfigService.getFieldForThreeView(
          SimConfigFieldIds.FrameType,
          this.threeID
        );
        const liftkitField = this.simConfigService.getFieldForThreeView(
          SimConfigFieldIds.LiftkitType,
          this.threeID
        );
        const basebracketField = this.simConfigService.getFieldForThreeView(
          SimConfigFieldIds.BasebracketHeight,
          this.threeID
        );

        let message: string;
        if (
          basebracketField.formControl.value <= 500 &&
          liftkitField.formControl.value.name === 'NONE'
        ) {
          const noneFrame = frameField.formControl.value.name === 'NONE';
          message = `${
            noneFrame
              ? 'Robot might not reach'
              : 'Robot might be inside the frame or might not reach'
          }. Recommend adding a mounting bracket or using frame with pedestal.`;
        }

        if (message) {
          this.notification.showMessage(message, 10000);
          this.couldGiveBasebracketReccomendation = false;
          // Can only report to the user every once every minute.
          setTimeout(
            () => (this.couldGiveBasebracketReccomendation = true),
            60 * 1000
          );
        }
      });
  }

  saveChanges() {
    if (this.returnChanges) {
      //Return scene without persisting changes to DB. Needed at SimulationRetryComponent.
      this.sceneConfigUpdate.emit(
        toScene(this.simConfigService.getAllFields(this.threeID))
      );
    } else {
      this.saveSceneAndUploadImage$.subscribe({
        next: (result) => this.saving$.next(result.isLoading),
        complete: () => this.saving$.next(false),
      });

      // Get name
      const nameField = this.simConfigService.getFieldForThreeView(
        SimConfigFieldIds.Name,
        this.threeID
      );
      this.triggerSave$.next(nameField?.getValue());
      this.triggerScreenshot();
    }
  }

  trackActionItem(index: number, item: any) {
    return JSON.stringify(item);
  }

  triggerScreenshot() {
    // Move camera to screenshot pose.
    this.cameraPosition = ObjectUtils.cloneObject(
      settings.view3d.hwImageCameraPosition
    );
    // Wait for camera repositioning confirmation before taking screenshot
    this.cameraRepositionConfirmation.pipe(take(1)).subscribe(() => {
      RenderTrigger.screenshotTrigger$.next(this.threeID);
    });
  }

  styleClassFromFieldGroup(fieldGroup: IFieldGroup): string {
    const stylemap: { [key in GroupStyle]: string } = {
      single: 'group-style-single',
      inline: '',
      rows: 'group-style-rows',
      columns: 'group-style-columns',
      collapsable: 'group-style-collapsable',
      collapsable_togglable: 'group-style-collapsable',
    };

    const classname = stylemap[fieldGroup.style];

    if (!classname) {
      return '';
    }

    return classname;
  }

  #groupFields(
    fields: Field[],
    groupLayout: ITemplateFieldGroup[]
  ): IFieldGroup[] {
    return groupLayout.map((groupLayout) => {
      const layout = {
        id: groupLayout.id,
        style: groupLayout.style,
        isTrailing: groupLayout.isTrailing,
        borderBottom: groupLayout.borderBottom,
        name: groupLayout.name,
        fields: groupLayout.fields
          ? groupLayout?.fields.map((fieldId) =>
              fields.find((field) => field?.id == fieldId)
            )
          : undefined,
        groups: groupLayout.groups
          ? this.#groupFields(fields, groupLayout.groups)
          : undefined,
      };
      if (groupLayout.enabledControl) {
        layout['enabledControl'] = fields.find(
          (field) => field?.id == groupLayout.enabledControl
        );
      }
      return layout;
    });
  }

  getSubMenuTitle(field: Field): string | null {
    if (
      field.id === SimConfigFieldIds.GripperType &&
      field.formControl.value?.name === 'CUSTOM'
    ) {
      const custom = this.localstoreService.getData(
        LocalStorageKey.CUSTOM_GRIPPER
      );
      return !custom ? 'Custom gripper?' : 'Edit custom gripper';
    }

    return null;
  }

  setCustomGripperValues(data: ICustomGripper): void {
    this.simConfigService
      .getFieldForThreeView(SimConfigFieldIds.GripperCustomLength, this.threeID)
      .formControl.setValue(data.custom_collision_box.length);
    this.simConfigService
      .getFieldForThreeView(SimConfigFieldIds.GripperCustomWidth, this.threeID)
      .formControl.setValue(data.custom_collision_box.width);
    this.simConfigService
      .getFieldForThreeView(SimConfigFieldIds.GripperCustomHeight, this.threeID)
      .formControl.setValue(data.custom_collision_box.height);
    this.simConfigService
      .getFieldForThreeView(
        SimConfigFieldIds.GripperCustomPositionX,
        this.threeID
      )
      .formControl.setValue(data.custom_offset_pose.position.x);
    this.simConfigService
      .getFieldForThreeView(
        SimConfigFieldIds.GripperCustomPositionY,
        this.threeID
      )
      .formControl.setValue(-data.custom_offset_pose.position.y);
  }

  /**
   * Note! For some reason context is lost when binding in the template
   * and ".bind(this)" fixes this.
   */
  handleCustomGripperClick = ((_e?: Event) => {
    const custom = this.localstoreService.getData(
      LocalStorageKey.CUSTOM_GRIPPER
    );
    this.dialogService
      .showCustomDialog(CustomGripperComponent, DialogSize.LARGE, null, {
        values: custom ? custom : undefined,
      })
      .afterClosed()
      .pipe(
        switchMap((data?: ISceneApiFileType['robot']['gripper']) => {
          // User cancelled
          if (!data) {
            return of(undefined);
          }

          this.setCustomGripperValues(data);

          return of(data);
        }),
        take(1)
      )
      .subscribe();
  }).bind(this);

  toggleFieldsAndClosePanel(
    enabled: boolean,
    planel: MatExpansionPanel,
    fieldGroup: IFieldGroup
  ): void {
    // TODO: Look in to why this isn't working.
    if (!enabled) {
      planel.close();
      if (fieldGroup.fields) {
        fieldGroup.fields.forEach((field) => {
          field.formControl.disable();
        });
      } else {
        fieldGroup.groups.forEach((group) => {
          this.toggleFieldsAndClosePanel(enabled, planel, group);
        });
      }
    } else {
      if (fieldGroup.fields) {
        fieldGroup.fields.forEach((field) => {
          field.formControl.enable();
        });
      } else {
        fieldGroup.groups.forEach((group) => {
          this.toggleFieldsAndClosePanel(enabled, planel, group);
        });
      }
    }
  }
}
