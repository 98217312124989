import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { ObjectUtils } from '../../utils/object';
import { ClientApiService } from './client-api.service';
import { ApiCalibration } from 'src/app/models_new/classes/api-models/ApiCalibration';
import { ApiInstalledRobot } from 'src/app/models_new/classes/api-models/ApiRobotInstallation';
import { ApiOrganization } from 'src/app/models_new/classes/api-models/ApiOrganization';
import { ApiRobotConfiguration } from 'src/app/models_new/classes/api-models/ApiRobotConfiguration';
import { ApiScene } from 'src/app/models_new/classes/api-models/ApiScene';

@Injectable({
  providedIn: 'root',
})
export class CalibrationsApiService {
  constructor(private clientApi: ClientApiService) {}

  /* ------------------------ Database query functions ------------------------ */

  fetchCalibrationByRobotId(
    robot_id: string,
    org_id: string
  ): Observable<ApiCalibration[]> {
    const q = gql`
      query GetCalibrationByRobotId {
        calibrations(where: { installed_robot_id: { _eq: "${robot_id}" }, organization_id: {_eq: "${org_id}"} }, order_by: {created_at: desc}) {
          id
          created_at
          data
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .query<{ calibrations: ApiCalibration[] }>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('GetCalibrationByRobotId failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get calibration: ' + data.errors[0].message
            );
          } else {
            return data.data.calibrations.map(
              (calibration) => new ApiCalibration(calibration)
            );
          }
        })
      );
  }

  subscribeCalibrationByRobotId(
    robot_id: string,
    org_id: string
  ): Observable<ApiCalibration[]> {
    const q = gql`
      subscription GetCalibrationByRobotId {
        calibrations(where: { installed_robot_id: { _eq: "${robot_id}" }, organization_id: {_eq: "${org_id}"} }, order_by: {created_at: desc}) {
          id
          updated_at
          data
          conveyor_type_1: data(path: "calibration.conveyors[0].type")
          conveyor_type_2: data(path: "calibration.conveyors[1].type")
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<{ calibrations: ApiCalibration[] }>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('GetCalibrationByRobotId failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get calibration: ' + data.errors[0].message
            );
          } else {
            return data.data.calibrations.map(
              (calibration) => new ApiCalibration(calibration)
            );
          }
        })
      );
  }

  fetchCalibrations(): Observable<ApiCalibration[]> {
    const q = gql`
      query GetFullCalibrations {
        calibrations {
          id
          organization {
            id
            name
          }
          installed_robot {
            id
            name
          }
          created_at
          updated_at
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .query<{ calibrations: ApiCalibration[] }>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('GetFullCalibrations failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get calibration: ' + data.errors[0].message
            );
          } else {
            const list = [];
            for (let i = 0, len = data.data.calibrations.length; i < len; i++) {
              const calibration = new ApiCalibration(
                ObjectUtils.cloneObject(data.data.calibrations[i])
              );
              calibration.installed_robot = new ApiInstalledRobot(
                calibration.installed_robot
              );
              calibration.organization = new ApiOrganization(
                calibration.organization
              );
              list.push(calibration);
            }
            return list;
          }
        })
      );
  }

  fetchCalibration(id: string): Observable<ApiCalibration> {
    const q = gql`
      query GetCalibrationByPK {
        calibrations_by_pk(id: "${id}") {
          id
          organization {
            id
            name
          }
          installed_robot {
            id
            name
            robot_configuration: installed_robots_robot_configuration {
              scene {
                image {
                  url
                }
              }
            }
          }
          created_at
          updated_at
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .query<{ calibrations_by_pk: ApiCalibration }>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('GetCalibrationByPK failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to get calibration: ' + data.errors[0].message
            );
          } else {
            const calibration = new ApiCalibration(
              ObjectUtils.cloneObject(data.data.calibrations_by_pk)
            );
            calibration.installed_robot = new ApiInstalledRobot(
              calibration.installed_robot
            );
            calibration.installed_robot.robot_configuration =
              new ApiRobotConfiguration(
                calibration.installed_robot.robot_configuration
              );
            calibration.installed_robot.robot_configuration.scene =
              new ApiScene(
                calibration.installed_robot.robot_configuration.scene
              );
            calibration.organization = new ApiOrganization(
              calibration.organization
            );
            return calibration;
          }
        })
      );
  }

  subscribeCalibrations(
    org_id?: string,
    type: 'lite' | 'full' = 'lite'
  ): Observable<ApiCalibration[]> {
    const ql = gql`
      subscription SubscribeCalibrations {
        calibrations${
          org_id ? `(where: {organization_id: {_eq: "${org_id}"}})` : ''
        } {
          id
          organization {
            id
            name
          }
          installed_robot {
            id
            name
            robot_serial_number
          }
          created_at
          updated_at
        }
      }
    `;
    const q = gql`
      subscription SubscribeCalibrations {
        calibrations${
          org_id ? `(where: {organization_id: {_eq: "${org_id}"}})` : ''
        } {
          id
          organization {
            id
            name
          }
          installed_robot {
            id
            name
          }
          created_at
          updated_at
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: type === 'lite' ? ql : q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('SubscribeCalibrations failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to subscribe to calibration: ' + data.errors[0].message
            );
          } else {
            const list = [];
            for (let i = 0, len = data.data.calibrations.length; i < len; i++) {
              const calibration = new ApiCalibration(
                ObjectUtils.cloneObject(data.data.calibrations[i])
              );
              calibration.installed_robot = new ApiInstalledRobot(
                calibration.installed_robot
              );
              calibration.organization = new ApiOrganization(
                calibration.organization
              );
              list.push(calibration);
            }
            return list;
          }
        })
      );
  }

  insertCalibrations(
    org_id: string,
    installed_robot_id: string,
    data: ApiCalibration['data']
  ): Observable<string> {
    const q = gql`
      mutation InsertCalibration(
        $organization_id: uuid!
        $data: json!
        $installed_robot_id: uuid!
      ) {
        insert_calibrations_one(
          object: {
            organization_id: $organization_id
            data: $data
            installed_robot_id: $installed_robot_id
          }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: q,
        variables: {
          organization_id: org_id,
          data: data,
          installed_robot_id: installed_robot_id,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('InsertCalibration failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to insert calibration: ' + data.errors[0].message
            );
          } else {
            return data.data.insert_calibrations_one.id;
          }
        })
      );
  }

  deleteCalibration(id: string): Observable<string> {
    const q = gql`
      mutation DeleteCalibration {
        delete_calibrations_by_pk(id: "${id}") {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.group('DeleteCalibration failed: ');
            data.errors.forEach((e) => {
              console.warn(e.message);
            });
            console.groupEnd();
            throw new Error(
              'Failed to delete calibration: ' + data.errors[0].message
            );
          } else {
            return data.data.delete_calibrations_by_pk.id;
          }
        })
      );
  }
}
