<app-split-card-table
  [tableData]="tableData$ | async"
  [actions]="actions"
  (actionClicked$)="actionClicked$.emit($event)"
  *ngIf="tableData$ | async"
>
  <div class="pally-version" *ngIf="swConfig$ | async as data">
    <img src="../../../../assets/icons/Pally_logo.png" alt="pally_logo" />
    <div class="version-text">
      <span *ngIf="data.data.software.pally_version === 'latest'">
        {{ data.data.software.pally_version }}
      </span>
      <span *ngIf="data.data.software.pally_version !== 'latest'">
        {{ 'V' + data.data.software.pally_version }}
      </span>
    </div>
  </div>
</app-split-card-table>
