import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { ModelUploadComponent } from '../model-upload/model-upload.component';
import { ModelType } from '../../../../models_new/classes/model-type';
import { Transform } from '../../../../models_new/classes/transform';
import { ViewChild } from '@angular/core';
import { CustomInterfaceAnimation } from '../../../../models_new/classes/custom-interface-animation';
import { ModelTypes } from '../../../../models_new/enums/model-types';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements AfterViewInit {
  @Input() modelUpload: ModelUploadComponent;
  @Input() animationsCustom: CustomInterfaceAnimation;

  @ViewChild('fileLabel') fileLabel: ElementRef;
  @ViewChild('subTitle') subTitle: ElementRef;
  @ViewChild('fileUploader') fileUploader: ElementRef;
  @ViewChild('mainTitle') mainTitle: ElementRef;
  @ViewChild('nameInput') nameInput: ElementRef;
  @ViewChild('inputLabel') inputLabel: ElementRef;
  @ViewChild('progressCounter') progressCounter: ElementRef;
  @ViewChild('progressBar') progressBar: ElementRef;

  modelTypes: ModelType[];
  modelTypesValue: string[];

  showOverlay: boolean;
  hasTyped: boolean;
  isLoaded: boolean;
  fileTooLarge: string;
  submitDisabled: boolean = false;

  constructor() {
    this.uploadFile = this.uploadFile.bind(this);
    this.onSelectPicker = this.onSelectPicker.bind(this);

    this.modelTypes = Object.values(ModelTypes);
    this.modelTypesValue = this.modelTypes.map((t) => t.name);
  }

  ngAfterViewInit(): void {
    if (this.modelUpload.generatorParams.file !== undefined) {
      this.isLoaded = true;
      this.fileLabel.nativeElement.innerHTML = `File selected: <i>${this.modelUpload.generatorParams.file.name}</i>`;
    }
    if (this.modelUpload.generatorParams.name !== undefined) {
      this.nameInput.nativeElement.value =
        this.modelUpload.generatorParams.name;
    }

    if (this.modelUpload.generatorParams.type !== undefined) {
      this.mainTitle.nativeElement.innerHTML = `Upload a ${this.modelUpload.generatorParams.type.name}`;
      this.inputLabel.nativeElement.innerHTML = `${this.modelUpload.generatorParams.type.name} Name`;
    }
  }

  onNameChange(event) {
    this.hasTyped = event.target.value !== '';
    this.modelUpload.generatorParams.name = event.target.value;
  }

  onSelectPicker(index: number) {
    this.selectType(this.modelTypes[index]);
  }

  onDragOver(evt) {
    this.showOverlay = true;
    evt.preventDefault();
  }

  onDropEnter(evt) {
    this.showOverlay = true;
    evt.preventDefault();
  }

  onDragLeave(evt) {
    this.showOverlay = false;
    evt.preventDefault();
  }

  onDrop(evt) {
    this.uploadFile(evt.dataTransfer.files);

    this.showOverlay = false;
    evt.preventDefault();
  }

  onUpload(evt) {
    this.uploadFile(evt.target.files);
  }

  hasUploaded() {
    return this.modelUpload.generatorParams.file !== undefined;
  }

  uploadAnotherFile() {
    this.fileUploader.nativeElement.value = '';
    this.fileUploader.nativeElement.click();
  }

  uploadFile(files: File[]) {
    this.isLoaded = false;

    const file = files[0];
    if (
      file === undefined ||
      (!file.name.toLowerCase().endsWith('.stp') &&
        !file.name.toLowerCase().endsWith('.step'))
    ) {
      this.fileTooLarge = 'This file is not a step file';
      this.modelUpload.generatorParams.file = undefined;
      return;
    }
    if (file.name.toLowerCase().endsWith('.stl') && file.size > 2097152) {
      this.fileTooLarge =
        'This file is too large. Maximum file size for .stl is 2MB';
      this.modelUpload.generatorParams.file = undefined;
      return;
    }
    if (file.name.toLowerCase().endsWith('.dae') && file.size > 9437184) {
      this.fileTooLarge =
        'This file is too large. Maximum file size for .dae is 9MB';
      this.modelUpload.generatorParams.file = undefined;
      return;
    }
    this.fileTooLarge = undefined;

    this.modelUpload.generatorParams.file = file;
    setTimeout(() => {
      this.progressBar.nativeElement.style.width = '0';
    }, 10);

    if (this.nameInput.nativeElement.value === '' || !this.hasTyped) {
      let name = files[0].name;
      const lastIndex = name.lastIndexOf('.');
      name = name.substring(0, lastIndex);
      this.nameInput.nativeElement.value = name;
      this.modelUpload.generatorParams.name = name;
    }

    this.fileLabel.nativeElement.innerHTML = `${files[0].name}`;

    this.isLoaded = true;
    return;

    //Unreachable
    this.modelUpload.resetCamera();

    let previousPercentage = 0;
    this.modelUpload.setupScene(
      (xhr) => {
        setTimeout(() => {
          let percentage: number = Math.floor(
            Math.max(0, (xhr.loaded / xhr.total) * 100 - 10)
          );
          percentage = Math.floor(percentage / 25) * 25;

          if (previousPercentage == percentage) {
            return;
          }
          previousPercentage = percentage;

          this.progressBar.nativeElement.style.width = percentage + '%';
          this.progressCounter.nativeElement.innerHTML = percentage + '%';
        }, 200);
      },
      () => {
        setTimeout(() => {
          this.progressBar.nativeElement.style.width = '100%';
          this.progressCounter.nativeElement.innerHTML = 'Done';
          this.isLoaded = true;
        }, 200);
      }
    );
  }

  nextClick() {
    if (
      !this.nameInput.nativeElement.value ||
      this.nameInput.nativeElement.value === ''
    ) {
      this.nameInput.nativeElement.focus();
      return;
    }
    if (this.isLoaded) {
      this.submitDisabled = true;
      this.progressCounter.nativeElement.innerHTML =
        'Please wait while we upload your model...';
      this.progressBar.nativeElement.style.display = 'none';
      this.modelUpload.nextClick();
    }
  }

  dropFile(event) {
    event.preventDefault();
  }

  selectType(type: ModelType) {
    this.modelUpload.generatorParams.type = type;

    this.modelUpload.generatorParams.transforms = [];
    for (let i = 0; i < this.modelUpload.generatorParams.type.pointCount; i++) {
      this.modelUpload.generatorParams.transforms.push(new Transform());
    }
  }
}
