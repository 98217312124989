<div class="positions">
  <button
    mat-icon-button
    (click)="subNavView.addLayer$.next(true)"
    matTooltip="Add new layer"
    [matTooltipPosition]="toolTipPosition"
  >
    <mat-icon>add</mat-icon>
  </button>
  <button
    *ngIf="hasSavedLayers() && lastLayerIsLayer()"
    mat-icon-button
    (click)="subNavView.duplicatePrevLayer$.next(true)"
    matTooltip="Duplicate top layer"
    [matTooltipPosition]="toolTipPosition"
  >
    <mat-icon>dynamic_feed</mat-icon>
  </button>
  <button
    *ngIf="hasSavedLayers() && lastLayerIsLayer()"
    mat-icon-button
    (click)="subNavView.rotatePrevLayer$.next(true)"
    matTooltip="Duplicate top layer, rotated"
    [matTooltipPosition]="toolTipPosition"
  >
    <mat-icon>rotate_right</mat-icon>
  </button>
  <button
    *ngIf="hasSavedLayers() && lastLayerIsLayer()"
    mat-icon-button
    (click)="subNavView.mirrorPrevLayerVert$.next(true)"
    matTooltip="Duplicate top layer, mirrored vertically"
    [matTooltipPosition]="toolTipPosition"
  >
    <mat-icon>swap_vert</mat-icon>
  </button>

  <button
    *ngIf="hasSavedLayers() && lastLayerIsLayer()"
    mat-icon-button
    (click)="subNavView.mirrorPrevLayerHoriz$.next(true)"
    matTooltip="Duplicate top layer, mirrored horizontally"
    [matTooltipPosition]="toolTipPosition"
  >
    <mat-icon>swap_horiz</mat-icon>
  </button>
  <button
    *ngIf="shimPaperInProject && palletPosition === 1"
    mat-icon-button
    (click)="subNavView.addShimpaper$.next(true)"
    matTooltip="Add shim paper"
    [matTooltipPosition]="toolTipPosition"
  >
    <mat-icon>filter_none</mat-icon>
  </button>
</div>
