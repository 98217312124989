import { IApiCalibration } from 'src/app/models_new/classes/api-models/ApiCalibration';
import { IApiGenerateWaypoint } from 'src/app/models_new/classes/api-models/ApiGeneratedWaypoint';
import { pagesPATH } from 'src/app/models_new/config/pages';
import { ISortingOption } from 'src/app/models_new/types/sorting-option';
import {
  InvetoryTableAction,
  InventoryTableDisplayColumn,
  InventoryTableCta,
} from '../../gui/inventory-table/inventory-table.component';
import { ISimulationTableItem } from 'src/app/services/api/simulation-api.service';
import { FilterType, IFilterData } from 'src/app/models_new/types/filter-data';

const displaySpecs: string[] = [
  'name',
  'robot_brand',
  'robot_type',
  'robot_serial_number',
  'pally_version',
  'os_version',
  'created_at',
  'updated_at',
];

const rowActionsPallyPrograms: InvetoryTableAction[] = [
  {
    label: 'Delete',
    actionId: 'delete_waypoints',
    roleAction: 'delete_waypoints',
    color: 'warn',
    icon: 'delete',
  },
];

const displayedPallyProgramsColumns: InventoryTableDisplayColumn[] = [
  {
    label: 'Name',
    path: 'name',
    sortType: 'string',
  },
  {
    label: 'Status',
    path: 'simulation_state',
    sortType: 'string',
    specialCell: [
      {
        type: 'badge-n-gauge',
        color: (row: IApiGenerateWaypoint) => {
          if (row.simulation_state === 'Error') {
            return 'warn';
          } else if (
            row.simulation_state !== 'Successful' &&
            row.simulation_state !== 'Finished'
          ) {
            return 'primary';
          } else {
            return 'accent';
          }
        },
        paths: ['progress', 'simulation_state'],
      },
    ],
  },
  {
    label: 'Pattern',
    path: 'pattern.name',
    sortType: 'string',
    specialCell: [
      {
        type: 'link',
        link: (e) => {
          return '/' + pagesPATH.PATTERNS + '/' + e.pattern.id;
        },
      },
    ],
  },
  {
    label: 'Product',
    path: 'pattern.product.name',
    sortType: 'string',
    specialCell: [
      {
        type: 'link',
        link: (e) => {
          return '/' + pagesPATH.PRODUCTS + '/' + e.pattern.product.id;
        },
      },
    ],
  },
  {
    label: 'Updated At',
    path: 'updated_at',
    sortType: 'date',
  },
];

const sortingColumnsPallyPrograms: ISortingOption[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'simulation_state',
    label: 'Status',
  },
  {
    id: 'pattern',
    label: 'Pattern',
  },
  {
    id: 'product',
    label: 'Product',
  },
  {
    id: 'updated_at',
    label: 'Updated At',
  },
];

const rowCtaPallyPrograms: InventoryTableCta[] = [
  {
    label: 'Export',
    actionId: 'download_waypoints',
    icon: 'download',
    roleAction: 'download_waypoints',
  },
];

const rowActionsCalibrations: InvetoryTableAction[] = [
  // TODO: Delete calibration not allowed backend for org_edit
  // {
  //   label: 'Delete',
  //   actionId: 'delete_calibrations',
  //   roleAction: 'delete_calibrations',
  //   color: 'warn',
  //   icon: 'delete',
  // },
];

const displayedCalibrationsColumns: InventoryTableDisplayColumn[] = [
  {
    label: 'Name',
    path: 'name',
    sortType: 'string',
  },
  {
    label: 'Status',
    path: 'active',
    sortType: 'string',
    specialCell: [
      {
        type: 'badge',
        color: (row: IApiCalibration) => {
          return row.active === 'Active' ? 'accent' : 'primary';
        },
      },
    ],
  },
  {
    label: 'Pally version',
    path: 'data.file_info.pally_version',
  },
  {
    label: 'Serial no.',
    path: 'data.file_info.serial_no',
  },
  {
    label: 'Conveyor 1',
    path: 'conveyor_type_1',
  },
  {
    label: 'Conveyor 2',
    path: 'conveyor_type_2',
  },
  {
    label: 'Uploaded At',
    path: 'updated_at',
    sortType: 'date',
  },
];

const sortingColumnsCalibrations: ISortingOption[] = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'active',
    label: 'Active',
  },
  {
    id: 'conveyor_type_1',
    label: 'Conveyor 1',
  },
  {
    id: 'conveyor_type_2',
    label: 'Conveyor 2',
  },
  {
    id: 'gripper_type',
    label: 'Gripper',
  },
  {
    id: 'updated_at',
    label: 'Uploaded At',
  },
];

const rowCtaCalibrations: InventoryTableCta[] = [
  {
    label: 'Export',
    actionId: 'download_calibrations',
    icon: 'download',
    roleAction: 'download_calibrations',
  },
];

const rowActionsSimulations: InvetoryTableAction[] = [
  {
    label: 'Retry',
    actionId: 'retry',
    roleAction: 'play_simulation',
    icon: 'refresh',
    divideAfter: true,
    disabled: (row: ISimulationTableItem) => row.simulation_state === 'Error',
  },
  {
    label: 'Pattern',
    actionId: 'get_pattern_json',
    roleAction: 'download_json_pattern',
    icon: 'download',
    divideAfter: true,
  },
  {
    label: 'Delete',
    actionId: 'delete',
    roleAction: 'delete_simulation',
    color: 'warn',
    icon: 'delete',
  },
];

const displayedSimulationsColumns: InventoryTableDisplayColumn[] = [
  { label: 'Name', path: 'nameSlice', sortType: 'string' },
  {
    label: 'Status',
    path: 'simulation_state',
    sortType: 'string',
    specialCell: [
      {
        type: 'badge-n-gauge',
        color: (row: ISimulationTableItem) => {
          if (row.simulation_state === 'Error') {
            return 'warn';
          } else if (row.simulation_state !== 'Successful') {
            return 'primary';
          } else {
            return 'accent';
          }
        },
        paths: ['simulation_status.progress', 'simulation_state'],
      },
    ],
  },
  {
    label: 'Cpm (req.)',
    path: 'inventoryCpmCalc',
    sortType: 'number',
  },
  {
    label: 'Patterns',
    path: 'patterns',
    specialCell: [
      {
        type: 'link',
        link: (e: { name: string; id: string }) =>
          `/${pagesPATH.PATTERNS}/${e.id}`,
      },
    ],
  },
  {
    label: 'Hardware',
    path: 'scene.name',
    sortType: 'string',
    specialCell: [
      {
        type: 'link',
        link: (row) =>
          `/${pagesPATH.INVENTORY}/${pagesPATH.HARDWARE_CONFIGURATIONS}/${row.scene?.id}`,
      },
    ],
  },
  {
    label: 'Software',
    path: 'strategy.name',
    sortType: 'string',
    specialCell: [
      {
        type: 'link',
        link: (row) =>
          `/${pagesPATH.INVENTORY}//${pagesPATH.SOFTWARE_LIST}/${row.strategy?.id}`,
      },
    ],
  },
  { label: 'Updated At', path: 'updated_at', sortType: 'date' },
];

const rowCtaSimulations: InventoryTableCta[] = [
  {
    label: 'Repair',
    roleAction: 'play_simulation',
    actionId: 'retry',
    icon: 'build',
    disabled: (row: ISimulationTableItem) => row.simulation_state !== 'Error',
  },
];

const sortingColumnsSimulations: ISortingOption[] = [
  {
    id: 'nameSlice',
    label: 'Name',
  },
  {
    id: 'pattern.name',
    label: 'Pattern',
  },
  {
    id: 'simulation_state',
    label: 'Status',
  },
  {
    id: 'simulation_status.progress',
    label: 'Progress',
  },
  {
    id: 'simulation_status.calculated.average_pallet_time_formatted',
    label: 'Avg. minutes per pallet',
  },
  {
    id: 'updated_at',
    label: 'Updated at',
  },
];

const resultFilterSimulations: IFilterData[] = [
  {
    filterType: FilterType.SELECT,
    id: 'simulation_state',
    label: 'Simulation State',
    options: [
      {
        label: 'Queued',
        id: 'Queued',
      },
      {
        label: 'Running',
        id: 'Running',
      },
      {
        label: 'Finished',
        id: 'Finished',
      },
      {
        label: 'Successful',
        id: 'Successful',
      },
      {
        label: 'Error',
        id: 'Error',
      },
      {
        label: 'Cancelled',
        id: 'Cancelled',
      },
      {
        label: 'Initializing',
        id: 'Initializing',
      },
      {
        label: 'Created',
        id: 'Created',
      },
      {
        label: 'Setting up',
        id: 'Setting_up',
      },
      {
        label: 'Starting robot',
        id: 'Starting_robot',
      },
      {
        label: 'Palletizing',
        id: 'Palletizing',
      },
    ],
  },
];

export const tableConfigurations = {
  displaySpecs: displaySpecs,
  rowActionsPallyPrograms: rowActionsPallyPrograms,
  displayedPallyProgramsColumns: displayedPallyProgramsColumns,
  sortingColumnsPallyPrograms: sortingColumnsPallyPrograms,
  rowCtaPallyPrograms: rowCtaPallyPrograms,
  rowActionsCalibrations: rowActionsCalibrations,
  displayedCalibrationsColumns: displayedCalibrationsColumns,
  sortingColumnsCalibrations: sortingColumnsCalibrations,
  rowCtaCalibrations: rowCtaCalibrations,
  rowActionsSimulations: rowActionsSimulations,
  displayedSimulationsColumns: displayedSimulationsColumns,
  sortingColumnsSimulations: sortingColumnsSimulations,
  rowCtaSimulations: rowCtaSimulations,
  resultFilterSimulations: resultFilterSimulations,
};

// Optional of individual objects for flexibility
export {
  displaySpecs,
  rowActionsPallyPrograms,
  displayedPallyProgramsColumns,
  sortingColumnsPallyPrograms,
  rowCtaPallyPrograms,
  rowActionsCalibrations,
  displayedCalibrationsColumns,
  sortingColumnsCalibrations,
  rowCtaCalibrations,
  rowActionsSimulations,
  displayedSimulationsColumns,
  sortingColumnsSimulations,
  rowCtaSimulations,
  resultFilterSimulations,
};
