import { Injector } from '@angular/core';
import * as THREE from 'three';
import { ReplaySubject } from 'rxjs';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { SimConfigFieldIds } from 'src/app/models_new/enums/simconfig-field-ids';
import { Type } from 'src/app/utils/type';
import { milliToMeter } from '../../../../utils/div';

export class PalletTask extends Task {
  static [taskNameSymbol] = 'Pallet';

  side: 'RIGHT' | 'LEFT';
  axis: string;

  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public init(data: any): void {
    super.init(data);
    this.side = this.data.fieldId.includes('place_targets.[name:p1]')
      ? 'RIGHT'
      : 'LEFT';
    this.axis = this.data.fieldId.split('.position.')[1];
  }

  public operation(resolve: () => void, _reject: (reason?: any) => void): void {
    this.updatePallet();

    const isRight = this.isRIGHT();
    const offset = this.readPalletOffset(isRight);
    if (isRight) {
      switch (this.axis) {
        case 'x':
          this.activateDimVis(
            SimConfigFieldIds.PalletRightPositionX,
            {
              value: offset.x,
            },
            this.readShowVisualizers() ? -1 : undefined
          );
          break;
        case 'z':
          this.activateDimVis(
            SimConfigFieldIds.PalletRightPositionZ,
            {
              value: offset.z,
            },
            this.readShowVisualizers() ? -1 : undefined
          );
          break;
      }
    } else {
      switch (this.axis) {
        case 'x':
          this.activateDimVis(
            SimConfigFieldIds.PalletLeftPositionX,
            {
              value: offset.x,
            },
            this.readShowVisualizers() ? -1 : undefined
          );
          break;
      }
    }

    resolve();
  }

  public updatePallet(): void {
    const isRight = this.isRIGHT();

    const palletJoint = this.robot.getJointByID(
      this.side === 'RIGHT' ? JointNames.PalletRight : JointNames.PalletLeft
    );
    const palletLink = URDFUtils.findLinkFromJoint(palletJoint);

    const offset = this.readPalletOffset(isRight);
    const rotation = this.readPalletRotation(isRight);

    palletJoint.position.copy(offset);

    palletLink.quaternion.copy(rotation);
  }

  protected isRIGHT(): boolean {
    return this.side === 'RIGHT';
  }

  protected readPalletOffset(
    isRight: boolean,
    offset?: THREE.Vector3
  ): THREE.Vector3 {
    const tempOffset: Array<any> = this.readFields([
      isRight
        ? SimConfigFieldIds.PalletRightPositionX
        : SimConfigFieldIds.PalletLeftPositionX,
      isRight
        ? SimConfigFieldIds.PalletRightPositionY
        : SimConfigFieldIds.PalletLeftPositionY,
      isRight
        ? SimConfigFieldIds.PalletRightPositionZ
        : SimConfigFieldIds.PalletLeftPositionZ,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempOffset.length; i++) {
      if (tempOffset[i] === -0 || tempOffset[i] === '-0') {
        tempOffset[i] = 0;
      } else if (Type.isOfType(tempOffset[i], 'string')) {
        tempOffset[i] = +tempOffset[i];
      }
    }

    const o = new THREE.Vector3(
      milliToMeter(tempOffset[0]),
      /*
      NOTE! This field get's corrected to 0 by default but
      the value is actually in relation to -600.
      */
      milliToMeter(-600 + tempOffset[1]),
      milliToMeter(tempOffset[2])
    );

    if (Type.isDefined(offset)) {
      offset.copy(o);
    }

    return o;
  }

  protected readPalletRotation(
    isRight: boolean,
    rotation?: THREE.Quaternion
  ): THREE.Quaternion {
    const tempRot: Array<any> = this.readFields([
      isRight
        ? SimConfigFieldIds.PalletRightRotationX
        : SimConfigFieldIds.PalletLeftRotationX,
      isRight
        ? SimConfigFieldIds.PalletRightRotationY
        : SimConfigFieldIds.PalletLeftRotationY,
      isRight
        ? SimConfigFieldIds.PalletRightRotationZ
        : SimConfigFieldIds.PalletLeftRotationZ,
      isRight
        ? SimConfigFieldIds.PalletRightRotationW
        : SimConfigFieldIds.PalletLeftRotationW,
    ]);

    // To mitigate "-0" which messes with later calculations
    for (let i = 0; i < tempRot.length; i++) {
      if (tempRot[i] === -0 || tempRot[i] === '-0') {
        tempRot[i] = 0;
      } else if (Type.isOfType(tempRot[i], 'string')) {
        tempRot[i] = +tempRot[i];
      }
    }

    const rot = new THREE.Quaternion(
      tempRot[0],
      tempRot[1],
      tempRot[2],
      tempRot[3]
    );

    if (Type.isDefined(rotation)) {
      rotation.copy(rot);
    }

    return rot;
  }
}
