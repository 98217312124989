<ng-container *ngIf="mainCardContent$ | async as mainCardContent">
  <!-- Loading state -->
  <app-loading *ngIf="mainCardContent.isLoading"></app-loading>

  <!-- Error state -->
  <app-error *ngIf="mainCardContent.error as error" [error]="error"></app-error>

  <app-split-card
    [divideEqually]="true"
    *ngIf="mainCardContent.didSucceed && mainCardContent.value as content"
  >
    <div split-card-content style="padding-top: 1em">
      <div *ngIf="content && !content.missingContentText" class="split-grid">
        <ng-container
          [ngTemplateOutlet]="fieldGroupsTemplate"
          [ngTemplateOutletContext]="{ fieldGroups: content.fieldGroups }"
        ></ng-container>
      </div>
      <ng-container *ngIf="content && content.missingContentText">
        {{ content.missingContentText }}
      </ng-container>
      <span style="margin-bottom: 10px" *ngIf="content && content.underText">{{
        content.underText
      }}</span>
      <span style="font-weight: bold" *ngIf="content && content.boldText">{{
        content.boldText
      }}</span>
      <ng-container *ngIf="!disableActions">
        <mat-card-actions
          *ngIf="
            !content.missingContentText && mainCardActions$ | async as actions
          "
        >
          <ng-container *ngFor="let a of actions; trackBy: trackActionItem">
            <app-button
              *ngIf="a.filled"
              [disabled]="a.disabled"
              [type]="'save'"
              [label]="a.label"
              [icon]="a.icon"
              [iconPosition]="a.iconPosition || 'after'"
              (buttonClick)="saveChanges()"
            >
            </app-button>
            <app-button
              *ngIf="!a.filled"
              [disabled]="a.disabled"
              [type]="'add'"
              [label]="a.label"
              [icon]="a.icon"
              [iconPosition]="a.iconPosition || 'after'"
              (buttonClick)="saveChanges()"
            >
            </app-button>
          </ng-container>
          <app-button
            *ngIf="enableCancel"
            type="add"
            label="Cancel"
            icon="none"
            (buttonClick)="cancel.emit(true)"
          >
          </app-button>
        </mat-card-actions>
      </ng-container>
    </div>
    <div split-card-artwork>
      <div
        class="two-split-card-3d-view"
        *ngIf="
          content.threeMode && content.threeId && !content.missingContentText
        "
        style="display: flex; align-items: center; justify-content: center"
      >
        <app-three-view
          style="width: 100%; height: 100%"
          [mode]="content.threeMode"
          [ID]="content.threeId"
          [choosePerspective]="content.allowPerspective"
          [cameraPosition]="cameraPosition"
          (cameraRepositioned)="cameraRepositionConfirmation.next($event)"
          (loading)="loading$.next($event)"
        ></app-three-view>
        <div class="three-view-settings">
          <mat-card appearance="outlined" style="padding: 8px">
            <app-field
              [id]="field.id"
              *ngFor="let field of content.threeFields"
              [highlightModified]="enableDefaultValueRestore"
              [field]="field"
            ></app-field>
          </mat-card>
        </div>
      </div>
      <div
        class="two-split-card-image"
        *ngIf="content.img && !content.threeId"
        [style.background-image]="'url(' + content.img + ')'"
      ></div>
    </div>
  </app-split-card>
</ng-container>

<ng-template #fieldGroupsTemplate let-fieldGroups="fieldGroups">
  <div
    *ngFor="let fieldGroup of fieldGroups"
    style="position: relative"
    [ngClass]="styleClassFromFieldGroup(fieldGroup)"
  >
    <ng-container
      *ngIf="fieldGroup.fields?.length > 0"
      [ngTemplateOutlet]="fieldGroupTemplate"
      [ngTemplateOutletContext]="{ fieldGroup: fieldGroup }"
    ></ng-container>

    <ng-container *ngIf="fieldGroup.groups?.length > 0">
      <ng-container
        *ngIf="
          fieldGroup.style === 'collapsable_togglable' &&
            fieldGroup.enabledControl;
          else expandableGroup
        "
      >
        <mat-expansion-panel
          #panel
          class="expandable"
          [disabled]="!fieldGroup.enabledControl.formControl.value"
        >
          <mat-expansion-panel-header>
            <mat-panel-title *ngIf="fieldGroup.name">
              {{ fieldGroup.name }}
            </mat-panel-title>
            <mat-panel-description class="togglable-description">
              <app-field
                [id]="fieldGroup.enabledControl.id"
                [field]="fieldGroup.enabledControl"
                (fieldChange)="
                  toggleFieldsAndClosePanel(
                    $event.newVal,
                    panel,
                    fieldGroup.groups
                  )
                "
              >
              </app-field>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ng-container
            [ngTemplateOutlet]="fieldGroupsTemplate"
            [ngTemplateOutletContext]="{ fieldGroups: fieldGroup.groups }"
          ></ng-container>
        </mat-expansion-panel>
      </ng-container>

      <ng-template #expandableGroup>
        <mat-expansion-panel class="expandable">
          <mat-expansion-panel-header>
            <mat-panel-title *ngIf="fieldGroup.name">
              {{ fieldGroup.name }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <ng-container
            [ngTemplateOutlet]="fieldGroupsTemplate"
            [ngTemplateOutletContext]="{ fieldGroups: fieldGroup.groups }"
          ></ng-container>
        </mat-expansion-panel>
      </ng-template>
      <div *ngIf="fieldGroup.groups?.length === 0" class="borderBottom"></div>
    </ng-container>
  </div>
</ng-template>

<ng-template #fieldGroupTemplate let-fieldGroup="fieldGroup">
  <app-field
    *ngFor="let field of fieldGroup.fields"
    [ngClass]="field"
    [id]="field.id"
    [field]="field"
    [highlightModified]="enableDefaultValueRestore"
    [subMenuTitle]="getSubMenuTitle(field)"
    [subMenuHandler]="handleCustomGripperClick"
  >
  </app-field>

  <div class="borderBottom" *ngIf="fieldGroup.borderBottom"></div>
  <ng-container
    *ngIf="
      !fieldGroup.isTrailing &&
      (fieldGroup.style === 'rows' || fieldGroup.style === 'inline')
    "
  >
    <div
      class="extendedBorderBottom"
      *ngIf="fieldGroup.noBottomBorder !== false"
    ></div>
    <div class="borderRight"></div>
  </ng-container>
</ng-template>
