import { compareVersions, validate } from 'compare-versions';
import { IPallyFileType } from '../models_new/types/pally-file-type';
import { LayerType } from '../models_new/enums/layer-type';

export function newestVersion(versions: string[]): string | null {
  let highest = '0';
  for (const v of versions) {
    if (validate(v) && compareVersions(v, highest) > 0) {
      highest = v;
    }
  }
  return !highest || highest === '0' ? null : highest;
}

export function meterToMilli(value: number): number {
  return value * 1000;
}

export function milliToMeter(value: number): number {
  return value / 1000;
}

export function fracToPercent(value: number): number {
  return value * 100;
}

export function percentToFrac(value: number): number {
  return value / 100;
}

export function floatWithinTolerance(
  value: number,
  target: number,
  tolerance: number
): boolean {
  const temp = value - target;
  return temp <= tolerance && temp >= -tolerance;
}

export function validateArrayIndex(
  arrayOrLength: Array<any> | number,
  index: number
): boolean {
  if (Array.isArray(arrayOrLength)) {
    return index >= 0 && index < arrayOrLength.length;
  } else {
    return index >= 0 && index < arrayOrLength;
  }
}

export function calcPalletEdgeOffset(
  width: number,
  length: number
): {
  left: number;
  right: number;
  front: number;
  back: number;
} {
  return {
    left: -(width / 2),
    right: width / 2,
    front: length / 2,
    back: -(length / 2),
  };
}

export function calcTotalPalletLoadHeightFromPallyFile(
  data: IPallyFileType
): number {
  let height = 0;
  for (const lname of data.layers) {
    const layer = data.layerTypes.find((l) => l.name === lname);
    if (layer.class === LayerType.SHIMPAPER) {
      height += layer.height;
    } else {
      height += data.productDimensions.height;
    }
  }
  return height;
}

export function isUUIDv4(input: string): boolean {
  const uuidv4Pattern =
    /^[0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[8|9|aA|bB][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidv4Pattern?.test(input);
}

export function sanitizeUploadNames(name: string): string {
  // Remove UUIDv4 + underscore.
  let matches = name.match(
    /.*([0-9a-f]{8}-[0-9a-f]{4}-4[0-9a-f]{3}-[89aAbB][0-9a-f]{3}-[0-9a-f]{12}).*/i
  );
  if (matches.length > 1) {
    name = name.substring(
      matches[1].length + 1, // Includes also "_"
      name.length - 1
    );
  }

  // Remove date + underscore.
  matches = name.match(/^(.+)_[^_]*$/);
  if (matches.length > 1) {
    name = matches[1];
  }

  return name;
}

/**
 * Runs the given function a set number of times at the given interval.
 * @param func Function - Callback function.
 * @param interval number - Interval to call the function at.
 * @param times number - Number of times to call the function.
 *
 * @returns NodeJS.Timeout - Reference to the internal timer so you can cancel with clearInterval();
 */
export function setCountedInterval(
  func: () => void,
  interval: number,
  times: number
): any {
  let counter = 0;
  const limitInterval = setInterval(() => {
    func();
    counter++;
    if (counter === times) {
      clearInterval(limitInterval);
    }
  }, interval);
  return limitInterval;
}
