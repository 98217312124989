import { NavbarItemType } from '../enums/advanced-navbar-button-item-type';
import { INavbarItem } from '../types/navbar-item';
import { pagesPATH } from './pages';
import { RobotBrandType } from '../enums/robot-brand-types';

export const mainMenuCustomer: INavbarItem[] = [
  {
    label: 'Production',
    type: NavbarItemType.LABEL,
    children: [
      {
        label: 'Products',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.PRODUCTS],
        activeSelector: pagesPATH.PRODUCTS,
        customIcon: 'product',
      },
      {
        label: 'Patterns',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.PATTERNS],
        activeSelector: pagesPATH.PATTERNS,
        customIcon: 'pattern',
      },
      {
        label: 'Simulations',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.SIMULATIONS],
        activeSelector: pagesPATH.SIMULATIONS,
        customIcon: 'simulation',
        restrictToBrands: [RobotBrandType.UR],
      },
      {
        label: 'Installed Robots',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.INSTALLED_ROBOTS],
        activeSelector: pagesPATH.INSTALLED_ROBOTS,
        customIcon: 'robot',
      },
      {
        label: 'Pally programs',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.WAYPOINTS],
        activeSelector: pagesPATH.WAYPOINTS,
        customIcon: 'software_configuration',
        restrictToBrands: [RobotBrandType.DOOSAN],
      },
      {
        label: 'Pally program settings',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.PALLY_PATH_STRATEGIES],
        activeSelector: pagesPATH.PALLY_PATH_STRATEGIES,
        customIcon: 'software_configuration',
        restrictToBrands: [RobotBrandType.DOOSAN],
      },
    ],
  },
  {
    label: 'Inventory',
    type: NavbarItemType.LABEL,
    restrictToBrands: [RobotBrandType.UR],
    children: [
      {
        label: 'Robot configurations',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.ROBOT_CONFIGURATIONS],
        activeSelector: pagesPATH.ROBOT_CONFIGURATIONS,
        customIcon: 'robot_configuration',
        restrictToBrands: [RobotBrandType.UR],
      },
      {
        label: 'Software configurations',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.SOFTWARE_LIST],
        activeSelector: pagesPATH.SOFTWARE_LIST,
        needQuery: true,
        customIcon: 'software_configuration',
        restrictToBrands: [RobotBrandType.UR],
      },
      {
        label: 'Hardware configurations',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.HARDWARE_CONFIGURATIONS],
        activeSelector: pagesPATH.HARDWARE_CONFIGURATIONS,
        needQuery: true,
        customIcon: 'hardware_configuration',
        restrictToBrands: [RobotBrandType.UR],
      },
    ],
  },
  {
    label: 'Organization',
    type: NavbarItemType.LABEL,
    children: [
      {
        label: 'Members',
        type: NavbarItemType.BUTTON,
        path: [
          pagesPATH.ORGANIZATIONS,
          '{organization_id}',
          pagesPATH.ORG_MEMBERS,
        ],
        activeSelector: pagesPATH.ORG_MEMBERS,
        materialIcon: 'people_outline',
      },
      {
        label: 'View organization',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.CUSTOMERS, pagesPATH.VIEW_ORG, '{organization_id}'],
        activeSelector: pagesPATH.VIEW_ORG,
        roleAction: 'update_organization',
        queryParams: { edit: true },
        materialIcon: 'people_outline',
      },
    ],
  },
];

export const mainMenuSales: INavbarItem[] = [
  {
    label: 'Inventory',
    type: NavbarItemType.BUTTON,
    restrictToBrands: [RobotBrandType.UR],
    children: [
      {
        label: 'Robot configurations',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.ROBOT_CONFIGURATIONS],
        customIcon: 'robot_configuration',
        restrictToBrands: [RobotBrandType.UR],
      },
      {
        label: 'Software configurations',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.SOFTWARE_LIST],
        needQuery: true,
        customIcon: 'software_configuration',
        restrictToBrands: [RobotBrandType.UR],
      },
      {
        label: 'Hardware configurations',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.HARDWARE_CONFIGURATIONS],
        needQuery: true,
        customIcon: 'hardware_configuration',
        restrictToBrands: [RobotBrandType.UR],
      },
    ],
  },
  {
    label: 'Organization',
    type: NavbarItemType.BUTTON,
    children: [
      {
        label: 'Members',
        type: NavbarItemType.BUTTON,
        path: [
          pagesPATH.ORGANIZATIONS,
          '{organization_id}',
          pagesPATH.ORG_MEMBERS,
        ],
        materialIcon: 'people_outline',
      },
      {
        label: 'Edit organization',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.CUSTOMERS, pagesPATH.VIEW_ORG, '{organization_id}'],
        roleAction: 'update_organization',
        queryParams: { edit: true },
        materialIcon: 'edit',
      },
      {
        label: 'Customers',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.INVENTORY, pagesPATH.CUSTOMERS],
        materialIcon: 'real_estate_agent',
      },
    ],
  },
  {
    label: 'Fast Track',
    type: NavbarItemType.LABEL,
    children: [
      {
        label: 'Leads',
        type: NavbarItemType.BUTTON,
        path: [pagesPATH.ORGANIZATIONS, pagesPATH.LEADS],
        materialIcon: 'request_quote',
        needQuery: true,
        disabled: true,
      },
    ],
  },
];
