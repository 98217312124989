import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PatternRoutingModule } from './pattern-routing.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '../material.module';
import { SharedModule } from '../shared.module';
import { ThreeModule } from '../three.module';
import { PatternsWrapperComponent } from '../../components/patterns/patterns-wrapper.component';
import { ProductModule } from '../product/product.module';
import { NewPatternComponent } from 'src/app/components/patterns/pattern/new-pattern/new-pattern.component';

@NgModule({
  imports: [
    CommonModule,
    PatternRoutingModule,
    SharedModule,
    ThreeModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    DragDropModule,
    ProductModule,
  ],
  declarations: [PatternsWrapperComponent, NewPatternComponent],
  exports: [PatternsWrapperComponent, NewPatternComponent],
})
export class PatternModule {}
