<ng-container *ngIf="init$ | async as init">
  <app-loading *ngIf="init.isLoading"></app-loading>
  <app-error *ngIf="init.error as error" [error]="error"></app-error>

  <ng-container *ngIf="init.didSucceed">
    <div class="stepper-wrapper">
      <mat-stepper
        orientation="horizontal"
        [selectedIndex]="0"
        [linear]="true"
        #stepper
      >
        <mat-step
          [stepControl]="stepFormGroup.get('product')"
          errorMessage="Select a product"
        >
          <ng-template matStepLabel> Select product </ng-template>
          <ng-template matStepContent>
            <app-page [dynamicHeight]="true" [class.dialog-layout]="isDialog">
              <app-header>
                <h1 app-overline>Create pattern</h1>
                <h2 app-title>Step 1: Set product details</h2>

                <app-header-actions *ngIf="!showNewProductCard">
                  <button
                    mat-flat-button
                    pally-rounded-button-m
                    color="primary"
                    (click)="createProduct()"
                  >
                    <mat-icon>add</mat-icon> New product
                  </button>
                </app-header-actions>
              </app-header>

              <ng-container *ngIf="productsTableData$ | async as products">
                <ng-container
                  *appVar="appLayout.lowHeight$ | async as lowHeigth"
                >
                  <div
                    class="product-content-swapper"
                    [class.is-flipped]="showNewProductCard"
                    [class.low-height]="lowHeigth"
                  >
                    <div class="product-table side">
                      <app-table
                        #table
                        [clickableRows]="true"
                        [clickToSelect]="true"
                        [topRow]="false"
                        [inputData]="products.value"
                        [disableBlockSelection]="true"
                        [displayedColumns]="columnsToDisplay"
                        [actions]="true"
                        [preSelectedRows]="[selectedProduct$ | async]"
                        (blockSelectedChange)="selectProduct($event)"
                      ></app-table>
                    </div>
                    <div *ngIf="isDialog" class="new-product-card side">
                      <app-product-component
                        [isEmbedded]="true"
                        [isNew]="true"
                        (outputEvent)="handleProductEvent($event)"
                      ></app-product-component>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </app-page>
          </ng-template>
        </mat-step>

        <mat-step
          [stepControl]="stepFormGroup.get('pattern')"
          errorMessage="Your pattern has errors"
        >
          <ng-template matStepLabel> Create pattern </ng-template>
          <ng-template matStepContent>
            <app-page [dynamicHeight]="true">
              <app-header>
                <h1 app-overline>Create pattern</h1>
                <h2 app-title>Step 2: Set pattern details</h2>
              </app-header>

              <ng-container
                *ngIf="fetchSelectedProduct$ | async as selectedProduct"
              >
                <app-pattern-maker-card
                  app-content
                  *ngIf="stepper.selectedIndex === 1"
                  [product]="selectedProduct"
                  (patternEmit)="patternEmitted($event)"
                  (formGroupStatus)="patternFromGroupStatusChange($event)"
                >
                </app-pattern-maker-card>
              </ng-container>
            </app-page>
          </ng-template>
        </mat-step>
      </mat-stepper>
    </div>
    <ng-container *ngIf="isDialog; else pageActions">
      <div class="dialog-actions-container">
        <app-wizard-bottom-navbar
          [title]="'New pattern'"
          [secondaryButton]="'back'"
          [primaryButton]="stepper.selectedIndex < 1 ? 'next' : 'confirm'"
          [primaryButtonDisabled]="showNewProductCard"
          (nextClick)="
            stepper.selectedIndex > 0 ? confirmClick() : nextClick(stepper)
          "
          (backClick)="backClick(stepper)"
          (tertiaryButtonClick)="confirmClick(true)"
        ></app-wizard-bottom-navbar>
      </div>
    </ng-container>
    <ng-template #pageActions>
      <app-wizard-bottom-navbar
        [title]="'New pattern'"
        [secondaryButton]="'back'"
        [primaryButton]="stepper.selectedIndex < 1 ? 'next' : 'confirm'"
        [tertiaryButton]="
          stepper.selectedIndex > 0 ? 'Confirm & Customize' : null
        "
        (nextClick)="
          stepper.selectedIndex > 0 ? confirmClick() : nextClick(stepper)
        "
        (backClick)="backClick(stepper)"
        (tertiaryButtonClick)="confirmClick(true)"
      ></app-wizard-bottom-navbar>
    </ng-template>
  </ng-container>
</ng-container>
