import {
  ChangeDetectorRef,
  Component,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { map, shareReplay, startWith } from 'rxjs';
import * as geos from 'geos-major';
import * as THREE from 'three';
import { New_ThreeViewComponent } from '../../three-view/three-view.component';
import { MathUtils } from 'three';
import { toRequestState } from '../../../../../data-request/operators';
import { RenderingService } from '../../../../../services/3dview/rendering.service';
import { NewAssetStoreService } from '../../../../../services/3dview/asset-store.service';
import { settings } from '../../../../../models_new/config/application-settings';

@Component({
  selector: 'app-earth-globe-view',
  // Doesn't need a custom template.
  templateUrl: '../../three-view/three-view.component.html',
  styleUrls: [
    '../../three-view/three-view.component.scss',
    './earth-globe-view.component.scss',
  ],
})
export class EarthGlobeViewComponent
  extends New_ThreeViewComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() countryCode: string;

  // We want to animate the camera position our self
  interactive = false;

  globeRadius = 0.5;
  targetCamPos: THREE.Vector3;

  animationProgress = 1;

  constructor(
    ngZone: NgZone,
    renderingService: RenderingService,
    cdRef: ChangeDetectorRef,
    private assetStore: NewAssetStoreService
  ) {
    super(ngZone, renderingService, cdRef);
    this.loading$ = this.assetStore
      .load('globe-points', settings.view3d.globePointsUrl, 'json')
      .data$.pipe(
        map((points: { x: number; y: number; z: number }[]) => {
          const dotGlobe = new THREE.InstancedMesh(
            new THREE.SphereGeometry(this.globeRadius / 200, 8, 8),
            new THREE.MeshLambertMaterial({ color: '#0f0f0f', opacity: 0.5 }),
            points.length
          );

          const dummy = new THREE.Object3D();
          // Populate instanced mesh with positions
          for (let i = 0; i < points.length; i++) {
            dummy.position.set(points[i].x, points[i].y, points[i].z);
            dummy.updateMatrix();

            // Assign the matrix
            dotGlobe.setMatrixAt(i, dummy.matrix);
          }
          this.scene.add(dotGlobe);
          this.render();

          // We're done loading
          return false;
        }),
        startWith(true),
        toRequestState(),
        // Stop subscriptions from making more globes
        shareReplay({ bufferSize: 1, refCount: false })
      );

    // Add lighting
    const ambient = new THREE.AmbientLight('#ffffff', 1);
    this.scene.add(ambient);

    this.enableRenderingLoop();
  }

  convertLongLatCoordsToVector3(
    lon: number,
    lat: number,
    radius: number
  ): THREE.Vector3 {
    const latitude_rad = MathUtils.degToRad(lat);
    const longitude_rad = MathUtils.degToRad(lon);

    return new THREE.Vector3(
      radius * Math.cos(latitude_rad) * Math.sin(longitude_rad),
      radius * Math.sin(latitude_rad),
      radius * Math.cos(latitude_rad) * Math.cos(longitude_rad)
    );
  }

  ngOnInit(): void {
    super.ngOnInit();

    // Set initial start position of camera.
    this.camera.position.set(0, 0, 1);
    this.controls.target.set(0, 0, 0);
    this.controls.update();
  }

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.countryCode?.currentValue) {
      // Find country info
      const countryInfo = geos.country(
        this.countryCode.toLowerCase().slice(0, 2)
      );

      const defaultToZero =
        changes.countryCode?.currentValue === 'DEFAULT' ||
        // Couldn't find country
        countryInfo === undefined;

      // Calculate new camera target position
      this.targetCamPos = this.convertLongLatCoordsToVector3(
        defaultToZero ? 0 : countryInfo.longitude,
        defaultToZero ? 0 : countryInfo.latitude,
        this.globeRadius
      ).multiplyScalar(2);

      this.animationProgress = 0; // Start camera animation
    }
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  update(dt?: number): void {
    if (this.animationProgress < 1) {
      const lerpVector = new THREE.Vector3().lerpVectors(
        this.camera.position,
        this.targetCamPos,
        this.animationProgress
      );

      // Maintain the original radius to make the camera "swing" around.
      lerpVector.setLength(this.targetCamPos.length());

      this.camera.position.set(lerpVector.x, lerpVector.y, lerpVector.z);
      this.controls.update();
    }

    // Update the animation progress (but max at 1 or "completed")
    this.animationProgress = Math.min(this.animationProgress + dt, 1);
  }
}
