<div class="landing-wrapper">
  <ng-container *ngIf="landingContent$ | async as content">
    <!-- Loading state -->
    <app-loading *ngIf="content.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="content.error as error" [error]="error"></app-error>

    <ng-container *ngIf="content.didSucceed && content.value">
      <!-- Landing Toolbar -->
      <app-landing-toolbar></app-landing-toolbar>
      <!-- Landing Header -->
      <div class="landing-header">
        <div class="landing-header-text">
          <p
            [innerHtml]="content.value.header.text | richtextDocumentToHtml"
          ></p>
        </div>
        <div class="landing-header-actions">
          <ng-container *ngFor="let action of content.value.header.actions">
            <button
              *ngIf="action.type === 'stroked'"
              mat-stroked-button
              color="accent"
              style="height: 56px"
              (click)="handleAction(action.action, content.value.links)"
            >
              {{ action.label }}
            </button>
            <button
              *ngIf="action.type === 'flat'"
              mat-flat-button
              color="accent"
              style="height: 56px"
              (click)="handleAction(action.action, content.value.links)"
            >
              {{ action.label }}
            </button>
          </ng-container>
        </div>
        <!-- Landing Carousel -->

        <div class="landing-header-carousel">
          <div class="landing-header-carousel-wrapper">
            <div class="landing-header-carousel-frame">
              <div class="dummyCarousel">
                <img
                  [@blur]="carousel.toggle"
                  (@blur.done)="
                    carousel.index = carousel.nextIndex ?? 0;
                    carousel.toggle = true
                  "
                  [src]="content.value.header.images[carousel.index]?.url"
                  [alt]="content.value.header.images[carousel.index].title"
                />
              </div>
              <p>
                {{ content.value.header.images[carousel.index]?.description }}
              </p>
            </div>
            <div class="landing-header-carousel-selector-wrapper">
              <div
                *ngFor="let image of content.value.header.images; index as i"
                class="landing-header-carousel-selector"
                [ngStyle]="{
                  opacity: carousel.index === i ? '1' : '0.3'
                }"
                (click)="
                  carousel.nextIndex = i;
                  carousel.toggle = false;
                  carousel.isTouched = true
                "
              ></div>
            </div>
          </div>
        </div>
      </div>
      <!-- Landing User-groups -->
      <div class="landing-userGroups">
        <div class="landing-userGroups-text">
          <p
            [innerHtml]="content.value.userGroups.text | richtextDocumentToHtml"
          ></p>
        </div>
        <div class="landing-userGroups-groupChips">
          <ng-container
            *ngFor="let groupCard of content.value.userGroups.cards; index as i"
          >
            <button
              *ngIf="userGroup.index === i; else selected"
              class="mat-elevation-z4"
              mat-flat-button
              pally-rounded-button-m
              style="height: 56px"
              color="primary"
            >
              <mat-icon>check</mat-icon> {{ groupCard.title }}
            </button>
            <ng-template #selected>
              <button
                class="mat-elevation-z4"
                mat-stroked-button
                pally-rounded-button-m
                style="height: 56px"
                color="primary"
                (click)="
                  userGroup.nextIndex = i;
                  userGroup.toggle = false;
                  userGroup.learnMoreIndex = null
                "
              >
                {{ groupCard.title }}
              </button>
            </ng-template>
          </ng-container>
        </div>
        <div
          *appVar="content.value.userGroups.cards[userGroup.index] as card"
          class="group-card-wrapper"
          [@blur]="userGroup.toggle"
          (@blur.done)="
            userGroup.index = userGroup.nextIndex ?? 0; userGroup.toggle = true
          "
        >
          <div class="card-summary mat-elevation-z4">
            <div class="card-header">
              <div
                class="circle"
                [style.background-image]="'url(' + card.icon?.url + ')'"
              ></div>
              <h2>{{ card.title }}</h2>
            </div>
            <div class="card-header-subtitle">
              <p [innerHTML]="card.subtitle?.json | richtextDocumentToHtml"></p>
            </div>
            <mat-divider></mat-divider>
            <div class="card-feature-list">
              <h3>Other popular features</h3>
              <div
                *ngFor="let feature of card.featureHighlights"
                class="card-feature-item"
              >
                <mat-icon> task_alt </mat-icon>
                <span>
                  {{ feature }}
                </span>
              </div>
            </div>
            <div class="card-header-button">
              <button
                mat-flat-button
                pally-button-l
                pally-gradient
                color="accent"
                style="height: 56px"
                (click)="handleAction('contact-form', content.value.links)"
              >
                Try it for free
              </button>
            </div>
          </div>
          <div class="card-highlight-wrapper">
            <!-- If no "LearnMore" is expanded -->
            <ng-container
              *ngIf="
                userGroup.learnMoreIndex === null ||
                  userGroup.learnMoreIndex === undefined;
                else groupLearnMore
              "
            >
              <div
                *ngFor="let highlightCard of card.subCards; index as i"
                class="card-hightight mat-elevation-z4"
              >
                <div
                  class="card-hightight-image-wrapper"
                  [style.background-image]="'url(' + highlightCard?.image + ')'"
                ></div>
                <div class="card-highlight-header">
                  <h3>
                    {{ highlightCard?.title }}
                  </h3>
                </div>
                <button
                  mat-button
                  color="accent"
                  (click)="
                    userGroup.learnMoreIndex = i;
                    userGroup.learnMoreToggle = false
                  "
                  style="height: 56px"
                >
                  Learn More
                </button>
              </div>
            </ng-container>

            <!-- If "LearnMore" is expanded -->
            <ng-template #groupLearnMore>
              <div
                class="card-learnMore mat-elevation-z4"
                [@blur]="userGroup.learnMoreToggle"
                (@blur.done)="userGroup.learnMoreToggle = true"
              >
                <div
                  class="card-learnMore-image"
                  [style]="{
                    'background-image':
                      'url(' +
                      card.subCards[userGroup.learnMoreIndex]?.image +
                      ')'
                  }"
                ></div>
                <div class="card-learnMore-details">
                  <h3>{{ card.subCards[userGroup.learnMoreIndex]?.title }}</h3>
                  <p
                    [innerHTML]="
                      card.subCards[userGroup.learnMoreIndex].description
                        | richtextDocumentToHtml
                    "
                  ></p>
                  <button
                    mat-button
                    color="warn"
                    (click)="userGroup.learnMoreIndex = null"
                    style="height: 56px"
                  >
                    Back to all features
                  </button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <!-- Landing Fast-Track -->

      <div class="landing-fast-track">
        <app-fast-track-outlet [embed]="true"></app-fast-track-outlet>
      </div>
      <!-- Landing Testimonials -->
      <div class="landing-testimonials">
        <div class="testimonials-wrapper">
          <div class="testimonials-header">
            <p
              [innerHtml]="
                content.value.testimonials.text | richtextDocumentToHtml
              "
            ></p>
          </div>
          <div class="testimonials-card-wrapper">
            <div
              *ngFor="let card of content.value.testimonials.testimonials"
              class="testimonial-card mat-elevation-z4"
            >
              <div class="card-header">
                <div
                  class="image-placeholder circle"
                  [style.background-image]="
                    'url(' + card.profileImage?.url + ')'
                  "
                ></div>
                <div class="user-metadata">
                  <span> {{ card.jobTitle }} </span>
                  <h3>{{ card.name }}</h3>
                  <span> {{ card.organizationAndLocation }} </span>
                </div>
              </div>
              <div class="card-body">
                <p [innerHTML]="card.comment.json | richtextDocumentToHtml"></p>
              </div>
              <div *ngIf="card.valoration" class="card-footer">
                <div *ngFor="let icon of getValorationIcons(card.valoration)">
                  <mat-icon>{{ icon }}</mat-icon>
                </div>
              </div>
            </div>
          </div>
          <div class="testimonials-logos">
            <div
              *ngFor="let logo of content.value.testimonials.logos"
              class="logo"
            >
              <img [src]="logo.url" />
            </div>
          </div>
        </div>
      </div>
      <!-- Landing FAQ: Digital Twin -->
      <div class="landing-digital-twin">
        <div class="digital-twin-wrapper">
          <div class="digital-twin-description">
            <p
              [innerHTML]="
                content.value.learnMore.digitalTwin.text
                  | richtextDocumentToHtml
              "
            ></p>
            <button
              mat-raised-button
              color="primary"
              (click)="
                handleAction('learn-more-digital-twin', content.value.links)
              "
            >
              Learn More
            </button>
          </div>
          <div class="digital-twin-compairson">
            <img [src]="content.value.learnMore.digitalTwin.images[0]?.url" />
          </div>
        </div>
      </div>
      <!-- Landing FAQ: Try it Out! -->

      <div class="landing-try-cta">
        <p
          [innerHTML]="
            content.value.learnMore.tryItNow.text | richtextDocumentToHtml
          "
        ></p>
        <button
          mat-raised-button
          *ngFor="let action of content.value.learnMore.tryItNow.actions"
          color="accent"
          (click)="handleAction(action.action, content.value.links)"
          style="height: 56px"
        >
          {{ action.label }}
        </button>
      </div>

      <!-- Landing FAQ: Q&A section -->
      <div class="landing-faq">
        <div class="questions-and-answers-header">
          <p
            [innerHTML]="
              content.value.learnMore.faq.text | richtextDocumentToHtml
            "
          ></p>
        </div>
        <div class="questions-and-answers-accordion">
          <mat-accordion>
            <mat-expansion-panel
              *ngFor="let listItem of content.value.learnMore.faq.faq"
            >
              <mat-expansion-panel-header>
                <mat-panel-title> {{ listItem.question }}</mat-panel-title>
              </mat-expansion-panel-header>
              <p
                [innerHTML]="sanitizer.bypassSecurityTrustHtml(listItem.answer)"
              ></p>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
      </div>

      <!-- Landing FAQ: Contact form -->
      <div class="landing-contact-form">
        <div class="contact-form-wrapper">
          <div class="contact-form-header">
            <p
              [innerHTML]="content.value.contact.text | richtextDocumentToHtml"
            ></p>
          </div>
          <div class="contact-form-input">
            <mat-form-field>
              <input
                type="text"
                matInput
                placeholder="Your email"
                id="email"
                required
                [formControl]="contactForm"
              />
            </mat-form-field>
            <button
              style="height: 56px"
              mat-raised-button
              *ngFor="let action of content.value.contact.actions"
              color="accent"
              (click)="handleAction(action.action, contactForm)"
            >
              {{ action.label }}
            </button>
          </div>
          <div class="contact-form-disclaimer">
            <p
              [innerHTML]="
                content.value.contact.disclaimer | richtextDocumentToHtml
              "
            ></p>
          </div>
        </div>
      </div>

      <!-- Landing Footer -->
      <app-fast-track-footer [darkMode]="true"></app-fast-track-footer>
    </ng-container>
  </ng-container>
</div>
