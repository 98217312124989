import { Injectable } from '@angular/core';
import { gql, MutationResult } from 'apollo-angular';
import { combineLatest, EMPTY, Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import {
  ApiOrganization,
  IAggregateObj,
  IApiOrganization,
  IOrganizationInvite,
  IOrganizationInviteData,
  IOrganizationInviteUserData,
  IOrganizationUserData,
} from '../../models_new/classes/api-models/ApiOrganization';
import { ApiOrganizationMember } from '../../models_new/classes/api-models/ApiOrganizationMember';
import { ApiPalletizingProject } from '../../models_new/classes/api-models/ApiPalletizingProject';
import { ApiPattern } from '../../models_new/classes/api-models/ApiPattern';
import { ApiScene } from '../../models_new/classes/api-models/ApiScene';
import { ApiStrategy } from '../../models_new/classes/api-models/ApiStrategy';
import { ObjectUtils } from '../../utils/object';
import { AssetApiService } from './asset-api.service';
import { ClientApiService } from './client-api.service';
import { Role } from 'src/app/models_new/types/role';
import { ApolloQueryResult } from '@apollo/client/core';
import { ISingleMutationResult } from 'src/app/models_new/types/mutation-result';
import { FileUtils, IValidUrl } from '../../utils/file-utils';
import { IApiOpenSimulation } from 'src/app/models_new/classes/api-models/ApiOpenSimulation';
import { ApiOrganizationSubscription } from '../../models_new/classes/api-models/ApiOrganizationSubscription';
import { isUUIDv4 } from 'src/app/utils/div';
import { ICountryNState } from './public-api.service';
import { UserType } from 'src/app/models_new/types/signup-user-type';
import { RobotBrandType } from 'src/app/models_new/enums/robot-brand-types';

export interface ILeadByEmail {
  email: string;
  company: string;
  name: string;
  level_of_interest: IApiOpenSimulation['phase_of_research'];
  country_region: string;
  first_request?: string;
  last_request?: string;
  open_sims: IApiOpenSimulation[];
}
interface aggregate {
  aggregate: {
    count: number;
  };
}

export interface ICustomersDashboardDataCustomerOrg {
  id: string;
  name: string;
  products_aggregate: aggregate;
  patterns_aggregate: aggregate;
  simulations_aggregate: aggregate;
  metadata: IApiOrganization['metadata'];
  logo: string;
  updated_at: number;
  created_at: number;
}
export interface ICustomersDashboardData {
  sales_org: {
    organization_members_aggregate: aggregate;
    robot_configurations_aggregate: aggregate;
    unhandled_leads_aggregate?: aggregate;
  };
  customer_orgs: ICustomersDashboardDataCustomerOrg[];
}
export interface IOrganizationLogoResult {
  url: string;
  data: string;
}

export interface UpdateOrganizationResult {
  update_organization_by_pk: {
    id: string;
    assets: { id: string }[];
  };
}

export interface AcceptOrganizationResult {
  insert_organization_member_one: {
    id: string;
    organization: {
      id: string;
    };
  };
}

const SALESORG_DASHBOARD_DATA_QUERY = gql`
  query customersLandingQuery($organization_id: uuid!) {
    sales_org: organization_by_pk(id: $organization_id) {
      organization_members_aggregate(distinct_on: user_id) {
        aggregate {
          count
        }
      }
      robot_configurations_aggregate {
        aggregate {
          count
        }
      }
      subscription_details: subscriptionByOrganizationSubscription {
        id
        trial
        valid_to
      }
    }
    customer_orgs: organization(
      where: {
        organization_relations: { organization_a_id: { _eq: $organization_id } }
      }
      order_by: { updated_at: asc }
    ) {
      id
      name
      updated_at
      created_at
      products_aggregate {
        aggregate {
          count
        }
      }
      patterns_aggregate {
        aggregate {
          count
        }
      }
      simulations_aggregate {
        aggregate {
          count
        }
      }
      logo: assets(where: { type: { _eq: logo } }) {
        url: url
      }
      subscription_details: subscriptionByOrganizationSubscription {
        id
        trial
        valid_to
      }
    }
  }
`;

const ORG_LOGO_META_QUERY = gql`
  query getOrganizationLogoMeta($organizationId: uuid!) {
    organization_by_pk(id: $organizationId) {
      assets(
        where: { type: { _eq: logo } }
        order_by: { created_at: desc_nulls_last }
      ) {
        id
        file_type
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class OrganizationApiService {
  defualtLogo: string = '../../../assets/dummy/dummy-logo.jpg';

  constructor(
    private clientApi: ClientApiService,
    private assetApi: AssetApiService
  ) {}

  validateOrReplaceOrgLogo(
    orgs: IApiOrganization[] | ICustomersDashboardDataCustomerOrg[]
  ): void {
    orgs.forEach((o: IApiOrganization | ICustomersDashboardDataCustomerOrg) => {
      if (o.logo.length) {
        o.logo = (o.logo as { url: string }[])[0].url;
        const validLogo: IValidUrl = FileUtils.validAzureBlobStoreUrl(o.logo);
        if (!validLogo.valid) {
          o.logo = this.defualtLogo;
        }
      } else {
        o.logo = this.defualtLogo;
      }
    });
  }

  getOrganizationDashboardData(id: string): Observable<IApiOrganization> {
    const q = gql`
    subscription getOrganizationDashboardData {
    organization_by_pk(id: "${id}") {
        id
        name
        type
        products_aggregate {
          aggregate {
            count
          }
        }
        patterns_aggregate {
          aggregate {
            count
          }
        }
        simulations_aggregate {
          aggregate {
            count
          }
        }
        logo: assets(where: { type: { _eq: logo } }) {
          url: url
        }
        installed_robots_aggregate {
          aggregate {
            count
          }
        }
        generated_waypoints_aggregate: waypoints_generations_aggregate {
          aggregate {
            count
          }
        }
        pally_path_strategies_aggregate {
          aggregate {
            count
          }
        }
        subscription_details: subscriptionByOrganizationSubscription {
          id
          trial
          valid_to
          subscription_data
        }
      }
    }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[1].message);
          } else {
            const o: IApiOrganization = ObjectUtils.cloneObject(
              data.data.organization_by_pk
            );
            this.validateOrReplaceOrgLogo([o]);
            return o;
          }
        })
      );
  }

  getOrganizationByIdLight(id: string): Observable<IApiOrganization> {
    const q = gql`
    subscription getOrganizationByIdLight {
      organization_by_pk(id: "${id}") {
        id
        name
        type
        created_at
        updated_at
        metadata
        patterns {
          id
          name
          description
        }
        products {
          id
          name
        }
        organization_members_aggregate {
          aggregate {
            count(distinct: true, columns: user_id)
          }
        }
        production_lines {
          id
          name
        }
        simulations_aggregate {
          aggregate {
            count
          }
        }
        logo: assets(where: { type: { _eq: logo } }) {
          url: url
        }
        installed_robots_aggregate {
          aggregate {
            count
          }
        }
        generated_waypoints_aggregate: waypoints_generations_aggregate {
          aggregate {
            count
          }
        }
        pally_path_strategies_aggregate {
          aggregate {
            count
          }
        }
        subscription_details: subscriptionByOrganizationSubscription {
          id
          trial
          valid_to
          subscription_data
        }
      }
    }`;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[1].message);
          } else {
            const o: IApiOrganization = ObjectUtils.cloneObject(
              data.data.organization_by_pk
            );
            this.validateOrReplaceOrgLogo([o]);
            return o;
          }
        })
      );
  }

  getOrganizationById(id: string): Observable<IApiOrganization> {
    const q = gql`
    query getOrganizationById {
      organization_by_pk(id: "${id}") {
        id
        name
        type
        created_at
        updated_at
        patterns {
          id
          name
          description
        }
        palletizing_projects {
          id
          name
          updated_at
          owner_id
          patterns: palletizing_project_patterns {
            id
          }
          scenes: palletizing_project_scenes {
            id
          }
          strategies: palletizing_project_strategies {
            id
          }
          products: palletizing_project_products {
            id
          }
        }
        products {
          id
          name
        }
        organization_members_aggregate {
          aggregate {
            count(distinct: true, columns: user_id)
          }
        }
        scenes {
          id
          simulations {
            id
          }
        }
        strategies {
          id
          simulations {
            id
          }
        }
        production_lines {
          id
          name
        }
        simulations {
          id
          name
        }
        generated_waypoints: waypoints_generations {
          id
          name
        }
        pally_path_strategies: pally_path_strategy {
          id
          name
        }
        subscription_details: subscriptionByOrganizationSubscription {
          id
          trial
          valid_to
        }
      }
    }`;
    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          const organization: IApiOrganization = ObjectUtils.cloneObject(
            data.data.organization_by_pk
          );
          organization.patterns = organization.patterns.map(
            (m) => new ApiPattern(m)
          );
          organization.palletizing_projects =
            organization.palletizing_projects.map(
              (m) => new ApiPalletizingProject(m)
            );
          organization.organization_members =
            organization.organization_members.map(
              (m) => new ApiOrganizationMember(m)
            );
          organization.scenes = organization.scenes.map((m) => new ApiScene(m));
          organization.strategies = organization.strategies.map(
            (m) => new ApiStrategy(m)
          );
          organization.subscription_details = new ApiOrganizationSubscription(
            organization.subscription_details
          );
          return organization as IApiOrganization;
        })
      );
  }

  getPublicOrgNameByID(id: string): Observable<string | undefined> {
    const q = gql`
      query getOrganizationNameById {
        organization_by_pk(id: "${id}") {
          name
        }
      }
    `;
    return this.clientApi
      .useClient('public', 'http')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          return data?.data?.organization_by_pk?.name;
        })
      );
  }

  /**
   * Gets the VendorOrganization's Name from id (params.vi) or vendor_filter_name (params.vn)
   * @param id
   * @param vendor_filter_name
   * @returns string | Undefined
   */
  getPublicVendorName(
    id: string,
    vendor_filter_name?: string
  ): Observable<string | undefined> {
    const q = id
      ? gql`
      query getPublicOrgName {
        vendor_organizations(where: { id: { _eq: "${id}" } }) {
          label
        }
      }`
      : gql`
          query getPublicOrgName {
            vendor_organizations(where: { name: { _eq: "${vendor_filter_name}" } }) {
              label
            }
          }
        `;
    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            return null;
          } else if (data?.data?.vendor_organizations?.length > 0) {
            return data.data.vendor_organizations[0].label;
          }
          return undefined;
        })
      );
  }

  /**
   * Gets the Vendor's filter name from id.
   * @param id
   * @returns string
   */
  getPublicVendorFilterName(id: string): Observable<string> {
    const q = gql`
        query getPublicOrgName {
          vendor_organizations(where: { id: { _eq: "${id}" } }) {
            name
          }
        }`;

    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            return null;
          } else if (data?.data?.vendor_organizations?.length > 0) {
            return data.data.vendor_organizations[0].name;
          }
          return null;
        })
      );
  }

  getSelectableSalesOrgs() {
    const q = gql`
      query getSelectableSalesOrgs {
        organization(
          where: { type: { _eq: sales_organization } }
          order_by: { name: asc }
        ) {
          id
          name
          logo: assets(where: { type: { _eq: logo } }) {
            url: url
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            return [];
          } else {
            const orgs: ApiOrganization[] = ObjectUtils.cloneObject(
              data.data?.organization.map((m: any) => new ApiOrganization(m))
            );

            this.validateOrReplaceOrgLogo(orgs);
            return orgs;
          }
        }),
        catchError((err) => {
          console.error(err.message);
          return of([]);
        })
      );
  }

  getOrganizationsLight(): Observable<IApiOrganization[]> {
    const q = gql`
      subscription getOrganizationsLight {
        organization(order_by: { name: asc }) {
          id
          name
          type
          created_at
          updated_at
          robot_brands
          organization_relations {
            id
            organization_a_id
          }
          organization_members_aggregate {
            aggregate {
              count(distinct: true, columns: user_id)
            }
          }
          robot_configurations_aggregate {
            aggregate {
              count
            }
          }
          logo: assets(
            where: { type: { _eq: logo } }
            limit: 1
            order_by: { updated_at: desc }
          ) {
            url: url
          }
          subscription_details: subscriptionByOrganizationSubscription {
            id
            trial
            valid_to
            subscription_data
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            throw new Error(data.errors[0].message);
          } else {
            const orgs: IApiOrganization[] = ObjectUtils.cloneObject(
              data.data?.organization
            );

            this.validateOrReplaceOrgLogo(orgs);
            return orgs;
          }
        })
      );
  }

  getCustomerOrganizationsLight() {
    const q = gql`
      subscription getOrganizationsLight {
        organization(where: { type: { _eq: customer_organization } }) {
          id
          name
          type
          created_at
          updated_at
          organization_relations {
            id
            organization_a_id
          }
          organization_members_aggregate {
            aggregate {
              count(distinct: true, columns: user_id)
            }
          }
          robot_configurations_aggregate {
            aggregate {
              count
            }
          }
          logo: assets(where: { type: { _eq: logo } }) {
            url: url
          }
          subscription_details: subscriptionByOrganizationSubscription {
            id
            trial
            valid_to
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            return [];
          } else {
            const orgs: IApiOrganization[] = ObjectUtils.cloneObject(
              data.data?.organization
            );

            this.validateOrReplaceOrgLogo(orgs);
            return orgs;
          }
        }),
        catchError((err) => {
          console.error(err.message);
          return of([]);
        })
      );
  }

  /**
   * Gets the any organization the user belongs to.
   *
   * @returns organization[]
   */
  getOrganizationsFull(): Observable<IApiOrganization[]> {
    const q = gql`
      subscription getOrganizationsFull {
        organization {
          id
          name
          type
          created_at
          updated_at
          robotConfigurations: robot_configurations {
            id
          }
          palletizing_projects {
            id
          }
          patterns {
            id
          }
          products {
            id
          }
          organization_members {
            user_id
            type
          }
          production_lines {
            id
          }
          simulations {
            id
            name
          }
          organization_relations {
            id
            organization_a_id
          }
          subscription_details: subscriptionByOrganizationSubscription {
            id
            trial
            valid_to
          }
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => data.data?.organization as IApiOrganization[]),
        catchError((err) => {
          console.error(err.message);
          return of([]);
        })
      );
  }

  getCustomersDashboardData(
    organization_id: string
  ): Observable<ICustomersDashboardData> {
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: SALESORG_DASHBOARD_DATA_QUERY,
        variables: {
          organization_id: organization_id,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            return null;
          } else {
            const d: ICustomersDashboardData = ObjectUtils.cloneObject(
              data.data
            );

            this.validateOrReplaceOrgLogo(d.customer_orgs);

            return d;
          }
        })
      );
  }

  /**
   * Gets the any related 'organization_b/customerOrg' listed along with the given 'organization_a/salesOrg'
   *
   * @param organization_id
   * @returns organization_b[]
   */
  getRelatedCustomerOrganizationsById(organization_id: string): Observable<
    (IApiOrganization & {
      products_aggregate: IAggregateObj;
      patterns_aggregate: IAggregateObj;
    })[]
  > {
    const q = gql`
    subscription getRelatedOrganizations {
      organization(where: {organization_relations: {organization_a_id: {_eq: "${organization_id}"}}}) {
        id
        name
        type
        metadata
        created_at
        updated_at
        products_aggregate {
          aggregate {
            count
          }
        }
        patterns_aggregate {
          aggregate {
            count
          }
        }
        simulations_aggregate {
          aggregate {
            count
          }
        }
        logo: assets(where: { type: { _eq: logo } }) {
          url: url
        }
        subscription_details: subscriptionByOrganizationSubscription {
          id
          trial
          valid_to
        }
      }
    }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors[0].message);
            return [];
          } else {
            const orgs: (IApiOrganization & {
              products_aggregate: IAggregateObj;
              patterns_aggregate: IAggregateObj;
            })[] = ObjectUtils.cloneObject(data.data?.organization);

            this.validateOrReplaceOrgLogo(orgs);
            return orgs;
          }
        })
      );
  }

  /**
   * Gets the any related 'organization_a/salesOrg''s ID listed along with the given 'organization_b/customerOrg'
   *
   * @param organization_id
   * @returns organization_a[]
   */
  getRelatedSalesOrganizationById(
    organization_id: string
  ): Observable<IApiOrganization[]> {
    const q = gql`
      subscription getRelatedOrganizations {
        organization(
          where: {
            organization_relations: {
              organization_b_id: { _eq: "${organization_id}" }
            }
          }
        ) {
          id
        }
      }
    `;
    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(map((data) => data.data?.organization as IApiOrganization[]));
  }

  /**
   * Sets a relation between 'Organization_a/salesOrg' and 'Organization_b/customerOrg'.
   * Relation type defaulted to 'sales_customer', since its the only one at the moment.
   *
   * @param salesOrg_id
   * @param customerOrg_id
   */
  setSalesCustomerOrganizationRelation(
    salesOrg_id: string,
    customerOrg_id: string
  ): Observable<any> {
    const m = gql`
      mutation relateOrganizations {
        insert_organization_relation(objects: {organization_a_id: "${salesOrg_id}", organization_b_id: "${customerOrg_id}"}) {
          affected_rows
        }
      }
    `;

    return salesOrg_id
      ? this.clientApi
          .useClient('org_edit')
          .mutate<any>({
            mutation: m,
          })
          .pipe(
            map((data) => {
              return data;
            })
          )
      : EMPTY;
  }

  /// Creates organization with the given name and metadata
  /// If salesOrgId is null, a sales_organization is created,
  /// if nonull a customer_organization.
  createOrganization(
    orgName: string,
    metadata: {
      country: ICountryNState;
      address?: string;
      state?: ICountryNState;
      sub_type?: UserType;
    },
    salesOrgId?: string,
    robotBrands?: RobotBrandType[]
  ): Observable<IApiOrganization['id']> {
    const m = gql`
      mutation createOrganization(
        $name: String!
        $metadata: json
        $robotBrands: jsonb
      ) {
        insert_organization_one(
          object: {
            name: $name
            metadata: $metadata
            robot_brands: $robotBrands
          }
        ) {
          id
        }
      }
    `;

    const variables = {
      name: orgName,
      metadata: metadata,
      robotBrands: robotBrands,
    };

    return this.clientApi
      .useClient(salesOrgId ? 'org_edit' : 'user')
      .mutate<any>({
        mutation: m,
        variables: variables ? variables : null,
      })
      .pipe(
        map((data) => {
          if (data.errors) throw new Error(data.errors[0].message);
          else return data.data.insert_organization_one.id;
        })
      );
  }

  updateOrganization(
    orgId: string,
    name: string,
    metadata: ApiOrganization['metadata']
  ): Observable<UpdateOrganizationResult> {
    const m = gql`
      mutation updateOrganization(
        $orgId: uuid!
        $name: String!
        $metadata: json
      ) {
        update_organization_by_pk(
          pk_columns: { id: $orgId }
          _set: { metadata: $metadata, name: $name }
        ) {
          id
          assets(where: { type: { _eq: logo } }) {
            id
          }
        }
      }
    `;

    const variables = {
      orgId: orgId,
      name: name,
      metadata: metadata,
    };

    return this.clientApi
      .useClient('org_admin')
      .mutate<UpdateOrganizationResult>({
        mutation: m,
        variables: variables,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data.errors);
            throw new Error(data.errors[0].message);
          } else {
            return data.data;
          }
        })
      );
  }

  deleteOrganization(orgId: string): Observable<any> {
    const m = gql`
      mutation deleteOrganization($id: uuid!) {
        delete_organization_by_pk(id: $id) {
          name
        }
      }
    `;

    return this.clientApi
      .useClient('org_admin')
      .mutate({
        mutation: m,
        variables: {
          id: orgId,
        },
      })
      .pipe(
        map((data) => {
          return data;
        })
      );
  }

  insertOrganizationInvite(
    email: string,
    org_id: string,
    roles: Role[] = ['org_view', 'org_edit', 'org_delete']
  ): Observable<any> {
    const q = gql`
      query InviteOrg($invitation_data: json!) {
        NewOrganizationInvitation(invitation_data: $invitation_data) {
          id
        }
      }
    `;

    const variables = {
      invitation_data: {
        user_email: email,
        member_type: {
          types: roles,
        },
        organization_id: org_id,
      },
    };

    return this.clientApi.useClient('org_admin').watchQuery<any>({
      query: q,
      variables: variables,
      fetchPolicy: 'no-cache',
    }).valueChanges;
  }

  deleteOrganizationInvite(
    id: string
  ): Observable<MutationResult<ApolloQueryResult<any>>> {
    const m = gql`
      mutation deleteOrganizationInvite {
        delete_organization_invite_by_pk(id: "${id}") {
          id
        }
    }`;

    return this.clientApi
      .useClient('org_admin')
      .mutate<ApolloQueryResult<any>>({
        mutation: m,
      });
  }

  denyOrganizationInvite(
    id: string
  ): Observable<MutationResult<ApolloQueryResult<any>>> {
    const m = gql`
      mutation deleteOrganizationInvite {
        delete_organization_invite_by_pk(id: "${id}") {
          id
        }
    }`;

    return this.clientApi.useClient('user').mutate<ApolloQueryResult<any>>({
      mutation: m,
    });
  }

  insertOrganizationMember(
    roles: Role[],
    org_id: string,
    user_id: string
  ): Observable<MutationResult<ApolloQueryResult<any>>> {
    const q = gql`
      query newOrgMember($membership_data: json!) {
        NewOrganizationMember(membership_data: $membership_data) {
          membership_ids {
            organization_id
            organization_member_ids
          }
        }
      }
    `;

    const variables = {
      membership_data: {
        member_type: { types: roles },
        user_id: user_id,
        organization_id: org_id,
      },
    };

    return this.clientApi.useClient('org_admin').watchQuery<any>({
      query: q,
      variables: variables,
    }).valueChanges;
  }

  deleteOrganizationMemberByRole(
    id: string
  ): Observable<MutationResult<ApolloQueryResult<any>>> {
    const m = gql`
    mutation deleteOrganizationMember{
      delete_organization_member_by_pk(id: "${id}") {
        id
      }
    }`;

    return this.clientApi
      .useClient('org_admin')
      .mutate<ApolloQueryResult<any>>({
        mutation: m,
      });
  }

  deleteOrganizationMember(
    user_id: string
  ): Observable<MutationResult<ApolloQueryResult<any>>> {
    const m = gql`
    mutation deleteOrganizationMember{
      delete_organization_member(where: {user_id: {_eq: "${user_id}"}}) {
        affected_rows
      }
    }`;

    return this.clientApi
      .useClient('org_admin')
      .mutate<ApolloQueryResult<any>>({
        mutation: m,
      });
  }

  public fetchOrganizationUserData(
    org_id: string,
    org_admin: boolean = false
  ): Observable<IOrganizationUserData[]> {
    const q = gql`
    subscription getOrganizationUserData {
        organization_member_user_data(where: {organization_id: {_eq: "${org_id}"}}) {
          name
          email
          metadata
          type
          organization_id
          get_lead_mail
        }
      }
    `;

    const q_admin = gql`
    subscription getOrganizationUserData {
      organization_member_user_data(where: {organization_id: {_eq: "${org_id}"}}) {
        id
        name
        email
        metadata
        type
        organization_id
        user_id
        get_lead_mail
      }
    }`;

    return this.clientApi
      .useClient(org_admin ? 'org_admin' : 'org_view', 'ws')
      .subscribe<any>({
        query: org_admin ? q_admin : q,
      })
      .pipe(map((data) => data.data.organization_member_user_data));
  }

  fetchOrganizationInvites(): Observable<IOrganizationInvite[]> {
    const q = gql`
      subscription getOrganizationInvites {
        organization_invite {
          id
          user_id
          organization_id
          member_type
          organization {
            name
            type
          }
        }
      }
    `;

    return this.clientApi
      .useClient('user', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(map((data) => data.data.organization_invite));
  }

  fetchOrganizationInviteUserData(
    org_id: string
  ): Observable<IOrganizationInviteUserData[]> {
    const q = gql`
      subscription getInviteUserData {
        organization_invite_user_data(where: {organization_id: {_eq: "${org_id}"}}) {
          email
          id
          name
          updated_at
        }
      }
    `;

    return this.clientApi
      .useClient('org_admin', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(map((data) => data.data.organization_invite_user_data));
  }

  fetchOrganizationInvitesForUser(
    userId: string
  ): Observable<IOrganizationInviteData[]> {
    const q = gql`
      subscription getInvitesForUser {
        organization_invite(where: {user_id: {_eq: "${userId}"}}) {
          id
          user_id
          member_type
          organization_id
          organization {
            name
          }
        }
      }
    `;

    return this.clientApi
      .useClient('user', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(map((data) => data.data.organization_invite));
  }

  getOrganizationInviteWithId(
    inviteId: string
  ): Observable<IOrganizationInviteData> {
    const q = gql`
      query getInviteById {
        organization_invite_by_pk(id: "${inviteId}") {
          id
          user_id
          member_type
          organization_id

          organization {
            name
          }
        }
      }
    `;

    return this.clientApi
      .useClient('user')
      .query<any>({
        query: q,
      })
      .pipe(map((data) => data.data?.organization_invite_by_pk));
  }

  rejectOrganizationInviteForUser(
    id: string
  ): Observable<ISingleMutationResult> {
    const m = gql`
      mutation rejectOrganizationInviteForUser {
        delete_organization_invite_by_pk(id: "${id}") {
          id
        }
    }`;

    return this.clientApi
      .useClient('user')
      .mutate<any>({
        mutation: m,
      })
      .pipe(map((data) => data.data?.delete_organization_invite_by_pk));
  }

  acceptOrganizationInviteForUser(
    id: string
  ): Observable<ISingleMutationResult> {
    const q = gql`
      query acceptOrganizationInvite {
        AcceptOrganizationInvitation(invitation_id: "${id}") {
          id
        }
      }`;

    return this.clientApi
      .useClient('user')
      .query<any>({
        query: q,
      })
      .pipe(map((data) => data.data?.AcceptOrganizationInvitation));
  }

  getOrganizationsByMemberDomain(
    userId: string
  ): Observable<{ name: IApiOrganization['name'] }[]> {
    const q = gql`
    query getOrganizationsByMemberDomain {
      getOrganizationsByMemberDomain(userId: "${userId}") {
        name
      }
    }    
    `;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          return data.data.getOrganizationsByMemberDomain as {
            name: IApiOrganization['name'];
          }[];
        })
      );
  }

  getOrganizationLogoUrl(organizationId: string): Observable<string> {
    const fallbackAsset = '../../../assets/dummy/dummy-logo.jpg';
    type MetaResult = {
      organization_by_pk: {
        assets: [
          {
            id: string;
            file_type: string;
          }
        ];
      };
    };
    if (isUUIDv4(organizationId)) {
      return of(fallbackAsset);
    }

    const meta$ = this.clientApi
      .useClient('org_view')
      .watchQuery<MetaResult>({
        query: ORG_LOGO_META_QUERY,
        variables: {
          organizationId: organizationId,
        },
      })
      .valueChanges.pipe(
        map((result) => {
          const assets = result.data.organization_by_pk.assets;

          // Dont stop on error, warn about missing asset.
          if (result.errors) {
            console.warn(result.errors[0]);
          }

          return !assets.length || result.errors ? null : assets[0];
        })
      );

    const url$ = meta$.pipe(
      switchMap((meta) => {
        return meta
          ? this.assetApi.getAssetData(meta.id, 'org_view')
          : of(null);
      }),
      map((data_result) => {
        if (
          !data_result ||
          !data_result?.data.assets_by_pk?.url?.includes('sv=') ||
          !data_result?.data.assets_by_pk?.url?.includes('se=') ||
          !data_result?.data.assets_by_pk?.url?.includes('sr=') ||
          !data_result?.data.assets_by_pk?.url?.includes('sp=') ||
          !data_result?.data.assets_by_pk?.url?.includes('sig=')
        ) {
          return fallbackAsset;
        } else {
          return data_result.data.assets_by_pk.url;
        }
      })
    );

    return url$;
  }

  /**
   * @deprecated use logo url in customer object
   */
  getOrganizationLogo(organizationId: string): Observable<string> {
    return this.#getOrganizationLogo(
      organizationId,
      false
    ) as Observable<string>;
  }

  getOrganizationLogoExposingUrl(
    organizationId: string
  ): Observable<IOrganizationLogoResult> {
    return this.#getOrganizationLogo(
      organizationId,
      true
    ) as Observable<IOrganizationLogoResult>;
  }

  #getOrganizationLogo(
    organizationId: string,
    exposeUrl: boolean
  ): Observable<string | IOrganizationLogoResult> {
    const url$ = this.getOrganizationLogoUrl(organizationId);

    // Convert to data url
    return url$.pipe(
      switchMap((url) => combineLatest([of(url), FileUtils.urlToBase64(url)])),
      map(([url, data]) => {
        if (!exposeUrl) {
          return data;
        } else {
          return {
            url: url,
            data: data,
          };
        }
      })
    );
  }

  getOrganizationMemberByRole(orgId: string, role: Role): Observable<any[]> {
    const q = gql`
      subscription getOrganizationMemberByRole {
        organization_member(
          where: {
            _and: {
              organization_id: { _eq: "${orgId}" }
              type: { _eq: org_admin }
            }
          }
        ) {
          user_id
        }
      }
    `;
    return this.clientApi
      .useClient(role, 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data);
            throw new Error(data.errors[0].message);
          } else {
            return data.data.organization_member;
          }
        })
      );
  }

  getNoOfUnhandledLeads(orgId: string): Observable<number> {
    const q = gql`
      subscription getNoOfUnhandledLeads {
        open_simulation_aggregate(where: {_and: {handled: {_eq: false}, email: {_nlike: "%@rocketfarm.no%"}, lead_owner: {_eq: "${orgId}"}}}, distinct_on: email) {
          aggregate {
            count
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        catchError((err) => {
          console.error(err);
          return of({
            errors: null,
            data: {
              open_simulation_aggregate: {
                aggregate: {
                  count: 0,
                },
              },
            },
          });
        }),
        map((data) => {
          if (data.errors) {
            console.error(data);
            throw new Error(data.errors[0].message);
          } else {
            return data.data.open_simulation_aggregate.aggregate.count;
          }
        })
      );
  }

  handleLead(openSimId: string): Observable<IApiOpenSimulation> {
    const m = gql`
      mutation handleLead {
        update_open_simulation_by_pk(
          pk_columns: { id: "${openSimId}" }
          _set: { handled: true }
        ) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('org_edit')
      .mutate<any>({
        mutation: m,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data);
            throw new Error(data.errors[0].message);
          } else {
            return data.data.update_open_simulation_by_pk;
          }
        })
      );
  }

  getLead(email: string, orgId: string): Observable<ILeadByEmail> {
    const q = gql`
      subscription getOrganizationLead($email: String) {
        open_simulation(where: {_and: {email: {_eq: $email}, lead_owner: {_eq: "${orgId}"} }}, order_by: {created_at: desc}) {
          id
          country
          region
          email
          created_at
          quote_requested_at: metadata(path: "quoteRequestedAt")
          name
          organization_name
          phase_of_research
          handled
          open_pattern {
            firstName: metadata(path: "firstName")
            lastName: metadata(path: "lastName")
            fullName: metadata(path: "fullName")
          }
          cpm: simulation_status(path: "$calculated.cpm")
          average_pallet_time: simulation_status(path: "$calculated.average_pallet_time_formatted")
          simulation_state
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
        variables: {
          email: email,
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data);
            throw new Error(data.errors[0].message);
          } else {
            const open_sims: IApiOpenSimulation[] = ObjectUtils.cloneObject(
              data.data.open_simulation
            );

            const lead: ILeadByEmail = {
              email: email,
              company: '',
              name: '',
              level_of_interest: null,
              country_region: '',
              first_request: null,
              last_request: null,
              open_sims: [],
            };

            open_sims.forEach((os: IApiOpenSimulation) => {
              lead.open_sims.push(os);
              const newLDate = new Date(os.created_at).getTime();
              const oldLDate = new Date(lead.last_request).getTime();
              const oldFDate = new Date(lead.first_request).getTime();

              // Update first and last simulation dates
              if (newLDate > oldLDate) {
                lead.last_request = os.created_at;
              }
              if (newLDate < oldFDate) {
                lead.first_request = os.created_at;
              }

              if (!lead.last_request) {
                lead.last_request = os.created_at;
              }

              if (!lead.first_request) {
                lead.first_request = os.created_at;
              }

              lead.company = os.organization_name;
              (lead.country_region = os.country + ', ' + os.region),
                (lead.name =
                  os['open_pattern'].fullName ||
                  // firstName & lastName Kept for backwards compatibility
                  os['open_pattern'].firstName +
                    ' ' +
                    os['open_pattern'].lastName),
                (lead.level_of_interest = os.phase_of_research);
            });

            return lead;
          }
        })
      );
  }

  getOrganizationLeads(orgId: string): Observable<{
    handled: ILeadByEmail[];
    unhandled: ILeadByEmail[];
  }> {
    const q = gql`
      subscription getOrganizationLeads {
        open_simulation(where: {_and: [
          {lead_owner: {_eq: "${orgId}"}},
          {email: {_nlike: "%@rocketfarm.no%"}}
        ]}, 
        order_by: {created_at: desc}) {
          name
          id
          phase_of_research
          organization_name
          email
          country
          region
          created_at
          quote_requested_at: metadata(path: "quoteRequestedAt")
          handled
          open_pattern {
            firstName: metadata(path: "firstName")
            lastName: metadata(path: "lastName")
            fullName: metadata(path: "fullName")
          }
        }
      }
    `;

    return this.clientApi
      .useClient('org_view', 'ws')
      .subscribe<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            console.error(data);
            throw new Error(data.errors[0].message);
          } else {
            const open_sims: IApiOpenSimulation[] = ObjectUtils.cloneObject(
              data.data.open_simulation
            );

            const unhandled: ILeadByEmail[] = [];
            const handled: ILeadByEmail[] = [];

            open_sims
              .filter((f) => !f.handled)
              .forEach((os: IApiOpenSimulation) => {
                const byMail = unhandled.find((f) => f.email === os.email);
                this.insertLeadData(os, unhandled, byMail);
              });

            open_sims
              .filter((f) => f.handled)
              .forEach((os: IApiOpenSimulation) => {
                const byMail = handled.find((f) => f.email === os.email);
                this.insertLeadData(os, handled, byMail);
              });

            return {
              unhandled: unhandled,
              handled: handled,
            };
          }
        })
      );
  }

  private insertLeadData(
    os: IApiOpenSimulation,
    array: ILeadByEmail[],
    byMail: ILeadByEmail
  ) {
    if (byMail) {
      byMail.open_sims.push(os);
      const newLDate = new Date(os.created_at).getTime();
      const oldLDate = new Date(byMail.last_request).getTime();
      const oldFDate = new Date(byMail.first_request).getTime();

      // Update first and last simulation dates
      if (newLDate > oldLDate) {
        byMail.last_request = os.created_at;
      }
      if (newLDate < oldFDate) {
        byMail.first_request = os.created_at;
      }
    } else {
      array.push({
        email: os.email,
        company: os.organization_name,
        name:
          os['open_pattern'].fullName ||
          // firstName & lastName Kept for backwards compatibility
          os['open_pattern'].firstName + ' ' + os['open_pattern'].lastName,
        level_of_interest: os.phase_of_research,
        country_region: os.country + ', ' + os.region,
        first_request: os.created_at,
        last_request: os.created_at,
        open_sims: [os],
      });
    }
  }

  createOrganizationSubscription(sub: { valid_to: Date }): Observable<string> {
    const m = gql`
      mutation createOrganizationSubscription($valid_to: timestamptz) {
        insert_organization_subscription_one(object: { valid_to: $valid_to }) {
          id
        }
      }
    `;

    return this.clientApi
      .useClient('org_admin')
      .mutate<any>({
        mutation: m,
        variables: {
          valid_to: sub.valid_to.toISOString(),
        },
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return data.data.insert_organization_subscription_one.id;
          }
        })
      );
  }

  updateOrganizationSubscription(
    orgId: string,
    subId: string
  ): Observable<string> {
    const m = gql`
    mutation updateOrganizationSubscription {
      update_organization_by_pk(pk_columns: {id: "${orgId}"}, _set: {subscription_id: "${subId}"}) {
        id
      }
    }
    `;

    return this.clientApi
      .useClient('org_admin')
      .mutate<any>({
        mutation: m,
      })
      .pipe(
        map((data) => {
          if (data.errors) {
            throw new Error(data.errors[0].message);
          } else {
            return orgId;
          }
        })
      );
  }
}
