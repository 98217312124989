import { PartType } from '../models_new/enums/sim-config-part-type';

export class HwPartUtils {
  public static getPartAssetID(type: PartType, name: string): string {
    // Other scenes except pally are unsupported for now
    if (type === PartType.SCENE && name !== 'PALLY') {
      return undefined;
    }

    // Secondary conveyor is the same as conveyor for asset IDs.
    // Asset IDs shouldn't care which conveyor it is, just what type it is.
    let typeStr: PartType = type;
    if (type === PartType.SECONDARY_CONVEYOR) {
      typeStr = PartType.CONVEYOR;
    } else if (type === PartType.SECONDARY_CONVEYOR_GUIDE) {
      typeStr = PartType.CONVEYOR_GUIDE;
    }

    return typeStr + '_' + name;
  }
}
