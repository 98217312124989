<div
  *appVar="appLayout.lowHeight$ | async as lowHeigth"
  class="landing-step-item-wrapper"
  [ngStyle]="
    lowHeigth
      ? {
          'padding-top': '0.5em',
          'padding-bottom': '1em',
          'margin-top': '0.5em'
        }
      : {}
  "
>
  <ng-container *ngIf="palletizingPattern$ | async as pattern">
    <!-- Loading state -->
    <app-loading *ngIf="pattern.isLoading"></app-loading>

    <!-- Error state -->
    <app-error *ngIf="pattern.error as error" [error]="error"></app-error>

    <ng-container *ngIf="pattern.didSucceed && pattern.value">
      <h1
        *appVar="currentPageContent$ | async as page"
        [ngStyle]="
          lowHeigth ? { 'margin-bottom': '0.5em', 'margin-top': '0.5em' } : {}
        "
      >
        {{ page?.content?.cardTitle || 'Pattern' }}
      </h1>
      <app-pattern-maker-card
        [isFastTrack]="true"
        [product]="pattern.value.product"
        (patternEmit)="patternUpdate($event)"
        [ngStyle]="lowHeigth ? { 'margin-bottom': '0.25em' } : {}"
      >
      </app-pattern-maker-card>
    </ng-container>
  </ng-container>
</div>
