import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ILicenseLinkInfo } from 'src/app/models_new/classes/api-models/ApiSoftwareLicense';
import { ClientApiService } from './client-api.service';
import { ObjectUtils } from 'src/app/utils/object';

@Injectable({
  providedIn: 'root',
})
export class LicenseApiService {
  constructor(private clientApi: ClientApiService) {}

  getAllLinkingLicenseInfoForCustomerWithId(
    organization_id: string
  ): Observable<ILicenseLinkInfo[]> {
    const q = gql`
      query getAllLicenseRobotLinkingInfoForCustomerWithId {
        sw_license(where: {customer_organization_id: {_eq: "${organization_id}"}}) {
          id
          robot_serial_number
          product_type
          license_valid_to
        }
      }
    `;

    return this.clientApi
      .useClient('org_view')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(map((data) => data.data.sw_license));
  }

  getValidLicenseForRobotSerialNumber(
    organization_id: string,
    robot_serial_number: string
  ): Observable<ILicenseLinkInfo> {
    const q = gql`
      query getValidLicenseForRobotSerialNumber {
        sw_license(
          where: {
            customer_organization_id: {_eq: "${organization_id}"}
            robot_serial_number: {_eq: "${robot_serial_number}"}
          },
          order_by: {created_at: desc_nulls_last}
        ) {
          id
          robot_serial_number
          product_type
          license_valid_to
          generated_license
        }
  }
`;

    return this.clientApi
      .useClient('org_view')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => {
          let validLicenses = data.data.sw_license
            .filter((license) => {
              return new Date(license.license_valid_to) > new Date();
            })
            .map((l) => {
              const license = ObjectUtils.cloneObject(l);
              license.status = 'active';
              return license;
            });

          // If there are no valid licenses, return the license with the latest valid_to date
          if (validLicenses.length === 0) {
            validLicenses = data.data.sw_license
              .sort((a, b) => {
                return (
                  new Date(b.license_valid_to).getTime() -
                  new Date(a.license_valid_to).getTime()
                );
              })
              .map((l) => {
                const license = ObjectUtils.cloneObject(l);
                license.status = 'expired';
                return license;
              })
              .slice(0, 1);
          }

          // If there are still no valid licenses, return a dummy license with no-license status
          if (validLicenses.length === 0) {
            return {
              id: null,
              robot_serial_number: robot_serial_number,
              product_type: null,
              license_valid_to: null,
              status: 'no-license',
            };
          }

          return validLicenses[0];
        })
      );
  }
}
