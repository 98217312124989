import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { from, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { NotificationService } from './notification.service';
import { gql } from 'apollo-angular';
import { ClientApiService } from './api/client-api.service';
import { LocalStorageKey } from '../models_new/enums/local-storage-keys';
import { Document as RichTextDocument } from '@contentful/rich-text-types';
import { InfoApiService } from './api/info-api.service';

export interface IRelease {
  id: number;
  tag: string;
  name: string;
  description: string;
  publishedAt: number;
  createdAt: number;
}

export interface IReleaseNotesContent {
  tag: string;
  title: string;
  subtitle: string;
  text: RichTextDocument[];
}

@Injectable({
  providedIn: 'root',
})
export class UpdateService {
  constructor(
    private swUpdate: SwUpdate,
    private notifier: NotificationService,
    private clientApi: ClientApiService,
    private infoApi: InfoApiService
  ) {}

  public isCheckForUpdateEnabled(): boolean {
    return this.swUpdate.isEnabled;
  }

  public checkForUpdates(): void {
    //Check if page was reloaded after update

    if (localStorage.getItem(LocalStorageKey.RELOAD_AFTER_UPDATE)) {
      localStorage.removeItem(LocalStorageKey.RELOAD_AFTER_UPDATE);

      setTimeout(() => {
        this.notifier.showMessage(
          'A new version of MRC has been loaded.',
          10000,
          false,
          {
            text: 'Release notes.',
            url: '/release-notes',
          }
        );
      }, 1000);
    }

    from(this.swUpdate.checkForUpdate())
      .pipe(take(1))
      .subscribe({
        next: (available) => {
          if (available) {
            localStorage.setItem(LocalStorageKey.RELOAD_AFTER_UPDATE, 'true');
            window.location.reload();
          }
        },
        error: (_) => this.notifier.showError('Could not verify app version'),
      });
  }

  getReleaseNotesContent(tag: string): Observable<IReleaseNotesContent> {
    const q = gql`
      query getReleaseNotesCollection {
        releaseNotesCollection(where: { tag_contains: "${tag}" }, limit: 1) {
          items {
            tag
            title
            subtitle
            text {
              json
              links {
                assets {
                  block {
                    title
                    url
                    sys {
                      id
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .query<any>({
        query: q,
      })
      .pipe(
        map((data) => data?.data?.releaseNotesCollection?.items[0]),
        map((item) => {
          return {
            tag: item.tag,
            title: item.title,
            subtitle: item.subtitle,
            text: this.infoApi.extractRichTextAssets(item),
          };
        })
      );
  }

  public getReleases(): Observable<IRelease> {
    const q = gql`
      query GetReleases {
        getReleases {
          id
          tag
          name
          description
          createdAt
          publishedAt
        }
      }
    `;

    return this.clientApi
      .useClient('public')
      .watchQuery<any>({
        query: q,
      })
      .valueChanges.pipe(
        map((data) => {
          if (data.errors) {
            return null;
          } else {
            return data.data?.getReleases[0] as IRelease;
          }
        })
      );
  }
}
