<app-tools
  [activeLayers]="activeLayers"
  class="mt-auto"
  [shimPaperInProject]="shimPaperInProject"
  [palletPosition]="palletPosition"
>
</app-tools>

<div
  cdkDropList
  class="layers-list col-md-12 p-0"
  (cdkDropListDropped)="drop.emit($event)"
>
  <div
    *ngFor="
      let layer of activeLayers;
      let i = index;
      let first = first;
      let last = last
    "
  >
    <mat-card
      appearance="outlined"
      [style.background-color]="
        workspaceService.getLayerColor(layer.type, layer.typeId)
      "
      [style.color]="workspaceService.getFontColor(layer.type, layer.typeId)"
      class="m-0 mt-1 mb-1 pallet-layer-card"
      cdkDrag
    >
      <div class="col-md-8 m-2 p-0 float-left d-flex layerInfoWrapper">
        <mat-card-title
          *ngIf="layer.type === layerTypes.LAYER"
          class="m-0 p-0 layerTitle"
          >{{ layer.name }}
          <span *ngIf="!layer.boxes.length">(empty)</span>
        </mat-card-title>
        <mat-card-title
          *ngIf="layer.type === layerTypes.SHIMPAPER"
          class="m-0 p-0 layerTitle"
        >
          Shim paper
          <span class="text-muted" style="font-size: small; font-style: italic"
            >{{ layer.height + ' mm' | unitSystemLabel }}
            <ng-container *ngIf="!isMetric"
              >({{ layer.height + ' mm' }})</ng-container
            >
          </span></mat-card-title
        >

        <div
          class="layerInfoInverseApproach"
          *ngIf="layer.approach === LayerApproach.INVERSE"
          matTooltip="Inverted approach"
        >
          <mat-icon style="transform: rotate(135deg)">arrow_right_alt</mat-icon>
          <mat-icon style="transform: translate(-11px, 0px) rotate(135deg)"
            >arrow_right_alt</mat-icon
          >
        </div>
      </div>

      <div class="spacer"></div>

      <ng-container *ngIf="layer.type === layerTypes.LAYER">
        <!-- Layer menu -->
        <button
          class="float-right"
          mat-icon-button
          [matMenuTriggerFor]="layerMenu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #layerMenu="matMenu">
          <button mat-menu-item (click)="editLayer.emit(layer.id)">
            Edit this layer
          </button>
          <button
            mat-menu-item
            *ngIf="_getAlikeLayers(layer.typeId).length > 1"
            (click)="editAllOfLayer.emit(layer.id)"
          >
            Edit all ({{ layer.name }})
          </button>
          <ng-container>
            <button mat-menu-item (click)="duplicateLayer.emit(layer.id)">
              Duplicate
            </button>

            <button mat-menu-item (click)="$event.stopPropagation()">
              <mat-slide-toggle
                labelPosition="before"
                #invPlacement
                [checked]="layer.approach === 'inverse'"
                (change)="
                  changeApproach.emit({
                    layerId: layer.id,
                    inverted: invPlacement.checked
                  })
                "
                >Inverted Approach
              </mat-slide-toggle>
            </button>

            <button
              mat-menu-item
              class="text-danger"
              (click)="removeLayer.emit(layer.id)"
            >
              Remove this layer
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>

      <ng-container *ngIf="layer.type === layerTypes.SHIMPAPER">
        <!-- Shimpaper menu -->
        <button
          class="float-right"
          mat-icon-button
          [matMenuTriggerFor]="shimpapermenu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #shimpapermenu="matMenu">
          <button mat-menu-item [matMenuTriggerFor]="shimpaperHeightmenu">
            Set height
          </button>
          <ng-container>
            <button mat-menu-item (click)="duplicateLayer.emit(layer.id)">
              Duplicate
            </button>
            <button
              mat-menu-item
              class="text-danger"
              (click)="removeLayer.emit(layer.id)"
            >
              Remove this layer
            </button>
          </ng-container>
        </mat-menu>

        <mat-menu #shimpaperHeightmenu="matMenu" class="shimpaperHeightsList">
          <ng-container *ngFor="let height of shimpaperHeights">
            <button
              mat-menu-item
              (click)="
                editShimpaperHeight.emit({ layerId: layer.id, height: height })
              "
            >
              {{ height + ' mm' | unitSystemLabel }}
              <i *ngIf="!isMetric">({{ height + ' mm' }})</i>
            </button>
          </ng-container>
        </mat-menu>
      </ng-container>
    </mat-card>
  </div>
</div>

<img src="../../../../assets/illustrative/raud_palle.png" style="width: 100%" />
