import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstalledRobotsOutletComponent } from '../../components/installed-robots/installed-robots-outlet/installed-robots-outlet.component';
import { pagesPATH } from '../../models_new/config/pages';
import { OrganizationContextResolver } from '../../resolvers/organization-context-resolver.resolver';
import { VerifyRobotConfigComponent } from '../../components/installed-robots/verify-robot-config/verify-robot-config.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { RobotEntityComponent } from 'src/app/components/installed-robots/robot-entity/robot-entity.component';
import { InstalledRobotsComponent } from 'src/app/components/inventory/installed-robots/installed-robots.component';

const routes: Routes = [
  {
    component: InstalledRobotsOutletComponent,
    path: '',
    resolve: { organization_id: OrganizationContextResolver },
    data: {
      breadcrumb: 'Installed robots',
    },
    children: [
      {
        component: InstalledRobotsComponent,
        path: '',
        data: {
          breadcrumb: undefined,
        },
      },
      {
        path: pagesPATH.ROBOT + '/:id',
        component: RobotEntityComponent,
        resolve: { organization_id: OrganizationContextResolver },
        data: {
          breadcrumb: 'Robot',
          bcRouteTo: [pagesPATH.INSTALLED_ROBOTS],
        },
      },
      {
        path: pagesPATH.VERIFY + '/:id',
        component: VerifyRobotConfigComponent,
        data: {
          breadcrumb: 'Verify robot',
        },
      },
      {
        path: pagesPATH.MY_UR,
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('../my-ur/my-ur.module').then((m) => m.MyUrModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class InstalledRobotsRoutingModule {}
