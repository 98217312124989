<mat-toolbar class="wizard-bottom-navbar">
  <div class="wizard-bottom-navbar-content">
    <div class="wizard-bottom-navbar-title">
      <span>{{ title }}</span>
    </div>
    <div class="spacer"></div>
    <span class="wizard-bottom-navbar-hint-text" *ngIf="hintText">{{
      hintText
    }}</span>
    <div class="wizard-bottom-navbar-actions">
      <button
        *ngIf="secondaryButton && secondaryButton !== 'hidden'"
        mat-stroked-button
        (click)="
          secondaryButton === 'skip'
            ? skipClick.emit(true)
            : backClick.emit(true)
        "
        [disabled]="secondaryButtonDisabled"
      >
        {{ secondaryButton }}
      </button>
      <button
        *ngIf="tertiaryButton"
        style="margin-right: 2em"
        mat-raised-button
        color="accent"
        (click)="tertiaryButtonClick.emit(true)"
        [disabled]="tertiaryButtonDisabled"
      >
        {{ tertiaryButton }}
        <mat-icon style="margin-bottom: 3px">edit</mat-icon>
      </button>
      <button
        *ngIf="primaryButton !== 'hidden'"
        mat-raised-button
        color="accent"
        (click)="nextClick.emit(true)"
        [disabled]="primaryButtonDisabled"
      >
        {{ primaryButton }}
        <mat-icon style="margin-bottom: 3px">
          {{ primaryButton === 'export' ? 'download' : 'arrow_forward' }}
        </mat-icon>
      </button>
    </div>
  </div>
</mat-toolbar>
