import { ApiOrganization } from './ApiOrganization';
import { ApiRobotConfiguration } from './ApiRobotConfiguration';
import { ILicenseLinkInfo } from './ApiSoftwareLicense';

export interface IInstalledRobotLicenceInfo {
  id: string;
  name?: string;
  organization: {
    id: ApiOrganization['id'];
    name: ApiOrganization['name'];
  };
  robot_serial_number?: string;
}

export interface IApiInstalledRobot {
  id: string;
  organization: ApiOrganization;
  name?: string;
  robot_configuration_id?: string;
  robot_configuration?: ApiRobotConfiguration;
  robot_name?: string;
  robot_brand?: string;

  mac_address?: string;
  robot_serial_number?: string;

  installed_robots_calibration?: {
    created_at?: string;
    id?: string;
  };

  installed_robot_sw_license?: ILicenseLinkInfo;
  license_id?: string;
  backup_zip_id?: string;
  calibration_id?: string;

  created_at?: string;
  updated_at?: number | string;
}

export class ApiInstalledRobot implements IApiInstalledRobot {
  id: string;
  organization: ApiOrganization;
  name?: string;
  robot_configuration_id?: string;
  robot_configuration?: ApiRobotConfiguration;
  robot_name?: string;
  robot_brand?: string;

  mac_address?: string;
  robot_serial_number?: string;

  installed_robots_calibration?: {
    created_at?: string;
    id?: string;
    pally_version?: string;
  };

  installed_robot_sw_license?: ILicenseLinkInfo;

  license_id?: string;
  backup_zip_id?: string;
  calibration_id?: string;

  created_at?: string;
  updated_at?: number | string;

  constructor(installation: IApiInstalledRobot) {
    for (const d in installation) {
      if (installation.hasOwnProperty(d)) {
        this[d] = installation[d];
      }
    }
  }
}
