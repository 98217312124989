import { ITableData } from 'src/app/components/gui/table/table.component';
import {
  ISimulationDetails,
  ISimulationMovementData,
} from '../../types/simulation-details';

export class SimulationReport {
  srd: ISimulationDetails; // static_report_data
  cpm: number;
  hop: number;
  mop: number;

  constructor(data: ISimulationDetails, private isOpen: boolean = false) {
    this.srd = data;
    this.createProductivity(); // Makes priceless values
  }

  createAll() {
    // this.createProductivity() is already done in contructor. Shown here to show context.
    this.createArtifacts();
    this.createGeneral();
    this.createHw();
    this.createInstallation();
    this.createPallySettings();
    this.createPowerConsumption();
    this.createSafetySettings();
    this.createTearAndWear();
    this.createVacuum();
    this.createMovementData();
    this.createMovementBoxes();
    return this;
  }

  createProductivity() {
    this.hop = 0;
    this.mop = 0;
    this.cpm = 0;
    if (
      this.srd.pattern.productivity?.length &&
      this.srd.pattern.productivity[0]?.palletizing_project
    ) {
      this.hop =
        this.srd.pattern.productivity[0].palletizing_project
          .palletizing_project_production_lines[0]?.production_line.per_day
          ?.hop || 12;
      this.mop =
        this.srd.pattern.productivity[0].palletizing_project
          .palletizing_project_production_lines[0]?.production_line.per_day
          ?.mop || 0;
      this.cpm =
        this.srd.pattern.productivity[0].palletizing_project
          .palletizing_project_production_lines[0]?.production_line.per_day
          ?.cpm || 0;
    }

    if (
      this.cpm === 0 &&
      this.srd.pattern['product']?.product_production_lines
    ) {
      this.cpm =
        this.srd.pattern['product'].product_production_lines[0]?.production_line
          ?.data || 0;
    }

    if (this.isOpen) {
      this.cpm = this.srd.pattern.cpm || 0;
    }

    let boxCount = 0;
    this.srd.pattern.data.layers?.forEach((layerType) => {
      this.srd.pattern.data.layerTypes.forEach((layerLayout) => {
        if (layerType === layerLayout.name) {
          boxCount += layerLayout.pattern?.length
            ? layerLayout.pattern.length
            : 0;
        }
      });
    });

    // casesPerMin * 60 * hoursOfProduction  +  casesPerMin * minutesOfProduction
    const productsPerDay = this.cpm * (this.hop * 60 + this.mop);

    const products = {
      Productivity: 'Products',
      'Per Day': productsPerDay,
      'Per Month*': productsPerDay * 30,
      'Per Year*': productsPerDay * 30 * 12,
    };

    this.srd.pattern.productivity = [
      {
        data: {
          Productivity: 'Pallets',
          'Per Day': products['Per Day'] / boxCount,
          'Per Month*': products['Per Month*'] / boxCount,
          'Per Year*': products['Per Year*'] / boxCount,
        },
      },
      { data: products },
    ];
    return this;
  }

  createHw() {
    // +Y is back towards the robot base / the opposite direction. Flip it to show "dimensions"
    this.srd.hw.gripper_bracket =
      (this.srd.hw.gripper_bracket as number) * -1 * 1000 + ' [mm]';
    this.srd.hw.lift_stroke =
      (this.srd.hw.lift_stroke as number) * 1000 + ' [mm]';
    this.srd.hw.mounting_bracket =
      (this.srd.hw.mounting_bracket as number) * 1000 + ' [mm]';

    // Renaming the lift_kit_type
    this.srd.hw['lifting_column_type'] = this.srd.hw['lift_kit_type'];
    delete this.srd.hw['lift_kit_type'];

    if (this.srd.hw['conveyor_types']) {
      this.srd.hw['primary_conveyor_type'] = this.srd.hw['conveyor_types'][0];
      if (this.srd.hw['conveyor_types'].length > 1) {
        this.srd.hw['secondary_conveyor_type'] =
          this.srd.hw['conveyor_types'][1];
      }
      delete this.srd.hw['conveyor_types'];
    }

    return this;
  }

  createInstallation() {
    const liftkit_duty_cycle_avg =
      this.srd.simulation_status.report_data?.liftkit_duty_cycle_avg;
    this.srd.installation.lifting_column_duty_cycle_avg =
      liftkit_duty_cycle_avg != undefined && liftkit_duty_cycle_avg != null
        ? liftkit_duty_cycle_avg + ' [%]'
        : 'N/A';
    const liftkit_duty_cycle_max =
      this.srd.simulation_status.report_data?.liftkit_duty_cycle_max;
    this.srd.installation.lifting_column_duty_cycle_max =
      liftkit_duty_cycle_max != undefined && liftkit_duty_cycle_max != null
        ? liftkit_duty_cycle_max + ' [%]'
        : 'N/A';
    // Primary conveyor
    this.srd.installation.conveyor_height =
      (this.srd.installation.conveyor_height as number) * 1000 + ' [mm]';
    this.srd.installation.robot_conveyor_distance =
      (this.srd.installation.robot_conveyor_distance as number) * 1000 +
      ' [mm]';
    // Secondary conveyor
    this.srd.installation.secondary_conveyor_height =
      (this.srd.installation.secondary_conveyor_height as number) * 1000 +
      ' [mm]';
    this.srd.installation.robot_secondary_conveyor_distance =
      (this.srd.installation.robot_secondary_conveyor_distance as number) *
        1000 +
      ' [mm]';

    this.srd.installation.robot_mounting_height =
      this.srd.simulation_status?.calculated?.robot_mount_height || 'N/A';
    this.srd.installation.total_TCP_offset =
      this.srd.simulation_status?.calculated?.total_tcp_offset || 'N/A';

    this.srd.installation.robot_pallet_edge_distance =
      Math.abs(this.srd.installation.robot_pallet_edge_distance as number) *
        1000 +
      ' [mm]';

    this.srd.installation.used_floor_space_width =
      Math.abs(
        this.srd?.simulation_status?.report_data?.used_floor_space
          ?.width as number
      ).toFixed(2) + ' [m]' || 'N/A';

    this.srd.installation.used_floor_space_length =
      Math.abs(
        this.srd?.simulation_status?.report_data?.used_floor_space
          ?.length as number
      ).toFixed(2) + ' [m]' || 'N/A';

    this.srd.installation.used_floor_space_area =
      Math.abs(
        this.srd?.simulation_status?.report_data?.used_floor_space
          ?.area as number
      ).toFixed(2) + ' [m²]' || 'N/A';
    return this;
  }

  createPallySettings() {
    this.srd.pally_settings.max_robot_speed =
      this.srd.pally_settings.max_robot_speed + ' [mm/s]';

    this.srd.pally_settings.max_robot_acceleration =
      this.srd.pally_settings.max_robot_acceleration + ' [mm/s²]';

    this.srd.pally_settings.high_approach_boost =
      this.srd.pally_settings.high_approach_boost + ' [%]';

    const maxGrip = this.srd.max_grip?.value || 1;
    const maxGripUnit = maxGrip === 1 ? 'case' : 'cases';

    this.srd.pally_settings.smart_grip =
      maxGrip.toString() + ' [' + maxGripUnit + ']';

    this.srd.pally_settings.above_pick_position_mm =
      this.srd.pally_settings.above_pick_position_mm + ' [mm]';
    this.srd.pally_settings.approach_distance =
      this.srd.pally_settings.approach_distance + ' [mm]';
    this.srd.pally_settings.box_free_height =
      this.srd.pally_settings.box_free_height + ' [mm]';

    this.srd.pally_settings.fixed_approach = this.srd.pally_settings
      .fixed_approach
      ? 'Yes'
      : 'No';

    return this;
  }

  createGeneral() {
    // Try to get achieved CPM
    let achievedCPM = this.srd.simulation_status?.calculated?.cpm;

    // NOt found, probably due to age. Try to use the backup cpm.
    if (!achievedCPM) {
      const backupAchievedCPM = this.srd.simulation_status?.cpm?.toPrecision(2);
      achievedCPM = backupAchievedCPM ? backupAchievedCPM : 'N/A'; // CPM wasn't defined anywhere.
    }

    const status = {
      eta: this.srd.simulation_status?.eta || 'N/A',
      'Completed Status': this.srd.simulation_status?.status || null,
      'CPM Required': this.cpm,
      'CPM Achieved': achievedCPM.replaceAll(',', '.'),
      'Avg time per pallet':
        this.srd.simulation_status?.calculated?.average_pallet_time_formatted ||
        '0',
      'Number of Collisions': null,
      'Smart grip': null,
    };
    this.srd.status = [{ data: status }];
    return this;
  }

  createVacuum() {
    this.srd.vacuum = [
      {
        data: {
          Vacuum: 'Vacuum per pallet',
          Value:
            this.srd.simulation_status?.report_data?.vacuum_on_pallet +
              ' [s]' || -1,
        },
      },
      {
        data: {
          Vacuum: 'Vacuum per hour',
          Value:
            this.srd.simulation_status?.report_data?.vacuum_on_hour + ' [s]' ||
            -1,
        },
      },
    ];
  }

  createSafetySettings() {
    this.srd.safety = [
      {
        data: {
          Safety: 'Safety Level',
          ['Level']: this.srd.safety?.safety_level || 'N/A',
        },
      },
      {
        data: {
          Safety: 'Pally Safety Restrictions',
          ['Max Speed']: this.srd.safety?.safety_restrictions_speed || 'N/A',
          ['Max Acceleration']:
            this.srd.safety?.safety_restrictions_acceleration || 'N/A',
        },
      },
      {
        data: {
          Safety: 'Simulation Results',
          ['Max Speed']: this.srd.simulation_status?.report_data
            ? this.srd.simulation_status?.report_data.tcp_speed_max
            : 'N/A',
          ['Max Acceleration']: this.srd.simulation_status?.report_data
            ? this.srd.simulation_status?.report_data.tcp_acceleration_max
            : 'N/A',
        },
      },
    ];
    return this;
  }

  createPowerConsumption() {
    const wattBasePerPallet =
      this.srd.simulation_status?.report_data
        ?.power_consumption_base_per_pallet || -1;
    const wattBasePerHour =
      this.srd.simulation_status?.report_data
        ?.power_consumption_base_per_hour || -1;
    const wattMovPerPallet =
      this.srd.simulation_status?.report_data
        ?.power_consumption_movement_per_pallet || -1;
    const wattMovPerHour =
      this.srd.simulation_status?.report_data
        ?.power_consumption_movement_per_hour || -1;
    const wattVacuumPerPallet =
      this.srd.simulation_status?.report_data
        ?.power_consumption_vacuum_pallet || -1;
    const wattVacuumPerHour =
      this.srd.simulation_status?.report_data?.power_consumption_vacuum_hour ||
      -1;

    const hoursPerDay = this.hop + this.mop / 60;
    const decimalPlaces = 3;

    const kwhBaseValues = [
      wattBasePerPallet,
      wattBasePerHour * hoursPerDay,
      wattBasePerHour * hoursPerDay * 30,
      wattBasePerHour * hoursPerDay * 30 * 12,
    ];

    const kwhMovValues = [
      wattMovPerPallet,
      wattMovPerHour * hoursPerDay,
      wattMovPerHour * hoursPerDay * 30,
      wattMovPerHour * hoursPerDay * 30 * 12,
    ];

    const kwhVacuumValues = [
      wattVacuumPerPallet,
      wattVacuumPerHour * hoursPerDay,
      wattVacuumPerHour * hoursPerDay * 30,
      wattVacuumPerHour * hoursPerDay * 30 * 12,
    ];

    const getKwhBaseValue = (value) => {
      const parsed = parseFloat(value.toPrecision(decimalPlaces));
      return parsed < 0 ? 'N/A' : parsed;
    };

    const kwhSumValues = [0, 0, 0, 0].map(
      (_, i) =>
        (kwhBaseValues[i] > 0 ? kwhBaseValues[i] : 0) +
        (kwhMovValues[i] > 0 ? kwhMovValues[i] : 0) +
        (kwhVacuumValues[i] > 0 ? kwhVacuumValues[i] : 0)
    );

    this.srd.power = [
      {
        data: {
          'Power Consumption': 'Robot base load',
          'kWh Per Pallet': getKwhBaseValue(kwhBaseValues[0]),
          'kWh Per Day': getKwhBaseValue(kwhBaseValues[1]),
          'kWh Per Month': getKwhBaseValue(kwhBaseValues[2]),
          'kWh Per Year': getKwhBaseValue(kwhBaseValues[3]),
        },
      },
      {
        data: {
          'Power Consumption': 'Robot movement load',
          'kWh Per Pallet': getKwhBaseValue(kwhMovValues[0]),
          'kWh Per Day': getKwhBaseValue(kwhMovValues[1]),
          'kWh Per Month': getKwhBaseValue(kwhMovValues[2]),
          'kWh Per Year': getKwhBaseValue(kwhMovValues[3]),
        },
      },
      {
        data: {
          'Power Consumption': 'Vacuum load',
          'kWh Per Pallet': getKwhBaseValue(kwhVacuumValues[0]),
          'kWh Per Day': getKwhBaseValue(kwhVacuumValues[1]),
          'kWh Per Month': getKwhBaseValue(kwhVacuumValues[2]),
          'kWh Per Year': getKwhBaseValue(kwhVacuumValues[3]),
        },
      },
      //{ data: { 'Power Consumption': 'Gripper' } },
      {
        data: {
          'Power Consumption': 'Sum',
          'kWh Per Pallet': getKwhBaseValue(kwhSumValues[0]),
          'kWh Per Day': getKwhBaseValue(kwhSumValues[1]),
          'kWh Per Month': getKwhBaseValue(kwhSumValues[2]),
          'kWh Per Year': getKwhBaseValue(kwhSumValues[3]),
        },
      },
    ];
    return this;
  }

  createTearAndWear() {
    this.srd.t_w = [
      {
        data: {
          'Tear & Wear': 'Tear & Wear treatment level',
          Value:
            this.srd.simulation_status?.report_data?.wear_and_tear || 'N/A',
        },
      },
    ];
    return this;
  }

  createArtifacts() {
    if (this.srd['artifact_urls']) {
      this.srd.artifactURLs = this.srd['artifact_urls'];
    }

    if (!this.srd.artifactURLs) {
      this.srd.artifactURLs = {
        bag: null,
        urdf: null,
        report: null,
      };
    }
    return this;
  }

  createMovementData() {
    const perPayloadData =
      this.srd.simulation_status.report_data?.per_payload_data;
    // If simulation is not finished, there is no available data so we exit.
    if (!perPayloadData?.max_tcp_speed) {
      this.srd.movementData = [];
      return;
    }

    const movementData: ITableData<ISimulationMovementData>[] = [];
    for (const i in perPayloadData.max_tcp_speed) {
      const boxes = perPayloadData.payload_in_boxes[i];
      movementData.push({
        data: {
          ['No. of boxes attached']:
            boxes === 0 ? 'None (returning state)' : boxes,
          ['Payload (Gripper Not Included)']:
            perPayloadData.payload_in_kg[i].toFixed(2) + ' [kg]',
          max_speed_reached:
            perPayloadData.max_tcp_speed[i].toFixed(2) + ' [mm/s]',
          number_of_moves: perPayloadData.moves_at_payload[i],
        },
      });
    }

    this.srd.movementData = movementData;
  }

  createMovementBoxes() {
    if (!this.srd?.simulation_status) return null;
    const movementBoxes: ITableData<any>[] = [];
    Object.entries(this.srd.simulation_status).forEach(([key]) => {
      if (key.startsWith('workspace')) {
        const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
        movementBoxes.push({
          data: {
            ['Movement bounding box']: capitalizedKey?.replace(/_/g, ' '),
            ['X-min ⇀ X-max']: `${(
              this.srd.simulation_status[key].x_min * 1000
            ).toFixed(0)} [mm] ⇀ ${(
              this.srd.simulation_status[key].x_max * 1000
            ).toFixed(0)} [mm]`,
            ['Y-min ⇀ Y-max']: `${(
              this.srd.simulation_status[key].y_min * 1000
            ).toFixed(0)} [mm] ⇀ ${(
              this.srd.simulation_status[key].y_max * 1000
            ).toFixed(0)} [mm]`,
            ['Z-min ⇀ Z-max']: `${(
              this.srd.simulation_status[key].z_min * 1000
            ).toFixed(0)} [mm] ⇀ ${(
              this.srd.simulation_status[key].z_max * 1000
            ).toFixed(0)} [mm]`,
          },
        });
      }
    });
    this.srd.movementBoxes = movementBoxes;
  }
}
