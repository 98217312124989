<mat-toolbar class="main-toolbar" color="primary">
  <mat-menu
    #customerOrgSwitch="matMenu"
    yPosition="below"
    class="org-switcher-wrapper"
  >
    <div class="org-switcher-menu">
      <div class="organization-list-header">
        <div class="row sub-menu-header">
          <div class="col-2">
            <mat-icon>arrow_back</mat-icon>
          </div>
          <span class="col-10" (click)="$event.stopPropagation()"
            >Switch customer</span
          >
        </div>
        <div class="sub-menu-filter" (click)="$event.stopPropagation()">
          <mat-form-field appearance="fill">
            <mat-label>Search:</mat-label>
            <input matInput (keyup)="applyTextFilter($event)" #input />
          </mat-form-field>
        </div>
      </div>
      <div class="organization-list-container">
        <ng-container *ngIf="filteredOrgs?.length">
          <span layout="row">{{ sales_org.name }}'s customers </span>
          <ng-container
            *ngIf="sales_org && filteredOrgs?.length"
            [ngTemplateOutlet]="organizationItemList"
            [ngTemplateOutletContext]="{ orgs: filteredOrgs }"
          ></ng-container>
        </ng-container>
        <ng-container *ngIf="filteredUnrelatedOrgs?.length">
          <span layout="row">Other organizations </span>
          <ng-container
            [ngTemplateOutlet]="organizationItemList"
            [ngTemplateOutletContext]="{ orgs: filteredUnrelatedOrgs }"
          ></ng-container>
        </ng-container>
        <ng-container
          *ngIf="
            sales_org &&
            activeOrganization &&
            activeOrganization.type === 'customer_organization'
          "
        >
          <mat-divider style="margin-bottom: 1em"></mat-divider>
          <button mat-menu-item (click)="selectSalesOrganization(sales_org)">
            <mat-icon>logout</mat-icon>Leave active customer
          </button>
        </ng-container>
      </div>
    </div>
  </mat-menu>
  <a class="toolbar-site-logo" [routerLink]="['/', pagesPATH.CUSTOMERS]">
    <img [src]="publicApi.mrcLogoWhite" />
  </a>
  <span class="toolbar-spacer"></span>
  <ng-container>
    <app-state-chips-component
      [user]="stateService.user$ | async"
      [customer_organizations]="organizations"
      [sales_organizations]="salesOrganizations"
      [sales_org]="stateService.sales_organization$ | async"
      [customer_org]="stateService.customer_organization$ | async"
    ></app-state-chips-component>
  </ng-container>
</mat-toolbar>

<ng-template #organizationItemList let-orgs="orgs">
  <div
    class="organization-card"
    *ngFor="let org of orgs"
    (click)="selectCustomerOrganization(org)"
  >
    <div class="row">
      <div class="col-2 extended-menu-avatar" mat-card-avatar disabled="true">
        <app-company-logo
          [orgId]="org.id"
          [assetUrl]="org.logo"
        ></app-company-logo>
      </div>
      <div class="col-9 row extended-menu-text" disabled="true">
        <span *ngIf="org.type; else dashboard" class="col-12 secondary-span">{{
          org.type.toUpperCase().replaceAll('_', ' ')
        }}</span>
        <ng-template #dashboard>
          <span>ORGANIZATION</span>
        </ng-template>
        <span class="col-12 main-span">{{ org.name }}</span>
      </div>
      <div class="col-1 extended-menu-radio_button">
        <mat-icon>
          {{
            activeOrganization?.id === org.id
              ? 'radio_button_checked'
              : 'radio_button_unchecked'
          }}
        </mat-icon>
      </div>
    </div>
  </div>
</ng-template>
