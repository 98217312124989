import * as THREE from 'three';
import {
  Conveyor,
  ISceneApiFileType,
} from '../../types/simulation-api-file-type';
import { ObjectUtils } from 'src/app/utils/object';

export const defaultCustomGripper: ISceneApiFileType['robot']['gripper'] = {
  type: 'CUSTOM',
  custom_collision_box: {
    length: 0.3,
    height: 0.1,
    width: 0.15,
    position: new THREE.Vector3(),
  },
  custom_offset_pose: {
    position: new THREE.Vector3(0, -0.05, 0),
    rotation: {
      r: 0,
      p: 0,
      y: 0,
    },
  },
};

export const defaultConveyor: Conveyor = {
  type: 'STANDARD',
  custom_description: {
    name: 'conveyor_mock',
    sensors: {
      products: [0],
      product_priority: 0,
    },
    // STANDARD conveyor dimension in meters
    dimension: new THREE.Vector3(0.387, 2.33, 0.74),
    velocity: 0.5,
    pub_rate: 40,
    guide_left: false,
    guide_width: 0.02,
    add_at_end: false,
  },
  box_corner_pose: {
    position: new THREE.Vector3(0, 0.9, 0.7),
    orientation: new THREE.Quaternion(),
  },
};

/**
 * Default scene configuration.
 * NOTE! Use "getDefaultScene()" to get a new instance of the default scene.
 * Do **NOT** use this object directly unless it's a harmless reference, like
 * reading a value.
 */
export const defaultScene: ISceneApiFileType = {
  id: null,
  robot_mounting_height: {
    position: new THREE.Vector3(),
    rotation: {
      r: 0,
      p: 0,
      y: 0,
    },
  },
  theme: 'PALLY',
  conveyors: [
    ObjectUtils.cloneObject(defaultConveyor) as Conveyor,
    // NOTE: If dualProduct is disabled, the second conveyor will be removed.
    // Just having two conveyors in the scene does not mean dualProduct is
    // enabled, but it is an indicator it should be.
    ObjectUtils.cloneObject(defaultConveyor) as Conveyor,
  ],
  /*
  Dual product mode is disabled by default even though the scene might have two
  conveyors. Because it seems much more difficult to handle every possible case
  where a field might look for it's value than to just remove the second
  conveyor when dualProduct is disabled, on save.

  - If disabled ON SAVE, the second conveyor will be removed.
  - If a scene with dualProductMode is loaded, the second conveyor will be
    loaded as well and fields will be setup, if then disabled it will be
    ignored and hidden in the view.
  */
  dualProduct: false,
  robot: {
    //  name: 'string',
    type: 'UR10E',
    frame: {
      type: 'EASY_PALLETIZER',
    },
    lift_kit: {
      type: 'EWELLIX',
      max_stroke: 0.9,
    },
    gripper: {
      type: 'PIAB-400',
      custom_collision_box: {
        width: 0,
        length: 0,
        height: 0,
        position: new THREE.Vector3(),
      },
      custom_offset_pose: {
        position: new THREE.Vector3(),
        rotation: {
          r: 0,
          p: 0,
          y: 0,
        },
      },
    },
    offset_bracket: {
      offset: new THREE.Vector3(),
      collision_object: {
        length: 0,
        height: 0,
        width: 0,
        position: new THREE.Vector3(),
      },
    },
  },
  place_targets: [
    {
      name: 'p1',
      lip: 0,
      corner_base_pose: {
        position: new THREE.Vector3(0.33, -0.6, 0),
        orientation: new THREE.Quaternion(),
      },
    },
    {
      name: 'p2',
      lip: 0,
      corner_base_pose: {
        position: new THREE.Vector3(-0.33, -0.6, 0),
        orientation: new THREE.Quaternion(),
      },
    },
  ],
};
