import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, Subject, switchMap } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DialogSize } from 'src/app/models_new/enums/dialogSize';
import { DialogService } from 'src/app/services/dialog.service';
import { ErrorHandlerService } from 'src/app/services/error-handler.service';
import { StateService } from '../../../auth/state.service';
import { ApiOrganization } from '../../../models_new/classes/api-models/ApiOrganization';
import { ApiProduct } from '../../../models_new/classes/api-models/ApiProduct';
import { defaultApiProduct } from '../../../models_new/config/default/api-default/default-api-product';
import { ProductApiService } from '../../../services/api/product-api.service';
import { ProductCardMode } from './new-product-card/new-product-card.component';
import { Location } from '@angular/common';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-product-component',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
})
export class ProductComponent implements OnInit, OnDestroy {
  ready = false;
  orgID: string;

  mode: ProductCardMode;
  originalDim: { length: number; width: number; height: number };
  product: ApiProduct = new ApiProduct(defaultApiProduct);
  isProductDirty = false;

  /** isEmbedded, isNew & outputEvent Meant to be used when the component is used embedded inside another component. */
  @Input() isEmbedded = false;
  @Input() isNew = false;
  @Output() outputEvent = new EventEmitter<{ id: string; name: string }>();

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private route: ActivatedRoute,
    private errorHandler: ErrorHandlerService,
    private dialogService: DialogService,
    private productApi: ProductApiService,
    private stateService: StateService,
    private location: Location,
    private notify: NotificationService,
    private router: Router
  ) {
    this.stateService
      .getCustomerOrSalesOrganization()
      .pipe(takeUntil(this.destroy$))
      .subscribe((org: ApiOrganization) => {
        this.orgID = org.id;
      });
  }

  ngOnInit(): void {
    const productID =
      this.isEmbedded && this.isNew ? 'new' : this.route.snapshot.params?.id;
    if (!productID) {
      this.errorHandler.handleError(new Error('Missing id in params'));
    }
    if (productID === 'new' || (this.isEmbedded && this.isNew)) {
      this.mode = 'create';
      this.ready = true;
    } else {
      this.mode = 'edit';
      this.productApi
        .fetchProductById(productID)
        .pipe(takeUntil(this.destroy$))
        .subscribe((product: ApiProduct) => {
          this.product = product;
          this.originalDim = {
            length: this.product.data.length,
            width: this.product.data.width,
            height: this.product.data.height,
          };
          this.ready = true;
        });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public productUpdate(product: ApiProduct): void {
    if (!product) return;
    this.product = product;
    this.isProductDirty =
      this.product.data.length !== this.originalDim?.length ||
      this.product.data.width !== this.originalDim?.width ||
      this.product.data.height !== this.originalDim?.height;
  }

  public onCancelAction(): void {
    if (this.isEmbedded) this.outputEvent.emit(null);
    else this.location.back();
  }

  public onSaveAction(): void {
    if (
      this.isProductDirty &&
      this.product.patterns &&
      this.product.patterns.length > 0
    ) {
      this.showPatternUpdateWarningDialog(
        this.product.patterns.length
      ).subscribe((conf) => {
        if (conf) {
          this.handleSaving();
        } // Cancel doesn't save the product, only exits.
      });
    } else {
      this.handleSaving();
    }
  }

  public onSaveAndCreatePatternAction(): void {
    if (
      this.isProductDirty &&
      this.product.patterns &&
      this.product.patterns.length > 0
    ) {
      this.showPatternUpdateWarningDialog(
        this.product.patterns.length
      ).subscribe((conf) => {
        if (conf) {
          this.handleSavingAndCreatePattern();
        } // Cancel doesn't save the product, only exits.
      });
    } else this.handleSavingAndCreatePattern();
  }

  private handleSaving(): void {
    this.saveProduct()
      .pipe(take(1))
      .subscribe((value) => {
        if (!value) {
          return;
        }
        this.product.id = value.id;
        this.notify.showSuccess(
          `The product "${this.product.name}" was saved!`
        );
        if (this.isEmbedded)
          this.outputEvent.emit({
            id: this.product.id,
            name: this.product.name,
          });
        else if (this.route.snapshot.queryParams.origin) {
          this.router.navigateByUrl(this.route.snapshot.queryParams.origin);
        } else this.location.back();
      });
  }

  private handleSavingAndCreatePattern(): void {
    this.saveProduct()
      .pipe(take(1))
      .subscribe((value) => {
        this.product.id = value.id;
        this.notify.showSuccess(
          `The product "${this.product.name}" was saved!`
        );
        //Route to pattern creation
        if (this.route.snapshot.queryParams.origin) {
          this.router.navigateByUrl(this.route.snapshot.queryParams.origin);
        } else {
          this.router.navigate([`/patterns/new`], {
            queryParams: { productID: this.product.id },
          });
        }
      });
  }

  private saveProduct(): Observable<any> {
    if (!this.product) {
      return of(new Error('Invalid product! Refresh and try again!'));
    }
    if (this.product?.name === '') return of(new Error('No name given!'));

    if (this.mode === 'create') {
      return this.productApi.fetchProductsLight(this.orgID).pipe(
        switchMap((products: ApiProduct[]) => {
          if (products.find((p) => p.name === this.product.name)) {
            let confirmDialog = this.dialogService.showStandardDialog({
              content: {
                title: 'This product name already exists, are you sure?',
                contentAsString: `A product with the name "${this.product.name}" already exists. Do you still want to create it?`,
              },
              button: { text: 'Yes' },
              gui: { size: DialogSize.MEDIUM },
            });
            return confirmDialog.afterClosed().pipe(
              switchMap((confirmed: boolean) => {
                if (confirmed) {
                  this.setProdDimentionCeiling();
                  return this.productApi.createProduct(
                    this.product.name,
                    this.product.data,
                    this.orgID
                  );
                } else {
                  return of(null);
                }
              })
            );
          }
          this.setProdDimentionCeiling();
          return this.productApi.createProduct(
            this.product.name,
            this.product.data,
            this.orgID
          );
        })
      );
    } else {
      this.setProdDimentionCeiling();
      return this.productApi.updateProduct(
        this.product.id,
        this.product.name,
        this.product.data
      );
    }
  }

  /**
   * @desc Sets the product dimensions to the nearest next integer.
   * This will only affect values coming form Imperial units, as metric can't have decimals inputted.
   */
  public setProdDimentionCeiling(): void {
    this.product.data.length = Math.ceil(this.product.data.length);
    this.product.data.width = Math.ceil(this.product.data.width);
    this.product.data.height = Math.ceil(this.product.data.height);
  }

  private showPatternUpdateWarningDialog(nrPatterns: number): Observable<any> {
    return this.dialogService
      .showStandardDialog({
        content: {
          title: 'Save product?',
          contentAsString: `This might make ${nrPatterns} ${
            nrPatterns > 1 ? 'patterns' : 'pattern'
          } in need of update!`,
        },
        button: { text: 'Yes, I understand, save it anyway' },
        gui: { size: DialogSize.MEDIUM },
      })
      .afterClosed()
      .pipe(take(1));
  }
}
