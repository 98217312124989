import { Injector } from '@angular/core';
import * as THREE from 'three';
import { URDFRobot } from '@rocketfarm/urdf-loader';
import { combineLatest, ReplaySubject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { PartType } from 'src/app/models_new/enums/sim-config-part-type';
import { FieldUpdate } from 'src/app/models_new/types/field-update';
import { JointNames } from 'src/app/services/project-robot-descriptor.service';
import { HwPartUtils } from 'src/app/utils/hw-part-utils';
import { RXJSUtils } from 'src/app/utils/rxjs-utils';
import { Type } from 'src/app/utils/type';
import { URDFUtils } from 'src/app/utils/urdf-utils';
import { Task, taskNameSymbol } from '../task';
import { ThreeUtils } from 'src/app/utils/three-utils';

export class LiftkitTask extends Task {
  static [taskNameSymbol] = 'Lifting column';
  private readonly liftkitDummyLinkName = 'liftkit_dummy_link';

  constructor(
    protected threeID: string,
    injector: Injector,
    protected destroy$: ReplaySubject<boolean>
  ) {
    super(threeID, injector, destroy$);
  }

  public operation(resolve: () => void, reject: (reason?: any) => void): void {
    this.updateLiftKit(this.data, resolve, reject);
  }

  public updateLiftKit(
    s: FieldUpdate,
    resolve: () => void,
    _reject: (reason?: any) => void
  ): void {
    // eslint-disable-next-line eqeqeq
    if (s.newVal.name == 'NONE') {
      combineLatest([
        this.getParentPart(PartType.LIFTKIT),
        this.getParentPart(PartType.BASE_BRACKET),
      ])
        .pipe(
          RXJSUtils.filterUndefinedAndNull(),
          take(1),
          map(([[parentPart, _], [__, bracketParentPartType]]) => [
            parentPart,
            bracketParentPartType,
          ])
        )
        .subscribe(([parentPart, _bracketParentPartType]) => {
          const frame_liftkitJoint = this.robot.getJointByID(
            JointNames.FrameLiftkit
          ) as THREE.Object3D;
          if (Type.isDefined(parentPart)) {
            const parentPartMountJoint = URDFUtils.findMountJoint(
              parentPart as URDFRobot
            );
            ThreeUtils.copyTransform(frame_liftkitJoint, parentPartMountJoint);
          }
          frame_liftkitJoint.children = [];

          const liftkitDummyLink =
            URDFUtils.makeEmptyURDFLink() as THREE.Object3D;
          liftkitDummyLink.name = this.liftkitDummyLinkName;
          liftkitDummyLink.children = [];
          frame_liftkitJoint.add(liftkitDummyLink);

          const liftkit_basebracketJoint = this.robot.getJointByID(
            JointNames.LiftkitBasebracket
          ) as THREE.Object3D;
          ThreeUtils.zeroTransform(liftkit_basebracketJoint);
          liftkitDummyLink.add(liftkit_basebracketJoint);

          this.visualize();

          resolve();
        });
    } else {
      const frame_liftkitJoint = this.robot.getJointByID(
        JointNames.FrameLiftkit
      ) as THREE.Object3D;
      const liftkit_basebracketJoint = this.robot.getJointByID(
        JointNames.LiftkitBasebracket
      ) as THREE.Object3D;

      combineLatest([
        this.partStoreService.getPart<URDFRobot>(
          HwPartUtils.getPartAssetID(PartType.LIFTKIT, s.newVal.name)
        ),
        this.getParentPart(PartType.LIFTKIT),
      ])
        .pipe(
          filter(([part, [parentPart, _]]: any[]) => {
            return Type.isDefined(part) && Type.isDefined(parentPart);
          }),
          take(1),
          takeUntil(this.destroy$)
        )
        .subscribe(([part, [parentPart, _]]: any[]) => {
          const partJoint = (part as THREE.Object3D).getObjectByName(
            'liftkit_joint'
          );
          const lastPartLink = URDFUtils.findLinkBeforeMountJoint(
              part as URDFRobot
            ),
            parentPartMountJoint = URDFUtils.findMountJoint(
              parentPart as URDFRobot
            ),
            partMountJoint = URDFUtils.findMountJoint(part);

          frame_liftkitJoint.children = [];
          for (const child of partJoint.children) {
            frame_liftkitJoint.add(child);
          }
          lastPartLink.children = lastPartLink.children.filter(
            (child) => !URDFUtils.isURDFJoint(child)
          );
          lastPartLink.add(liftkit_basebracketJoint);

          if (Type.isDefined(parentPartMountJoint)) {
            ThreeUtils.copyTransform(frame_liftkitJoint, parentPartMountJoint);
          }

          ThreeUtils.copyTransform(liftkit_basebracketJoint, partMountJoint);

          this.visualize();

          resolve();
        });
    }
  }

  visualize(): void {
    this.activateDimVis(
      'robot_mount_height_full',
      undefined,
      this.readShowVisualizers() ? -1 : undefined
    );
  }
}
