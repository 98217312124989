import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatternsWrapperComponent } from '../../components/patterns/patterns-wrapper.component';
import { PatternsComponent } from '../../components/inventory/patterns/patterns.component';
import { OrganizationContextResolver } from '../../resolvers/organization-context-resolver.resolver';
import { NewPatternComponent } from 'src/app/components/patterns/pattern/new-pattern/new-pattern.component';
import { PalletComponent } from 'src/app/components/patterns/pattern/pallet/pallet.component';

const routes: Routes = [
  {
    path: '',
    component: PatternsWrapperComponent,
    data: {
      breadcrumb: 'Patterns',
    },
    children: [
      {
        component: NewPatternComponent,
        path: 'new',
        resolve: { organization_id: OrganizationContextResolver },
        data: {
          breadcrumb: 'New pattern',
        },
      },
      {
        component: PatternsComponent,
        path: '',
        data: {
          breadcrumb: 'pattern',
        },
      },
      {
        component: PalletComponent,
        path: ':id',
        resolve: { organization_id: OrganizationContextResolver },
        data: {
          breadcrumb: 'Edit pattern',
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PatternRoutingModule {}
