<div class="table-wrapper">
  <div class="top-row" *ngIf="topRow">
    <h3>{{ tableName }}</h3>
    <app-filters
      (textFilter)="textFilter = $event"
      (statusFilter)="statusFilter = $event"
      (dateFilter)="dateFilter = $event"
    ></app-filters>
  </div>

  <div class="table-container">
    <table
      #table
      mat-table
      [dataSource]="dataSource"
      class="mat-elevation-z8"
      matSort
      matSortActive="updatedAt"
      matSortDirection="desc"
    >
      <ng-container *ngFor="let column of displayedColumns">
        <ng-container *ngIf="column === 'select'" matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef>
            <mat-checkbox
              *ngIf="!disableBlockSelection"
              color="primary"
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
            >
            </mat-checkbox>
            <button
              mat-icon-button
              [matMenuTriggerFor]="blockMenu"
              (click)="$event.stopPropagation()"
              *ngIf="selection.hasValue() && blockActions?.length"
            >
              <mat-icon>more_vert</mat-icon>
            </button>
            <mat-menu #blockMenu="matMenu">
              <ng-container *ngFor="let action of blockActions">
                <button
                  style="display: flex; align-items: center"
                  [style.color]="action.txtColor"
                  [style.background-color]="action.bgColor"
                  mat-menu-item
                  (click)="doBlockAction(action)"
                  *appIfCommandAvailable="
                    action.roleAction ? action.roleAction : 'default_action'
                  "
                >
                  <mat-icon
                    *ngIf="action.icon"
                    [ngClass]="
                      action.iconPosition === 'right' ? 'alignRight' : ''
                    "
                    [style.color]="action.txtColor"
                  >
                    {{ action.icon }}</mat-icon
                  >
                  <span
                    style="white-space: pre-wrap !important; font-size: 11px"
                  >
                    {{ action.label }}
                  </span>
                </button>
              </ng-container>
            </mat-menu>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              color="primary"
              (click)="$event.stopPropagation()"
              (change)="$event ? selectRow(row) : null"
              [checked]="selection.isSelected(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container *ngIf="column !== 'select'" [matColumnDef]="column">
          <ng-container *ngIf="column !== 'actions'">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <ng-container
                *ngIf="overrideTitle(column); else regularColumnHeader"
              >
                {{ overrideTitle(column).columnTitle }}
              </ng-container>
              <ng-template #regularColumnHeader>
                {{
                  shouldDoTitleCase ||
                  column === 'updated_at' ||
                  column === 'created_at'
                    ? (column | removeLodash | titlecase)
                    : (column | removeLodash)
                }}
                <ng-container
                  *ngIf="
                    headerActions && getHeaderAction(column) as headerAction
                  "
                >
                  <div (click)="$event.stopPropagation()">
                    <button
                      mat-icon-button
                      color="primary"
                      *ngIf="
                        headerAction.icon && !headerAction.label;
                        else iconButton
                      "
                      (click)="
                        actionClicked.emit({
                          actionId: headerAction.actionId
                        })
                      "
                    >
                      <mat-icon
                        *ngIf="
                          headerAction.icon !== 'info';
                          else customInfoIcon
                        "
                        >{{ headerAction.icon }}</mat-icon
                      >
                      <ng-template #customInfoIcon></ng-template>
                      <mat-icon [svgIcon]="headerAction.icon"></mat-icon>
                    </button>
                    <ng-template #iconButton>
                      <button mat-flat-button>
                        {{ headerAction.label
                        }}<mat-icon *ngIf="headerAction.icon">{{
                          headerAction.icon
                        }}</mat-icon>
                      </button>
                    </ng-template>
                  </div>
                </ng-container>
              </ng-template>
            </th>
          </ng-container>

          <ng-container *ngIf="column === 'actions'">
            <th class="hasActions" mat-header-cell *matHeaderCellDef></th>
          </ng-container>

          <td
            mat-cell
            *matCellDef="let element"
            class="generic-row-cell"
            (click)="rowClick(column, element)"
            [class.hasActions]="column === 'actions'"
            [class.strictFirstColWidth-50]="strictFirstColWidth"
          >
            <div style="display: flex; align-items: center">
              <ng-container
                *ngIf="getInfoPopup(element) && column === columnsToDisplay[0]"
              >
                <mat-icon
                  svgIcon="info"
                  [color]="'primary'"
                  (click)="openInfoPopup(element)"
                  style="margin-right: 8px; cursor: pointer"
                  class="pdf-hide"
                ></mat-icon>
              </ng-container>
              <ng-container
                *ngIf="
                  column !== 'actions' &&
                  column !== 'projects' &&
                  column !== 'updated_at' &&
                  column !== 'created_at' &&
                  column !== 'first_request' &&
                  column !== 'last_request' &&
                  column !== 'valid_to' &&
                  column !== 'expires_at'
                "
              >
                <ng-container *ngIf="!element[column]?.name; else objectName">
                  <ng-container *ngIf="column === 'name'; else text">
                    <a
                      *ngIf="element.linkName"
                      class="clamp-content"
                      style="cursor: pointer; color: #477ff1"
                      (click)="rowClick(column, element)"
                    >
                      {{ element[column] | removeLodash }}
                    </a>
                    <span *ngIf="!element.linkName" class="clamp-content">
                      {{ element[column] | removeLodash }}
                    </span>
                  </ng-container>

                  <ng-template #text>
                    <ng-container
                      *ngIf="
                        elementHasInfoOrError(element, column, inputData);
                        else otherField
                      "
                    >
                      <ng-container
                        [ngTemplateOutlet]="infoErrorContainer"
                        [ngTemplateOutletContext]="{
                          elementData: getElementData(element, inputData),
                          column: column
                        }"
                      ></ng-container>
                      <ng-template
                        #infoErrorContainer
                        let-elementData="elementData"
                        let-column="column"
                      >
                        <app-info-error-badge
                          [type]="elementData?.info?.[column] ? 'info' : 'error'"
                          [data]="elementData?.info?.[column] ? elementData?.info?.[column] : elementData?.error?.[column]"
                          (actionClicked)="
                            actionClicked.emit({
                              actionId: $event.actionId,
                              element: element
                            })
                          "
                        ></app-info-error-badge>
                      </ng-template>
                    </ng-container>

                    <ng-template #otherField>
                      <div
                        [ngClass]="getCellStyling(element)?.(element, column)"
                      >
                        {{
                          typeOf(element[column]) === 'object' &&
                          element[column].actionText !== undefined
                            ? element[column].actionText
                            : element[column]
                        }}
                      </div>
                    </ng-template>
                  </ng-template>
                </ng-container>

                <ng-template #objectName>
                  <span class="clamp-content">
                    {{ element[column].name | removeLodash }}
                  </span>
                </ng-template>
              </ng-container>

              <ng-container
                *ngIf="
                  column === 'updated_at' ||
                  column === 'created_at' ||
                  column === 'first_request' ||
                  column === 'last_request' ||
                  column === 'valid_to' ||
                  column === 'expires_at'
                "
              >
                <div style="white-space: nowrap">
                  {{ timeUtils.getFormattedDate(element[column], '2-digit') }}
                </div>
              </ng-container>

              <ng-container *ngIf="column === 'projects'">
                <mat-chip-listbox>
                  <mat-chip-option
                    *ngFor="let r of element.projects"
                    (click)="
                      actionClicked.emit({ actionId: 'project', element: r })
                    "
                    >{{ r.name }}</mat-chip-option
                  >
                </mat-chip-listbox>
              </ng-container>

              <ng-container *ngIf="column === 'actions'">
                <div
                  style="display: flex; align-items: center"
                  [ngClass]="{ 'hide-actions-lowres': hideRowActions }"
                >
                  <ng-container
                    *ngFor="let action of getActions(element) | async"
                  >
                    <ng-container *ngIf="action.label === 'favourite'">
                      <button
                        mat-icon-button
                        (click)="
                          actionClicked.emit({
                            actionId: action.actionId,
                            element: element
                          })
                        "
                      >
                        <mat-icon
                          class="material-icons-outlined"
                          [ngStyle]="{
                            color: element['favourite']
                              ? '#E03131'
                              : 'rgba(7, 10, 23, 0.54)'
                          }"
                        >
                          {{
                            'favorite' +
                              (!element['favourite'] ? '_outline' : '')
                          }}
                        </mat-icon>
                      </button>
                    </ng-container>

                    <ng-container
                      *appIfCommandAvailable="
                        action.roleAction ? action.roleAction : 'default_action'
                      "
                    >
                      <ng-container *ngIf="action.button">
                        <button
                          *ngIf="action.button === 'flat'"
                          [disabled]="action.disabled"
                          mat-flat-button
                          color="primary"
                          style="min-width: fit-content; margin-right: 8px"
                          (click)="
                            actionClicked.emit({
                              actionId: action.actionId,
                              element: element
                            })
                          "
                        >
                          <span
                            style="
                              width: 100%;
                              font-size: 11px;
                              display: flex;
                              align-items: center;
                            "
                          >
                            <mat-icon
                              *ngIf="action.icon"
                              [ngClass]="
                                action.iconPosition === 'right'
                                  ? 'alignRight'
                                  : ''
                              "
                              >{{ action.icon }}</mat-icon
                            >
                            {{ action.label }}
                            <mat-icon
                              *ngIf="action.customIcon"
                              svgIcon="action.customIcon"
                            ></mat-icon>
                          </span>
                        </button>
                        <button
                          *ngIf="action.button === 'basic'"
                          mat-button
                          [disabled]="action.disabled"
                          icon
                          style="min-width: fit-content; margin-right: 8px"
                          class="action-button"
                          [ngClass]="
                            action?.disabled
                              ? 'action-button-disabled'
                              : action?.buttonTheme
                              ? 'action-button-' + action.buttonTheme
                              : 'action-button-primary'
                          "
                          (click)="
                            actionClicked.emit({
                              actionId: action.actionId,
                              element: element
                            })
                          "
                        >
                          <span
                            style="
                              white-space: pre-wrap !important;
                              font-size: 13px;
                              display: flex;
                              align-items: center;
                              justify-content: center;
                            "
                          >
                            {{ action.label }}
                            <mat-icon *ngIf="action.icon">{{
                              action.icon
                            }}</mat-icon>
                            <mat-icon
                              *ngIf="action.customIcon"
                              svgIcon="action.customIcon"
                            ></mat-icon>
                          </span>
                        </button>
                      </ng-container>
                    </ng-container>
                  </ng-container>
                  <ng-container
                    *appVar="getActions(element, true) | async as items"
                  >
                    <app-sub-menu
                      #menu
                      [items]="items"
                      (actionEmit)="
                        actionClicked.emit({
                          actionId: $event,
                          element: element
                        })
                      "
                    ></app-sub-menu>
                    <button
                      mat-icon-button
                      class="ellipsis-menu"
                      *ngIf="
                        canShowActionMenuButtonWithActions(items) &&
                        !hideActionsMenu
                      "
                      [matMenuTriggerFor]="menu.childMenu"
                      (click)="$event.stopPropagation()"
                    >
                      <mat-icon>more_vert</mat-icon>
                    </button>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </td>
        </ng-container>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="columnsToDisplay"></tr>
      <tr
        [class.clickable]="clickableRows || clickToSelect"
        [class.new]="row.isNew"
        [style.background-color]="getBgColor(row)"
        mat-row
        [class.lastClicked]="lastClicked === row"
        *matRowDef="let row; columns: columnsToDisplay"
        (click)="clickToSelect ? selectRow(row) : ''"
      ></tr>

      <!-- Row shown when there is no matching data. -->
      <tr class="mat-row" *matNoDataRow>
        <td class="mat-cell no-table-data" colspan="4">
          <span>{{ noDataText }}</span>
          <button
            *ngIf="shouldShowButtonToCreateIfNoData"
            (click)="this.didPressCreate.emit()"
            class="linkButton"
          >
            Create one?
          </button>
        </td>
      </tr>
    </table>
  </div>

  <mat-paginator *ngIf="showPaginator"></mat-paginator>
</div>
