<svg
  class="icon-loader"
  xmlns="http://www.w3.org/2000/svg"
  version="1.1"
  width="201px"
  height="200px"
  xmlns:xlink="http://www.w3.org/1999/xlink"
>
  <g>
    <path
      style="opacity: 0.928"
      fill="transparent"
      d="M 189.5,0.5 C 195.641,-0.250239 198.641,2.41643 198.5,8.5C 198.674,10.6934 198.007,12.5267 196.5,14C 165.833,31.6667 135.167,49.3333 104.5,67C 100.548,67.9115 96.8809,67.2448 93.5,65C 83.4004,59.2289 73.5671,53.0623 64,46.5C 63.4833,36.0391 67.9833,33.2057 77.5,38C 84.8333,42.3333 92.1667,46.6667 99.5,51C 100.5,51.6667 101.5,51.6667 102.5,51C 131.563,34.1399 160.563,17.3066 189.5,0.5 Z"
      class="mrc-loader-1"
      [class.dark-mode]="darkMode"
    ></path>
  </g>
  <g>
    <path
      style="opacity: 0.955"
      fill="transparent"
      d="M 5.5,30.5 C 10.1923,30.8405 14.5256,32.3405 18.5,35C 40.5,47.3333 62.5,59.6667 84.5,72C 86,72.8333 87.1667,74 88,75.5C 88.6667,115.167 88.6667,154.833 88,194.5C 83.1993,198.749 78.866,198.416 75,193.5C 74.6667,156.833 74.3333,120.167 74,83.5C 50.5808,70.2066 27.0808,57.04 3.5,44C -0.591318,38.7794 0.0753484,34.2794 5.5,30.5 Z"
      class="mrc-loader-2"
      [class.dark-mode]="darkMode"
    ></path>
  </g>
  <g>
    <path
      style="opacity: 0.969"
      fill="transparent"
      d="M 21.5,71.5 C 25.3153,70.8285 28.1486,72.1619 30,75.5C 30.6667,103.167 30.6667,130.833 30,158.5C 25.7139,163.815 21.3806,163.815 17,158.5C 16.3333,130.833 16.3333,103.167 17,75.5C 18.3968,73.9297 19.8968,72.5964 21.5,71.5 Z"
      class="mrc-loader-3"
      [class.dark-mode]="darkMode"
    ></path>
  </g>
  <g>
    <path
      style="opacity: 0.923"
      fill="transparent"
      d="M 176.5,81.5 C 180.622,81.4763 183.788,83.143 186,86.5C 186.667,113.167 186.667,139.833 186,166.5C 182.402,173.616 177.736,174.282 172,168.5C 171.333,140.833 171.333,113.167 172,85.5C 173.397,83.9297 174.897,82.5964 176.5,81.5 Z"
      class="mrc-loader-4"
      [class.dark-mode]="darkMode"
    ></path>
  </g>
  <g>
    <path
      style="opacity: 0.938"
      fill="transparent"
      d="M 51.5,91.5 C 55.4326,91.4113 58.2659,93.078 60,96.5C 60.5216,122.841 60.1883,149.175 59,175.5C 54.6667,180.833 50.3333,180.833 46,175.5C 45.3333,149.167 45.3333,122.833 46,96.5C 47.1065,93.887 48.9399,92.2203 51.5,91.5 Z"
      class="mrc-loader-5"
      [class.dark-mode]="darkMode"
    ></path>
  </g>
  <g>
    <path
      style="opacity: 0.944"
      fill="transparent"
      d="M 148.5,101.5 C 151.559,101.797 154.059,103.13 156,105.5C 156.951,131.83 157.285,158.163 157,184.5C 152.333,191.167 147.667,191.167 143,184.5C 142.333,158.167 142.333,131.833 143,105.5C 144.5,103.531 146.333,102.198 148.5,101.5 Z"
      class="mrc-loader-6"
      [class.dark-mode]="darkMode"
    ></path>
  </g>
  <g>
    <path
      style="opacity: 0.909"
      fill="transparent"
      d="M 184.5,33.5 C 192.201,33.3622 195.367,37.0289 194,44.5C 186.256,50.2914 178.089,55.4581 169.5,60C 159.842,61.5145 156.675,57.6812 160,48.5C 168.314,43.6001 176.481,38.6001 184.5,33.5 Z"
      class="mrc-loader-7"
    ></path>
  </g>
  <g>
    <path
      style="opacity: 0.929"
      fill="transparent"
      d="M 130.5,65.5 C 139.328,64.1607 142.828,67.8274 141,76.5C 134.335,81.3345 127.335,85.6679 120,89.5C 119.667,123.833 119.333,158.167 119,192.5C 114.833,198.759 110.167,199.092 105,193.5C 104.333,156.5 104.333,119.5 105,82.5C 105.465,81.0692 106.299,79.9025 107.5,79C 115.559,74.9779 123.225,70.4779 130.5,65.5 Z"
      class="mrc-loader-8"
    ></path>
  </g>
</svg>
