<div class="advanced_navbar_wrapper">
  <div class="advanced_navbar_actions_wrapper">
    <div class="advanced_navbar_buttons_wrapper">
      <button
        mat-button
        (click)="settingsClick.emit(true); showSettings = !showSettings"
        class="settings_button"
      >
        <span *ngIf="!showSettings">
          <mat-icon style="overflow: visible">settings</mat-icon>
          Settings
        </span>
        <span *ngIf="showSettings">
          <mat-icon style="overflow: visible">close</mat-icon>
          Close settings
        </span>
      </button>
    </div>

    <div class="advanced_navbar_pallet_info" *ngIf="getPalletSummary()">
      <div>
        Current pallet:
        <button
          mat-raised-button
          color="primary"
          [disabled]="project.palletAdvancedSettings.altLayout !== 'custom'"
          (click)="palletToggleClick.emit()"
        >
          {{ project.getActivePalletPosition() === 0 ? 'Left' : 'Right' }}
        </button>
      </div>

      <button
        class="pallet_info_item"
        disableRipple="true"
        mat-button
        matTooltip="Box Count"
      >
        <mat-icon svgIcon="boxes"></mat-icon>
        <span>{{ getPalletSummary().boxCount }}</span>
      </button>

      <button
        class="pallet_info_item"
        disableRipple="true"
        mat-button
        matTooltip="Pallet Height (Pallet Load Height + {{
          project.getProjectData().getPalletData().dimensions.palletHeight +
            ' mm' | unitSystemLabel
        }})"
      >
        <mat-icon>height</mat-icon>
        <span>{{
          (getPalletSummary().palletHeight / 1000 | number: '1.0-3') + ' m'
            | unitSystem
        }}</span>
      </button>

      <button
        class="pallet_info_item"
        disableRipple="true"
        mat-button
        matTooltip="Pallet Load Height"
      >
        <mat-icon svgIcon="pallet_height"></mat-icon>
        <span>{{
          (getPalletSummary().palletLoadHeight / 1000 | number: '1.0-3') + ' m'
            | unitSystem
        }}</span>
      </button>

      <button
        class="pallet_info_item"
        disableRipple="true"
        mat-button
        matTooltip="Pallet Weight"
      >
        <mat-icon svgIcon="pallet_weight"></mat-icon>
        <span>{{
          (getPalletSummary().palletWeight / 1000 | number: '1.0-2') + ' kg'
            | unitSystem
        }}</span>
      </button>

      <button
        class="pallet_info_item"
        disableRipple="true"
        mat-button
        matTooltip="Total Number of Layers"
      >
        <mat-icon>layers</mat-icon>
        <span>{{ getPalletSummary().numberOfLayers }}</span>
      </button>

      <button
        class="pallet_info_item"
        disableRipple="true"
        mat-button
        matTooltip="Pallet Area Efficiency"
      >
        <mat-icon svgIcon="pallet_fill"></mat-icon>
        <span>{{
          getPalletSummary().palletAreaEfficiency | number: '1.0-2'
        }}</span>
      </button>

      <button
        class="pallet_info_item"
        disableRipple="true"
        mat-button
        matTooltip="Pallet Cube Efficiency"
      >
        <mat-icon svgIcon="pallet_cube"></mat-icon>
        <span>{{
          getPalletSummary().palletCubeEfficiency | number: '1.0-2'
        }}</span>
      </button>
    </div>
  </div>

  <mat-divider></mat-divider>
</div>
